import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import UserSettingsTypes from './types.js';
import { mapKeys, isNil, pick, mapValues } from 'mojito/utils';
import { actions as authenticationActions } from 'services/authentication/slice.js';

const {
    LOCAL_TIME,
    ODDS_FORMATS_SUPPORTED,
    ODDS_FORMAT,
    ONBOARDING_TYPES,
    ONBOARDINGS_INITIALLY_ENABLED,
    ONBOARDINGS_FOR_NEW_USER,
} = UserSettingsTypes;

const NamedStorageService = MojitoCore.Services.Storage.NamedService;
const reduxInstance = MojitoCore.Services.redux;

const DateTimeUtils = MojitoCore.Base.DateTimeUtils;

/**
 * Defines the state of the user settings store.
 *
 * @typedef UserSettingsState
 *
 * @property {Array<Mojito.Services.UserSettings.types.ODDS_FORMAT>} oddsFormats - Odds formats. Represents a list of supported odd formats ('decimal', 'american', 'fractional', etc).
 * @property {Mojito.Services.UserSettings.types.ODDS_FORMAT} defaultOddsFormat - Default odds format.
 * @property {Array<Mojito.Services.UserSettings.types.ODDS_FORMAT>} enabledOddsFormats - Enabled odds format.
 * @property {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
 * @property {boolean} useLocalTime - Use local time indicator.
 * @property {boolean} isAnonymousUser - Anonymous user indicator.
 * @property {object} onboardingEnabledMap - Onboarding enabled map.
 * @property {string|undefined} timeZone - Time zone value.
 * @property {number|Mojito.Services.UserSettings.types.LOCAL_TIME} timeOffset - Time offset value.
 * @property {string|string[]} locales - An array of locales or a locale string.
 * @property {string|undefined} countryCode - Country code.
 *
 * @memberof Mojito.Services.UserSettings
 */

/**
 * The name of the user settings store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.UserSettings
 */
export const STORE_KEY = 'userSettingsStore';

const STORAGE_KEY = 'userSettings';
const userSettingsStorage = new NamedStorageService(STORAGE_KEY);

const isValidOddsFormat = format => format && ODDS_FORMATS_SUPPORTED.includes(format);
const isValidTimeOffset = offset =>
    offset === LOCAL_TIME || (!isNaN(offset) && offset >= -12 && offset <= 12);

const convertTimeOffset = offset =>
    offset === LOCAL_TIME ? DateTimeUtils.getLocalTimezoneOffset() / -1 : offset;
const propertyRetriever = (validate, propName) => source =>
    validate(source[propName]) ? source[propName] : undefined;

const getTimeOffset = propertyRetriever(isValidTimeOffset, 'timeOffset');
const getTimeZone = propertyRetriever(() => true, 'timeZone');
const getOddsFormat = propertyRetriever(isValidOddsFormat, 'oddsFormat');
const getDefaultOddsFormat = propertyRetriever(isValidOddsFormat, 'defaultOddsFormat');

// Convenience object
// {key1: value1, key2: value2} => {value1: value1, value2: value2}
const onboardingKeys = mapKeys(ONBOARDING_TYPES);

// Onboarding configuration for users who used the app but missed all onboardings
const defaultBaseOnboarding = mapValues(onboardingKeys, () => ({ passed: false }));

// Onboarding configuration for users who never used the app.
const defaultNewUserOnboarding = mapValues(ONBOARDINGS_FOR_NEW_USER, enabled =>
    enabled
        ? { passed: false }
        : {
              passed: true,
              note: 'Skipped for a new user',
          }
);

const saveToStorage = (state, key) => {
    if (!isNil(state[key])) {
        const existingData = userSettingsStorage.getItem();
        userSettingsStorage.setItem({ ...existingData, [key]: state[key] });
    }
};

const getEnabledOddsFormatsState = ({ appDefaultValues }) => {
    return appDefaultValues.oddsFormats
        ? appDefaultValues.oddsFormats.filter(isValidOddsFormat)
        : defaultConfig.oddsFormats;
};

const getDefaultOddsFormatState = ({ storageData, appDefaultValues, enabledOddsFormats }) => {
    const findEnabled = format => enabledOddsFormats.find(enabled => format === enabled);

    return (
        findEnabled(getOddsFormat(storageData)) ||
        findEnabled(getDefaultOddsFormat(appDefaultValues)) ||
        getDefaultOddsFormat(defaultConfig)
    );
};

const getDefaultDateTimeFormatState = ({ storageData, appDefaultValues }) => {
    const dateTimeFormat = appDefaultValues.dateTimeFormat || {};
    const defaultFormat = pick(defaultConfig, 'timeOffset', 'timeZone', 'locales');
    const timeZone = getTimeZone(dateTimeFormat) || getTimeZone(defaultFormat);
    const timeOffset =
        DateTimeUtils.getTimezoneOffset(timeZone) * -1 ||
        getTimeOffset(storageData) ||
        getTimeOffset(dateTimeFormat) ||
        getTimeOffset(defaultFormat);
    const { useLocalTime } = storageData;
    dateTimeFormat.useLocalTime = !isNil(useLocalTime) ? useLocalTime : timeOffset === LOCAL_TIME;
    dateTimeFormat.timeOffset = convertTimeOffset(timeOffset);
    return { ...defaultFormat, ...dateTimeFormat };
};

const getDefaultOnboardingProgressState = ({ storageData, appDefaultValues }) => {
    const appDefaultOnboarding = appDefaultValues.onboarding || {};
    // A historical note:
    // first was a version with userSettings with no onboarding,
    // then came a version with navigation onboarding,
    // now autocashout onboarding support is added.
    const onboarding =
        storageData.onboarding || appDefaultValues.newUserOnboarding || defaultNewUserOnboarding;

    // If a section for the key is missing,
    // it can be only existing user
    // which has to see onboarding for this onboarding type (or appDefaultValues if set)
    return mapValues(
        onboardingKeys,
        value => onboarding[value] || appDefaultOnboarding[value] || defaultBaseOnboarding[value]
    );
};

/**
 * User settings default config.
 *
 * @typedef defaultConfig
 * @memberof Mojito.Services.UserSettings
 */
export const defaultConfig = {
    oddsFormats: ODDS_FORMATS_SUPPORTED,
    defaultOddsFormat: ODDS_FORMAT.FRACTIONAL,
    countryCode: undefined,
    locales: [],
    timeOffset: LOCAL_TIME,
    timeZone: undefined,
};

export const INITIAL_STATE = {
    ...defaultConfig,
    onboardingEnabledMap: mapValues(onboardingKeys, value => ONBOARDINGS_INITIALLY_ENABLED[value]),
    oddsFormat: ODDS_FORMAT.FRACTIONAL,
    enabledOddsFormats: defaultConfig.oddsFormats,
    useLocalTime: true,
};

export const { reducer, actions } = createSlice({
    name: 'userSettings',
    initialState: INITIAL_STATE,
    reducers: {
        toggleOnboardingType(state, { payload }) {
            const { onboardingType, enabled } = payload;
            state.onboardingEnabledMap[onboardingType] = enabled;
        },
        updateOnboardingProgress(state, { payload: onboarding }) {
            state.onboarding = { ...state.onboarding, ...onboarding };
        },
        updateTimeOffset(state, { payload: offset }) {
            // Ignore if offset is not valid or if time zone is used.
            // Time zone setup take precedence over time offset.
            if (isValidTimeOffset(offset) && !state.timeZone) {
                state.useLocalTime = offset === LOCAL_TIME;
                state.timeOffset = convertTimeOffset(offset);
            }
        },
        updateOddsFormat(state, { payload: oddsFormat }) {
            const isEnabled = state.enabledOddsFormats.includes(oddsFormat);
            if (isValidOddsFormat(oddsFormat) && isEnabled) {
                state.oddsFormat = oddsFormat;
            }
        },
        setApplicationDefaults(state, { payload: appDefaultValues }) {
            const storageData = userSettingsStorage.getItem() || {};
            const payload = { appDefaultValues, storageData };
            const { timeOffset, locales, timeZone, useLocalTime } =
                getDefaultDateTimeFormatState(payload);
            const enabledOddsFormats = getEnabledOddsFormatsState(payload);

            Object.assign(state, {
                timeOffset,
                locales,
                timeZone,
                useLocalTime,
                enabledOddsFormats,
                oddsFormat: getDefaultOddsFormatState({ ...payload, enabledOddsFormats }),
                countryCode: storageData.countryCode || appDefaultValues.countryCode,
                onboarding: getDefaultOnboardingProgressState(payload),
                isAnonymousUser: storageData.isAnonymousUser || appDefaultValues.isAnonymousUser,
            });
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
    extraReducers: builder => {
        builder.addCase(authenticationActions.loginSuccess, (state, { payload: userInfo }) => {
            const country = userInfo?.country;
            if (country) {
                state.countryCode = country;
            }
        });
    },
});

/**
 * User settings related actions.
 *
 * @module UserSettingsActions
 * @name actions
 * @memberof Mojito.Services.UserSettings
 */

/**
 * Override the store's built in default with new defaults for this application.
 *
 * @function setApplicationDefaults
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {object} Settings - Default settings values.
 *
 * @memberof Mojito.Services.UserSettings.actions
 */

/**
 * Enable or disable specific onboarding type.
 *
 * @function toggleOnboardingType
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{ onboardingType: string, enabled: boolean }} payload - Data needed to toggle onboarding.
 *
 * @memberof Mojito.Services.UserSettings.actions
 */

/**
 * Update onboarding progress action.
 *
 * @function updateOnboardingProgress
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {object} onboardingProgressInfo - Map onboarding progress by types.
 *
 * @memberof Mojito.Services.UserSettings.actions
 */

/**
 * Update the current timeOffset.
 *
 * @function updateTimeOffset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {number|Mojito.Services.UserSettings.types.LOCAL_TIME} timeOffset - TimeOffset could be a number or string 'localTime' if system's timeOffset is used.
 *
 * @memberof Mojito.Services.UserSettings.actions
 */

/**
 * Update the current odds format.
 *
 * @function updateOddsFormat
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - The new odds format.
 *
 * @memberof Mojito.Services.UserSettings.actions
 */

/**
 * Reset action returns user settings event state to initial value.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.UserSettings.actions
 */

reduxInstance.actionListener.startListening({
    actionCreator: actions.updateOnboardingProgress,
    effect: (action, listenerApi) => {
        const onboarding = listenerApi.getState()[STORE_KEY].onboarding;
        saveToStorage({ onboarding }, 'onboarding');
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.updateTimeOffset,
    effect: (action, listenerApi) => {
        const timeOffset = listenerApi.getState()[STORE_KEY].timeOffset;
        const useLocalTime = listenerApi.getState()[STORE_KEY].useLocalTime;
        saveToStorage({ timeOffset }, 'timeOffset');
        saveToStorage({ useLocalTime }, 'useLocalTime');
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.updateOddsFormat,
    effect: (action, listenerApi) => {
        const oddsFormat = listenerApi.getState()[STORE_KEY].oddsFormat;
        saveToStorage({ oddsFormat }, 'oddsFormat');
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: (action, listenerApi) => {
        const countryCode = listenerApi.getState()[STORE_KEY].countryCode;
        saveToStorage({ countryCode }, 'countryCode');
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.reset,
    effect: () => {
        userSettingsStorage.removeItem();
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
