import { isPlainObject } from 'mojito/utils';
import TransactionsTypes from './types.js';
import storageService from 'core/services/storage/';
import Utils from 'core/utils';

const { ERROR_CODE, DOM_ERROR_NAME, ERRORS_MAP, DOM_ERRORS_MAP, CONTENT_TYPES, HEADER } =
    TransactionsTypes;

const BODY_RESOLVER = {
    [CONTENT_TYPES.JSON]: resp => resp.json(),
    [CONTENT_TYPES.PDF]: resp => resp.blob(),
};
const TEXT_BODY_RESOLVER = resp => resp.text();

/**
 * Request constructor.
 *
 * @class TransactionRequest
 * @param  {string} url - Request URL.
 * @param  {TransactionsTypes.REQUEST_METHOD} method - HTTP method.
 * @param {object} logger - Logger instance.
 * @classdesc Class offering generic functionality for requests towards transactional mojito API.
 * Operates as a wrapper around Fetch API.
 * @memberof Mojito.Core.Services.Transactions
 */
export default class TransactionRequest {
    constructor(url, method, logger) {
        this.url = url;
        this.method = method;
        this.logger = logger;
        this.headers = new Headers();
        this.description = undefined;
        this.useRawBodyResponse = false;
        this.credentials = undefined;
        this.errorHandler = undefined;
        this.abortController = new AbortController();
        this.withContentType(CONTENT_TYPES.JSON);
        this.withCorrelationHeader(true);
    }

    /**
     * Send `data` as the request body. Delegates call to fetch() api.
     *
     * @param {object} data - Data object will be passed to fetch API function.
     * @returns {Promise} Promise resolves on fetch response processed. Rejects on network error or if response has status other than 2XX.
     * Promise resolves with response <code>body.data</code> object or with raw <code>body</code> if {@link Mojito.Core.Services.Transactions.TransactionRequest#withRawBodyResponse|withRawBodyResponse} has been called during request build process.
     * In case of error promise rejects with {@link Mojito.Core.Services.Transactions.types.Error|error} object.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#send
     */
    send(data) {
        const request = new Request(this.url, {
            method: this.method,
            headers: this.headers,
            credentials: this.credentials || 'same-origin',
            body: data ? JSON.stringify(data) : null,
            signal: this.abortController.signal,
        });

        return (
            fetch(request)
                .then(response => this.resolveBody(response))
                .then(body => {
                    if (isPlainObject(body)) {
                        return this.useRawBodyResponse ? body : body.data;
                    }
                    // If not JSON we will return body as is.
                    return body;
                })
                // Here we will handle both Mojito API and network errors.
                .catch(error => {
                    const aborted = error.name === DOM_ERROR_NAME.AbortError;
                    !aborted &&
                        this.logError(`Failed to process ${this.description} response.\n`, error);
                    const isDOMError = error instanceof DOMException;
                    const errorResolver = isDOMError ? this.resolveDOMError : this.resolveAPIError;
                    const transactionError = errorResolver.bind(this)(error);
                    this.errorHandler && this.errorHandler(transactionError);
                    throw transactionError;
                })
        );
    }

    /**
     * Set credentials flag to include credentials.
     *
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withCredentials
     */
    withCredentials() {
        this.credentials = 'include';
        return this;
    }

    /**
     * Set content type.
     *
     * @param {Mojito.Core.Services.Transactions.TransactionsTypes.CONTENT_TYPES} contentType - Content type header value.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withContentType
     */
    withContentType(contentType) {
        const { CONTENT_TYPE } = HEADER;
        if (!contentType) {
            return this.deleteHeader(CONTENT_TYPE);
        }
        this.withHeader(CONTENT_TYPE, contentType);
        return this;
    }

    /**
     * Apply errors map object.
     *
     * @param {object} value - Errors map object. Where key - transactional API error status,
     * value - client error type, that it will be mapped to.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withErrorsMap
     */
    withErrorsMap(value) {
        this.customErrorsMap = value;
        return this;
    }

    /**
     * Apply errors handler callback. Will be executed on request API error with error object as parameter.
     *
     * @param {Mojito.Core.Services.Transactions.types.ErrorHandler} handler - Error handler callback.
     *
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withErrorHandler
     */
    withErrorHandler(handler) {
        this.errorHandler = handler;
        return this;
    }

    /**
     * Set header to headers object.
     *
     * @param {string} name - Header name.
     * @param {string} value - Header value.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withHeader
     */
    withHeader(name, value) {
        this.headers.set(name, value);
        return this;
    }

    /**
     * Set correlation id header to request object.
     * Can be useful for requests tracking between services.
     *
     * @param {boolean} value - True if correlation id header should be sent within request, false otherwise.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withCorrelationHeader
     */
    withCorrelationHeader(value) {
        const { CORRELATION_ID } = HEADER;
        if (!value) {
            return this.deleteHeader(CORRELATION_ID);
        }
        this.withHeader(CORRELATION_ID, Utils.generateUUID());
        return this;
    }

    /**
     * Set CSRF Token header on request object if Cookie containing token is present.
     *
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withCsrfTokenHeader
     */
    withCsrfTokenHeader() {
        const xsrfToken = storageService.getCookie('XSRF-TOKEN');
        if (xsrfToken) {
            this.withHeader(HEADER.XSRF_TOKEN, xsrfToken);
        }
        return this;
    }

    /**
     * Set raw body response flag.
     * If executed then {@link Mojito.Core.Services.Transactions.TransactionRequest#send|send} response Promise will be resolved with full response body.
     * It can be useful if caller requires response status for its processing.
     *
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withRawBodyResponse
     */
    withRawBodyResponse() {
        this.useRawBodyResponse = true;
        return this;
    }

    /**
     * Apply request description. Typically human readable text that can be used for logging.
     *
     * @param {string} value - Request description.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#withDescription
     */
    withDescription(value) {
        this.description = value;
        return this;
    }

    /**
     * Delete header from request.
     *
     * @param {string} name - Name of the header to be removed.
     * @returns {Mojito.Core.Services.Transactions.MojitoRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#deleteHeader
     */
    deleteHeader(name) {
        if (this.headers.has(name)) {
            this.headers.delete(name);
        }
        return this;
    }

    /**
     * Aborts request.
     *
     * @returns {Mojito.Core.Services.Transactions.MojitoRequest} Current instance.
     * @function Mojito.Core.Services.Transactions.TransactionRequest#abort
     */
    abort() {
        this.abortController.abort();
        return this;
    }

    logError(text, error) {
        if (!this.logger) {
            return;
        }
        if (!this.description) {
            this.logger.error(error);
            return;
        }
        this.logger.error(text, error);
    }

    resolveBody(response) {
        return new Promise((resolve, reject) => {
            const contentType = response.headers.get(HEADER.CONTENT_TYPE);
            const resolver = BODY_RESOLVER[contentType] || TEXT_BODY_RESOLVER;
            resolver(response).then(body => {
                if (response.ok) {
                    resolve(body);
                } else {
                    // If not 2XX - re-throw the error to be handled in a catch.
                    reject({ status: response.status, body });
                }
            });
        });
    }

    resolveAPIError(error) {
        const { status: httpStatus, body } = error;
        const errorsMap = { ...ERRORS_MAP, ...(this.customErrorsMap || {}) };
        let errorType = errorsMap[httpStatus];
        if (errorType) {
            return { type: errorType };
        }
        if (!body) {
            return { type: ERROR_CODE.UNKNOWN };
        }
        const { status, message } = body;
        errorType = errorsMap[status] || ERROR_CODE.UNKNOWN;
        const errorMessages = message ? [message] : undefined;
        return {
            type: errorType,
            messages: errorMessages,
        };
    }

    resolveDOMError(error) {
        const type = DOM_ERRORS_MAP[error.name] || ERROR_CODE.UNKNOWN;
        return { type, messages: [error.message] };
    }
}
