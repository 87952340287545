import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import CommonUtils from 'services/common/utils.js';
const { selectApplicationMode, selectAdditionalContext, selectContextToChannelMap } =
    MojitoCore.Services.SystemSettings.selectors;

const reduxInstance = MojitoCore.Services.redux;

/**
 * Bootstrap related selectors.
 *
 * @module BootstrapSelectors
 * @name selectors
 * @memberof Mojito.Services.Bootstrap
 */

/**
 * Selects bootstrap state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {{initStatus: Mojito.Services.Common.types.INIT_STATUS}} Mojito API init status.
 * @memberof Mojito.Services.Bootstrap.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects channel.
 *
 * @function selectChannel
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {string} Channel.
 * @memberof Mojito.Services.Bootstrap.selectors
 */
export const selectChannel = state => {
    const mode = selectApplicationMode(state);
    const additionalContext = selectAdditionalContext(state);
    const contextToChannelMap = selectContextToChannelMap(state);
    return (
        (additionalContext && contextToChannelMap[additionalContext]) ||
        CommonUtils.resolveChannel(mode)
    );
};

/**
 * Selects Mojito API initialisation status.
 *
 * @function selectInitStatus
 *
 * @param {object} [state] - Application state object.
 * @returns {Mojito.Services.Common.types.INIT_STATUS} API initialisation status.
 * @memberof Mojito.Services.Bootstrap.selectors
 */
export const selectInitStatus = state => selectState(state).initStatus;
