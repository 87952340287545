export default {
    DBXNativeAppForcedUpgradeView: {},
    Box: {},
    VBox: {
        values: {},
    },
    HBox: {
        values: {},
    },
    StaticHtmlPage: {
        meta: {
            extendable: false,
        },
        values: {
            container: {},
            textView: {
                content: {
                    style: {
                        backgroundColor: 'none',
                    },
                },
                textArea: {},
            },
        },
    },
    CurrentTime: {
        values: {
            timeStyle: {},
            labelStyle: {
                color: 'white',
            },
            containerStyle: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
    },
    IconsRow: {},
    LinksList: {},
    WidgetBonuses: {},
    SessionTimerContainer: {},
    NativeAppHostingPageView: {},
    SimpleIcon: {},
    DBX_CONFIG: {},
    CustomPage: {},
};
