import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';
const reduxInstance = MojitoCore.Services.redux;
/**
 * Selects acca bar state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Acca bar state.
 * @memberof Mojito.Modules.AccaBar.selectors
 */
export const selectState = state => {
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Check if the acca bar is allowed to be shown.
 *
 * @function isAllowedToShow
 *
 * @param {object} [state] - Application state object.
 * @returns {boolean} True if acca bar is allowed to show.
 * @memberof Mojito.Modules.AccaBar.selectors
 */
export const isAllowedToShow = state => selectState(state).allowedToShow;
