import MojitoCore from 'mojito/core';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import serviceFactory from './service/service-factory';
import { selectState } from './selectors.js';

const NamedStorageService = MojitoCore.Services.Storage.NamedService;

const reduxInstance = MojitoCore.Services.redux;
const RECENT_SEARCHES_STORAGE_KEY = 'recentSearchHistory';

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const DEFAULT_MAX_SIZE = 10;
const DEFAULT_MAX_DAYS = 30;

const recentSearchStorage = new NamedStorageService(RECENT_SEARCHES_STORAGE_KEY);

/**
 * Store keeping track of ongoing searches and their results.
 *
 * @typedef SearchState
 *
 * @property {object} search - Contains data about the latest search and its results.
 * @property {object} history - Contains data representing history of searches.
 *
 * @memberof Mojito.Services.Search
 */

/**
 * The name of the search store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Search
 */
export const STORE_KEY = 'searchStore';

const loadRecentSearches = () => {
    const parsed = recentSearchStorage.getItem(RECENT_SEARCHES_STORAGE_KEY);
    return parsed && Array.isArray(parsed) ? parsed : [];
};

/**
 * Set maximum size for the items array based on maximum size provided.
 *
 * @function limitMaxSize
 *
 * @private
 * @param {number} maxSize - Data denotes the maximum allowed size of the items array.
 * @param {object[]} items - Object storing the query text and the timestamp at which it was added to recent search.
 * @param {string} items.query - Query text.
 * @param {number} items.timestamp - Timestamp at which query text was added to recent search.
 * @returns {object[]} Items returned after applying maximum size.
 */
const limitMaxSize = (maxSize, items) => {
    return items.slice(-maxSize);
};

/**
 * Remove oldest data from items array based on the given maximum days value.
 *
 * @function limitMaxDays
 *
 * @private
 * @param {number} maxDays - Data denotes to filter out the oldest values in the items array based on the maximum days allowed.
 * @param {object[]} items - Object storing the query text and the timestamp at which it was added to recent search.
 * @param {string} items.query - Query text.
 * @param {number} items.timestamp - Timestamp at which query text was added to recent search.
 * @returns {object[]} Items returned after applying maximum days.
 */
const limitMaxDays = (maxDays, items) => {
    const now = Date.now();
    items = items.filter(({ timestamp }) => (now - timestamp) / MS_PER_DAY < maxDays);
    return items;
};

/**
 * Set maximum size for the items array and filter out older items based on maximum days provided.
 *
 * @function applyLimitMaxSizeAndMaxDays
 *
 * @private
 * @param {number} maxSize - Data denotes the maximum allowed size of the items array.
 * @param {number} maxDays - Data denotes to filter out the oldest values in the items array based on the maximum days allowed.
 * @param {object[]} items - Object storing the query text and the timestamp at which it was added to recent search.
 * @param {string} items.query - Query text.
 * @param {number} items.timestamp - Timestamp at which query text was added to recent search.
 * @returns {object[]} Items returned after applying maxSize and maxDays.
 */
const applyLimitMaxSizeAndMaxDays = (maxSize, maxDays, items) => {
    items = limitMaxSize(maxSize, items);
    return limitMaxDays(maxDays, items);
};

export const INITIAL_STATE = {
    search: { id: 0, result: {}, options: {} },
    history: {
        maxSize: DEFAULT_MAX_SIZE,
        maxDays: DEFAULT_MAX_DAYS,
        items: applyLimitMaxSizeAndMaxDays(
            DEFAULT_MAX_SIZE,
            DEFAULT_MAX_DAYS,
            loadRecentSearches()
        ),
    },
};

export const { reducer, actions } = createSlice({
    name: 'search',
    initialState: INITIAL_STATE,
    reducers: {
        configure(state, { payload }) {
            const {
                recentHistory: { maxSize, maxDays },
            } = payload;

            state.history.maxSize = maxSize;
            state.history.maxDays = maxDays;
            state.history.items = applyLimitMaxSizeAndMaxDays(
                maxSize,
                maxDays,
                state.history.items
            );
        },

        beforeSearch(state, { payload }) {
            const { id, query, languageCode } = payload;

            state.search = {
                id,
                result: { pending: true },
                options: { query, languageCode, pageNumber: 0 },
            };
        },

        deliverResult(state, { payload }) {
            const { id, result } = payload;

            if (id === state.search.id) {
                if (result.error) {
                    state.search = { id, result };
                } else {
                    const items = state.search.result.items
                        ? state.search.result.items.concat(result.items)
                        : result.items;
                    state.search = {
                        id,
                        result: { items, hasNextPage: result.hasNextPage },
                        options: state.search.options,
                    };
                }
            }
        },

        beforeSearchNext(state, { payload }) {
            const { id } = payload;

            state.search = {
                id,
                result: { ...state.search.result, pending: true },
                options: {
                    ...state.search.options,
                    pageNumber: state.search.options.pageNumber + 1,
                },
            };
        },

        addRecentSearch(state, { payload }) {
            const { query } = payload;
            let items = state.history.items;
            items = items.filter(item => item.query.toLowerCase() !== query.toLowerCase());
            if (items.length >= state.history.maxSize) {
                items = items.slice(-state.history.maxSize + 1);
            }
            items.push({ query, timestamp: Date.now() });
            state.history.items = items;
        },

        removeRecentSearch(state, { payload }) {
            const { query } = payload;
            let items = state.history.items;
            items = items.filter(item => item.query.toLowerCase() !== query.toLowerCase());
            state.history.items = items;
        },

        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Search actions.
 *
 * @class SearchActions
 * @name actions
 * @memberof Mojito.Services.Search
 */

/**
 * Configures the search store.
 *
 * @function configure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - The configuration settings.
 * @param {object} payload.recentHistory - The configuration settings for recent history.
 * @param {number} payload.recentHistory.maxSize - The maximum number of items to be stored in the history.
 * @param {number} payload.recentHistory.maxDays - The maximum number of days for retaining items in the history.
 * @memberof Mojito.Services.Search.actions
 */

/**
 * Handle updates before searching for first time.
 *
 * @function beforeSearch
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{ id: number, query: string, languageCode: string }} payload - Payload for handling updates before searching.
 * @memberof Mojito.Services.Search.actions
 */

/**
 * Deliver the search result for query or error.
 *
 * @function deliverResult
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Payload for deliver search result.
 * @param {number} payload.id - Query ID.
 * @param {object} payload.result - Search result.
 * @param {string} payload.query - Original search query.
 * @memberof Mojito.Services.Search.actions
 */

/**
 * Handle updates before searching for next results.
 *
 * @function beforeSearchNext
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{ id: number }} payload - Payload for handling updates before searching next results.
 * @memberof Mojito.Services.Search.actions
 */

/**
 * Add recent search to history.
 *
 * @function addRecentSearch
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{query: string}} payload - Payload for adding query text to recent search.
 * @memberof Mojito.Services.Search.actions
 */

/**
 * Remove recent search from history.
 *
 * @function removeRecentSearch
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{query: string}} payload - Payload for removing query text from recent search.
 * @memberof Mojito.Services.Search.actions
 */

/**
 * Reset search state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Search.actions
 */

/**
 * Init search service layer.
 *
 * @function init
 *
 * @param {Mojito.Services.Search.types.SearchConfig} config - Search config.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk.
 * @memberof Mojito.Services.Search.actions
 */
actions.init = config => () => {
    serviceFactory.init(config);
};

/**
 * Run a new search.
 *
 * @function search
 *
 * @param {string} query - Query text.
 * @param {string} languageCode - System language code.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} New search thunk.
 * @memberof Mojito.Services.Search.actions
 */
actions.search = (query, languageCode) => {
    return (dispatch, getState) => {
        const currentState = getState()[STORE_KEY];
        const id = currentState.search.id + 1;

        dispatch(actions.beforeSearch({ id, query, languageCode }));
        search(id, query, languageCode, 0, dispatch);
    };
};

/**
 * Search for next results.
 *
 * @function searchNext
 *
 * @param {string} query - Query text.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Next search thunk.
 * @memberof Mojito.Services.Search.actions
 */
actions.searchNext = query => {
    return (dispatch, getState) => {
        const currentState = getState()[STORE_KEY];

        const options = { ...currentState.search.options };
        // Dismiss if called before first search or other query was sent since or no more data available.
        if (options.query !== query || currentState.search.result.hasNextPage === false) {
            return false;
        }

        const id = currentState.search.id + 1;

        dispatch(actions.beforeSearchNext({ id }));
        search(id, query, options.languageCode, options.pageNumber, dispatch);
    };
};

/**
 * Search result.
 *
 * @function search
 *
 * @private
 * @param {number} id - Query ID.
 * @param {string} query - Query text.
 * @param {string} languageCode - System language code.
 * @param {number} pageNumber - Page number if there are more than one page.
 * @param {Function} dispatch - Redux dispatch function to trigger actions from thunk.
 */
const search = (id, query, languageCode, pageNumber, dispatch) => {
    serviceFactory
        .getService()
        .search({
            query,
            languageCode,
            pageNumber,
        })
        .then(result => {
            dispatch(actions.deliverResult({ id, query, result }));
        })
        .catch(() => dispatch(actions.deliverResult({ id, query, result: { error: true } })));
};

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(actions.addRecentSearch, actions.removeRecentSearch),
    effect: (action, listenerApi) => {
        recentSearchStorage.setItem(selectState(listenerApi.getState()).history.items);
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
