import PropTypes from 'prop-types';
import ButtonScrollPaneImpl from './button-scroll-pane.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * This component enables horizontal scrolling of child elements. Scrolling can be controlled via buttons or touch events based on configuration.
 * It also accommodates the capability to automatically bring a pre-selected element into the user's view.
 *
 * @class ButtonScrollPane
 * @memberof Mojito.Presentation.Components
 */

/**
 * `ButtonScrollPane` component prop types.
 *
 * @property {string} [class] - The className to be added to the DOM element.
 * @property {Object<string | number, React.RefObject>} [refs = {}] - Object containing RefObjects mapped to unique keys (needed for snap to work). Read more about RefObjects here: {@link https://reactjs.org/docs/refs-and-the-dom.html}.
 * @property {string|number} [selectedItemKey] - The ref key to scroll to on init (need to be used together with refs to work).
 * @property {node[]} children - The content that should be displayed.
 * @memberof Mojito.Presentation.Components.ButtonScrollPane
 */
const propTypes = {
    class: PropTypes.string,
    refs: PropTypes.objectOf(PropTypes.shape({ current: PropTypes.instanceOf(Element) })),
    selectedItemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const defaultProps = {
    refs: {},
};

export default UIView('ButtonScrollPane', ButtonScrollPaneImpl, propTypes, defaultProps);
