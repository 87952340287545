import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import Image from 'presentation/components/image/index.jsx';

export default function Notification(props) {
    const { text, children, mojitoTools } = props;
    const { config } = mojitoTools;

    return (
        <FlexPane config={config.container} class={props.class}>
            {config.icon.src && <Image config={config.icon} />}
            <Text config={config.text}>{text}</Text>
            {children}
        </FlexPane>
    );
}
