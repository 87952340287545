/*eslint no-console: "off"  */

const DEBUG = 0;
const INFO = 1;
const WARN = 2;
const ERROR = 3;
const PANIC = 4;
export const LOG_LEVEL = {
    EVERYTHING: -1,
    DEBUG,
    INFO,
    WARN,
    ERROR,
    PANIC,
};

const loggers = {};
let defaultLogLevel = LOG_LEVEL.INFO;
let logEverything = false;
const errorHooks = [];

export function Logger(namespace) {
    const level = loggers[namespace] ? loggers[namespace]._level : defaultLogLevel;
    const logger = new LoggerClass(namespace, level);
    loggers[namespace] = logger;
    return logger;
}

Logger.STYLE_DEBUG = 'color: gray; font-weight: bold';
Logger.STYLE_PROGRESS = 'color: seagreen; font-weight: bold';

export function setLogsLevelFiltering(doFiltering) {
    logEverything = !doFiltering;
}

export function setLogLevel(namespace, level) {
    let logger = loggers[namespace];
    if (!logger) {
        logger = loggers[namespace] = {};
    }
    logger._level = level;
}

/**
 * Add error hook.
 * @param fn - function first argument is namespace, rest is what user have passed
 */
export function addErrorHook(fn) {
    errorHooks.push(fn);
}

function buildArgs(namespace, messages) {
    const loggingArguments = [].slice.call(messages);
    if (typeof loggingArguments[0] === 'string') {
        loggingArguments[0] = `${namespace}: ${loggingArguments[0]}`;
    } else {
        loggingArguments.unshift(`${namespace}:`);
    }

    return loggingArguments;
}

class LoggerClass {
    constructor(namespace, level) {
        this._namespace = namespace;
        this._level = level;
    }

    isLoggingEnabled(logLevel) {
        return logEverything || this._level <= logLevel;
    }

    debug() {
        (logEverything || this._level <= DEBUG) && console.debug.apply(console, buildArgs(this._namespace, arguments));
    }

    info() {
        (logEverything || this._level <= INFO) && console.info.apply(console, buildArgs(this._namespace, arguments));
    }

    warn() {
        (logEverything || this._level <= WARN) && console.warn.apply(console, buildArgs(this._namespace, arguments));
    }

    error() {
        (logEverything || this._level <= ERROR) && console.error.apply(console, buildArgs(this._namespace, arguments));
        if (errorHooks.length !== 0) {
            for (let fn of errorHooks) fn(this._namespace, ...arguments);
        }
    }

    panic() {
        console.error.apply(console, buildArgs(this._namespace, arguments));
        if (errorHooks.length !== 0) {
            for (let fn of errorHooks) fn(this._namespace, ...arguments);
        }
    }

    /**
     * Increases indentation of subsequent lines by two spaces.
     * If one or more `label`s are provided, those are printed first without the additional indentation.
     */
    group() {
        console.group.apply(console, buildArgs(this._namespace, arguments));
    }

    /**
     * The `groupCollapsed()` function is an alias for {@link group()}.
     */
    groupCollapsed() {
        console.groupCollapsed.apply(console, buildArgs(this._namespace, arguments));
    }

    /**
     * Decreases indentation of subsequent lines by two spaces.
     */
    groupEnd() {
        console.groupEnd.apply(console, buildArgs(this._namespace, arguments));
    }

    /**
     * Starts a timer you can use to track how long an operation takes.
     */
    time() {
        console.time.apply(console, buildArgs(this._namespace, arguments));
    }

    /**
     * Stops a timer that was previously started by calling log.time().
     */
    timeEnd() {
        console.timeEnd.apply(console, buildArgs(this._namespace, arguments));
    }

    /**
     * Displays tabular data as a table
     */
    table() {
        console.table.apply(console, arguments);
    }
}
