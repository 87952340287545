import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './open-bets-slice.js';
import ServicesTypes from 'services/common/types.js';
import BetsTypes from 'services/bets/types.js';

const reduxInstance = MojitoCore.Services.redux;
const { CONTENT_STATE } = ServicesTypes;

import { isLoggedIn } from 'services/authentication/selectors.js';

const { CASHOUT_STATUS, CASHOUT_AVAILABILITY } = BetsTypes;
const lockedStatuses = new Set([
    CASHOUT_STATUS.CONFIRMATION,
    CASHOUT_STATUS.PENDING,
    CASHOUT_STATUS.ACCEPTED,
]);

/**
 * Open Bets related selectors.
 *
 * @module OpenBetsSelectors
 * @name openBetsSelectors
 * @memberof Mojito.Services.Bets
 */

/**
 * Selects bets store state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.Bets.OpenBetsState} Open Bets store state.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects data hash state.
 *
 * @function selectDataHash
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Data hash.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectDataHash = state => selectState(state).dataHash;

/**
 * Check if open bets data or error have been received.
 *
 * @function selectIsLoaded
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if open bets fetching has finished.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectIsLoaded = state => selectState(state).loadingStatus === CONTENT_STATE.AVAILABLE;

/**
 * Check if open bets fetching is in progress.
 *
 * @function selectIsPending
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if open bets fetch is in progress.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectIsPending = state => selectState(state).loadingStatus === CONTENT_STATE.PENDING;

/**
 * Selects bets.
 *
 * @function selectBets
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Bets.types.Bet>} Bets.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectBets = state => selectState(state).bets;

/**
 * Selects bet by bet ID.
 *
 * @function selectBet
 * @param {string} betId - Bet ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Bets.types.Bet|undefined} Bet.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectBet = (betId, state) => selectState(state).bets.find(bet => bet.id === betId);

/**
 * Selects bet count.
 *
 * @function selectBetCount
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {number} Bet count.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectBetCount = state => selectState(state).totalNumberOfBets;

/**
 * Selects if open bets have been requested.
 *
 * @function selectIsRequested
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if open bets have been requested.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectIsRequested = state => selectState(state).isRequested;

/**
 * Selects cashout information by bet ID.
 *
 * @function selectCashoutInfo
 * @param {string} betId - Bet ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Bets.types.CashoutInfo|undefined} Cashout info for a bet.
 * Usually contains data about bet early cashout options.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectCashoutInfo = (betId, state) => {
    const { cashoutInfos, lockedAmounts } = selectState(state);
    const info = cashoutInfos[betId];
    if (!info) {
        return;
    }

    const status = selectCashoutStatus(betId, state);
    return lockedStatuses.has(status)
        ? {
              ...info,
              amount: lockedAmounts[betId],
              amountNet: lockedAmounts[betId],
              availability: CASHOUT_AVAILABILITY.AVAILABLE,
          }
        : info;
};

/**
 * Selects cashout status.
 *
 * @function selectCashoutStatus
 * @param {string} betId - Bet ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Bets.types.CASHOUT_STATUS} Status of the bet cashout.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectCashoutStatus = (betId, state) =>
    selectState(state).cashoutStatuses[betId] || CASHOUT_STATUS.IDLE;

/**
 * Checks if cashout is allowed.
 *
 * @function selectIsCashoutAllowed
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if cashout is permitted.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectIsCashoutAllowed = state => selectState(state).allowCashout;

/**
 * Get auto cashout status for bet with specific <code>betId</code>.
 *
 * @function selectAutoCashoutStatus
 * @param {string} betId - Bet ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Bets.types.AutoCashoutStatus} Status of the auto cashout rule.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectAutoCashoutStatus = (betId, state) =>
    selectState(state).cashoutRulesStatuses[betId];

/**
 * Check if cashout offers have been requested.
 *
 * @function selectIsCashoutOffersRequested
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if cashout offere were requested.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectIsCashoutOffersRequested = state => selectState(state).isCashoutOffersRequested;

/**
 * Selects bets which can be eligible for cashout.
 *
 * @function selectCashoutableBets
 * @param {Array<string>} [betIds] - Optional bet ID list to limit the involved open bets. Otherwise, use all open bets.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Bets.types.Bet>} Bets which are eligible for cashout.
 * @memberof Mojito.Services.Bets.openBetsSelectors
 */
export const selectCashoutableBets = (betIds, state) => {
    if (
        !selectIsCashoutAllowed(state) ||
        !isLoggedIn(state) ||
        selectIsCashoutOffersRequested(state)
    ) {
        return [];
    }
    const betIdsSet = betIds ? new Set(betIds) : { has: () => true };

    return selectBets(state).filter(({ id }) => {
        if (!betIdsSet.has(id)) {
            return false;
        }
        const info = selectState(state).cashoutInfos[id];
        const status = selectState(state).cashoutStatuses[id];
        return (
            info?.availability !== CASHOUT_AVAILABILITY.UNAVAILABLE &&
            status !== CASHOUT_STATUS.ACCEPTED
        );
    });
};
