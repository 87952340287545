import MojitoCore from 'mojito/core';

const DataProvider = MojitoCore.Services.DataProvider;
const COLLECTION_NAME = 'events';

/**
 *
 * Class offering the possibility to link to specific event lists.
 *
 * @class EventProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class EventProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(eventId, onUpdate) {
        return super.subscribeToEntity(eventId, onUpdate, COLLECTION_NAME);
    }

    subscribeToEntities(eventIds, onInit, onUpdate) {
        return super.subscribeToEntities(eventIds, onInit, onUpdate, COLLECTION_NAME);
    }
}

export default new EventProvider();
