import MojitoCore from 'mojito/core';
import { createSelector } from '@reduxjs/toolkit';
import ServicesTypes from 'services/common/types.js';
import EventUtils from 'services/sports-content/events/utils.js';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import { pick } from 'mojito/utils';

const reduxInstance = MojitoCore.Services.redux;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;
const arraysAreEqual = MojitoCore.Base.objUtils.arraysAreEqual;
const { UNKNOWN } = ServicesTypes.CONTENT_STATE;

/**
 * Events store selectors.
 *
 * @class EventSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.Events
 */

/**
 * Selects events state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.Events.EventsState} The state of the events store.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects events.
 *
 * @function selectEvents
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<object>} Markets list.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectEvents = state => selectState(state).events;

/**
 * Selects markets by provided ids list.
 *
 * @callback MarketsByIdsSelector
 *
 * @param {string} marketIds - Markets ids to select.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<object>} Markets list.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */

/**
 * Selector creator function. Will create memoized selector of type {@link Mojito.Services.SportsContent.Events.selectors.MarketsByIdsSelector|MarketsByIdsSelector} to fetch markets by ids.
 * The created selector guarantees that the same array instance will be returned on each call if no input params changed.
 * Note: when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance.
 *
 * @function makeSelectMarketsByIds
 *
 * @returns {Function} Selector function.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const makeSelectMarketsByIds = () =>
    createSelector(
        [(marketIds, state) => selectMarkets(state), marketIds => marketIds],
        (markets, marketIds) => marketIds?.map(id => markets[id]).filter(Boolean),
        {
            memoizeOptions: {
                resultEqualityCheck: arraysAreEqual,
            },
        }
    );

/**
 * Selects all available markets.
 *
 * @function selectMarkets
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Map<string, object>} Markets map.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectMarkets = state => selectState(state).markets;

/**
 * Selects events loading state.
 *
 * @callback EventsStateSelector
 *
 * @param {string} eventIds - Event ids to select state for each of those.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Map<string, Mojito.Services.Common.types.CONTENT_STATE>} Events state list. Key is event id and value is loading state.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */

/**
 * Selector creator function. Will create memoized selector of type {@link Mojito.Services.SportsContent.Events.selectors.EventsStateSelector|EventsStateSelector} to fetch event states by event ids.
 * The created selector guarantees that the same object instance will be returned on each call if no input params changed.
 * Note: when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance.
 *
 * @function makeSelectEventsState
 *
 * @returns {Mojito.Services.SportsContent.Events.selectors.EventsStateSelector} Selector function.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const makeSelectEventsState = () =>
    createSelector(
        [(eventIds, state) => selectState(state).eventsState, eventIds => eventIds],
        (eventsState, eventIds) => pick(eventsState, eventIds),
        {
            memoizeOptions: {
                resultEqualityCheck: isShallowEqual,
            },
        }
    );

/**
 * Selects markets loading state.
 *
 * @callback MarketsStateSelector
 *
 * @param {string} marketIds - Market ids to select state for each of those.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Map<string, Mojito.Services.Common.types.CONTENT_STATE>} Markets state list. Key is market id and value is loading state.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */

/**
 * Selector creator function. Will create memoized selector of type {@link Mojito.Services.SportsContent.Events.selectors.MarketsStateSelector|MarketsStateSelector} to fetch market states by market ids.
 * The created selector guarantees that the same object instance will be returned on each call if no input params changed.
 * Note: when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance.
 *
 * @function makeSelectMarketsState
 *
 * @returns {Mojito.Services.SportsContent.Events.selectors.MarketsStateSelector} Selector function.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const makeSelectMarketsState = () =>
    createSelector(
        [(marketIds, state) => selectState(state).marketsState, marketIds => marketIds],
        (marketsState, marketIds) => pick(marketsState, marketIds),
        {
            memoizeOptions: {
                resultEqualityCheck: isShallowEqual,
            },
        }
    );

/**
 * Selects event item.
 *
 * @function selectEventItem
 *
 * @param {string} eventId - Event id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Event content object.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectEventItem = (eventId, state) => (selectEvents(state) || {})[eventId];

/**
 * Selects market item.
 *
 * @function selectMarket
 *
 * @param {string} marketId - Market id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Market content object.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectMarket = (marketId, state) => selectState(state).markets[marketId];

/**
 * Selects selection item.
 *
 * @function selectSelection
 *
 * @param {string} marketId - Market id.
 * @param {string} selectionId - Selection id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Selection content object.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectSelection = (marketId, selectionId, state) => {
    const market = selectMarket(marketId, state) || {};
    return EventUtils.getSelectionFromMarket(market, selectionId);
};

/**
 * Selects event item lifetime state in a store.
 *
 * @function selectEventState
 *
 * @param {string} eventId - Event id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Lifetime state of specific event.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectEventState = (eventId, state) => {
    const { eventsState } = selectState(state);
    return eventsState[eventId] || UNKNOWN;
};

/**
 * Selects market item lifetime state in a store.
 *
 * @function selectMarketState
 *
 * @param {string} marketId - Market id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Lifetime state of specific market.
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const selectMarketState = (marketId, state) => {
    const { marketsState } = selectState(state);
    return marketsState[marketId] || UNKNOWN;
};

/**
 * Subscribes to the changes of particular events state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Services.SportsContent.Events.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
