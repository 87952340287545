import PropTypes from 'prop-types';
import ButtonBarImpl from './button-bar.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * A component for visualizing a horizontal menu bar with text items.
 * If the container is too narrow for the items to fit in one line,
 * the container can expand to multiple lines.
 *
 * @class ButtonBar
 * @memberof Mojito.Presentation.Components
 */

/**
 * @typedef items
 * @type {object}
 * @property {string} label - Item label.
 * @property {string} key - Item unique key.
 * @property {boolean} newFeature - Indicates whether the item should be highlighted as a new feature.
 *
 * @memberof Mojito.Presentation.Components.ButtonBar
 */

/**
 * `ButtonBar` component prop types.
 *
 * @property {Mojito.Presentation.Components.ButtonBar.items[]} items - An array of item objects.
 * @property {string} [selectedKey] - The key of the item that should be selected initially.
 * @property {Function} onSelectionChanged - Callback function invoked when the selection changes.
 * @property {Function} [getItemHrefLink = () => {}] - Callback function to obtain the hrefLink property for SEO purposes.
 * @property {string} [newFeatureText = ''] - The text to render for "new feature" items.
 * @property {Function} [onSHowHideToggleClicked = () => {}] - Callback func that is triggered when show/hide toggle clicked.
 *
 * @memberof Mojito.Presentation.Components.ButtonBar
 */
const propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            key: PropTypes.string,
            newFeature: PropTypes.bool,
        })
    ).isRequired,
    selectedKey: PropTypes.string,
    onSelectionChanged: PropTypes.func.isRequired,
    getItemHrefLink: PropTypes.func,
    newFeatureText: PropTypes.string,
    onSHowHideToggleClicked: PropTypes.func,
};

const defaultProps = {
    newFeatureText: '',
    getItemHrefLink: noop,
    onSHowHideToggleClicked: noop,
};

export default UIView('ButtonBar', ButtonBarImpl, propTypes, defaultProps);
