import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get('ApiProvider');

/**
 * A class that provides common functions for API providers.
 *
 * @class ApiProvider
 * @memberof Mojito.Core.Services
 */
export default class ApiProvider {
    /**
     * Checks the validity of the `serverUrl` setting.
     *
     * @returns {boolean} Returns `true` if the setting is correct, `false` otherwise.
     * @function Mojito.Core.Services.ApiProvider#checkServerUrl
     */
    checkServerUrl() {
        // Sanity check
        if (!this.serverUrl) {
            log.warn('server url is missing');
        }
        return !!this.serverUrl;
    }

    /**
     * Generic handler for authentication-related errors.
     * Triggers the `AuthenticationAction.validateToken` action for errors.
     *
     * @param {string} action - Action name to added to log.
     * @param {object} err - The error object.
     * @param {object} resp - The response object.
     * @param {Function} successCallback - The callback function executed upon success.
     * @param {Function} errorCallback - The callback function executed upon error.
     * @function Mojito.Core.Services.ApiProvider#handleResponse
     */
    handleResponse(action, err, resp, successCallback, errorCallback) {
        this.handleGenericResponse(action, err, resp, successCallback, error => {
            errorCallback && errorCallback(error);
        });
    }

    /**
     * Handler for generic responses.
     *
     * @param {string} action - Action name to be added to the log.
     * @param {object} err - Error object.
     * @param {object} resp - Response object.
     * @param {Function} successCallback - Optional success callback.
     * @param {Function} errorCallback - Optional error callback.
     * @param {Function} disconnectCallback - Optional callback that will be called in case of disconnect or server unavailability. If not set, the error callback will be called.
     * @function Mojito.Core.Services.ApiProvider#handleGenericResponse
     */
    handleGenericResponse(action, err, resp, successCallback, errorCallback, disconnectCallback) {
        if (err || !resp || !resp.ok) {
            log.error(`Failed to ${action}`, err, resp);
            if (!resp && disconnectCallback) {
                // Disconnect
                disconnectCallback();
                return;
            }
            errorCallback && errorCallback(err);
        } else {
            successCallback && successCallback(resp.body);
        }
    }
}
