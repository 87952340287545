import MenuRowSkeletonImpl from './menu-row-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * The component works as a preloading component for MenuRow to show while the content is loading.
 *
 * @function MenuRowSkeleton
 *
 * @memberof Mojito.Presentation.Components
 */

export default UIView('MenuRowSkeleton', MenuRowSkeletonImpl);
