import PATHS from 'application/app/paths.js';
import RouteResolver from './route-resolver.js';

/**
 * Singleton object that keeps track of {@link Mojito.Application.RouteResolver| route resolver instance}.
 *
 * @class RouteResolverFactory
 * @name routeResolverFactory
 * @memberof Mojito.Application.Router
 */
class RouteResolverFactory {
    constructor() {
        this.resolver = new RouteResolver(PATHS);
    }
    /**
     * Route resolver setter.
     *
     * @param {Mojito.Application.AbstractRouteResolver} resolver - Route resolver object.
     *
     * @function setRouteResolver
     *
     * @memberof Mojito.Application.Router.routeResolverFactory
     */
    setRouteResolver(resolver) {
        if (resolver) {
            this.resolver = resolver;
        }
    }

    /**
     * Route resolver getter.
     *
     * @function getRouteResolver
     *
     * @returns {Mojito.Application.AbstractRouteResolver} Route resolver instance.
     *
     * @memberof Mojito.Application.Router.routeResolverFactory
     */
    getRouteResolver() {
        return this.resolver;
    }
}

export default new RouteResolverFactory();
