import {Logger} from '#core/utils/logger.js';
import {fetchInternalResource} from '#core/utils/utils.js';
import {merge} from '#core/utils/config-utils.js';
import {PortalApi} from '#core/portal/portal-api-handler.js';

import {convertStringToSVGHtmlNode} from './spritemap-storage.utils.js';
import {SPORT_SPRITES_MAPPING} from './sport-sprites-mapping.js';

const SESSION_STORAGE_CUSTOM_ICONS_KEY = 'dbx.app-custom-icons';

class SpriteMapStorageImpl {
    constructor() {
        this.logger = Logger('SpriteMapStorage');
        this.spriteMapContainerNodeId = 'SVGSpriteMap';
        this.spritePrefix = 'svgsprite-';
        this.sportIdToSpritesInitialMapping = SPORT_SPRITES_MAPPING;
        this.sportIdToSpritesMapping = {...this.sportIdToSpritesInitialMapping};
        this.customSprites = {};
        this.spriteIdToSpriteHTMLNodeMap = {}; // here we store all sprites HTML nodes for convenient usage
        this.spriteIdToDefaultSpriteHTMLNodeMap = {}; // here we store copies of default sprites HTML nodes
    }

    getSpriteIdWithPrefix(spriteId) {
        return this.spritePrefix + spriteId;
    }

    getCustomSprites() {
        return this.customSprites;
    }

    getCurrentSpriteIdBySportId(sportId) {
        return this.sportIdToSpritesMapping[sportId];
    }

    getDefaultSpriteIdBySportId(sportId) {
        return this.sportIdToSpritesInitialMapping[sportId];
    }

    getDefaultSpriteHTMLNodeBySpriteId(spriteId) {
        return this.spriteIdToDefaultSpriteHTMLNodeMap[spriteId];
    }

    removeCustomSprite(customSpriteId) {
        delete this.customSprites[customSpriteId];
    }

    async init(spriteContainerParentNode, customSportIconsMapping = {}, configPromise) {
        if (this.initialized) return;

        if (!spriteContainerParentNode || !spriteContainerParentNode.appendChild) {
            this.logger.error('SpriteMap container HTML node is required!');

            return;
        }

        this.sportIdToSpritesMapping = merge(this.sportIdToSpritesMapping, customSportIconsMapping);

        try {
            const fetchedSpriteMapResponse = await fetchInternalResource(`images/spritemap.svg?${GIT_HASH}`, {
                credentials: 'include',
            });
            const fetchedSpriteMapData = await fetchedSpriteMapResponse.text();

            this.spriteMapContainerNode = document.createElement('div');
            this.spriteMapSVGNode = new DOMParser().parseFromString(fetchedSpriteMapData, 'image/svg+xml').firstChild;

            // here we fill spriteIdToSpriteHTMLNodeMap map with data inside spriteMapSVGNode
            this.spriteMapSVGNode.childNodes.forEach(
                svgChildNode =>
                    // need to use sprite id as a key without prefix, it's easier for us
                    (this.spriteIdToSpriteHTMLNodeMap[svgChildNode.id.replace(this.spritePrefix, '')] = svgChildNode)
            );

            // here we clone HTML nodes per sport to have fallback icons while we are fetching external custom icons
            Object.keys(customSportIconsMapping).forEach(sportId => {
                const sportNewSpriteId = 'sport-' + sportId;
                const sportNewSpriteIdWithPrefix = this.getSpriteIdWithPrefix(sportNewSpriteId);
                const sportCurrentSpriteNode =
                    this.spriteIdToSpriteHTMLNodeMap[this.sportIdToSpritesInitialMapping[sportId]] ||
                    this.spriteIdToSpriteHTMLNodeMap['default'];
                const sportCurrentSpriteNodeClone = sportCurrentSpriteNode.cloneNode(true);
                const sportCurrentSpriteNodeCloneTitleNode = sportCurrentSpriteNodeClone.childNodes[0];

                if (sportCurrentSpriteNodeCloneTitleNode.nodeName === 'title')
                    sportCurrentSpriteNodeCloneTitleNode.innerHTML = sportNewSpriteIdWithPrefix;

                sportCurrentSpriteNodeClone.setAttribute('id', sportNewSpriteIdWithPrefix);

                this.spriteIdToSpriteHTMLNodeMap[sportNewSpriteId] = sportCurrentSpriteNodeClone;
                this.spriteMapSVGNode.appendChild(sportCurrentSpriteNodeClone);
            });

            this.spriteMapContainerNode.append(this.spriteMapSVGNode);
            this.spriteMapContainerNode.id = this.spriteMapContainerNodeId;
            this.spriteMapContainerNode.style.setProperty('width', '0');
            this.spriteMapContainerNode.style.setProperty('height', '0');
            this.spriteMapContainerNode.style.setProperty('overflow', 'hidden');

            spriteContainerParentNode.appendChild(this.spriteMapContainerNode);

            this.initialized = true;

            return this.fetchAndReplaceDefaultSpritesToCustom(configPromise);
        } catch (err) {
            this.logger.error(err.message);
        }
    }

    async fetchAndReplaceDefaultSpritesToCustom(configPromise) {
        if (this.customSpritesFetched || !this.initialized) return;

        let customExternalSprites = JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_CUSTOM_ICONS_KEY) || '{}');

        try {
            // naming confusing ((
            const cmsPostInitConfig = await PortalApi.getPostInitConfig(configPromise);

            customExternalSprites = merge(customExternalSprites, cmsPostInitConfig?.sprites || {});
        } catch (err) {
            this.logger.error(err.message);
        }

        this.customSprites = {
            ...customExternalSprites.sport,
            ...customExternalSprites.promotion,
            ...customExternalSprites.vertical,
        };

        Object.keys(this.customSprites).forEach(customSpriteId => {
            try {
                const spriteHTMLNode = this.spriteIdToSpriteHTMLNodeMap[customSpriteId];
                const customSpriteParsedSymbolNode = convertStringToSVGHtmlNode(this.customSprites[customSpriteId]);

                // here we save default html node for this sprite to have ability to show it in Mozaic when user chooses 'Revert to default'
                this.spriteIdToDefaultSpriteHTMLNodeMap[customSpriteId] = spriteHTMLNode.cloneNode(true);

                spriteHTMLNode.setAttribute('viewBox', customSpriteParsedSymbolNode.getAttribute('viewBox'));
                spriteHTMLNode.innerHTML = customSpriteParsedSymbolNode.innerHTML;
            } catch (err) {
                this.logger.error(
                    `Error during fetching custom sprite with id ${customSpriteId}. This sprite was ignored. Reason: ${err.message}`
                );
            }
        });

        this.customSpritesFetched = true;
    }

    dispose() {
        if (!this.initialized) {
            this.logger.error('Cannot dispose spritemap storage because it is not initialized yet');

            return;
        }

        this.sportIdToSpritesMapping = {...this.sportIdToSpritesInitialMapping};
        this.customSprites = {};
        this.spriteIdToSpriteHTMLNodeMap = {};
        this.spriteIdToDefaultSpriteHTMLNodeMap = {};

        if (this.spriteMapContainerNode) this.spriteMapContainerNode.remove();
        if (this.spriteMapSVGNode) this.spriteMapSVGNode.remove();

        this.spriteMapSVGNode = undefined;
        this.spriteMapContainerNode = undefined;

        this.customSpritesFetched = false;
        this.initialized = false;
    }
}

export const SpriteMapStorage = new SpriteMapStorageImpl();
