import PropTypes from 'prop-types';
import EventCardsCarousel from './event-cards-carousel.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Requests card promotions data, filters them and renders carousel presenter.
 * Render cards views by type (match, outright, banner etc.), fill up carousel with fallback cards if needed.
 *
 * @function EventCardsCarousel
 * @name module
 *
 * @memberof Mojito.Modules.EventCardsCarousel
 */

/**
 * EventCardsCarousel prop types.
 *
 * @property {string} pageId - The id of the applicable page where event cards will be shown. Example: 'homepage', 'tennis', 'basketball'.
 * @property {string} [sportId] - Sport id will be used to render sport specific fallback event card.
 *
 * @memberof Mojito.Modules.EventCardsCarousel.module
 */
const propTypes = {
    pageId: PropTypes.string.isRequired,
    sportId: PropTypes.string,
};

export default UIView('EventCardsCarousel', EventCardsCarousel, propTypes);
