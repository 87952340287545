import PropTypes from 'prop-types';
import TextInputImpl from './text-input.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * Component that handles text input from a user. It can be configured to be one of the following types:
 * <ul>
 * <li>text (Default)</li>
 * <li>number</li>
 * <li>tel</li>
 * <li>password (Note! '{@link Mojito.Presentation.Components.PasswordInput|PasswordInput}' is the better choice in most cases)</li>
 * </ul>
 * It is the responsibility of a parent component to keep the input value as a state and pass it down to TextInput as a prop.
 *
 * @class TextInput
 * @memberof Mojito.Presentation.Components
 */

/**
 * Set focus
 * <br>
 * Can be accessed by passing a ref prop to the TextInput component.
 *
 * @example <caption>How to set focus</caption>
 * import React from 'react';
 * import MojitoCore from 'mojito/core';
 * import MojitoPresentation from 'mojito/presentation';
 * const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
 * const TextInput = MojitoPresentation.Components.TextInput;
 *
 * class Parent extends UIViewImplementation {
 *   constructor(reactComponent) {
 *     super(reactComponent);
 *     this.textInput = React.createRef();
 *   }
 *
 *   setFocus() {
 *     this.textInput.current.focus();
 *   }
 *
 *   render() {
 *     return (
 *       <TextInput value="" inputElementRef={this.textInput} />
 *     );
 *   }
 * }
 *
 * @function Mojito.Presentation.Components.TextInput#focus
 */

/**
 * @param {string} value - The current value of the input element.
 * @param {React.SyntheticEvent} event - The React-wrapped event object representing the HTML input event. See {@link https://reactjs.org/docs/events.html}.
 *
 * @callback Mojito.Presentation.Components.TextInput.changeCallback
 */

/**
 * Prop types for TextInput.
 *
 * @property {string} value - Value in the TextInput.
 * @property {string} [placeholderValue] - Placeholder text displayed when input value is empty.
 * @property {boolean} [disabled] - If true, the text input field is disabled.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onChange = () => {}] - Callback function executed for onchange events.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onFocus = () => {}] - Callback function executed when the text input field gains focus.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onBlur = () => {}] - Callback function executed when the text input field loses focus.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onKeyDown = () => {}] - Callback function executed for onKeyDown events.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onKeyUp = () => {}] - Callback function executed for onKeyUp events.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onKeyEnter = () => {}] - Callback function executed for onKeyEnter events.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onSelect = () => {}] - Callback function to handle input changes for onselect events.
 * @property {string} [class] - A set of class names assigned to the TextInput's root element.
 * @property {string} [inputId] - An id that may be assigned to the TextInput's root element.
 * @property {boolean} [hasError] - If true, represents that the text input field contains an error.
 * @property {object} [inputParams = {}] - Extra parameters applied to the input HTML element.
 * @property {Function | object} [inputElementRef] - Reference to the rendered element.
 *
 * @memberof Mojito.Presentation.Components.TextInput
 */
const propTypes = {
    value: PropTypes.string.isRequired,
    placeholderValue: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyEnter: PropTypes.func,
    onSelect: PropTypes.func,
    class: PropTypes.string,
    inputId: PropTypes.string,
    hasError: PropTypes.bool,
    inputParams: PropTypes.objectOf(PropTypes.string),
    inputElementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const defaultProps = {
    onChange: noop,
    onFocus: noop,
    onBlur: noop,
    onKeyDown: noop,
    onKeyUp: noop,
    onKeyEnter: noop,
    onSelect: noop,
    inputParams: {},
};

export default UIView('TextInput', TextInputImpl, propTypes, defaultProps);
