import PropTypes from 'prop-types';
import FallbackEventCardImpl from './fallback-event-card.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view component that displays a fallback event card.
 *
 * @class FallbackEventCard
 * @memberof Mojito.Presentation.Components.EventCards
 */

/**
 * `FallbackEventCard` component prop types.
 *
 * @property {string} headerTitle - The title for the fallback card's header.
 * @property {string} contentText - The text content for the fallback card.
 * @property {string} [sportId] - The sport id. Can be used to resolve a background image.
 * @property {object} [onClickData] - Data of object type passed to the onClick callback.
 * @property {Function} [onClick = ()=>{}] - Callback function triggered on clicking the event card area.
 * @memberof Mojito.Presentation.Components.EventCards.FallbackEventCard
 */
const propTypes = {
    headerTitle: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    sportId: PropTypes.string,
    onClickData: PropTypes.object,
    onClick: PropTypes.func,
};

const defaultProps = {
    onClick: noop,
};

export default UIView('FallbackEventCard', FallbackEventCardImpl, propTypes, defaultProps);
