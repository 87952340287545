import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';

export default function FormattedLabelWithTotalOdds(props) {
    const { mojitoTools, odds, isTotalOddsApplicable } = props;
    const { config, stringResolver } = mojitoTools;
    const oddsString =
        odds ||
        stringResolver.resolveString('$FORMATTED_LABEL_WITH_TOTAL_ODDS.TOTAL_ODDS_NOT_APPLICABLE');

    if (!isTotalOddsApplicable) {
        return null;
    }

    return (
        <FlexPane config={config.container}>
            <Text config={config.label}>
                {stringResolver.resolveString('$FORMATTED_LABEL_WITH_TOTAL_ODDS.TOTAL_ODDS')}
            </Text>
            <Text class={'ta-Odds'} config={config.value}>
                {oddsString}
            </Text>
        </FlexPane>
    );
}
