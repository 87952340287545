import { createContext } from 'react';
import { noop } from 'mojito/utils';

const NULL_CONTEXT = {
    registerRequest: noop,
    removeRequest: noop,
    requestContent: noop,
    hasAwaitingRequest: () => true,
    discardContent: noop,
};

/**
 * Mojito content preloader context object created by <code>React.createContext()</code>.
 *
 * @typedef ContentPreloaderContext
 * @memberof Mojito.Core.Presentation
 */
const ContentPreloaderContext = createContext(NULL_CONTEXT);

ContentPreloaderContext.NULL_CONTEXT = NULL_CONTEXT;

export default ContentPreloaderContext;
