import GenericBetFundsInfo from 'presentation/components/bet-funds/generic-info/index.jsx';
import CurrencyHelper from 'presentation/formatters/currency/index.js';
import BettingHelper from 'presentation/formatters/betting/index.js';

const PayoutInfo = ({ payout, previousPayout, labelKey, currencyCode, showIcon, mojitoTools }) => {
    const { stringResolver, config } = mojitoTools;

    const label = stringResolver.resolveString(labelKey);
    const notAvailableLabel = stringResolver.resolveString(config.notAvailableValuel10nKey);

    const defaultLabel =
        isNaN(payout) || payout < 0
            ? notAvailableLabel
            : CurrencyHelper.formatCurrency(0, currencyCode);

    return (
        <GenericBetFundsInfo
            class="ta-payout"
            config={config.betInfo}
            label={label}
            valueClass="ta-PayoutValue"
            value={BettingHelper.formatPayout(payout, defaultLabel, currencyCode)}
            previousValue={
                previousPayout ? CurrencyHelper.formatCurrency(previousPayout, currencyCode) : ''
            }
            showIcon={showIcon}
        />
    );
};

export default PayoutInfo;
