export const SPORT_SPRITES_MAPPING = {
    ALL: 'all',
    ATHL: 'athletics',
    GREY: 'greyhound_racing',
    HORS: 'horse_racing',
    MMAF: 'boxing',
    MTCY: 'motorcycling',
    POLI: 'politics',
    TVEN: 'entertainment',
    american_football: 'american_football',
    athletics: 'athletics',
    aussie_rules: 'australian_football',
    australian_football: 'australian_football',
    badminton: 'badminton',
    bandy: 'bandy',
    baseball: 'baseball',
    basketball: 'basketball',
    basketball_3x3: 'basketball_3x3',
    beach_soccer: 'soccer',
    beach_volleyball: 'beach_volleyball',
    bowls: 'bowls',
    bowling: 'bowling',
    boxing: 'boxing',
    cricket: 'cricket',
    curling: 'curling',
    cycling: 'cycling',
    darts: 'darts',
    entertainment: 'entertainment',
    e_sports: 'e_sports',
    esports: 'e_sports',
    field_hockey: 'field_hockey',
    hockey: 'field_hockey',
    floorball: 'floorball',
    football: 'soccer',
    football_1x1: 'soccer_1x1',
    futsal: 'futsal',
    formula_1: 'formula_1',
    gaa_football: 'default',
    gaelic_games: 'gaelic_games',
    golf: 'golf',
    greyhound_racing: 'greyhound_racing',
    gymnastics: 'gymnastics',
    handball: 'handball',
    horse_racing: 'horse_racing',
    ice_hockey: 'ice_hockey',
    jaialai: 'jaialai',
    martial_arts: 'martial_arts',
    motorsport: 'motorsport',
    motorcycling: 'motorcycling',
    motor_cross: 'motor_cross',
    padel: 'padel',
    pesapallo: 'pesapallo',
    politics: 'politics',
    rugby_league: 'rugby_league',
    rugby_union: 'rugby_union',
    snooker: 'snooker',
    soccer: 'soccer',
    speedway: 'speedway',
    squash: 'squash',
    table_tennis: 'table_tennis',
    tennis: 'tennis',
    tzoker: 'default',
    virtuals: 'virtuals',
    virtual_basketball: 'basketball',
    virtual_tennis: 'tennis',
    virtual_soccer: 'virtual_soccer',
    virtual_football: 'virtual_soccer',
    virtual_soccer_inplay: 'virtual_soccer_inplay',
    soccer_inplay: 'virtual_soccer_inplay',
    virtual_football_inplay: 'virtual_soccer_inplay',
    football_inplay: 'virtual_soccer_inplay',
    virtual_ice_hockey_inplay: 'virtual_ice_hockey_inplay',
    ice_hockey_inplay: 'virtual_ice_hockey_inplay',
    virtual_greyhound_racing: 'greyhound_racing',
    virtual_horse_racing: 'horse_racing',
    virtual_speedway: 'speedway',
    virtual_motorcycling: 'speedway',
    virtual_cycling: 'cycling',
    virtual_trotting: 'trotting',
    trotting: 'trotting',
    // --- IDs used in virtual sport streaming:
    BASKETBALL: 'basketball',
    TENNIS: 'tennis',
    FOOTBALL_MATCH: 'soccer',
    FOOTBALL_INPLAY: 'virtual_soccer_inplay',
    ICEHOCKEY_INPLAY: 'virtual_ice_hockey_inplay',
    DOG_FLAT: 'greyhound_racing',
    HORSE_FLAT: 'horse_racing',
    MOTOR_CYCLE_SPEEDWAY: 'speedway',
    CYCLE_TRACK_KEIRIN: 'cycling',
    HORSE_TROTTING: 'trotting',
    // ---
    volleyball: 'volleyball',
    waterpolo: 'waterpolo',
    winter_sports: 'winter_sports',
    winter_sport: 'winter_sports',
    olympics: 'olympics',
    olympic: 'olympics',
    rowing: 'default',
    swimming: 'default',
    modern_pentathlon: 'default',
    archery: 'default',
    equestrian: 'default',
    fencing: 'default',
    judo: 'judo',
    sailing: 'default',
    surfing: 'surfing',
    taekwondo: 'default',
    triathlon: 'default',
    wrestling: 'default',
    gymnastic: 'default',
    weightlifting: 'default',
    canoeing: 'default',
    diving: 'default',
    shooting: 'default',
    mountain_biking: 'default',
    karate: 'default',
    skateboarding: 'skateboarding',
    sport_climbing: 'default',
    bobsleigh: 'bobsleigh',
    figure_skating: 'iceskating',
    freestyle_skiing: 'skiing',
    luge: 'bobsleigh',
    short_track: 'iceskating',
    skeleton: 'bobsleigh',
    snowboard: 'snowboarding',
    speed_skating: 'iceskating',
};
