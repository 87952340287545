import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('abstract-bonus-service');

/**
 * Abstract bonus service to be implemented by different integrations.
 * Note: All methods must be implemented in an asynchronous way and return promise.
 *
 * @class AbstractBonusService
 * @abstract
 * @memberof Mojito.Services.Bonus
 */
export default class AbstractBonusService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @function Mojito.Services.Bonus.AbstractBonusService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Get filtered campaign bonuses for the currently logged-in user.
     *
     * @param {Mojito.Services.Bonus.types.BONUS_FILTER} filter - Campaign filter option.
     * @returns {Promise} Promise.
     * @function Mojito.Services.Bonus.AbstractBonusService#getCampaigns
     */
    // eslint-disable-next-line no-unused-vars
    getCampaigns(filter) {
        log.error('getCampaign must be implemented by a concrete subclass!');
        return Promise.reject();
    }

    /**
     * Get free bet bonuses for the currently logged-in user.
     *
     * @param {string} currency - User currency.
     * @param {Mojito.Services.Bonus.types.BONUS_FILTER} filter - Free bets filter option.
     * @returns {Promise} Promise.
     * @function Mojito.Services.Bonus.AbstractBonusService#getFreeBets
     */
    // eslint-disable-next-line no-unused-vars
    getFreeBets(currency, filter) {
        log.error('getFreeBets must be implemented by a concrete subclass!');
        return Promise.reject();
    }

    /**
     * Add free bet to user account.
     *
     * @param {string} freeBetCode - Free bet code.
     * @returns {Promise} Promise.
     * @function Mojito.Services.Bonus.AbstractBonusService#addFreeBet
     */
    // eslint-disable-next-line no-unused-vars
    addFreeBet(freeBetCode) {
        log.error('addFreeBet must be implemented by a concrete subclass!');
        return Promise.reject();
    }
}
