import GlobalLinksImpl from './global-links.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view for displaying global links.
 *
 * @class GlobalLinks
 * @memberof Mojito.Presentation.Components
 */

export default UIView('GlobalLinks', GlobalLinksImpl);
