import GenericBetFundsInfo from 'presentation/components/bet-funds/generic-info/index.jsx';
import CurrencyHelper from 'presentation/formatters/currency/index.js';

const TaxInfo = ({ value, isPotential, hasDivider, currencyCode, mojitoTools }) => {
    const { stringResolver, config } = mojitoTools;
    const label = isPotential
        ? stringResolver.resolveString('$BET_FUNDS.POTENTIAL_TAX')
        : stringResolver.resolveString('$BET_FUNDS.TAX');
    const divider = hasDivider ? stringResolver.resolveString('$BET_FUNDS.DIVIDER') : '';

    return (
        <GenericBetFundsInfo
            class="ta-TaxInfo"
            config={config.betInfo}
            label={`${label}${divider}`}
            valueClass={isPotential ? 'ta-PotentialTaxValue' : 'ta-TaxValue'}
            value={CurrencyHelper.formatCurrency(value, currencyCode)}
        />
    );
};

export default TaxInfo;
