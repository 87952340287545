/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {Array}           The HSL representation
 */
export function rgbToHsl(r, g, b) {
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    let h,
        s,
        l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return [h, s, l];
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export function hslToRgb(h, s, l) {
    function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    }

    let r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

const parsedColorsCache = new Map();
/**
 * Parses string representation of color
 * available formats: rgb(0,0,0), rgba(0,0,0,1), #000, #000f, #000000, #00000000
 * returns [r,g,b,a] array
 *
 * @param   {string}    color   String representation of color
 * @return  {Array}             The RGBA representation. Null if input is invalid
 */
export function parseColor(color) {
    if (!color) return null;
    let result;
    result = parsedColorsCache.get(color);
    if (result !== undefined) {
        return result;
    }

    let len = color.length;
    if (len > 9) {
        // 'rgb()' notation
        let parts;
        let [r, g, b, a] = (parts = color.split(','));
        len = parts.length;
        if (len < 3 || len > 4 || (color[0] !== 'r' && color[0] !== 'R')) return null;

        result = [
            parseInt(r[3].toUpperCase() === 'A' ? r.slice(5) : r.slice(4)), // 'rgb' or 'rgba' ?
            parseInt(g),
            parseInt(b),
            a ? parseFloat(a) : -1,
        ];
        parsedColorsCache.set(color, result);
        return result;
    }

    // hex notation
    if (color[0] !== '#' || (len !== 4 && len !== 5 && len !== 7 && len !== 9)) {
        // cannot parse
        parsedColorsCache.set(color, null);
        return null;
    }

    let c;
    if (len === 4 || len === 5) {
        // adapt to 6 or 8 digit
        color =
            // no hash #
            color[1] +
            color[1] +
            color[2] +
            color[2] +
            color[3] +
            color[3] +
            // alpha ?
            (len > 4 ? color[4] + color[4] : '');

        c = parseInt(color, 16);
    } else {
        color = color.slice(1);
        c = parseInt(color, 16);
    }

    if (isNaN(c)) {
        // cannot parse
        parsedColorsCache.set(color, null);
        return null;
    }

    len = color.length; // may be only 6 or 8 digit

    if (len === 8) {
        result = [(c >> 24) & 255, (c >> 16) & 255, (c >> 8) & 255, Math.round((c & 255) / 0.255) / 1000];
    } else {
        result = [c >> 16, (c >> 8) & 255, c & 255, -1];
    }

    parsedColorsCache.set(color, result);
    return result;
}

/**
 * Search and replaces all color occurrences in source string.
 * Mostly needed to modify (darken/lighten) colors in gradient backgrounds
 * @param str {string} Source string
 * @param fn {Function} Callback function where to pass all found string occurrences. Colors are replaced with return value
 * @returns {string} Resulting string
 */
export function replaceColors(str, fn) {
    let slices = [],
        result;

    while ((result = /(rgba?\([\d\s.,]*\)|#[a-f0-9]{3,8})/i.exec(str))) {
        slices.push(str.substr(0, result.index));
        slices.push(fn(result[0]));
        str = str.substr(result.index + result[0].length);
    }
    slices.push(str);
    return slices.join('');
}

function toHex(c) {
    const val = c.toString(16);
    return val.length === 1 ? '0' + val : val;
}

/**
 * Shades color lighten or darker depends on sign of 'amount' parameter.
 * It searches for all color occurrences in the colorStr (for example is colorStr is a gradient)
 * If 'amount' is negative then darker color, if positive then lighten
 * Available formats of source color: rgb(0,0,0), rgba(0,0,0,1), #000, #000f, #000000, #00000000
 * returns string representation of modified color
 *
 * @param   {string}  colorStr  String representation of source color
 * @param   {number}  amount    Number between [-100, 100] in percent. Adds this amount to color lightness
 * @return  {string}            String representation of modified color (always 6-8 digit HEX format)
 */
export function lightenDarkenColor(colorStr, amount) {
    if (!amount) {
        return colorStr;
    }

    return replaceColors(colorStr, color => {
        const c = parseColor(color);
        if (!c) return null;

        let [h, s, l] = rgbToHsl(c[0], c[1], c[2]);
        l = (l * 100 + amount) / 100;
        if (l < 0) l = 0;
        if (l > 1) l = 1;
        let [r, g, b] = hslToRgb(h, s, l);

        if (c[3] >= 0) return `#${toHex(r) + toHex(g) + toHex(b) + toHex(Math.round(c[3] * 255))}`;
        else return `#${toHex(r) + toHex(g) + toHex(b)}`;
    });
}

/**
 * Shades color lighten or darker depending on lightness of source color.
 * It searches for all color occurencies in the colorStr (for example is colorStr is a gradient)
 * Available formats of source color: rgb(0,0,0), rgba(0,0,0,1), #000, #000f, #000000, #00000000
 * returns string representation of modified color
 *
 * @param   {string}  colorStr  String representation of source color
 * @param   {number}  amount    Number between [-100, 100] in percent
 * @return  {string}            String representation of modified color
 */
export function autoLightenDarkenColor(colorStr, amount) {
    amount = Math.abs(amount);
    if (isLightColor(colorStr)) {
        amount = -amount;
    }
    return lightenDarkenColor(colorStr, amount);
}

const isLightColorCache = new Map();

/**
 * Returns true if lightness of input color is greater than 50%
 * @param colorStr {string} Color to check
 * @param fallbackValue {boolean} Fallback value if color is not light nor light
 * @returns {*|boolean} Boolean flag or fallbackValue
 */
export function isLightColor(colorStr, fallbackValue) {
    if (!colorStr || colorStr === 'transparent' || colorStr === 'none') return fallbackValue;
    if (isLightColorCache.has(colorStr)) {
        return isLightColorCache.get(colorStr);
    }
    const luma = luminance(colorStr, -1);
    if (luma < 0) return fallbackValue;

    const result = luma > 0.5;
    isLightColorCache.set(colorStr, result);
    return result;
}

export function getContextByColor(colorStr, defaultValue) {
    return isLightColor(colorStr, defaultValue) ? 'ON_LIGHT' : 'ON_DARK';
}

const K_RED = 0.2126;
const K_GREEN = 0.7152;
const K_BLUE = 0.0722;
const GAMMA = 2.4;

const lumaCache = new Map();
/**
 * Luminance of color
 * Uses formula from https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 * @param colorValue {String} Color value
 * @param fallback {number} Fallback value if color is invalid
 * @return {number} Luminance in [0..1]. Returns fallback if color is invalid
 */
export function luminance(colorValue, fallback = 0) {
    let result = lumaCache.get(colorValue);
    if (result !== undefined) return result;

    const rgb = parseColor(colorValue);
    if (!rgb) return fallback;

    const a = rgb.map(v => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
    });
    result = a[0] * K_RED + a[1] * K_GREEN + a[2] * K_BLUE;

    lumaCache.set(colorValue, result);
    return result;
}

const contrastCache = new Map(); // Map of Maps

/**
 * Returns contrast ration between two colors
 * @param color1 {string}
 * @param color2 {string}
 * @return {number} ratio in range [1..21]
 */
export function contrast(color1, color2) {
    let contrastsToColor1 = contrastCache.get(color1);
    if (!contrastsToColor1) {
        contrastsToColor1 = new Map();
        contrastCache.set(color1, contrastsToColor1);
    }
    let result = contrastsToColor1.get(color2);
    if (result !== undefined) return result;
    //-------- Do the actual calculation
    const lum1 = luminance(color1);
    const lum2 = luminance(color2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    result = (brightest + 0.05) / (darkest + 0.05);
    //--------
    contrastsToColor1.set(color2, result);
    return result;
}

const bestContrastColorCache = new Map();

/**
 * Choose color that has min contrast but larger that specified ratio.
 * If best color is not found, will return color with maximum contrast
 * @param chosenColorsStr {string} List of colors to choose separated by pipe '|'
 * @param baseColorStr {string}
 * @param minRatio {number}
 * @return {string}
 */
export function chooseMinContrast(chosenColorsStr, baseColorStr, minRatio = 4.5) {
    const cacheKey = `min: ${chosenColorsStr} on ${baseColorStr}/${minRatio}`;
    let result = bestContrastColorCache.get(cacheKey);
    if (result !== undefined) return result;

    // ------ Do the actual calculation
    let bestContrastRatio = 99999;
    let currContrastRatio;
    const chosenColors = chosenColorsStr.split('|');
    for (let colorStr of chosenColors) {
        currContrastRatio = contrast(baseColorStr, colorStr);
        if (currContrastRatio >= minRatio && currContrastRatio < bestContrastRatio) {
            bestContrastRatio = currContrastRatio;
            result = colorStr;
        }
    }
    if (!result) {
        // Bad case :(
        // Didn't find desired contrast ratio. Lets fallback and choose at least something with max contrast
        bestContrastRatio = 0;
        for (let colorStr of chosenColors) {
            currContrastRatio = contrast(baseColorStr, colorStr);
            if (currContrastRatio > bestContrastRatio) {
                bestContrastRatio = currContrastRatio;
                result = colorStr;
            }
        }
    }
    // ------
    bestContrastColorCache.set(cacheKey, result);
    return result;
}

/**
 * Choose max contract color
 * @param chosenColorsStr {string} List of colors to choose separated by pipe '|'
 * @param baseColorStr {string}
 * @return {any}
 */
export function chooseMaxContrast(chosenColorsStr, baseColorStr) {
    const cacheKey = `max: ${chosenColorsStr} on ${baseColorStr}`;
    let result = bestContrastColorCache.get(cacheKey);
    if (result !== undefined) return result;

    // ------ Do the actual calculation
    let bestContrastRatio = 99999;
    let currContrastRatio;
    const chosenColors = chosenColorsStr.split('|');

    bestContrastRatio = 0;
    for (let colorStr of chosenColors) {
        currContrastRatio = contrast(baseColorStr, colorStr);
        if (currContrastRatio > bestContrastRatio) {
            bestContrastRatio = currContrastRatio;
            result = colorStr;
        }
    }

    // ------
    bestContrastColorCache.set(cacheKey, result);
    return result;
}

/**
 * Choose first color in the list that has contrast higher or equal than specified ratio.
 * If best color is not found, will return color with maximum contrast
 * @param chosenColorsStr {string} List of colors to choose separated by pipe '|'
 * @param baseColorStr {string}
 * @param minRatio {number}
 * @return {string}
 */
export function chooseFirstWithContrast(chosenColorsStr, baseColorStr, minRatio = 4.5) {
    const cacheKey = `first: ${chosenColorsStr} on ${baseColorStr}/${minRatio}`;
    let result = bestContrastColorCache.get(cacheKey);
    if (result !== undefined) return result;

    // ------ Do the actual calculation
    let currContrastRatio;
    const variants = chosenColorsStr.split('|');

    for (let colorStr of variants) {
        currContrastRatio = contrast(baseColorStr, colorStr);
        if (currContrastRatio >= minRatio) {
            bestContrastColorCache.set(cacheKey, colorStr);
            return colorStr;
        }
    }

    // Bad case :(
    // Didn't find desired contrast ratio. Lets fallback and choose at least something with max contrast
    let bestContrastRatio = 0;
    for (let colorStr of variants) {
        currContrastRatio = contrast(baseColorStr, colorStr);
        if (currContrastRatio > bestContrastRatio) {
            bestContrastRatio = currContrastRatio;
            result = colorStr;
        }
    }
    bestContrastColorCache.set(cacheKey, result);
    return result;
}

/**
 *
 * @param color {string} Input color
 * @param amount {number} Amount to reduce saturation, in percentage [1..100]
 * @return {*|string} RGB color
 */
export function desaturate(color, amount = 100) {
    if (!color) return color;

    let rgb = parseColor(color);
    if (!rgb) return color;

    let hsl = rgbToHsl(rgb[0], rgb[1], rgb[2]);
    hsl[1] = Math.max(0, hsl[1] - Math.abs(amount) / 100);
    rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
    return `#${toHex(rgb[0]) + toHex(rgb[1]) + toHex(rgb[2])}`;
}

/**
 * Set alpha value of the provided color
 * @param color {string}
 * @param opacity {number}
 * @return {string}
 */
export function setAlpha(color, opacity) {
    if (!color) return color;

    const colorValue = parseColor(color);
    if (!colorValue) return color;

    return `#${toHex(colorValue[0]) + toHex(colorValue[1]) + toHex(colorValue[2]) + toHex(Math.round(opacity * 255))}`;
}

const isProbablyBGImageCache = new Map();
/**
 * Checks if provided values is probably background image
 * @param value {string} - Background CSS property value
 * @returns {boolean}
 */
export function isProbablyBGImage(value) {
    let result;
    result = isProbablyBGImageCache.get(value);
    if (result !== undefined) {
        return result;
    }

    result =
        value &&
        (value.includes('-gradient(') || // {value} is gradient, like "linear-gradient(to bottom, #333, #fff)"
            value.includes('url(') || // {value} is likely an image path, like "url(./images/icon.png)"
            value.includes('image-set(')); // {value} is likely an image set, like "image-set(image1.jpg" 1x, "image2.jpg" 2x)"

    isProbablyBGImageCache.set(value, result);
    return result;
}

/**
 * @param value {string} Background-color of element
 * @param prefix {string} Context prefix if we need to specify it
 * @returns {object} Object with proper background value
 * */
export function resolveBackground(value, prefix = '') {
    if (!value) return {};

    if (prefix) prefix = `_${prefix}_`;

    if (value === 'none') return {[`${prefix}backgroundColor`]: 'transparent'};

    if (isProbablyBGImage(value)) return {[`${prefix}backgroundImage`]: value};

    return {[`${prefix}backgroundColor`]: value};
}

const colorsRegex = /(?:#|0x)(?:[a-f0-9]{3,4}|[a-f0-9]{6}|[a-f0-9]{8})\b|(?:rgb|hsl)a?\([^)]*\)/gi;

/**
 * Returns correct CSS color for any input string.
 * If input is not color, returns 'none'
 * @param value {string} Input color
 * @returns {*|string} Same color, or 'none' if invalid
 */
export function resolveColor(value = '') {
    const result = String(value).match(colorsRegex) || [];
    return result.length > 0 ? result[0] : 'none';
}

/**
 * Flop (flip vertically) shadow. Usually it drops to the bottom. This function makes it to drop to the top
 * @param shadowStr {string} CSS representation of the shadow
 * @return {string} Same CSS shadow but mirrored vertically
 */
export function flopShadow(shadowStr) {
    function flop(value) {
        return value.startsWith('-') ? value.slice(1) : '-' + value;
    }

    if (!shadowStr || shadowStr === 'none') return shadowStr;
    if (shadowStr.startsWith('rgb')) {
        return shadowStr;
    }
    const parts = shadowStr.split(/\s/);
    if (parts.length < 3) return shadowStr;

    if (parts[0] === 'inset') {
        parts[2] = flop(parts[2]);
    } else {
        parts[1] = flop(parts[1]);
    }

    return parts.join(' ');
}

if (process.env.NODE_ENV === 'development') {
    window.DBX = {...window.DBX, contrastCache, bestContrastColorCache};
}
