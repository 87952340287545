import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { isAnyOf } from '@reduxjs/toolkit';
import { actions as routerActions } from 'application/stores/router/slice.js';
import { actions as cookieConsentActions } from 'core/services/cookie-consent/slice.js';

const reduxInstance = MojitoCore.Services.redux;
const { routeChange } = routerActions;
const { serviceFactory } = MojitoCore.Services.Performance;
const { selectUserName } = MojitoServices.UserInfo.selectors;

/**
 * Class to be used in UI components to emit performance metrics.
 *
 * @class PerformanceEmitter
 * @memberof Mojito.Application
 */
export default class PerformanceEmitter {
    constructor() {
        // Do not listen for fired actions when NULL_SERVICE is kept
        if (serviceFactory.isActive()) {
            reduxInstance.actionListener.startListening({
                actionCreator: routeChange,
                effect: ({ payload }, { getState }) => {
                    const service = serviceFactory.getService();
                    const userName = selectUserName(getState());
                    service.navigate(payload, userName);
                },
            });

            reduxInstance.actionListener.startListening({
                matcher: isAnyOf(cookieConsentActions.giveConsent, cookieConsentActions.reset),
                effect: () => {
                    const service = serviceFactory.getService();
                    service.activate();
                },
            });
        }
    }
    /**
     * Emits a performance metric.
     *
     * @param {string} type - Performance metric type.
     * @param {*} payload - Data of any type to be used in the performance report.
     * @function Mojito.Application.PerformanceEmitter#emitPerformanceMetric
     */
    emitPerformanceMetric(type, payload) {
        const service = serviceFactory.getService();
        service.report(type, payload);
    }
}
