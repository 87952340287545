/**
 * Betradar types.
 *
 * @class BetradarTypes
 * @readonly
 * @name types
 * @memberof Mojito.Presentation.Components.BetradarMatchTracker
 */
export default class BetradarMatchTrackerTypes {}

/**
 * @typedef STATE
 * @property {string} NOT_INITIALIZED - BetradarMatchTracker is not initialized.
 * @property {string} INITIALIZING - BetradarMatchTracker is initializing.
 * @property {string} INITIALIZED - BetradarMatchTracker successfully renders the third-party widget.
 * @property {string} UNAVAILABLE - BetradarMatchTracker is not available.
 * @memberof Mojito.Presentation.Components.BetradarMatchTracker.types
 */
BetradarMatchTrackerTypes.STATE = {
    NOT_INITIALIZED: 'NOT_INITIALIZED',
    INITIALIZING: 'INITIALIZING',
    INITIALIZED: 'INITIALIZED',
    UNAVAILABLE: 'UNAVAILABLE',
};

/**
 * Types for the functions available on the SIR API, documented on https://widgets.sir.sportradar.com/docs/SIR.html.
 *
 * @typedef BETRADAR_API_METHODS
 * @property {string} ADD_WIDGET - Add a new widget.
 * @property {string} REMOVE_WIDGET - Remove a widget.
 * @property {string} UPDATE_WIDGET - Update an existing widget.
 * @memberof Mojito.Presentation.Components.BetradarMatchTracker.types
 */
BetradarMatchTrackerTypes.BETRADAR_API_METHODS = {
    ADD_WIDGET: 'addWidget',
    REMOVE_WIDGET: 'removeWidget',
    UPDATE_WIDGET: 'updateWidget',
};

/**
 * Types for the onTrack function described on https://widgets.sir.sportradar.com/docs/Widgets.html.
 *
 * @typedef BETRADAR_ONTRACK_TYPES
 * @property {string} DATA_CHANGE - Widget gets new data.
 * @property {string} ODDS_CLICK - User clicks on an odds button.
 * @property {string} SOCIAL_SHARE - User clicks on social share button.
 * @property {string} LICENSE_ERROR - Widget returns a licensing error.
 * @memberof Mojito.Presentation.Components.BetradarMatchTracker.types
 */
BetradarMatchTrackerTypes.BETRADAR_ONTRACK_TYPES = {
    DATA_CHANGE: 'data_change',
    ODDS_CLICK: 'odds_click',
    SOCIAL_SHARE: 'social_share',
    LICENSE_ERROR: 'license_error',
};
