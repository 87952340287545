import React from 'react';
import {connect} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoApplication from 'mojito/application';

import appHeaderConfig from 'mojito/core/generated/configs/_application-header.js';

const authenticationSelectors = MojitoServices.Authentication.selectors;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const MainNavigationBar = MojitoApplication.MainNavigationBar;

MojitoCore.Services.Config.configRegistry.add(appHeaderConfig);

class DBXCompactHeaderImpl extends UIViewImplementation {
    render() {
        if (this.config.mainNavigationBar.hide) {
            return null;
        }

        const {loginTime} = this.props;

        return (
            <MainNavigationBar
                config={
                    this.config.layout === 'normal'
                        ? this.config.normalLayout.mainNavigationBar
                        : this.config.compactLayout.mainNavigationBar
                }
                loginTime={loginTime}
            />
        );
    }
}

const mapStateToProps = state => ({loginTime: authenticationSelectors.selectLoginTime(state)});

export const DBXCompactHeader = connect(mapStateToProps)(DBXCompactHeaderImpl);
