import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';
import LoaderSuspenseImpl from './loader-suspense.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component that shows loader element until isContentPending property is set to true.
 * Guarantees loader presence for a certain minimum period of time which is applicable through config.
 * This can be used to avoid loader flickering in case isContentPending reset to false in very short time.
 * Note: by default component supports only one time loader appearance per component life cycle which means that it reacts on one change of isContentPending flag from true to false - at this stage the loader will be hidden and content will show up.
 * In order to show loader each time isContentPending is set to true, the unique contentHash argument should be provided on each new loading cycle.
 *
 * @function LoaderSuspense
 * @memberof Mojito.Presentation.Components
 */

/**
 * LoaderSuspense prop types.
 *
 * @property {boolean} [isContentPending = false] - True if content is still pending (fetching from backend) and loader is shown.
 * @property {*} [contentHash] - Content hash used to identify the content type change and force loader to be shown again.
 * Provide this property if component suppose to render different types of content during its life cycle.
 * @property {node} loader - Loader element, will be shown until isContentPending is set to true.
 * @property {node} [children] - Children. Will be rendered as a main content once isContentPending is set to false and loader timeout delay is over if configured.
 * @property {Function} [onShowContent = () => {}] - Arbitrary callback that will be run on content shown.
 *
 * @memberof Mojito.Presentation.Components.LoaderSuspense
 */
const propTypes = {
    isContentPending: PropTypes.bool,
    contentHash: PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    loader: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    onShowContent: PropTypes.func,
};

const defaultPropTypes = {
    isContentPending: false,
    onShowContent: noop,
};

export default UIView('LoaderSuspense', LoaderSuspenseImpl, propTypes, defaultPropTypes);
