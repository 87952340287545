import PropTypes from 'prop-types';
import EventListItemImpl from './event-list-item.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Event list internal view representing an event as a list item.
 * The list item supports both horizontal and vertical market layouts.
 * The layout of the list item is highly responsive and adjusts based
 * on the available width.
 *
 * @class EventListItem
 * @memberof Mojito.Modules.EventList
 */

/**
 * EventList prop types.
 *
 * @property {string} [eventId] - The event item id. Used to load the event item.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from content. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 * @property {Function} [onClick = ()=>{}] - Callback function triggered when the item is clicked.
 * @property {Function} [onDataLoad] - Callback function triggered when the market group is loaded.
 * @property {string} [hrefLink] - A string to be set in the `hrefLink` prop of the `<Button>` component (for SEO purpose only).
 * @property {boolean} [isGameLineMode = false] - Flag indicating whether the item should present markets in the form of a game line.
 * @property {Array<Mojito.Services.EventGroups.types.MarketOption>} [marketOptions = []] - Array of market options. These will be associated with either `marketLines` or `event.marketLines` objects to identify which markets to load and present.
 * @property {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} [marketLines] - A map of market lines where the key is the line type and the value is the actual market line.
 * If this property is present, it will be used to identify markets to load and present. Typically, this field will not be empty when the component is rendering a CMS coupon.
 *
 * @memberof Mojito.Modules.EventList.EventListItem
 */
const propTypes = {
    eventId: PropTypes.string,
    shouldRequestData: PropTypes.bool,
    onClick: PropTypes.func,
    onDataLoad: PropTypes.func,
    hrefLink: PropTypes.string,
    isGameLineMode: PropTypes.bool,
    marketOptions: PropTypes.array,
    marketLines: PropTypes.object,
};

const defaultProps = {
    isGameLineMode: false,
    shouldRequestData: true,
    onClick: noop,
    onDataLoad: noop,
    marketOptions: [],
};

export default UIView('EventListItem', EventListItemImpl, propTypes, defaultProps);
