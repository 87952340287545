import { createSelector } from '@reduxjs/toolkit';
import { pick } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import ServicesTypes from 'services/common/types.js';

const reduxInstance = MojitoCore.Services.redux;
const { UNKNOWN } = ServicesTypes.CONTENT_STATE;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

/**
 * Event groups store selectors.
 *
 * @class EventGroupsSelectors
 * @name selectors
 * @memberof Mojito.Services.EventGroups
 */

/**
 * Selects event groups state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.EventGroups.EventGroupsState} The state of the event groups store.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select a single event group.
 *
 * @function selectEventGroup
 *
 * @param {string} eventGroupId - Id of event group.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array} Array of groups in event group.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const selectEventGroup = (eventGroupId, state) => selectEventGroups(state)[eventGroupId];

/**
 * Select event groups.
 *
 * @function selectEventGroups
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Event groups map. Key is event group id and value is array of groups.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const selectEventGroups = state => selectState(state).eventGroups;

/**
 * Select event groups by ids.
 *
 * @callback EventGroupsByIdsSelector
 *
 * @param {Array} groupIds - Group IDs to select.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Map<string, Array<object>>} Event groups map. Key is event group id and value is array of groups.
 * @memberof Mojito.Services.EventGroups.selectors
 */

/**
 * Selector creator function. Will create memoized selector of type {@link Mojito.Services.EventGroups.selectors.EventGroupsByIdsSelector|EventGroupsByIdsSelector} to fetch event groups by group ids.
 * The created selector guarantees that the same object instance will be returned on each call if no input params changed.
 * Note: when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance.
 *
 * @function makeSelectEventGroupsByIds
 *
 * @returns {Mojito.Services.EventGroups.selectors.EventGroupsByIdsSelector} Selector function.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const makeSelectEventGroupsByIds = () =>
    createSelector(
        [(groupIds, state) => selectEventGroups(state), groupIds => groupIds],
        (eventGroups, groupIds) => pick(eventGroups, groupIds),
        {
            memoizeOptions: {
                resultEqualityCheck: isShallowEqual,
            },
        }
    );

/**
 * Select event group names.
 * Name is typically present on content coming from CMS and not part of event group object and therefore stored seperately from event groups.
 *
 * @function selectEventGroupNames
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Event group names map. Key is event group id and value is event group name.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const selectEventGroupNames = state => selectState(state).eventGroupNames;

/**
 * Select event group name.
 * Name is typically present on content coming from CMS and not part of event group object and therefore stored seperately from event groups.
 *
 * @function selectEventGroupName
 *
 * @param {string} eventGroupId - Id of event group.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Event group name.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const selectEventGroupName = (eventGroupId, state) =>
    selectEventGroupNames(state)[eventGroupId];

/**
 * Select event group market options.
 * This info, typically, defines the presence of market selectors or game line switchers for a coupon.
 *
 * @function selectEventGroupMarketOptions
 *
 * @param {string} eventGroupId - Id of event group.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.EventGroups.types.MarketOption>} Array of market options for specified event group id.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const selectEventGroupMarketOptions = (eventGroupId, state) =>
    selectState(state).marketOptions[eventGroupId];

/**
 * Selects event group item lifetime state in a store.
 *
 * @function selectEventGroupState
 *
 * @param {string} eventGroupId - Events group id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Lifetime state of specific event group.
 * @memberof Mojito.Services.EventGroups.selectors
 */
export const selectEventGroupState = (eventGroupId, state) => {
    const { eventGroupsState } = selectState(state);
    return eventGroupsState[eventGroupId] || UNKNOWN;
};
