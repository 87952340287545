import MojitoServices from 'mojito/services';
import QuickLinks from './view/index.jsx';
import { useSelector } from 'react-redux';

const { selectBetCount } = MojitoServices.Bets.openBetsSelectors;

export default function QuickLinksController() {
    const betCount = useSelector(state => selectBetCount(state));
    const notifications = { hasOpenBets: betCount > 0 };
    return <QuickLinks notifications={notifications} />;
}
