import MojitoPresentation from 'mojito/presentation';

const { Button, FlexPane, Image, Text } = MojitoPresentation.Components;

export default function RecentSearch({
    mojitoTools: { config },
    items,
    onItemClick,
    onItemRemoveClick,
}) {
    // Store handlers to variables, do not create a function in renderItem
    const onClick = getHandler(onItemClick);
    const onRemoveClick = getHandler(onItemRemoveClick);

    return <FlexPane config={config.container}>{items.map(renderItem)}</FlexPane>;

    function renderItem(item) {
        return (
            <FlexPane config={config.item} key={item} class={'ta-recentSearchItem'}>
                <Image config={config.itemIcon} />
                <Button
                    config={config.searchButton}
                    onClickData={item}
                    onClick={onClick}
                    class={'ta-recentSearchButton'}
                >
                    <Text config={config.itemText}>{item}</Text>
                </Button>
                <Button
                    config={config.removeButton}
                    onClickData={item}
                    onClick={onRemoveClick}
                    class={'ta-recentSearchRemoveButton'}
                >
                    <Image config={config.removeIcon} />
                </Button>
            </FlexPane>
        );
    }
}

function getHandler(callback) {
    return (ev, item) => callback(item);
}
