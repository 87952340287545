import { pick, isEmpty } from 'mojito/utils';
import EventCardsTypes from 'modules/event-cards/types.js';

/**
 * Utility functions associated with the event cards carousel module.
 *
 * @class EventCardsCarouselUtils
 * @name utils
 * @memberof Mojito.Modules.EventCardsCarousel
 */
export default class EventCardsCarouselUtils {
    /**
     * Converts promotion object into a card.
     *
     * @param {Mojito.Services.Promotions.types.Promotion} promotion - Promotion that should be represented as a card.
     *
     * @returns {Mojito.Modules.EventCards.types.Cards} Promotion card.
     * @function Mojito.Modules.EventCardsCarousel.convertToCard
     */
    static convertToCard(promotion) {
        const resolveCard = promotion.imgUrl ? resolveBannerCard : resolveEventCard;
        return {
            ...pick(promotion, 'id', 'displayOrder'),
            ...resolveCard(promotion),
        };
    }

    /**
     * Enrich with fallback cards if total amount of provided cards is smaller than minCardsNumber.
     * This method does not mutate cards list. The fallback cards will be added to in the end of a list.
     *
     * @param {Array<Mojito.Modules.EventCards.types.Card>} cards - Cards to enrich.
     * @param {number} minCardsNumber - Minimum required cards number. This property is used to make a decision if fallback cards should be added and how many of them.
     * @param {Array<Mojito.Modules.EventCards.types.Card>} fallbackCards - Fallback cards to be used.
     *
     * @returns {Array<Mojito.Modules.EventCards.types.Card>} Cards with a fallback cards or original cards list if not fallback cards were added.
     * @function Mojito.Modules.EventCardsCarousel.enrichWithFallbackCards
     */
    static enrichWithFallbackCards(cards, minCardsNumber, fallbackCards) {
        if (isEmpty(cards)) {
            return cards;
        }
        const missingCardsNumber = minCardsNumber - cards.length;
        const fallbackCardsNumber = Math.min(missingCardsNumber, fallbackCards.length);
        return fallbackCardsNumber > 0
            ? [...cards, ...fallbackCards.slice(0, fallbackCardsNumber)]
            : cards;
    }
}

const resolveEventCard = promotion => {
    const { event, displayOrder } = promotion;
    return event
        ? {
              type: event.market?.selection ? EventCardsTypes.CARD_TYPE.SELECTION : event.eventType,
              displayOrder: displayOrder,
              content: {
                  eventId: event.id,
                  marketId: event.market?.marketId,
                  marketName: event.market?.marketName,
                  selectionId: event.market?.selection?.selectionId,
                  selectionName: event.market?.selection?.selectionName,
                  ...pick(event, 'gameLine', 'marketLines'),
              },
          }
        : {};
};

const resolveBannerCard = promotion => {
    const { imgUrl, target, displayOrder } = promotion;
    return {
        type: EventCardsTypes.CARD_TYPE.BANNER,
        displayOrder: displayOrder,
        content: {
            imgUrl,
            ...pick(target, 'type', 'url', 'openInNewTab'),
        },
    };
};
