/**
 * Class defining generic item list data descriptors.
 *
 * @class ItemListDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.ItemList
 */
export default class ItemListDataDescriptor {
    /**
     * Factory method for creating a generic  descriptor.
     *
     * @param {string} listId - The ID of the list.
     *
     * @returns {{dataType: string}} Returns a generic descriptor.
     *
     * @function Mojito.Services.ItemList.descriptor#create
     * @static
     */
    static create(listId) {
        return {
            dataType: ItemListDataDescriptor.DATA_TYPES.ITEM_LIST,
            listId: listId,
        };
    }
}

/**
 * System-unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} ITEM_LIST - Describes the item list data descriptor type.
 * @memberof Mojito.Services.ItemList.descriptor
 */
ItemListDataDescriptor.DATA_TYPES = {
    ITEM_LIST: 'item-list',
};
