import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import {Bonuses} from 'core/application/modules/bonuses/bonuses';

const {dispatch} = MojitoCore.Services.redux.store;

const {types: UserInfoTypes, actions: UserInfoActions} = MojitoServices.UserInfo;
const {utils: BonusFreeBetsUtils} = MojitoServices.BonusFreeBets;

const WITHDRAWABLE = 'withdrawable';
const SPORTS_BONUS = 'sportsBonus';
const SPORTS_TOKEN = 'sportsToken';
const SPORTS_TOTAL = 'sportsTotal';

export function calculateBalances({isUserLoggedIn, userInfo, activeFreeBets}) {
    const result = [];
    const {balances, currency} = userInfo || {};
    const addBalanceType = (amount, type) => {
        result.push({
            balance: {
                amount: '' + amount,
                currencyCode: currency,
            },
            balanceType: type,
        });
    };
    if (!isUserLoggedIn || !balances) {
        addBalanceType(0, WITHDRAWABLE);
        addBalanceType(0, SPORTS_BONUS);
        addBalanceType(0, SPORTS_TOKEN);
        addBalanceType(0, SPORTS_TOTAL);
    } else {
        let doCalculateFreebets =
            !Bonuses.useIMSFreebetBalance && balances[UserInfoTypes.BALANCE_TYPES.FREE_BET] === undefined;
        const freebetsAmount = doCalculateFreebets
            ? BonusFreeBetsUtils.calculateTotalFreeBetsAmount(activeFreeBets) // calculate based on freebet items from store. Store may have outdated freebet items
            : balances[UserInfoTypes.BALANCE_TYPES.FREE_BET];

        addBalanceType(balances.withdrawable || 0, WITHDRAWABLE);
        addBalanceType(balances.bonus || 0, SPORTS_BONUS);
        addBalanceType(freebetsAmount || 0, SPORTS_TOKEN);
        addBalanceType((balances.withdrawable || 0) + (balances.bonus || 0) + (freebetsAmount || 0), SPORTS_TOTAL);
    }

    return result;
}

export function balancesAreEqual(a, b) {
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
        if (
            a[i].balanceType !== b[i].balanceType ||
            a[i].balance.amount !== b[i].balance.amount ||
            a[i].balance.currencyCode !== b[i].balance.currencyCode
        ) {
            return false;
        }
    }
    return true;
}

// Put balances into UserInfo store, balances has the same format as SportsbookAPIBalancesStore.getBalances
export function updateBalances(balances) {
    const result = Object.create(null);

    (balances || []).forEach(balance => {
        switch (balance.balanceType) {
            case WITHDRAWABLE:
                result[UserInfoTypes.BALANCE_TYPES.WITHDRAWABLE] = Number(balance.balance.amount) || 0;
                break;
            case SPORTS_BONUS:
                result[UserInfoTypes.BALANCE_TYPES.BONUS] = Number(balance.balance.amount) || 0;
                break;
            case SPORTS_TOKEN:
                result[UserInfoTypes.BALANCE_TYPES.FREE_BET] = Number(balance.balance.amount) || 0;
                break;
        }
    });

    dispatch(UserInfoActions.updateBalance(result));
}

/**
 * Adapt and store user balances that coming from IMS OpenAPI.
 * Both withdrawable and bonus balances must be specified.
 * @param message32010 {object} - message directly from OpenAPI
 */
// To start retrieving balances, client must be subscribed to balance updates
// See doc: https://ims.ptdev.eu/apis/openapi/services/AuthenticationService/operations/StartWindowSession.html
export function handle32010message(message32010) {
    // -- Adopt balances message
    if (message32010.ID !== BALANCE_UPDATE_ID) {
        return; // it is not balance update message
    }

    const {balances} = message32010.data;
    const data = {};

    balances.forEach(balanceItem => {
        const {balanceType: imsBalanceType} = balanceItem;
        const balanceType = BALANCE_TYPES_MAP[imsBalanceType];
        if (!balanceType) return;

        // Ignore freebet balance updates from IMS (OpenAPI) when freebet polling enabled. In such a case, the freebet balance calculated based on polled freebets.
        // This is because the IMS freebet balance updates were unstable in the past. Once it's fixed, Sportsbook could disable the freebet balance polling on it's side, and rely on IMS.
        if (balanceType === UserInfoTypes.BALANCE_TYPES.FREE_BET && !Bonuses.useIMSFreebetBalance) return;

        data[balanceType] = balanceItem.balance.amount;
    });

    if (Object.keys(data).length === 0) {
        return; // Do not override balance if nothing to update
    }

    dispatch(UserInfoActions.updateBalance(data));
}

const BALANCE_TYPES_MAP = {
    /** Withdrawable balance */
    sportsbook_gaming_balance: UserInfoTypes.BALANCE_TYPES.WITHDRAWABLE,
    /** Bonus balance */
    sportsbook_bonus_balance: UserInfoTypes.BALANCE_TYPES.BONUS,
    /** FreeBet balance */
    sportsbook_freebet_balance: UserInfoTypes.BALANCE_TYPES.FREE_BET,
};

const BALANCE_UPDATE_ID = 32010; // NotifyBalanceChangeNotification message ID
