import GenericBetFundsInfo from 'presentation/components/bet-funds/generic-info/index.jsx';
import CurrencyHelper from 'presentation/formatters/currency/index.js';
import { isNumber } from 'mojito/utils';

const StakeTaxInfo = ({ value, hasDivider, currencyCode, mojitoTools }) => {
    const { stringResolver, config } = mojitoTools;

    if (!isNumber(value)) {
        return null;
    }

    const label = stringResolver.resolveString('$BET_FUNDS.STAKE_TAX');
    const divider = hasDivider ? stringResolver.resolveString('$BET_FUNDS.DIVIDER') : '';

    return (
        <GenericBetFundsInfo
            class="ta-StakeTax"
            config={config.betInfo}
            label={`${label}${divider}`}
            valueClass="ta-StakeTaxValue"
            value={CurrencyHelper.formatCurrency(value, currencyCode)}
        />
    );
};

export default StakeTaxInfo;
