import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import GenericMarketContentImpl from './generic-market-content.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Module that displays market lines content for {@link Mojito.Services.SportsContent.MarketGroups.types.AGGREGATED_MARKET_TYPE.GENERIC|GENERIC}
 * aggregated market type.
 *
 * @class GenericMarketContent
 * @memberof Mojito.Modules.AggregatedMarketPane
 */

/**
 * GenericMarketContent prop types.
 *
 * @property {Mojito.Services.SportsContent.Events.types.AggregatedMarket} aggregatedMarket - Aggregated market object.
 * @property {object} eventItem - Event item object.
 * @property {boolean} [showEachWayTerms = true] - True to show each way terms label above selections.
 * @property {boolean} [showSelectionHeaders = true] - True to show selection headers for markets types and categories that have corresponding config in layoutDescriptors.
 *
 * @memberof Mojito.Modules.AggregatedMarketPane.GenericMarketContent
 */
const propTypes = {
    aggregatedMarket: PropTypes.object.isRequired,
    eventItem: PropTypes.object.isRequired,
    showEachWayTerms: PropTypes.bool,
    showSelectionHeaders: PropTypes.bool,
};

const defaultProps = {
    showEachWayTerms: true,
    showSelectionHeaders: true,
};

export default UIView('GenericMarketContent', GenericMarketContentImpl, propTypes, defaultProps);
