import MojitoCore from 'mojito/core';
import Text from 'presentation/components/text/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import { isEmpty } from 'mojito/utils';
import Popover from 'presentation/components/popover/index.jsx';

const { dispatch } = MojitoCore.Services.redux.store;
const intentActions = MojitoCore.Intents.actions;

const EarlyPayoutIndicator = props => {
    const { rules, mojitoTools } = props;
    const { config, stringResolver } = mojitoTools;

    const linkText = stringResolver.resolveString('$EARLY_PAYOUT_INDICATOR.LINK_TEXT');

    return (
        <Popover
            config={config.popover}
            class="ta-earlyPayoutIndicator"
            isDisabledButton={isEmpty(rules)}
            shouldShowPopup={rules.length > 0}
            signpostContent={
                <Text config={config.text}>
                    {stringResolver.resolveString('$EARLY_PAYOUT_INDICATOR.TEXT')}
                </Text>
            }
        >
            <Content rules={rules} linkText={linkText} config={config} />
        </Popover>
    );
};

const Content = props => {
    const { config, linkText } = props;
    return (
        <>
            <Rules rules={props.rules} config={config} />
            {config.intent && (
                <Button
                    config={config.linkButton}
                    onClick={() =>
                        dispatch(
                            intentActions.publishIntent(config.intent.type, config.intent.data)
                        )
                    }
                >
                    <Text config={config.linkText}>{linkText}</Text>
                </Button>
            )}
        </>
    );
};

const Rules = props => {
    const { rules, config } = props;
    return (
        <FlexPane config={config.rulesContainer}>
            {rules.map(rule => {
                return (
                    <Text key={rule.id} config={config.ruleText}>
                        {rule.text}
                    </Text>
                );
            })}
        </FlexPane>
    );
};

export default EarlyPayoutIndicator;
