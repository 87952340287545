/**
 * Search related types.
 *
 * @class SearchTypes
 * @name types
 * @memberof Mojito.Services.Search
 */
export default class SearchTypes {}

/**
 * Search record class: event, sport etc. Should correspond to backend result types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Search.types
 */
SearchTypes.RESULT_TYPE = {
    EVENT: 'EVENT',
    TYPE: 'TYPE',
    RACE_MEETING: 'RACE_MEETING',
    SPORT: 'SPORT',
};

/**
 * Object defines search parameters.
 *
 * @typedef SearchData
 * @type {object}
 * @property {number} id - Query id.
 * @property {string} query - Query string.
 * @property {number} pageNumber - Page number, zero-based.
 * @property {string} languageCode - System language code.
 *
 * @memberof Mojito.Services.Search.types
 */

/**
 * Config object used to initialise search service layer.
 *
 * @typedef SearchConfig
 * @type {object}
 * @property {Mojito.Services.Search.SearchAbstractService} service - Search service implementation.
 *
 * @memberof Mojito.Services.Search.types
 */

/**
 * Object defines search state.
 *
 * @typedef SearchState
 * @type {object}
 * @property {object} search - An object which stores search data.
 * @property {number} search.id - Search id.
 * @property {object} search.result - An object which stores search result.
 * @property {object} search.options - An object which contains property query, pageNumber and languageCode used for the last searc.
 * @property {object} history - An object with maxSize, maxDays, and items properties, which contains details of the recent search histories.
 * @memberof Mojito.Services.Search.types
 */

/**
 * Defines the structure for a search result.
 *
 * @typedef SearchResult
 * @type {object}
 * @property {object} items - An object containing the results returned from a search operation.
 * @property {boolean} hasNextPage - A flag indicating if there are more pages or subsequent sets of results. If true, there are more results.
 * @property {boolean} pending - A flag indicating if the results are being fetched. If true, the fetch operation is in progress.
 *
 * @memberof Mojito.Services.Search.types
 */
