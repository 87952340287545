import MojitoCore from 'mojito/core';
import MatchSportPageImpl from './match-sport-page.jsx';

const { UIPage } = MojitoCore.Presentation;

/**
 * @namespace MatchSportPage
 * @memberof Mojito.Application.Pages
 */

/**
 * Page that displays match sports.
 *
 * @class MatchSportPage
 * @memberof Mojito.Application.Pages.MatchSportPage
 */
export default UIPage('MatchSportPage', MatchSportPageImpl);
