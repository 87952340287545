/**
 * This module detects not supported browsers/platforms,
 * prints banner in the console and try to polyfill/patch
 */

function dinosaurDetected() {
    // eslint-disable-next-line no-console
    console.log(
        "%c\
                    ██████████████  \n\
                  ████░░████████████  WOW!,\n\
                  ██████████████████  live dinosaur detected!🦖🦖🦖\n\
                  ██████████████████\n\
                  ██████████████████\n\
                  ████████            Looks like your browser hasn't evolved\n\
                  ██████████████░░    since the Cretaceous period.\n\
                  ██████            \n\
██              ██████████          \n\
██▒▒        ▒▒▒▒██████████████        We're all for prehistoric creatures,\n\
████▓▓      ██████████████  ██        but not when it comes to technology.\n\
██████▒▒▒▒████████████████          \n\
██████████████████████████          \n\
██████████████████████                For the best experience, please update\n\
    ██████████████████                to a modern browser - sportsbook cannot\n\
    ▒▒██████████████                  and will not support the internet fossils.\n\
      ▒▒██████▒▒██▓▓                \n\
        ████      ▓▓                \n\
        ██▒▒      ▓▓                  Thanks for evolving with us!\n\
        ██        ██                \n\
",
        'color: green; font-width: bold'
    );
}

function detectRelicAndPatch() {
    const ua = navigator.userAgent;
    function getMajorVersion(version) {
        if (!version) return 0;
        const major = version.split('.').shift();
        return parseInt(major);
    }
    function isSafari() {
        const isApple = /iPad|iPhone|iPod/.test(ua);
        return isApple;
    }
    function getSafariVersion() {
        const rxResult = ua.match(/[iPad|iPhone|iPod].*Version\/([\d.]+)\s/);
        return rxResult ? getMajorVersion(rxResult[1]) : -1;
    }

    function isChromium() {
        return /Chrome\//.test(ua);
    }

    function getChromiumVersion() {
        const rxResult = ua.match(/Chrome\/([\d.]+)\s/);
        return rxResult ? getMajorVersion(rxResult[1]) : -1;
    }

    function isFireFox() {
        return /Firefox\//.test(ua);
    }

    function getFireFoxVersion() {
        const rxResult = ua.match(/Firefox\/([\d.]+)/);
        return rxResult ? getMajorVersion(rxResult[1]) : -1;
    }

    if (/MSIE|Trident|Edge/.test(ua)) return true; // UA "Edge" is legacy microsoft browser based on Spartan

    if (isSafari()) {
        const version = getSafariVersion();
        if (version < 15) {
            // === Patching ===
            // The performance.measure() method of the Performance API supports the measureOptions parameter starting from iOS 14.5.
            //  * https://caniuse.com/?search=measureOptions
            performance.measure = function () {}; // It is ok to just do nothing
        }

        return version < 16;
    }

    // All chromium based browsers
    if (isChromium()) {
        return getChromiumVersion() < 80;
    }

    if (isFireFox()) {
        return getFireFoxVersion() < 80;
    }
}

function detect() {
    try {
        if (detectRelicAndPatch()) {
            dinosaurDetected();
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
}

detect();
