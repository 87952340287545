import { useSanitizeHtml } from 'presentation/hooks';

const Text = props => {
    const {
        mojitoTools: { config, style },
        children,
        textRef,
    } = props;

    const htmlText = config.isHtml ? children : undefined;
    const sanitizedText = useSanitizeHtml(htmlText);
    const renderOptions = config.isHtml
        ? { dangerouslySetInnerHTML: { __html: sanitizedText } }
        : { children };

    const { text, container } = config.truncate ? style.truncation : style.normal;

    return (
        <div style={container}>
            <div {...renderOptions} className={props.class} style={text} ref={textRef} />
        </div>
    );
};

Text.getStyle = config => {
    const truncatesTextDependsOnLine =
        config.ellipsisOnLineNumber > 1
            ? {
                  display: '-webkit-box',
                  WebkitLineClamp: config.ellipsisOnLineNumber,
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'normal',
                  overflowWrap: 'anywhere',
              }
            : {};

    return {
        normal: {
            text: config.style,
            container: {
                WebkitTextSizeAdjust: '100%',
                ...config.container,
            },
        },
        truncation: {
            text: {
                ...config.style,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                ...truncatesTextDependsOnLine,
            },
            container: {
                ...config.container,
                display: 'grid',
            },
        },
    };
};

export default Text;
