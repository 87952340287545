import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import VirtualEventsCouponControllerImpl from './virtual-events-coupon-controller.jsx';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * Module that displays a virtual events coupon.
 *
 * @class VirtualEventsCoupon
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.Virtuals
 */

/**
 * VirtualEventsCoupon prop types.
 *
 * @property {string} sportId - The sport id of the event to be shown.
 * @property {string} classId - The sport class id of the event to be shown.
 * @property {string} [selectedEventId] - Event id that should be selected.
 *
 * @memberof Mojito.Modules.Virtuals.VirtualEventsCoupon
 */

const propTypes = {
    sportId: PropTypes.string.isRequired,
    classId: PropTypes.string.isRequired,
    selectedEventId: PropTypes.string,
};

export default UIControllerView(
    'VirtualEventsCouponController',
    VirtualEventsCouponControllerImpl,
    propTypes
);
