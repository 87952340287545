import PropTypes from 'prop-types';
import FixedWidthTimeImpl from './fixed-width-time.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view component that displays time in one, two, or three equally sized "boxes", dependent on the input.
 * <br>
 * For example, a typical time string '10:52:27' will be shown as '10', '52', and '27', separated
 * by the first character in props.outputSeparator (default value ':').
 * <br>
 * Using equally sized boxes prevents flickering in the dynamic time string display
 * caused by digits of varying width, such as '1' not being as wide as '0'.
 * <br>
 * Particularly useful for timers and clocks where the time is continuously updated.
 *
 * @class FixedWidthTime
 * @memberof Mojito.Presentation.Components
 */

/**
 * `FixedWidthTime` component prop types.
 *
 * @property {string} [time="00:00:00"] - One or more characters (separated by the first character in props.inputSeparator if there are two or more time parts). Acceptable values include: '02:09:09', '01:13', '1:3', '23', '7', '--', '--:--', '--:--:--'.
 * @property {symbol} [inputSeparator=":"] - Character that separates the time parts in the input time string. For example, you could use ':', '-', ' '.
 * @property {symbol} [outputSeparator=":"] - Character to separate the time parts in the output time string. For example, you could use ':', which results in '02:10', or '-', which results in '01-02-35'.
 *
 * @memberof Mojito.Presentation.Components.FixedWidthTime
 */
const propTypes = {
    time: PropTypes.string,
    inputSeparator: PropTypes.string,
    outputSeparator: PropTypes.string,
};

const defaultProps = {
    time: '00:00:00',
    inputSeparator: ':',
    outputSeparator: ':',
};

export default UIView('FixedWidthTime', FixedWidthTimeImpl, propTypes, defaultProps);
