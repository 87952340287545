import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {assignToHierarchy} from 'core/utils/config-utils';
import SCHEMA from './multi-jurisdiction.schema.yaml';

class MultiJurisdictionFeature extends AbstractFeature {
    get name() {
        return 'MultiJurisdiction';
    }

    get configSchema() {
        return SCHEMA;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        assignToHierarchy(mojitoConfig, 'application.multiJurisdiction', {
            enabled: true, // always enabled if feature is enabled
        });

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }
}

export const MultiJurisdiction = new MultiJurisdictionFeature(allFeatures);
