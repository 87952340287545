import GridCell from '../grid-cell/index.jsx';

const GridSeparator = props => {
    const {
        mojitoTools: {
            config: { topSeparator, bottomSeparator },
        },
        children,
        startColumn,
        endColumn,
        startRow,
        endRow,
    } = props;

    const renderSeparator = separatorConfig =>
        separatorConfig.style ? (
            <GridCell
                config={separatorConfig}
                class={props.class}
                startColumn={startColumn}
                endColumn={endColumn}
                startRow={startRow}
                endRow={endRow}
            />
        ) : null;

    return (
        <>
            {renderSeparator(topSeparator)}
            {children}
            {renderSeparator(bottomSeparator)}
        </>
    );
};

export default GridSeparator;
