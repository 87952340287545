import PropTypes from 'prop-types';
import TextImpl from './text.jsx';
import MojitoCore from 'mojito/core';

/**
 * Generic text component. There are 2 ways to pass child into it, depending on config.isHtml:
 * * isHtml = false: Child as a single plain text.
 * * isHtml = true: Child as a HTML. And then this HTML will be added via `dangerouslySetInnerHTML`.
 *
 * @class Text
 * @memberof Mojito.Presentation.Components
 */

/**
 * Text prop types.
 *
 * @property {string} [class] - Class name applied to the root text element.
 * @property {object} [textRef] - Ref that will be applied to element that is containing text of compontent.
 *
 * @memberof Mojito.Presentation.Components.Text
 */
const propTypes = {
    children: PropTypes.node,
    class: PropTypes.string,
    textRef: PropTypes.object,
};

export default MojitoCore.Presentation.UIView('Text', TextImpl, propTypes);
