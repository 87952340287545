import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types';
import channelFactory from 'services/common/content/content-channel-factory';
import sportMetaInformationProvider from 'services/sports-content/content-provider/sport-meta-information-provider';
import SportMetaInformationDescriptor from './descriptor.js';
import { omit } from 'mojito/utils';

const reduxInstance = MojitoCore.Services.redux;
const { actionsRegistry } = MojitoCore.Services.Content;
const { SPORT_META_INFORMATION, SPORT_META_INFORMATION_CHUNK } =
    SportMetaInformationDescriptor.DATA_TYPES;
const { PENDING, AVAILABLE, UNSUBSCRIBED, UNAVAILABLE } = ServicesTypes.CONTENT_STATE;

export const getSportMetaInformationChannel = () =>
    channelFactory.getChannel(sportMetaInformationProvider, SPORT_META_INFORMATION);

/**
 * Defines the state of the sport meta information store.
 *
 * @typedef SportMetaInformationState
 *
 * @property {object} sportMetaInformation - Sport meta information. Object where keys are the sportMetaInformationId & values are sportMetas.
 * @property {Object<string, Mojito.Services.Common.types.CONTENT_STATE>} sportMetaInformationState - Sport meta information state. Object where keys are list ids and values are [content state]{@link Mojito.Services.Common.types.CONTENT_STATE}.
 *
 * @memberof Mojito.Services.SportsContent.SportMetaInformation
 */

/**
 * The name of the sport meta information state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.SportMetaInformation
 */

export const STORE_KEY = 'sportMetaInformationStore';

export const INITIAL_STATE = {
    sportMetaInformation: {},
    sportMetaState: {},
};

const reducers = {
    updateSportMetaInformation(state, { payload }) {
        const { sportMetaInformation, sportMetaInformationId } = payload;

        if (!sportMetaInformation) {
            delete state.sportMetaInformation[sportMetaInformationId];
            state.sportMetaState[sportMetaInformationId] = UNAVAILABLE;
        } else {
            state.sportMetaInformation[sportMetaInformation.id] = sportMetaInformation.sportMetas;
            state.sportMetaState[sportMetaInformation.id] = AVAILABLE;
        }
    },
    updateSportMetaInformationList(state, { payload: sportMetaInfos }) {
        sportMetaInfos.forEach(sportMetaInfo => {
            const payload = {
                sportMetaInformationId: sportMetaInfo.id,
                sportMetaInformation: sportMetaInfo.data,
            };
            reducers.updateSportMetaInformation(state, { payload });
        });
    },
    sportMetaInformationPending(state, { payload }) {
        const { listIds } = payload;

        listIds?.forEach(listId => {
            state.sportMetaState[listId] = PENDING;
        });
    },
    removeSportMetaInformation(state, { payload }) {
        const { listIds } = payload;

        state.sportMetaInformation = omit(state.sportMetaInformation, listIds);
        listIds?.forEach(listId => {
            state.sportMetaState[listId] = UNSUBSCRIBED;
        });
    },
    reset() {
        return { ...INITIAL_STATE };
    },
};

export const { reducer, actions } = createSlice({
    name: 'sportMetaInformation',
    initialState: INITIAL_STATE,
    reducers,
});

/**
 * Sport meta information actions.
 *
 * @class SportMetaInformationActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.SportMetaInformation
 */

/**
 * Update sport meta information.
 *
 * @function updateSportMetaInformation
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{ sportMetaInformation: object, sportMetaInformationId: string }} payload - Payload for update sport meta information.
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.actions
 */

/**
 * Pending sport meta information.
 *
 * @function sportMetaInformationPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload that contains the ids of pending sport meta information.
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.actions
 */

/**
 * Remove sport meta information.
 *
 * @function removeSportMetaInformation
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload that contains the ids of the sport meta information to be removed.
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.actions
 */

/**
 * Reset sport meta information state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.actions
 */

/**
 * Subscribe to sport meta information.
 *
 * @function subscribe
 *
 * @param {{clientId: string, sportMetaInformationId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to sport meta information thunk.
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.actions
 */
actions.subscribe =
    ({ clientId, sportMetaInformationId }) =>
    dispatch => {
        const onData = (listId, sportMetaInformation) => {
            dispatch(
                actions.updateSportMetaInformation({
                    sportMetaInformationId: listId,
                    sportMetaInformation,
                })
            );
        };
        const pendingSportMetaInformationIds = getSportMetaInformationChannel().subscribe(
            [sportMetaInformationId],
            clientId,
            onData
        );
        dispatch(actions.sportMetaInformationPending({ listIds: pendingSportMetaInformationIds }));
    };

/**
 * Triggers sport meta chunk subscription for multiple clients.
 *
 * @function subscribeMultiple
 *
 * @param {object} payload - Subscription payload. Contains the list of meta requests for each client subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to sport meta information thunk.
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.actions
 */
actions.subscribeMultiple = payload => dispatch => {
    const { requests } = payload;
    const onInit = initChunk => {
        dispatch(actions.updateSportMetaInformationList(initChunk));
    };
    const onUpdate = (id, metaInfo) => {
        dispatch(
            actions.updateSportMetaInformation({
                sportMetaInformationId: id,
                sportMetaInformation: metaInfo,
            })
        );
    };
    const pendingIds = getSportMetaInformationChannel().subscribeMultiple(
        requests,
        onInit,
        onUpdate
    );
    dispatch(actions.sportMetaInformationPending({ listIds: pendingIds }));
};

/**
 * Unsubscribe from sport meta information.
 *
 * @function unsubscribe
 *
 * @param {string} clientId - The clientId of subscriber to unsubscribe.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from sport meta information thunk.
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getSportMetaInformationChannel().unsubscribeAll(clientId, listIds => {
        dispatch(actions.removeSportMetaInformation({ listIds }));
    });
};

actionsRegistry.addSubscription(SPORT_META_INFORMATION, actions.subscribe, actions.unsubscribe);
actionsRegistry.addSubscription(SPORT_META_INFORMATION_CHUNK, actions.subscribeMultiple);

reduxInstance.injectReducer(STORE_KEY, reducer);
