import MojitoCore from 'mojito/core';
import Image from 'presentation/components/image/index.jsx';

const uiStyle = MojitoCore.Presentation.uiStyle;

export default class Spinner extends MojitoCore.Presentation.UIViewImplementation {
    render() {
        return (
            <div style={this.style.iconContainer} className="ta-Spinner">
                <Image config={this.config.image} />
            </div>
        );
    }
}

Spinner.getStyle = (config, applicationMode, merge) => {
    return {
        iconContainer: merge(
            {
                ...uiStyle.createCssAnimation(config, 'spinner_icon', config.style.iconAnimation),
            },
            config.style.container
        ),
    };
};
