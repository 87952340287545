/**
 * Date time utils types.
 *
 * @class DateTimeTypes
 * @memberof Mojito.Core.Base
 */
export default class DateTimeTypes {}

/**
 * Timezone offset format.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Base.DateTimeTypes
 */
DateTimeTypes.OFFSET_FORMAT = {
    /** Defines offset representation in minutes. */
    MINUTES: 'MINUTES',
    /** Defines offset representation in hours. */
    HOURS: 'HOURS',
};
