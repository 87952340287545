import PropTypes from 'prop-types';
import TeamIconImpl from './team-icon.jsx';
import MojitoCore from 'mojito/core';

/**
 * Team icon component. It is used to display the team icon based on team id.
 *
 * @class TeamIcon
 * @memberof Mojito.Presentation.Components
 */

/**
 * TeamIcon prop types.
 *
 * @property {string} [sportCode] - Sport code for the respective sports.
 * @property {string} [teamId] - Team id used to identify the mapped team icon.
 * @property {string} [title] - String or string key that, if present, will be used as a title for the image element.
 * @property {Function} [onInit] - Callback triggered before the initial rendering of team icon component.
 * @property {Function} [onLoad] - Callback triggered on team icon load event.
 * @property {Function} [onError] - Callback triggered on team icon load error.
 *
 * @memberof Mojito.Presentation.Components.TeamIcon
 */
const propTypes = {
    sportCode: PropTypes.string,
    teamId: PropTypes.string,
    title: PropTypes.string,
    onInit: PropTypes.func,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};

export default MojitoCore.Presentation.UIView('TeamIcon', TeamIconImpl, propTypes);
