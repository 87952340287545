import BannerCardImpl from './banner-card.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * Banner card component.
 *
 * @function BannerCard
 * @name view
 *
 * @memberof Mojito.Modules.EventCards.Banner
 */

/**
 * Banner card controller prop types.
 *
 * @property {string} imgUrl - Banner image url.
 * @property {string} targetUrl - Url that should be opened when the user clicks on a banner.
 * @property {boolean} [openInNewTab = false] - Flag to open targetUrl in new browser tab.
 * @property {Function} [onLoadDone = ()=>{}] - Callback triggered once banner image is loaded or if error occurred during loading process.
 * @property {boolean} [showSkeleton = true] - True if data loading skeleton should be visible. It can be useful to hide skeleton if parent component already covers component with its of top level skeleton.
 * @memberof Mojito.Modules.EventCards.Banner.view
 */

const propTypes = {
    imgUrl: PropTypes.string.isRequired,
    targetUrl: PropTypes.string.isRequired,
    openInNewTab: PropTypes.bool,
    onLoadDone: PropTypes.func,
    showSkeleton: PropTypes.bool,
};

const defaultProps = {
    onLoadDone: noop,
    openInNewTab: false,
    showSkeleton: true,
};

export default UIView('BannerCard', BannerCardImpl, propTypes, defaultProps);
