import {merge} from './config-utils.js';

export function selectableButtonStyle(styleOrComponent, style) {
    if (!style) {
        // handle case when only 1 argument
        style = styleOrComponent;
        styleOrComponent = undefined;
    }
    style = {
        selected: {
            style: {
                base: style,
            },
        },
        unselected: {
            style: {
                base: style,
            },
        },
    };
    return styleOrComponent ? merge(styleOrComponent, style) : style;
}

/**
 * @deprecated Not needed. New palette-schema already resolves text styles
 * @param style {object|string} color of element if string, object value treats as complete style
 * @param style2 {object|string} Style that will be merged to first operand. Could be a string value
 * @returns {object} Complete style of element
 */
export function resolveStyle(style, style2 = null) {
    style = typeof style === 'string' ? {color: style} : style;
    style2 = typeof style2 === 'string' ? {color: style2} : style2;
    return !style2 ? style : merge(style, style2);
}

/**
 * @param style {object|string} color of element if string, object value treats as complete style
 * @param style2 {object|string} Style that will be merged to first operand. Could be a string value
 * @returns {object} Complete style of element
 */
export function resolveBorderStyle(style, style2 = null) {
    style = typeof style === 'string' ? {border: style} : style || {};
    style2 = typeof style2 === 'string' ? {border: style2} : style2 || {};
    return !style2 ? style : merge(style, style2);
}

/**
 *
 * @param radius {number} If radius is negative it is treated as percent. I.e. -50 means 50%
 */
export function resolveBorderRadius(radius) {
    return radius < 0 ? `${-radius}%` : radius;
}

export function iconColor(color) {
    return {
        style: {
            fill: color,
        },
    };
}

export function icon(color, src) {
    let config = {
        style: {
            fill: color,
        },
    };
    if (src !== undefined && src !== null && src !== '') config.src = src;
    return config;
}

export function borderStyle(radius, color, width) {
    if (color) {
        return {
            borderColor: color,
            borderStyle: 'solid',
            borderWidth: width || 1,
            borderRadius: radius,
        };
    } else {
        return {
            borderStyle: 'none',
            borderRadius: radius,
        };
    }
}
