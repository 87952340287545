import MojitoCore from 'mojito/core';

const { DataProvider } = MojitoCore.Services;
const COLLECTION_NAME = 'sportmenu';

/**
 *
 * Class offering the possibility to link to sports content menus information.
 *
 * @class MenusProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class MenusProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    /**
     * Link to specific sport menu.
     *
     * @param {string} sportMenuId - The sport menu list id.
     * @param {Function} setter - Function called when the requested data is updated.
     * @returns {object} Subscription that must be disposed when no longer needed.
     * @function Mojito.Services.SportsContent.Menus.SportMenusProvider#subscribeToEntity
     */
    subscribeToEntity(sportMenuId, setter) {
        return super.subscribeToEntity(sportMenuId, setter, COLLECTION_NAME);
    }
}

export default new MenusProvider();
