import PropTypes from 'prop-types';
import InPlayContent from './inplay-content.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Module that displays InPlay events.
 *
 * @class InPlayContent
 * @name InPlayContent
 * @memberof Mojito.Modules.InPlay
 */

/**
 * `InPlayContent` prop types.
 *
 * @property {string} [selectedSport] - The sport code of the currently selected sport.
 * @property {Array<string>} [sportIds = []] - Array of the inplay sports ids.
 * @property {object} [eventGroups = {}] - Event groups of the inplay sports.
 *
 * @memberof Mojito.Modules.InPlay.InplayContent
 */
const propTypes = {
    eventGroups: PropTypes.object,
    selectedSport: PropTypes.string,
    sportIds: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    sportIds: [],
    eventGroups: {},
};

export default UIView('InPlayContent', InPlayContent, propTypes, defaultProps);
