import AbstractTask from 'services/common/task-runner/abstract-task';
import MojitoCore from 'mojito/core';
import { actions as promotionsActions } from 'services/promotions/slice.js';
const { dispatch } = MojitoCore.Services.redux.store;

/**
 * Retrieves user specific promotions.
 *
 * @class PollUserPromotionsTask
 * @extends AbstractTask
 * @memberof Mojito.Services.Promotions
 */
class PollUserPromotionsTask extends AbstractTask {
    constructor(service) {
        super();
        this.service = service;
    }

    execute(callback) {
        if (!this.service || !this.service.getPromotions) {
            return;
        }

        const withFinallyCallback = action => response => {
            dispatch(action(response));
            callback();
        };
        this.service
            .getPromotions()
            .then(withFinallyCallback(promotionsActions.updateChannelPromotions))
            .catch(withFinallyCallback(promotionsActions.getPromotionsFailed));
    }
}

export default PollUserPromotionsTask;
