import PropTypes from 'prop-types';
import GridCellImpl from './grid-cell.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * A helper component that creates a cell within a GridPane.
 * It allows a cell to span over multiple rows or columns by specifying the start/end column/row.
 * Always wraps child elements in a singular div element and sets the necessary gridColumn/gridRow
 * start/end styling.
 *
 * @class GridCell
 * @memberof Mojito.Presentation.Components.GridLayout
 */

/**
 * `GridCell` component prop types.
 *
 * @property {number} [startColumn] - The column index where this cell should start rendering.
 * @property {number} [endColumn] - The column index where this cell should stop rendering.
 * @property {number} [startRow] - The row index where this cell should start rendering.
 * @property {number} [endRow] - The row index where this cell should stop rendering.
 * @property {string} [class] - The class name to be applied to the GridCell's root element.
 * @property {Function} [onClick = ()=>{}] - The callback function executed when the cell is clicked.
 *
 * @memberof Mojito.Presentation.Components.GridLayout.GridCell
 */
const propTypes = {
    startColumn: PropTypes.number,
    endColumn: PropTypes.number,
    startRow: PropTypes.number,
    endRow: PropTypes.number,
    class: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const defaultProps = {
    onClick: noop,
};

export default MojitoCore.Presentation.UIView('GridCell', GridCellImpl, propTypes, defaultProps);
