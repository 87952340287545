/**
 * Sport context menu types.
 *
 * @class SportContextMenuTypes
 * @name types
 * @memberof Mojito.Presentation.Components.SportContextMenuList
 */
export default class SportContextMenuTypes {}

/**
 * SportContextMenuTypes ITEMS enum, used for indicating which item that should be displayed as selected in menu.
 *
 * @typedef ITEMS
 * @property {string} HIGHLIGHTS - Highlights menu item.
 * @property {string} COMPETITIONS - Competitions menu item.
 * @property {string} OUTRIGHTS - Outrights menu item.
 * @property {string} MATCHES - Matches menu item.
 * @property {string} TODAY - Today menu item.
 * @property {string} TOMORROW - Tomorrow menu item.
 * @property {string} PRICE_BOOSTS - Price Boosts item.
 * @property {string} TODAYS_RACING - Today's racing item.
 * @property {string} TOMORROWS_RACING - Tomorrow's racing item.
 * @memberof Mojito.Presentation.Components.SportContextMenuList.types
 */
SportContextMenuTypes.ITEMS = {
    HIGHLIGHTS: 'highlights',
    COMPETITIONS: 'competitions',
    OUTRIGHTS: 'outrights',
    MATCHES: 'matches',
    MEETINGS: 'meetings',
    FUTURE: 'future',
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    PRICE_BOOSTS: 'price_boosts',
    TODAYS_RACING: 'todays_racing',
    TOMORROWS_RACING: 'tomorrows_racing',
};
