import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';
import MatchAccaUtils from './utils';
import { createSelector } from '@reduxjs/toolkit';
const arraysAreEqual = MojitoCore.Base.objUtils.arraysAreEqual;

const reduxInstance = MojitoCore.Services.redux;

/**
 * Match acca selectors.
 *
 * @class MatchAccaSelectors
 * @name selectors
 * @memberof Mojito.Services.MatchAcca
 */

/**
 * Selects match acca state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.MatchAcca.MatchAccaState} Match acca state.
 * @memberof Mojito.Services.MatchAcca.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects match acca by id.
 *
 * @function selectMatchAccaById
 *
 * @param {string} [eventId] - Event id.
 * @param {object} [state] - Application state object.
 *
 * @returns {object} A valid Match acca object.
 * @memberof Mojito.Services.MatchAcca.selectors
 */
export const selectMatchAccaById = (eventId, state) =>
    selectState(state).matchAccas[eventId] || selectState(state).matchAccaPrototype;

/**
 * Selects match acca.
 *
 * @function selectMatchAcca
 *
 * @param {object} [state] - Application state object.
 *
 * @returns {object} Match accas.
 * @memberof Mojito.Services.MatchAcca.selectors
 */
export const selectMatchAcca = state => selectState(state).matchAccas;

const getAllMatchAccaSelections = (_, state) => {
    const { matchAccas, matchAccaPrototype } = selectState(state);
    return Object.values(matchAccas || matchAccaPrototype).flatMap(({ selections }) => selections);
};

/**
 * Selects specific selection from all match accas.
 *
 * @function selectSelectionFromMatchAcca
 *
 * @param {string} selectionId - Selection id.
 * @param {object} [state] - Application state object.
 *
 * @returns {object} A valid Match acca object.
 * @memberof Mojito.Services.MatchAcca.selectors
 */

export const selectSelectionFromMatchAcca = createSelector(
    [getAllMatchAccaSelections, selectionId => selectionId],
    (selections, selectionId) =>
        MatchAccaUtils.getSelectionFromMatchAcca({ selections }, selectionId),
    {
        memoizeOptions: { resultEqualityCheck: arraysAreEqual },
    }
);
