/**
 * Subscription actions registry. Used to register subscribe/unsubscribe actions
 * by Redux slices. Keeps the map of action subscription functions to the data type which the particular
 * subscription is aiming for. Typically, used by data subscription managers like {@link Mojito.Core.Services.RequestDataHelper|RequestDataHelper}.
 *
 * @class ActionsRegistry
 * @name actionsRegistry
 * @memberof Mojito.Core.Services.Content
 */
class ActionsRegistry {
    constructor() {
        this.subscribeActions = {};
        this.unsubscribeActions = {};
    }

    /**
     * Add subscribe/unsubscribe actions to the registry.
     *
     * @param {string} dataType - Data type. Unique key which identifies the data to which subscription is aiming for, e.g. 'sports', 'event', 'sport-menu' etc.
     * @param {Function} subscribe - Subscribe action function.
     * @param {Function} unsubscribe - Unsubscribe action function.
     *
     * @function Mojito.Core.Services.Content.actionsRegistry#addSubscription
     */
    addSubscription(dataType, subscribe, unsubscribe) {
        this.subscribeActions[dataType] = subscribe;
        this.unsubscribeActions[dataType] = unsubscribe;
    }

    /**
     * Remove subscription actions from the registry.
     *
     * @param {string} dataType - Data type. Unique key which identifies existing subscription.
     *
     * @function Mojito.Core.Services.Content.actionsRegistry#removeSubscription
     */
    removeSubscription(dataType) {
        delete this.subscribeActions[dataType];
        delete this.unsubscribeActions[dataType];
    }

    /**
     * Get subscribe action by data type.
     *
     * @param {string} dataType - Data type. Unique key which identifies existing subscription.
     *
     * @returns {Function} Subscription function.
     * @function Mojito.Core.Services.Content.actionsRegistry#getSubscribe
     */
    getSubscribe(dataType) {
        return this.subscribeActions[dataType];
    }

    /**
     * Get all unsubscribe actions existing in registry.
     *
     * @returns {Array<Function>} List of unsubscribe functions.
     * @function Mojito.Core.Services.Content.actionsRegistry#getAllUnsubscribes
     */
    getAllUnsubscribes() {
        return Object.values(this.unsubscribeActions);
    }
}

export default new ActionsRegistry();
