import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { resolveSelectionGroupsByName } from 'modules/market/helper/market-helper';
import Market from 'modules/market/index.jsx';
import PromotionUtils from 'modules/promotions/utils/promotion-utils';
import { connect } from 'react-redux';

const { UIViewImplementation } = MojitoCore.Presentation;
const { DateTime } = MojitoPresentation.Components;
const { utils: EventUtils, descriptors: EventDescriptor } = MojitoServices.SportsContent.Events;
const { createEventDescriptor, createEventMarketDescriptor } = EventDescriptor;
const { flatMerge } = MojitoCore.Base.objUtils;
const RequestDataHelper = MojitoCore.Services.RequestDataHelper;
const { selectEventItem, makeSelectMarketsByIds } = MojitoServices.SportsContent.Events.selectors;

class BannerMarket extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.resolveSelectionGroups = this.resolveSelectionGroups.bind(this);
        this.contentPreloader = props.contentPreloader;
        this.requestDataHelper = new RequestDataHelper(this.instanceId);
    }

    componentDidMount() {
        const { shouldRequestData, event, marketId } = this.props;
        if (shouldRequestData) {
            this.requestDataHelper.requestData(createEventDescriptor(event.id));
            this.requestDataHelper.requestData(createEventMarketDescriptor(marketId, event.id));
        }
    }

    componentWillUnmount() {
        this.requestDataHelper.unsubscribeAll();
    }

    resolveSeparatorLabel(homeAway) {
        const l10nString = !homeAway
            ? '$BANNERMARKET.HEADER.SEPARATOR.AWAY_HOME'
            : '$BANNERMARKET.HEADER.SEPARATOR.HOME_AWAY';
        return this.resolveString(l10nString);
    }

    renderTimeOrStatus() {
        const event = this.props.eventItem;
        if (!event) {
            return null;
        }
        if (event.details.inPlay) {
            return (
                <div style={this.style.startTime}>
                    {this.resolveString('$BANNERMARKET.HEADER.LIVE')}
                </div>
            );
        }

        return (
            <div style={this.style.startTime}>
                <DateTime config={this.config.startTimeFormat} dateTime={event.startTime} />
            </div>
        );
    }

    renderSportLeague() {
        return (
            <div style={this.style.sportLeague}>
                {this.appContext().sportName(this.props.eventItem.sportId)}
                {this.config.sportLeague.delimiter}
                {this.props.eventItem.typeName}
            </div>
        );
    }

    resolveSelectionGroups(market, selectionTypes, homeAway) {
        return resolveSelectionGroupsByName(
            market,
            market?.selections?.map(selection => selection.name),
            homeAway
        );
    }

    resolveMarket() {
        const { market, event } = this.props;

        const outrightSelections = PromotionUtils.getTopSelections(
            market,
            this.config.maxSelectionsAmount
        );

        return EventUtils.isOutrightEventType(event.eventType)
            ? { ...market, selections: outrightSelections }
            : market;
    }

    renderSelections() {
        const { market, eventItem } = this.props;

        if (!market?.selections) {
            return null;
        }

        return (
            <Market
                market={this.resolveMarket()}
                event={eventItem}
                config={this.config.market}
                selectionGroupsResolver={this.resolveSelectionGroups}
            />
        );
    }

    renderLabel() {
        if (!this.props.eventItem) {
            return null;
        }
        const { participants, name, homeAway } = this.props.eventItem;

        if (!participants || participants.length !== 2) {
            return <div style={this.style.eventName}>{name}</div>;
        }
        return (
            <div style={this.style.participants}>
                <div style={this.style.eventName}>{participants[0].name}</div>
                <div style={this.style.eventName}>{this.resolveSeparatorLabel(homeAway)}</div>
                <div style={this.style.eventName}>{participants[1].name}</div>
            </div>
        );
    }

    render() {
        if (!this.props.eventItem) {
            return null;
        }
        return (
            <div style={this.style.container} className={`oddsbanner-${this.props.marketId}`}>
                {this.renderSportLeague()}
                {this.renderTimeOrStatus()}
                {this.renderLabel()}
                {this.renderSelections()}
            </div>
        );
    }
}

BannerMarket.getStyle = function (config, applicationMode, merge) {
    const textOverflowEllipsis = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };
    return {
        container: merge(
            {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            config.style
        ),
        eventName: merge(textOverflowEllipsis, {
            width: '100%',
            textAlign: 'center',
            color: config.eventName.text.color,
            fontSize: config.eventName.text.fontSize,
            fontFamily: config.eventName.text.fontFamily,
            fontWeight: config.eventName.text.fontWeight,
            textTransform: config.eventName.text.textTransform,
            margin: config.eventName.margin,
        }),
        participants: merge(config.participants, {
            display: 'flex',
        }),
        sportLeague: flatMerge(config.sportLeague, {
            width: '100%',
        }),
        startTime: flatMerge(config.startTime, {
            position: 'absolute',
        }),
    };
};

const mapStateToProps = (state, ownProps) => {
    const selectMarkets = makeSelectMarketsByIds();
    return {
        eventItem: selectEventItem(ownProps.event.id, state),
        market: selectMarkets([ownProps.event.market.marketId])[0],
    };
};

export default connect(mapStateToProps)(BannerMarket);
