import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

import {MobileContext} from 'core/utils/context-utils';

import {StoreProviderWrapper} from './wrappers';

const {useConfig} = MojitoCore.Presentation.Hooks;
const {ContextExtender: AnalyticsContextExtender, ContextBuilder: AnalyticsContextBuilder} =
    MojitoCore.Presentation.AnalyticsContext;
const {LoaderSuspense, SkeletonPart} = MojitoPresentation.Components;

const {view: SelectionButtonView, types: SelectionButtonTypes} = MojitoModules.SelectionButton;
const {
    actions: betslipActions,
    selectors: betslipSelectors,
    modelFactory: betslipModelFactory,
} = MojitoServices.Betslip;
const {useEventItem, useEventsLoadDone} = MojitoModules.Common.Hooks;

const SOURCE = 'Widget Selection Button';

/**
 * This widget renders a selection button, with inherits existed styles.
 * The button could add/remove the selection to/from the betslip.
 * The button listens to changes in the betslip store, and changes own state correspondingly (active/inactive/pending).
 */

// eslint-disable-next-line react/prop-types
const WidgetSelectionButtonImpl = ({selectionId, marketId, eventId, betRef, price}) => {
    const dispatch = useDispatch();
    const event = useEventItem(eventId);
    const eventItemLoadDone = useEventsLoadDone([eventId]);
    const betslipSelectionState = useBetslipSelectionState(selectionId);
    const selectionButtonConfig = useConfig('SelectionButton');
    const eventsMarketsSkeletonConfig = useConfig('EventMarketsSkeleton');
    const buttonState = getButtonState(betslipSelectionState);
    const selectionData = useMemo(
        () => ({
            id: selectionId,
            prices: [{...price, betRef}],
            marketId,
        }),
        [selectionId, price, marketId, betRef]
    );

    const onClick = useCallback(() => {
        switch (buttonState) {
            case SelectionButtonTypes.STATES.INACTIVE:
                dispatch(
                    betslipActions.addSelection({
                        selection: betslipModelFactory.getSelection(selectionData),
                        parent: betslipModelFactory.getParent(event, selectionData),
                        source: SOURCE,
                    })
                );
                return;
            case SelectionButtonTypes.STATES.ACTIVE:
                dispatch(
                    betslipActions.removeSelection({
                        selectionId: selectionData.id,
                        parent: betslipModelFactory.getParent(event, selectionData),
                        source: SOURCE,
                    })
                );
                return;
            case SelectionButtonTypes.STATES.PENDING:
            default:
                // do nothing
                return;
        }
    }, [buttonState, event, selectionData]);

    return (
        <LoaderSuspense
            isContentPending={!eventItemLoadDone}
            contentHash={eventId}
            loader={<SkeletonPart config={eventsMarketsSkeletonConfig.selectionPlaceholder} />}
        >
            {event ? (
                <AnalyticsContextExtender value={analyticsContext}>
                    <MobileContext>
                        <SelectionButtonView
                            config={selectionButtonConfig.view}
                            state={buttonState}
                            selection={selectionData}
                            event={event}
                            nameDisplay={SelectionButtonTypes.NAME_DISPLAY.HIDE}
                            onClick={onClick}
                        />
                    </MobileContext>
                </AnalyticsContextExtender>
            ) : null}
        </LoaderSuspense>
    );
};

const analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('WidgetSelectionButton').build();

const useBetslipSelectionState = selectionId =>
    useSelector(state => betslipSelectors.selectSelectionState(selectionId, state));

const getButtonState = selectionState => {
    const {INACTIVE, PENDING, ACTIVE} = SelectionButtonTypes.STATES;

    if (!selectionState) {
        return INACTIVE;
    }

    return selectionState.isPending ? PENDING : ACTIVE;
};

const propTypes = {
    betRef: PropTypes.string.isRequired,
    selectionId: PropTypes.string.isRequired,
    marketId: PropTypes.string.isRequired,
    eventId: PropTypes.string.isRequired,
    price: PropTypes.shape({
        decimalLabel: PropTypes.string.isRequired,
        fractionalLabel: PropTypes.string.isRequired,
        americanLabel: PropTypes.string.isRequired,
    }).isRequired,
};

export const WidgetSelectionButton = StoreProviderWrapper(
    'WidgetSelectionButton',
    WidgetSelectionButtonImpl,
    propTypes
);
