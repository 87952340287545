import MojitoNGen from 'mojito/ngen';
import translationService from 'core/services/localization/translation-service';
import uiStyle from 'core/presentation/ui-style';
import { omit, omitBy } from 'mojito/utils';
import { configFactory, META } from 'core/config/config-factory';
import ExecutionMode from 'core/base/execution-mode';

const log = MojitoNGen.logger.get('UIViewUtils');
const REACT_LAZY_TYPE = Symbol.for('react.lazy');
const REACT_LAZY_RESOLVED = 1;
const shouldOmitProp = (value, key) => key === 'implementation' || value === undefined;

/**
 * Utility class for composing UIView components.
 *
 * @class UIViewUtils
 * @memberof Mojito.Core.Presentation
 */
export default {
    /**
     * Search for component implementation by provided name and contextPath.
     *
     * @example <caption>calling <code>getImplementation('Button', 'inplay')</code> will find MyButtonInplay implementation for _inplay_ context path if the one was configured in viewImplementations object like: <br><br> </caption>
     *
     * const viewImplementations = {
     *      Button: {
     *          implementation: MyButton,
     *          _inplay_implementation: MyButtonInplay,
     *      },
     *  };
     *
     * @example <caption>Will return object:</caption>
     * {
     *     implementation: MyButtonInplay,
     * }
     *
     * @param {string} name - Name of the look-up component.
     * @param {string} contextPath - Current context used to look for component implementation.
     *
     * @returns {Function} Implementation class or function.
     * @function Mojito.Core.Presentation.UIViewUtils.getImplementation
     */
    getImplementation: (name, contextPath) => {
        const implementationConfig = configFactory.getImplementationConfig(name, contextPath);
        return implementationConfig?.implementation;
    },

    /**
     * Search for component's config by name and contextPath, merges found config with config from parent if provided.
     *
     * @param {string} name - Name of the component.
     * @param {string} contextPath - Current context used to look for component config.
     * @param {Mojito.Core.Services.Config.ConfigObject} configOverride - Config that was passed by parent through props.
     *
     * @returns {Mojito.Core.Services.Config.ConfigObject} Contextualized ConfigObject or undefined if no config available.
     * @function Mojito.Core.Presentation.UIViewUtils.getConfig
     */
    getConfig: (name, contextPath, configOverride) => {
        const configMeta = configOverride?.[META];
        const isCompleteConfig = configMeta?.isConfigObject;
        const configName = configMeta?.name;
        if (isCompleteConfig && name !== configName) {
            log.error(`${name} received config for: ${configName}`);
        }
        return isCompleteConfig
            ? configOverride
            : configFactory.getConfig(name, contextPath, configOverride);
    },

    /**
     * Get the translations object for a given language code.
     *
     * @param {string} langCode - The language code used to find the corresponding localization object.
     *
     * @returns {object} The l10n object with localized values.
     * @function Mojito.Core.Presentation.UIViewUtils.getTranslations
     */
    getTranslations(langCode) {
        const l10n = translationService.getTranslations(langCode);
        if (!l10n) {
            log.error(`Failed to load language: ${langCode}`);
        }
        return l10n;
    },

    /**
     * Get component style by executing `getStyleHook`,
     * typically a static `getStyle` function on the component class.
     * The resulting style object is vendor-prefixed.
     *
     * @param {Mojito.Core.Services.Config.ConfigObject} config - Config of the related component, supplied to `getStyleHook`.
     * @param {Function} getStyleHook - Function for building the style object, usually defined as static on the component class.
     * @param {string} applicationMode - Passed into `getStyleHook`.
     * @param {string} name - Component name.
     *
     * @returns {object} Style object ready for use by the component.
     * @function Mojito.Core.Presentation.UIViewUtils.getStyle
     */
    getStyle: (config, getStyleHook, applicationMode, name) => {
        if (!getStyleHook) {
            return undefined;
        }

        let style = getStyleHook(config, applicationMode, uiStyle.merge);
        // Prefixer might throw an exception if it can't mutate style object. We will handle it gracefully.
        try {
            style = uiStyle.prefix(style);
        } catch (e) {
            if (e instanceof TypeError) {
                log.error(
                    `${name} styles vendor prefixing failed. getStyle hook returned immutable object. Check getStyle function of ${name} component. This can happen if ${name}.getStyle returned object that contains component config created by Mojito.Core.Services.Config.ConfigFactory#getConfig which produces objects restricted to mutate. Make sure to use structuredClone inside ${name}.getStyle to clone config parts before returning from getStyle.`
                );
            } else {
                throw e;
            }
        }
        return style;
    },

    /**
     * Verifies that prop types are defined for all incoming props.
     *
     * @param {object} props - Object to verify.
     * @param {object} propTypes - Specifies the type for each property.
     * @param {string} name - Name of the component being verified.
     *
     * @function Mojito.Core.Presentation.UIViewUtils.verifyPropTypes
     */
    verifyPropTypes: (props, propTypes, name) => {
        if (!props || !ExecutionMode.isDebugMode()) {
            return;
        }
        if (Object.keys(props).length > 0 && !propTypes) {
            log.error(`Expected PropTypes for class: ${name}`);
            return;
        }
        // Then check so that all properties have a propType
        Object.keys(props).forEach(key => {
            if (!propTypes.hasOwnProperty(key)) {
                log.error(`Unexpected props name '${key}' for class: ${name}`);
            }
        });
    },

    /**
     * Omits component own props that were provided by UIView HoC: `mojitoTools` and `config` that were set by parent.
     * Typically, these props are for internal use only and not part of the component's public interface.
     * However, we sometimes need to propagate all props to the child.
     *
     * @example // Propagate all props to child - leads to props validation warning.
     * import React from 'react';
     * import RoutingView from './view/index.jsx';
     *
     * class RoutingController {
     *     render() {
     *          return <RoutingView {...this.props} />;
     *     }
     * }
     *
     * @example // Propagate all props to child with `omitComponentOwnProps` - OK.
     * import React from 'react';
     * import MojitoCore from 'mojito/core';
     * import RoutingView from './view/index.jsx';
     *
     * const UIViewUtils = MojitoCore.Presentation.UIViewUtils;
     * class RoutingController {
     *     render() {
     *          return <RoutingView {...UIViewUtils.omitComponentOwnProps(this.props)} />;
     *     }
     * }
     *
     * @param {object} props - Component's props object.
     * @param {*} rest - Remaining string-based param keys to be omitted.
     *
     * @returns {object} Props object without component's own properties.
     * @function MojitoCore.Presentation.UIViewUtils.omitComponentOwnProps
     */
    omitComponentOwnProps(props, ...rest) {
        return omit(props, 'mojitoTools', 'config', ...rest);
    },

    /**
     * Logs a warning if props contain properties whose names are reserved for internal Mojito usage.
     *
     * @param {object} props - Props object to be verified.
     * @param {string} sourceName - Name of the component performing the props check.
     * @param {*} reservedPropNames - Any number of prop names reserved for internal use.
     *
     * @function Mojito.Core.Presentation.UIViewUtils.validateReservedProps
     */
    validateReservedProps(props, sourceName, ...reservedPropNames) {
        if (!props || !ExecutionMode.isDebugMode()) {
            return;
        }
        reservedPropNames.forEach(reservedPropName => {
            if (reservedPropName in props) {
                log.warn(
                    `'${reservedPropName}' is reserved prop name and will be overridden by ${sourceName}`
                );
            }
        });
    },

    /**
     * Check if component module is react lazy loadable.
     *
     * @param {object} component - Reference to component module.
     *
     * @returns {boolean} True if lazy loadable.
     * @function Mojito.Core.Presentation.UIViewUtils.isLazy
     */
    isLazy(component) {
        return component?.$$typeof === REACT_LAZY_TYPE;
    },

    /**
     * Function resolves component module reference from the output of {@link https://reactjs.org/docs/code-splitting.html#reactlazy|React.lazy} function
     * once the lazy loading is done and module is available. Before that undefined will be returned.
     *
     * @param {object} lazyComponent - Imported lazy module object. This is the output of {@link https://reactjs.org/docs/code-splitting.html#reactlazy|React.lazy}.
     * @param {string} name - The name of the component that module is imported for.
     *
     * @returns {Function|undefined} Reference to component module or undefined.
     * @function Mojito.Core.Presentation.UIViewUtils.resolveModuleFromLazy
     */
    resolveModuleFromLazy(lazyComponent, name) {
        const payload = lazyComponent._payload;
        if (payload._status !== REACT_LAZY_RESOLVED) {
            return;
        }
        const moduleObject = payload._result;
        if (!('default' in moduleObject)) {
            log.error(`Lazy component ${name} should be exported as the default export.`);
        }
        return moduleObject.default;
    },

    /**
     * Ensure component default props on props object. The values from defaultProps will be assigned to
     * props object if they are missing on props object of undefined.
     *
     * @param {object} props - Component props object.
     * @param {object} defaultProps - Component default props object.
     *
     * @returns {object} Resulting props object.
     * @function Mojito.Core.Presentation.UIViewUtils.ensureDefaultProps
     */
    ensureDefaultProps(props, defaultProps) {
        return {
            ...defaultProps,
            ...omitBy(props, shouldOmitProp),
        };
    },
};
