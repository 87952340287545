import PropTypes from 'prop-types';
import RecentSearchImpl from './recent-search.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View for recent search history.
 *
 * @class RecentSearch
 * @memberof Mojito.Application.Search
 */

/**
 * Callback to notify on item click or removal.
 *
 * @param {string} item  - List item involved.
 * @callback Mojito.Application.Search.RecentSearch.itemCallback
 */

/**
 * RecentSearch prop types.
 *
 * @property {Array<string>} items - Recent search items.
 * @property {Mojito.Application.Search.RecentSearch.itemCallback} [onItemClick = ()=>{}] - Recent search item clicked callback.
 * @property {Mojito.Application.Search.RecentSearch.itemCallback} [onItemRemoveClick = ()=>{}] - Recent search item remove clicked callback.
 * @memberof Mojito.Application.Search.RecentSearch
 */
const propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    onItemClick: PropTypes.func,
    onItemRemoveClick: PropTypes.func,
};

const defaultProps = {
    onItemClick: noop,
    onItemRemoveClick: noop,
};

export default UIView('RecentSearch', RecentSearchImpl, propTypes, defaultProps);
