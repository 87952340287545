/**
 * Bonus types.
 *
 * @class BonusTypes
 * @name types
 * @memberof Mojito.Services.Bonus
 */
export default class BonusTypes {}

/**
 * Bonus status type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bonus.types
 */
BonusTypes.STATUS = {
    ACTIVE: 'ACTIVE',
    AWARDED: 'AWARDED',
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE',
    EXPIRED: 'EXPIRED',
    CANCELLED: 'CANCELLED',
    ALL: 'ALL', // Special value, used for filtering only
};

/**
 * In progress bonus step.
 *
 * @typedef InProgressBonusStep
 * @type {object}
 * @property {string} name - Name.
 * @property {string} description - Description.
 * @property {number} amount - Amount.
 * @property {string} requiredWager - Wagered amount.
 * @property {number} numBets - Number of bets.
 * @property {number} minBets - Minimum number of bets.
 * @property {number} order - Order.
 * @property {Mojito.Services.Bonus.types.IN_PROGRESS_STEP_STATUS} status - Status.
 * @property {string} type - Type.
 * @memberof Mojito.Services.Bonus.types
 */

/**
 * In progress bonus step status type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Bonus.types
 */
BonusTypes.IN_PROGRESS_STEP_STATUS = {
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE',
    IN_PROGRESS: 'IN_PROGRESS',
};

/**
 * Bonus filter type.
 *
 * @typedef BONUS_FILTER
 * @enum {string}
 * @memberof Mojito.Services.Bonus.types
 */
BonusTypes.BONUS_FILTER = {
    /* Active bonus filter*/
    ACTIVE: 'ACTIVE',
    /* Historic bonus filter*/
    HISTORIC: 'HISTORIC',
    /* In progress bonus filter*/
    IN_PROGRESS: 'IN_PROGRESS',
};

/**
 * Error codes when trying to add a free bet.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.BonusFreeBets.types
 */
BonusTypes.FREE_BET_ERROR_TYPE = {
    INVALID: 'INVALID',
    REDEEMED: 'REDEEMED',
    UNKNOWN: 'UNKNOWN',
    USED: 'USED',
};

/**
 * Bonus type.
 *
 * @typedef BONUS_TYPE
 * @enum {string}
 * @memberof Mojito.Services.Bonus.types
 */
BonusTypes.BONUS_TYPE = {
    /* Freebet bonus*/
    FREEBET: 'freebet',
    /* Prewager bonus*/
    PREWAGER: 'prewager',
    /* Cash bonus */
    CASH: 'cash',
    /* Token */
    TOKEN: 'token',
};

/**
 * Bonus Item.
 *
 * @typedef BonusItem
 * @type {object}
 * @property {string} id - Identifier.
 * @property {string} name - Name.
 * @property {string} description - Description.
 * @property {string} expireDate - Expiration date.
 * @property {Mojito.Services.Bonus.types.STATUS} status - Status.
 * @property {string} amount - Amount.
 * @memberof Mojito.Services.Bonus.types
 */

/**
 * FreeBet.
 *
 * @typedef FreeBet
 * @type {Mojito.Services.Bonus.types.BonusItem}
 * @property {string} conditions - Free bet conditions.
 * @property {string} remainingAmount - Remaining amount for splittable free bet.
 * @memberof Mojito.Services.Bonus.types
 */

/**
 * Campaign.
 *
 * @typedef Campaign
 * @type {Mojito.Services.Bonus.types.BonusItem}
 * @property {string} wagered - Wagered amount.
 * @property {string} remaining - Remaining amount.
 * @property {string} target - Targeted amount.
 * @property {string} startDate - Start date.
 * @property {string} bonusType - Bonus type.
 *
 * @memberof Mojito.Services.Bonus.types
 */

/**
 * InProgressBonus.
 *
 * @typedef InProgressBonus
 * @type {Mojito.Services.Bonus.types.BonusItem}
 * @property {string} id - Identifier.
 * @property {string} name - Name.
 * @property {string} description - Description.
 * @property {Mojito.Services.Bonus.types.STATUS} status - Status.
 * @property {Array<Mojito.Services.Bonus.types.InProgressBonusStep>} step - Bonus step.
 *
 * @memberof Mojito.Services.Bonus.types
 */

/**
 * Error.
 *
 * @typedef FreeBetError
 * @type {object}
 * @property {Mojito.Services.Bonus.types.FREE_BET_ERROR_TYPE} type - Error type.
 * @property {string} message - Error message.
 *
 * @memberof Mojito.Services.Bonus.types
 */
