import { createContext } from 'react';

/**
 * Analytics context builder class.
 * Used to build a properly structured analytics context.
 *
 * @class AnalyticsContextBuilder
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */

/**
 * Factory function to create an AnalyticsContextBuilder.
 *
 * @function ContextBuilder
 *
 * @returns {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextBuilder} The AnalyticsContextBuilder instance.
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */
export function ContextBuilder() {
    const context = {};
    return {
        /**
         * Assign an analytics path to the context.
         *
         * @function withAnalyticsPath
         * @instance
         *
         * @param {string} path - Analytics context path. See {@link Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition|AnalyticsContextDefinition}.
         * @returns {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextBuilder} The AnalyticsContextBuilder instance.
         *
         * @memberof Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextBuilder
         */
        withAnalyticsPath: function (path) {
            context.analyticsPath = path;
            return this;
        },
        /**
         * Assign analytics data to the context.
         *
         * @function withData
         * @instance
         *
         * @param {Function} data - Analytics data. See {@link Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition|AnalyticsContextDefinition}.
         * @returns {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextBuilder} The AnalyticsContextBuilder instance.
         *
         * @memberof Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextBuilder
         */
        withData: function (data) {
            context.data = data;
            return this;
        },
        /**
         * Builds an analytics context object.
         *
         * @function build
         * @instance
         *
         * @returns {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} The analytics context instance.
         *
         * @memberof Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextBuilder
         */
        build: function () {
            return context;
        },
    };
}

/**
 * Empty analytics context object.
 *
 * @constant
 * @type {Mojito.Core.Presentation.AnalyticsContextDefinition}
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */
export const NULL_ANALYTICS_CONTEXT = new ContextBuilder()
    .withAnalyticsPath(undefined)
    .withData({})
    .build();

const AnalyticsContext = createContext(NULL_ANALYTICS_CONTEXT);

/**
 * Mojito analytics context object created by <code>React.createContext()</code>.
 *
 * @typedef Context
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */
export default AnalyticsContext;
