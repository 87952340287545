import MojitoPresentation from 'mojito/presentation';
import { useParams, useOutletContext } from 'react-router-dom';
import MojitoModules from 'mojito/modules';

const { FlexPane } = MojitoPresentation.Components;
const { COUPONS } = MojitoModules.SingleLeagueCoupon.SingleLeagueCouponTypes;
const { module: Leagues, types: LeaguesTypes } = MojitoModules.Leagues;

import { useEffect, useState } from 'react';

export default function OutrightsView({ mojitoTools }) {
    const { config, emitPerformanceMark } = mojitoTools;
    const { sportId } = useParams();
    const contentInfo = useOutletContext();

    const [dataLoaded, setDataLoaded] = useState(false);

    const handleDataLoad = () => setDataLoaded(true);

    useEffect(() => {
        emitPerformanceMark('moduleRendered', dataLoaded);
    }, [emitPerformanceMark, dataLoaded]);

    return (
        <>
            <FlexPane config={config.container}>
                <Leagues
                    sportId={sportId}
                    type={LeaguesTypes.TYPE.TOP}
                    groupId={`${sportId}${LeaguesTypes.GROUP_ID.TOP_OUTRIGHTS}`}
                    pageType={COUPONS.OUTRIGHTS}
                    onDataLoad={handleDataLoad}
                    config={config.topLeagues}
                />
                <Leagues
                    sportId={sportId}
                    type={LeaguesTypes.TYPE.ALL}
                    groupId={contentInfo?.outrights?.group}
                    pageType={COUPONS.OUTRIGHTS}
                    onDataLoad={handleDataLoad}
                    config={config.allLeagues}
                />
            </FlexPane>
        </>
    );
}
