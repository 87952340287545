import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import { createSlice } from '@reduxjs/toolkit';

const IntentTypes = MojitoPresentation.Base.Intent.Types;
const intentActions = MojitoCore.Intents.actions;
const reduxInstance = MojitoCore.Services.redux;

/**
 * Defines the state of the app-settings module store.
 *
 * @typedef AppSettingsModuleState
 *
 * @property {boolean} isViewVisible - The visibility state of the app-settings view (i.e., non-/visible).
 *
 * @memberof Mojito.Modules.AppSettings
 */

/**
 * The name of the app-settings store that is used in the global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Modules.AppSettings
 */
export const STORE_KEY = 'modules/app-settings';

export const INITIAL_STATE = {
    isViewVisible: false,
};

export const { reducer, actions } = createSlice({
    name: STORE_KEY,
    initialState: INITIAL_STATE,

    reducers: {
        reset() {
            return { ...INITIAL_STATE };
        },

        showAppSettingsView(state) {
            state.isViewVisible = true;
        },

        hideAppSettingsView(state) {
            state.isViewVisible = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(intentActions.publishIntent, (state, { payload: intent }) => {
            if (intent.type === IntentTypes.APP_SETTINGS && !state.isViewVisible) {
                state.isViewVisible = true;
            }
        });
    },
});

/**
 * Actions related to the AppSettings module.
 *
 * @class AppSettingsActions
 * @name actions
 * @memberof Mojito.Modules.AppSettings
 */

/**
 * Reset AppSettings state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Modules.AppSettings.actions
 */

/**
 * Show the AppSettings view.
 *
 * @function showAppSettingsView
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Modules.AppSettings.actions
 */

/**
 * Hide the AppSettings view.
 *
 * @function hideAppSettingsView
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Modules.AppSettings.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
