import PropTypes from 'prop-types';
import ScrollPaneImpl, { onResizeNoop } from './scroll-pane.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * This component exposes content scroll functionality. It allows both controlled scrolling,
 * through the 'targetPosition' prop, as well as uncontrolled scrolling when the
 * 'nativeScroll' config flag is set.
 * <br>
 * Conflicts that can arise when uncontrolled scrolling contradicts the controlled 'targetPosition'
 * is expected to be handled by the ScrollPane's parent component. A typical approach is to listen
 * to the scroll changes through the 'onScroll' callback, and react by updating the 'targetPosition'
 * prop value.
 *
 * @example <caption>Example:  A scroll pane that keeps the selected child item is view:</caption>
 * <ScrollPane targetPosition={this.getSelectedItemPos(this.state.selectedItemId)}>
 *     <Item ref={this.refs[0]}>Child 0</Item>
 *     <Item ref={this.refs[1]}>Child 1</Item>
 * </ScrollPane>
 *
 * @class ScrollPane
 * @memberof Mojito.Presentation.Components
 */

/**
 * @param {object} scrollData
 * @param {number} scrollData.scrollPosition - The current scroll position.
 * @param {number} scrollData.maxScrollPosition - The maximum scroll position.
 * @param {number} scrollData.viewportSize - The size of the viewport, i.e., the region in which content is visible.
 *
 * @callback Mojito.Presentation.Components.ScrollPane.changeCallback
 */

/**
 * PropTypes for `ScrollPane` component.
 *
 * @property {string} [class] - The className to add to the DOM element.
 * @property {number} [targetPosition = 0] - A desired scroll position. Determines the initial scroll position. When this value changes, the component will attempt to move the scroll position to the given value, but makes no guarantees about getting there; e.g., user actions could override the scrolling.
 * @property {Mojito.Presentation.Components.ScrollPane.changeCallback} [onInit = ()=>{}] - Invoked after the first render of the component.
 * @property {Mojito.Presentation.Components.ScrollPane.changeCallback} [onScroll = ()=>{}] - Invoked when the scroll position changes.
 * @property {Mojito.Presentation.Components.ScrollPane.changeCallback} [onScrollFinished = ()=>{}] - Invoked when the scroll action will be finished with a slight delay (default 100ms). Useful for capturing momentum scroll.
 * @property {Mojito.Presentation.Components.ScrollPane.onTouchMove} [onTouchMove = ()=>{}] - Invoked when the component raises touch move event.
 * @property {Mojito.Presentation.Components.ScrollPane.changeCallback} [onResize = ()=>{}] - Invoked when the component's size in the scroll direction changes.
 * @property {string} [id] - The id to add to the DOM element.
 *
 * @memberof Mojito.Presentation.Components.ScrollPane
 */
const propTypes = {
    class: PropTypes.string,
    targetPosition: PropTypes.number,
    onInit: PropTypes.func,
    onScroll: PropTypes.func,
    onScrollFinished: PropTypes.func,
    onTouchMove: PropTypes.func,
    id: PropTypes.string,
    onResize: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const defaultProps = {
    onInit: noop,
    onScroll: noop,
    onScrollFinished: noop,
    onTouchMove: noop,
    onResize: onResizeNoop,
    targetPosition: 0,
};

export default MojitoCore.Presentation.UIView(
    'ScrollPane',
    ScrollPaneImpl,
    propTypes,
    defaultProps
);
