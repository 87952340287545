export default class ResourceHandler {
    static init(resourceConfig) {
        ResourceHandler.config = resourceConfig;
    }

    static getImage(path) {
        if (ResourceHandler._isExternalPath(path)) {
            return path;
        }

        return ResourceHandler.config.getImage(path);
    }

    static getFixture(path) {
        if (ResourceHandler._isExternalPath(path)) {
            return path;
        }
        return ResourceHandler.config.getFixture(path);
    }

    static _isExternalPath(path) {
        return path && path.startsWith('http');
    }
}
