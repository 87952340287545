import PropTypes from 'prop-types';
import SelectionButtonViewImpl from './selection-button-view.jsx';
import MojitoCore from 'mojito/core';
import SelectionButtonTypes from 'modules/selection-button/types/index.js';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * View to a display a market selection with selection name and price.
 * Includes indication of price changes as they increase / decrease.
 *
 * @class SelectionButtonView
 * @name view
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.SelectionButton
 */

/**
 * Callback fired on selection button click.
 *
 * @callback Mojito.Modules.SelectionButton.view.clickCallback
 */

/**
 * SelectionButtonView prop types.
 *
 * @property {Mojito.Modules.SelectionButton.types.STATES} [state = Mojito.Modules.SelectionButton.types.STATES.INACTIVE] - State of the selection button.
 * @property {object} selection - The selection to display.
 * @property {object} event - Parent event.
 * @property {string} marketStatus - Parent market status.
 * @property {string} marketType - Parent market type.
 * @property {string} [noPricesAvailableString] - If the selection contains no prices the default behavior is to display the translatable string GENERIC.UNAVAILABLE. Setting this will override the default string.
 * @property {string} [suspendedString] - String to show instead of a lock icon, if the selection is suspended.
 * @property {Mojito.Modules.SelectionButton.types.NAME_DISPLAY} [nameDisplay = undefined] - Display type to use a for the selection name.
 * @property {object | Function} [buttonRef] - Ref to the rendered element button.
 * @property {Mojito.Modules.SelectionButton.view.clickCallback} [onClick = () => {}] - Callback fired on button click.
 * @property {Function} [onOverflowText = () => {}] - Callback fired when text overflown.
 *
 * @memberof Mojito.Modules.SelectionButton.view
 */
export const propTypes = {
    state: PropTypes.oneOf(Object.values(SelectionButtonTypes.STATES)),
    selection: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    marketStatus: PropTypes.string,
    marketType: PropTypes.string,
    nameDisplay: PropTypes.oneOf(Object.values(SelectionButtonTypes.NAME_DISPLAY)),
    noPricesAvailableString: PropTypes.string,
    suspendedString: PropTypes.string,
    buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onClick: PropTypes.func,
    onOverflowText: PropTypes.func,
};

const defaultProps = {
    nameDisplay: undefined,
    onClick: noop,
    state: SelectionButtonTypes.STATES.INACTIVE,
    onOverflowText: noop,
};

export default UIView('SelectionButtonView', SelectionButtonViewImpl, propTypes, defaultProps);
