import MojitoCore from 'mojito/core';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class PageSplitLayout extends UIViewImplementation {
    renderChild(idx) {
        // Ensure child exist
        if (!this.props.children || idx >= this.props.children.length) {
            return null;
        }

        return this.props.children[idx];
    }

    renderPrimary() {
        return (
            <div style={this.style.primary} className="ta-primary">
                {this.renderChild(0)}
            </div>
        );
    }

    renderSecondary() {
        if (!this.config.secondary.show) {
            return null;
        }

        return (
            <div style={this.style.secondary} className="ta-secondary">
                {this.renderChild(1)}
            </div>
        );
    }

    render() {
        return (
            <div style={this.style.container} className="ta-PageSplitLayout">
                {this.renderSecondary()}
                {this.renderPrimary()}
            </div>
        );
    }
}

PageSplitLayout.getStyle = function (config /* , applicationMode, merge */) {
    const style = {
        container: {
            display: 'flex',
        },
        secondary: {
            flex: `0 0 ${config.secondary.width}`,
            width: config.secondary.width,
            margin: config.secondary.margin,
            padding: config.secondary.padding,
        },
        primary: {
            flex: '1 1 auto',
            width: 0,
            minWidth: config.primary.minWidth,
            padding: config.primary.padding,
        },
    };

    return style;
};
