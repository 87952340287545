import SpinnerImpl from './spinner.jsx';
import MojitoCore from 'mojito/core';

/**
 * The Spinner component is used to indicate that the application is performing a task or action.
 *
 * @class Spinner
 * @memberof Mojito.Presentation.Components
 */

export default MojitoCore.Presentation.UIView('Spinner', SpinnerImpl, {});
