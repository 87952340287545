import { STORE_KEY, INITIAL_STATE } from './slice.js';
import reduxInstance from 'core/services/redux-instance';

/**
 * Cookie consent store selectors.
 *
 * @class CookieConsentSelectors
 * @name selectors
 * @memberof Mojito.Services.CookieConsent
 */

/**
 * Selects cookie consent  state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Cookie consent state.
 * @memberof Mojito.Services.CookieConsent.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects cookie consent state.
 *
 * @function selectCookieConsent
 *
 * @param {object} [state] - Application state object.
 * @returns {boolean} Cookie consent state.
 * @memberof Mojito.Services.CookieConsent.selectors
 */

export const selectCookieConsent = state => selectState(state).isConsentGiven;
