import { memo } from 'react';
import Text from 'presentation/components/text/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import ChangedValueIndicator from 'presentation/components/changed-value-indicator/index.jsx';

// eslint-disable-next-line react/display-name
const GenericBetFundsInfo = memo(props => {
    const { label, value, previousValue, showIcon, mojitoTools } = props;
    const { config } = mojitoTools;

    return (
        <FlexPane config={config.container} class={props.class}>
            <Text config={config.label}>{label}</Text>
            {showIcon && <Image class="ta-GenericBetFundsInfoIcon" config={config.icon} />}
            {previousValue ? (
                <ChangedValueIndicator
                    config={config.changedValueIndicator}
                    newValue={value}
                    previousValue={previousValue}
                />
            ) : (
                <Text class={props.valueClass} config={config.value}>
                    {value}
                </Text>
            )}
        </FlexPane>
    );
});

export default GenericBetFundsInfo;
