import PropTypes from 'prop-types';
import EarlyPayoutIndicatorImpl from './early-payout-indicator.jsx';
import MojitoCore from 'mojito/core';

/**
 * Early Payout Indicator component. Displays a clickable button which opens a popup dialog to show rule/rules
 * that needs to be fulfilled for the bet to payout early.
 *
 * @class EarlyPayoutIndicator
 * @memberof Mojito.Presentation.Components
 */

/**
 * Early Payout Indicator  prop types.
 *
 * @property {Array<object>} [rules = []] - List of rules to show inside popup.
 * @memberof Mojito.Presentation.Components.EarlyPayoutIndicator
 */

const propTypes = {
    rules: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
    rules: [],
};

export default MojitoCore.Presentation.UIView(
    'EarlyPayoutIndicator',
    EarlyPayoutIndicatorImpl,
    propTypes,
    defaultProps
);
