/**
 * User info types.
 *
 * @class UserInfoTypes
 * @name types
 * @memberof Mojito.Services.UserInfo
 */
export default class UserInfoTypes {}

/**
 * This is the date time format from QuantumService.
 *
 * @typedef MAP_DATE_TIME_FORMAT
 * @type {string}
 * @memberof Mojito.Services.UserInfo.types
 */
UserInfoTypes.MAP_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

/**
 * This is the default pending period in hours.
 *
 * @typedef DEFAULT_PENDING_PERIOD_IN_HOURS
 * @type {number}
 * @memberof Mojito.Services.UserInfo.types
 */
UserInfoTypes.DEFAULT_PENDING_PERIOD_IN_HOURS = 24;

/**
 * Automatic price change acceptance preferences.
 * The values correspond to Quantum UserService values for the AutomaticOddsChangeSettings parameter.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.UserInfo.types
 */
UserInfoTypes.AUTOMATIC_PRICE_CHANGE_ACCEPTANCE_PREFERENCE = {
    ALL: 'ALL',
    HIGHER: 'HIGHER',
    LOWER: 'LOWER',
    NONE: 'NONE',
};

/**
 * Balance types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.UserInfo.types
 */
UserInfoTypes.BALANCE_TYPES = {
    WITHDRAWABLE: 'withdrawable',
    BONUS: 'bonus',
    FREE_BET: 'freebet',
};

/**
 * Due diligence flags.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.UserInfo.types
 */
UserInfoTypes.DUE_DILIGENCE_FLAGS = {
    REQUIRE_DOCUMENTS: 'require_documents',
};
