import { useState, useEffect, useRef } from 'react';
import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import { Transition } from 'react-transition-group';

const uiStyle = MojitoCore.Presentation.uiStyle;

const { useStringResolver } = MojitoCore.Presentation.Hooks;

const RedCard = props => {
    const {
        cardsNumber,
        isAnimationEnabled,
        mojitoTools: { style, config, l10n },
    } = props;
    const { bannerAnimationDuration } = config;

    const [showBannerAnimation, setShowBannerAnimation] = useState(false);
    const [useAnimatedStyle, setUseAnimatedStyle] = useState(false);
    const stringResolver = useStringResolver(l10n);
    const [prevCardsNumber, setCardsNumber] = useState(cardsNumber);
    const handleBannerAnimationEnded = () => {
        setUseAnimatedStyle(true);
    };
    const timeoutRef = useRef();

    useEffect(() => {
        if (cardsNumber <= prevCardsNumber || !isAnimationEnabled) {
            setCardsNumber(cardsNumber);
        } else {
            setShowBannerAnimation(true);
            timeoutRef.current = setTimeout(() => {
                setShowBannerAnimation(false);
                setUseAnimatedStyle(false);
                setCardsNumber(cardsNumber);
            }, bannerAnimationDuration);
        }
        return () => timeoutRef.current && clearTimeout(timeoutRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardsNumber, isAnimationEnabled, bannerAnimationDuration]);

    const isCardsNumberShown = config.isShownOneRedCard || cardsNumber > 1;

    const renderCardBanner = (state, redCardStringStyle) => {
        return (
            <div className="ta-redCardBanner" style={redCardStringStyle[state]}>
                {stringResolver.resolveString('$RED_CARD.BANNER')}
            </div>
        );
    };
    const transitionRef = useRef();

    return cardsNumber ? (
        <FlexPane config={config.container}>
            <Transition
                nodeRef={transitionRef}
                in={showBannerAnimation}
                appear
                timeout={bannerAnimationDuration}
                onExited={handleBannerAnimationEnded}
            >
                {state => renderCardBanner(state, style.redCardBanner)}
            </Transition>
            <div
                className="ta-redCard"
                style={useAnimatedStyle ? style.redCardAnimation : style.redCard}
            >
                {isCardsNumberShown && (
                    <span className="ta-redCardText" style={style.redCardText}>
                        {prevCardsNumber}
                    </span>
                )}
            </div>
        </FlexPane>
    ) : null;
};

RedCard.getStyle = (config, applicationMode, merge) => {
    const {
        style: { card, text, bannerContainer },
    } = config;
    const redCards = {
        position: 'absolute',
        zIndex: 999,
        ...bannerContainer,
    };
    const redCardBannerHidden = { opacity: 0, ...redCards };
    const redCardBannerVisible = { opacity: 1, ...redCards };

    const animation = uiStyle.createCssAnimation(
        config,
        'red-card-banner-animation',
        config.animation
    );
    const redCardAnimation = merge(card, animation);

    return {
        redCardBanner: {
            unmounted: redCardBannerHidden,
            entering: redCardBannerVisible,
            entered: redCardBannerVisible,
            exiting: redCardBannerVisible,
            exited: redCardBannerHidden,
        },
        redCard: card,
        redCardText: text,
        redCardAnimation,
    };
};

export default RedCard;
