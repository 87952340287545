/**
 * Class defining meeting data descriptors.
 *
 * @class MeetingsDataDescriptors
 * @name descriptors
 * @memberof Mojito.Services.SportsContent.Meetings
 */
export default class MeetingsDataDescriptors {
    /**
     * Factory method for creating a meeting data descriptor.
     *
     * @param {string} meetingId - The meeting id.
     * @returns {{dataType: string, meetingId: *}} Meeting data descriptor.
     * @function Mojito.Services.SportsContent.Meetings.descriptors#createMeetingDescriptor
     * @static
     */
    static createMeetingsDescriptor(meetingId) {
        return {
            dataType: MeetingsDataDescriptors.DATA_TYPES.MEETING,
            meetingId: meetingId,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} MEETING - Meeting data descriptor type.
 *
 * @memberof Mojito.Services.SportsContent.Meetings.descriptors
 */
MeetingsDataDescriptors.DATA_TYPES = {
    MEETING: 'meeting',
};
