import MojitoServices from 'mojito/services';

const { utils: EventUtils } = MojitoServices.SportsContent.Events;

/**
 * Helper class to work with selections.
 *
 * @class SelectionHelper
 * @memberof Mojito.Presentation.Utils
 */
export default class SelectionHelper {
    /**
     * Compares selections by price decimalLabel for sorting.
     *
     * @param {object} selection1 - The first selection to compare.
     * @param {object} selection2 - The second selection to compare.
     *
     * @returns {number} Returns -1 if 'selection1' should be sorted before 'selection2', 1 if 'selection2' should be sorted before 'selection1', and 0 if they are equal.
     * @function Mojito.Presentation.Utils.SelectionHelper.compareByPrice
     */
    static compareByPrice(selection1, selection2) {
        const price1 = EventUtils.getPrice(selection1.prices);
        const price2 = EventUtils.getPrice(selection2.prices);
        if (price1 || price2) {
            if (!price1) {
                return 1;
            }
            if (!price2) {
                return -1;
            }
            return parseFloat(price1.decimalLabel) - parseFloat(price2.decimalLabel);
        }
        return 0;
    }

    /**
     * Compares selections by sort order for sorting.
     *
     * @param {object} selection1 - The first selection to compare.
     * @param {object} selection2 - The second selection to compare.
     *
     * @returns {number} Returns -1 if 'selection1' should be sorted before 'selection2', 1 if 'selection2' should be sorted before 'selection1', and 0 if they are equal.
     * @function Mojito.Presentation.Utils.SelectionHelper.compareBySortOrder
     */
    static compareBySortOrder(selection1, selection2) {
        return selection1.sortOrder - selection2.sortOrder;
    }

    /**
     * Compare selections by handicapLabel for sorting.
     *
     * @param {object} selection1 - The first selection to compare.
     * @param {object} selection2  - The second selection to compare.
     *
     * @returns {number} Returns -1 if 'selection1' should be sorted before 'selection2', 1 if 'selection2' should be sorted before 'selection1', and 0 if they are equal.
     * @function Mojito.Presentation.Utils.SelectionHelper.compareByHandicap
     */
    static compareByHandicap(selection1, selection2) {
        if (!selection1.handicapLabel || !selection2.handicapLabel) {
            return 0;
        }
        // Handicap can be not just number but also complex value, e.g. "0 / +0.5".
        // In that case we will just compare them as a strings to keep predictable order within the group.
        if (isNaN(selection1.handicapLabel) || isNaN(selection2.handicapLabel)) {
            return selection1.handicapLabel.localeCompare(selection2.handicapLabel);
        }

        const handicap1 = parseFloat(selection1.handicapLabel);
        const handicap2 = parseFloat(selection2.handicapLabel);

        return handicap1 - handicap2;
    }

    /**
     * Compare selections by type for sorting.
     *
     * @param {object} selection1 - The first selection to compare.
     * @param {object} selection2 - The second selection to compare.
     *
     * @returns {number} Returns -1 if 'selection1' should be sorted before 'selection2', 1 if 'selection2' should be sorted before 'selection1', and 0 if they are equal.
     * @function Mojito.Presentation.Utils.SelectionHelper.compareByUnknownTypeNumber
     */
    static compareByUnknownTypeNumber(selection1, selection2) {
        if (!selection1.type || !selection2.type) {
            return 0;
        }

        const typeNumber1 = SelectionHelper._getUnknownTypeNumber(selection1);
        const typeNumber2 = SelectionHelper._getUnknownTypeNumber(selection2);

        return typeNumber1 - typeNumber2;
    }

    /**
     * Creates an accumulative comparator function that can be used as an argument for `Array.sort()` method.
     * This function is especially useful when needing to compare by multiple properties during a sort operation.
     *
     * @param {...Function} comparators - One or more comparator functions to be incorporated into the cumulative comparison.
     *
     * @returns {Function} An accumulative comparator function that can compare based on multiple properties.
     * @function Mojito.Presentation.Utils.SelectionHelper.composeComparators
     */
    static composeComparators(...comparators) {
        return (selection1, selection2) => {
            return comparators.reduce(
                (result, comparator) => result || comparator(selection1, selection2),
                0
            );
        };
    }

    static _getUnknownTypeNumber(selection) {
        const tokens = selection.type.split('-');

        if (tokens.length === 2) {
            return parseInt(tokens[1]);
        }

        return 0;
    }

    /**
     * Resolves and sorts the selections based on the specifications in the provided market descriptor.
     *
     * This function performs sorting based on the market descriptor's type columns. If no type columns are provided in the market descriptor, it defaults to sorting by order. It also has the ability to perform an alternative sort based on the home away type columns depending on the provided flag.
     *
     * @param {object} marketDescriptor - The descriptor object representing the market.
     * @param {Array<object>} selections - An array of selection objects that require resolution and potential sorting.
     * @param {boolean} switchHomeAway - A flag indicating whether the selections should be sorted by the home away type columns.
     *
     * @returns {Array<object>} An array of resolved and sorted selection objects.
     * @function Mojito.Presentation.Base.SelectionHelper.resolveSelections
     */
    static resolveSelections(marketDescriptor, selections, switchHomeAway) {
        const cloneSelections = [...selections];

        // If no typeColumns defined in the marketDescriptor then sort selections by order.
        if (!marketDescriptor || !marketDescriptor.typeColumns) {
            cloneSelections.sort(SelectionHelper.compareBySortOrder);
            if (switchHomeAway) {
                cloneSelections.reverse();
            }
            return cloneSelections;
        }

        let typeColumns = marketDescriptor.typeColumns;

        if (switchHomeAway) {
            typeColumns = SelectionHelper.getSwitchedHomeAwayTypeColumns(typeColumns);
        }

        return SelectionHelper.sortSelectionsByTypeColumns(cloneSelections, typeColumns);
    }

    /**
     * Sorts an array of selections based on the provided type columns.
     *
     * This function sorts an array of selection objects based on the order of the type columns provided in the market descriptor object.
     *
     * @param {Array<object>} selections - An array of selection objects that need to be sorted.
     * @param {Array<string>} typeColumns - An array of type columns in the market descriptor object used as the criteria for sorting selections.
     *
     * @returns {Array<object>} An array of sorted selection objects based on provided type columns.
     * @function Mojito.Presentation.Base.SelectionHelper.sortSelectionsByTypeColumns
     */
    static sortSelectionsByTypeColumns(selections, typeColumns) {
        return selections.sort(
            (selection1, selection2) =>
                typeColumns.indexOf(selection1.type) - typeColumns.indexOf(selection2.type)
        );
    }

    /**
     * Returns the switched versions of home and away type columns from the provided market descriptor type columns.
     *
     * This function takes an array with the market descriptor type columns and switches home and away columns.
     *
     * @param {Array<string>} typeColumns - An array containing the market descriptor type columns which need to be switched.
     *
     * @returns {Array<object>} An array containing switched home and away type columns.
     * @function Mojito.Presentation.Base.SelectionHelper.getSwitchedHomeAwayTypeColumns
     */
    static getSwitchedHomeAwayTypeColumns(typeColumns) {
        const returnTypeColumns = typeColumns.slice();

        let homeIndex = -1;
        let awayIndex = -1;
        typeColumns.forEach((typeColumn, index) => {
            switch (typeColumn) {
                case '1':
                case 'H':
                case 'P1':
                case '1X':
                    homeIndex = index;
                    break;
                case '2':
                case 'A':
                case 'P2':
                case 'X2':
                    awayIndex = index;
                    break;
                default:
                    break;
            }
        });

        if (homeIndex >= 0 && awayIndex >= 0) {
            returnTypeColumns[homeIndex] = typeColumns[awayIndex];
            returnTypeColumns[awayIndex] = typeColumns[homeIndex];
        }

        return returnTypeColumns;
    }
}
