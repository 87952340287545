import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import { bindAll } from 'mojito/utils';
import { connect } from 'react-redux';
import SearchResultItem from 'application/components/search/search-result-item/index.jsx';
import RecentSearch from 'application/components/search/recent-search/index.jsx';

const { SearchInput, Popup, FlexPane, AbsolutePane, Text, InfiniteList } =
    MojitoPresentation.Components;
const { UIViewImplementation, bodyScrollManager } = MojitoCore.Presentation;
const searchActions = MojitoServices.Search.actions;
const log = MojitoCore.logger.get('SearchView');

const RENDER_MODE = {
    NOTHING: 'nothing',
    PENDING: 'pending',
    PROMPT: 'prompt',
    NOT_ENOUGH_SEARCH_CHARS: 'notEnoughSearchChars',
    NO_RESULTS_FOUND: 'noResultFound',
    SHOW_RESULTS: 'showResults',
};

class SearchView extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.state = {
            active: this.props.initiallyActive,
            searchString: '',
            renderMode: RENDER_MODE.PROMPT,
            hasNextPage: true,
        };

        this.searchRequestDelay = this.config.searchRequestDelay * 1000;
        this.searchRequestTimer = undefined;

        bindAll(this, [
            'onFocus',
            'onChange',
            'onKeyEnter',
            'onClose',
            'performSearch',
            'search',
            'fetchMoreResults',
            'afterRedirection',
            'onRecentSearchItemClick',
        ]);
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchResult !== prevProps.searchResult) {
            const { hasNextPage } = this.props.searchResult;
            this.setState({
                renderMode: this.getRenderMode(),
                hasNextPage,
            });
        }

        if (this.props.searchInputResetState !== prevProps.searchInputResetState) {
            this.onChange('');
        }
    }

    componentWillUnmount() {
        this.stopSearchRequestDelay();
    }

    getRenderMode() {
        const { items, pending } = this.props.searchResult;
        // If pending, but items are here, let InfiniteList to show animation
        if (items) {
            return items.length ? RENDER_MODE.SHOW_RESULTS : RENDER_MODE.NO_RESULTS_FOUND;
        }
        // If not pending, should be error. Do not show to user, just report no results
        return pending ? RENDER_MODE.PENDING : RENDER_MODE.NO_RESULTS_FOUND;
    }

    haveEnoughSearchChars() {
        return this.state.searchString.length >= this.config.minRequiredChars;
    }

    performSearch() {
        if (this.haveEnoughSearchChars()) {
            this.search(this.state.searchString);
        } else {
            this.setState({ renderMode: RENDER_MODE.NOT_ENOUGH_SEARCH_CHARS });
        }
    }

    fetchMoreResults() {
        this.props.dispatch(searchActions.searchNext(this.state.searchString));
    }

    onRecentSearchItemClick(searchString) {
        this.emitAnalytics('searchHistoryItemClicked', searchString);
        this.search(searchString);
    }

    search(searchString) {
        this.setState({ searchString, renderMode: RENDER_MODE.PENDING });
        this.stopSearchRequestDelay();
        this.props.dispatch(
            searchActions.search(searchString, this.appContext().systemSettings().language)
        );
    }

    startSearchRequestDelay() {
        this.searchRequestTimer = setTimeout(this.performSearch, this.searchRequestDelay);
    }

    stopSearchRequestDelay() {
        if (this.searchRequestTimer) {
            clearTimeout(this.searchRequestTimer);
            this.searchRequestTimer = undefined;
        }
    }

    renderResults() {
        const scrollTargetId = 'resultsContainer';
        const scrollableTarget = this.config.customScrollableTarget || scrollTargetId;
        return (
            <div id={scrollTargetId} style={this.style.resultsContainer}>
                <InfiniteList
                    config={this.config.infiniteList}
                    fetchMore={this.fetchMoreResults}
                    hasMore={this.state.hasNextPage}
                    scrollableTarget={scrollableTarget}
                >
                    {this.props.searchResult.items.map(item => (
                        <SearchResultItem
                            key={item.id}
                            {...item}
                            afterRedirection={this.afterRedirection}
                        />
                    ))}
                </InfiniteList>
            </div>
        );
    }

    renderMessage(message) {
        return (
            <FlexPane config={this.config.messageContainer}>
                <Text config={this.config.messageText}>{message}</Text>
            </FlexPane>
        );
    }

    renderPrompt() {
        const { recentItems, onRemoveRecentSearch } = this.props;
        if (recentItems.length === 0) {
            return this.renderMessage(this.resolveString('$SEARCH_VIEW.GREETING'));
        }

        return (
            <RecentSearch
                items={recentItems}
                onItemClick={this.onRecentSearchItemClick}
                onItemRemoveClick={onRemoveRecentSearch}
            />
        );
    }

    renderContents() {
        switch (this.state.renderMode) {
            case RENDER_MODE.NOTHING:
                return null;
            case RENDER_MODE.PENDING:
                return null;
            case RENDER_MODE.PROMPT:
                return this.renderPrompt();
            case RENDER_MODE.NOT_ENOUGH_SEARCH_CHARS:
                return this.renderMessage(
                    this.resolveAndFormatString(
                        '$SEARCH_VIEW.NOT_ENOUGH_CHARS',
                        this.config.minRequiredChars
                    )
                );
            case RENDER_MODE.NO_RESULTS_FOUND:
                return this.renderMessage(
                    this.resolveAndFormatString(
                        '$SEARCH_VIEW.NO_RESULTS_FOUND',
                        `'${this.state.searchString}'`
                    )
                );
            case RENDER_MODE.SHOW_RESULTS:
                return this.renderResults();
            default:
                log.error(`renderContents: wrong value of ${this.state.renderMode}`);
        }
    }

    renderResultArea() {
        if (!this.state.active) {
            return null;
        }
        if (this.config.showPopover) {
            return (
                <Popup
                    visible={true}
                    config={this.config.searchResultsPopup}
                    class="ta-searchResultsPopup"
                    captureOutsideClicks={true}
                    onOutsideClick={this.onClose}
                >
                    <FlexPane config={this.config.resultsContentContainerPopup}>
                        {this.renderContents()}
                    </FlexPane>
                </Popup>
            );
        }
        return (
            <AbsolutePane
                class="ta-resultContentContainer"
                config={this.config.resultsContentContainer}
            >
                {this.renderContents()}
            </AbsolutePane>
        );
    }

    onFocus() {
        !this.state.active && this.emitAnalytics('searchInputFocused');
        this.setState({ active: true });
    }

    onChange(searchString) {
        this.stopSearchRequestDelay();
        this.setState({ searchString });
        if (searchString === '') {
            this.setState({ renderMode: RENDER_MODE.PROMPT });
        } else {
            this.setState({ renderMode: RENDER_MODE.NOTHING });
            this.startSearchRequestDelay();
        }
    }

    onKeyEnter() {
        this.stopSearchRequestDelay();
        this.performSearch();
    }

    onClose() {
        this.props.onSearchClose();
        this.setState({ active: false });
    }

    afterRedirection() {
        bodyScrollManager.reset();
        this.props.dispatch(searchActions.addRecentSearch({ query: this.state.searchString }));
        this.onClose();
    }

    render() {
        return (
            <div className="SearchView" style={this.style.searchBox}>
                <SearchInput
                    key="input"
                    config={this.config.searchInput}
                    placeholderValue={this.resolveString('$SEARCH_VIEW.INPUT_PLACEHOLDER')}
                    default={this.state.searchString}
                    showSpinner={this.state.renderMode === RENDER_MODE.PENDING}
                    onFocus={this.onFocus}
                    onChange={this.onChange}
                    onKeyEnter={this.onKeyEnter}
                    initiallyActive={this.props.initiallyActive}
                />
                {this.renderResultArea()}
            </div>
        );
    }
}

SearchView.getStyle = function (config, mode, merge) {
    return {
        searchBox: config.style.searchBox,
        resultsContainer: merge(config.style.resultsContainer, {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
        }),
    };
};

export default connect()(SearchView);
