/**
 * @class BetslipTypes
 * @name types
 * @memberof Mojito.Services.Betslip
 */
export default class BetslipTypes {}

/**
 * Markets available to place a bet on.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.MARKET_BET_TYPES = {
    /** To be used for all sports _except_ racing (horses, greyhound). */
    STANDARD_MARKET: 'STANDARD_MARKET',
    /** This market and the one's below are for racing. */
    WIN_MARKET: 'WIN_MARKET',
    WIN_OR_EACHWAY_MARKET: 'WIN_OR_EACHWAY_MARKET',
    PLACE_MARKET: 'PLACE_MARKET',
    FORECAST_MARKET: 'FORECAST_MARKET',
    TRICAST_MARKET: 'TRICAST_MARKET',
    EACHWAY_MARKET: 'EACHWAY_MARKET',
};

/**
 * Placement status.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.PLACEMENT_STATUS = {
    OPEN: 'OPEN',
    REFERRED: 'REFERRED',
    OFFERED: 'OFFERED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    REFERRED_AUTO: 'REFERRED_AUTO',
};

/**
 * Betslip status. Contains all entries from PLACEMENT_STATUS enum.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.BETSLIP_STATUS = {
    /** This status is applied during betslip placement, waiting for response from the server. */
    PENDING_PLACE: 'PENDING_PLACE',
    /** This status is applied during betslip saving, waiting for response from the server. */
    UPDATING: 'UPDATING',
    /** This status is typically applied on API failure. */
    FAILED: 'FAILED',
    ...BetslipTypes.PLACEMENT_STATUS,
};

/**
 * Betslip modes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.BETSLIP_MODE = {
    UK: 'uk',
    EU: 'eu',
};

/**
 * Betslip action types.
 *
 * @readonly
 * @enum {string}
 * @property {string} SET_STAKE - = 'setStake' - Set stake action.
 * @property {string} CLEAR_BETSLIP - = 'clearBetslip' - Clear betslip action.
 * @property {string} ADD_PART - = 'addPart' - Add part action.
 * @property {string} DESERIALIZE - = 'deserialize' - Deserialize action.
 *
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.ACTIONS = {
    SET_STAKE: 'setStake',
    CLEAR_BETSLIP: 'clearBetslip',
    ADD_PART: 'addPart',
    DESERIALIZE: 'deserialize',
};

/**
 * Betslip part.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.BETSLIP_PART = {
    ITEMS: 'ITEMS',
    SETTINGS: 'SETTINGS',
};

/**
 * Stake group name.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.STAKE_GROUP_NAME = {
    SINGLES: 'singles',
    MULTIPLES: 'multiples',
    SYSTEMS: 'systems',
    TEASERS: 'teasers',
    BANKERS: 'bankers',
    DEFAULT: 'default',
};

/**
 * Price change policy.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.PRICE_CHANGE_POLICY = {
    /** Allow lower price change policy. */
    ALLOW_LOWER: 'ALLOW_LOWER',
    /** Allow higher price change policy. */
    ALLOW_HIGHER: 'ALLOW_HIGHER',
    /** Not allow price change policy. */
    NOT_ALLOW: 'NOT_ALLOW',
};

/**
 * Error source type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.ERROR_SOURCE_TYPE = {
    GENERIC: 'GENERIC',
    BET: 'BET',
    EVENT: 'EVENT',
    MARKET: 'MARKET',
    SELECTION: 'SELECTION',
};

/**
 * Error codes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.ERROR_CODE = {
    BET_REJECTED: 'BET_REJECTED',
    EVENT_BETTING_RESTRICTION: 'EVENT_BETTING_RESTRICTION',
    EVENT_STARTED: 'EVENT_STARTED',
    FREE_BET_NOT_APPLICABLE: 'FREE_BET_NOT_APPLICABLE',
    FREE_BET_WITH_MULTI_LINED_GRP_SGL: 'FREE_BET_WITH_MULTI_LINED_GRP_SGL',
    FREE_BET_WITH_MORE_THAN_ONE_BET: 'FREE_BET_WITH_MORE_THAN_ONE_BET',
    FREE_BET_WITH_MULTI_LINED_BET: 'FREE_BET_WITH_MULTI_LINED_BET',
    FREE_BET_NOT_MATCH_STAKE: 'FREE_BET_NOT_MATCH_STAKE',
    HANDICAP_CHANGE: 'HANDICAP_CHANGE',
    MAX_ACC_FOLD_BREACH: 'MAX_ACC_FOLD_BREACH',
    MAX_SELECTION_BREACH: 'MAX_SELECTION_BREACH',
    MAX_BETSLIP_STAKE_BREACH: 'MAX_BETSLIP_STAKE_BREACH',
    MAX_PAYOUT_BREACH: 'MAX_PAYOUT_BREACH',
    MAX_STAKE_BREACH: 'MAX_STAKE_BREACH',
    MIN_BETSLIP_STAKE_BREACH: 'MIN_BETSLIP_STAKE_BREACH',
    MIN_STAKE_BREACH: 'MIN_STAKE_BREACH',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
    ODDS_CHANGE: 'ODDS_CHANGE',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    NOT_COMBINABLE: 'NOT_COMBINABLE',
    RESPONSIBLE_GAMING_GENERIC: 'RESPONSIBLE_GAMING_GENERIC',
    RESPONSIBLE_GAMING_STAKING: 'RESPONSIBLE_GAMING_STAKING',
    STAKE_INVALID: 'STAKE_INVALID',
    STAKING_LIMIT_BREACH: 'STAKING_LIMIT_BREACH',
    SUSPENDED: 'SUSPENDED',
    TECHNICAL_FAILURE: 'TECHNICAL_FAILURE',
    UNCOMBINABLE_SELECTION: 'UNCOMBINABLE_SELECTION',
    MULTIPLIER_BREACH: 'MULTIPLIER_BREACH',
    NOT_ENOUGH_SELECTIONS: 'NOT_ENOUGH_SELECTIONS',
    INVALID_SELECTION: 'INVALID_SELECTION',
    TEASERS_UNAVAILABLE: 'TEASERS_UNAVAILABLE',
    TEASER_MIXED_SPORTS_NOT_ALLOWED: 'TEASER_MIXED_SPORTS_NOT_ALLOWED',
    FINISHED_EVENTS_REMOVED: 'FINISHED_EVENTS_REMOVED',
    UNKNOWN: 'UNKNOWN',
    BET_BUILDER_NON_COMBINABLE: 'BET_BUILDER_NON_COMBINABLE',
    MIN_COMBINATION_BREACH: 'MIN_COMBINATION_BREACH',
    PENDING_TO_LONG: 'PENDING_TO_LONG',
};

/**
 * Lists error codes that do not prevent the betslip from being committed.
 * For instance, place betslip actions are permitted.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.NON_BLOCKING_ERROR_CODES = [
    BetslipTypes.ERROR_CODE.UNKNOWN,
    BetslipTypes.TECHNICAL_FAILURE,
    BetslipTypes.ERROR_CODE.MULTIPLIER_BREACH,
    BetslipTypes.ERROR_CODE.MAX_ACC_FOLD_BREACH,
    BetslipTypes.ERROR_CODE.MAX_SELECTION_BREACH,
    BetslipTypes.ERROR_CODE.BET_BUILDER_NON_COMBINABLE,
    BetslipTypes.ERROR_CODE.MIN_COMBINATION_BREACH,
];

/**
 * Free bet constraints.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.CONSTRAINT_POLICY = {
    NONE: 'NONE',
    USABLE_TOKENS: 'USABLE_TOKENS',
};

/**
 * Price change types.
 *
 * @readonly
 * @typedef PRICE_CHANGE_TYPE
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.PRICE_CHANGE_TYPE = {
    HIGHER: 'higher',
    LOWER: 'lower',
};

/**
 * Overask actions.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.OVERASK_ACTION = {
    UPDATE: 'UPDATE',
    ACCEPT: 'ACCEPT',
    REJECT: 'REJECT',
    TIMEOUT: 'TIMEOUT',
};

/**
 * Overask update types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.OVERASK_UPDATE_TYPE = {
    BET: 'BET',
    BONUS: 'BONUS',
};

/**
 * Overask check statuses.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.OVERASK_CHECK_STATUS = {
    UNAVAILABLE: 'UNAVAILABLE',
    CHECKED: 'CHECKED',
    UNCHECKED: 'UNCHECKED',
};

/**
 * Types of selection part update.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.PART_UPDATE_FACTOR = {
    /** Odds update on selection. */
    ODDS: 'ODDS',
    /** Handicap update on selection. */
    HCAP: 'HCAP',
};

/**
 * Type of the teaser bet.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.TEASER_TYPE = {
    /** Low teaser type. Typically presented as smallest handicap transformation value. */
    LOW: 'LOW',
    /** Medium teaser type. Typically presented as medium handicap transformation value. */
    MEDIUM: 'MEDIUM',
    /** High teaser type. Typically presented as highest handicap transformation value. */
    HIGH: 'HIGH',
};

/**
 * The type of the betslip storage, used to persist betslip data between browser sessions.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.BETSLIP_STORAGE_TYPE = {
    /** Betslip data storage key. Usually contains stored betslip state, version and date time when it was stored. */
    DATA: 'data',
    /** Betslip settings storage key. Contains selected user preferences. */
    SETTINGS: 'settings',
};

/**
 * Enum determining the different betting contexts that selection can represent while added to the betslip.
 *
 * @typedef BETTING_CONTEXT
 * @property {string} DEFAULT - Default betting context. Used when normal selection is added to the betslip.
 * @property {string} PRE_BUILT_MATCH_ACCA_BET - Prebuilt match acca betting context. Used for exotic selections, typically, present in prebuilt bet cards from CMS.
 * @memberof Mojito.Services.Betslip.types
 */
BetslipTypes.BETTING_CONTEXT = {
    DEFAULT: 'DEFAULT',
    PRE_BUILT_MATCH_ACCA_BET: 'PRE_BUILT_MATCH_ACCA_BET',
};

/**
 * Set stake request object.
 *
 * @typedef SetStakeRequest
 * @type {object}
 * @property {number} stake - Stake.
 * @property {string} betId - Bet id.
 * @property {Mojito.Services.Bets.types.BET_WAY} betWay - Bet way.
 * @property {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Action object.
 *
 * @typedef Action
 * @type {object}
 * @property {Mojito.Services.Betslip.types.ACTIONS} type - Action type.
 * @property {object} request - Request payload for corresponding action type.
 * @property {Array<object>} conditions - List of conditions.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Selection object.
 *
 * @typedef Selection
 * @type {object}
 * @property {string} id - Id of the selection.
 * @property {string} marketId - Id of the market which selection belongs to.
 * @property {string} odds - Selection odds.
 * @property {string} hcap - Selection handicap value.
 * @property {string} betRef - Selection betRef value.
 * @property {Array<number>} places - List of place numbers. Typically used for racing sports selection within multicast leg types.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Defines the payload object for adding a part to the betslip.
 * The payload object can consist of `betRef` or a combination of `selection` and `parent`.
 * If both `selection` and `betRef` are provided, `betRef` will take precedence over `selection`.
 *
 * @typedef AddSelectionPartPayload
 * @type {object}
 * @property {Mojito.Services.Betslip.types.Selection} selection - The selection that will be added as a part of the betslip.
 * @property {Mojito.Services.Betslip.types.ParentInfo} parent - Information about the selection’s parent.
 * @property {string} source - A string identifier indicating where the request originates from.
 * @property {string} betRef - The `betRef` property, if provided, will take precedence over the `selection` property. The client will send either `betRef` or `selection`; the `parent` will only be guaranteed for `selection`.
 * @property {Mojito.Services.Betslip.types.BETTING_CONTEXT} bettingContext - Betting contexts that selection can represent once added to the betslip, typically, selection is a normal event result that user can bet.
 * But sometimes selection can represent exotic things like already prebuilt accumulator bets, e.g. Prebuilt match acca.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Remove selection betslip part payload object.
 * Typically used to remove betslip part from selections coupon.
 *
 * @typedef RemoveSelectionPartPayload
 * @type {object}
 * @param {string} selectionId - Selection id.
 * @param {Mojito.Services.Betslip.types.ParentInfo} parent - Selection parent info.
 * @param {string} source - String identifier of the request source where it originates from.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Remove betslip part payload object.
 * Typically used in service requests.
 *
 * @typedef RemovePartPayload
 * @type {object}
 * @property {string} selectionId - Selection id.
 * @property {Mojito.Services.Betslip.types.Bet} bet - Bet object.
 * @property {string} source - String identifier of the request source. Defines where request originates from.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Switch betslip part selection payload object.
 * Typically used in service requests.
 *
 * @typedef SwitchSelectionPayload
 * @type {object}
 * @property {string} selectionId - Selection id to be replaced.
 * @property {string} betRef - Bet reference string, describes selection to be used instead of replaced one.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Add betslip composite payload object.
 *
 * @typedef AddCompositePayload
 * @type {object}
 * @property {Array<Mojito.Services.Betslip.types.Selection>} selections - Selections list that will be added into betslip composite.
 * @property {Mojito.Services.Bets.types.LEG_SORT} legSort - Leg sort.
 * @property {string} odds - Composite total odds.
 * @property {Mojito.Services.Betslip.types.ParentInfo} parent - Selections parent info.
 * @property {string} source - String identifier of the request source. Defines where request originates from.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Update betslip composite payload object.
 *
 * @typedef UpdateCompositePayload
 * @type {object}
 * @property {Array<Mojito.Services.Betslip.types.Selection>} selections - Selections list that will be added into betslip composite.
 * @property {string} betId - Bet id.
 * @property {string} odds - Composite total odds.
 * @property {Mojito.Services.Betslip.types.ParentInfo} parent - Selection parent info.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Remove betslip part payload object.
 *
 * @typedef RemoveCompositePayload
 * @type {object}
 * @param {Array} selectionIds - List of selection ids within composite.
 * @param {Mojito.Services.Betslip.types.Bet} bet - Bet object.
 * @property {string} source - String identifier of the request source. Defines where request originates from.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Content node parents descriptor.
 * Defines node parents ids within content hierarchy.
 *
 * @typedef ParentInfo
 * @type {object}
 * @property {string} eventId - Parent event id.
 * @property {string} sportId - Parent sport id.
 * @property {string} sportClassId - Parent sport class id.
 * @property {string} sportTypeId - Parent sport type id.
 * @property {string} marketId - Parent market id.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Odds object.
 *
 * @typedef Odds
 * @type {object}
 * @property {string} current - Current odds.
 * @property {string} base - Base odds.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Configuration for price change acceptance. The key for this setting is of type {@link Mojito.Services.Betslip.types.PRICE_CHANGE_TYPE}.
 *
 * @typedef PriceChangeAcceptanceSettings
 * @type {object}
 * @property {boolean} higher - A flag indicating whether increases in price should be automatically accepted.
 * @property {boolean} lower - A flag indicating whether decreases in price should be automatically accepted.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * The state of the bet in betslip.
 * Typically, used for tracking if bet has odds changes or appears to be suspended.
 *
 * @typedef BetState
 * @type {object}
 * @property {boolean} isSuspended - True if bet appears to be suspended, false otherwise.
 * @property {boolean} hasOddsChange - True if bet has unaccepted odds changes, false otherwise.
 * @property {boolean} hasHcapChange - True if bet has unaccepted handicap changes, false otherwise.
 * @property {boolean} isBankerPending - True if bet is selected as a banker and this process it pending (it might take time for API to process banker bet).
 * @property {Map<string, object>} suspendedParts - Map of suspended parts. Key is selection id and value is object which identifies suspension status.
 * @property {boolean} isInvalid - True if bet has invalid selection.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * The state of the selection within a bet in betslip.
 * Typically, used for tracking if selection is in pending state for adding/removing to the betslip.
 *
 * @typedef BetSelectionState
 * @type {object}
 * @property {boolean} isPending - True if selection is either adding to or removing from the betslip.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip response.
 *
 * @typedef BetslipResponse
 * @type {object}
 * @property {Mojito.Services.Betslip.types.Betslip} betslip - Betslip object.
 * @property {Mojito.Services.Betslip.types.PLACEMENT_STATUS} status - Betslip placement status.
 * @property {Mojito.Services.Betslip.types.Receipt} receipt - Receipt object, typically comes after successful bet placement.
 * @property {Mojito.Services.Betslip.types.Overask} overask - Overask object, typically comes once bet went to overask process.
 * @property {*} state - Betslip state.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip object.
 *
 * @typedef Betslip
 * @type {object}
 * @property {Array<Mojito.Services.Betslip.types.Bet>} bets - Array of bets.
 * @property {Map<string, Mojito.Services.Betslip.types.StakeGroup>} stakeGroups - Map of stake groups where key is stake group name.
 * @property {Array<Error>} errors - Array of errors.
 * @property {Array<string>} freebets - Array of freebet tokens.
 * @property {Map<Mojito.Services.Betslip.types.TEASER_TYPE, Array<Mojito.Services.Betslip.types.TeaserOption>>} teaserOptions - Map of available teaser options.
 * Can only appear once {@link Mojito.Services.Betslip.types.STAKE_GROUP_NAME|TEASERS} stake group is active.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip bet.
 *
 * @typedef Bet
 * @type {object}
 * @property {string} id - Bet id.
 * @property {Mojito.Services.Bets.types.BET_TYPE} betType - Bet type.
 * @property {Mojito.Services.Bets.types.LEG_SORT} legSort - Leg sort.
 * @property {Array<Mojito.Services.Bets.types.BET_WAY>} availableBetWays - Bet ways available for bet.
 * @property {number} numberOfLines - Number of lines.
 * @property {number} numberOfEWLines - Number of each way lines.
 * @property {Array<Mojito.Services.Betslip.types.Part>} parts - Parts.
 * @property {string} baseOdds - Base odds.
 * @property {string} exactOdds - Exact odds.
 * @property {string} odds - Odds. Once betslip is in teaser mode this property will contain resulting handicap for a bet.
 * @property {Mojito.Services.Bets.types.PRICE_TYPE} priceType - Price type.
 * @property {Mojito.Services.Betslip.types.TEASER_TYPE} teaserType - Type of the teaser. Only applicable for teaser bets.
 * @property {Array<Mojito.Services.Betslip.types.OddsLine>} oddsLines - Odds lines. Only used for multi line odds.
 * @property {number} multiplierPerLine - Multiplier per line. Used for denominator functionality.
 * @property {boolean} hasRelatedBet - Flag to indicate if bet has a related bet in betslip.
 * @property {boolean} notCombinable - Flag to indicate if bet is not combinable into system/multi.
 * @property {Array<Mojito.Services.Betslip.types.BetBonus>} bonuses - Bonuses.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip stake group.
 *
 * @typedef StakeGroup
 * @type {object}
 * @property {Map<string, Mojito.Services.Betslip.types.BetStake>} stakes - Stakes for bets where key is bet id.
 * @property {Mojito.Services.Betslip.types.Calculation} calculation - Calculation.
 * @property {Mojito.Services.Betslip.types.FreeBetConstraints} freeBetConstraints - Free bet constraints.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip error.
 *
 * @typedef Error
 * @type {object}
 * @property {Mojito.Services.Betslip.types.ERROR_CODE} code - The error code.
 * @property {Mojito.Services.Betslip.types.ErrorSource} source - The source of the error.
 * @property {string} message - The error message.
 * @property {string} factor - Additional information. If relevant to the error code, this could be a value that was exceeded or changed, causing the error. Examples include `new_price`, `new_handicap`, `max_stake`, `min_stake`, etc.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Teaser option.
 *
 * @typedef TeaserOption
 * @type {object}
 * @property {string} sportId - Sport id.
 * @property {string} hcap - Teaser handicap modifier.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip error source.
 *
 * @typedef ErrorSource
 * @type {object}
 * @property {Mojito.Services.Betslip.types.ErrorSourceType} type - The type of the error source.
 * @property {string} id - The identifier of the source node, such as bet id, selection id, etc.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip bet part.
 *
 * @typedef Part
 * @type {object}
 * @property {string} selectionId - Selection id.
 * @property {string} selectionName - Selection name.
 * @property {string} hcap - Handicap.
 * @property {number} number - Horse/dog number in race card.
 * @property {Mojito.Services.Betslip.types.PartInfo} partInfo - Part info.
 * @property {Array<Mojito.Services.Betslip.types.AlternativeSelection>} alternativeSelections - Alternative selections. Typically present to allow user to switch selection, for example in pitcher sensitive markets on baseball.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip bet part alternative selection.
 *
 * @typedef AlternativeSelection
 * @type {object}
 * @property {string} id - Selection id.
 * @property {string} marketName - Market name.
 * @property {string} marketType - Market type.
 * @property {string} betRef - Bet references, usually BASE64 encoded string that presents selection information.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Part info.
 *
 * @typedef PartInfo
 * @type {object}
 * @property {string} marketId - Market id.
 * @property {string} marketName - Market name.
 * @property {string} marketType - Market type.
 * @property {Mojito.Services.Bets.types.EachWayTerms} eachWayTerms - Each way terms.
 * @property {string} eventId - Event id.
 * @property {string} eventName - Event name.
 * @property {string} startTime - Start time (for racing).
 * @property {string} sportId - Sport id.
 * @property {string} sportClassId - Sport class id.
 * @property {string} sportTpeId - Sport type id.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * OddsLine. Presenting odds per selections line.
 * Typically used in priced multicast bets.
 *
 * @typedef OddsLine
 * @type {object}
 * @property {Array<string>} selectionIds - List of selection ids.
 * @property {string} odds - Odds.
 * @property {string} exactOdds - Exact odds.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Bet bonus. Usually represents bonuses like acca boost and acca insurance.
 *
 * @typedef BetBonus
 * @type {object}
 * @property {Mojito.Services.Bets.types.BONUS_TYPE} type - Bonus type.
 * @property {string} rate - The rate of the bonus. Usually percentage value represents the amount of potential bonus recap.
 * @property {string} maxCap - Max bonus recap value.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip bet stake. Available per stake group.
 *
 * @typedef BetStake
 * @type {object}
 * @property {Mojito.Services.Bets.types.BET_WAY} betWay - Bet way.
 * @property {number} stake - Stake.
 * @property {number} freebetStake - Freebet stake.
 * @property {number} payout - Payout.
 * @property {number} stakeTax - Stake tax.
 * @property {number} payoutTax - Payout tax.
 * @property {Array<Mojito.Services.Betslip.types.Error>} errors - Errors.
 * @property {string} freebetToken - Freebet token.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Total betslip calculation available per stake group.
 *
 * @typedef Calculation
 * @type {object}
 * @property {number} stake - Stake.
 * @property {number} freebetStake - Freebet stake.
 * @property {number} stakeAfterTaxes - Stake after taxes.
 * @property {number} stakeTax - Stake tax.
 * @property {number} payout - Contains gross payout amount.
 * @property {number} payoutTax - Payout tax.
 * @property {number} payoutNet - Contains net payout after deduction of taxes.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Free bet constraints.
 *
 * @typedef FreeBetConstraints
 * @type {object}
 * @property {Mojito.Services.Betslip.types.CONSTRAINT_POLICY} policy - Constraint policy.
 * @property {Array<string>} tokens - Tokens.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip receipt. Contains information about successful betslip placement.
 *
 * @typedef Receipt
 * @type {object}
 * @property {Array<Mojito.Services.Bets.types.Bet>} bets - List of placed bets.
 * @property {string} placementTime - Time of betslip placement.
 * @property {Mojito.Services.Bets.types.Funds} betslipTotal - Betslip total funds info.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Overask update request payload.
 *
 * @typedef OveraskUpdatePayload
 * @type {object}
 * @property {Mojito.Services.Betslip.types.OVERASK_ACTION} action - Overask action.
 * @property {Array<Mojito.Services.Betslip.types.OveraskUpdate>} updates - Overask updates.
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Overask update.
 *
 * @typedef OveraskUpdate
 * @type {object}
 * @property {string} id - Id.
 * @property {Mojito.Services.Betslip.types.OVERASK_UPDATE_TYPE} type - Type, either BET or BONUS.
 * @property {Mojito.Services.Betslip.types.OVERASK_CHECK_STATUS} value - New value.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Overask.
 *
 * @typedef Overask
 * @type {object}
 * @property {Array<Mojito.Services.Bets.types.Bet>} bets - Bets.
 * @property {Mojito.Services.Bets.types.Funds} total - Total funds.
 * @property {Array<Mojito.Services.Betslip.types.BetOffer>} betOffers - Bet offers.
 * @property {Mojito.Services.Bets.types.Funds} offeredTotal - Total offered funds.
 * @property {Date} expires - Date when offer expires.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Bet offer.
 *
 * @typedef BetOffer
 * @type {object}
 * @property {Mojito.Services.Betslip.types.PLACEMENT_STATUS} status - Place status.
 * @property {string} betId - Bet id.
 * @property {string} originalBetId - Id of original bet.
 * @property {Mojito.Services.Betslip.types.OVERASK_CHECK_STATUS} checkStatus - Status to tell if overask is chosen or not to be accepted.
 * @property {string} bonusId - Bonus id.
 * @property {number} numberOfLines - Number of lines.
 * @property {Mojito.Services.Bets.types.Funds} funds - Funds.
 * @property {Array<Mojito.Services.Betslip.types.LegOffer>} legs - Leg offers.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Leg offer.
 *
 * @typedef LegOffer
 * @type {object}
 * @property {Mojito.Services.Bets.types.BET_WAY} betWay - Bet way.
 * @property {Array<Mojito.Services.Betslip.types.PartOffer>} parts - Part offers.
 * @property {string} odds - Odds.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Part offer.
 *
 * @typedef PartOffer
 * @type {object}
 * @property {string} selectionId - Selection id.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Selection update descriptor.
 * Note: this type is not a part of mojito transactional API. Used for client processing.
 *
 * @typedef SelectionUpdate
 * @type {object}
 * @property {Array<Mojito.Services.Betslip.types.PART_UPDATE_FACTOR>} factors - List of update factors. Identifies properties that were updated on selection.
 * @property {Mojito.Services.Betslip.types.Selection} selection - Updated selection object.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Betslip settings object. This settings can be changed by a user and, typically, stored in local storage.
 *
 * @typedef BetslipSettings
 * @type {object}
 * @property {boolean} quickBetslipEnabled - True is quick betslip is enabled, false otherwise.
 * @property {Mojito.Services.Betslip.types.PriceChangeAcceptanceSettings} priceChangeAcceptance - Price change acceptance settings.
 *
 * @memberof Mojito.Services.Betslip.types
 */

/**
 * Selection payload.
 *
 * @typedef SelectionPayload
 * @property {string} marketId - The id of the market to which the selection belongs.
 * @property {string} hcap - The selection's handicap value.
 * @property {string} betRef - The selection's betRef value.
 * @property {Array<number>} places - A list of place numbers, typically used for racing sports selections within multicast leg types.
 *
 * @memberof Mojito.Services.Betslip.types
 */
