import BettingCenterControllerImpl from './betting-center-controller.jsx';
import MojitoCore from 'mojito/core';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * BettingCenter module.
 *
 * @class BettingCenterController
 * @name module
 * @memberof Mojito.Modules.BettingCenter
 */

export default UIControllerView('BettingCenterController', BettingCenterControllerImpl);
