import MojitoCore from 'mojito/core';
import { memo } from 'react';
import { memoize } from 'mojito/utils';

const classUtils = MojitoCore.Base.classUtils;
const uiStyle = MojitoCore.Presentation.uiStyle;
const CacheKeyGenerator = MojitoCore.Base.CacheKeyGenerator;
const ACTIVE_ANIMATION_NAME = 'SkeletonPart';
const animationKeyGenerator = new CacheKeyGenerator();

// eslint-disable-next-line react/display-name
const SkeletonPart = memo(props => {
    const {
        mojitoTools: { style },
    } = props;
    return (
        <div
            style={style.animatedLabel}
            className={classUtils.classNames('ta-SkeletonPart', props.class)}
        />
    );
});

export default SkeletonPart;

SkeletonPart.getStyle = function (config) {
    const { fromKeyframe, toKeyframe } = config;
    const animationId = animationKeyGenerator.generate(
        ACTIVE_ANIMATION_NAME,
        [fromKeyframe, toKeyframe],
        true
    );
    const keyframes = { from: fromKeyframe, to: toKeyframe };
    const animation = registerAnimation(animationId, keyframes);
    return {
        animatedLabel: { ...config.style, animationName: animation.animationName },
    };
};

// Register only one CSS animation per specific key frame setup. This is needed for better animation performance on slow devices.
const registerAnimation = memoize((id, keyframes) => {
    const keyframesConfig = { keyframes };
    const definition = { meta: { id } };
    return uiStyle.createCssAnimation(definition, ACTIVE_ANIMATION_NAME, keyframesConfig);
});
