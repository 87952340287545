/**
 * NumberUtils class offers utility functions for numbers.
 *
 * @class NumberUtils
 * @memberof Mojito.Core.Base
 */

const CSS_TIME_MATCHER = /^(-?[0-9.]+)(m?s)$/;
const TIME_UNIT_TO_MILLISECONDS = { s: 1000, ms: 1 };

export default class NumberUtils {
    /**
     * Rounds a number to a desired precision.
     * <br>
     * If the desired precision is higher than the actual number of decimals, zeros are appended to create
     * the desired decimal length. It will return the number as a string just like the Number.prototype.toFixed() method.
     * <br>
     * The reason for this function is due to that Number.prototype.toFixed can return different results,
     * depending on which browser is used. Using this function instead should solve that problem.
     *
     * @param {number} value - The number to round.
     * @param {number} precision - The desired decimal places.
     *
     * @throws {TypeError} If value and precision are not numbers.
     * @returns {string} A string representing the rounded number using fixed-point notation.
     * @function Mojito.Core.Base.NumberUtils.toFixed
     */
    static toFixed(value, precision) {
        return NumberUtils.numberToPrecision(value, precision).toFixed(precision);
    }

    /**
     * Rounds a number to a specified precision.
     *
     * @param {number} value - The number to round.
     * @param {number} precision - The desired decimal places.
     *
     * @throws {TypeError} If value and precision are not numbers.
     * @returns {number} The rounded number using fixed point notation.
     * @function Mojito.Core.Base.NumberUtils.numberToPrecision
     */
    static numberToPrecision(value, precision) {
        if (typeof value !== 'number') {
            throw new TypeError('The value argument is not a number');
        }

        if (typeof precision !== 'number') {
            throw new TypeError('The precision argument is not a number');
        }

        if (!isFinite(value)) {
            return value;
        }

        // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
        const roundedInPrecision = Math.round(Number(`${value}e+${precision}`));
        return Number(`${roundedInPrecision}e-${precision}`);
    }

    /**
     * Converts a CSS <time> value (https://developer.mozilla.org/en-US/docs/Web/CSS/time) to milliseconds.
     *
     * @param {string} cssTimeStr - A string representing a CSS time value.
     * @returns {number} The equivalent time duration in milliseconds.
     * @function Mojito.Core.Base.NumberUtils.cssTimeToMs
     */
    static cssTimeToMs(cssTimeStr) {
        const matches = CSS_TIME_MATCHER.exec(cssTimeStr);

        if (!matches) {
            return NaN;
        }

        const [amountStr, unitStr] = matches.slice(1);
        const amount = parseFloat(amountStr);

        if (!isFinite(amount) || !(unitStr in TIME_UNIT_TO_MILLISECONDS)) {
            return NaN;
        }

        const toMillisecondsConversionFactor = TIME_UNIT_TO_MILLISECONDS[unitStr];

        return amount * toMillisecondsConversionFactor;
    }

    /* eslint-disable jsdoc/require-description-complete-sentence */
    /**
     * Converts a percentage multiplier to its equivalent percentage value.
     * This can represent either an increase or decrease.
     * <br>
     * Examples:
     * <ul>
     * <li>- A multiplier of 1.25 will return 25.</li>
     * <li>- A multiplier of 0.85 will return 15.</li>
     * </ul>
     *
     * @param {number} percentageMultiplier - The percentage multiplier.
     * @returns {number} The percentage increase or decrease value of the multiplier.
     * @function Mojito.Core.Base.NumberUtils.percentageMultiplierToPercentageValue
     */
    static percentageMultiplierToPercentageValue(percentageMultiplier) {
        if (percentageMultiplier >= 1) {
            return Math.round(100 * (percentageMultiplier - 1));
        }

        return Math.round(100 * (1 - percentageMultiplier));
    }

    /* eslint-disable jsdoc/require-description-complete-sentence */
    /**
     * Converts a string percentage to a decimal multiplier by dividing by 100.
     * <br>
     * Examples:
     * <ul>
     * <li>- '55%' is converted to 0.55.</li>
     * <li>- '125%' is converted to 1.25.</li>
     * </ul>
     *
     * @param {string} value - A string formatted as a percentage (e.g., '55%').
     * @returns {number|undefined} The equivalent decimal multiplier or undefined if conversion is not possible.
     * @function Mojito.Core.Base.NumberUtils.toPercentageMultiplier
     */
    static toPercentageMultiplier(value) {
        if (!value || !value.endsWith('%')) {
            return;
        }
        const pureString = Number(value.replace('%', ''));
        return pureString / 100;
    }

    /**
     * Takes an absolute number value and multiplies x100 to get result in percents.
     * <br><br>
     * Example: 0.5 results to 50, 1.25 results to 125.
     *
     * @param {number} value - The absolute value. Typically range [0, 1].
     * @returns {number} The value in percents.
     * @function  Mojito.Core.Base.NumberUtils.toPercent
     */
    static toPercent(value) {
        if (typeof value !== 'number') {
            throw new TypeError('The value argument is not a number');
        }
        return Math.round(value * 100);
    }

    /**
     * Removes pixels from string end and casts to number.
     * <br><br>
     * Example: value '5px' results to 5.
     *
     * @param {string} value - String with pixel.
     *
     * @returns {number} Number value.
     * @function  Mojito.Core.Base.NumberUtils.escapePixels
     */
    static escapePixels(value) {
        if (!isNaN(value)) {
            return value;
        }
        if (!value || !value.endsWith('px')) {
            return;
        }
        return Number(value.replace('px', ''));
    }

    /**
     * Check if string is a valid number with option to check correct number of decimals.
     *
     * @param {string} stakeString - Stake string.
     * @param {number} [decimalAmount = 0] - Number of decimals to be checked.
     *
     * @returns {boolean} True if number is valid.
     * @function Mojito.Core.Base.NumberUtils.isValidNumber
     */
    static isValidNumber(stakeString, decimalAmount = 0) {
        if (!decimalAmount) {
            return /^\d*[.,]?\d*$/.test(stakeString);
        }
        return new RegExp('^\\d*([\\.|,]\\d{0,' + decimalAmount + '})?$').test(stakeString); //eslint-disable-line
    }
}
