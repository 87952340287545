import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import PageContextMenuImpl from './page-context-menu.jsx';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * A module for handling the page context menu.
 *
 * Will display CMS-links if they are available for home page.
 * Will display quick-link for price boost coupon if boosted markets is available.
 *
 * @class PageContextMenu
 * @memberof Mojito.Modules.PageContextMenu
 */

/**
 * PageContextMenu prop types.
 *
 * @property {string} applicablePage - Name of the page where the menu will be shown. The name is used to retrieve all applicable links provided from CMS-tool.
 *
 * @memberof Mojito.Modules.PageContextMenu
 */
const propTypes = {
    applicablePage: PropTypes.string.isRequired,
};

export default UIControllerView('PageContextMenu', PageContextMenuImpl, propTypes);
