import MojitoCore from 'mojito/core';
import AccaBarViewImpl from './acca-bar-view.jsx';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * View component of the AccaBar.
 *
 * @class AccaBarView
 * @memberof Mojito.Modules
 */

/**
 * Callback for handling click on hide icon.
 *
 * @callback Mojito.Modules.AccaBarView.hideAccaBarCallback
 */

/**
 * Callback for handling click on the acca bar.
 *
 * @callback Mojito.Modules.AccaBarView.onBarClickCallback
 */

/**
 * AccaBarView prop types.
 *
 * @property {Mojito.Modules.AccaBarView.onBarClickCallback} [onBarClick = () => {}] - Callback for handling click on the acca bar.
 * @property {Mojito.Modules.AccaBarView.hideAccaBarCallback} [hideAccaBar = () => {}] - Callback for handling click on the hide icon.
 * @property {boolean} [isVisible] - If acca bar should be visible or not.
 * @property {Mojito.Services.Betslip.types.Bet} [bet] - The bet to be shown.
 *
 * @memberof Mojito.Modules.AccaBarView
 */
const propTypes = {
    onBarClick: PropTypes.func,
    hideAccaBar: PropTypes.func,
    isVisible: PropTypes.bool,
    bet: PropTypes.object,
};

const defaultProps = {
    onBarClick: noop,
    hideAccaBar: noop,
};

export default UIView('AccaBarView', AccaBarViewImpl, propTypes, defaultProps);
