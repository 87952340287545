import { actions as loginActions } from './login/slice.js';
import MojitoCore from 'mojito/core';
const { dispatch } = MojitoCore.Services.redux.store;

class Modules {
    /**
     * Initialize the modules layer based on the provided configuration.
     *
     * @param {Mojito.Modules.Config} [config={}] - The modules layer configuration.
     * @function Mojito.Modules#init
     */
    init(config = {}) {
        // Configure Login store
        if (config.stores && config.stores.login) {
            dispatch(loginActions.configure(config.stores.login));
        }
    }
}

export default new Modules();
