import { pull, noop, isEmpty } from 'mojito/utils';

/**
 * Job constructor.
 *
 * @class Job
 * @private
 * @classdesc Class wrapper for task function. Responsible for task execution and abort flow.
 * @memberof Mojito.Core.Services.TaskExecutor
 */
class Job {
    constructor(task, resolve = noop, reject = noop, args = []) {
        this.task = task;
        this.resolve = resolve;
        this.reject = reject;
        this.args = args;
        this.taskPromise = undefined;
    }

    hasTask(task) {
        return this.task === task;
    }

    execute() {
        this.taskPromise = this.task(...this.args);
        this.taskPromise.then(data => this.resolve(data)).catch(err => this.reject(err));
        return this.taskPromise;
    }

    abort() {
        if (this.taskPromise && this.taskPromise.abort) {
            this.taskPromise.abort();
        }
    }
}

/**
 * Promise based async function that is used as input parameter for {@link Mojito.Core.Services.TaskExecutor|TaskExecutor} methods.
 *
 * @function task
 * @param {*} args - Variable number of arguments.
 * @returns {Promise} Instance of the <code>Promise</code> or promisable object
 * that implements <code>then</code>, <code>catch</code> and <code>abort</code> functions.
 * <code>abort</code> is useful in case if there is necessity to manually cancel <code>task</code>.
 *
 * @memberof Mojito.Core.Services.TaskExecutor
 */

/**
 * TaskExecutor constructor.
 *
 * @class TaskExecutor
 *
 * @param {Function} [done = () => {}] - Function called once task executor is done with all tasks.
 * @classdesc Class offering queue functionality for async functions that are based on promises.
 * @memberof Mojito.Core.Services
 */
export default class TaskExecutor {
    constructor(done = noop) {
        this.queue = [];
        this.executingJob = undefined;
        this.done = done;
    }

    /**
     * Get execution state. True if task is in the middle of execution, false otherwise.
     *
     * @type {boolean}
     */
    get isExecuting() {
        return !!this.executingJob;
    }

    /**
     * Wraps input function (a.k. <code>task<code/>) with queue aware function that can be called with the same
     * interface as the origin and returns resulted function.
     * Once resulted function called:
     * If the queue is empty, then <code>task</code> will be executed immediately,
     * otherwise the execution will be postponed until more recent <code>tasks<code> in the queue are resolved.
     * Result function returns Promise.
     *
     * @param {Mojito.Core.Services.TaskExecutor.task} task - Function that will be wrapped with queue functionality.
     * This function should return <code>Promise<code/> object.
     *
     * @returns {Function} Wrapper function that will handle queue processing once called.
     *
     * @function Mojito.Core.Services.TaskExecutor#withQueue
     */
    withQueue(task) {
        return (...args) => {
            // Will create promise and return it to caller.
            // This promise will be resolved afterwards once the task turn comes and it is executed.
            const promise = new Promise((resolve, reject) => {
                // The incoming function and its arguments will be used to create `Job` instance.
                // The job is placed in the queue for later call.
                const job = new Job(task, resolve, reject, args);
                this.queue.push(job);
                // Start queue execution immediately unless the execution is already ongoing.
                !this.isExecuting && this.execute();
            });
            return promise;
        };
    }

    /**
     * Aborts task that has been wrapped with queue and is executing right now
     * or scheduled for execution in future.
     *
     * @param {Mojito.Core.Services.TaskExecutor.task} task - Function that needs to be aborted.
     *
     * @function Mojito.Core.Services.TaskExecutor#abortTask
     */
    abortTask(task) {
        const jobs = this.findJobsByTask(task);
        if (!isEmpty(jobs)) {
            jobs.forEach(job => job.abort());
            pull(this.queue, ...jobs);
            if (jobs.includes(this.executingJob)) {
                this.executingJob = undefined;
            }
        }
    }

    /**
     * Aborts all tasks that has been wrapped with queue and are executing right now
     * or scheduled for execution in future.
     *
     * @function Mojito.Core.Services.TaskExecutor#abortAll
     */
    abortAll() {
        const allJobs = [...this.queue, this.executingJob].filter(Boolean);
        allJobs.forEach(job => job.abort());
        this.queue = [];
        this.executingJob = undefined;
    }

    /**
     * Detects if task executor contains provided task in queue or if the task is currently executing.
     *
     * @param {Mojito.Core.Services.TaskExecutor.task} task - Function that needs to be checked.
     *
     * @returns {boolean} True if task is found, false otherwise.
     * @function Mojito.Core.Services.TaskExecutor#hasTask
     */
    hasTask(task) {
        const jobs = this.findJobsByTask(task);
        return !isEmpty(jobs);
    }

    /**
     * This function will be called recursively to execute each job in the queue one by one.
     * It doesn't matter if async `func` has been succeed of failed, the execution chain keep on going
     * until we reach the end of the queue.
     *
     * @private
     */
    execute() {
        this.executingJob = this.queue.shift();
        const job = this.executingJob;
        if (job) {
            job.execute()
                .then(() => this.execute())
                .catch(() => this.execute());
        } else {
            this.done();
        }
    }

    /**
     * Find all jobs that were added to the queue for particular task.
     *
     * @param {Mojito.Core.Services.TaskExecutor.task} task - Task to perform search for.
     * @returns {Array<Mojito.Core.Services.TaskExecutor.Job>} List of jobs.
     * @private
     */
    findJobsByTask(task) {
        const allJobs = [...this.queue, this.executingJob].filter(Boolean);
        return allJobs.filter(job => job.hasTask(task));
    }
}
