import FlexPane from 'presentation/components/flex-pane/index.jsx';
import PayoutInfo from 'presentation/components/bet-funds/payout-info/index.jsx';
import TaxInfo from 'presentation/components/bet-funds/tax-info/index.jsx';
import PrewagerPayoutInfo from 'presentation/components/bet-funds/prewager-payout-info/index.jsx';
import MojitoCore from 'mojito/core';

const { classUtils } = MojitoCore.Base;

const TotalPayoutInfo = props => {
    const {
        funds,
        isPotential,
        isBoosted,
        isSuspended,
        previousPayout,
        showPayoutIcon,
        mojitoTools,
    } = props;
    const { config } = mojitoTools;
    const { currency, payout, payoutNet, payoutTax = 0, prewager } = funds;
    const payoutValue = isPotential && payout <= 0 ? undefined : payout;
    const payoutNetValue = isPotential && payoutNet <= 0 ? undefined : payoutNet;

    return (
        <FlexPane
            class={classUtils.classNames('ta-totalPayoutFunds', props.class)}
            config={config.container}
        >
            <PayoutInfo
                config={config.payout}
                payout={payoutValue}
                previousPayout={previousPayout}
                labelKey={getPayoutKey(isPotential, isBoosted)}
                currencyCode={currency}
                showIcon={showPayoutIcon && !payoutTax}
            />
            {prewager && (
                <PrewagerPayoutInfo
                    config={config.prewager}
                    prewager={prewager}
                    isPotential={isPotential}
                    currencyCode={currency}
                />
            )}
            {!isSuspended && payoutTax > 0 && (
                <TaxInfo
                    config={config.tax}
                    value={payoutTax}
                    isPotential={isPotential}
                    currencyCode={currency}
                />
            )}
            {payoutTax > 0 && (
                <PayoutInfo
                    config={config.payoutAfterTax}
                    payout={payoutNetValue}
                    labelKey={getPayoutAfterTaxKey(isPotential, isBoosted)}
                    currencyCode={currency}
                    showIcon={showPayoutIcon}
                />
            )}
        </FlexPane>
    );
};

const getPayoutAfterTaxKey = (isPotential, withBoost) => {
    if (isPotential && withBoost) {
        return '$BET_FUNDS.TOTAL_POTENTIAL_PAYOUT_WITH_BOOST';
    } else if (isPotential) {
        return '$BET_FUNDS.TOTAL_POTENTIAL_PAYOUT';
    } else if (withBoost) {
        return '$BET_FUNDS.TOTAL_PAYOUT_WITH_BOOST';
    }
    return '$BET_FUNDS.TOTAL_PAYOUT';
};

const getPayoutKey = (isPotential, withBoost) => {
    if (isPotential && withBoost) {
        return '$BET_FUNDS.POTENTIAL_PAYOUT_WITH_BOOST';
    } else if (isPotential) {
        return '$BET_FUNDS.POTENTIAL_PAYOUT';
    } else if (withBoost) {
        return '$BET_FUNDS.PAYOUT_WITH_BOOST';
    }
    return '$BET_FUNDS.PAYOUT';
};

export default TotalPayoutInfo;
