import {PALETTE_IDS} from 'core/application/modules/ids';
import {COLOR, FONT} from '../theme';

export function getPageHeadersConfigs() {
    return {
        custom: {
            [PALETTE_IDS.INTERFACE]: {
                ApplicationMenu: {
                    logo: {
                        style: {
                            height: 32,
                            width: 124,
                        },
                    },
                    logoButton: {
                        style: {
                            base: {
                                flexBasis: 124,
                            },
                        },
                    },
                },
            },
        },
        palette: {
            [PALETTE_IDS.APPLICATION_HEADER]: {
                APPLICATION_HEADER_NAV_BAR_PAGE_SELECTOR: {
                    PIVOT_INDICATOR_BG: COLOR.RESERVED.TRANSPARENT, // temporary solution until bug inside MainNavigationBar with selected item is fixed
                },
                APPLICATION_HEADER_NAV_BAR_BG: COLOR.BRAND.BACKGROUND1,
                APPLICATION_HEADER_MOBILE_SHOW_SHADOW: false,
                SEARCH_POPOVER: {
                    POPOVER_BG: COLOR.BRAND.BACKGROUND4,
                    POPOVER_TEXT: COLOR.BRAND.FOREGROUND2,
                },
            },
            [PALETTE_IDS.INTERFACE]: {
                BANNER_BG: COLOR.BASE.PAGE,
                BREADCRUMBS_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                MOBILE_BREADCRUMBS_BG: COLOR.BRAND.BACKGROUND6,
                MOBILE_BREADCRUMBS_TEXT: COLOR.BRAND.FOREGROUND5,
                MOBILE_BREADCRUMBS_ICON_COLOR: COLOR.BRAND.FOREGROUND5,
                MOBILE_BREADCRUMBS_BACK_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND5,
                MOBILE_BREADCRUMBS_QUICK_NAVIGATION_CATEGORY_TEXT: {
                    color: COLOR.BRAND.FOREGROUND3,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                MOBILE_BREADCRUMBS_QUICK_NAVIGATION_TOURNAMENT_TEXT: COLOR.BRAND.FOREGROUND5,
                MOBILE_BREADCRUMBS_QUICK_NAVIGATION_ICON_COLOR: COLOR.BRAND.FOREGROUND5,
                SYSTEM_INITIALIZATION_TEXT: COLOR.RESERVED.WHITE,
            },
            [PALETTE_IDS.SETTINGS]: {
                SETTINGS_SECTION_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                },
            },
        },
    };
}
