import PropTypes from 'prop-types';
import UserAuthenticationInfo from './user-authentication-info.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * User Authentication Info
 * displays the login button when the user is not logged in
 * and the user balance and 'userInfoPopup' button when logged in.
 *
 * @class UserAuthenticationInfo
 * @memberof Mojito.Application
 */

/**
 * UserAuthenticationInfo prop types.
 *
 * @property {Mojito.Services.Authentication.types.UserInfo} [userInfo] - User info data.
 * @property {Mojito.Services.Bonus.types.FreeBet[]} [freeBets] - List of free bets.
 *
 * @memberof Mojito.Application.UserAuthenticationInfo
 */
const propTypes = {
    userInfo: PropTypes.object,
    freeBets: PropTypes.array,
};

export default UIView('UserAuthenticationInfo', UserAuthenticationInfo, propTypes);
