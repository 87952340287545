import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import { omit } from 'mojito/utils';
import { useMemo } from 'react';

const { useConfig, useAppContext } = MojitoCore.Presentation.Hooks;
const { FlexPane } = MojitoPresentation.Components;
const ObjUtils = MojitoCore.Base.objUtils;

const NAME = 'EventListMarketsSection';

/**
 * EventListMarketsSection prop types.
 *
 * @typedef Props
 * @property {Mojito.Core.Services.Config.ConfigObject} config - Mojito view config object.
 * @property {Array<React.ReactNode>|React.ReactNode} children - Children.
 * @property {string} sportId - Sport id. Will be used to identify layout and sizing overrides for specific sport.
 *
 * @memberof Mojito.Modules.EventList.EventListMarketsSection
 */

/**
 * A Higher Order Component (HoC) function intended for usage with Markets.
 *
 * @function EventListMarketsSection
 * @memberof Mojito.Modules.EventList
 *
 * @param {Mojito.Modules.EventList.Props} props - Component properties.
 * @returns {React.ReactElement} A React element.
 */
export default function EventListMarketsSection(props) {
    const config = useConfig(NAME, props.config, useAppContext().uiContextPath());
    const { children, sportId } = props;
    const sportWidth = config.sportWidthOverride?.[sportId];
    const flexPaneConfig = useMemo(() => {
        const widthOverride =
            sportWidth === undefined ? {} : { style: { width: sportWidth, minWidth: sportWidth } };
        return ObjUtils.merge(config.container, widthOverride);
    }, [config, sportWidth]);

    return (
        <FlexPane {...omit(props, 'sportId')} config={flexPaneConfig}>
            {children}
        </FlexPane>
    );
}
