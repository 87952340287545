import MojitoCore from 'mojito/core';
import AbstractAuthenticationService from './abstract-authentication-service.js';
import AuthenticationTypes from 'services/authentication/types.js';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const {
    CREDENTIALS_PUBLIC_TYPE,
    CREDENTIALS_PRIVATE_TYPE,
    LOGIN_ERRORS,
    SESSION_VALIDATION_RESULT,
} = AuthenticationTypes;

const loginErrorsMap = {
    ACCOUNT_LOCKED: LOGIN_ERRORS.ACCOUNT_IS_LOCKED,
    WRONG_CREDENTIALS: LOGIN_ERRORS.WRONG_CREDENTIALS,
};

const BASE_URL = '/authentication';
const logger = MojitoCore.logger.get('AuthenticationService');

/**
 * Authentication service config.
 *
 * @typedef AuthenticationServiceConfig
 * @type {object}
 * @property {string} serviceUrl - Authentication API endpoint URL.
 *
 * @memberof Mojito.Services.Authentication
 */

/**
 *
 * Class offering the possibility to interact with the Authentication API.
 *
 * @class AuthenticationService
 * @name service
 * @extends Mojito.Services.Authentication.AbstractAuthenticationService
 * @memberof Mojito.Services.Authentication
 */
class AuthenticationService extends AbstractAuthenticationService {
    /**
     * Configure service.
     *
     * @param {Mojito.Services.Authentication.AuthenticationServiceConfig} config - Service configuration object.
     *
     * @function Mojito.Services.Authentication.service#configure
     */
    configure(config) {
        this.serviceUrl = config.serviceUrl;
        this.requestFactory = new ApiRequestProvider(logger);
        this.apiUrl = `${this.serviceUrl + BASE_URL}/`;

        this.login = this._withUrlSanity(this.login);
        this.logout = this._withUrlSanity(this.logout);
        this.validateSession = this._withUrlSanity(this.validateSession);
        this.restoreSession = this._withUrlSanity(this.restoreSession);
    }

    /**
     * Performs login request with provided credentials.
     *
     * @param {Mojito.Services.Authentication.types.AuthCredentials} credentials - User authentication credentials.
     * @param {Mojito.Services.Authentication.types.loginSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.loginFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.service#login
     */
    login(credentials, successCallback, errorCallback) {
        const { publicType, privateType, data } = credentials;
        this.requestFactory
            .post(`${this.apiUrl}login`)
            .withDescription('login')
            .withErrorsMap(loginErrorsMap)
            .withCredentials()
            .send({
                credentialsPublicType: publicType || CREDENTIALS_PUBLIC_TYPE.USERNAME,
                credentialsPrivateType: privateType || CREDENTIALS_PRIVATE_TYPE.PASSWORD,
                data: data,
            })
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Performs logout request.
     *
     * @param {Mojito.Services.Authentication.types.logoutSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.logoutFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.service#logout
     */
    logout(successCallback, errorCallback) {
        this.requestFactory
            .post(`${this.apiUrl}logout`)
            .withDescription('logout')
            .withCredentials()
            .send()
            .then(successCallback)
            .catch(errorCallback);
    }

    /**
     * Validates existing user session.
     *
     * @param {Mojito.Services.Authentication.types.validateSessionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.validateSessionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.service#validateSession
     */
    validateSession(successCallback, errorCallback) {
        this.requestFactory
            .post(`${this.apiUrl}validate-session`)
            .withDescription('validate session')
            .withCredentials()
            .withRawBodyResponse()
            // no need to handle unexpected session lost for this one
            .withErrorHandler(undefined)
            .send()
            .then((response = {}) => {
                const callback =
                    response.status === SESSION_VALIDATION_RESULT.SUCCESS
                        ? successCallback
                        : errorCallback;
                callback(response);
            })
            .catch(errorCallback);
    }

    /**
     * Tries to restore user session.
     * Typically called on application startup. Delegates request to
     * Mojito.Services.Authentication.service#validateSession method.
     *
     * @param {Mojito.Services.Authentication.types.restoreSessionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.restoreSessionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.service#restoreSession
     */
    restoreSession(successCallback, errorCallback) {
        this.validateSession(successCallback, errorCallback);
    }

    _withUrlSanity(func) {
        return (...args) => {
            if (!this.serviceUrl) {
                logger.warn('serviceUrl is missing.');
                return;
            }
            func.apply(this, args);
        };
    }
}

export default new AuthenticationService();
