import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

const IntentTypes = MojitoPresentation.Base.Intent.Types;
const { RacingHelper, SelectionHelper } = MojitoPresentation.Utils;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { TARGET_TYPE } = MojitoServices.Promotions.types;
const { SPORT_ID } = MojitoServices.SportsContent.Sports.types;
const log = MojitoCore.logger.get('PromotionUtils');
/**
 * Utilities for converting Mojito data model promotion locations into
 * promotion objects suitable to be rendered by the Promotion component.
 *
 * @class PromotionUtils
 * @memberof Mojito.Modules.Promotions
 */
export default class PromotionUtils {
    /**
     * Returns the top selections for an outright market, selections being ordered by price in ascending order.
     *
     * @param {market} market - The market to get selections for.
     * @param {number} maxSelectionsAmount - Max number of selections.
     *
     * @returns {Array} Top selections of an outright market.
     * @function Mojito.Modules.PromotionUtils.getTopSelections
     */
    static getTopSelections(market, maxSelectionsAmount) {
        const orderedSelections = market.selections.toSorted(SelectionHelper.compareByPrice);
        return orderedSelections.slice(0, maxSelectionsAmount);
    }

    /**
     * Resolve the promotion intent, if any, associated with the promotion item.
     *
     * @param {object} promotion - The promotion item.
     * @returns {object|undefined} Intent.
     * @function Mojito.Modules.PromotionUtils.resolveIntentFromPromotion
     */
    static resolveIntentFromPromotion(promotion) {
        const { target } = promotion;
        // If the promotion/banner doesn't have a valid link (event may have expired) just exit
        if (!target) {
            return;
        }
        return this.buildIntentFromTarget(target, promotion);
    }

    static buildIntentFromTarget(target, promotion) {
        const { type, sportId, eventId, classId, typeId, meetingId, openInNewTab, url, isVirtual } =
            target;

        const withVirtualNavigation = toSport =>
            isVirtual ? NavigationPathBuilder.toVirtualSport(sportId, classId) : toSport();

        switch (type) {
            case TARGET_TYPE.SPORT:
                return this.buildSportIntent(sportId, classId);
            case TARGET_TYPE.EVENT:
                return this.buildEventIntent(sportId, eventId, meetingId);
            case TARGET_TYPE.CLASS:
                return this.buildClassIntent(sportId, classId, withVirtualNavigation);
            case TARGET_TYPE.TYPE:
                return this.buildTypeIntent(
                    sportId,
                    classId,
                    typeId,
                    meetingId,
                    withVirtualNavigation
                );
            case TARGET_TYPE.EXTERNAL_URL:
                return this.buildExternalUrlIntent(url, openInNewTab, promotion);
            default:
                log.warn('Unhandled promotion target type: ', type);
                return null;
        }
    }

    static buildSportIntent(sportId, classId) {
        if (sportId) {
            let data = NavigationPathBuilder.toSport(sportId);
            // TODO: MOJ-12662. This is crazy to make decision about sport based on its id assuming that someone will keep semantic meaning inside id. Id can be anything: '1', 'abc' etc. We need to implement this properly.
            if (sportId.includes(SPORT_ID.VIRTUALS)) {
                data = classId
                    ? NavigationPathBuilder.toVirtualSport(sportId, classId)
                    : NavigationPathBuilder.toVirtualSports();
            }

            return {
                type: IntentTypes.VIEW,
                data,
            };
        }
    }

    static buildEventIntent(sportId, eventId, meetingId) {
        if (eventId && sportId) {
            const isRaceEvent = RacingHelper.isRacingSport(sportId);
            return {
                type: IntentTypes.VIEW,
                data: isRaceEvent
                    ? NavigationPathBuilder.toRaceEvent(sportId, meetingId, eventId)
                    : NavigationPathBuilder.toSportEvent(sportId, eventId),
            };
        }
    }

    static buildClassIntent(sportId, classId, withVirtualNavigation) {
        if (sportId && classId) {
            const isRaceEvent = RacingHelper.isRacingSport(sportId);
            return {
                type: IntentTypes.VIEW,
                data: isRaceEvent
                    ? NavigationPathBuilder.toSportMeetings(sportId)
                    : withVirtualNavigation(() =>
                          NavigationPathBuilder.toSportCompetitions(sportId, classId)
                      ),
            };
        }
    }

    static buildTypeIntent(sportId, classId, typeId, meetingId, withVirtualNavigation) {
        if (classId && typeId) {
            const isRaceEvent = RacingHelper.isRacingSport(sportId);
            return {
                type: IntentTypes.VIEW,
                data: isRaceEvent
                    ? NavigationPathBuilder.toSportMeeting(sportId, meetingId)
                    : withVirtualNavigation(() =>
                          NavigationPathBuilder.toSportCompetitionsLeague(sportId, typeId)
                      ),
            };
        }
    }

    static buildExternalUrlIntent(url, openInNewTab, promotion) {
        if (url) {
            return {
                type: IntentTypes.OPEN_URL,
                data: {
                    url,
                    // WindowName is set to open the external url in a new tab
                    windowName: openInNewTab ? '' : undefined,
                },
            };
        }
        log.warn('Target URL is missed for promotion ', promotion);
    }

    /**
     * Check if config object has locationId property.
     *
     * @param {Mojito.Core.Services.Config.ConfigObject} config - Promotions component config object.
     *
     * @returns {boolean} True if config exists and has location id.
     * @function Mojito.Modules.PromotionUtils.hasLocationId
     */
    static hasLocationId(config) {
        return config && config.locationId;
    }
}
