import MojitoPresentation from 'mojito/presentation';
import Search from 'application/components/search/index.jsx';

const { Button, FlexPane, Image, Overlay } = MojitoPresentation.Components;

const SearchOverlay = props => {
    const { isOpen, mojitoTools, onSearchClose } = props;
    const { config } = mojitoTools;
    return isOpen ? (
        <Overlay config={config.overlay}>
            <FlexPane config={config.searchHeader}>
                <Button onClick={onSearchClose} class="ta-backButton">
                    <Image config={config.searchBackButtonIcon} />
                </Button>
                <Search
                    initiallyActive={isOpen}
                    onSearchClose={onSearchClose}
                    config={config.searchView}
                />
            </FlexPane>
        </Overlay>
    ) : null;
};

export default SearchOverlay;
