import MojitoCore from 'mojito/core';
import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {getParamFromURL} from 'core/utils/url-utils';
import {LOG_LEVEL, Logger, setLogLevel, setLogsLevelFiltering} from 'core/utils/logger';
import {assignToHierarchy} from 'core/utils/config-utils';
import {ApplicationConfig} from 'core/config/application-config';
import SCHEMA from './debug.schema.yaml';

// This feature supposed to receive configs only from session storage
class DebugFlagsFeature extends AbstractFeature {
    get name() {
        return 'Debug';
    }

    get configSchema() {
        return SCHEMA;
    }

    get no3rdParties() {
        return this.config.no3rdParties;
    }

    get allLogs() {
        return !!this.config.allLogs;
    }

    get analyzeConfigs() {
        return this.config.analyzeConfigs;
    }

    get measurePerformance() {
        return this.config.measurePerformance;
    }

    init(sessionConfigs) {
        this.config = this.getConfiguration(sessionConfigs);

        const configFromParams = {};
        if (getParamFromURL('_no3rdParties') !== null) {
            configFromParams.no3rdParties = true;
        }
        if (getParamFromURL('_wrapPhrases') !== null) {
            configFromParams.wrapPhrases = true;
        }
        if (getParamFromURL('_keysAsPhrases') !== null) {
            configFromParams.keysAsPhrases = true;
        }
        if (getParamFromURL('_allLogs') !== null) {
            configFromParams.allLogs = true;
        }
        if (getParamFromURL('_analyzeConfigs') !== null) {
            configFromParams.analyzeConfigs = true;
        }
        if (Object.keys(configFromParams).length > 0) {
            ApplicationConfig.updateInSessionStorage({debug: configFromParams});
        }

        this.config = Object.assign({}, this.config, configFromParams);

        if (this.config.no3rdParties) {
            this.logger.warn('%cAll third party scripts are ignored', Logger.STYLE_DEBUG);
        }
        if (this.config.wrapPhrases) {
            this.logger.warn('%cAll translation phrases are wrapped', Logger.STYLE_DEBUG);
        }
        if (this.config.keysAsPhrases) {
            this.logger.warn('%cAll translation phrases are showing keys', Logger.STYLE_DEBUG);
        }
        if (this.config.allLogs) {
            this.logger.warn('%cEnabled extensive logging', Logger.STYLE_DEBUG);
            setLogsLevelFiltering(false); // set it asap
        }
    }

    setupConfigs() {
        // Do nothing
    }

    async beforeMojito() {
        if (this.config.allLogs) {
            setLogsLevelFiltering(false);
            MojitoCore.logger.setGlobalLogLevel(MojitoCore.logger.DEBUG);
        }
        return super.beforeMojito(); // This Promise will auto-unwrap in this async function
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        // Adjust log level additionally. Only string literals are works (mojito bug)
        assignToHierarchy(mojitoConfig, 'core', {
            logLevel: this.config.allLogs ? MojitoCore.logger.DEBUG : MojitoCore.logger.INFO,
        });
        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    afterMojito() {
        if (this.config.allLogs) {
            // Disable spam loggers, to prevent flood
            MojitoCore.logger.configureLogger('stompjs', {level: MojitoCore.logger.NONE});
            MojitoCore.logger.configureLogger('execution-engine', {level: MojitoCore.logger.NONE});
            MojitoCore.logger.configureLogger('execution-engine:job-queue', {level: MojitoCore.logger.NONE});
            MojitoCore.logger.configureLogger('socket-enabler:client', {level: MojitoCore.logger.NONE});
        }

        if (this.config.logNamespaces) {
            const namespaces = this.config.logNamespaces.split(',').map(ns => ns.trim());
            namespaces.forEach(ns => {
                setLogLevel(ns, LOG_LEVEL.EVERYTHING);
                MojitoCore.logger.configureLogger(ns, {level: MojitoCore.logger.ALL});
            });
        }
        return super.afterMojito();
    }
}

export const DebugFlags = new DebugFlagsFeature(allFeatures);
DebugFlags.init(ApplicationConfig.getFromSessionStorage()); // init debug feature first (session storage only)
