import PropTypes from 'prop-types';
import BetHistoryViewImpl from './bet-history-view.jsx';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { noop } from 'mojito/utils';

const { FILTER_CRITERIA_TYPE } = MojitoServices.Bets.types;
const UIView = MojitoCore.Presentation.UIView;

/**
 * View displaying a bet history UI.
 *
 * @class BetHistoryView
 * @name view
 * @memberof Mojito.Modules.BetHistory
 */

/**
 * BetHistoryView prop types.
 *
 * @property {Array<Mojito.Services.Bets.types.Bet>} betHistory - Array of bets.
 * @property {Mojito.Services.Bets.types.Pagination} [pagination = {}] - Pagination object that comes along with bets.
 * @property {boolean} [isFetchingBetHistory = false] - True if currently fetching bet history.
 * @property {object} pdfData - Information about fetching of pdf.
 * @property {Mojito.Services.Bets.types.BET_STATUS} [selectedStatusFilter] - Selected status filter in the dropdown.
 * @property {Mojito.Services.Bets.types.BetHistoryFilter} [selectedRangeFilter] - Selected range filter in the dropdown (time frame or last bets).
 * Use one of the value set by the config for betsRangeFilters.
 * @property {Function} [onItemClicked = () => {}] - Callback for when an item is clicked.
 * @property {Mojito.Core.Services.Transactions.types.Error} [error] - Error object.
 * @property {boolean} [userLoggedIn] - Flag to indicate if user is logged in.
 * @memberof Mojito.Modules.BetHistory.view
 */

const propTypes = {
    betHistory: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    isFetchingBetHistory: PropTypes.bool,
    selectedStatusFilter: PropTypes.string,
    selectedRangeFilter: PropTypes.shape({
        options: PropTypes.object,
        type: PropTypes.oneOf([
            FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT,
            FILTER_CRITERIA_TYPE.TIME_FRAME,
        ]),
    }),
    pdfData: PropTypes.object.isRequired,
    onItemClicked: PropTypes.func,
    error: PropTypes.object,
    userLoggedIn: PropTypes.bool,
};

const defaultProps = {
    isFetchingBetHistory: false,
    onItemClicked: noop,
    pagination: {},
};

export default UIView('BetHistoryView', BetHistoryViewImpl, propTypes, defaultProps);
