import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {fetchInternalResource} from 'core/utils/utils';
import {clone} from 'core/utils/config-utils';

const {dispatch} = MojitoCore.Services.redux.store;

const {actions: EventsActions} = MojitoServices.SportsContent.Events;
const {actions: SportActions} = MojitoServices.SportsContent.Sports;
const {actions: MarketGroupActions} = MojitoServices.SportsContent.MarketGroups;
const {actions: EventGroupsActions} = MojitoServices.EventGroups;

const alreadyDone = {};
const cachedResult = {};

export const FAKE_DATE = '2020-01-01T00:00:00Z';

/**
 * @param events {array<object>}
 */
export function addEventsToStore(events) {
    events.forEach(event => {
        const eventMarkets = event.markets;

        dispatch(EventsActions.updateEvent({eventId: event.id, event: clone(event)}));

        // just for mockups, because somehow pendingMarkets actions triggers right after updateMarket action
        setTimeout(() => {
            for (const eventMarketKey of Object.keys(eventMarkets || {})) {
                const market = clone(eventMarkets[eventMarketKey]);

                dispatch(EventsActions.updateMarket({eventId: event.id, marketId: market.id, market}));
            }
        }, 0);
    });
}

export function addEventGroupsToStore(groups) {
    groups.forEach(group => {
        dispatch(EventGroupsActions.updateEventGroup(group));
    });
}

export function addMarketGroupsToStore(marketGroups) {
    marketGroups.forEach(marketGroup => {
        dispatch(MarketGroupActions.updateMarketGroup({groupId: marketGroup.id, group: marketGroup}));
    });
}

export function addSportsToStore(sports) {
    dispatch(SportActions.updateSports({sports}));
}

export function addFakeSportsToStore() {
    addSportsToStore([
        {id: 'basketball', name: 'Basketball'},
        {id: 'ice_hockey', name: 'Ice hockey'},
        {id: 'soccer', name: 'Football'},
        {id: 'fakeSport3', name: 'Some Fake Sport3'},
        {id: 'fakeSport4', name: 'Some Fake Sport4'},
    ]);
}

export function once(cacheId, fn, ...args) {
    if (alreadyDone[cacheId]) return cachedResult[cacheId];
    alreadyDone[cacheId] = true;
    cachedResult[cacheId] = fn.apply(this, args);
    return cachedResult[cacheId];
}

/**
 * Build event properties based on markets that this event have
 * @param event {object} Event object
 * @returns {object} Source event
 */
export function constructEventByMarkets(event) {
    event.marketIds = Object.keys(event.markets);
    event.marketTypesToIds = Object.keys(event.markets).reduce((prev, marketId) => {
        let list = prev[event.markets[marketId].type] || [];
        list.push(marketId);
        prev[event.markets[marketId].type] = list;
        return prev;
    }, {});
    Object.values(event.markets).forEach(market => {
        Object.keys(market.selectionsMap || {}).forEach(key => {
            market.selectionsMap[key].eventId = event.id;
            market.selectionsMap[key].marketId = market.id;
        });

        (market.selections || []).forEach(selection => {
            selection.eventId = event.id;
            selection.marketId = market.id;
        });
    });

    event.market = event.markets[event.marketIds[0]];

    return event;
}

/**
 * Build Market Groups based on provided event
 * @param event {object} Event object
 */
export function buildMarketGroups(event) {
    const result = [
        {
            id: 'id-all',
            name: 'All Markets',
            marketTypes: event.marketIds.map(id => event.markets[id].type),
            displayOrder: 0,
            aggregatedMarkets: [], // TODO fill it with valid data
        },
    ];
    const uniqueTypes = [];
    event.marketIds.forEach(id => {
        const type = event.markets[id].type;
        if (uniqueTypes.includes(type)) return;
        uniqueTypes.push(type);
    });

    uniqueTypes.forEach(type => {
        result.push({
            id: `id-${type}`,
            name: `MARKET_${type}`,
            marketTypes: [type],
            displayOrder: -99,
            aggregatedMarkets: [],
        });
    });
    return result;
}

/**
 * Coverts array of objects to map
 * @param array {array} Array of objects, each object must have an 'id'
 */
export function convertListToMap(array) {
    const result = {};
    array.forEach(obj => {
        result[obj.id] = obj;
    });
    return result;
}

/**
 * Increment milliseconds to current date
 * @param increment {number}
 * @returns {string} Date in ISO format
 */
export const getISODate = (increment = 0) => {
    return new Date(new Date(FAKE_DATE).getTime() + increment).toISOString();
};

const FIXTURES_CACHE_PREFIX = 'fixtures';

async function fetchFixture(fixtureName) {
    const fetchedRawFixture = await fetchInternalResource(`fixtures/${fixtureName}.json`);

    return await fetchedRawFixture.json();
}

/**
 * Fetch data from fixture files
 * @param fixtures {array} Array or string with fixture(s) filename which should be fetched
 * @returns {Promise} Promise which return array of objects with fetched data
 */
export async function fetchFixtures(fixtures) {
    const fetchedFixtures = await Promise.all(
        fixtures.map(fixtureName => {
            const fixtureCacheName = `${FIXTURES_CACHE_PREFIX}:${fixtureName}`;

            if (cachedResult[fixtureCacheName]) return cachedResult[fixtureCacheName];

            const fetchFixturePromise = fetchFixture(fixtureName);

            cachedResult[fixtureCacheName] = fetchFixturePromise;

            return fetchFixturePromise;
        })
    );

    return getParsedFixtures(fixtures, fetchedFixtures);
}

function getParsedFixtures(fixturesList, fetchedFixtures) {
    const fixturesNames = fixturesList.map(getFixtureParsedName);

    return getStructuredFixtures(fetchedFixtures, fixturesNames);
}

function getFixtureParsedName(fixtureName) {
    const splitNameParts = fixtureName.split('-');
    const capitalizedParts = splitNameParts.map((namePart, index) => {
        if (index === 0) return namePart;

        return namePart.charAt(0).toUpperCase() + namePart.slice(1);
    });

    return capitalizedParts.join('');
}

function getStructuredFixtures(fetchedItems, fixturesNames = []) {
    const fixturesResult = {};

    fixturesNames.forEach((fixtureName, index) => {
        // in case if fixture is placed in sub-folders
        if (fixtureName.includes('/')) {
            const [rootFixtureNamePart, ...splittedFixtureNameParts] = fixtureName.split('/');
            const rootFixtureObject = fixturesResult[rootFixtureNamePart] || {};

            fixturesResult[rootFixtureNamePart] = getStructuredFixtureWithFolders(
                splittedFixtureNameParts,
                rootFixtureObject,
                fetchedItems[index]
            );

            return;
        }

        fixturesResult[fixtureName] = fetchedItems[index];
    });

    return fixturesResult;
}

function getStructuredFixtureWithFolders(fixtureNameParts = [], fixturesResult = {}, fetchedFixture) {
    if (fixtureNameParts.length > 1) {
        const [folderName, ...otherFixtureNameParts] = fixtureNameParts;

        return {
            ...fixturesResult,
            [folderName]: getStructuredFixtureWithFolders(
                otherFixtureNameParts,
                fixturesResult[folderName] || {},
                fetchedFixture
            ),
        };
    }

    return {
        ...fixturesResult,
        [fixtureNameParts[0]]: fetchedFixture,
    };
}

const clearRegex = /^background|color|display|^flex|^grid/gi;

/**
 * Clear styles from properties based on regex
 * @param stylesObject {object} Object with styles that are needed to be cleared
 * @returns {object} New object with cleared styles
 */
export function clearStyles(stylesObject = {}) {
    const newStyles = {};

    for (const stylesKey in stylesObject) {
        const stylesValue = stylesObject[stylesKey];

        if (!stylesKey.match(clearRegex) && stylesValue) {
            newStyles[stylesKey] = stylesValue;
        }
    }

    return newStyles;
}
