import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { SLIDING_VIEW_TYPES } from 'application/app/view/types.js';
import { isEmpty, merge } from 'mojito/utils';
import ApplicationTypes from './types.js';
import { isQuickBetslipActive as isQuickBetslip } from './utils.js';

const { ADD_FIRST_SELECTION, ADD_FIRST_PREPLAY_SELECTION, ADD_FIRST_INPLAY_SELECTION } =
    ApplicationTypes.SHOW_BETSLIP_CONDITION;
const { BETSLIP_TYPE } = MojitoModules.SlidingBetslip.types;
const { selectEventItem } = MojitoServices.SportsContent.Events.selectors;
const { actions: keypadActions, selectors: keypadSelectors } = MojitoModules.Keypad;
const loginHelper = MojitoModules.Login.helper;
const {
    actions: authenticationActions,
    types: AuthenticationTypes,
    selectors: { selectLoginState },
} = MojitoServices.Authentication;
const {
    actions: betslipActions,
    utils: BetslipUtils,
    selectors: { selectBetslip, selectBetslipSettings },
} = MojitoServices.Betslip;
const { selectApplicationMode } = MojitoCore.Services.SystemSettings.selectors;
const { APPLICATION_MODE } = MojitoCore.Services.SystemSettings.types;

/**
 * The name of the application settings store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Application
 */
export const STORE_KEY = 'application/main';

/**
 * Defines the state of the application store.
 *
 * @typedef ApplicationState
 * @property {boolean} isInitialized - True if application is initialized.
 * @property {object} config - Configuration object.
 * @property {string|undefined} activeSlidingViewType - Active sliding view type or undefined.
 * @property {string|undefined} activeSlidingViewData - Active sliding view data or undefined.
 * @property {boolean} keypadIsActive - True if keypad is active.
 * @memberof Mojito.Application
 */

const {
    SHOW_SLIDING_BETSLIP,
    SHOW_SLIDING_OPEN_BETS,
    SHOW_SLIDING_GAMIFICATION,
    SHOW_SLIDING_AZ_MENU,
    SHOW_QUICK_EVENTS_NAVIGATION,
    SHOW_QUICK_LEAGUES_NAVIGATION,
    HIDE_SLIDING_BETSLIP,
    HIDE_SLIDING_OPEN_BETS,
    HIDE_SLIDING_GAMIFICATION,
    HIDE_SLIDING_AZ_MENU,
    HIDE_QUICK_LEAGUES_NAVIGATION,
    HIDE_QUICK_EVENTS_NAVIGATION,
    VIEW,
    MOJITO_INITIALIZED,
    BACK_NAVIGATION,
    OVERLAY_INTERACTION,
    SHOW_FOREIGN_WINDOW,
    HIDE_FOREIGN_WINDOW,
    SHOW_ACCOUNT_POPUP,
    HIDE_ACCOUNT_POPUP,
} = MojitoPresentation.Base.Intent.Types;

const INTENT_TO_SLIDING_VIEW_TYPE = {
    [SHOW_SLIDING_BETSLIP]: SLIDING_VIEW_TYPES.BETSLIP,
    [SHOW_SLIDING_OPEN_BETS]: SLIDING_VIEW_TYPES.OPEN_BETS,
    [SHOW_SLIDING_AZ_MENU]: SLIDING_VIEW_TYPES.AZ_MENU,
    [SHOW_SLIDING_GAMIFICATION]: SLIDING_VIEW_TYPES.GAMIFICATION,
    [SHOW_QUICK_EVENTS_NAVIGATION]: SLIDING_VIEW_TYPES.QUICK_EVENTS_NAVIGATION,
    [SHOW_QUICK_LEAGUES_NAVIGATION]: SLIDING_VIEW_TYPES.QUICK_LEAGUES_NAVIGATION,
    [SHOW_FOREIGN_WINDOW]: SLIDING_VIEW_TYPES.FOREIGN_WINDOW,
    [SHOW_ACCOUNT_POPUP]: SLIDING_VIEW_TYPES.MY_ACCOUNT,
};

const SHOW_SLIDING_VIEW_INTENTS = [
    SHOW_SLIDING_BETSLIP,
    SHOW_SLIDING_OPEN_BETS,
    SHOW_SLIDING_GAMIFICATION,
    SHOW_SLIDING_AZ_MENU,
    SHOW_QUICK_EVENTS_NAVIGATION,
    SHOW_QUICK_LEAGUES_NAVIGATION,
    SHOW_FOREIGN_WINDOW,
    SHOW_ACCOUNT_POPUP,
];

const HIDE_SLIDING_VIEW_INTENTS = [
    HIDE_SLIDING_BETSLIP,
    HIDE_SLIDING_OPEN_BETS,
    HIDE_SLIDING_GAMIFICATION,
    HIDE_SLIDING_AZ_MENU,
    HIDE_QUICK_LEAGUES_NAVIGATION,
    HIDE_QUICK_EVENTS_NAVIGATION,
    HIDE_FOREIGN_WINDOW,
    VIEW,
    BACK_NAVIGATION,
    OVERLAY_INTERACTION,
    HIDE_ACCOUNT_POPUP,
];

const { LOGGED_OUT } = AuthenticationTypes.STATES;
const STORABLE_SLIDING_VIEWS = [SLIDING_VIEW_TYPES.BETSLIP];

const { NamedService } = MojitoCore.Services.Storage;
const reduxInstance = MojitoCore.Services.redux;
const intentActions = MojitoCore.Intents.actions;

const showBetslipConditions = {
    [ADD_FIRST_SELECTION]: () => true,
    [ADD_FIRST_INPLAY_SELECTION]: event => event.details.inPlay,
    [ADD_FIRST_PREPLAY_SELECTION]: event => !event.details.inPlay,
};

const applicationStorageService = new NamedService('activeOverlay');
const storedActiveView = applicationStorageService.getItem() || {};

export const initialState = {
    isInitialized: false,
    config: {
        betslipAutoShowUp: {
            [ADD_FIRST_PREPLAY_SELECTION]: BETSLIP_TYPE.QUICK_BETSLIP,
            [ADD_FIRST_INPLAY_SELECTION]: BETSLIP_TYPE.QUICK_BETSLIP,
        },
    },
    activeSlidingViewType: storedActiveView.type,
    activeSlidingViewData: storedActiveView.data,
    keypadIsActive: keypadSelectors.selectIsActive(),
};

export const { reducer, actions } = createSlice({
    name: 'application',
    initialState,
    reducers: {
        configureApplicationSettings: (state, { payload }) => {
            state.config = payload;
        },
        clearActiveViewSuccess(state) {
            state.activeSlidingViewType = undefined;
            state.activeSlidingViewData = undefined;
        },
        activeKeypad(state, { payload }) {
            state.keypadIsActive = payload;
        },
        activeSlidingType(state, { payload }) {
            state.activeSlidingViewType = payload;
        },
        activeSlidingData(state, { payload }) {
            state.activeSlidingViewData = payload;
        },
        isInitializedSuccess(state) {
            state.isInitialized = true;
        },
    },
});

/**
 * Application store related actions.
 *
 * @name actions
 * @memberof Mojito.Application
 */

/**
 * Clear active view success.
 *
 * @function clearActiveViewSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Application.actions
 */

/**
 * Clear active view success.
 *
 * @function activeKeypad
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {boolean} payload - Keypad is active value.
 *
 * @memberof Mojito.Application.actions
 */

/**
 * Update active sliding view type state.
 *
 * @function activeSlidingType
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string|undefined} payload - Active sliding type value or undefined.
 *
 * @memberof Mojito.Application.actions
 */

/**
 * Update active sliding view data state.
 *
 * @function activeSlidingData
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {object} payload - Intent data.
 *
 * @memberof Mojito.Application.actions
 */

/**
 * Is initializedSuccess success action.
 *
 * @function isInitializedSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Application.actions
 */

/**
 * This action is called for initial configuration setup for application layer.
 *
 * @function configure
 *
 * @param {object} payload - Initial configuration.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk. Dispatches restoreSession action once init is done.
 * @memberof Mojito.Application.actions
 */
actions.configure = payload => (dispatch, getState) => {
    const { config } = getState()[STORE_KEY];
    const storeConfig = payload?.application?.stores?.application || {};
    const newConfig = merge(storeConfig, config);
    dispatch(actions.configureApplicationSettings(newConfig));
};

/**
 * This action is an interface for clearActiveViewSuccess.
 *
 * @function clearActiveView
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk. Dispatches restoreSession action once init is done.
 * @memberof Mojito.Application.actions
 */
actions.clearActiveView = () => dispatch => {
    dispatch(actions.clearActiveViewSuccess());
    applicationStorageService.removeItem();
};

/**
 * Action to store active view.
 *
 * @function storeActiveView
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk. Dispatches restoreSession action once init is done.
 * @memberof Mojito.Application.actions
 */
actions.storeActiveView = () => (dispatch, getState) => {
    const { activeSlidingViewType, activeSlidingViewData } = getState()[STORE_KEY];
    if (!STORABLE_SLIDING_VIEWS.includes(activeSlidingViewType)) {
        return;
    }
    const data = {
        type: activeSlidingViewType,
        data: activeSlidingViewData,
    };
    applicationStorageService.setItem(data);
};

/**
 * Action to show betslip on condition.
 *
 * @function showBetslipOnCondition
 *
 * @param {object} event - Event data.
 * @param {object} bet - Bet.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk. Dispatches restoreSession action once init is done.
 * @memberof Mojito.Application.actions
 */
actions.showBetslipOnCondition = (event, bet) => (dispatch, getState) => {
    const { config } = getState()[STORE_KEY];
    const { betslipAutoShowUp } = config;
    if (isEmpty(betslipAutoShowUp)) {
        return;
    }

    const conditionToOpen = Object.keys(betslipAutoShowUp).find(conditionType => {
        const conditionResolver = showBetslipConditions[conditionType] || (() => false);
        return conditionResolver(event);
    });
    let betslipType = conditionToOpen && betslipAutoShowUp[conditionToOpen];
    betslipType = isBetslipApplicable(betslipType, bet)
        ? betslipType
        : BETSLIP_TYPE.STANDARD_BETSLIP;
    const shouldShow =
        !isBetslipDisabled(betslipType) &&
        selectApplicationMode(getState()) !== APPLICATION_MODE.DESKTOP;
    if (shouldShow) {
        dispatch(actions.activeSlidingType(SLIDING_VIEW_TYPES.BETSLIP));
        dispatch(actions.activeSlidingData({ betslipType }));
        dispatch(actions.storeActiveView());
    }
};

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.disposeSession,
    effect: (action, listenerApi) => {
        const { activeSlidingViewType } = listenerApi.getState()[STORE_KEY];
        const isLoggedOut = selectLoginState(listenerApi.getState()) === LOGGED_OUT;
        if (isLoggedOut && activeSlidingViewType === SLIDING_VIEW_TYPES.GAMIFICATION) {
            listenerApi.dispatch(actions.clearActiveView());
        }
    },
});

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(betslipActions.addPartSuccess, betslipActions.addCompositeSuccess),
    effect: (action, listenerApi) => {
        // Here we will decide either to show standard or the quick betslip.
        // This will happen if store has been configured to do so and if the condition matches.
        // If turns out that the number of selections in betslip not equals to 1,
        // the betslip will not be shown automatically.
        const betslip = selectBetslip(listenerApi.getState());
        const singleBets = BetslipUtils.getSingleBets(betslip);
        if (singleBets.length === 1) {
            const bet = singleBets[0];
            const eventId = BetslipUtils.getEventIdFromBet(bet);
            const event = selectEventItem(eventId, listenerApi.getState()) || {};
            listenerApi.dispatch(actions.showBetslipOnCondition(event, bet));
        } else if (
            listenerApi.getState()[STORE_KEY].activeSlidingViewType === SLIDING_VIEW_TYPES.BETSLIP
        ) {
            listenerApi.dispatch(actions.activeSlidingType(undefined));
            // Not using clearActiveView here because if we clean activeSlidingViewData immediately, the component is
            // fast enough to apply REGULAR_BETSLIP mode which is set by default, Which lead to undesired jumping.
            applicationStorageService.removeItem();
        }
    },
});

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(betslipActions.removePartSuccess, betslipActions.clearBetslipSuccess),
    effect: (action, listenerApi) => {
        const betslip = selectBetslip(listenerApi.getState());
        const betsCount = BetslipUtils.getSingleBetsCount(betslip);
        const anySlidingBetslipActive =
            listenerApi.getState()[STORE_KEY].activeSlidingViewType === SLIDING_VIEW_TYPES.BETSLIP;
        if (betsCount === 0 && anySlidingBetslipActive) {
            listenerApi.dispatch(actions.activeSlidingType(undefined));
            // Not using clearActiveView here because if we clean activeSlidingViewData immediately, the component is
            // fast enough to apply REGULAR_BETSLIP mode which is set by default, Which lead to undesired jumping.
            applicationStorageService.removeItem();
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: betslipActions.initSuccess,
    effect: (action, listenerApi) => {
        if (
            listenerApi.getState()[STORE_KEY].activeSlidingViewType !== SLIDING_VIEW_TYPES.BETSLIP
        ) {
            return;
        }

        const betslip = selectBetslip(listenerApi.getState());
        const selectionsCount = BetslipUtils.getSingleBetsCount(betslip);
        if (selectionsCount === 0) {
            listenerApi.dispatch(actions.clearActiveView());
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: betslipActions.placeBetslipSuccess,
    effect: (action, listenerApi) => {
        const { status } = action.payload;
        if (
            BetslipUtils.isOverask(status) &&
            isQuickBetslipActive(listenerApi.getState()[STORE_KEY])
        ) {
            listenerApi.dispatch(
                actions.activeSlidingData({ betslipType: BETSLIP_TYPE.STANDARD_BETSLIP })
            );
            listenerApi.dispatch(actions.storeActiveView());
        }
    },
});

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(keypadActions.setInputId, keypadActions.inputBlur),
    effect: (action, listenerApi) => {
        listenerApi.dispatch(
            actions.activeKeypad(keypadSelectors.selectIsActive(listenerApi.getState()))
        );
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: intentActions.publishIntent,
    effect: (action, listenerApi) => {
        const intent = action.payload;
        const { keypadIsActive, activeSlidingViewType } = listenerApi.getState()[STORE_KEY];
        // Keypad is treated as overlay, so if it is active during BACK_NAVIGATION, we will mark it as deactivated.
        // We will not hide any other activeSlidingView in this case.
        if (needToHideKeypad(intent.type, keypadIsActive)) {
            listenerApi.dispatch(actions.activeKeypad(false));
            return;
        }
        if (needToNavigateBack(intent.type, activeSlidingViewType)) {
            window.history.back();
            return;
        }
        if (needToShowSlidingView(intent.type)) {
            // we don't show sliding view if published intent requires user to be authenticated
            const accessAllowed = !loginHelper.isLoginRequired(intent.type);
            const type = accessAllowed ? INTENT_TO_SLIDING_VIEW_TYPE[intent.type] : undefined;
            listenerApi.dispatch(actions.activeSlidingType(type));
            listenerApi.dispatch(actions.activeSlidingData(intent.data));
            listenerApi.dispatch(actions.storeActiveView());
        } else if (needToHideSlidingView(intent.type)) {
            listenerApi.dispatch(actions.activeSlidingType(undefined));
            // Not using clearActiveView here because if we clean activeSlidingViewData immediately, the component is
            // fast enough to apply REGULAR_BETSLIP mode which is set by default, Which lead to undesired jumping.
            applicationStorageService.removeItem();
        } else if (intent.type === MOJITO_INITIALIZED) {
            listenerApi.dispatch(actions.isInitializedSuccess());
        }
    },
});

const needToHideKeypad = (intentType, keypadIsActive) =>
    intentType === BACK_NAVIGATION && keypadIsActive;
const needToNavigateBack = (intentType, activeSlidingViewType) =>
    intentType === BACK_NAVIGATION && !activeSlidingViewType;
const needToShowSlidingView = intentType => SHOW_SLIDING_VIEW_INTENTS.includes(intentType);
const needToHideSlidingView = intentType => HIDE_SLIDING_VIEW_INTENTS.includes(intentType);
const isQuickBetslipActive = state =>
    isQuickBetslip(state.activeSlidingViewType, state.activeSlidingViewData);
// Currently only check for QUICK_BETSLIP as this is the only betslip that can be disabled.
const isBetslipDisabled = type =>
    type === BETSLIP_TYPE.QUICK_BETSLIP && !selectBetslipSettings().quickBetslipEnabled;
const isBetslipApplicable = (type, bet) => {
    if (type === BETSLIP_TYPE.QUICK_BETSLIP) {
        const part = BetslipUtils.getFirstBetPart(bet);
        return isEmpty(part.alternativeSelections);
    }
    return true;
};

reduxInstance.injectReducer(STORE_KEY, reducer);
