import MojitoCore from 'mojito/core';

const DataProvider = MojitoCore.Services.DataProvider;
const COLLECTION_NAME = 'marketgroup';

/**
 *
 * Class offering the possibility to link to specific market group from mongo marketCollection.
 *
 * @class MarketGroupsProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class MarketGroupsProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(groupId, setter) {
        return super.subscribeToEntity(groupId, setter, COLLECTION_NAME);
    }
}

export default new MarketGroupsProvider();
