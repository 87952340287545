import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import BetsTypes from 'services/bets/types.js';
import MatchAccaUtils from './utils';
import serviceFactory from './service/service-factory';
import { selectOddsFormat } from 'services/user-settings/selectors';
import EventTypes from 'services/sports-content/events/types.js';
import { actions as eventActions } from 'services/sports-content/events/slice.js';
import { actions as betslipActions } from 'services/betslip/slice.js';
import { first } from 'mojito/utils';
import { selectMatchAccaById, selectMatchAcca } from './selectors';

const { MATCH_ACCA } = BetsTypes.LEG_SORT;
const reduxInstance = MojitoCore.Services.redux;

/**
 * The name of the match acca store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.MatchAcca
 */
export const STORE_KEY = 'matchAccaStore';

/**
 * Defines the state of MatchAccaStore.
 *
 * @typedef MatchAccaState
 *
 * @property {object} matchAccaPrototype - Default match acca object.
 * @property {object} matchAccas - All match accas objects.
 *
 * @memberof Mojito.Services.MatchAcca
 */
export const initialState = {
    matchAccaPrototype: MatchAccaUtils.createMatchAcca(),
    matchAccas: {},
};

export const { reducer, actions } = createSlice({
    name: STORE_KEY,
    initialState,
    reducers: {
        updateMatchAcca(state, { payload }) {
            const { eventId, options } = payload;
            state.matchAccas[eventId] = {
                ...state.matchAccas[eventId],
                ...options,
            };
        },
        addSelection(state, { payload }) {
            const { selection, parent } = payload;
            const { marketId, eventId } = parent;
            const { id: selectionId } = selection;
            if (!MatchAccaUtils.hasMatchAcca(state.matchAccas, eventId)) {
                // reset matchAcca
                state.matchAccas[eventId] = {
                    ...state.matchAccas[eventId],
                    ...MatchAccaUtils.createMatchAcca(),
                };
            }

            const matchAcca = state.matchAccas[eventId];
            if (!MatchAccaUtils.hasSelection(matchAcca, selectionId)) {
                const { selections: matchAccaSelections } = matchAcca;
                const existingSelections = MatchAccaUtils.removeSelectionByMarketId(
                    matchAccaSelections,
                    marketId
                );
                const selections = [...existingSelections, selection];
                state.matchAccas[eventId] = { ...state.matchAccas[eventId], selections };
            }
        },

        removeSelection(state, { payload }) {
            const { parent, selectionId } = payload;
            const { eventId } = parent;
            const matchAcca = state.matchAccas[eventId];
            if (MatchAccaUtils.hasSelection(matchAcca, selectionId)) {
                const { selections } = matchAcca;
                const remainingSelections = MatchAccaUtils.removeSelectionById(
                    selections,
                    selectionId
                );

                state.matchAccas[eventId] = {
                    ...state.matchAccas[eventId],
                    selections: remainingSelections,
                    odds: undefined,
                    hasInvalidCombinations: false,
                    hasError: false,
                };
            }
        },

        calculateOddsSuccess(state, { payload }) {
            const { eventId, oddsLines } = payload;
            const { odds: receivedOdds, exactOdds } = first(oddsLines) || {};
            const odds = receivedOdds || EventTypes.UNAVAILABLE_ODDS;

            const hasInvalidCombinations =
                parseFloat(odds) === parseFloat(EventTypes.UNAVAILABLE_ODDS);

            state.matchAccas[eventId] = {
                ...state.matchAccas[eventId],
                odds: { odds, exactOdds },
                hasInvalidCombinations,
                hasError: false,
            };
        },

        calculateOddsFailed(state, { payload: eventId }) {
            state.matchAccas[eventId] = {
                ...state.matchAccas[eventId],
                odds: undefined,
                hasInvalidCombinations: false,
                hasError: true,
            };
        },

        clear(state, { payload: eventId }) {
            delete state.matchAccas[eventId];
        },

        reset() {
            return { ...initialState };
        },
    },
    extraReducers: builder => {
        builder
            .addCase(
                betslipActions.addCompositeSuccess,
                (state, { payload: { requestPayload } }) => {
                    const {
                        parent: { eventId },
                        legSort,
                    } = requestPayload;

                    if (
                        legSort === MATCH_ACCA &&
                        MatchAccaUtils.hasMatchAcca(state.matchAccas, eventId)
                    ) {
                        delete state.matchAccas[eventId];
                    }
                }
            )
            .addCase(eventActions.updateEvent, (state, { payload }) => {
                const { eventId, event } = payload;
                const matchAcca = state.matchAccas[eventId];

                if (matchAcca && !event) {
                    delete state.matchAccas[eventId];
                }
            })
            .addCase(eventActions.updateMarket, (state, { payload }) => {
                const { eventId, marketId, market } = payload;
                const matchAcca = state.matchAccas[eventId];
                if (matchAcca) {
                    state.matchAccas[eventId] = MatchAccaUtils.syncSelections(
                        matchAcca,
                        marketId,
                        market?.selections
                    );
                }
            });
    },
});

/**
 * Init match acca service layer.
 *
 * @function init
 *
 * @param {MatchAccaConfig} config - Match acca config.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk.
 * @memberof Mojito.Services.MatchAcca.actions
 */
actions.init = config => () => {
    serviceFactory.init(config);
};

/**
 * Calculate odds.
 *
 * @function calculateOdds
 *
 * @param {string} eventId - Event id.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Calculate odds thunk. Dispatches calculateOddsSuccess or calculateOddsFailed when calculateOdds process is finalised.
 * @memberof Mojito.Services.MatchAcca.actions
 */
actions.calculateOdds = eventId => (dispatch, getState) => {
    const state = getState()[STORE_KEY];
    const matchAcca = state.matchAccas[eventId];

    if (!matchAcca) {
        return;
    }
    const { selections } = matchAcca;
    // If there are less than 2 selections in match acca - do not calculate odds as
    // this type of match acca is not complete and can't be placed.
    if (selections.length < 2) {
        return;
    }
    const oddsFormat = selectOddsFormat(getState());
    serviceFactory
        .getService()
        .calculateOdds(selections, MATCH_ACCA, oddsFormat)
        .then(res => {
            if (res.status === 'SUCCESS') {
                dispatch(actions.calculateOddsSuccess({ oddsLines: res.data.oddsLines, eventId }));
            } else {
                dispatch(actions.calculateOddsFailed(eventId));
            }
        })
        .catch(() => dispatch(actions.calculateOddsFailed(eventId)));
};

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(
        eventActions.updateEvent,
        eventActions.updateMarket,
        actions.addSelection,
        actions.removeSelection
    ),
    effect: (action, listenerApi) => {
        const { dispatch, getState, getOriginalState } = listenerApi;
        const { payload } = action;
        const eventId = payload.eventId || payload.parent?.eventId;
        const hasMatchAcca = MatchAccaUtils.hasMatchAcca(selectMatchAcca(), eventId);
        if (!hasMatchAcca) {
            return;
        }
        const matchAcca = selectMatchAccaById(eventId, getState());
        const prevMatchAcca = selectMatchAccaById(eventId, getOriginalState());
        const isEventUpdate = action.type === eventActions.updateEvent.toString();
        // Once event updated always recalculate odds.
        if (MatchAccaUtils.hasChanges(matchAcca, prevMatchAcca) || isEventUpdate) {
            dispatch(actions.calculateOdds(eventId));
        }
    },
});

/**
 * Config object used to initialise match acca service layer.
 *
 * @typedef MatchAccaConfig
 * @type {object}
 * @property {Mojito.Services.Betslip.AbstractBetslipService} service - Match acca service implementation.
 * @property {string} serviceUrl - URL that will be used by <code>service<code/> instance.
 *
 * @memberof Mojito.Services.MatchAcca
 */

/**
 * Actions related to the MatchAcca services.
 *
 * @class MatchAccaActions
 * @name actions
 * @memberof Mojito.Services.MatchAcca
 */

/**
 * Add Selection.
 *
 * @function addSelection
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{selection: object, parent: object}} payload - Payload contains selection object and selection parent info.
 *
 * @memberof Mojito.Services.MatchAcca.actions
 */

/**
 * Remove Selection.
 *
 * @function removeSelection
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{selectionId: string, parent: object}} payload - Payload contains selection id and selection parent info.
 *
 * @memberof Mojito.Services.MatchAcca.actions
 */

/**
 * Calculate odds success action.
 *
 * @function calculateOddsSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{eventId: string, oddsLines: object}} payload - Payload contains event id and odds lines object.
 *
 * @memberof Mojito.Services.MatchAcca.actions
 */

/**
 * Calculate odds failed action.
 *
 * @function calculateOddsFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} eventId - Event id.
 *
 * @memberof Mojito.Services.MatchAcca.actions
 */

/**
 * Remove match acca by event id.
 *
 * @function clear
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} eventId - Event id of the match acca that should be removed.
 *
 * @memberof Mojito.Services.MatchAcca.actions
 */

/**
 * Reset match acca state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.MatchAcca.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
