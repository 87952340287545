import { useState, useRef, useCallback, useEffect } from 'react';
import MojitoCore from 'mojito/core';
import Button from 'presentation/components/button/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import ScrollPane from 'presentation/components/scroll-pane/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';
import { isEmpty } from 'mojito/utils';
import { useEffectOnUpdate } from 'presentation/hooks/index.jsx';

const classUtils = MojitoCore.Base.classUtils;

const SCROLL_DIRECTION = {
    LEFT: -1,
    RIGHT: 1,
};

const SCROLL_BUTTON_POSITION = {
    LEFT: 'left',
    RIGHT: 'right',
};

const SCROLL_BEHAVIOR = {
    START: 'start',
    CENTER: 'center',
    END: 'end',
};

const ButtonScrollPane = ({
    refs,
    children,
    selectedItemKey,
    mojitoTools,
    class: componentClass,
}) => {
    const { config, style } = mojitoTools;
    const [targetScrollPosition, setTargetScrollPosition] = useState(0);
    const [scrollPaneState, setScrollPaneState] = useState({
        scrollPosition: 0,
        maxScrollPosition: 0,
        scrollPaneWidth: undefined,
    });
    const { scrollPosition, maxScrollPosition, scrollPaneWidth } = scrollPaneState;
    const leftScrollButtonRef = useRef();
    const rightScrollButtonRef = useRef();
    const scrollRef = useRef();

    const stringifiedRefsKeys = Object.keys(refs).join('-');

    useEffect(() => {
        return () => {
            // We had setTargetScrollPosition scrolling sticking issue because of setTargetScrollPosition updates in onScrollPositionChanged method,
            // causing race conditions issues, when switching market group buttons fast.
            // So after we got rid of it we faced another issue that is fixed here,
            // where the scroll pane was scrolled by the user without changing selectedItemKey
            // after user navigates between the similar pages (i.e. event pages).
            if (scrollRef.current) {
                scrollRef.current?.scrollTo({
                    top: 0,
                    left: 0,
                });
            }
        };
    }, [stringifiedRefsKeys]);

    useEffectOnUpdate(() => {
        scrollToSelectedItem(maxScrollPosition);
    }, [stringifiedRefsKeys, selectedItemKey]);

    const scrollToSelectedItem = maxScrollPosition => {
        const targetRef = refs[selectedItemKey];
        if (config.scrollItemIntoView && targetRef?.current) {
            const pos = getScrollPosition(
                targetRef,
                targetRef.current?.offsetLeft,
                maxScrollPosition
            );
            setTargetScrollPosition(pos);
        }
    };

    const scrollToItemEnd = (sortedRefsOffsetLeft, target, lastItem, maxScrollPosition) => {
        if (lastItem) {
            return maxScrollPosition;
        }
        const containerGap = parseFloat(target?.current?.parentElement?.style.gap || 0);

        // filter only the refs that are after the selected one
        const filteredRefs = sortedRefsOffsetLeft.filter(
            ref => ref.current.offsetLeft > target?.current.offsetLeft
        );
        return (
            maxScrollPosition -
            filteredRefs.reduce((current, rightRef) => current + rightRef.current?.offsetWidth, 0) -
            filteredRefs.length * containerGap
        );
    };

    const getScrollPosition = (targetRef, offsetLeft, maxScrollPosition) => {
        const sortedRefsPosition = Object.values(refs).sort(
            (a, b) => a.current.offsetLeft - b.current.offsetLeft
        );

        const parentOffsetWidth = targetRef?.current.offsetParent?.offsetWidth;
        const parentPaddingLeft = parseFloat(
            targetRef?.current.parentElement?.style.paddingLeft || 0
        );
        const isLastItem = sortedRefsPosition.at(-1).current.offsetLeft === offsetLeft;
        const isFirstItem = sortedRefsPosition.at(0).current.offsetLeft === offsetLeft;

        switch (config.scrollItemIntoViewBehavior) {
            case SCROLL_BEHAVIOR.END:
                return scrollToItemEnd(
                    sortedRefsPosition,
                    targetRef,
                    isLastItem,
                    maxScrollPosition
                );
            case SCROLL_BEHAVIOR.START:
                return isFirstItem ? 0 : offsetLeft - parentPaddingLeft;
            default:
                return parentOffsetWidth
                    ? offsetLeft - parentOffsetWidth / 2 + targetRef?.current?.offsetWidth / 2
                    : 0;
        }
    };

    const onScrollPaneChanged = useCallback(scrollData => {
        setScrollPaneState({
            scrollPosition: scrollData.scrollPosition,
            maxScrollPosition: scrollData.maxScrollPosition,
            scrollPaneWidth: scrollData.viewportSize,
        });
    }, []);

    const onScrollPositionChanged = useCallback(scrollData => {
        setScrollPaneState({
            scrollPosition: scrollData.scrollPosition,
            maxScrollPosition: scrollData.maxScrollPosition,
            scrollPaneWidth: scrollData.viewportSize,
        });
    }, []);

    const snapToItemsOnScroll = () => {
        return config.snapToItemOnScroll && Object.values(refs).length > 1;
    };

    const onScrollPaneInit = useCallback((scrollData, el) => {
        onScrollPaneChanged(scrollData);
        scrollToSelectedItem(scrollData.maxScrollPosition);
        // Save the scroll element reference
        scrollRef.current = el;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollLeft = () => {
        const leftButtonWidth = leftScrollButtonRef?.current?.offsetWidth ?? 0;
        const targetOffsetLeft = Object.values(refs)
            .map(ref => ref.current?.offsetLeft)
            .sort((a, b) => b - a)
            .find(offsetLeft => offsetLeft < scrollPosition + leftButtonWidth);

        return targetOffsetLeft - leftButtonWidth;
    };

    const scrollRight = () => {
        const rightButtonWidth = rightScrollButtonRef?.current?.offsetWidth ?? 0;
        const targetOffsetLeft = Object.values(refs)
            .map(ref => ref.current?.offsetLeft)
            .sort((a, b) => a - b)
            .find(offsetLeft => offsetLeft > scrollPosition + scrollPaneWidth + rightButtonWidth);

        return !targetOffsetLeft
            ? maxScrollPosition
            : targetOffsetLeft - scrollPaneWidth + rightButtonWidth;
    };

    const onButtonScroll = (event, direction) => {
        let targetPosition;
        if (snapToItemsOnScroll()) {
            targetPosition = direction === SCROLL_DIRECTION.RIGHT ? scrollRight() : scrollLeft();
        } else {
            const round = direction === SCROLL_DIRECTION.RIGHT ? Math.ceil : Math.floor;
            const delta = round(Math.abs(scrollPaneWidth * config.scrollFactor) * direction);
            targetPosition = scrollPosition + delta;
        }

        setTargetScrollPosition(targetPosition);
    };

    const isLeftButtonEnabled = () => config.useButtonScrolling && Math.floor(scrollPosition) > 0;
    const isRightButtonEnabled = () =>
        config.useButtonScrolling && Math.ceil(scrollPosition) < maxScrollPosition;
    const className = classUtils.classNames('ta-ButtonScrollPane', componentClass);

    return (
        <FlexPane class={className} config={style.scrollPaneContainer}>
            {isLeftButtonEnabled() && (
                <ScrollButton
                    config={buildScrollButtonConfig(config, SCROLL_BUTTON_POSITION.LEFT)}
                    class={'ta-LeftScrollButton'}
                    onClick={onButtonScroll}
                    onClickData={SCROLL_DIRECTION.LEFT}
                    buttonRef={leftScrollButtonRef}
                />
            )}
            {!isEmpty(refs) && (
                <ScrollPane
                    targetPosition={targetScrollPosition}
                    onInit={onScrollPaneInit}
                    onScroll={onScrollPositionChanged}
                    onResize={onScrollPaneChanged}
                    config={style.scrollPane}
                >
                    <FlexPane config={config.contentContainer}>{children}</FlexPane>
                </ScrollPane>
            )}
            {isRightButtonEnabled() && (
                <ScrollButton
                    config={buildScrollButtonConfig(config, SCROLL_BUTTON_POSITION.RIGHT)}
                    class={'ta-RightScrollButton'}
                    onClick={onButtonScroll}
                    onClickData={SCROLL_DIRECTION.RIGHT}
                    buttonRef={rightScrollButtonRef}
                />
            )}
        </FlexPane>
    );
};

const buildScrollButtonConfig = (config, direction) => {
    return {
        scrollButtonContainer: config[`${direction}ScrollButtonContainer`],
        scrollButton: config[`${direction}ScrollButton`],
        scrollButtonIcon: config[`${direction}ScrollButtonIcon`],
    };
};

const ScrollButton = props => {
    const { config, onClickData, onClick, class: className, buttonRef } = props;
    return (
        <AbsolutePane config={config.scrollButtonContainer}>
            <Button
                config={config.scrollButton}
                class={className}
                onClick={onClick}
                onClickData={onClickData}
                buttonElementRef={buttonRef}
            >
                <Image config={config.scrollButtonIcon} />
            </Button>
        </AbsolutePane>
    );
};

ButtonScrollPane.getStyle = (config, applicationMode, merge) => {
    const { scrollPaneContainer, useButtonScrolling } = config;
    const { nativeScroll, mouseDragScroll } = config.scrollPane;
    return {
        scrollPaneContainer: merge(scrollPaneContainer, { createStackingContext: true }),
        scrollPane: {
            ...config.scrollPane,
            direction: 'x',
            nativeScroll: nativeScroll || (applicationMode === 'mobile' && !useButtonScrolling),
            mouseDragScroll:
                mouseDragScroll || (applicationMode === 'desktop' && !useButtonScrolling),
        },
    };
};

export default ButtonScrollPane;
