import PropTypes from 'prop-types';
import TopSportsControllerViewImpl from './top-sports-controller-view.jsx';
import MojitoCore from 'mojito/core';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * A module that displays sports based on the provided configuration, specifically the `sportListName`.
 *
 * @class TopSportsControllerView
 * @name TopSports
 * @memberof Mojito.Modules
 */

/**
 * `TopSportsControllerView` prop types.
 *
 * @property {string} [navigationTitle] - The title displayed in the navigation header of the Top Sports view.
 *
 * @memberof Mojito.Modules.TopSports
 */
const propTypes = {
    navigationTitle: PropTypes.string,
};

export default UIControllerView('TopSportsControllerView', TopSportsControllerViewImpl, propTypes);
