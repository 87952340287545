import PropTypes from 'prop-types';
import StakeImpl from './stake-info.jsx';
import MojitoCore from 'mojito/core';
const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to display stake info of bet funds.
 *
 * @class StakeInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * StakeInfo props.
 *
 * @property {number} [value] - Stake value.
 * @property {number} [previousValue] - Previous stake value.
 * @property {boolean} [isTotal = true] - Flag to indicate if label should be $BET_FUNDS.STAKE or $BET_FUNDS.TOTAL_STAKE.
 * @property {string} [currencyCode] - Currency.
 * @property {boolean} [showFreeBetIcon] - Flag to display an icon if stake contains freebet.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.StakeInfo
 */
const propTypes = {
    value: PropTypes.number,
    previousValue: PropTypes.number,
    isTotal: PropTypes.bool,
    currencyCode: PropTypes.string,
    showFreeBetIcon: PropTypes.bool,
};

const defaultProps = {
    isTotal: true,
};

export default UIView('StakeInfo', StakeImpl, propTypes, defaultProps);
