import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('AbstractExternalNavigationService');

/**
 * @typedef ModalWindow
 * @type {object}
 * @property {Function} onClosed - The onClosed is called when the modal window is closed.
 * @memberof Mojito.Services.ExternalNavigation.AbstractExternalNavigationService
 */

/**
 * @typedef ExternalNavigationPayload
 * @type {object}
 * @property {string} url - External url to navigate to.
 * @property {string} windowName - Window name.
 * @property {string} [windowFeatures] - A string containing a comma-separated list of window features.
 * @property {Mojito.Services.ExternalNavigation.AbstractExternalNavigationService.ModalWindow} [modal] - If exists the url should be opened as a modal window.
 * @memberof Mojito.Services.ExternalNavigation.AbstractExternalNavigationService
 */

/**
 * Abstract external navigation service to be implemented by different integrations.
 *
 * @class AbstractExternalNavigationService
 * @abstract
 * @memberof Mojito.Services.ExternalNavigation
 */
export default class AbstractExternalNavigationService {
    /**
     * Navigate to external url.
     *
     * @param {Mojito.Services.ExternalNavigation.AbstractExternalNavigationService.ExternalNavigationPayload} payload - External navigation payload.
     * @function Mojito.Services.ExternalNavigation.AbstractExternalNavigationService#navigate
     */
    // eslint-disable-next-line no-unused-vars
    navigate(payload) {
        log.error('navigate must be implemented by a concrete subclass!');
    }
}
