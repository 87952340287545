import MojitoCore from 'mojito/core';
const DataProvider = MojitoCore.Services.DataProvider;

/**
 *
 * Class offering the possibility to link to promotions.
 *
 * @class PromotionsProvider
 * @extends Mojito.Core.DataProviders.DataProvider
 * @memberof Mojito.Services.Promotions
 */
class PromotionsProvider extends DataProvider {
    init(languageCode) {
        this.addLocalizedCollection(PromotionsProvider.COLLECTION_NAME, languageCode);
    }

    /**
     * Link to a promotion channel.
     *
     * @param {string} channel - The specific promotion channel to link to (example: desktop|mobile|desktop_auth).
     * @param {Function} setter - Function called when the requested data is updated.
     * @returns {object} Subscription that must be disposed when no longer needed.
     * @function Mojito.Services.Promotions.PromotionsProvider#subscribeToEntity
     */
    subscribeToEntity(channel, setter) {
        return super.subscribeToEntity(channel, setter, PromotionsProvider.COLLECTION_NAME);
    }
}

PromotionsProvider.COLLECTION_NAME = 'promotions';

export default new PromotionsProvider();
