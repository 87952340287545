import PropTypes from 'prop-types';
import TopSportsImpl from './top-sports.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Show a list with the top X sports.
 *
 * @class TopSports
 * @private
 * @memberof Mojito.Modules.TopSports
 */

/**
 * TopSports prop types.
 *
 * @property {Array} [topSports = []] - Array of top sports codes.
 * @property {string} [navigationTitle] - Title to show in the navigation header.
 * @property {string} [selectedSport] - Selected sport ID.
 * @property {Function} [onSportClicked = ()=>{}] - Called when a sport item in the navigation part was clicked.
 * @property {Function} [onShowUp = ()=>{}] - Called when view is shown within a view port.
 * @property {boolean} [isLoading = false] - True if top sports information is loading.
 *
 * @memberof Mojito.Modules.TopSports.TopSports
 */
const propTypes = {
    topSports: PropTypes.array,
    navigationTitle: PropTypes.string,
    selectedSport: PropTypes.string,
    onSportClicked: PropTypes.func,
    isLoading: PropTypes.bool,
    onShowUp: PropTypes.func,
};

const defaultProps = {
    onSportClicked: noop,
    onShowUp: noop,
    topSports: [],
    isLoading: false,
};

export default UIView('TopSports', TopSportsImpl, propTypes, defaultProps);
