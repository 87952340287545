/**
 * @class SelectionButtonTypes
 * @name types
 * @memberof Mojito.Presentation.SelectionButton
 */
export default class SelectionButtonTypes {}

/**
 * SelectionButtonTypes NAME_DISPLAY enum, used to determine how much of the selection button info to display.
 *
 * @typedef NAME_DISPLAY
 * @property {string} SHOW - Show full selection button info.
 * @property {string} HIDE - Hide selection button info.
 * @property {string} SHOW_SHORT - Show short version of selection button info.
 * @property {string} SHOW_SHORTEST - Show shortest version of selection button info.
 * @memberof Mojito.Modules.SelectionButton.types
 */
SelectionButtonTypes.NAME_DISPLAY = {
    SHOW: 'show',
    HIDE: 'hide',
    SHOW_SHORT: 'show short',
    SHOW_SHORTEST: 'show shortest',
};

/**
 * SelectionButtonTypes STATES, used to determine current state of the selection.
 *
 * @typedef STATES
 * @property {string} INACTIVE - Selection unselected (default state).
 * @property {string} ACTIVE - Selection added to betSlip.
 * @property {string} PENDING - Selection is being added to/removed from betslip.
 * @property {string} DISABLED - Selection unavailable for selection.
 * @memberof Mojito.Modules.SelectionButton.types
 */
SelectionButtonTypes.STATES = {
    INACTIVE: 'inactive',
    ACTIVE: 'active',
    PENDING: 'pending',
    DISABLED: 'disabled',
};

/**
 * Enum for HOVER_STATE, determining whether the selection is being hovered over.
 *
 * @typedef HOVER_STATE
 * @property {string} HOVER - Selection is hovering.
 * @memberof Mojito.Modules.SelectionButton.types
 */
SelectionButtonTypes.HOVER_STATE = 'hover';

/**
 * Enum for PRICE_CHANGE_STATES, determining the different states of the price.
 *
 * @typedef PRICE_CHANGE_STATES
 * @property {string} INCREASE - Price increase.
 * @property {string} DECREASE - Price decrease.
 * @property {string} NONE - Price not changed.
 * @memberof Mojito.Modules.SelectionButton.types
 */
SelectionButtonTypes.PRICE_CHANGE_STATES = {
    INCREASE: 'increase',
    DECREASE: 'decrease',
    NONE: 'none',
};
