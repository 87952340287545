import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';
const { FlexPane, SkeletonPart } = MojitoPresentation.Components;

const PrebuiltBetsSkeleton = props => {
    const {
        mojitoTools: { config },
    } = props;

    return (
        <FlexPane config={config.container}>
            {range(config.numberOfCards).map(i => (
                <Card key={i} {...props} />
            ))}
        </FlexPane>
    );
};

const Card = props => {
    const {
        mojitoTools: { config },
    } = props;

    return (
        <FlexPane config={config.cardContainer} class="ta-PrebuiltBetsSkeleton">
            <FlexPane config={config.selectionItemsContainer}>
                <SelectionItem config={config} />
                <SelectionItem config={config} />
                <SelectionItem config={config} />
            </FlexPane>
            <FlexPane config={config.selectionButtonContainer}>
                <SkeletonPart config={config.selectionButtonSkeleton} />
            </FlexPane>
        </FlexPane>
    );
};

const SelectionItem = ({ config }) => (
    <FlexPane config={config.selectionItem}>
        <SkeletonPart config={config.iconSkeleton} />
        <FlexPane config={config.selectionTextContainer}>
            <SkeletonPart config={config.selectionTopRow} />
            <SkeletonPart config={config.selectionBottomRow} />
        </FlexPane>
    </FlexPane>
);

export default PrebuiltBetsSkeleton;
