/**
 * Class defining generic sport meta information data descriptors.
 *
 * @class SportMetaInformationDescriptor
 * @name descriptor
 * @memberof Mojito.Services.SportsContent.SportMetaInformation
 */
export default class SportMetaInformationDescriptor {
    /**
     * Factory method for creating a generic sport meta information descriptor.
     *
     * @param {string} sportMetaInformationId - Id of sport meta information.
     *
     * @returns {{dataType: string, sportMetaInformationId: string}} Generic sport meta information descriptor.
     *
     * @function Mojito.Services.SportsContent.SportMetaInformation.descriptor#createSportMetaInformationDescriptor
     * @static
     */
    static createSportMetaInformationDescriptor(sportMetaInformationId) {
        return {
            dataType: SportMetaInformationDescriptor.DATA_TYPES.SPORT_META_INFORMATION,
            sportMetaInformationId,
        };
    }

    /**
     * Factory method for creating multi event data descriptor.
     *
     * @param {Array<object>} requests - The list of sport meta request objects.
     *
     * @returns {{dataType: string, requests: Array<object>}} Events data descriptor.
     *
     * @function Mojito.Services.SportsContent.SportMetaInformation.descriptor#createSportMetaChunkDescriptor
     * @static
     */
    static createSportMetaChunkDescriptor(requests) {
        return {
            dataType: SportMetaInformationDescriptor.DATA_TYPES.SPORT_META_INFORMATION_CHUNK,
            requests,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} SPORT_META_INFORMATION - Sport meta information data descriptor type.
 * @property {string} SPORT_META_INFORMATION_CHUNK - Sport meta information chunk data descriptor type.
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.descriptor
 */
SportMetaInformationDescriptor.DATA_TYPES = {
    SPORT_META_INFORMATION: 'sport-meta-information',
    SPORT_META_INFORMATION_CHUNK: 'sport-meta-information-chunk',
};
