import CrossSellMenuImpl from './cross-sell-menu.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * This is a view for the cross-sell menu, capable of containing links to both internal and external pages.
 *
 * @class CrossSellMenu
 * @memberof Mojito.Application
 */

const propTypes = {
    onClick: PropTypes.func,
};

const defaultPropTypes = {
    onClick: noop,
};
export default UIView('CrossSellMenu', CrossSellMenuImpl, propTypes, defaultPropTypes);
