import PropTypes from 'prop-types';
import EventTimingStatusImpl from './event-timing-status.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View displaying event details like stream availability
 * and start date/time.
 *
 * @class EventTimingStatus
 * @memberof Mojito.Presentation.Components
 */

/**
 * EventTimingStatus prop types.
 *
 * @property {object} eventItem - The event object.
 * @property {Function} [onClick = ()=>{}] - Callback function that is triggered once component is clicked.
 *
 * @memberof Mojito.Presentation.Components.EventTimingStatus
 */
const propTypes = {
    eventItem: PropTypes.object.isRequired,
    onClick: PropTypes.func,
};

const defaultProps = {
    onClick: noop,
};

export default UIView('EventTimingStatus', EventTimingStatusImpl, propTypes, defaultProps);
