import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Quick start guide related selectors.
 *
 * @module QuickStartGuideSelectors
 * @name selectors
 * @memberof Mojito.Services.QuickStartGuide
 */

/**
 * Selects quick start guide state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.QuickStartGuide.types.QuickStartGuideStoreState} Quick start guide state.
 * @memberof Mojito.Services.QuickStartGuide.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects guides state.
 *
 * @function selectGuides
 *
 * @param {object} [state] - Application state object.
 *
 * @returns {Array<Mojito.Services.QuickStartGuide.types.Guide>} Array of guides.
 * @memberof Mojito.Services.QuickStartGuide.selectors
 */
export const selectGuides = state => selectState(state).guides;

/**
 * Selects menu state.
 *
 * @function selectMenu
 *
 * @param {object} [state] - Application state object.
 *
 * @returns {Mojito.Services.QuickStartGuide.types.Menu} Menu.
 * @memberof Mojito.Services.QuickStartGuide.selectors
 */
export const selectMenu = state => selectState(state).menu;
