import PropTypes from 'prop-types';
import MenuLinkPromotionsImpl from './menu-link-promotions.jsx';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;
const { LOCATION } = MojitoServices.Promotions.types;

/**
 * Module that displays promotions as a menu links row.
 * In default implementation promotions are retrieved from promotions store
 * using locationId config property and applicablePage component prop.
 *
 * @class MenuLinkPromotions
 * @memberof Mojito.Modules.Promotions
 */

/**
 * @callback linkClickCallback
 *
 * @param {Mojito.Presentation.Components.MenuRow.MenuRowItem} item - Item that has been clicked.
 * @memberof Mojito.Modules.Promotions.MenuLinkPromotions
 */

/**
 * MenuLinkPromotions prop types.
 *
 * @property {Mojito.Services.Promotions.types.LOCATION} [locationId] - Location id used to find the promotions to show. This value can also be set through component config. If provided in both places then component property takes precedence over the one from config.
 * @property {string} [applicablePage] - If provided, only promotions that belong to particular page will be presented.
 * @property {Array<Mojito.Presentation.Components.MenuRow.MenuRowItem>} [extraLinks = []] - The list of additional links to be render next to promotions available in promotions store.
 * @property {Mojito.Modules.Promotions.MenuLinkPromotions.linkClickCallback} [onLinkClick = () => {}] - Callback triggered once link is clicked.
 * @property {Function} [onDataLoad = () => {}] - Callback triggered when component and its children have loaded all data.
 * @memberof Mojito.Modules.Promotions.MenuLinkPromotions
 */
const propTypes = {
    locationId: PropTypes.oneOf(Object.values(LOCATION)),
    applicablePage: PropTypes.string,
    extraLinks: PropTypes.array,
    onLinkClick: PropTypes.func,
    onDataLoad: PropTypes.func,
};

const defaultProps = {
    extraLinks: [],
    onLinkClick: noop,
    onDataLoad: noop,
};

export default UIView('MenuLinkPromotions', MenuLinkPromotionsImpl, propTypes, defaultProps);
