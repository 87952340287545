import {icon} from 'core/utils/style-utils';

export function bonusHistoryFactory(PALETTE, BONUS_ITEM) {
    const {FONT} = PALETTE;

    return {
        BonusHistory: {
            compactMode: true,
            compactNoBonusesLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUSES_NOT_FOUND_TEXT,
                },
            },
            compactItemList: {
                // mobile container for everything. FlexPane
                itemSpacing: '8px',
                style: {
                    padding: 0,
                },
            },
            bonusTitle: {
                style: {
                    ...BONUS_ITEM.title,
                },
            },
            prewagerIcon: {
                style: BONUS_ITEM.icon,
            },
            freeBetIcon: {
                style: BONUS_ITEM.icon,
            },
            cashIcon: {
                style: BONUS_ITEM.icon,
            },
            compactItem: {
                container: {
                    style: {
                        borderStyle: 'none',
                        ...BONUS_ITEM.container,
                    },
                },
                nameContainer: {
                    style: {
                        padding: '0 0 8px',
                    },
                },
                headerContainer: {
                    style: {
                        ...PALETTE.BONUS_HISTORY_ITEM_HEADER_TEXT,
                    },
                },
                nameDateContainer: {
                    style: BONUS_ITEM.expiryDate,
                },
                status: {
                    style: {
                        ...BONUS_ITEM.status,
                    },
                },
                description: {
                    style: BONUS_ITEM.description,
                },
                amount: BONUS_ITEM.betFundsInfo,
                highlightedAmount: BONUS_ITEM.betFundsInfo,
                remainingAmount: BONUS_ITEM.betFundsInfo,
            },
            spinnerLabel: {
                container: {
                    fontSize: FONT.SIZE_MEDIUM,
                    textAlign: 'center',
                    ...PALETTE.BONUS_HISTORY_LOADING_TEXT,
                },
            },
            spinner: {
                image: icon(PALETTE.BONUS_HISTORY_LOADING_ICON_COLOR),
            },
        },
    };
}
