import PropTypes from 'prop-types';
import ExpandableViewImpl from './expandableview.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * View displaying a header with expandable/collapsible content area.
 *
 * @class ExpandableView
 * @memberof Mojito.Presentation.Components
 */

/**
 * ExpandableView prop types.
 *
 * @property {boolean} [expanded = undefined] - Used to set the expanded state (it overrides the config 'initiallyExpanded' when defined at component creation).
 * @property {Array<node>} [rightItems = []] - List of items to show on the right side of header. * @property {string} [headerText = ''] - Header text.
 * @property {string} [leftIconSrc] - Src of left icon rendered between the left expand icon and the header text.
 * @property {Function} [onExpandedChanged = ()=>{}] - Notification callback for "expanded" state changes.
 * @property {object} children - Child view rendered in the content area.
 * @property {string} [class = ''] - Name applied to root component.
 * @property {boolean} [loading = false] - Set to true if content area is dynamic and require some time to be loaded. It will show a spinner in the header to indicate loading process.
 * @property {Function} [headerRenderer = ()=>{}] - Function used to render custom header. If not provided then simple Text will be used to render headerText.
 *
 * @memberof Mojito.Presentation.Components.ExpandableView
 */
const propTypes = {
    expanded: PropTypes.bool,
    rightItems: PropTypes.arrayOf(PropTypes.node),
    headerText: PropTypes.string,
    leftIconSrc: PropTypes.string,
    onExpandedChanged: PropTypes.func,
    headerRenderer: PropTypes.func,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    class: PropTypes.string,
};

const defaultProps = {
    rightItems: [],
    loading: false,
    headerText: '',
    onExpandedChanged: noop,
    headerRenderer: noop,
    class: '',
};

export default UIView('ExpandableView', ExpandableViewImpl, propTypes, defaultProps);
