module.exports = {
  "en-US": {
    "LANGUAGE_NATIVE_NAME": "English(US)"
  },
  "en-GB": {
    "LANGUAGE_NATIVE_NAME": "English"
  },
  "es-ES": {
    "LANGUAGE_NATIVE_NAME": "Español"
  }
};