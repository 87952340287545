import {getObjectProperty} from 'core/utils/config-utils';
import {
    COLOR_CHOOSE_STRATEGY,
    DATA_TYPE,
    parseBool,
    parseInteger,
    parseList,
    parseNumber,
    parseString,
    parseText,
} from 'core/utils/data-validation.cjs';
import {constructTilePalette} from './tiles-cache';
import {tileSchemaById} from '../schema-registry';
import {convertTypes, postProcessFallbackValue} from './palette-retriever.utils';
import {resolveColor} from 'core/utils/color-utils';

const MAX_PASSES = 3;
const showUndescribed = true;

// basedOn always refers to resolved value
// fallback refers to intermediate value

// Retriever is stateful - it affects tiles cache
export class PaletteRetriever {
    constructor(logger, context = {defaults: null, defaultsResolved: null, basedOn: undefined}) {
        this.logger = logger;
        // Cache for intermediate, not final result. Example: "#fff|#000" for colors
        this.intermediateCache = Object.create(context.defaults);
        // Cache for final token values.
        // It holds resolved transparent backgrounds (transparent -> based on transparent -> which is based on red, resulting red),
        // resolved tiles and maps.
        // For tiles and maps it has full structure together with flat map in form resolvedValuesCache[fullPath]. fullPath may contain dots
        this.resolvedValuesCache = Object.create(context.defaultsResolved);
        this.basedOnValue = context.basedOn;
        this.defaultStrategy = [COLOR_CHOOSE_STRATEGY.MAX_CONTRAST];
    }

    constructPaletteBySchema(config, schema) {
        const errors = [];
        let result = getObjectProperty(config, schema.key, null);
        if (schema.strategy) {
            this.defaultStrategy = schema.strategy;
        }
        this.isTile = schema.key.startsWith('tile.');
        this.allowVariants = this.isTile && schema.darkLightVariants;

        if (result) {
            result = this.validateMap(
                result,
                schema.items,
                this.resolvedValuesCache,
                this.intermediateCache,
                schema.key,
                errors
            );
        } else {
            if (schema.required) {
                errors.push(`No config at "${schema.key}"`);
            } else {
                // fill with default values
                result = this.validateMap(
                    {},
                    schema.items,
                    this.resolvedValuesCache,
                    this.intermediateCache,
                    schema.key,
                    errors
                );
            }
        }

        if (errors.length > 0) {
            errors.forEach(msg => this.logger.warn(msg));
            if (IS_LOCAL) {
                throw new Error(`Configuration is not valid`);
            }
        }
        return result;
    }

    validateMap(config, schemaItems, resolvedValuesCache, intermediateCache, pathPrefix = '', errors = []) {
        const result = Object.create(null);
        const copiedConfig = {...config};

        let itemsToProceed = schemaItems;
        let postponed;
        const self = this;

        // Process schema item and fill up fallbacks
        function processSchemaItem(schemaItem) {
            let value;

            let basementColor = self.basedOnValue;
            if (schemaItem.basedOn) {
                basementColor = tryToGetValueAndCache(resolvedValuesCache, schemaItem.basedOn);
                if (basementColor === undefined) {
                    postponed.add(schemaItem); // dependency not yet resolved, so postpone it
                    return;
                }
                if (basementColor === 'none' || basementColor === 'transparent') {
                    basementColor = self.basedOnValue;
                }
                if (basementColor && !basementColor.startsWith('#')) {
                    basementColor = resolveColor(basementColor); // resolve gradients
                }
            }

            // Preliminary check for TILE
            // No fallbacks, no type transform, no intermediates
            if (schemaItem.type === DATA_TYPE.TILE) {
                if (!tileSchemaById[schemaItem.tile]) {
                    errors.push(`Unknown tile ID: ${schemaItem.tile}`);
                    return;
                }
                value = constructTilePalette(schemaItem.tile, self.logger, basementColor, config[schemaItem.key]);
                intermediateCache[schemaItem.key] = value;
                resolvedValuesCache[schemaItem.key] = value;
                result[schemaItem.key] = value;
                return;
            }

            const parsed = self.getParsedValue(
                config,
                schemaItem,
                resolvedValuesCache,
                intermediateCache,
                pathPrefix,
                errors
            );
            if (parsed.error) {
                errors.push(parsed.error);
                return;
            }

            // Resolve fallbacks first
            if (parsed.value === undefined) {
                if (!schemaItem.fallback) {
                    // no value, no fallback - just do nothing - value will be undefined
                    return;
                }
                // Choose which cache (source/resolved) to lookup
                const cacheToLookUp =
                    schemaItem.strategy && schemaItem.strategy[0] === COLOR_CHOOSE_STRATEGY.EXACT
                        ? resolvedValuesCache
                        : intermediateCache;

                value = tryToGetValueAndCache(cacheToLookUp, schemaItem.fallback);
                if (value === undefined) {
                    // fallback is not resolved. Postpone this item to next iteration
                    postponed.add(schemaItem);
                    return;
                }

                // Got fallback value. Process it (convert types and apply calculations)
                value = postProcessFallbackValue(schemaItem, value, self.defaultStrategy, basementColor, errors);
            } else {
                // no any fallbacks, just direct value
                value = parsed.value;
            }

            const sourceValue = value; // intermediate has not processed colors, because they're still context dependent
            let resolvedValue;

            // Handle/convert types
            value = convertTypes(value, schemaItem, basementColor, self.defaultStrategy);
            if (
                schemaItem.type === DATA_TYPE.BACKGROUND &&
                (value === 'none' || value === 'transparent') &&
                basementColor
            ) {
                // if background is absent (none) treat it equal to basementColor
                resolvedValue = basementColor;
            } else {
                resolvedValue = value;
            }
            self.intermediateCache[schemaItem.key] = sourceValue;
            self.resolvedValuesCache[schemaItem.key] = resolvedValue;
            result[schemaItem.key] = value;
        }

        for (let pass = 1; pass <= MAX_PASSES; pass++) {
            postponed = new Set();
            if (itemsToProceed.length === 0) break;
            for (const schemaItem of itemsToProceed) {
                processSchemaItem(schemaItem);
                delete copiedConfig[schemaItem.key];
                // deleteObjectProperty(copiedConfig, schemaItem.key, true);
            }
            itemsToProceed = [...postponed];
        }

        if (itemsToProceed.length > 0) {
            const items = itemsToProceed.map(itm => itm.key).join(', ');

            this.logger.warn(`Could not resolve fallback/dependency for: %c ${items}`, 'font-weight: bold');
        }

        if (showUndescribed) {
            let shown = false;
            for (const configKey in copiedConfig) {
                if (this.allowVariants && (configKey === 'ON_DARK' || configKey === 'ON_LIGHT')) continue;

                const fullPathKey = pathPrefix ? `${pathPrefix}.${configKey}` : configKey;
                shown = true;
                this.logger.warn(
                    `This config item is not described with SCHEMA: %c ${fullPathKey}`,
                    'font-weight: bold'
                );
            }

            if (IS_LOCAL && shown) {
                throw new Error('Aborted. Please fix issues described above');
            }
        }

        return result;
    }

    getParsedValue(config, schemaItem, resolvedValuesCache, intermediateCache, pathPrefix = '', errors = []) {
        const fullPath = pathPrefix ? `${pathPrefix}.${schemaItem.key}` : schemaItem.key;
        const value = config[schemaItem.key];

        if (schemaItem.required && (value === undefined || value === null)) {
            return {error: `Property "${fullPath}" is required.`};
        }

        let parsed;

        switch (schemaItem.type) {
            case DATA_TYPE.INT:
                parsed = parseInteger(value, schemaItem.required, schemaItem.def, fullPath);
                break;
            case DATA_TYPE.BOOL:
                parsed = parseBool(value, schemaItem.required, schemaItem.def, fullPath);
                break;
            case DATA_TYPE.NUMBER:
                parsed = parseNumber(value, schemaItem.required, schemaItem.def, fullPath);
                break;
            case DATA_TYPE.STRING:
            case DATA_TYPE.COLOR:
            case DATA_TYPE.BACKGROUND:
                parsed = parseString(value, schemaItem.required, schemaItem.def, fullPath);
                break;
            case DATA_TYPE.TEXT_STYLE:
                parsed = parseText(value, schemaItem.required, schemaItem.def, fullPath);
                break;
            case DATA_TYPE.MAP: {
                const localResolvedValuesCache = Object.create(resolvedValuesCache);
                const localIntermediateCache = Object.create(intermediateCache);
                parsed = {
                    value: this.validateMap(
                        value || {},
                        schemaItem.items,
                        localResolvedValuesCache,
                        localIntermediateCache,
                        fullPath,
                        errors
                    ),
                };
                // Following do the trick with prototypes, unlinking it from parent
                // but putting inside parent object as property
                // This trick invalidates javascript engine inline cache, that may significantly slow down performance
                // but since it last operation it is ok
                Object.setPrototypeOf(localResolvedValuesCache, null);
                Object.setPrototypeOf(localIntermediateCache, null);

                resolvedValuesCache[schemaItem.key] = localResolvedValuesCache;
                intermediateCache[schemaItem.key] = localIntermediateCache;
                break;
            }
            case DATA_TYPE.LIST:
                parsed = parseList(value, schemaItem.required, schemaItem.def, schemaItem.listItemsType, fullPath);
                break;
            case DATA_TYPE.TILE:
                throw new Error('Assertion: Should not fall here');
            case DATA_TYPE.ANY:
                parsed = {value};
                break;
            default:
                throw new Error(`Unknown data type: ${schemaItem.type}`);
        }

        if (schemaItem.possibleValues && !schemaItem.possibleValues.includes(parsed.value)) {
            return {
                error: `Value of "${fullPath}" property should be one of: ${schemaItem.possibleValues.join(
                    ' / '
                )}. Current value is "${parsed.value}".`,
            };
        }

        return parsed;
    }
}

function tryToGetValueAndCache(cache, pathItems) {
    if (cache[pathItems[0]] === undefined) {
        // dependency not yet resolved, so postpone it
        return;
    }
    if (pathItems.length === 1) {
        return cache[pathItems[0]];
    }

    const path = pathItems.join('.');
    if (path in cache) {
        // check cache first
        return cache[path];
    } else {
        const value = getObjectProperty(cache, path, undefined);
        cache[path] = value; // store cache
        return value;
    }
}
