import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import SelectionHeadersImpl from './selection-headers.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to display selection headers for markets.
 *
 * @class SelectionHeaders
 * @memberof Mojito.Modules.AggregatedMarketPane
 */

/**
 * SelectionHeaders prop types.
 *
 * @property {object} [market] - Related market object.
 * @property {object} eventItem - Event item object.
 * @property {object} [shouldShowDrawHeader = true] - Flag indicates if the header for draw result should be shown.
 *
 * @memberof Mojito.Modules.AggregatedMarketPane.SelectionHeaders
 */
const propTypes = {
    market: PropTypes.object,
    eventItem: PropTypes.object.isRequired,
    shouldShowDrawHeader: PropTypes.bool,
};

const defaultProps = {
    shouldShowDrawHeader: true,
};

export default UIView('SelectionHeaders', SelectionHeadersImpl, propTypes, defaultProps);
