import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';
import { createSelector } from '@reduxjs/toolkit';

const arraysAreEqual = MojitoCore.Base.objUtils.arraysAreEqual;
const reduxInstance = MojitoCore.Services.redux;

/**
 * Meetings store selectors.
 *
 * @class MeetingsSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.Meetings
 */

/**
 * Selects meetings state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.Meetings.MeetingsState} The state of the meetings store.
 * @memberof Mojito.Services.SportsContent.Meetings.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects all meetings.
 *
 * @function selectMeetings
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<object>} Meetings list.
 *
 * @memberof Mojito.Services.SportsContent.Meetings.selectors
 */
export const selectMeetings = state => selectState(state).meetings;

/**
 * Selects meetings by ids.
 *
 * @function selectMeetingsByIds
 *
 * @param {Array<string>} meetingsIds - The ids of wanted meetings.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<object>} List of meetings.
 *
 * @memberof Mojito.Services.SportsContent.Meetings.selectors
 */
export const selectMeetingsByIds = (meetingsIds, state) =>
    meetingsIds.map(id => selectMeetings(state)[id]).filter(Boolean);

/**
 * Selector creator function. Will create memoized selector based on {@link Mojito.Services.SportsContent.Meetings.selectors.selectMeetingsByIds|selectMeetingsByIds} to fetch meetings by ids.
 * The created selector guarantees that the same array instance will be returned on each call if no input params changed.
 * Note: when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance.
 *
 * @function makeSelectMeetingsByIds
 *
 * @returns {Function} Selector function accepts same arguments as {@link Mojito.Services.SportsContent.Meetings.selectors.selectMeetingsByIds|selectMeetingsByIds}.
 * @memberof Mojito.Services.SportsContent.Meetings.selectors
 */
export const makeSelectMeetingsByIds = () =>
    createSelector(
        [(meetingsIds, state) => selectMeetings(state), meetingsIds => meetingsIds],
        (meetings, meetingsIds) => meetingsIds.map(id => meetings[id]).filter(Boolean),
        {
            memoizeOptions: {
                resultEqualityCheck: arraysAreEqual,
            },
        }
    );

const getMeetingsState = state => selectState(state).meetingsState;

/**
 * Selects meetings state.
 *
 * @function selectMeetingsState
 *
 * @param {Array<string>} meetingIds - The ids of wanted meetings state.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Common.types.CONTENT_STATE>} Meetings state.
 *
 * @memberof Mojito.Services.SportsContent.Meetings.selectors
 */

export const selectMeetingsState = createSelector(
    [(meetingIds, state) => getMeetingsState(state), meetingIds => meetingIds],
    (meetingsState, meetingIds) => meetingIds?.map(id => meetingsState[id]),
    {
        memoizeOptions: {
            resultEqualityCheck: arraysAreEqual,
        },
    }
);
