import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';
const { FlexPane, SkeletonPart } = MojitoPresentation.Components;

const RaceCardsSkeleton = props => {
    const {
        mojitoTools: { config },
    } = props;

    return (
        <FlexPane config={config.container}>
            {range(config.numberOfCards).map(i => (
                <RaceCard key={i} config={config} />
            ))}
        </FlexPane>
    );
};

const RaceCard = ({ config }) => (
    <FlexPane config={config.raceCardContainer} class="ta-RaceCardsSkeleton">
        <RaceHeader config={config} />
        <Runners config={config} />
        <AllRunnersSelection config={config} />
    </FlexPane>
);

const RaceHeader = ({ config }) => (
    <FlexPane config={config.raceHeaderContainer}>
        <SkeletonPart config={config.raceTitleSkeleton} />
        <SkeletonPart config={config.raceNameLabelSkeleton} />
    </FlexPane>
);

const Runners = ({ config }) => {
    const numberOfRunners = 3;

    return (
        <FlexPane config={config.runnersContainer}>
            {range(numberOfRunners).map(i => (
                <FlexPane key={i} config={config.runnerContainer}>
                    <SkeletonPart config={config.silkSkeleton} />
                    <FlexPane config={config.runnerNameContainer}>
                        <SkeletonPart config={config.runnerHorseNameSkeleton} />
                        <SkeletonPart config={config.runnerJockeyNameSkeleton} />
                    </FlexPane>
                    <SkeletonPart config={config.selectionSkeleton} />
                </FlexPane>
            ))}
        </FlexPane>
    );
};

const AllRunnersSelection = ({ config }) => (
    <FlexPane config={config.showAllRunnersContainer}>
        <SkeletonPart config={config.showAllRunnerSkeleton} />
    </FlexPane>
);

export default RaceCardsSkeleton;
