import PropTypes from 'prop-types';
import ClockImpl from './clock.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View for displaying the current time in a specific format.
 *
 * @class Clock
 * @memberof Mojito.Presentation.Components
 */

/**
 * `Clock` component prop types.
 *
 * @property {number|string} [timeOffset=0] - UTC offset.
 * @property {string} [class] - A string containing class names assigned to the root element.
 *
 * @memberof Mojito.Presentation.Components.Clock
 */
const propTypes = {
    timeOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    class: PropTypes.string,
};

const defaultProps = {
    timeOffset: 0,
};

export default UIView('Clock', ClockImpl, propTypes, defaultProps);
