import BannerSkeletonsImpl from './banner-skeletons.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Component that works as an overlay on banner promotion to indicate that the banners are loading.
 * The component needs to be placed inside container with relative position to cover content properly.
 *
 * @function BannerSkeletons
 * @memberof Mojito.Modules.Promotions
 */

export default UIView('BannerSkeletons', BannerSkeletonsImpl);
