import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * VideoStreaming store selectors.
 *
 * @class VideoStreamingSelectors
 * @name selectors
 * @memberof Mojito.Services.VideoStreaming
 */

/**
 * Selects VideoStreaming state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} The state of the VideoStreaming store.
 * @memberof Mojito.Services.VideoStreaming.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select stream data for the currently requested stream.
 *
 * @function selectStreamData
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.VideoStreaming.types.VideoStreamingData} Stream data.
 *
 * @memberof Mojito.Services.VideoStreaming.selectors
 */
export const selectStreamData = state => selectState(state).streamData;

/**
 * Select current stream state.
 *
 * @function selectStreamState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.VideoStreaming.types.STREAM_STATE} Stream state.
 *
 * @memberof Mojito.Services.VideoStreaming.selectors
 */
export const selectStreamState = state => selectState(state).streamState;

/**
 * Subscribes to the changes of particular VideoStreaming state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Services.VideoStreaming.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
