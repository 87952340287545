export class AbstractSchemaValidator {
    /**
     * Validate and get config object by provided schema
     * @param root {object} root configuration object (typically its ApplicationConfig.config)
     * @param schema {object} schema by which config is validated and retrieved
     */
    // eslint-disable-next-line no-unused-vars
    buildConfigFromSchema(root, schema) {
        return root;
    }
}
