import PropTypes from 'prop-types';
import ParticipantsImpl from './participants.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * @class Participants
 * @memberof Mojito.Presentation.Components
 */

/**
 * Participants prop types.
 *
 * @property {object} event - The event item containing participants.
 * @property {boolean} [forceDisplayEventName = false] - Set to true to force display of event name rather than participants name.
 * @property {string} [drawLabel] - Will render draw label if added.
 *
 * @memberof Mojito.Presentation.Components.Participants
 */
const propTypes = {
    event: PropTypes.object.isRequired,
    forceDisplayEventName: PropTypes.bool,
    drawLabel: PropTypes.string,
};

const defaultProps = {
    forceDisplayEventName: false,
};

export default UIView('Participants', ParticipantsImpl, propTypes, defaultProps);
