import MojitoCore from 'mojito/core';
import MatchesCouponImpl from './matches-coupon.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * MatchesCoupon - render the content based on matches sport page for a specific filter.
 *
 * @class MatchesCoupon
 * @memberof Mojito.Application.Pages
 */
export default UIView('MatchesCoupon', MatchesCouponImpl);
