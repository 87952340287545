import { memo } from 'react';
import MojitoPresentation from 'mojito/presentation';
import BannerMarket from 'modules/promotions/banners/banner-market/index.jsx';

const { Image, AbsolutePane } = MojitoPresentation.Components;

// eslint-disable-next-line react/display-name
const BannerPromotion = memo(
    ({ promotion, onClick, shouldRequestData, mojitoTools: { config, style } }) => {
        const { event, imgUrl } = promotion;
        const marketId = event && event.market && event.market.marketId;
        const className = `ta-promotion-item`;

        return (
            <div style={style.wrapperContainer} className={className} onClick={onClick}>
                {imgUrl && (
                    <Image
                        config={config.image}
                        src={encodeURI(imgUrl)}
                        class={`ta-img-${imgUrl.replace(/[^A-Za-z0-9-_]/g, '')}`}
                    />
                )}
                {marketId && (
                    <AbsolutePane config={config.marketOverlay}>
                        <BannerMarket
                            config={config.bannerMarket}
                            event={event}
                            marketId={marketId}
                            shouldRequestData={shouldRequestData}
                        />
                    </AbsolutePane>
                )}
            </div>
        );
    }
);

BannerPromotion.getStyle = () => {
    return {
        wrapperContainer: {
            display: 'flex',
            flex: '1 1 auto',
            position: 'relative',
        },
    };
};

export default BannerPromotion;
