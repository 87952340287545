import AbstractSystemSettingsStorage from './abstract-system-settings-storage';
import NamedStorageService from 'core/services/storage/named-storage-service.js';
import UrlTypes from 'core/base/url-utils/url-types.js';
import { noop } from 'mojito/utils';

const LANGUAGE = 'language';
const URL_LANGUAGE = 'urlLanguage';
const TA_MODE = 'taMode';
const SYSTEM_SETTINGS = 'systemSettings';
const ADDITIONAL_CONTEXT = 'additionalContext';
const { PARAMETER } = UrlTypes;

const NULL_STORAGE = {
    getItem: noop,
    setItem: noop,
    removeItem: noop,
};

/**
 * SystemSettingsStorage constructor.
 *
 * @class SystemSettingsStorage
 *
 * @param {Mojito.Core.Services.Storage.NamedStorageService} [storageService] - Instance of named storage service.
 * Will be used to store system settings in local or session storage depending on <code>storageService</code> implementation specifics. If not provided then
 * system settings will be stored in local storage using key name `systemSettings`.
 *
 * @classdesc Class offering the possibility to fetch and persist system settings.
 *
 * @extends Mojito.Core.Services.SystemSettings.AbstractSystemSettingsStorage
 * @memberof Mojito.Core.Services.SystemSettings.Storage
 */
export default class SystemSettingsStorage extends AbstractSystemSettingsStorage {
    constructor(storageService) {
        super();
        this.storageService = storageService || new NamedStorageService(SYSTEM_SETTINGS);
        this.urlLanguageStorage = NULL_STORAGE;
        this.taModeStorage = NULL_STORAGE;
    }

    /**
     * Accepts configuration object.
     *
     * @param {object} config - Configuration object.
     * @param {boolean} [config.enableUrlLanguage] - The 'true' value tells the system to store the LANG param from URL in session storage for future usage in case URL path is reset.
     * @param {boolean} [config.allowTAMode] - The 'true' value tells the system to store the value of TAMode in session storage.
     *
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#configure
     */
    configure(config = {}) {
        const { enableUrlLanguage = true, allowTAMode = false } = config;
        this.enableUrlLanguage = enableUrlLanguage;
        this.allowTAMode = allowTAMode;
        if (enableUrlLanguage) {
            this.urlLanguageStorage = new NamedStorageService(URL_LANGUAGE, window.sessionStorage);
            this.storeLanguageInSessionStorage(this.getLanguageFromUrl());
        }
        if (allowTAMode) {
            this.taModeStorage = new NamedStorageService(TA_MODE, window.sessionStorage);
            this.storeTAModeInSessionStorage(this.getTAModeFromUrl());
        }
    }

    /**
     * Set system language to local storage. If <code>enableUrlLanguage</code> config property is set to true
     * and URL contains {@link Mojito.Core.Base.UrlTypes.PARAMETER|LANG} parameter, then it will be removed as long as language
     * is explicitly getting reset, typically by user interaction.
     *
     * @param {string} language - Language code. Typically ISO 639-1 two letter 'xx' code or BCP 47 'xx-YY'.
     *
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#setLanguage
     */
    setLanguage(language) {
        // Once user manually reset language, we will remove deep linking LANG parameter as it is not respected any more.
        // We need to remove it from both URL and session storage if exists.
        if (this.enableUrlLanguage) {
            this.removeLanguageFromUrl();
            this.removeLanguageFromSessionStorage();
        }
        this.saveData({ [LANGUAGE]: language });
    }

    /**
     * Get system language from local storage. If <code>enableUrlLanguage</code> config property is set to true
     * then will search for {@link Mojito.Core.Base.UrlTypes.PARAMETER|LANG} URL parameter and return its value which takes precedence
     * over language from local storage.
     *
     * @returns {string} Language code.
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#getLanguage
     */
    getLanguage() {
        const storedUrlLanguage = this.urlLanguageStorage.getItem();
        return this.getLanguageFromUrl() || storedUrlLanguage || this.getProperty(LANGUAGE);
    }

    /**
     * Gets the TAMode state from url or session storage.
     *
     * @returns {boolean} TAMode state.
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#isTAMode
     */
    isTAMode() {
        return this.getTAModeFromUrl() === 'true' || this.taModeStorage.getItem() === 'true';
    }

    /**
     * Reset system settings storage.
     *
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#reset
     */
    reset() {
        this.storageService.removeItem();
    }

    setAdditionalContext(value) {
        this.saveData({ [ADDITIONAL_CONTEXT]: value });
    }

    getAdditionalContext() {
        return this.getAdditionalContextFromUrl() || undefined;
    }

    saveData(data) {
        const existingData = this.storageService.getItem() || {};
        this.storageService.setItem({ ...existingData, ...data });
    }

    getProperty(key) {
        const existingData = this.storageService.getItem() || {};
        return existingData[key];
    }

    removeProperty(key) {
        this.storageService.removeItemProperty(key);
    }

    getAdditionalContextFromUrl() {
        return this.getUrl().searchParams.get(PARAMETER.ADDITIONAL_CONTEXT);
    }

    getLanguageFromUrl() {
        if (!this.enableUrlLanguage) {
            return undefined;
        }
        return this.getUrl().searchParams.get(PARAMETER.LANG);
    }

    getTAModeFromUrl() {
        if (!this.allowTAMode) {
            return undefined;
        }
        return this.getUrl().searchParams.get(PARAMETER.TA_MODE);
    }

    getUrl() {
        return new URL(document.location.href);
    }

    /**
     * If language URL deep linking is enabled and there is {@link Mojito.Core.Base.UrlTypes.PARAMETER|LANG} parameter
     * in URL, then we will store it in session storage to make it persistent for user session on the tab.
     * We do that due to the fact that user can navigate around which will lead to path change hence losing the LANG parameter.
     * If that happens having language stored in session storage gives us possibility to read from it on page reload and guarantee
     * that language provided through deep linking is still respected until user closes the tab.
     *
     * @param {string} language - Language code.
     * @private
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#storeLanguageInSessionStorage
     */
    storeLanguageInSessionStorage(language) {
        if (language) {
            this.urlLanguageStorage.setItem(language);
        }
    }

    storeTAModeInSessionStorage(taMode) {
        if (taMode) {
            this.taModeStorage.setItem(taMode);
        }
    }

    /**
     * Removes LANG parameter from URL and replaces history the state with new URL.
     *
     * @private
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#removeLanguageFromUrl
     */
    removeLanguageFromUrl() {
        const url = this.getUrl();
        const { searchParams } = url;
        if (searchParams.has(PARAMETER.LANG)) {
            searchParams.delete(PARAMETER.LANG);
            const { state, title } = window.history;
            window.history.replaceState(state, title, url);
        }
    }

    /**
     * Removes previously stored urlLanguage property from session storage.
     *
     * @private
     * @function Mojito.Core.Services.SystemSettings.Storage.SystemSettingsStorage#removeLanguageFromSessionStorage
     */
    removeLanguageFromSessionStorage() {
        this.urlLanguageStorage.removeItem();
    }
}
