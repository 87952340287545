import PrebuiltBet from './prebuilt-bet.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

/**
 * Prebuilt bet.
 *
 * @class PrebuiltBet
 * @memberof Mojito.Modules
 */

/**
 * Prebuilt bet prop types.
 *
 * @property {string} [eventId] - Event id.
 * @property {string} [marketId] - Market id.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from content. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 *
 * @memberof Mojito.Modules.PrebuiltBets.PrebuiltBet
 */
const propTypes = {
    eventId: PropTypes.string,
    marketId: PropTypes.string,
    shouldRequestData: PropTypes.bool,
};

const defaultProps = {
    shouldRequestData: true,
};

export default MojitoCore.Presentation.UIControllerView(
    'PrebuiltBet',
    PrebuiltBet,
    propTypes,
    defaultProps
);
