/**
 * Class defining generic meta information data descriptors.
 *
 * @class MetaInformationDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.MetaInformation
 */
export default class MetaInformationDataDescriptor {
    /**
     * Factory method for creating a generic Meta information descriptor.
     *
     * @param {string} metaInformationId - Id of meta information.
     *
     * @returns {{dataType: string}} Generic meta information descriptor.
     *
     * @function Mojito.Services.MetaInformation.descriptor.createMetaInformationDescriptor
     */
    static createMetaInformationDescriptor(metaInformationId) {
        return {
            dataType: MetaInformationDataDescriptor.DATA_TYPES.META_INFORMATION,
            metaInformationId,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} META_INFORMATION - Meta Information data descriptor type.
 * @memberof Mojito.Services.MetaInformation.descriptor
 */
MetaInformationDataDescriptor.DATA_TYPES = {
    META_INFORMATION: 'meta-information',
};
