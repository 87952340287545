import ObjUtils from 'core/base/objutils';
import IdGenerator from 'core/base/idgenerator';
import { isEqual } from 'mojito/utils';

/**
 * CacheKeyGenerator offers key generation for cached data sets.
 * The key is generated based on the provided deps list.
 *
 * @class CacheKeyGenerator
 * @memberof Mojito.Core.Base
 */
export default class CacheKeyGenerator {
    constructor() {
        this.cacheRefs = [];
    }

    /**
     * Will generate unique number based on provided path and dependencies.
     * For each subsequent call with the same path and deps the function will return same result analogically to how React.useMemo works.
     *
     * @param {string} path - Cache path.
     * @param {Array} deps - List of dependencies.
     * @param {boolean} enableDeepEqual - True if items in deps should be compared with deep equal object comparison, otherwise it will use shallow compare each item in deps list by reference.
     *
     * @returns {number} Cache key which can be used for indexing in cached repos for provided input qualifiers.
     *
     * @function Mojito.Core.Base.CacheKeyGenerator#generate
     */
    generate(path, deps, enableDeepEqual = false) {
        const itemCacheDomain = this.cacheRefs[path] || [];
        for (let i = 0; i < itemCacheDomain.length; i++) {
            const cacheRef = itemCacheDomain[i];
            const isRefApplicable =
                ObjUtils.arraysAreEqual(cacheRef.deps, deps) ||
                (enableDeepEqual && this.arraysAreDeepEqual(cacheRef.deps, deps));
            if (isRefApplicable) {
                return cacheRef.key;
            }
        }
        const key = IdGenerator.generateId();
        itemCacheDomain.push({ key, deps });
        this.cacheRefs[path] = itemCacheDomain;
        return key;
    }

    arraysAreDeepEqual(arr1, arr2) {
        for (let id = 0; id < arr1.length; id++) {
            if (!isEqual(arr1[id], arr2[id])) {
                return false;
            }
        }
        return true;
    }
}
