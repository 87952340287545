import { useState, useEffect } from 'react';

/**
 * Component used to render children asynchronously and with a possible delay.
 *
 * @example <caption>Delayed rendering<br><br>Can be used to delay rendering certain large components in order to free up execution time for more time-critical tasks. A parent component may render a handful of children, but child X is not as critical as the others. By wrapping child X in PostponedRenderer, the initialization and rendering of X will be part of a new task. This task will not execute until after the current ask is finished, letting the other children initialize and render first.</caption>
 *  render() {
 *      return (
 *          <>
 *              <PostponedRenderer delay={500}>
 *                  <Child/>
 *              </PostponedRenderer>
 *              <CriticalChild/>
 *          </>
 *      );
 *  }
 *
 *
 * @example <caption>Speeding up side-effects<br><br>If a component needs to speed up execution of functions with side-effects, wrapping all children in PostponedRenderer will execute that side-effect before rendering the children, given that the side-effect can be executed at the time. If, given the code below, <code>doSomething</code> needs to be executed asynchronously but before <code>Child</code> is rendered, this is how it can be used.</caption>
 *  class ExampleView extends UIViewImplementation
 *      constructor() {
 *          setTimeout(() => {
 *              doSomething()
 *          })
 *      }
 *
 *      render() {
 *          return (
 *              <PostponedRenderer>
 *                  <Child/>
 *              </PostponedRenderer>
 *          );
 *      }
 *  }
 *
 * @function PostponedRenderer
 * @memberof Mojito.Presentation.Components.PostponedRenderer
 *
 * @param {*} props - Represents the properties passed to the component.
 * @param {number} props.delay - Determines the amount of delay, in milliseconds, before rendering the children. The default value is 0.
 * @param {node} props.children - Specifies the children elements to be rendered after the delay.
 * @returns {React.ReactElement} Returns a React element prepared for rendering.
 */
const PostponedRenderer = props => {
    const delay = props.delay || 0;
    const [readyToRender, setReadyToRender] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setReadyToRender(true), delay);
        return () => clearTimeout(timeout);
    }, [delay]);

    if (!readyToRender) {
        return null;
    }

    return props.children;
};

export default PostponedRenderer;
