import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import AuthenticationTypes from 'services/authentication/types.js';
import { noop, pick } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import serviceFactory from 'services/authentication/service/service-factory';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import { CredentialsBuilder } from './credentials';

const { STATES, PASSWORD_CHANGE_STATE, LOGIN_ERRORS, LOGIN_TIME_STORAGE_KEY, LOGOUT_REASON } =
    AuthenticationTypes;
const reduxInstance = MojitoCore.Services.redux;
const NamedStorageService = MojitoCore.Services.Storage.NamedService;
const TransactionsTypes = MojitoCore.Services.Transactions.types;
const { ERROR_CODE } = TransactionsTypes;

const log = MojitoCore.logger.get('AuthenticationSlice');
export const loginTimeStorage = new NamedStorageService(LOGIN_TIME_STORAGE_KEY);

/**
 * The name of the authentication store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Authentication
 */
export const STORE_KEY = 'authenticationStore';

export const INITIAL_STATE = {
    sessionInfo: {},
    errorInfo: undefined,
    loginState: STATES.UNKNOWN,
    passwordChangeState: PASSWORD_CHANGE_STATE.IDLE,
    logoutReason: undefined,
    loginTime: undefined,
};

export const { reducer, actions } = createSlice({
    name: 'authentication',
    initialState: INITIAL_STATE,
    reducers: {
        loginPending(state) {
            state.errorInfo = undefined;
            state.loginState = STATES.LOGGING_IN;
        },
        loginSuccess(state, { payload: authInfo }) {
            // On login by SSO the authInfo might be not provided by action dispatcher, this is why check is needed.
            const { sessionInfo } = authInfo || {};
            state.sessionInfo = sessionInfo;
            state.loginTime = resolveLoginTime(sessionInfo?.creationTime);
            state.logoutReason = undefined;
            state.loginState = STATES.LOGGED_IN;
        },
        loginFailed(state, { payload: errorInfo }) {
            resetState(state);
            state.errorInfo = errorInfo;
        },
        disposeSession(state, { payload: reason }) {
            resetState(state);
            state.logoutReason = reason;
        },
        logoutSuccess: noop,
        logoutFailed: noop,
        loginMessage: noop,
        unexpectedSessionLost: noop,
        changePasswordPending(state) {
            state.passwordChangeState = PASSWORD_CHANGE_STATE.PENDING;
        },
        changePasswordSuccess(state) {
            state.passwordChangeState = PASSWORD_CHANGE_STATE.CHANGED;
        },
        changePasswordFailed(state) {
            state.passwordChangeState = PASSWORD_CHANGE_STATE.FAILED;
        },
        changePasswordReset(state) {
            state.passwordChangeState = PASSWORD_CHANGE_STATE.IDLE;
        },
        termsAndConditionsPending(state) {
            const { errorInfo } = state;
            if (errorInfo?.type === LOGIN_ERRORS.TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED) {
                state.errorInfo = undefined;
            }
        },
        reset() {
            return INITIAL_STATE;
        },
    },
});

/**
 * Authentication actions.
 *
 * @class AuthenticationActions
 * @name actions
 * @memberof  Mojito.Services.Authentication
 */

/**
 * Init authentication service layer.
 *
 * @function init
 *
 * @param {Mojito.Services.Authentication.types.AuthenticationConfig} config - Authentication config.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk. Dispatches restoreSession action once init is done.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.init = config => {
    return dispatch => {
        serviceFactory.init(config).then(() => dispatch(actions.restoreSession()));
    };
};

/**
 * Login action.
 *
 * @function login
 *
 * @param {{username: string, password: string, loginMethod: Mojito.Services.Authentication.types.CREDENTIALS_PUBLIC_TYPE}} payload - Login payload object.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Login thunk. Dispatches loginSuccess or loginFailed actions once login process is finalised.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.login = payload => {
    return dispatch => {
        const { userName, password, loginMethod } = payload;
        dispatch(actions.loginPending());
        const credentials = new CredentialsBuilder()
            .withPublicFactor(userName.trim())
            .withPublicType(loginMethod)
            .withPrivateFactor(password)
            .build();
        serviceFactory.getService().login(
            credentials,
            authInfo => {
                dispatch(actions.loginSuccess(authInfo));
            },
            errorInfo => {
                dispatch(actions.loginFailed(errorInfo));
            }
        );
    };
};

/**
 * Logout action.
 *
 * @function logout
 *
 * @param {Mojito.Services.Authentication.types.LOGOUT_REASON} logoutReason - User logout reason.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Logout thunk. Dispatches logoutSuccess or logoutFailed actions once logout process is finalised.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.logout = logoutReason => {
    return (dispatch, getState) => {
        // Important to selectLoginState before disposeSession action because it will reset the state.
        const loginState = getState()[STORE_KEY].loginState;
        dispatch(actions.disposeSession(logoutReason));
        if (loginState !== STATES.LOGGED_OUT) {
            serviceFactory.getService().logout(
                () => {
                    dispatch(actions.logoutSuccess(logoutReason));
                },
                () => {
                    dispatch(actions.logoutFailed());
                }
            );
        }
    };
};

/**
 * Validate session action.
 *
 * @function validateSession
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Validate session thunk. Dispatches logout if session validation failed.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.validateSession = () => {
    return dispatch => {
        serviceFactory.getService().validateSession(noop, () => {
            dispatch(actions.disposeSession(LOGOUT_REASON.SESSION_INVALID));
        });
    };
};

/**
 * Restore session action.
 *
 * @function restoreSession
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Restore session thunk.
 * Dispatches loginSuccess if session was restored successfully otherwise will trigger logout.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.restoreSession = () => {
    return dispatch => {
        // Allow the auth service do its initial check for SSO log in
        // and initiate login call towards betting platform if login check was successful.
        serviceFactory.getService().restoreSession(
            authInfo => {
                dispatch(actions.loginSuccess(authInfo));
            },
            response => {
                const logoutReason =
                    response?.status === ERROR_CODE.INVALID_SESSION
                        ? LOGOUT_REASON.SESSION_INVALID
                        : undefined;
                dispatch(actions.logout(logoutReason));
            }
        );
    };
};

/**
 * Triggers the password change process.
 *
 * @param {{currentPassword: string, newPassword: string, privateCredentialType: Mojito.Services.Authentication.types.CREDENTIALS_PRIVATE_TYPE}} payload - Change password payload.
 *
 * @function changePassword
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Restore session thunk.
 * Dispatches changePasswordSuccess or changePasswordFailed on process finalise.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.changePassword = payload => {
    return (dispatch, getState) => {
        const isPending =
            getState()[STORE_KEY].passwordChangeState === PASSWORD_CHANGE_STATE.PENDING;
        if (isPending) {
            return;
        }
        dispatch(actions.changePasswordPending());
        const type = payload?.privateCredentialType;
        const passwordUpdate = { ...pick(payload, 'currentPassword', 'newPassword'), type };
        serviceFactory.getService().changePassword(
            passwordUpdate,
            () => dispatch(actions.changePasswordSuccess()),
            () => dispatch(actions.changePasswordFailed())
        );
    };
};

/**
 * Accept terms and conditions action.
 *
 * @function acceptTermsAndConditions
 *
 * @param {Mojito.Services.Authentication.AcceptTermsAndConditionsInfo} termsAndConditionsInfo - T&C info associated with a TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED "error".
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Accept terms and conditions thunk.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.acceptTermsAndConditions = termsAndConditionsInfo => {
    return dispatch => {
        serviceFactory.getService().acceptTermsAndConditions(
            termsAndConditionsInfo,
            () => {
                // When T&C has been accepted retry to login.
                serviceFactory
                    .getService()
                    .restoreSession(
                        termsAndConditionsInfo.cbResolve,
                        termsAndConditionsInfo.cbReject
                    );
            },
            noop
        );
        dispatch(actions.termsAndConditionsPending());
    };
};

/**
 * Decline terms and conditions action.
 *
 * @function declineTermsAndConditions
 *
 * @param {Mojito.Services.Authentication.AcceptTermsAndConditionsInfo} termsAndConditionsInfo - T&C info associated with a TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED "error".
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Decline terms and conditions thunk.
 * @memberof Mojito.Services.Authentication.actions
 */
actions.declineTermsAndConditions = termsAndConditionsInfo => {
    return dispatch => {
        serviceFactory
            .getService()
            .declineTermsAndConditions(
                termsAndConditionsInfo,
                termsAndConditionsInfo.cbReject,
                termsAndConditionsInfo.cbReject
            );
        dispatch(actions.termsAndConditionsPending());
    };
};

// Ensure login time is side effect this is why we are handling it in listener.
reduxInstance.actionListener.startListening({
    actionCreator: actions.loginSuccess,
    // This effect will be executed once loginSuccess action fired right after reducer handles it.
    effect: (action, listenerApi) => {
        const loginTime = listenerApi.getState()[STORE_KEY].loginTime;
        loginTimeStorage.setItem(loginTime);
    },
});

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(actions.loginFailed, actions.disposeSession),
    effect: () => loginTimeStorage.removeItem(),
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.unexpectedSessionLost,
    effect: (action, listenerApi) => {
        log.error('user session between mojito client and betting platform has been lost.');
        serviceFactory.getService().validateSession(noop, () => {
            const { dispatch } = listenerApi;
            dispatch(actions.disposeSession(LOGOUT_REASON.UNEXPECTED_SESSION_LOST));
        });
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: bootstrapActions.dispose,
    // IMS auth service implements this
    effect: () => serviceFactory.getService().terminate(),
});

const resetState = state => {
    state.sessionInfo = {};
    state.passwordChangeState = PASSWORD_CHANGE_STATE.IDLE;
    state.errorInfo = undefined;
    state.loginState = STATES.LOGGED_OUT;
    state.loginTime = undefined;
};

const resolveLoginTime = timeStr => {
    if (timeStr) {
        return new Date(timeStr).toISOString();
    }
    return loginTimeStorage.getItem() || new Date().toISOString();
};

/**
 * Login pending action.
 *
 * @function loginPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Login success action.
 *
 * @function loginSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Authentication.types.AuthInfo} [authInfo = {}] - Object defines user authentication information.
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Login failed action.
 *
 * @function loginFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Core.Services.Transactions.types.Error} errorInfo - Object defines error details.
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * User session dispose action.
 *
 * @function disposeSession
 * @param {Mojito.Services.Authentication.types.LOGOUT_REASON} [reason] - Reason for which the session is getting disposed.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Logout success action.
 *
 * @function logoutSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Logout failed action.
 *
 * @function logoutFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Change password pending action.
 *
 * @function changePasswordPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Change password success action.
 *
 * @function changePasswordSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Change password failed action.
 *
 * @function changePasswordFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Change password reset action.
 *
 * @function changePasswordReset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Terms and conditions pending action.
 *
 * @function termsAndConditionsPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Authentication.actions
 */

/**
 * Unexpected session lost action.
 *
 * @function unexpectedSessionLost
 *
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Authentication.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
