import GenericBetFundsInfo from 'presentation/components/bet-funds/generic-info/index.jsx';
import CurrencyHelper from 'presentation/formatters/currency/index.js';

const FreebetStakeInfo = ({ value, currencyCode, showFreeBetIcon, mojitoTools }) => {
    const { stringResolver, config } = mojitoTools;
    return (
        <GenericBetFundsInfo
            showIcon={showFreeBetIcon}
            class="ta-freebetStake"
            config={config.betInfo}
            label={stringResolver.resolveString('$BET_FUNDS.FREEBET_STAKE')}
            valueClass="ta-FreebetStakeValue"
            value={
                isNaN(value)
                    ? stringResolver.resolveString(config.notAvailableValuel10nKey)
                    : CurrencyHelper.formatCurrency(value, currencyCode)
            }
        />
    );
};

export default FreebetStakeInfo;
