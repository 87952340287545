import MojitoCore from 'mojito/core';
import LoadingViewImpl from './loading-view.jsx';
import PropTypes from 'prop-types';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Displays loading view. Typically, applies for code split while waiting for specific JavaScript chunk to be loaded into browser.
 *
 * @function LoadingView
 * @memberof Mojito.Presentation.Components
 */

/**
 * LoadingView prop types.
 *
 * @property {string} [name] - This name will be used as a key to determine the config override among config.extensionConfigs.
 * Typically, it will be set as a name of component that has been split with {@link https://reactjs.org/docs/code-splitting.html#reactlazy|React.lazy} and is currently loading.
 *
 * @memberof Mojito.Presentation.Components.LoadingView
 */
const propTypes = {
    name: PropTypes.string,
};

export default UIView('LoadingView', LoadingViewImpl, propTypes);
