import {getCookie} from '#core/utils/cookie-utils.js';
import {absoluteUrlToInternalResource} from '#core/utils/url-utils.js';

export const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export const arrayLengthAnnex = (arr, length, value = null) => {
    if (length <= arr.length) return arr;
    return arr.concat(Array.from(Array(length - arr.length)).map(() => value));
};

export const getCutTimezone = tz => {
    let l1 = tz[0];
    tz = tz.substring(1);
    let l2 = parseInt(tz.split(':')[0]);
    let l3 = parseInt(tz.split(':')[1]);
    return l1.concat(l2).concat(l3 > 0 ? ':' + l3 : '');
};

export const formatString = (source, ...args) => {
    args.forEach((arg, i) => {
        source = source.replace(new RegExp('\\{' + i + '\\}', 'g'), arg);
    });
    return source;
};

// from https://stackoverflow.com/a/1527820
export const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const loadScript = (src, async = true, attributes) => {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        document.head.appendChild(script);

        script.onload = function onload(event) {
            resolve(script, event);
        };
        script.onerror = reject;
        script.type = 'text/javascript';
        script.async = !!async;

        if (attributes) {
            Object.entries(attributes).forEach(([key, value]) => {
                if (Object.prototype.hasOwnProperty.call(attributes, key)) {
                    script.setAttribute(key, value);
                }
            });
        }
        script.src = src;
    });
};

export const loadStyle = (href, DOMNode) => {
    const styleEl = document.createElement('link');
    styleEl.href = href;
    styleEl.rel = 'stylesheet';
    styleEl.type = 'text/css';
    DOMNode ? DOMNode.appendChild(styleEl) : document.body.appendChild(styleEl);
    return styleEl;
};

/**
 * Doing same as fetch, but override url in case if sportsbook is embedded
 * @param url {string}
 * @param options {object}
 * @return {Promise<Response>}
 */
export const fetchInternalResource = (url, options) => {
    const fullUrl = absoluteUrlToInternalResource(url);
    return fetch(fullUrl, options);
};

export const convertToCSSRules = obj => {
    const rules = [];
    for (let prop in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
            continue;
        }
        const selector = prop.replace(/[A-Z]/g, x => `-${x.toLowerCase()}`); // convert camelCase to kebab-case
        rules.push(`${selector}: ${obj[prop]};`);
    }
    return rules.join('');
};

export const buildWindowFeatures = ({windowFeatures, windowWidth}) => {
    if (windowFeatures) {
        return windowFeatures + ', noopener, noreferrer';
    }

    if (windowWidth) {
        // Original implementation was took at https://stackoverflow.com/a/16861050
        const screenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX; // get left indent, including case when user has multiple monitors

        const currentWindowWidth = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
              ? document.documentElement.clientWidth
              : screen.width;
        const currentWindowHeight = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
              ? document.documentElement.clientHeight
              : screen.height;

        const systemZoom = currentWindowWidth / window.screen.availWidth;
        const left = (currentWindowWidth - windowWidth) / 2 / systemZoom + screenLeft; // left indent to center new window above original

        return `resizable, scrollbars, status, noopener, noreferrer, width=${windowWidth}, height=${currentWindowHeight}, top=0, left=${left}`;
    }
    return 'noopener, noreferrer';
};

export const createTagWithAttributes = (tagName, attrs = {}) => {
    const el = document.createElement(tagName);
    for (let key in attrs) {
        el.setAttribute(key, attrs[key]);
    }
    return el;
};

//Checks whether Native app user accepted to add tracking features in our application(see DBX-8191)
export const isAppTrackingConsentAccepted = () => {
    return getCookie('dbx.cookie-consent-given') === 'true';
};

export const isJsonContentType = response => {
    const value = response.headers.get('content-type');
    return !!value && value.includes('application/json');
};

export function debounce(func, timeout = 300) {
    let timer;

    return function (...args) {
        clearTimeout(timer);

        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

// lodash replacements

export function noop() {
    // no operation function
}

export function uniq(array) {
    return [...new Set(array)];
}

export function bindAll(obj, ...methods) {
    methods.forEach(method => {
        obj[method] = obj[method].bind(obj);
    });
}
export function mapValues(obj, iteratee) {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        newObj[key] = iteratee(obj[key]);
    });
    return newObj;
}

export function isString(str) {
    return str !== null && typeof str === 'string';
}

export function isEmpty(val) {
    const isMapOrSet = val instanceof Map || val instanceof Set;
    // check if empty Map or Set
    if (isMapOrSet) {
        return val.size < 1;
    }

    const isObject = val instanceof Object;
    return isObject && !Object.entries(val).length;
}

// Extension of MojitoCore.Base.StringUtils.formatString (DBX-12134)
// When e.g multiple %1 dynamic values are used in translations currently Mojito only converts the first one
// This override will convert all of them, replace -> replaceAll
export function formatTranslationString(str, ...args) {
    // Sanity check
    if (!str) {
        return;
    }

    const isPlainObject = value => value?.constructor === Object;

    let res = str;
    if (args.length === 1 && isPlainObject(args[0])) {
        const argument = args[0];
        Object.keys(argument).forEach(key => {
            res = res.replace(`%${key}%`, argument[key] !== undefined ? argument[key] : '');
        });
    } else {
        for (let idx = 0; idx < args.length; idx++) {
            // All replacement args start at 1
            // ie: res.replace('%1', args[0])
            // note: the '' empty string fallback is required to prevent the replacement with 'undefined'.
            res = res.replaceAll(`%${idx + 1}`, args[idx] !== undefined ? args[idx] : '');
        }
    }
    return res;
}

let uid_counter = 0;
export function generateUniqueId() {
    const timestamp = Date.now().toString(36); // Convert current time to base-36 string
    const randomNum = Math.random().toString(36).substring(2, 10); // Generate a random base-36 string
    const sequenceNum = (uid_counter++).toString(36); // Sequence counter, to avoid collisions
    const uniqueId = timestamp + randomNum + sequenceNum; // Combine parts
    return uniqueId;
}
