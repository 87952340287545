import PropTypes from 'prop-types';
import MatchEventCardImpl from './match-event-card.jsx';
import MojitoCore from 'mojito/core';

/**
 * Match event card.
 *
 * @function MatchEventCard
 * @name view
 *
 * @memberof Mojito.Modules.EventCards.Match
 */

/**
 * Match event card prop types.
 *
 * @property {string} eventId - Id of event to show.
 * @property {string} [marketId] - Id of market to show if not a game line card.
 * @property {string} [marketName] - Name of the market to show if not a game line card.
 * @property {Mojito.Services.EventGroups.types.MarketOption} [gameLine] - Markets game line. Typically, present for US sports. Used to resolve market ids inside marketLines.
 * @property {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} [marketLines] - The map of market lines. Key is line type and value is actual market line.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from content. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 * @property {boolean} [showSkeleton = true] - True if data loading skeleton should be visible. It can be useful to hide skeleton if parent component already covers component with its of top level skeleton.
 *
 * @memberof Mojito.Modules.EventCards.Match.view
 */
const propTypes = {
    eventId: PropTypes.string.isRequired,
    marketId: PropTypes.string,
    marketName: PropTypes.string,
    gameLine: PropTypes.object,
    marketLines: PropTypes.object,
    shouldRequestData: PropTypes.bool,
    showSkeleton: PropTypes.bool,
};

const defaultProps = {
    shouldRequestData: true,
    showSkeleton: true,
};

export default MojitoCore.Presentation.UIControllerView(
    'MatchEventCard',
    MatchEventCardImpl,
    propTypes,
    defaultProps
);
