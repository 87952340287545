/**
 * Class defining event data descriptors to be used in
 * {@link Mojito.Services.SubscribeActions|SubscribeActions}.
 *
 * @see {@link Mojito.Services.SubscribeActions|SubscribeActions}
 *
 * @class EventsDataDescriptors
 * @name descriptors
 * @memberof Mojito.Services.SportsContent.Events
 */
export default class EventsDataDescriptors {
    /**
     * Factory method for creating an event data descriptor.
     *
     * @param {string} eventId - The event id.
     *
     * @returns {{dataType: string, eventId: *}} Event data descriptor.
     *
     * @function Mojito.Services.SportsContent.Events.descriptors#createEventDescriptor
     * @static
     */
    static createEventDescriptor(eventId) {
        return EventsDataDescriptors.createEventsDescriptor([eventId]);
    }

    /**
     * Factory method for creating multi event data descriptor.
     *
     * @param {Array<string>} eventIds - The list of event ids.
     *
     * @returns {{dataType: string, eventIds: Array<string>}} Events data descriptor.
     *
     * @function Mojito.Services.SportsContent.Events.descriptor#createEventsDescriptor
     * @static
     */
    static createEventsDescriptor(eventIds) {
        return {
            dataType: EventsDataDescriptors.DATA_TYPES.EVENT,
            eventIds,
        };
    }

    /**
     * Factory method for creating a event market data descriptor.
     *
     * @param {string} marketId - The market id.
     * @param {string} eventId - The event id.
     *
     * @returns {{dataType: string, marketId: *, eventId: *}} Event market data descriptor.
     *
     * @function Mojito.Services.SportsContent.Events.descriptor#createEventMarketDescriptor
     * @static
     */
    static createEventMarketDescriptor(marketId, eventId) {
        return EventsDataDescriptors.createEventMarketsDescriptor([marketId], eventId);
    }

    /**
     * Factory method for creating multi market data descriptor.
     *
     * @param {Array<string>} marketIds - The list of market ids.
     * @param {string} eventId - The event id.
     *
     * @returns {{dataType: string, marketIds: Array<string>, eventId: string}} Event market data descriptor.
     *
     * @function Mojito.Services.SportsContent.Events.descriptors#createEventMarketDescriptor
     * @static
     */
    static createEventMarketsDescriptor(marketIds, eventId) {
        return {
            dataType: EventsDataDescriptors.DATA_TYPES.EVENT_MARKET,
            marketIds,
            eventId,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} EVENT - Event data descriptor type.
 * @property {string} EVENT_MARKET - Event market data descriptor type.
 *
 * @memberof Mojito.Services.SportsContent.Events.descriptors
 */
EventsDataDescriptors.DATA_TYPES = {
    EVENT: 'event',
    EVENT_CHUNK: 'event-chunk',
    EVENT_MARKET: 'event-market',
    EVENT_MARKET_CHUNK: 'event-market-chunk',
};
