import PropTypes from 'prop-types';
import EventListImpl from './event-list.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * EventList is a view responsible for displaying events in a list format.
 * The information it displays pertains to in-play details such as scores,
 * times, and preferred markets.
 * It showcases its responsiveness by conforming its layout to the available width.
 *
 * @function EventList
 * @memberof Mojito.Modules.EventList
 */

/**
 * EventList prop types.
 *
 * @property {string} [headerText] - Text used as header. If not provided then sport name will be used.
 * @property {Function} [onItemClick = ()=>{}] - Callback fired when an item is clicked.
 * @property {Function} [onDataLoad] - Callback triggered when the market group is loaded.
 * @property {string} eventGroupsId - The id of event groups list.
 * @property {Array<Mojito.Services.EventGroups.types.EventGroup>} eventGroups - Groups of events to show.
 * @property {Array<string>} [initialExpandedGroupIds = []] - The list of initially expanded group ids.
 * @property {Array<Mojito.Services.EventGroups.types.MarketOption>} [marketOptions = []] - Market options list.
 * @property {string} sportId - Sport id.
 * @property {Function} [onShowAllButtonClick = ()=>{}] - Callback triggered when 'show all events' button is clicked.
 * @property {boolean} [isInPlay = false] - Flag to indicate if the list is for live events.
 *
 * @memberof Mojito.Modules.EventList.EventList
 */
const propTypes = {
    headerText: PropTypes.string,
    onItemClick: PropTypes.func,
    onDataLoad: PropTypes.func,
    eventGroupsId: PropTypes.string,
    eventGroups: PropTypes.array.isRequired,
    initialExpandedGroupIds: PropTypes.array,
    marketOptions: PropTypes.array,
    sportId: PropTypes.string.isRequired,
    onShowAllButtonClick: PropTypes.func,
    isInPlay: PropTypes.bool,
};

const defaultProps = {
    isInPlay: false,
    marketOptions: [],
    initialExpandedGroupIds: [],
    onDataLoad: noop,
    onItemClick: noop,
    onShowAllButtonClick: noop,
};

export default UIView('EventList', EventListImpl, propTypes, defaultProps);
