/**
 * This class consists of utilities that are uniquely needed for the Config service.
 *
 * @class Utils
 * @memberof Mojito.Core.Services.Config
 */

/**
 * Creates a ConfigSet.
 * If a config has a property with a value that is a ConfigSet then when
 * the UIView receives the config in this.config the ConfigSet will have
 * been replaced with the actual config represented by 'configSet'.
 *
 * @param {object} configSet - The reference to the configuration that this ConfigSet should be expanded to.
 * @param {object} [configValues] - Optional additional configuration values that should be merged into the expanded configuration.
 *
 * @returns {object} A ConfigSet.
 *
 * @function Mojito.Core.Services.Config.Utils#createConfigSet
 */
export const createConfigSet = (configSet, configValues) => ({
    configSet,
    configValues,
});

/**
 * This function injects metadata into a component's configuration.
 * It recursively traverses the `configValues` object and keeps track of its current key path.
 * The metadata contains information about the name of the parent component and the full key path of a config key.
 * This key has a value which contains a `configSet`.
 *
 * @example <caption>Suppose we have the following configuration:</caption>
 * ParentComponent:
 *   #@class: FlexPane
 *   container:
 *
 *   base:
 *     #@class: Button
 *     button:
 *
 * @example <caption>After the metadata injection, the `configValues` object will look like this:</caption>
 * {
 *  container: {
 *    configSet: FlexPane,
 *    configValues: {},
 *    metadata: {
 *      parentConfigId: "ParentComponent",
 *      configKey: "container"
 *    }
 *  },
 *  base: {
 *    button: {
 *      configSet: Button,
 *      configValues: {},
 *      metadata: {
 *        parentConfigId: "ParentComponent",
 *        configKey: "base.button"
 *      }
 *    }
 *  }
 * }
 *
 * @param {object} configValues - Object, or a subset of the original object containing the config values for a certain `parentConfigId`.
 * @param {string} parentConfigId - The name of the component which the `configValues` object belongs to.
 * @param {string} [keyPath] - One or more config keys separated by a point. Should be set when recursively moving down into a subset of the `configValues` object and include all keys to reach that subset.
 * @function injectConfigMetaData
 */
export const injectConfigMetaData = (configValues, parentConfigId, keyPath) => {
    const keys = Object.keys(configValues);

    keys.forEach(key => {
        const value = configValues[key];
        if (!value) {
            return;
        }

        const currentKeyPath = keyPath ? `${keyPath}.${key}` : key;
        if (value.configSet) {
            // If the key value is an object with a configSet then inject the metadata.
            configValues[key].metadata = {
                parentConfig: parentConfigId,
                configKey: currentKeyPath,
            };
        } else if (isNestedConfigObject(value, key)) {
            // In some config files the object with a configSet is not placed on the top level, so we need to
            // dig deeper into the configValues object.
            injectConfigMetaData(value, parentConfigId, currentKeyPath);
        }
    });
};

const isNestedConfigObject = (value, key) => {
    return key !== 'style' && typeof value === 'object' && !Array.isArray(value);
};
