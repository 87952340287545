/**
 * @class PerformanceTypes
 * @name types
 * @memberof Mojito.Core.Services.Performance
 */

/**
 * Performance metrics record.
 *
 * @typedef PerformanceRecord
 * @type {object}
 * @property {string} url - Page url.
 * @property {string} version - Mojito version.
 * @property {number} loadingTime - Time that was taken to fetch the required data and render the page (in seconds).
 * @property {string} username - User name.
 * @property {Mojito.Core.Services.SystemSettings.types.APPLICATION_MODE} mode - Application mode.
 * @property {boolean} initialLoading - True if it is a first page that user visited.
 * @property {number} wsSubscriptions - The number of subscriptions that were made to retrieve the data required to render the page.
 * @property {number} wsTransferredDataSize - The size of the data that was transferred via websockets (in kilobytes).
 * @property {number} wsLatencyAvg - Avarage value of websocket latency (in seconds).
 * @property {number} wsLatencyMax - The biggest websocket latency value (in seconds).
 * @property {number} wsLatencyMin - The smallest websocket latency value (in seconds).
 * @property {string} browserName - Browser name.
 * @property {string} browserVersion - Browser version.
 * @property {string} os - Operating system name and version.
 *
 * @memberof Mojito.Core.Services.Performance.types
 */

/**
 * Performance service config object.
 *
 * @typedef ServiceConfig
 * @type {object}
 * @property {Array<Mojito.Core.Services.Performance.AbstractPerformanceLogger>} loggers - Array of performance logger.
 */

/**
 * Service factory config object.
 *
 * @typedef FactoryConfig
 * @type {object}
 * @property {Mojito.Core.Services.Performance.AbstractPerformanceService} service - Performance service instance.
 * @property {Mojito.Core.Services.Performance.types.ServiceConfig} serviceConfig - Performance service config.
 */

/**
 * Timeline record types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Performance.types
 */
const TIMELINE_RECORD_TYPES = {
    /**
     * Record type for navigation events.
     */
    NAVIGATION: 'navigation',
    /**
     * Record type for module rendering events.
     * Used to track the time it takes for individual modules to render.
     */
    MODULE_RENDERED: 'moduleRendered',
    /**
     * Record type for WebSocket message events.
     * Used to track the sending and receiving of data over WebSockets.
     */
    WS_MESSAGE: 'wsMessage',
    /**
     * This time line type is reported once there is a request intention to subscribe for a data.
     * These requests are typically triggered by components.
     */
    WS_SUBSCRIPTION_INTENT: 'wsSubscriptionIntent',
    /**
     * Record type for WebSocket status events.
     * Used to track the connection state of the WebSocket. See {@link Mojito.Core.Services.Performance.types.WEBSOCKET_STATUS_TYPES|websocket status types} for possible states.
     */
    WS_STATUS: 'wsStatus',
    /**
     * Informs when websocket does subscription.
     */
    WS_SUBSCRIPTION: 'wsSubscription',
    /**
     * Informs when data processed with reducer.
     */
    WS_MESSAGE_PROCESSED: 'wsMessageProcessed',
};

/**
 * Websocket status types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Performance.types
 */
const WEBSOCKET_STATUS_TYPES = {
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
    READY: 'ready',
    CLOSED: 'close',
    DISCONNECTED: 'disconnected',
};

/**
 * Data aggregation types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Performance.types
 */
const DATA_TO_AGGREGATE = {
    WS_SUBSCRIPTIONS: 'wsSubscriptions',
    WS_TRANSFERRED_DATA: 'wsTransferredDataSize',
    WS_LATENCY_AVERAGE: 'wsLatencyAvg',
    WS_LATENCY_MIN: 'wsLatencyMin',
    WS_LATENCY_MAX: 'wsLatencyMax',
};

export default { TIMELINE_RECORD_TYPES, WEBSOCKET_STATUS_TYPES, DATA_TO_AGGREGATE };
