import PropTypes from 'prop-types';
import FlexPaneSeparatorImpl from './flex-pane-separator.jsx';
import MojitoCore from 'mojito/core';

/**
 * Helper component, used by FlexPane to separate its child items visually.
 *
 * @class FlexPaneSeparator
 * @memberof Mojito.Presentation.Components
 */

/**
 * `FlexPaneSeparator` component prop types.
 *
 * @property {string} direction - The main axis along which this separator is meant to separate things.
 * @property {string} [class] - A string of class names given to the component's root element.
 *
 * @memberof Mojito.Presentation.Components.FlexPane
 */
const propTypes = {
    direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']).isRequired,
    class: PropTypes.string,
    children: PropTypes.instanceOf(null),
};

export default MojitoCore.Presentation.UIView(
    'FlexPaneSeparator',
    FlexPaneSeparatorImpl,
    propTypes
);
