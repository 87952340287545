import MarketGroupTypes from './types';
import { isEmpty } from 'mojito/utils';

const { AGGREGATED_MARKET_TYPE, GROUP_TYPES } = MarketGroupTypes;

/**
 * Market groups data model utilities.
 *
 * @class MarketGroupsUtils
 * @name utils
 * @memberof Mojito.Services.SportsContent.MarketGroups
 */
export default class MarketGroupsUtils {
    /**
     * Creates aggregated market info object.
     *
     * @param {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} marketLines - The map of market lines. Key is line type and value is market line that contains actual market id.
     * @param {Mojito.Services.EventGroups.types.MarketOption} marketOption - Market option. Will be associated with event.marketLines object to identify marketIds and selections mask for generated market info object.
     * @param {number} displayOrder - Display order.
     *
     * @returns {Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo|undefined} Generic aggregated market info object. Or undefined if no marketOption provided.
     * @function Mojito.Services.SportsContent.MarketGroups.utils.createMarketInfo
     */
    static createMarketInfo(marketLines, marketOption, displayOrder) {
        if (!marketOption) {
            return;
        }
        const marketLine = marketLines?.[marketOption.marketLineType] || {};

        return {
            id: `AGGREGATED_MARKET_TYPE.GENERIC-${displayOrder}`,
            name: 'Generic market',
            type: AGGREGATED_MARKET_TYPE.GENERIC,
            mask: marketOption.headers,
            marketIds: [marketLine.id],
            fallbackMarketIds: marketLine.fallback ? [marketLine.id] : [],
            matchAcca: false,
            displayOrder,
        };
    }

    /**
     * Creates price boost aggregated market info object.
     *
     * @param {Array<string>} marketIds - List of market line ids.
     * @returns {Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo|undefined} Price boost aggregated market info object. Or undefined if marketIds is empty.
     * @function Mojito.Services.SportsContent.MarketGroups.utils.createPriceBoostedMarketInfo
     */
    static createPriceBoostedMarketInfo(marketIds) {
        if (isEmpty(marketIds)) {
            return;
        }
        return {
            id: AGGREGATED_MARKET_TYPE.PRICE_BOOST,
            name: 'Price boost',
            type: AGGREGATED_MARKET_TYPE.PRICE_BOOST,
            marketIds,
            matchAcca: false,
        };
    }

    /**
     * Creates game line aggregated market info object.
     *
     * @param {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} marketLines - The map of market lines. Key is line type and value is market line that contains actual market id.
     * @param {Mojito.Services.EventGroups.types.MarketOption} gameLine - Game line markets option. Will be associated with event.marketLines object to identify marketIds and selections mask for generated game line object.
     *
     * @returns {Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo|undefined} Game line aggregated market info object. Or undefined if gameLineOption is not provided.
     * @function Mojito.Services.SportsContent.MarketGroups.utils.createGameLineMarketInfo
     */
    static createGameLineMarketInfo(marketLines, gameLine) {
        if (!gameLine) {
            return;
        }
        const lines = marketLines || {};
        // marketOptions contains info about markets which are part of the game line a.k. MONEY, TOTAL, SPREAD.
        const marketOptions = gameLine.options || [];
        const marketIds = MarketGroupsUtils.getMarketIdsByMarketOption(lines, marketOptions);
        const matchAcca = marketOptions.some(option => lines[option.marketLineType]?.matchAcca);
        const mask = marketOptions.map(option => option.label);

        return {
            id: AGGREGATED_MARKET_TYPE.GAME_LINE,
            name: 'Game lines',
            type: AGGREGATED_MARKET_TYPE.GAME_LINE,
            mask,
            marketIds,
            matchAcca,
        };
    }

    /**
     * Resolves market ids from market lines.
     * Will go through each market option in provided list and resolve corresponding market id from market lines using marketOption.marketLineType property.
     *
     * @param {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} marketLines - The map of market lines. Key is line type and value is market line that contains actual market id.
     * @param {Array<Mojito.Services.EventGroups.types.MarketOption>} [marketOptions = []] - Market option. Will be associated with event.marketLines object to identify marketIds and selections mask for generated game line object.
     *
     * @returns {Array<string>} List of market ids.
     * @function Mojito.Services.SportsContent.MarketGroups.utils.getMarketIdsByMarketOption
     */
    static getMarketIdsByMarketOption(marketLines, marketOptions = []) {
        return marketOptions.map(option => marketLines[option.marketLineType]?.id);
    }

    /**
     * Finds race market group id.
     *
     * @param {Array} marketGroups - Market groups.
     *
     * @returns {string} Race market group id.
     * @function Mojito.Services.SportsContent.MarketGroups.utils.getRaceMarketGroup
     */
    static getRaceMarketGroupId(marketGroups = []) {
        return marketGroups.find(e => e.type === GROUP_TYPES.RACE_MARKET)?.id;
    }
}

/**
 * @typedef {Function} MarketGroupFilter
 * @param {object} marketGroup - Market group item.
 * @param {object} eventItem - The event object.
 * @returns {boolean} True if the group should belong to particular category, false otherwise.
 */
