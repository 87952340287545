import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('GamificationServiceFactory');

const voidResponder = () => {
    log.warn('Gamification service instance is missing.');
    return Promise.reject();
};

const NULL_SERVICE = {
    configure: () => {},
    connect: voidResponder,
    disconnect: voidResponder,
    getInitialDataSet: voidResponder,
    onBadgeUpdate: () => {},
};

/**
 * Singleton object that spawns service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.Gamification
 */
class ServiceFactory {
    constructor() {
        this.service = NULL_SERVICE;
    }
    /**
     * Init factory.
     *
     * @function init
     *
     * @param {Mojito.Services.Gamification.AbstractGamificationService} service - Instance.
     *
     * @memberof Mojito.Services.Gamification.serviceFactory
     */
    init(service) {
        this.service = service || NULL_SERVICE;
    }

    /**
     * Service getter.
     *
     * @function getService
     *
     * @returns {Mojito.Services.Gamification.AbstractGamificationService} Gamification service instance.
     *
     * @memberof Mojito.Services.Gamification.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
