/**
 * Class defining race region data descriptors.
 *
 * @class RaceRegionDataDescriptors
 * @name descriptors
 * @memberof Mojito.Services.SportsContent.RaceRegions
 */
export default class RaceRegionsDataDescriptors {
    /**
     * Factory method for creating a race region data descriptor.
     *
     * @param {string} raceRegionId - The race region id.
     *
     * @returns {{dataType: string, raceRegionId: *}} Race region data descriptor.
     *
     * @function Mojito.Services.SportsContent.RaceRegions.descriptors#createRaceRegionsDescriptor
     * @static
     */
    static createRaceRegionsDescriptor(raceRegionId) {
        return {
            dataType: RaceRegionsDataDescriptors.DATA_TYPES.RACE_REGION,
            raceRegionId: raceRegionId,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @property {string} RACE_REGION - Race region data descriptor type.
 *
 * @memberof Mojito.Services.SportsContent.RaceRegions.descriptors
 */
RaceRegionsDataDescriptors.DATA_TYPES = {
    RACE_REGION: 'race-region',
};
