import PropTypes from 'prop-types';
import GenericBetFundsImpl from './generic-bet-funds-info.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Generic component used to display bet funds.
 *
 * @class GenericBetFundsInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * GenericBetFundsInfo props.
 *
 * @property {string} label - Label.
 * @property {string} value - Value.
 * @property {string} [previousValue] - Previous value.
 * @property {string} [class] - Class name.
 * @property {string} [valueClass] - A string of class names given to the Text value root element.
 * @property {boolean} [showIcon] - Flag to display icon.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.GenericBetFundsInfo
 */
const propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    previousValue: PropTypes.string,
    class: PropTypes.string,
    valueClass: PropTypes.string,
    showIcon: PropTypes.bool,
};

export default UIView('GenericBetFundsInfo', GenericBetFundsImpl, propTypes);
