import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class FallbackEventCard extends UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.onClick = () => this.props.onClick(this.props.onClickData);
    }

    renderHeader() {
        return (
            <Text class="ta-headerTitle" config={this.config.eventCardBase.title}>
                {this.props.headerTitle}
            </Text>
        );
    }

    renderBetNow() {
        return (
            <FlexPane config={this.config.betNowContainer}>
                <Text config={this.config.betNowText}>
                    {this.resolveAndFormatString('$FALLBACK_CARD.BET_NOW')}
                </Text>
                <Image config={this.config.betNowIcon} />
            </FlexPane>
        );
    }

    renderBackgroundSportImage(imageSrc) {
        return (
            <AbsolutePane config={this.config.backgroundImageContainer}>
                <Image
                    src={imageSrc}
                    config={this.config.backgroundImage}
                    class="ta-backgroundImage"
                />
            </AbsolutePane>
        );
    }

    renderContent() {
        return (
            <FlexPane config={this.config.contentContainer}>
                <Text class="ta-contentText" config={this.config.contentText}>
                    {this.props.contentText}
                </Text>
            </FlexPane>
        );
    }

    render() {
        const { eventCardBase, showBackgroundSportImage, sportIcons } = this.config;
        const { clickableArea, container } = eventCardBase;
        const imageSrc = sportIcons[this.props.sportId] || sportIcons.defaultIcon;
        const showBgImage = Boolean(showBackgroundSportImage && imageSrc);
        return (
            <Button class="ta-clickableArea" config={clickableArea} onClick={this.onClick}>
                <FlexPane class="ta-FallbackEventCard" config={container}>
                    {showBgImage && this.renderBackgroundSportImage(imageSrc)}
                    {this.renderHeader()}
                    {this.renderContent()}
                    {this.renderBetNow()}
                </FlexPane>
            </Button>
        );
    }
}
