import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * Abstract search service to be implemented by different integrations.
 * Note: All methods must be implemented in a asynchronous way, hence
 * the specified callbacks must not be called when inside the called function.
 *
 * @class SearchAbstractService
 * @abstract
 * @memberof Mojito.Services.Search
 */

export default class SearchAbstractService {
    /**
     * Search request.
     *
     * @param {Mojito.Services.Search.types.SearchData} searchData - Object defines search data.
     *
     * @returns {Promise} Search promise.
     * @function Mojito.Services.Search.SearchAbstractService#search
     */
    // eslint-disable-next-line no-unused-vars
    search(searchData) {
        log.error('search must be implemented by concrete subclass!');
        return Promise.reject();
    }
}
