import { createRef, Fragment } from 'react';
import MojitoCore from 'mojito/core';
import Button from 'presentation/components/button/index.jsx';
import HideablePane from 'presentation/components/hideable-pane/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import ScrollPane from 'presentation/components/scroll-pane/index.jsx';
import RotatableImage from 'presentation/components/rotatable-image/index.jsx';

const { blockEvent } = MojitoCore.Base.domUtils;
const { StringUtils } = MojitoCore.Base;

export default class Dropdown extends MojitoCore.Presentation.UIViewImplementation {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.rootNode = createRef();

        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleDropdownClick = this.handleDropdownClick.bind(this);
        this.handleMenuItemClicked = this.handleMenuItemClicked.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick, false);
    }

    toggleMenu(newOpenState) {
        if (newOpenState !== this.state.isOpen) {
            this.setState({
                isOpen: newOpenState,
            });

            if (newOpenState && this.props.triggerOverlayInteraction) {
                this.props.mojitoTools.appContext.overlayInteraction();
            }
        }
    }

    manageListeners() {
        if (this.state.isOpen) {
            document.removeEventListener('click', this.handleDocumentClick, false);
        } else {
            document.addEventListener('click', this.handleDocumentClick, false);
        }
    }

    handleDocumentClick(event) {
        if (this.rootNode.current.contains(event.target)) {
            return;
        }

        event.stopPropagation();
        this.manageListeners();
        this.toggleMenu(!this.state.isOpen);
    }

    handleDropdownClick() {
        if (this.props.disabled) {
            return;
        }

        this.manageListeners();
        this.toggleMenu(!this.state.isOpen);
    }

    handleMenuItemClicked(event, { value, label }) {
        blockEvent(event);
        this.manageListeners();
        this.toggleMenu(false);

        if (value !== this.props.selectedItemValue) {
            this.props.cbOnChange(value, this.props.id, label);
        }
    }

    renderControl() {
        const itemLabel = this.props.controlText || this.resolveControlLabel() || '';
        const { isSelected } = this.props;
        const {
            controlButtonSelected,
            controlButton,
            controlTextSelected,
            controlText,
            rotationSelected,
            rotation,
        } = this.config;

        return (
            <Button
                class="ta-DropdownControl"
                onClick={this.handleDropdownClick}
                config={isSelected ? controlButtonSelected : controlButton}
                disabled={this.props.disabled}
            >
                <Text
                    class={`ta-DropdownSelected ta-item-${StringUtils.makeCssClassName(itemLabel)}`}
                    config={isSelected ? controlTextSelected : controlText}
                >
                    {itemLabel}
                </Text>
                <RotatableImage
                    config={isSelected ? rotationSelected : rotation}
                    angle={this.state.isOpen ? '0deg' : '180deg'}
                />
            </Button>
        );
    }

    resolveControlLabel() {
        const { items, itemGroups } = this.props;

        let selectedItem;

        if (items) {
            selectedItem = this.getSelectedItem(items);
        } else {
            itemGroups.forEach(group => {
                selectedItem = this.getSelectedItem(group.items) || selectedItem;
            });
        }

        return selectedItem && selectedItem.label;
    }

    getSelectedItem(items) {
        return items.find(item => item.value === this.props.selectedItemValue);
    }

    renderMenuItem(item) {
        const hrefLink = item.hrefLink ? item.hrefLink : '';
        return (
            <Button
                class={`ta-DropdownMenuItem ta-item-${StringUtils.makeCssClassName(item.label)}${
                    this.props.selectedItemValue === item.value ? ' ta-selected' : ''
                }`}
                key={item.value}
                hrefLink={hrefLink}
                config={this.config.menuItem}
                onClick={this.handleMenuItemClicked}
                onClickData={{ value: item.value, label: item.label }}
                active={this.props.selectedItemValue === item.value}
            >
                <Text config={this.config.menuItemText}>{item.label}</Text>
            </Button>
        );
    }

    renderMenuItems(items) {
        return (
            <FlexPane config={this.config.columnContainer}>
                {items.map(item => this.renderMenuItem(item))}
            </FlexPane>
        );
    }

    renderMenuItemGroups(groups) {
        return groups.map(group => (
            <Fragment key={`dropdown-label-${group.label}`}>
                <Text config={this.config.subHeaderText} class="ta-DropdownMenuItemHeader">
                    {group.label}
                </Text>
                {this.renderMenuItems(group.items)}
            </Fragment>
        ));
    }

    renderMenu() {
        return (
            <AbsolutePane class="ta-DropdownMenu" config={this.config.menuContainer}>
                {this.config.limitNavigation && (
                    <HideablePane
                        hidden={!this.state.isOpen}
                        config={this.style.onOutsideClickContainer}
                    >
                        <div style={this.style.scrim} onClick={this.handleDropdownClick} />
                    </HideablePane>
                )}
                <HideablePane hidden={!this.state.isOpen} config={this.config.itemsContainer}>
                    <ScrollPane config={this.style.scrollPaneConfig}>
                        {this.props.items
                            ? this.renderMenuItems(this.props.items)
                            : this.renderMenuItemGroups(this.props.itemGroups)}
                    </ScrollPane>
                </HideablePane>
            </AbsolutePane>
        );
    }

    render() {
        // <div> required as contains method doesn't exist for react component references.
        return (
            <div
                className={`ta-Dropdown ${this.props.class || ''}`}
                ref={this.rootNode}
                style={this.config.style.rootContainer}
            >
                {this.renderControl()}
                {this.renderMenu()}
            </div>
        );
    }
}

Dropdown.getStyle = () => ({
    onOutsideClickContainer: {
        style: {
            base: {
                maxHeight: '0px',
                maxWidth: '0px',
            },
        },
    },
    scrim: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    scrollPaneConfig: {
        direction: 'y',
    },
});
