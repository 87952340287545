import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';

const { SkeletonPart, FlexPane } = MojitoPresentation.Components;

const EventListItemSkeleton = ({ mojitoTools: { config }, numberOfMarkets }) => {
    return (
        <FlexPane class="ta-EventListItemSkeleton" config={config.container}>
            <SkeletonPart config={config.eventTime} />
            {config.isAmericanEvent ? (
                <AmericanParticipantsAndMarkets config={config} numberOfMarkets={numberOfMarkets} />
            ) : (
                <ParticipantsAndMarkets config={config} numberOfMarkets={numberOfMarkets} />
            )}
            <SkeletonPart config={config.marketCount} />
        </FlexPane>
    );
};

const isOdd = value => value % 2 !== 0;

const ParticipantsAndMarkets = ({ config, numberOfMarkets }) => {
    return (
        <FlexPane config={config.participantsAndMarketsContainer}>
            <FlexPane config={config.participantsContainer}>
                <SkeletonPart config={config.participant} />
                <SkeletonPart config={config.participant} />
            </FlexPane>
            {range(numberOfMarkets).map(key => (
                <Market key={key} config={config} numberOfSelections={isOdd(key + 1) ? 3 : 2} />
            ))}
        </FlexPane>
    );
};

const AmericanParticipantsAndMarkets = ({ config, numberOfMarkets }) => {
    const markets = range(numberOfMarkets).map(key => (
        <Market key={key} config={config} numberOfSelections={numberOfMarkets > 1 ? 1 : 3} />
    ));

    return (
        <FlexPane config={config.americanParticipantsAndMarketsContainer}>
            <FlexPane config={config.americanParticipantContainer}>
                <SkeletonPart config={config.participant} />
                <FlexPane config={config.americanMarketsContainer}>{markets}</FlexPane>
            </FlexPane>
            <FlexPane config={config.americanParticipantContainer}>
                <SkeletonPart config={config.participant} />
                <FlexPane config={config.americanMarketsContainer}>{markets}</FlexPane>
            </FlexPane>
        </FlexPane>
    );
};

const Market = ({ config, numberOfSelections }) => {
    return (
        <FlexPane config={config.marketContainer}>
            {range(numberOfSelections).map(key => (
                <SkeletonPart key={key} config={config.selection} />
            ))}
        </FlexPane>
    );
};

export default EventListItemSkeleton;
