import { useRef, useEffect } from 'react';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { noop, pick, omit } from 'mojito/utils';
import SelectionButtonTypes from 'modules/selection-button/types/index.js';

const { Image, Text, FlexPane, Arrowhead, AbsolutePane, HideablePane } =
    MojitoPresentation.Components;
const { EventFormatter, MarketHelper } = MojitoPresentation.Utils;

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const uiStyle = MojitoCore.Presentation.uiStyle;
const EventsUtils = MojitoServices.SportsContent.Events.utils;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;
const NumberUtils = MojitoCore.Base.NumberUtils;
const log = MojitoCore.logger.get('SelectionButton');

export default class SelectionButtonView extends UIViewImplementation {
    constructor(props) {
        super(props);
        const { selection } = this.props;
        this.state = {
            priceChangeState: SelectionButtonTypes.PRICE_CHANGE_STATES.NONE,
            selectionPrices: selection.prices,
            isHovering: false,
            touchStarted: false,
            isInfoTextTruncated: false,
        };

        this.priceChangeTimerId = null;
        this.lastRenderedBetslipKey = null;

        this.onClick = this.onClick.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onInfoTextTruncationChange = this.onInfoTextTruncationChange.bind(this);
    }

    static resolveNewPriceChangeState(props, state) {
        const newComponentState = {};

        const oldPrice = EventsUtils.getPrice(state.selectionPrices);
        const newPrice = EventsUtils.getPrice(props.selection.prices);
        if (!oldPrice || !newPrice) {
            return newComponentState;
        }
        let newPriceChangeState;
        // Did a price change occur?
        if (EventsUtils.priceIsHigher(oldPrice, newPrice)) {
            newPriceChangeState = SelectionButtonTypes.PRICE_CHANGE_STATES.INCREASE;
        } else if (EventsUtils.priceIsHigher(newPrice, oldPrice)) {
            newPriceChangeState = SelectionButtonTypes.PRICE_CHANGE_STATES.DECREASE;
        }

        // Hold on to current state in case it changed
        if (newPriceChangeState && newPriceChangeState !== state.priceChangeState) {
            newComponentState.priceChangeState = newPriceChangeState;
        }

        return newComponentState;
    }

    componentWillUnmount() {
        if (this.priceChangeTimerId) {
            clearTimeout(this.priceChangeTimerId);
            this.priceChangeTimerId = null;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.priceChangeState !== prevState.priceChangeState &&
            this.state.priceChangeState !== SelectionButtonTypes.PRICE_CHANGE_STATES.NONE
        ) {
            // Reset timer in order to keep it alive even if there are consecutive changes
            if (this.priceChangeTimerId) {
                clearTimeout(this.priceChangeTimerId);
            }

            // Set up the timer to reset the price change state
            this.priceChangeTimerId = setTimeout(() => {
                // Reset price change state
                this.setState({
                    priceChangeState: SelectionButtonTypes.PRICE_CHANGE_STATES.NONE,
                });

                // Reset timer id
                this.priceChangeTimerId = null;
            }, this.config.msPriceChangeTimeout);
        }
    }

    componentDidMount() {
        const { selection, marketStatus, event } = this.props;
        const isSuspended = EventsUtils.selectionIsSuspended(
            selection.status,
            marketStatus,
            event.status
        );
        const noPriceAvailable = !EventsUtils.pricesAreAvailable(selection.prices);
        if (isSuspended || noPriceAvailable) {
            // Make sure that onOverflowText called even if selection doesn't have info text or price
            this.props.onOverflowText(false, selection.id);
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {
            selectionPrices: props.selection.prices,
        };

        // Resolve our new price change state
        Object.assign(newState, SelectionButtonView.resolveNewPriceChangeState(props, state));

        return newState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isShallowEqual(this.props, nextProps) || !isShallowEqual(this.state, nextState);
    }

    isPending() {
        return this.props.state === SelectionButtonTypes.STATES.PENDING;
    }

    onClick(event) {
        event.stopPropagation();
        this.props.onClick();
    }

    onMouseOver() {
        this.setState({ isHovering: true });
    }

    onMouseOut() {
        this.setState({ isHovering: false });
    }

    onTouchStart() {
        // Remember that a touch event was started so that the hovering effect can be removed on betslip status change
        this.setState({ touchStarted: true });
    }

    resolveSelectionName() {
        const nameDisplay = this.props.nameDisplay || this.config.nameDisplay;
        const { HIDE, SHOW, SHOW_SHORT, SHOW_SHORTEST } = SelectionButtonTypes.NAME_DISPLAY;
        const { name, shortName } = this.props.selection;

        switch (nameDisplay) {
            case HIDE:
                return '';
            case SHOW_SHORT:
                return shortName || name;
            case SHOW_SHORTEST:
                return (name || '').substr(0, 1);
            case SHOW:
                return name;
            default:
                log.warn(
                    `Unsupported 'nameDisplay' type: ${nameDisplay}. Will fallback to use 'show'.`
                );
                return name;
        }
    }

    formatSelectionName(selectionName) {
        // Added zero width space before '/' and '-' to allow line break on longer words or empty string.
        return selectionName
            ? selectionName.replace(/\//g, '/\u200B').replace(/-/g, '-\u200B')
            : '';
    }

    resolveSetGameInfoText() {
        if (
            !this.config.showSetGameInfoText ||
            !this.config.setGameMarketTypes.includes(this.props.marketType)
        ) {
            return;
        }

        return EventFormatter.formatSetGameInfoText(
            this.props.event,
            this.config.sportsDisplayFormat[this.props.event.sportId].segment,
            this.l10n
        );
    }

    onInfoTextTruncationChange(isInfoTextTruncated) {
        this.setState({ isInfoTextTruncated });
    }

    renderInfoText() {
        const { infoText, handicap } = this.resolveInfoTextLabels();
        if (!infoText && !handicap) {
            return null;
        }
        const ellipsisOnFirstLine = this.config.infoText.ellipsisOnLineNumber === 1;
        const textStyle =
            this.state.isInfoTextTruncated || ellipsisOnFirstLine
                ? this.style.infoTextTruncated
                : this.style.infoText;
        const infoTextStyle = textStyle[this.props.state];
        const infoTextHandicapStyle = this.style.infoTextHandicap[this.props.state];

        return (
            <FlexPane key="infoText" class="ta-infoText" config={this.config.infoTextContainer}>
                {infoText && (
                    <SelectionInfoText
                        infoText={infoText}
                        handicap={this.state.isInfoTextTruncated ? undefined : handicap}
                        infoTextStyle={infoTextStyle}
                        selectionId={this.props.selection.id}
                        onOverflows={this.props.onOverflowText}
                        onTruncate={this.onInfoTextTruncationChange}
                    />
                )}
                {handicap && (this.state.isInfoTextTruncated || !infoText) && (
                    <Text class="ta-infoTextHandicap" config={infoTextHandicapStyle}>
                        {handicap}
                        {this.resolveSetGameInfoText()}
                    </Text>
                )}
            </FlexPane>
        );
    }

    resolveInfoTextLabels() {
        const { selection } = this.props;
        const selectionName = this.resolveSelectionName();
        if (selectionName?.includes(selection.handicapLabel)) {
            const { name, handicapLabel } = MarketHelper.handleHandicap(
                selectionName,
                selection.handicapLabel
            );
            return { infoText: this.formatSelectionName(name), handicap: handicapLabel };
        }
        const handicap = this.config.handicapDisplay ? selection.handicapLabel : undefined;
        return { infoText: this.formatSelectionName(selectionName), handicap };
    }

    renderPriceText() {
        if (!this.config.showPrice) {
            return null;
        }

        const priceText = EventFormatter.formatSelectionPrice(
            this.props.selection.prices,
            this.l10n
        );

        let priceChangeColor;

        // Take price change state into consideration
        if (this.config.applyPriceChangeTextColor) {
            priceChangeColor = this.style.priceText[`${this.state.priceChangeState}Color`];
        }

        // Merge price change color if it is defined
        const { state: currentState } = this.props;
        const priceTextStyle = priceChangeColor
            ? uiStyle.mergeAndPrefix(this.style.priceText[currentState], {
                  color: priceChangeColor,
              })
            : this.style.priceText[currentState];

        return (
            <div key="priceText" style={priceTextStyle}>
                {this.config.priceText.ellipsize ? (
                    <Text class="ta-price_text" config={this.config.priceTextEllipsis}>
                        {priceText}
                    </Text>
                ) : (
                    priceText
                )}
            </div>
        );
    }

    renderUnavailable(label) {
        return (
            <div style={this.style.unavailable} className="ta-unavailable">
                {label || this.resolveString('$GENERIC.UNAVAILABLE')}
            </div>
        );
    }

    renderPriceDirectionArrow({ arrowConfig, paneConfig }) {
        const { container } = this.style.priceChangeIndicators;

        return (
            <AbsolutePane config={container}>
                <HideablePane config={paneConfig}>
                    <Arrowhead config={arrowConfig} />
                </HideablePane>
            </AbsolutePane>
        );
    }

    renderPriceChangeIndicators() {
        const {
            hidePaneDown,
            arrow: { up, down },
        } = this.style.priceChangeIndicators;
        const { INCREASE, DECREASE, NONE } = SelectionButtonTypes.PRICE_CHANGE_STATES;
        const priceDirections = {
            [INCREASE]: {
                arrowConfig: up,
                paneConfig: this.config.priceChangeIndicators.transition,
            },
            [DECREASE]: { arrowConfig: down, paneConfig: hidePaneDown },
            [NONE]: null,
        }[this.state.priceChangeState];

        return priceDirections && this.renderPriceDirectionArrow(priceDirections);
    }

    getInfoTextStyle() {
        const { state: currentState } = this.props;
        return uiStyle.mergeAndPrefix(this.style.infoText[currentState]);
    }

    resolveContent() {
        if (this.config.l10nLabel) {
            const labelStyle = this.style.infoText[this.props.state];
            return <div style={labelStyle}>{this.resolveString(this.config.l10nLabel)}</div>;
        }
        return [this.renderInfoText(), this.renderPriceText()];
    }

    render() {
        let content, containerStyle, className;
        let isClickable = false;
        const { state: currentState } = this.props;

        if (
            EventsUtils.selectionIsSuspended(
                this.props.selection.status,
                this.props.marketStatus,
                this.props.event.status
            )
        ) {
            containerStyle = this.style.container[SelectionButtonTypes.STATES.DISABLED];
            className = 'ta-Suspended';
            content = this.props.suspendedString ? (
                this.renderUnavailable(this.props.suspendedString)
            ) : (
                <div style={this.style.suspended}>
                    <Image config={this.config.lockedIcon} class="ta-lock" />
                </div>
            );
        } else if (!EventsUtils.pricesAreAvailable(this.props.selection.prices)) {
            containerStyle = this.style.container[SelectionButtonTypes.STATES.DISABLED];
            content = this.renderUnavailable(this.props.noPricesAvailableString);
            className = 'ta-Unavailable';
        } else {
            // "Normal" mode...
            if (this.state.isHovering && !this.isPending()) {
                containerStyle =
                    this.style.container[
                        currentState + SelectionButtonTypes.HOVER_STATE.toLowerCase()
                    ];
            } else {
                containerStyle = this.style.container[currentState];
            }
            const contentContainerStyle = this.style.contentContainer[this.config.layoutDirection];
            className = 'ta-Normal';
            // Make sure the button-click is handled
            isClickable = true;

            content = (
                <>
                    <FlexPane
                        class={this.state.isHovering ? 'hovering' : currentState}
                        config={contentContainerStyle}
                    >
                        {this.resolveContent()}
                    </FlexPane>
                    {this.config.showPriceChangeIndicators && this.renderPriceChangeIndicators()}
                </>
            );
        }

        return (
            <div
                className={`ta-SelectionButtonView ${className} ${currentState}`}
                onClick={isClickable ? this.onClick : noop}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onTouchStart={this.onTouchStart}
                style={containerStyle}
                ref={this.props.buttonRef}
                role={'button'}
            >
                {content}
            </div>
        );
    }
}

const isMultiLineText = (element, lineCount) => {
    const computedStyle = getComputedStyle(element);
    const fontSize = Number(NumberUtils.escapePixels(computedStyle.fontSize));
    let lineHeight = Number(NumberUtils.escapePixels(computedStyle.lineHeight));
    const height = element.offsetHeight;
    lineHeight = isNaN(lineHeight) ? fontSize : Math.max(lineHeight, fontSize);
    return lineHeight * lineCount <= height;
};

const isTextOverflowed = (element, lineCount) => {
    const widthOverflows = element && element.offsetWidth < element.scrollWidth;
    const heightOverflows = element && isMultiLineText(element, lineCount);
    return widthOverflows || heightOverflows;
};

const SelectionInfoText = props => {
    const { infoTextStyle, infoText, handicap, selectionId, onOverflows, onTruncate } = props;
    const elementRef = useRef();
    useEffect(() => {
        const element = elementRef.current;
        onOverflows(isTextOverflowed(element, 2), selectionId);
        onTruncate(isTextOverflowed(element, 3));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoText]);

    const text = handicap ? `${infoText} ${handicap}` : infoText;
    return (
        <Text class="ta-infoTextName" textRef={elementRef} config={infoTextStyle}>
            {text}
        </Text>
    );
};

SelectionButtonView.getStyle = function (config, applicationMode, merge) {
    const infoTextOverride = config.infoTextOverrides[config.layoutDirection];
    const truncationProps = ['truncate', 'ellipsisOnLineNumber'];
    const infoTextBase = merge(config.infoText, pick(infoTextOverride, truncationProps), {
        style: omit(infoTextOverride, truncationProps),
    });

    const infoTextBaseNoTruncation = merge(infoTextBase, { truncate: false });

    const infoTextHandicapBase = merge(infoTextBase, {
        truncate: false,
        container: { flexShrink: 0, alignSelf: 'end' },
    });

    const createInfoTextConfig = baseConfig => {
        const { inactive, active } = config.infoTextOverrides;
        const createInactiveStyle = () => omit(inactive, truncationProps);
        const createActiveStyle = () => omit(active, truncationProps);
        return {
            inactive: merge(baseConfig, { style: createInactiveStyle() }),
            active: merge(baseConfig, { style: createActiveStyle() }),
            pending: merge(baseConfig, { style: createActiveStyle() }),
        };
    };

    const containerBase = merge(
        {
            minHeight: config.minHeight,
            height: config.height,
            width: config.width,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: config.layoutDirection === 'horizontalAlignLeft' ? 'flex-start' : 'center',
            boxSizing: 'border-box',
            textTransform: 'uppercase',
            cursor: 'pointer',
            padding: config.padding,
            position: 'relative',
            overflow: 'hidden',
        },
        config.borderStyle
    );

    const contentContainerBase = {
        style: {
            // Setting maxWidth is needed to make ellipsing for text work as intended
            maxWidth: '100%',
            flexGrow: 0,
            boxSizing: 'border-box',
        },
    };

    const priceTextBase = merge(
        {
            alignSelf: 'center',
            display: 'flex',
            flex: '1 0 auto',
        },
        config.priceText.base,
        config.priceText[config.layoutDirection]
    );

    const style = {
        container: {
            active: merge(containerBase, {
                background: config.background.active,
                ...uiStyle.createCssAnimation(config, 'active_fade_in', config.animation),
            }),
            activehover: merge(containerBase, {
                background: config.background.activehover,
            }),
            pending: merge(containerBase, {
                background: config.background.active,
                opacity: 0.5,
            }),
            inactive: merge(containerBase, {
                background: config.background.inactive,
            }),
            inactivehover: merge(containerBase, {
                background: config.background.inactivehover,
            }),
            disabled: merge(containerBase, {
                background: config.background.disabled,
                cursor: 'default',
            }),
        },
        unavailable: merge(
            {
                textAlign: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                alignSelf: 'center',
            },
            config.unavailable
        ),
        suspended: {
            alignSelf: 'center',
        },
        contentContainer: {
            vertical: merge(
                {
                    style: {
                        justifyContent: 'center',
                        flexDirection: 'column',
                    },
                },
                contentContainerBase,
                config.contentContainer.vertical
            ),
            horizontalAlignLeft: merge(
                {
                    style: {
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                    },
                },
                contentContainerBase,
                config.contentContainer.horizontalAlignLeft
            ),
            horizontalAlignCenter: merge(
                {
                    style: {
                        justifyContent: 'center',
                        flexDirection: 'row',
                    },
                },
                contentContainerBase,
                config.contentContainer.horizontalAlignCenter
            ),
        },
        infoTextContainer: {
            style: {
                justifyContent: 'center',
                minWidth: 0,
            },
            itemSpacing: config.infoText.itemSpacing,
        },
        infoText: createInfoTextConfig(infoTextBaseNoTruncation),
        infoTextTruncated: createInfoTextConfig(infoTextBase),
        infoTextHandicap: createInfoTextConfig(infoTextHandicapBase),
        priceText: {
            inactive: merge(priceTextBase, config.priceText.inactive),
            pending: merge(priceTextBase, config.priceText.active),
            active: merge(priceTextBase, config.priceText.active),
            decreaseColor: config.priceText.decreaseColor,
            increaseColor: config.priceText.increaseColor,
        },

        priceChangeIndicators: {
            container: {
                style: {
                    top: '0%',
                    right: '0%',
                    height: '100%',
                    flexDirection: 'column',
                },
            },

            hidePaneDown: merge(config.priceChangeIndicators.transition, {
                style: {
                    base: {
                        alignItems: 'flex-end',
                    },
                },
            }),

            arrow: {
                up: merge(config.priceChangeIndicators.arrowheadUp, { direction: 'upRight' }),
                down: merge(config.priceChangeIndicators.arrowheadDown, { direction: 'downRight' }),
            },
        },
    };

    return style;
};
