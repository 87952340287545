import PropTypes from 'prop-types';
import EventListItemSkeletonImpl from './event-list-item-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Event list item skeleton used in event list skeleton.
 *
 * @function EventListItemSkeleton
 * @memberof Mojito.Presentation.EventList
 */

/**
 * EventListItemSkeleton prop types.
 *
 * @param {number} [numberOfMarkets = 1] - Number of markets to render.
 */
const propTypes = {
    numberOfMarkets: PropTypes.number,
};

const defaultProps = {
    numberOfMarkets: 1,
};

export default UIView('EventListItemSkeleton', EventListItemSkeletonImpl, propTypes, defaultProps);
