import MojitoCore from 'mojito/core';
import { isEmpty, merge } from 'mojito/utils';
import { DATE_TIME_FORMAT, DURATION } from './types.js';

const {
    LONG_DATE_TIME,
    SHORT_DATE_TIME,
    FULL_DATE_TIME,
    LONG_DATE,
    SHORT_DATE,
    LONG_TIME,
    SHORT_TIME,
    NUMERIC_DATE,
    NUMERIC_SHORT_DATE,
    NUMERIC_DATE_TIME,
    NUMERIC_DATE_LONG_TIME,
} = DATE_TIME_FORMAT;
const { selectLanguage } = MojitoCore.Services.SystemSettings.selectors;
const StringUtils = MojitoCore.Base.StringUtils;
const log = MojitoCore.logger.get('DateTimeFormatter');
const DEFAULT_TIME_ZONE = 'UTC';
const { DateTimeUtils } = MojitoCore.Base;

const shortTime = Object.freeze({
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
});
const longTime = Object.freeze({
    ...shortTime,
    second: 'numeric',
});
const shortDate = Object.freeze({
    month: 'short',
    day: 'numeric',
});
const longDate = Object.freeze({
    ...shortDate,
    year: 'numeric',
});
const shortDateTime = Object.freeze({ ...shortDate, ...shortTime });
const longDateTime = Object.freeze({ ...longDate, ...shortTime });
const fullDateTime = Object.freeze({ ...longDate, ...longTime });
const numericShortDate = Object.freeze({
    month: 'numeric',
    day: 'numeric',
});
const numericDate = Object.freeze({
    ...numericShortDate,
    year: 'numeric',
});
const numericDateTime = Object.freeze({ ...numericDate, ...shortTime });
const numericDateLongTime = Object.freeze({ ...numericDate, ...longTime });
const formatMapper = (format, options = {}) => {
    const formats = {
        [LONG_DATE_TIME]: longDateTime,
        [SHORT_DATE_TIME]: shortDateTime,
        [FULL_DATE_TIME]: fullDateTime,
        [LONG_DATE]: longDate,
        [SHORT_DATE]: shortDate,
        [LONG_TIME]: longTime,
        [SHORT_TIME]: shortTime,
        [NUMERIC_DATE]: numericDate,
        [NUMERIC_SHORT_DATE]: numericShortDate,
        [NUMERIC_DATE_TIME]: numericDateTime,
        [NUMERIC_DATE_LONG_TIME]: numericDateLongTime,
    };
    return { ...formats[format], ...options };
};
const dateFormatMap = new Map();
const getFormattingDelimiter = (locale, format) => {
    const options = {
        [DURATION.MINUTES_SECONDS]: {
            minute: '2-digit',
            second: '2-digit',
        },
        [DURATION.HOURS_MINUTES_SECONDS]: {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        },
    };
    return new Intl.DateTimeFormat(locale, { ...options[format], hour12: false })
        .formatToParts()
        .reduce((acc, part) => {
            acc[part.type] = part.value;
            return acc;
        }, {});
};

const prependZero = unit => (unit > 9 ? String(unit) : `0${unit}`);
const prependZeros = (units, delimiter) => units.map(prependZero).filter(Boolean).join(delimiter);

/**
 * Utility class for formatting date and time strings.
 *
 * @memberof Mojito.Presentation.Utils.DateTime
 */
class DateTimeFormatter {
    /**
     * Returns a date and time label in either long or short format.
     *
     * @param {string} dateTime - A date and time string in ISO 8601 format.
     * @param {string} dateTimeFormat - Which date time format to use. Should be one of the values in
     * {@link Mojito.Presentation.Utils.DateTime.DateTimeTypes.DATE_TIME_FORMAT|DATE_TIME_FORMAT}.
     * @param {string} language - Language code.
     * @param {object} userSettings - User settings.
     * @param {boolean} [useHour12 = false] - Whether to use 12-hour time (as opposed to 24-hour time).
     * @returns {string} A date and time label.
     */
    static formatDateTime(dateTime, dateTimeFormat, language, userSettings, useHour12 = false) {
        if (!dateTime) {
            return '';
        }
        const { timeZone, timeOffset, countryCode, locales, useLocalTime } = userSettings;
        const currentLocale = !isEmpty(locales)
            ? locales
            : DateTimeFormatter.generateLocale(language, countryCode);
        const date = new Date(dateTime);
        const dateToFormat =
            useLocalTime || timeZone ? date : DateTimeUtils.addHours(date, timeOffset);
        const mapperOptions = { hour12: useHour12 };
        let formatTimeZone = timeZone;
        if (!formatTimeZone && !useLocalTime) {
            formatTimeZone = DEFAULT_TIME_ZONE;
        }
        merge(mapperOptions, { timeZone: formatTimeZone });
        const options = formatMapper(dateTimeFormat, mapperOptions);
        if (!options) {
            log.warn('Format is not supported', dateTimeFormat);
        }
        return DateTimeFormatter.getFormatter(currentLocale, options).format(dateToFormat);
    }

    /**
     * Format a number of seconds to the specified format.
     * <br>
     * NOTE! If seconds are not included in the format, the final token value
     * will be truncated, eg: format='m' and sec=119 => '1'.
     *
     * @param {number} sec - Number of seconds.
     * @param {object} userSettings - User settings.
     * @param {string} [format='mm:ss'] - String of tokens that specifies the format.
     *
     * @returns {string} The formatted number of seconds.
     */
    static formatDuration(sec, userSettings, format = DURATION.MINUTES_SECONDS) {
        // Making seconds absolute in case of negative
        const absSec = Math.abs(sec);
        const language = selectLanguage();
        const { countryCode, locales } = userSettings;
        const currentLocale = !isEmpty(locales)
            ? locales
            : DateTimeFormatter.generateLocale(language, countryCode);

        const { literal: delimiter } = getFormattingDelimiter(currentLocale, format);

        const seconds = Math.floor(absSec % 60);
        const minutes = format.includes('HH')
            ? Math.floor((absSec % 3600) / 60)
            : Math.floor(absSec / 60);
        const hours = Math.floor(absSec / 3600);

        const formattingOptions = {
            [DURATION.SECONDS]: prependZero(sec),
            [DURATION.MINUTE]: `${minutes}'`,
            [DURATION.MINUTES_SECONDS]: prependZeros([minutes, seconds], delimiter),
            [DURATION.HOURS_MINUTES_SECONDS]: prependZeros([hours, minutes, seconds], delimiter),
        };
        return formattingOptions[format];
    }

    /**
     * Generates a locale string from a given language and country code. The country code is ignored if it's a regional language.
     * <br>
     * A regional language follows the BCP 47 format: 'xx-YY', where 'xx' is the language code and 'YY' is the country code
     * (e.g., 'en-GB'). If the 'countryCode' is not provided, the function will return the supplied language as the result.
     *
     * @param {string} language - The two-letter language code.
     * @param {string} [countryCode] - An optional two-letter country code.
     * @returns {string} The generated locale string.
     * @private
     */
    static generateLocale(language, countryCode) {
        if (countryCode && !StringUtils.isRegionalLanguage(language)) {
            return `${language}-${countryCode}`;
        }
        return language;
    }

    /**
     * Returns an Intl.DateTimeFormat object based on the locale and options parameters.
     *
     * @param {(string|string[])} locale - A string with a BCP 47 language tag, or an array of such strings.
     * @param {object} [options = {}] - Object with options according to the Intl.DateTimeFormat documentation.
     *
     * @returns {any} Intl.DateTimeFormat object.
     * @private
     */
    static getFormatter(locale, options = {}) {
        const key = `${locale}-${Object.entries(options).toString()}`;
        if (!dateFormatMap.has(key)) {
            dateFormatMap.set(key, new Intl.DateTimeFormat(locale, options));
        }
        return dateFormatMap.get(key);
    }
}

export default DateTimeFormatter;
