import { omit, isEmpty } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';
import ItemListProvider from './provider';
import ItemListDataDescriptor from './descriptor';
import ServicesTypes from 'services/common/types.js';
import channelFactory from 'services/common/content/content-channel-factory.js';

const reduxInstance = MojitoCore.Services.redux;
const { actionsRegistry } = MojitoCore.Services.Content;
const {
    CONTENT_STATE: { PENDING, AVAILABLE, UNSUBSCRIBED, UNAVAILABLE },
} = ServicesTypes;
export const getItemListChannel = () =>
    channelFactory.getChannel(ItemListProvider, ItemListDataDescriptor.DATA_TYPES.ITEM_LIST);
/**
 * Defines the state of the item list store.
 *
 * @typedef ItemListState
 *
 * @property {object} itemLists - Map of item lists.
 * @property {Map<string, Mojito.Services.Common.types.CONTENT_STATE>} itemsState - Map of loading states for every requested list.
 *
 * @memberof Mojito.Services.ItemList
 */

/**
 * The name of the item list store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.ItemList
 */

export const STORE_KEY = 'itemListStore';

export const INITIAL_STATE = {
    itemLists: {},
    itemsState: {},
};

export const { reducer, actions } = createSlice({
    name: 'itemList',
    initialState: INITIAL_STATE,
    reducers: {
        updateItemList(state, { payload }) {
            const { items, listId } = payload;
            state.itemLists[listId] = items;
            state.itemsState[listId] = Array.isArray(items) ? AVAILABLE : UNAVAILABLE;
        },
        removeItemList(state, { payload }) {
            const { listIds = [] } = payload;
            state.itemLists = omit(state.itemLists, listIds);
            listIds.forEach(listId => (state.itemsState[listId] = UNSUBSCRIBED));
        },
        pendingItemLists(state, { payload }) {
            const { listIds = [] } = payload;
            listIds.forEach(listId => (state.itemsState[listId] = PENDING));
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Item list actions.
 *
 * @class ItemListActions
 * @name actions
 * @memberof Mojito.Services.ItemList
 */

/**
 * Update item list.
 *
 * @function updateItemList
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{ id: string, listId: object }} payload - Payload for update item list.
 * @memberof Mojito.Services.ItemList.actions
 */

/**
 * Remove items list.
 *
 * @function removeItemList
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload for items lists removal.
 * @memberof Mojito.Services.ItemList.actions
 */

/**
 * Pending container items.
 *
 * @function pendingItemLists
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload contains the id's of pending items lists.
 * @memberof Mojito.Services.ItemList.actions
 */

/**
 * Reset item list state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.ItemList.actions
 */

/**
 * Subscribe to item list.
 *
 * @function subscribeItemList
 *
 * @param {{clientId: string, clientId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to item list thunk.
 * @memberof Mojito.Services.ItemList.actions
 */
actions.subscribeItemList = payload => {
    return dispatch => {
        const { clientId, listId } = payload;
        const onData = (listId, data) => {
            const items = data?.itemIds.map(item => item.id);
            dispatch(actions.updateItemList({ listId, items }));
        };
        const pendingListIds = getItemListChannel().subscribe([listId], clientId, onData);
        !isEmpty(pendingListIds) && dispatch(actions.pendingItemLists({ listIds: pendingListIds }));
    };
};

/**
 * Unsubscribe client from item list.
 *
 * @function unsubscribeItemList
 *
 * @param {string} clientId - The id of subscriber which aims to be unsubscribed.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from item list thunk.
 * @memberof Mojito.Services.ItemList.actions
 */
actions.unsubscribeItemList = clientId => {
    return dispatch => {
        getItemListChannel().unsubscribeAll(clientId, listIds => {
            dispatch(actions.removeItemList({ listIds }));
        });
    };
};

const { subscribeItemList, unsubscribeItemList } = actions;
actionsRegistry.addSubscription(
    ItemListDataDescriptor.DATA_TYPES.ITEM_LIST,
    subscribeItemList,
    unsubscribeItemList
);

reduxInstance.injectReducer(STORE_KEY, reducer);
