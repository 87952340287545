import userInfoService from 'services/user-info/service/user-info-service.js';
import PollBalanceTask from './poll-balance-task';
import AbstractBalanceService from './abstract-balance-service.js';
import TaskRunner from 'services/common/task-runner/task-runner.js';

const DEFAULT_UPDATE_INTERVAL = 10 * 1000;

/**
 * User info balance service responsible for balance update. Continuously polls balance from user service.
 *
 * @class BalanceService
 * @extends Mojito.Services.UserInfo.Balance.AbstractBalanceService
 * @memberof Mojito.Services.UserInfo.Balance
 */
export class BalanceService extends AbstractBalanceService {
    /**
     * Constructor.
     *
     * @param {object} params - Service parameters.
     * @param {Mojito.Services.UserInfo.service} params.userInfoService - User info service.
     *
     * @function Mojito.Services.UserInfo.Balance.BalanceService#constructor
     */
    constructor(params) {
        super();
        this.userInfoService = params.userInfoService;
    }

    /**
     * Method to configure instance of BalanceService.
     *
     * @param {object} config - Balance service configuration.
     * @param {number} [config.balanceUpdateIntervalSeconds] - Interval in seconds between balance update request.
     *
     * @returns {object} BalanceService instance.
     *
     * @function Mojito.Services.UserInfo.Balance.BalanceService#configure
     */
    configure(config) {
        this.balanceUpdateInterval = config.balanceUpdateIntervalSeconds
            ? config.balanceUpdateIntervalSeconds * 1000
            : DEFAULT_UPDATE_INTERVAL;
        this.balanceTaskRunner = new TaskRunner(this.balanceUpdateInterval);

        return this;
    }

    /**
     * Request additional balance update when subscription exist.
     *
     * @param {string} currency - User currency code.
     *
     * @throws {Error} Will throw an error if there is no subscription.
     * @function Mojito.Services.UserInfo.Balance.BalanceService#fetchBalance
     */
    fetchBalance(currency) {
        super.fetchBalance();
        this.balanceTask.setCurrency(currency);
        this.isSubscribed && this.balanceTaskRunner.run(this.balanceTask);
    }

    /**
     * Initiate balance pooling.
     *
     * @param {string} currency - User currency code.
     * @param {Function} successCallback - Success callback function that will be called when balance is received.
     * @param {Function} failedCallback - Failed callback function that will be called when balance is failed.
     *
     * @throws Will throw an error when subscription already exist.
     * @function Mojito.Services.UserInfo.Balance.BalanceService#subscribeToBalanceUpdates
     */
    subscribeToBalanceUpdates(currency, successCallback, failedCallback) {
        super.subscribeToBalanceUpdates();
        this.balanceTask = new PollBalanceTask(
            this.userInfoService,
            successCallback,
            failedCallback
        );
        this.balanceTask.setCurrency(currency);
        this.balanceTaskRunner.run(this.balanceTask);
    }

    /**
     * Stops balance polling.
     *
     * @function Mojito.Services.UserInfo.Balance.BalanceService#unsubscribeFromBalanceUpdates
     */
    unsubscribeFromBalanceUpdates() {
        super.unsubscribeFromBalanceUpdates();
        this.balanceTaskRunner.reset();
        this.balanceTask = undefined;
    }
}

export default new BalanceService({ userInfoService });
