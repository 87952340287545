/**
 * Class defining generic event group data descriptors.
 *
 * @class EventGroupsDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.EventGroups
 */
export default class EventGroupsDataDescriptor {
    /**
     * Factory method for creating a generic event group descriptor.
     *
     * @param {string} eventGroupId - Id of event group.
     *
     * @returns {{dataType: string, eventGroupIds: Array<string>}} Generic event group descriptor.
     *
     * @function Mojito.Services.EventGroups.descriptor#createEventGroupDescriptor
     * @static
     */
    static createEventGroupDescriptor(eventGroupId) {
        return EventGroupsDataDescriptor.createEventGroupsDescriptor([eventGroupId]);
    }

    /**
     * Factory method for creating a generic event group descriptor for multiple event groups.
     *
     * @param {Array<string>} eventGroupIds - Ids of event groups.
     *
     * @returns {{dataType: string, eventGroupIds: Array<string>}} Generic event group descriptor.
     *
     * @function Mojito.Services.EventGroups.descriptor#createEventGroupsDescriptor
     * @static
     */
    static createEventGroupsDescriptor(eventGroupIds) {
        return {
            dataType: EventGroupsDataDescriptor.DATA_TYPES.EVENT_GROUP,
            eventGroupIds,
        };
    }

    /**
     * Factory method for creating multi event groups data descriptor.
     *
     * @param {Array<object>} requests - The list of event groups request objects.
     *
     * @returns {{dataType: string, requests: Array<object>}} Event groups data descriptor.
     *
     * @function Mojito.Services.EventGroups.descriptor.createEventGroupsDescriptorChunk
     * @static
     */
    static createEventGroupsDescriptorChunk(requests) {
        return {
            dataType: EventGroupsDataDescriptor.DATA_TYPES.EVENT_GROUP_CHUNK,
            requests,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} EVENT_GROUP - Event group data descriptor type.
 * @memberof Mojito.Services.EventGroups.descriptor
 */
EventGroupsDataDescriptor.DATA_TYPES = {
    EVENT_GROUP: 'event-group',
    EVENT_GROUP_CHUNK: 'event-group-chunk',
};
