import AbstractTask from 'services/common/task-runner/abstract-task.js';

/**
 * Retrieves balance continuously.
 *
 * @class PollBalanceTask
 * @memberof Mojito.Services.UserInfo.Balance
 */
export default class PollBalanceTask extends AbstractTask {
    constructor(service, responseSuccessHandlerFn, responseFailedHandlerFn) {
        super();
        this.service = service;
        this.responseSuccessHandlerFn = responseSuccessHandlerFn;
        this.responseFailedHandlerFn = responseFailedHandlerFn;
        this.currency = undefined;
    }

    setCurrency(value) {
        this.currency = value;
    }

    execute(callback) {
        const started = this.service
            .getBalance(this.currency)
            .then(data => {
                const { balances } = data || {};
                this.responseSuccessHandlerFn(balances);
                callback();
            })
            .catch(error => {
                this.responseFailedHandlerFn(error);
                callback();
            });

        if (!started) {
            callback();
        }
    }
}
