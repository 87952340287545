import { Children } from 'react';

/**
 * Utility class for React operations.
 *
 * @class ReactUtils
 * @memberof Mojito.Core.Base
 */
export default class ReactUtils {
    /**
     * Validation of a conditionally required prop.
     *
     * @param {Function} type - The type of the prop.
     * @param {Function} condition - Condition for the prop to be valid.
     *
     * @returns {Function} Validation function.
     * @function Mojito.Core.Base.ReactUtils#requiredIf
     */
    static requiredIf(type, condition) {
        return (...args) => {
            const [props, propName, componentName] = args;

            if (typeof type !== 'function') {
                return new Error(
                    `Invalid required-if prop type for prop ${propName} supplied to ${componentName}. Validation failed.`
                );
            }

            if (typeof condition !== 'function') {
                return new Error(
                    `Invalid required-if condition supplied for prop ${propName} to ${componentName}. Validation failed.`
                );
            }

            const test = condition(props) ? type.isRequired : type;
            return test.apply(this, args);
        };
    }

    /**
     * This function checks if the node or any of its children is not a function, meaning it has
     * already been rendered.
     *
     * @param {*} node - A React node.
     * @returns {boolean} True if rendered.
     *
     * @function Mojito.Core.Base.ReactUtils#hasRenderedSomething
     */
    static hasRenderedSomething(node) {
        return node.type instanceof Function
            ? Children.toArray(node.props.children).some(ReactUtils.hasRenderedSomething)
            : true;
    }
}
