import AbstractVideoStreamingService from './abstract-video-streaming-service.js';

/**
 * Singleton object that receives video streaming service config and spawn service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.VideoStreaming
 */
class ServiceFactory {
    /**
     * Init factory.
     *
     * @function init
     * @param {object} config - Video streaming service config.
     * @param {Mojito.Services.VideoStreaming.AbstractVideoStreamingService} config.service - Video streaming service implementation.
     * @param {string} config.serviceUrl - Streaming endpoint URL.
     * @memberof Mojito.Services.VideoStreaming.serviceFactory
     */
    init(config = {}) {
        const { service, serviceUrl } = config;
        this.service = service || new AbstractVideoStreamingService();
        this.service.configure({ serviceUrl });
    }

    /**
     * Service getter.
     *
     * @function getService
     * @returns {Mojito.Services.VideoStreaming.AbstractVideoStreamingService} Video streaming service instance.
     * @memberof Mojito.Services.VideoStreaming.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
