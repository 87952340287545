import PropTypes from 'prop-types';
import MarketImpl from './market.jsx';
import MojitoCore from 'mojito/core';
import { resolveSelectionGroupsByType } from './helper/market-helper';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View to visualize a market.
 *
 * @class Market
 * @memberof Mojito.Modules
 */

/**
 * Market prop types.
 *
 * @property {object} event - The event item containing this market.
 * @property {object} [market] - Market(s) parent event item.
 * @property {boolean} [hideHeader = true] - Set to true if the view initially should be expanded.
 * @property {string} headerl10nKey - Translation key for header label.
 * @property {Array<string>} [selectionTypes] - List of Selection types.
 * @property {Array<Array>} [selectionGroups] - List of selection groups. Each item contains the list of selections which belongs to selections group, typically, rendered in a column. If this property is set then selectionGroupsResolver is not used.
 * @property {Mojito.Modules.Market.types.selectionsGroupResolver} [selectionGroupsResolver = Mojito.Modules.MarketHelper.resolveSelectionGroupsByType] - Function that will be used to resolve selection groups within particular child market. This property will be ignored if selectionGroups is set.
 * @property {Mojito.Modules.SelectionButton.types.NAME_DISPLAY} [nameDisplay = undefined] - Display type to use a for the selection name.
 * @property {Function} [onDirectionChange = () => {}] - Callback fired when the layout direction is changed.
 *
 * @memberof Mojito.Modules.Market
 */
const propTypes = {
    event: PropTypes.object.isRequired,
    market: PropTypes.object,
    hideHeader: PropTypes.bool,
    headerl10nKey: PropTypes.string,
    selectionTypes: PropTypes.array,
    selectionGroups: PropTypes.array,
    selectionGroupsResolver: PropTypes.func,
    nameDisplay: PropTypes.string,
    isPending: PropTypes.bool,
    onDirectionChange: PropTypes.func,
};

const defaultProps = {
    hideHeader: true,
    selectionGroupsResolver: resolveSelectionGroupsByType,
    isPending: false,
    onDirectionChange: noop,
};

export default UIView('Market', MarketImpl, propTypes, defaultProps);
