import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import RoutingControllerImpl from './routing-controller.jsx';

const { UIView } = MojitoCore.Presentation;

/**
 * Module for RoutingController that handles the application's routing functionality.
 *
 * @class RoutingController
 * @memberof Mojito.Application
 */
/**
 * RoutingController prop types.
 *
 * @property {Array} [customRoutes] - An array of objects each defining a custom route to be added to the routing map.
 *
 * @memberof Mojito.Application.RoutingController
 */
const propTypes = {
    customRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            component: PropTypes.func,
        })
    ),
};

export default UIView('RoutingController', RoutingControllerImpl, propTypes, {});
