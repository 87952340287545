import { useMemo, useState } from 'react';
import MojitoCore from 'mojito/core';
import { ruleTemplates } from './text-input-style';

const { merge } = MojitoCore.Base.objUtils;
const { useCssRule } = MojitoCore.Presentation.Hooks;
const classUtils = MojitoCore.Base.classUtils;
const log = MojitoCore.logger.get('TextInput');
const INPUT_TYPES = ['text', 'number', 'tel', 'password'];

const TextInput = props => {
    const {
        inputParams,
        mojitoTools: { config, style },
        inputId,
        inputElementRef,
        value,
        placeholderValue,
        disabled,
    } = props;

    const [isHovered, setIsHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);

    const onChange = event => {
        props.onChange(event.target.value, event);
    };

    const onFocus = event => {
        setIsFocused(true);
        props.onFocus(event.target.value, event);
    };

    const onBlur = event => {
        setIsFocused(false);
        props.onBlur(event.target.value, event);
    };

    const onKeyDown = event => {
        props.onKeyDown(event.target.value, event);
    };

    const onKeyUp = event => {
        if (props.onKeyEnter && event.key === 'Enter') {
            props.onKeyEnter(event.target.value, event);
        }

        if (props.onKeyUp) {
            props.onKeyUp(event.target.value, event);
        }
    };

    const onSelect = event => {
        props.onSelect(event.target.value, event);
    };

    const getTextInputStyle = () => {
        const { base, focus, disabled, hover, error } = style;

        if (props.disabled) {
            return disabled;
        }

        if (props.hasError) {
            return error;
        }

        if (isFocused) {
            return focus;
        }

        if (isHovered) {
            return hover;
        }

        return base;
    };

    const classUUID = useMemo(() => classUtils.createClassUUID(config.class), [config.class]);
    const cssVars = useMemo(() => getCssVariables(config, classUUID), [config, classUUID]);
    useCssRule(classUUID, cssVars, ruleTemplates);

    return (
        <input
            {...inputParams}
            {...filteredType(config)}
            className={classUtils.classNames('ta-TextInput', config.class, props.class, classUUID)}
            id={inputId}
            ref={inputElementRef}
            style={getTextInputStyle()}
            value={value}
            placeholder={placeholderValue}
            disabled={disabled}
            maxLength={config.maxLength}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onSelect={onSelect}
        />
    );
};

TextInput.getStyle = function (config) {
    const { focus, hover, disabled, error } = config.style;

    const base = merge(
        {
            margin: 0, // Required due to the non-standard Safari's default input margin:2px.
            appearance: 'none',
            boxSizing: 'border-box',
            width: '100%', // To be able to flex properly
        },
        config.style.base
    );

    return {
        base,
        focus: merge(base, focus),
        hover: merge(base, hover),
        disabled: merge(base, disabled),
        error: merge(base, error),
    };
};

function getCssVariables(config, classUUID) {
    const { placeholder } = config.style;
    const uniqueClassSegment = classUUID ? `.${classUUID}` : '';
    return {
        '%class%': `.${config.class}${uniqueClassSegment}`,
        '%type%': config.type,
        '%placeholder%': getCssProperties(placeholder),
    };
}

function getCssProperties(config) {
    return Object.entries(config || {})
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n');
}

const filteredType = config => {
    const { type, inputMode } = config;

    if (INPUT_TYPES.includes(type)) {
        return { type, inputMode };
    }

    log.warn(`The configured type "${type}" is invalid.`);
    return {};
};

export default TextInput;
