import PropTypes from 'prop-types';
import Hooks from 'core/presentation/hooks';
import ComponentRenderer from 'core/presentation/component-renderer';
import UIViewUtils from 'core/presentation/ui-view/utils';
import { useMemo } from 'react';

/**
 * UIControllerView HoC function is used to wrap component implementation
 * with basic mojito functionality and tooling and provides
 * specific data loading functionality with `controllerHelper` object that will be passed down
 * to implementation through props.
 *
 * @function UIControllerView
 * @memberof Mojito.Core.Presentation
 *
 * @param {string} name - ControllerView name.
 * @param {object} defaultImpl - Default implementation class.
 * @param {object} [propTypes] - ControllerView prop types.
 * @param {object} [defaultProps = {}] - ControllerView default prop values.
 * @param {Function} [loadingView] - Loading view component will be shown for suspense implementations.
 *
 * @returns {Function} MojitoController function.
 */
export default function UIControllerView(
    name,
    defaultImpl,
    propTypes,
    defaultProps = {},
    loadingView = undefined
) {
    const mojitoPropTypes = {
        ...propTypes,
        config: PropTypes.object,
    };

    const MojitoController = props => {
        const { implementation: Implementation } = props;
        const componentProps = UIViewUtils.ensureDefaultProps(props, defaultProps);
        const mojitoTools = Hooks.useComponentTools(
            name,
            props.config,
            Implementation,
            Hooks.useAppContext(),
            Hooks.useEmitPerformanceMark()
        );
        const controllerHelper = Hooks.useControllerHelper(mojitoTools.instanceId);
        Hooks.usePropTypesValidation(componentProps, mojitoPropTypes, name);
        const controllerTools = useMemo(
            () => ({ ...mojitoTools, controllerHelper }),
            [controllerHelper, mojitoTools]
        );

        return <Implementation {...componentProps} mojitoTools={controllerTools} />;
    };

    const ComponentWrapper = props => {
        return (
            <ComponentRenderer name={name} defaultImpl={defaultImpl} loadingView={loadingView}>
                {impl => <MojitoController {...props} implementation={impl} />}
            </ComponentRenderer>
        );
    };

    ComponentWrapper.propTypes = mojitoPropTypes;
    ComponentWrapper.displayName = `${name}UIControllerWrapper`;

    return ComponentWrapper;
}
