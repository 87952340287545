import {merge} from 'core/utils/config-utils';
import {PALETTE_IDS} from 'core/application/modules/ids';
import {COLOR, FONT} from './theme';
import {getBaseComponentsConfig} from './style/base-components';
import {getOnboardingConfig} from './style/onboarding';
import {getPageHeadersConfigs} from './style/interface';
import {getDialogConfig} from './style/dialogs';
import {getMenuListsConfigs} from './style/menus-links-lists';
import {getStaticPagesConfig} from './style/static-pages';
import {getVirtualsConfig} from './style/virtuals';
import {getMatchAccaConfig} from './style/matchacca';
import {getBonusesConfig} from './style/bonuses';
import {getRacingConfig} from './style/racing';
import {getBetHistoryConfig} from './style/bethistory';
import {getAccountConfigs} from './style/account';
import {getCardsConfig} from './style/cards';
import {getMarketsConfig} from './style/markets';
import {getBettingCenterConfig} from './style/betting-center';
import {getSportsContentConfigs} from './style/sports-content';

export function getTokens() {
    const palette = {
        [PALETTE_IDS.FONT]: FONT,
        [PALETTE_IDS.APP_VIEW]: {
            APPLICATION_PAGE_TEXT: {
                color: COLOR.BRAND.FOREGROUND5,
                fontSize: FONT.SIZE_XSMALL,
            },
            ACCA_BAR_BG: COLOR.BASE.PAGE,
        },
        [PALETTE_IDS.MOBILE_NAVBAR]: {
            DOT_NOTIFICATION: {
                DOT_COLOR: COLOR.BRAND.ACCENT1,
            },
            CONTAINER_BG: COLOR.BRAND.BACKGROUND7,
            ICON_COLOR: COLOR.BRAND.FOREGROUND5,
            LABEL_TEXT: {
                color: COLOR.BRAND.FOREGROUND3,
                textTransform: 'uppercase',
            },
        },
        [PALETTE_IDS.SEARCH_RESULT]: {
            SEARCH_RECENT_ITEM_ICON_COLOR: COLOR.BRAND.FOREGROUND3,
            SEARCH_RECENT_ITEM_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: FONT.WEIGHT_NORMAL,
            },
            SEARCH_RECENT_ITEM_REMOVE_ICON_COLOR: COLOR.BRAND.FOREGROUND1,

            SEARCH_RESULT_ITEM_ICON_COLOR: COLOR.BRAND.FOREGROUND3,
            SEARCH_RESULT_ITEM_TOURNAMENT_TEXT: {
                color: COLOR.BRAND.FOREGROUND2,
                fontWeight: FONT.WEIGHT_NORMAL,
            },
            SEARCH_RESULT_ITEM_NAME_TEXT: {
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
                color: COLOR.BRAND.FOREGROUND1,
            },
            SEARCH_RESULT_ITEM_STATUS_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: FONT.WEIGHT_NORMAL,
            },
        },
        [PALETTE_IDS.SEARCH]: {
            SEARCH_OVERLAY_BG: COLOR.BRAND.BACKGROUND4,
        },
        [PALETTE_IDS.SCOREBOARDS]: {
            SCOREBOARD_CONTAINER_BG: COLOR.BRAND.BACKGROUND1,
            TIMING_STATUS_LABEL: {
                EVENT_TIMING_LIVE_TEXT: COLOR.BRAND.FOREGROUND2,
                EVENT_TIMING_UPCOMING_TEXT: COLOR.BRAND.FOREGROUND2,
            },
            PRIMARY_COLOR: COLOR.BRAND.BACKGROUND1,
            SCORE_TEXT: COLOR.BRAND.ACCENT1,
            ACCENT_COLOR: COLOR.BRAND.ACCENT3,
            SEGMENT_TEXT: {
                color: COLOR.BRAND.FOREGROUND2,
                fontWeight: 500,
            },
            SEGMENT_TOTAL_TEXT: COLOR.BRAND.ACCENT1,
            PARTICIPANT_NAME_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: 500,
            },
            SEGMENT_HEADER_TEXT: {
                color: COLOR.BRAND.FOREGROUND3,
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
            },
            SEGMENT_TOTAL_HEADER_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
            },
            SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND1,
        },
        [PALETTE_IDS.SKELETON]: {
            SKELETON_COLOR: COLOR.BRAND.BACKGROUND5,
        },
        [PALETTE_IDS.PROMOTIONS]: {
            BANNER_CONTAINER_BG: COLOR.BRAND.BACKGROUND6,
            BANNER_MARKET_EVENT_NAME_TEXT: {
                fontSize: FONT.SIZE_LARGE,
                fontWeight: FONT.WEIGHT_BOLD,
                textTransform: 'uppercase',
            },
        },
        [PALETTE_IDS.CHANGED_VALUE_INDICATOR]: {
            CVI_INDICATION_ICON_COLOR: COLOR.BRAND.ACCENT1,
        },
        [PALETTE_IDS.INFO_NOTIFICATION]: {
            INFO_BG: COLOR.BRAND.FOREGROUND3,
            INFO_ICON_COLOR: COLOR.BRAND.FOREGROUND7,
            INFO_TEXT: {
                color: COLOR.BRAND.FOREGROUND7,
                fontWeight: FONT.WEIGHT_NORMAL,
            },
            INFO_BORDER_COLOR: COLOR.BRAND.BACKGROUND7,
        },
        [PALETTE_IDS.ERROR_NOTIFICATION]: {
            ERROR_TEXT: {
                fontWeight: FONT.WEIGHT_NORMAL,
                color: COLOR.RESERVED.BLACK,
            },
        },
        [PALETTE_IDS.WARNING_NOTIFICATION]: {
            WARNING_TEXT: {
                fontWeight: FONT.WEIGHT_NORMAL,
                color: COLOR.RESERVED.BLACK,
            },
        },
        [PALETTE_IDS.SUCCESS_NOTIFICATION]: {
            SUCCESS_TEXT: {
                fontWeight: FONT.WEIGHT_NORMAL,
                color: COLOR.RESERVED.BLACK,
            },
        },
        [PALETTE_IDS.EMPTY_CONTENT_MESSAGE]: {
            EMPTY_CONTENT_MESSAGE_TEXT: COLOR.BRAND.FOREGROUND2,
        },
        [PALETTE_IDS.VIDEO_STREAMING]: {
            VIDEO_STREAMING_CONTAINER_BG: COLOR.RESERVED.BLACK,
            VIDEO_STREAMING_STATUSBAR_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: FONT.WEIGHT_NORMAL,
            },
            VIDEO_STREAMING_MESSAGES_TEXT: {
                color: COLOR.BRAND.FOREGROUND1,
                fontWeight: FONT.WEIGHT_NORMAL,
            },
        },
    };

    const viewsConfig = {
        AppView: {
            style: {
                mainContainer: {
                    backgroundImage: 'generic/bg_pattern.svg',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                },
            },
        },
        AccaBarView: {
            style: {
                bottom: {
                    visible: 'calc(106px + env(safe-area-inset-bottom, 0px))', // TODO temp fix, remove on MOJ-14219
                },
            },
        },
    };

    const tokens = merge(
        {
            custom: {
                [PALETTE_IDS.APP_VIEW]: viewsConfig,
            },
            palette,
        },
        getBaseComponentsConfig(),
        getAccountConfigs(),
        getMenuListsConfigs(),
        getBetHistoryConfig(),
        getBettingCenterConfig(),
        getBonusesConfig(),
        getCardsConfig(),
        getDialogConfig(),
        getMarketsConfig(),
        getMatchAccaConfig(),
        getOnboardingConfig(),
        getPageHeadersConfigs(),
        getRacingConfig(),
        getStaticPagesConfig(),
        getVirtualsConfig(),
        getSportsContentConfigs()
    );

    return tokens;
}
