import PropTypes from 'prop-types';
import AbstractAnalyticsEmitter from 'core/presentation/abstract-analytics-emitter';
import AbstractRouteResolver from 'core/base/routing/abstract-route-resolver';

/**
 * This class defines the Context that should be provided in the root UIControllerView.
 * Although the Context isn't a concrete object in Mojito Core, this documentation highlights
 * the necessary functions for the context object in the root UIView.
 * <br><br>
 * The context is passed to all UIView's in the component tree. For proper operation, UIView's
 * based on Mojito.Core expect the context to provide certain functionalities. It's the
 * Mojito.Core user's responsibility to supply a context that meets these needs.
 * <br><br>
 * Be aware that higher Mojito layers may extend the AppContextDefinition here and demand more from the context.
 *
 * @class AppContextDefinition
 * @property {Mojito.Core.Presentation.AbstractAnalyticsEmitter} [analyticsEmitter] - An instance of {@link Mojito.Core.Presentation.AbstractAnalyticsEmitter|AbstractAnalyticsEmitter}. This is used to emit analytics data from UI components.
 * @memberof Mojito.Core.Presentation.AppContext
 */
export default class AppContextDefinition {
    /**
     * Get the current system settings.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#systemSettings
     * @returns Map of current system settings.
     */

    /**
     * Get image data/url from the specified path.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#getImage
     * @param {string} path - Image path.
     * @returns {string}
     */

    /**
     * Get fixture data/url from the specified path.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#getFixture
     * @param {string} path - Fixture path.
     * @returns {string}
     */

    /**
     * Checks if the content specified is currently shown.
     * Typically used when figuring out if the content of a 'VIEW' intent
     * is already visible.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#isContentShown
     * @param {object} navigationPayload - Content navigation payload.
     * @param {boolean} strict - True if match path of the content should be performed strictly.
     * @returns {boolean} True if content is shown, otherwise false.
     */

    /**
     * Retrieves the path for determining the current context in the configuration system.
     * <br><br>
     * This is typically used to return the current applicationMode, such as "_mobile".
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#uiContextPath
     * @returns {string} The current uiContextPath.
     */

    /**
     * Get the current user settings.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#userSettings
     * @returns Map of current user settings.
     */

    /**
     * Get the name of the specified sport.
     *
     * @param {string} sportCode
     * @returns {string}
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#sportName
     */

    /**
     * Get logged in state.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#getLoginState
     * @returns {Mojito.Services.Authentication.types.STATES} The current user login state.
     */

    /**
     * Get route resolver instance.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#routeResolver
     * @returns {Mojito.Core.Base.AbstractRouteResolve} The instance of concrete route resolver implementation.
     */

    /**
     * Get performance emitter instance.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#performanceEmitter
     * @returns {Mojito.Application.PerformanceEmitter} The instance of performance emitter.
     */

    /**
     * Gets the current pathname.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#pathname
     * @returns {string} The current pathname.
     */

    /**
     * Function to trigger an application wide overlay interaction.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#overlayInteraction
     */

    /**
     * Get suspense fallback React element. This will be used by {@link Mojito.Core.Presentation.UIView|UIView} HoC to render a fallback content (such as a loading indicator) while component is waiting for the lazy implementation to load.
     * Only applicable if provided component implementation is imported using {@link https://reactjs.org/docs/code-splitting.html#reactlazy|React.lazy} function.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#suspenseFallback
     * @returns {Function} The reference to react component type.
     */

    /**
     * Get error fallback React element. This will be used by {@link Mojito.Core.Presentation.UIView|UIView} HoC to render a fallback content (such as error notification) if component chunk failed to load or in case of exception inside suspended component.
     * Only applicable if provided component implementation is imported using {@link https://reactjs.org/docs/code-splitting.html#reactlazy|React.lazy} function.
     *
     * @function Mojito.Core.Presentation.AppContext.ContextDefinition#errorFallback
     * @returns {Function} The reference to react component type.
     */

    /**
     * This function returns the React PropTypes required by UIView to be in the context.
     *
     * @function
     * @static
     * @memberof Mojito.Core.Presentation.AppContext.ContextDefinition
     * @returns {object} Object containing the React PropTypes for the required context.
     */
    static propTypes() {
        return {
            systemSettings: PropTypes.func,
            getImage: PropTypes.func,
            getFixture: PropTypes.func,
            isContentShown: PropTypes.func,
            uiContextPath: PropTypes.func,
            userSettings: PropTypes.func,
            sportName: PropTypes.func,
            getLoginState: PropTypes.func,
            analyticsEmitter: PropTypes.instanceOf(AbstractAnalyticsEmitter),
            routeResolver: PropTypes.instanceOf(AbstractRouteResolver),
            suspenseFallback: PropTypes.func,
            errorFallback: PropTypes.func,
            overlayInteraction: PropTypes.func,
            performanceEmitter: PropTypes.object,
            pathname: PropTypes.string,
        };
    }
}
