import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

const { FlexPane, Button, Image, Text, DateTime } = MojitoPresentation.Components;
const { Types: IntentTypes } = MojitoPresentation.Base.Intent;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { dispatch } = MojitoCore.Services.redux.store;
const intentActions = MojitoCore.Intents.actions;
const { DateTimeUtils } = MojitoCore.Base;
const {
    DateTimeFormatter,
    DateTimeTypes: { DATE_TIME_FORMAT },
} = MojitoPresentation.Utils.DateTime;
const { RESULT_TYPE } = MojitoServices.Search.types;
const { EventFormatter } = MojitoPresentation.Utils;

const log = MojitoCore.logger.get('SearchResultItem');

const renderersByResultType = {
    [RESULT_TYPE.SPORT]: renderSport,
    [RESULT_TYPE.TYPE]: renderType,
    [RESULT_TYPE.EVENT]: renderEvent,
    [RESULT_TYPE.RACE_MEETING]: renderRaceMeeting,
};

export default function SearchResultItem(props) {
    const {
        sportId,
        resultType,
        afterRedirection,
        raceMeetingId,
        mojitoTools: { config, stringResolver, appContext },
    } = props;

    if (!renderersByResultType[resultType]) {
        log.error(`Unexpected resultType ${resultType} in SearchResultItem`);
        return null;
    }

    const icon = config.sportIcons[sportId] || config.sportIcons.defaultIcon;
    const routeResolver = appContext.routeResolver;
    const navigationData = getHrefNavigationData(props);
    const hrefLink = routeResolver.getRoute(navigationData);
    const component = {
        renderNames,
        renderRacingNames,
        renderName,
        renderLivePane,
        renderDateTimePane,
    };

    const renderer =
        resultType === RESULT_TYPE.EVENT && raceMeetingId
            ? renderRaceEvent
            : renderersByResultType[resultType];

    return (
        <Button
            config={config.button}
            class={'ta-searchResultItem'}
            hrefLink={hrefLink}
            onClick={onSearchResultClick}
        >
            <Image src={icon} config={config.searchItemIcon} />
            {renderer(component, props)}
        </Button>
    );

    function getNameWithClass(name) {
        return stringResolver.resolveAndFormatString(
            '$SEARCH_RESULT_ITEM.TYPE_NAME_WITH_CLASS_NAME',
            name,
            props.sportClassName
        );
    }

    function getParentName() {
        return resultType === RESULT_TYPE.EVENT && !raceMeetingId
            ? getNameWithClass(props.parentName)
            : props.parentName;
    }

    function getName() {
        switch (resultType) {
            case RESULT_TYPE.RACE_MEETING:
                return EventFormatter.formatEventName(props.name);
            case RESULT_TYPE.TYPE:
                return getNameWithClass(props.name);
            default:
                return props.name;
        }
    }

    function renderNames() {
        return (
            <FlexPane config={config.searchNamesContainer} key={'names'}>
                <Text config={config.parentText}>{getParentName()}</Text>
                <Text config={config.nameText}>{getName()}</Text>
            </FlexPane>
        );
    }

    function renderRacingNames() {
        const startTime = DateTimeFormatter.formatDateTime(
            props.startTime,
            DATE_TIME_FORMAT.SHORT_TIME,
            appContext.systemSettings().language,
            appContext.userSettings()
        );
        return (
            <FlexPane config={config.searchNamesContainer} key={'names'}>
                <Text config={config.parentText}>{EventFormatter.formatEventName(props.name)}</Text>
                <Text config={config.nameText}>
                    {EventFormatter.formatEventName(props.name, startTime)}
                </Text>
            </FlexPane>
        );
    }

    function renderName() {
        return (
            <FlexPane config={config.searchNamesContainer} key={'name'}>
                <Text config={config.sportNameText}>{props.name}</Text>
            </FlexPane>
        );
    }

    function renderLivePane() {
        return (
            <FlexPane config={config.infoContainer} class="ta-info-section-live" key={'live'}>
                <Text config={config.live}>
                    {stringResolver.resolveString('$SEARCH_RESULT_ITEM.IN_PLAY_STATUS')}
                </Text>
            </FlexPane>
        );
    }

    function renderDateTimePane() {
        return (
            <FlexPane
                config={config.infoContainer}
                class="ta-info-section-datetime"
                key={'datetime'}
            >
                <Text config={config.startDate}>{renderDate()}</Text>
                <Text config={config.startTime}>
                    <DateTime config={config.startTimeFormat} dateTime={props.startTime} />
                </Text>
            </FlexPane>
        );
    }

    function renderDate() {
        const { timeOffset } = appContext.userSettings();
        if (DateTimeUtils.isSameDateWithOffset(new Date(props.startTime), 0, timeOffset)) {
            return stringResolver.resolveString('$SEARCH_RESULT_ITEM.TODAY');
        } else if (DateTimeUtils.isSameDateWithOffset(new Date(props.startTime), 1, timeOffset)) {
            return stringResolver.resolveString('$SEARCH_RESULT_ITEM.TOMORROW');
        }

        return <DateTime config={config.startDateFormat} dateTime={props.startTime} />;
    }

    function onSearchResultClick() {
        dispatch(
            intentActions.publishIntent(IntentTypes.VIEW, {
                ...navigationData,
                isRedirect: false,
            })
        );
        afterRedirection();

        const { emitAnalytics } = appContext.analyticsEmitter;
        emitAnalytics('searchResultsItemClicked', {
            sportId: props.sportId,
            name: props.name,
            parentName: props.parentName,
            resultType: props.resultType,
        });
    }
}

function renderEvent({ renderNames, renderLivePane, renderDateTimePane }, { inPlay }) {
    return [renderNames(), inPlay ? renderLivePane() : renderDateTimePane()];
}

function renderRaceEvent({ renderRacingNames, renderDateTimePane }) {
    return [renderRacingNames(), renderDateTimePane()];
}

function renderType({ renderNames }) {
    return renderNames();
}

function renderSport({ renderName }) {
    return renderName();
}

function renderRaceMeeting({ renderNames, renderDateTimePane }) {
    return [renderNames(), renderDateTimePane()];
}

function getHrefNavigationData({ id, sportId, raceMeetingId, resultType, inPlay, isOutright }) {
    switch (resultType) {
        case RESULT_TYPE.SPORT:
            return NavigationPathBuilder.toSport(sportId);

        case RESULT_TYPE.TYPE:
            return isOutright
                ? NavigationPathBuilder.toSportOutrightsLeague(sportId, id)
                : NavigationPathBuilder.toSportCompetitionsLeague(sportId, id);

        case RESULT_TYPE.EVENT:
            if (raceMeetingId) {
                return NavigationPathBuilder.toRaceEvent(sportId, raceMeetingId, id);
            }
            return inPlay
                ? NavigationPathBuilder.toInplayEvent(id)
                : NavigationPathBuilder.toSportEvent(sportId, id);

        case RESULT_TYPE.RACE_MEETING:
            return NavigationPathBuilder.toSportMeeting(sportId, id);
    }
}
