/**
 * Single league coupon view types.
 *
 * @class SingleLeagueCouponTypes
 * @memberof Mojito.Modules.SingleLeagueCoupon
 */
export default class SingleLeagueCouponTypes {}

/**
 * Coupon view types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.SingleLeagueCoupon.SingleLeagueCouponTypes
 */
SingleLeagueCouponTypes.COUPONS = {
    COMPETITIONS: 'competitions',
    OUTRIGHTS: 'outrights',
};
