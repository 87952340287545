import RaceCardsSkeletonImpl from './race-cards-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Race cards skeleton component used to represent loading race cards.
 *
 * @function RaceCardsSkeleton
 * @memberof Mojito.Modules.RaceCard
 */

export default UIView('RaceCardsSkeleton', RaceCardsSkeletonImpl);
