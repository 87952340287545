import { omit } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';
import ServicesTypes from 'services/common/types.js';
import channelFactory from 'services/common/content/content-channel-factory.js';
import containerProvider from 'services/sports-content/content-provider/container-provider';
import ContainerItemsDescriptor from 'services/sports-content/container-items/descriptor.js';

const reduxInstance = MojitoCore.Services.redux;
const { actionsRegistry } = MojitoCore.Services.Content;
const {
    CONTENT_STATE: { PENDING, AVAILABLE, UNAVAILABLE, UNKNOWN },
} = ServicesTypes;
export const getContainerItemsChannel = () =>
    channelFactory.getChannel(containerProvider, ContainerItemsDescriptor.DATA_TYPE);

/**
 * Defines the state of the containerItems store.
 *
 * @typedef ContainerItemsState
 *
 * @property {object} itemLists - Map of container items lists.
 * @property {object} suffixes - Items suffices map. Typically, used to subscribe to particular class or type by container id.
 * @property {Map<string, Mojito.Services.Common.types.CONTENT_STATE>} itemsState - Map of loading states for every requested list.
 *
 * @memberof Mojito.Services.SportsContent.ContainerItems
 */

/**
 * The name of the containerItems store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.ContainerItems
 */
export const STORE_KEY = 'containerItemsStore';

export const INITIAL_STATE = {
    itemLists: {},
    suffixes: {},
    itemsState: {},
};

export const { reducer, actions } = createSlice({
    name: 'containerItems',
    initialState: INITIAL_STATE,
    reducers: {
        updateItemList(state, { payload }) {
            const { listId, items, suffix } = payload;
            state.itemLists[listId] = items;
            state.suffixes[listId] = suffix;
            state.itemsState[listId] = Array.isArray(items) ? AVAILABLE : UNAVAILABLE;
        },
        removeItemLists(state, { payload }) {
            const { listIds = [] } = payload;
            state.itemLists = omit(state.itemLists, listIds);
            listIds.forEach(listId => (state.itemsState[listId] = UNKNOWN));
        },
        pendingItemLists(state, { payload }) {
            const { listIds } = payload;
            listIds &&
                listIds.forEach(listId => {
                    state.itemsState[listId] = PENDING;
                });
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Container items related actions.
 *
 * @class ContainerItemsActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.ContainerItems
 */

/**
 * Function to update the item list of a container.
 *
 * @function updateItemList
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - The payload for the container item update.
 * @param {string} payload.listId - The identifier for the list.
 * @param {Array} payload.items - The list of items for a particular group.
 * @param {string} payload.suffix - The suffix for the sport class item. This is used when subscribing to a specific sport class and, depending on the backend implementation, could be something like 'class-' or 'c-'.
 * @memberof Mojito.Services.SportsContent.ContainerItems.actions
 */

/**
 * Remove container items lists.
 *
 * @function removeItemLists
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload for container items lists removal.
 * @memberof Mojito.Services.SportsContent.ContainerItems.actions
 */

/**
 * Pending container items.
 *
 * @function pendingItemLists
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload contains the id's of pending container items lists.
 * @memberof Mojito.Services.SportsContent.ContainerItems.actions
 */

/**
 * Reset container items state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.ContainerItems.actions
 */

/**
 * Subscribes to container items by list id.
 *
 * @function subscribeContainerItems
 *
 * @param {{clientId: string, listId: string}} payload - Subscription payload. Contains the subscribed listId and clientId of subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to container items thunk.
 * @memberof Mojito.Services.SportsContent.ContainerItems.actions
 */
actions.subscribeContainerItems = payload => {
    return dispatch => {
        const { clientId, listId } = payload;
        const onData = (listId, data) => {
            dispatch(actions.updateItemList({ listId, ...data }));
        };
        const pendingListIds = getContainerItemsChannel().subscribe([listId], clientId, onData);
        dispatch(actions.pendingItemLists({ listIds: pendingListIds }));
    };
};

/**
 * Unsubscribe from container items.
 *
 * @function unsubscribeContainerItems
 *
 * @param {string} clientId - The clientId of subscriber to unsubscribe.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from container items thunk.
 * @memberof Mojito.Services.SportsContent.ContainerItems.actions
 */
actions.unsubscribeContainerItems = clientId => {
    return dispatch => {
        getContainerItemsChannel().unsubscribeAll(clientId, listIds => {
            dispatch(actions.removeItemLists({ listIds }));
        });
    };
};

const { subscribeContainerItems, unsubscribeContainerItems } = actions;
actionsRegistry.addSubscription(
    ContainerItemsDescriptor.DATA_TYPE,
    subscribeContainerItems,
    unsubscribeContainerItems
);

reduxInstance.injectReducer(STORE_KEY, reducer);
