import DataProvider from 'core/services/data-provider/data-provider.js';

const COLLECTION_NAME = 'systeminformation';

/**
 *
 * Class providing access to system information.
 *
 * @class SystemInformationProvider
 *
 * @extends Mojito.Services.DataProvider
 * @memberof Mojito.Services.SystemInformation
 */
class SystemInformationProvider extends DataProvider {
    init() {
        super.addSimpleCollection(COLLECTION_NAME);
    }

    subscribeToEntity(systemInformationId, setter) {
        return super.subscribeToEntity(systemInformationId, setter, COLLECTION_NAME);
    }
}

export default new SystemInformationProvider();
