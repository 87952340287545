import PropTypes from 'prop-types';
import FallbackEventCardModuleImpl from './fallback-event-card-module.jsx';
import MojitoCore from 'mojito/core';

/**
 * Fallback event card module.
 *
 * @class FallbackEventCardModule
 * @name module
 *
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.EventCards.Fallback
 */

/**
 * Fallback event card prop types.
 *
 * @property {string} type - Type of fallback, for example: inplay, sport, etc.
 * @property {string} [sportId = 'soccer'] - Sport id for sport specific info to be rendered in fallback card.
 * @memberof Mojito.Modules.EventCards.Fallback.module
 */
const propTypes = {
    type: PropTypes.string.isRequired,
    sportId: PropTypes.string,
};

const defaultProps = {
    sportId: 'soccer',
};

export default MojitoCore.Presentation.UIControllerView(
    'FallbackEventCardModule',
    FallbackEventCardModuleImpl,
    propTypes,
    defaultProps
);
