import React from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'mojito/utils';
import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';

const SportsMenu = MojitoModules.SportsMenu.module;
const InPlayMenu = MojitoModules.InPlay.InPlayMenu;
const Breadcrumbs = MojitoModules.Breadcrumbs.module;
const InPlayContent = MojitoModules.InPlay.InPlayContent;
const BreadcrumbsTypes = MojitoModules.Breadcrumbs.types;
const { useInPlaySportsData } = MojitoModules.Common.Hooks;
const {
    FlexPane,
    PageHeader,
    GlobalLinks,
    DocumentHead,
    EmptyContent,
    PageSplitLayout,
    DefaultHeadMetaFactory,
} = MojitoPresentation.Components;

export default function InPlayPage(props) {
    const { sportId } = useParams();

    const {
        mojitoTools: { l10n, config, appContext, stringResolver },
    } = props;

    const metaFactory = config.documentHeadMetaFactory || DefaultHeadMetaFactory;
    const documentHeadMetaFactory = new metaFactory(l10n);

    const renderDocumentHead = () => {
        const sportName = sportId && appContext.sportName(sportId);

        const headData = {
            title: '$IN_PLAY_PAGE.PAGE_TITLE',
            keywords: '$IN_PLAY_PAGE.PAGE_KEYWORDS',
            description: '$IN_PLAY_PAGE.PAGE_DESCRIPTION',
            canonical: '$IN_PLAY_PAGE.PAGE_CANONICAL',
            localizedParams: new Map([['sportName', sportName]]),
            params: {
                sportId: sportId,
            },
        };

        return <DocumentHead data={headData} metaFactory={documentHeadMetaFactory} />;
    };

    const renderPageHeader = () => {
        if (!config.showPageHeader) {
            return null;
        }

        return (
            <PageHeader
                config={config.pageHeader}
                label={stringResolver.resolveString('$IN_PLAY_PAGE.HEADER')}
            />
        );
    };

    const renderBreadcrumbs = () => {
        if (config.hideBreadcrumbs) {
            return null;
        }

        const breadcrumbs = [
            {
                type: BreadcrumbsTypes.TEXT_LABEL,
                data: stringResolver.resolveString('$IN_PLAY_PAGE.HEADER'),
                analyticData: 'In-Play',
            },
        ];

        return <Breadcrumbs breadcrumbs={breadcrumbs} />;
    };

    const { isPending, eventGroups, sportIds } = useInPlaySportsData(config.sportListName);
    const noEventsAvailable = isEmpty(sportIds) || (sportId && !sportIds.includes(sportId));

    return (
        <div className="ta-InPlayPage">
            {renderDocumentHead()}
            <PageSplitLayout>
                {!isPending && noEventsAvailable ? (
                    <EmptyContent config={config.emptyContent} />
                ) : (
                    <div>
                        {renderBreadcrumbs()}
                        {renderPageHeader()}
                        <InPlayMenu
                            selectedSport={sportId}
                            sportIds={sportIds}
                            eventGroups={eventGroups}
                            hasPendingGroups={isPending}
                        />
                        <InPlayContent
                            selectedSport={sportId}
                            sportIds={sportIds}
                            eventGroups={eventGroups}
                        />
                    </div>
                )}
                <FlexPane config={config.secondaryContentContainer}>
                    <SportsMenu />
                    <GlobalLinks />
                </FlexPane>
            </PageSplitLayout>
        </div>
    );
}
