import MojitoCore from 'mojito/core';
import ActiveBonuses from './view/index.jsx';
import MojitoServices from 'mojito/services';
import { connect } from 'react-redux';

const { actions: bonusFreeBetsActions, selectors: bonusFreeBetsSelectors } =
    MojitoServices.BonusFreeBets;
const { selectActiveFreeBets } = bonusFreeBetsSelectors;
const { selectCurrency } = MojitoServices.UserInfo.selectors;
const { UIViewImplementation } = MojitoCore.Presentation;

class ActiveBonusesController extends UIViewImplementation {
    componentDidMount() {
        this.fetchBonuses();
    }

    fetchBonuses() {
        this.props.dispatch(bonusFreeBetsActions.fetchActiveFreeBets());
    }

    render() {
        return (
            <ActiveBonuses
                campaigns={this.props.campaigns}
                freeBets={this.props.freeBets}
                currencyCode={this.props.currencyCode}
                config={this.config.view}
                showPrewager={this.props.showPrewager}
            />
        );
    }
}

const mapStateToProps = state => ({
    freeBets: selectActiveFreeBets(state),
    currencyCode: selectCurrency(state),
});

export default connect(mapStateToProps)(ActiveBonusesController);
