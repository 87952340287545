export function convertStringToSVGHtmlNode(svgString) {
    const parsedSVGHTMLNode = new DOMParser()
        .parseFromString(svgString, 'image/svg+xml')
        .getElementsByTagName('svg')[0];

    if (!parsedSVGHTMLNode) throw new Error('Markup does not contain SVG node!');

    const parsedNodeViewBoxAttribute = parsedSVGHTMLNode.getAttribute('viewBox');
    const parsedNodeWidthAttribute = parsedSVGHTMLNode.getAttribute('width');
    const parsedNodeHeightAttribute = parsedSVGHTMLNode.getAttribute('height');

    if (!parsedNodeViewBoxAttribute) {
        if (!parsedNodeWidthAttribute || !parsedNodeHeightAttribute) {
            throw new Error('SVG icon should contain attribute viewBox or at least both width and height attributes!');
        }

        parsedSVGHTMLNode.setAttribute('viewBox', `0 0 ${parsedNodeWidthAttribute} ${parsedNodeHeightAttribute}`);
    }

    return parsedSVGHTMLNode;
}
