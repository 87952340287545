import MojitoCore from 'mojito/core';
import SearchAbstractService from './search-abstract-service.js';

const log = MojitoCore.logger.get('SearchService');
const transporterFactory = MojitoCore.Services.transporterFactory;

/**
 *
 * Implementation of search service.
 *
 * @class SearchService
 * @name searchService
 * @memberof Mojito.Services.Search
 */
export class SearchService extends SearchAbstractService {
    /**
     * Search request.
     *
     * @param {Mojito.Services.Search.types.SearchData} searchData - Object defines search data.
     *
     * @returns {Promise} Search promise.
     * @function Mojito.Services.Search.SearchService#search
     */
    search(searchData) {
        const { query, pageNumber, languageCode } = searchData;
        const transporter = transporterFactory.getTransporter();
        return new Promise((resolve, reject) => {
            transporter.search(
                'event, activeSport, activeType, raceEvent, raceMeeting',
                { locale: languageCode, pageNumber },
                query,
                (err, result) => {
                    if (err) {
                        log.warn(`Search error`, err);
                        reject(err);
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }
}

export default new SearchService();
