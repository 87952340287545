import { STORE_KEY, INITIAL_STATE } from './slice.js';
import reduxInstance from 'core/services/redux-instance';

/**
 * System settings store selectors.
 *
 * @class SystemSettingsSelectors
 * @name selectors
 * @memberof Mojito.Services.SystemSettings
 */

/**
 * Selects system settings state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SystemSettings.SystemSettingsState} The state of the system settings store.
 * @memberof Mojito.Services.SystemSettings.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects current language.
 *
 * @function selectLanguage
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Language.
 * @memberof Mojito.Services.SystemSettings.selectors
 */
export const selectLanguage = state => selectState(state)?.language;

/**
 * Selects current application mode.
 *
 * @function selectApplicationMode
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Application mode.
 * @memberof Mojito.Services.SystemSettings.selectors
 */
export const selectApplicationMode = state => selectState(state)?.applicationMode;

/**
 * Selects current additional context.
 *
 * @function selectAdditionalContext
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Additional context.
 * @memberof Mojito.Services.SystemSettings.selectors
 */
export const selectAdditionalContext = state => selectState(state)?.additionalContext;

/**
 * Selects channel.
 *
 * @function selectContextToChannelMap
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} ContextToChannelMap.
 * @memberof Mojito.Services.SystemSettings.selectors
 */
export const selectContextToChannelMap = state => selectState(state)?.contextToChannelMap;

/**
 * Selects current taMode mode status.
 *
 * @function selectIsTAMode
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} Returns indicator of taMode status.
 * @memberof Mojito.Services.SystemSettings.selectors
 */
export const selectIsTAMode = state => selectState(state).isTAMode;

/**
 * Subscribes to the changes of particular system settings state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Services.SystemSettings.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
