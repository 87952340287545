import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * Abstract HeadMetaFactory (including title, keywords and description) to be implemented by different integrations if the default meta-factory {@link Mojito.Presentation.Components.DefaultHeadMetaFactory} isn't sufficient.
 *
 * @class AbstractHeadMetaFactory
 * @abstract
 * @memberof Mojito.Presentation.Components
 */
export default class AbstractHeadMetaFactory {
    /**
     * Typically you do not want to override the constructor.
     *
     * @param {object} l10n - An object with l10n-strings. If title/keywords/description in {@link Mojito.Presentation.Components.DocumentHead.PageDataParams} is a key the value will be fetched from here.
     * @function Mojito.Presentation.Components.AbstractHeadMetaFactory#constructor
     */
    // eslint-disable-next-line no-unused-vars
    constructor(l10n) {
        // To be implemented by subclasses
    }

    /**
     * Get the Document Title.<br>
     * Typical implementation would look at the data params and return a page and param specific title.
     *
     * @param {Mojito.Presentation.Components.DocumentHead.PageDataParams} data - Business logic parameters. Can for instance be used to output special title for a specific sport or league.
     * @function Mojito.Presentation.Components.AbstractHeadMetaFactory#getTitle
     * @returns {string} Title.
     */
    // eslint-disable-next-line no-unused-vars
    getTitle(data) {
        log.error('getTitle must be implemented by concrete subclass!');
        return undefined;
    }

    /**
     * Get the Document Meta-tag Keywords.<br>
     * Typical implementation would look at the data params and return page and param specific keywords.
     *
     * @param {Mojito.Presentation.Components.DocumentHead.PageDataParams} data - Business logic parameters. Can for instance be used to output special keywords for a specific sport or league.
     * @function Mojito.Presentation.Components.AbstractHeadMetaFactory#getKeywords
     * @returns {string} Keywords.
     */
    // eslint-disable-next-line no-unused-vars
    getKeywords(data) {
        log.error('getKeywords must be implemented by concrete subclass!');
        return undefined;
    }

    /**
     * Get the Document Meta-tag description.<br>
     * Typical implementation would look at the data params and return a page and param specific description.
     *
     * @param {Mojito.Presentation.Components.DocumentHead.PageDataParams} data - Business logic parameters. Can for instance be used to output a special description for a specific sport or league.
     * @function Mojito.Presentation.Components.AbstractHeadMetaFactory#getDescription
     * @returns {string} Description.
     */
    // eslint-disable-next-line no-unused-vars
    getDescription(data) {
        log.error('getDescription must be implemented by concrete subclass!');
        return undefined;
    }

    /**
     * Get the Document Meta-tag canonical.<br>
     * Typical implementation would look at the data params and return a page and param specific canonical.
     *
     * @param {Mojito.Presentation.Components.DocumentHead.PageDataParams} data - Business logic parameters. Can for instance be used to output a special description for a specific sport or league.
     * @function Mojito.Presentation.Components.AbstractHeadMetaFactory#getDescription
     * @returns {string} Canonical.
     */
    // eslint-disable-next-line no-unused-vars
    getCanonical(data) {
        log.error('getCanonical must be implemented by concrete subclass!');
        return undefined;
    }
}
