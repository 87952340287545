import Image from 'presentation/components/image/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';
import ImageButton from 'presentation/components/image-button/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import Popup from 'presentation/components/popup/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import { isEmpty } from 'mojito/utils';

const Tooltip = props => {
    const { mojitoTools, onClose, text, onClick } = props;
    const { config } = mojitoTools;
    const onCloseClick = event => {
        event.stopPropagation();
        onClose();
    };
    const hasImage = !isEmpty(config.image.src);
    return (
        <Popup config={config.popup} visible={true}>
            <Button config={config.clickableContainer} onClick={onClick}>
                {hasImage && <Image config={config.image} class="ta-image" />}
                <Text config={config.textLabel}>{text}</Text>
                <AbsolutePane config={config.closeButtonContainer}>
                    <ImageButton
                        class="ta-tooltip-closeButton"
                        onClick={onCloseClick}
                        config={config.closeButton}
                    />
                </AbsolutePane>
            </Button>
        </Popup>
    );
};

export default Tooltip;
