/**
 * Services' common utilities.
 *
 * @namespace utils
 * @memberof Mojito.Services
 */

/**
 * Default service url key.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services
 */
export const DEFAULT_SERVICE_URL_KEY = 'serviceUrl';

/**
 * Function wrapper that allows any request to be repeated after a certain number of errors.
 *
 * @param {Function} fn - Function to wrap.
 * @param {number} maxRetries - Number of retries.
 *
 * @returns {Function} Wrapped function.
 * @function Mojito.Services.utils.withRetry
 */
export function withRetry(fn, maxRetries) {
    return (...args) => {
        return new Promise((resolve, reject) => {
            let attempt = 0;
            const doTry = () => {
                fn(...args)
                    .then(resolve)
                    .catch(err => {
                        if (attempt < maxRetries) {
                            attempt++;
                            doTry();
                        } else {
                            reject(err);
                        }
                    });
            };
            doTry();
        });
    };
}

/**
 * Create wrapper around function to check if serviceUrl existed on call.
 *
 * @param {string} logger - Logger to log error.
 * @param {string} serviceUrlKey - Service url key (property inside class) name.
 *
 * @returns {Function} Wrapped function.
 * @function Mojito.Services.utils.createBaseServiceUrlExistenceValidation
 */
export const createBaseServiceUrlExistenceValidation = (logger, serviceUrlKey) =>
    function (serviceCall) {
        return function (...args) {
            if (!this[serviceUrlKey]) {
                logger.warn(`${serviceUrlKey} is missing.`);
                return Promise.reject();
            }
            return serviceCall(...args);
        };
    };
/**
 * Create wrapper around function to check if Mojito default key serviceUrl existed on call.
 * DEFAULT_SERVICE_URL_KEY is used as default.
 *
 * @param {string} logger - Logger to log error.
 *
 * @returns {Function} Wrapped function.
 * @function Mojito.Services.utils.createDefaultServiceUrlValidation
 */
export const createDefaultServiceUrlValidation = logger =>
    createBaseServiceUrlExistenceValidation(logger, DEFAULT_SERVICE_URL_KEY);

/**
 * Resolves time zone suffix string.
 * Typically used for data subscriptions as part of storable collection name.
 * For instance, in `soccer-today-tz-indian-maldives-match-events` the portion 'tz-indian-maldives' is the time zone suffix.
 * If the `timeZone` property is set then it will take precedence over `timeOffset`.
 *
 * @example // If timeZone is 'Europe/Kiev', the suffix will be 'tz-europe-kiev'
 * resolveTimeZoneSuffix({ timeZone: 'Europe/Kiev' });
 * // If timeOffset is 5, the suffix will be 'gmt+5'
 * resolveTimeZoneSuffix({ timeOffset: 5 });
 *
 * @param {{timeZone: string, timeOffset: string}} settings - Time zone settings object.
 *
 * @returns {string} The time zone suffix.
 * @function Mojito.Services.utils.resolveTimeZoneSuffix
 */
export const resolveTimeZoneSuffix = settings => {
    const { timeZone, timeOffset } = settings;
    return timeZone
        ? `tz-${timeZone.replace(/\//, '-').toLowerCase()}`
        : formatTimeOffset(timeOffset);
};

/**
 * Get time offset from GMT as a formatted string.
 *
 * @example // returns "gmt+5"
 * formattedTimeOffset(5);
 *
 * @param {number} offset - GMT offset.
 * @returns {string} The time offset as a string. Possible values are [gmt | gmt+timeOffset | gmt-timeOffset].
 * @function Mojito.Services.utils.formattedTimeOffset
 */
export const formatTimeOffset = offset => {
    if (offset === 0) {
        return 'gmt';
    }
    return offset > 0 ? `gmt+${offset}` : `gmt${offset}`;
};
