import HideablePane from 'presentation/components/hideable-pane/index.jsx';
import MojitoCore from 'mojito/core';
import Button from 'presentation/components/button/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import RotatableImage from 'presentation/components/rotatable-image/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import Spinner from 'presentation/components/spinner/index.jsx';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class ExpandableView extends UIViewImplementation {
    constructor(props) {
        super(props);

        const expandedIsUndefined = this.props.expanded === undefined;
        this.state = {
            expanded: expandedIsUndefined ? this.config.initiallyExpanded : this.props.expanded,
            mirroredExpandedProp: this.props.expanded, // This mirrored state from prop is required because getDerivedStateFromProps does not expose prevProps.
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.expanded !== state.mirroredExpandedProp) {
            return { expanded: props.expanded, mirroredExpandedProp: props.expanded };
        }
        return null;
    }

    onHeaderClicked() {
        const newExpanded = !this.state.expanded;
        this.setState({ expanded: newExpanded });
        this.props.onExpandedChanged(newExpanded);
    }

    renderContent() {
        return (
            <HideablePane hidden={!this.state.expanded} config={this.config.contentContainer}>
                {this.props.children}
            </HideablePane>
        );
    }

    renderRightItems(items) {
        if (items.length === 0) {
            return null;
        }

        return <FlexPane config={this.config.rightItemsContainer}>{items}</FlexPane>;
    }

    renderLeftItems() {
        const items = [];

        if (this.config.expandIconPosition === 'left') {
            items.push(this.renderExpandIcon(this.config.expandIconPosition));
        }

        if (this.props.leftIconSrc) {
            items.push(
                <Image
                    src={this.props.leftIconSrc}
                    config={this.config.leftIcon}
                    key="leftIcon"
                    class="leftIcon"
                />
            );
        }

        if (items.length === 0) {
            return null;
        }

        return <FlexPane config={this.config.leftItemsContainer}>{items}</FlexPane>;
    }

    renderExpandIcon(position) {
        const { loadingSpinner, showLoadingSpinner } = this.config;
        if (this.props.loading && showLoadingSpinner) {
            return <Spinner key="loadingSpinner" config={loadingSpinner} />;
        }
        return (
            <RotatableImage
                key="expandIcon"
                class={`${position}ExpandIcon`}
                config={this.config.rotation}
                angle={this.state.expanded ? '0deg' : '180deg'}
            />
        );
    }

    renderHeader() {
        const { headerRenderer } = this.props;
        const headerContent = headerRenderer() || (
            <Text config={this.config.headerTextContainer} class="headerText">
                {this.props.headerText}
            </Text>
        );
        return (
            <>
                {this.renderLeftItems()}
                {headerContent}
                {this.renderRightItems(this.props.rightItems)}
                {this.renderRightExpandIcon()}
            </>
        );
    }

    renderRightExpandIcon() {
        if (this.config.expandIconPosition === 'right') {
            return this.renderExpandIcon(this.config.expandIconPosition);
        }
    }

    render() {
        return (
            <FlexPane
                config={this.config.container}
                class={`ta-ExpandableView ${this.props.class}`}
            >
                <Button
                    config={this.config.headerContainer}
                    onClick={this.onHeaderClicked.bind(this)}
                    class="header"
                >
                    {this.renderHeader()}
                </Button>
                {this.renderContent()}
            </FlexPane>
        );
    }
}
