import MojitoCore from 'mojito/core';
import { selectInitStatus as selectApiInitStatus } from 'services/bootstrap/selectors.js';
import bootstrapController from 'services/bootstrap/controller.js';
import ServicesTypes from 'services/common/types.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
const { INIT_STATUS } = ServicesTypes;

const { dispatch } = MojitoCore.Services.redux.store;
const { RequestFactory, types: TransactionsTypes } = MojitoCore.Services.Transactions;
const { INVALID_SESSION } = TransactionsTypes.ERROR_CODE;

/**
 * RequestProvider constructor.
 *
 * @class RequestProvider
 * @param {object} logger - Logger instance.
 * @param {Mojito.Core.Services.Transactions.RequestFactory} [requestFactory] - Instance of request factory.
 * If not provided, then the instance of RequestFactory will be created automatically.
 * @classdesc Class used to provide instance of {@link Mojito.Core.Services.Transactions.TransactionRequest|Request} object.
 * If API bootstrap initialisation is not complete then {@link Mojito.Services.Common.ApiRequest.PostponedRequest|PostponedRequest} will used as output.
 *
 * @memberof Mojito.Services.Common.ApiRequest
 */
export default class RequestProvider {
    constructor(logger, requestFactory) {
        this.requestFactory =
            requestFactory || new RequestFactory(logger, this.globalErrorHandler.bind(this));
        this.logger = logger;
    }

    /**
     * Creates get request.
     *
     * @param  {string} url - Request URL.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest|Mojito.Core.Services.Transactions.PostponedRequest} Instance or request or postponed request.
     * @function Mojito.Services.Common.ApiRequest.RequestProvider#get
     */
    get(url) {
        const request = this.requestFactory.get(url);
        return this.ensureApiFinalised(request);
    }

    /**
     * Creates patch request.
     *
     * @param  {string} url - Request URL.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest|Mojito.Core.Services.Transactions.PostponedRequest} Instance of request or postponed request.
     * @function Mojito.Services.Common.ApiRequest.RequestProvider#patch
     */
    patch(url) {
        const request = this.requestFactory.patch(url);
        return this.ensureApiFinalised(request);
    }

    /**
     * Creates post request.
     *
     * @param  {string} url - Request URL.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest|Mojito.Core.Services.Transactions.PostponedRequest} Instance or request or postponed request.
     * @function Mojito.Services.Common.ApiRequest.RequestProvider#post
     */
    post(url) {
        const request = this.requestFactory.post(url);
        return this.ensureApiFinalised(request);
    }

    /**
     * Checks if API is considered to be finalised. We assume that API finalisation is done
     * when bootstrap initialisation status is either successful or faulty. That means that even if we tried to init API
     * few times, and it failed, we are not going postpone requests anymore. Now it will be up to concrete
     * Mojito endpoint to mitigate this or respond with proper error.
     *
     * @returns {boolean} True is API is considered to be finalised, false otherwise.
     * @private
     * @function Mojito.Services.Common.ApiRequest.RequestProvider#isApiFinalised
     */
    isApiFinalised() {
        const status = selectApiInitStatus();
        return status === INIT_STATUS.INITIALISED || status === INIT_STATUS.FAILED;
    }

    /**
     * Converts transaction request into postponed request. This request will not be sent immediately, instead it will trigger
     * onSend callback. This will add the request in a queue of awaiting requests. All these requests will be executed after mojito API is initialized.
     *
     * @param  {Mojito.Core.Services.Transactions.TransactionRequest} request - Transaction request instance.
     *
     * @returns {Mojito.Core.Services.Transactions.PostponedRequest} Instance of postponed request.
     * @private
     * @function Mojito.Services.Common.ApiRequest.RequestProvider#toPostponed
     */
    toPostponed(request) {
        return this.requestFactory.toPostponed(request, self =>
            bootstrapController.addWaitingRequest(self)
        );
    }

    ensureApiFinalised(request) {
        return this.isApiFinalised() ? request : this.toPostponed(request);
    }

    globalErrorHandler(error) {
        if (error.type === INVALID_SESSION) {
            dispatch(authenticationActions.unexpectedSessionLost());
        }
    }
}
