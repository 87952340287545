import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import FreeBetTypes from './types.js';

const { CurrencyHelper } = MojitoPresentation.Utils;

const { FlexPane, Image, Text, DateTime, GridLayout } = MojitoPresentation.Components;
const { GridPane, GridSeparator } = GridLayout;
const { UIViewImplementation } = MojitoCore.Presentation;
const BonusFreeBetsUtils = MojitoServices.BonusFreeBets.utils;
const { HEADER_COLUMNS } = FreeBetTypes;

export default class FreeBetItems extends UIViewImplementation {
    constructor(reactComponent) {
        super(reactComponent);
        this.onExpireDateRender = this.onExpireDateRender.bind(this);
        this.renderers = {
            [HEADER_COLUMNS.NAME]: this.renderNameColumn.bind(this),
            [HEADER_COLUMNS.DESCRIPTION]: this.renderDescriptionColumn.bind(this),
            [HEADER_COLUMNS.EXPIRY]: this.renderExpiryColumn.bind(this),
            [HEADER_COLUMNS.TOTAL]: this.renderTotalColumn.bind(this),
            [HEADER_COLUMNS.REMAINING]: this.renderRemainingColumn.bind(this),
            [HEADER_COLUMNS.STATUS]: this.renderStatusColumn.bind(this),
        };
    }

    onExpireDateRender(expireDate) {
        return this.resolveAndFormatString(
            '$FREE_BET_ITEM.ATTRIBUTES.COLUMN.EXPIRE_DATE',
            expireDate
        );
    }

    renderFreeBetNameAndStatus(name) {
        return (
            <FlexPane config={this.config.compactContainerInner}>
                <Image config={this.config.icon} class="ta-freebetIcon" />
                <Text config={this.config.title} class="ta-bonusTitle">
                    {name.toUpperCase()}
                </Text>
                <Text config={this.config.status} class="ta-bonusStatus">
                    {this.resolveString(`$FREE_BET_ITEM.STATUS_ACTIVE`).toUpperCase()}
                </Text>
            </FlexPane>
        );
    }

    renderFreeBetExpiry(expiry) {
        return (
            <FlexPane config={this.config.expireDateContainer}>
                <Text config={this.config.expiry} class="ta-bonusExpirationDate">
                    <DateTime
                        config={this.config.expireDateTimeFormat}
                        dateTime={expiry}
                        dateTimeRenderer={this.onExpireDateRender}
                    />
                </Text>
            </FlexPane>
        );
    }

    renderFreeBetDescription(description) {
        return (
            <Text config={this.config.description} class="ta-bonusDescription">
                {description}
            </Text>
        );
    }

    renderFreeBetTotal(total, remaining) {
        const amountStyle =
            remaining !== undefined ? this.config.amountAndRemainingAmount : this.config.onlyAmount;
        return (
            <FlexPane config={this.config.amountContainer}>
                <Text config={amountStyle} class="ta-bonusAmountLabel">
                    {this.resolveAndFormatString('$FREE_BET_ITEM.ATTRIBUTES.COLUMN.TOTAL_VALUE')}
                </Text>
                <Text config={amountStyle} class="ta-bonusAmount">
                    {total}
                </Text>
            </FlexPane>
        );
    }

    renderFreeBetRemaining(remaining) {
        return (
            <FlexPane config={this.config.remainingAmountContainer}>
                <Text config={this.config.remainingAmount} class="ta-bonusRemainingAmountLabel">
                    {this.resolveAndFormatString(
                        '$FREE_BET_ITEM.ATTRIBUTES.COLUMN.REMAINING_VALUE'
                    )}
                </Text>
                <Text config={this.config.remainingAmount} class="ta-bonusRemainingAmount">
                    {remaining}
                </Text>
            </FlexPane>
        );
    }

    renderCompactView(freeBet) {
        const remainingAmount = BonusFreeBetsUtils.getRemainingAmount(freeBet);
        const remainingFormatCurrency = CurrencyHelper.formatCurrency(
            remainingAmount,
            this.props.currencyCode
        );
        const totalFormatCurrency = CurrencyHelper.formatCurrency(
            freeBet.amount,
            this.props.currencyCode
        );
        return (
            <FlexPane config={this.config.compactContainer} class="ta-freeBetItemsCompactView">
                {this.renderFreeBetNameAndStatus(freeBet.name)}
                {freeBet.expireDate && this.renderFreeBetExpiry(freeBet.expireDate)}
                {this.renderFreeBetDescription(freeBet.description)}
                {this.renderFreeBetTotal(totalFormatCurrency, remainingFormatCurrency)}
                {freeBet.remainingAmount !== undefined &&
                    this.renderFreeBetRemaining(remainingFormatCurrency)}
            </FlexPane>
        );
    }

    getHeaderStyle(key) {
        const headerStyle = {
            [HEADER_COLUMNS.TOTAL]: 'headerStyleAlignedEnd',
            [HEADER_COLUMNS.REMAINING]: 'headerStyleAlignedEnd',
            [HEADER_COLUMNS.STATUS]: 'headerStyleCenter',
        };
        const headerDefaultStyle = 'headerStyle';
        return this.config[headerStyle[key]] || this.config[headerDefaultStyle];
    }

    renderGridHeader() {
        return this.config.headerColumns.map(columnName => {
            const headerStyle = this.getHeaderStyle(columnName);
            return (
                <Text config={headerStyle} key={columnName}>
                    {this.resolveString(`$FREE_BET_ITEM.HEADERS.${columnName.toUpperCase()}`)}
                </Text>
            );
        });
    }

    renderNameColumn(freeBet) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-freebetInfo"
                key={freeBet._id + HEADER_COLUMNS.NAME}
            >
                <Image config={this.config.freebetsIcon} class="ta-freebetIcon" />
                <Text config={this.config.textItem}>{freeBet.name}</Text>
            </FlexPane>
        );
    }

    renderDescriptionColumn(freeBet) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-freebetInfo"
                key={freeBet._id + HEADER_COLUMNS.DESCRIPTION}
            >
                <Text config={this.config.textItem}>{freeBet.description}</Text>
            </FlexPane>
        );
    }

    renderExpiryColumn(freeBet) {
        const expireDate = freeBet.expireDate;
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-freebetInfo"
                key={freeBet._id + HEADER_COLUMNS.EXPIRY}
            >
                <Text config={this.config.textItem}>
                    {expireDate !== undefined ? (
                        <DateTime config={this.config.expireDateTimeFormat} dateTime={expireDate} />
                    ) : undefined}
                </Text>
            </FlexPane>
        );
    }

    renderTotalColumn(freeBet) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-freebetInfo"
                key={freeBet._id + HEADER_COLUMNS.TOTAL}
            >
                <Text config={this.config.textItemAlignedEnd} class="ta-bonusAmount">
                    {CurrencyHelper.formatCurrency(freeBet.amount, this.props.currencyCode)}
                </Text>
            </FlexPane>
        );
    }

    renderRemainingColumn(freeBet) {
        const remainingAmount = BonusFreeBetsUtils.getRemainingAmount(freeBet);
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-freebetInfo"
                key={freeBet._id + HEADER_COLUMNS.REMAINING}
            >
                <Text config={this.config.textItemAlignedEnd} class="ta-bonusRemainingAmount">
                    {CurrencyHelper.formatCurrency(remainingAmount, this.props.currencyCode)}
                </Text>
            </FlexPane>
        );
    }

    renderStatusColumn(freeBet) {
        return (
            <FlexPane
                config={this.config.columnItem}
                class="ta-freebetInfo"
                key={freeBet._id + HEADER_COLUMNS.STATUS}
            >
                <Text config={this.config.textItemCenter}>
                    {this.resolveString(`$FREE_BET_ITEM.STATUS_ACTIVE`)}
                </Text>
            </FlexPane>
        );
    }

    renderContent(freeBet) {
        return this.config.headerColumns.map(column => this.renderers[column](freeBet));
    }

    renderFullLayout(freeBets) {
        const freeBetsInfo = freeBets.map(freeBet => {
            return (
                <GridSeparator config={this.config.gridSeparator} key={freeBet.id}>
                    {this.renderContent(freeBet)}
                </GridSeparator>
            );
        });
        const header = this.renderGridHeader();

        return (
            <GridPane class="ta-freebetItems" config={this.config.listContainer}>
                {header}
                {freeBetsInfo}
            </GridPane>
        );
    }

    renderCompactLayout(freeBets) {
        return freeBets.map(freeBet => (
            <FlexPane key={freeBet.id} config={this.config.container} class="ta-bonusItem">
                {this.renderCompactView(freeBet)}
            </FlexPane>
        ));
    }

    render() {
        const freeBets = this.props.freeBets || [];
        return (
            <>
                {this.config.compactLayout
                    ? this.renderCompactLayout(freeBets)
                    : this.renderFullLayout(freeBets)}
            </>
        );
    }
}
