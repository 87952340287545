import SportFallbackEventCardImpl from './sport-fallback-event-card.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

/**
 * Sport specific event card for events promotion.
 *
 * @class SportFallbackEventCard
 * @name sport
 *
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.EventCards.Fallback.types
 */

/**
 * Sport specific event card prop types.
 *
 * @property {string} sportId - Sport id.
 * @memberof Mojito.Modules.EventCards.Fallback.types.sport
 */

const propTypes = {
    sportId: PropTypes.string.isRequired,
};

export default MojitoCore.Presentation.UIControllerView(
    'SportFallbackEventCard',
    SportFallbackEventCardImpl,
    propTypes
);
