/**
 * ClockTicker offers encapsulate a timer and provide methods
 * to subscribe callback functions to be executed each interval(clock tick).
 *
 * @class ClockTicker
 * @name clockTicker
 * @memberof Mojito.Core.Base
 */
class ClockTicker {
    constructor() {
        this.listeners = [];
        this.interval = undefined;
        this.onTick = this.onTick.bind(this);
    }
    /**
     * Add a callback function to be executed on each tick of the clock.
     *
     * @param {Function} listener - The callback function to be executed on each clock tick.
     *
     * @function Mojito.Core.Base.ClockTicker#addListener
     */
    addListener(listener) {
        if (!listener) {
            return;
        }
        this.listeners.push(listener);
        if (!this.interval) {
            this.interval = setInterval(this.onTick, 1000);
        }
    }

    /**
     * Remove the callback function.
     *
     * @param {Function} listener - The callback function to be removed.
     *
     * @function Mojito.Core.Base.ClockTicker#removeListener
     */
    removeListener(listener) {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            this.listeners.splice(index, 1);
            if (!this.listeners.length) {
                clearInterval(this.interval);
                this.interval = undefined;
            }
        }
    }

    onTick() {
        this.listeners.forEach(listener => listener());
    }
}

export default new ClockTicker();
