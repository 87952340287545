/**
 * Content notifications types.
 *
 * @class ContentNotificationsTypes
 * @name types
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */
export default class ContentNotificationsTypes {}

/**
 * Event subscription type.
 *
 * @readonly
 * @memberof Mojito.Services.SportsContent.ContentNotifications.types
 */
ContentNotificationsTypes.SUBSCRIPTION_TYPE = {
    /** Subscribe to all available event actions. */
    EVENT: 'EVENT',
    /** Not supported. */
    FULL_TIME_RESULT: 'FULL_TIME_RESULT',
    /** Not supported. */
    HALF_TIME_RESULT: 'HALF_TIME_RESULT',
};

/**
 * Follow event payload.
 *
 * @typedef FollowEventInfo
 * @type {object}
 * @property {string} eventId - The unique identifier of the event.
 * @property {string} notificationsId - Notification subscription identifier, this could be a Betradar identifier in some integrations.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.types
 */

/**
 * Object that holds event notifications subscription info.
 *
 * @typedef EventSubscriptionInfo
 * @type {object}
 * @property {Array<Mojito.Services.SportsContent.ContentNotifications.types.SUBSCRIPTION_TYPE>} types - The id of the event.
 * @property {number} expired - Timestamp identifies when subscription will be expired.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.types
 */
