import RequestDataHelper from 'core/services/request-data-helper';
import { pick } from 'mojito/utils';

/**
 * Content preloader. Used to sync content requests for clients distinguished by types.
 * Can be helpful to trigger data chunk request for the set of registered clients.
 *
 * @class ContentPreloader
 * @memberof Mojito.Core.Services.Content
 */
export default class ContentPreloader {
    constructor() {
        this.requestRegistry = {};
        this.awaitingRequests = {};
        this.requestHelper = new RequestDataHelper();
    }

    /**
     * Register request type for a future execution.
     *
     * @param {string} type - Request type. Can be common for different callee in order to request content in chunk.
     * @param {string} clientId - Client id, typically, instance id of the component.
     * @param {string} dataType - The type of the data that client is aiming to request.
     * @function Mojito.Core.Services.Content.ContentPreloader#registerRequest
     */
    registerRequest(type, clientId, dataType) {
        if (this.hasAwaitingRequest(type, clientId)) {
            return;
        }
        const request = this.requestRegistry[type] || { dataType, requested: 0, clientIds: [] };
        request.clientIds.push(clientId);
        this.requestRegistry[type] = request;
    }

    /**
     * Remove request added by {@link Mojito.Core.Services.Content.ContentPreloader#registerRequest|registerRequest} call
     * to disregard client from content request.
     *
     * @param {string} type - Request type.
     * @param {string} clientId - Client id.
     * @function Mojito.Core.Services.Content.ContentPreloader#removeRequest
     */
    removeRequest(type, clientId) {
        if (!this.hasAwaitingRequest(type, clientId)) {
            return;
        }
        const request = this.requestRegistry[type];
        const indexToRemove = request.clientIds.indexOf(clientId);
        request.clientIds.splice(indexToRemove, 1);
        if (request.clientIds.length === 0) {
            delete this.requestRegistry[type];
        } else {
            this.intentDataRequest(type);
        }
    }

    /**
     * Request content items for specific client.
     * The actual request will be executed if all registered requests of the same type have already been triggered or if registration
     * was removed by {@link Mojito.Core.Services.Content.ContentPreloader#removeClient|removeRequest} call.
     *
     * @param {string} type - Request type.
     * @param {string} clientId - Client id.
     * @param {Array<string>} itemIds - The list of requested item ids.
     * @param {Function} done - Callback triggered once actual request is executed.
     *
     * @function Mojito.Core.Services.Content.ContentPreloader#requestContent
     */
    requestContent(type, clientId, itemIds, done) {
        if (!itemIds?.filter(Boolean).length || !this.hasAwaitingRequest(type, clientId)) {
            return;
        }
        const awaitingRequests = this.awaitingRequests[type] || [];
        awaitingRequests.push({ clientId, type, itemIds, done });
        this.awaitingRequests[type] = awaitingRequests;
        this.intentDataRequest(type);
    }

    /**
     * Check if there is registered client type.
     *
     * @param {string} type - Client id.
     * @param {string} clientId - Client id.
     *
     * @returns {boolean} Returns true or false.
     * @function Mojito.Core.Services.Content.ContentPreloader#hasAwaitingRequest
     */
    hasAwaitingRequest(type, clientId) {
        const request = this.requestRegistry[type];
        return Boolean(request && request.clientIds.includes(clientId));
    }

    /**
     * Discard content requested for a client.
     *
     * @param {string} clientId - Client id.
     * @function Mojito.Core.Services.Content.ContentPreloader#discardContent
     */
    discardContent(clientId) {
        this.requestHelper.unsubscribeAll(clientId);
    }

    intentDataRequest(type) {
        const requestInfo = this.requestRegistry[type];
        const awaitingClients = this.awaitingRequests[type]?.map(request => request.clientId) || [];
        const everyClientRequested =
            requestInfo?.clientIds.length > 0 &&
            requestInfo.clientIds.every(clientId => awaitingClients.includes(clientId));
        if (everyClientRequested) {
            this.requestDataChunk(type);
            delete this.requestRegistry[type];
            delete this.awaitingRequests[type];
        }
    }

    requestDataChunk(type) {
        const awaitingRequests = this.awaitingRequests[type];
        const { dataType } = this.requestRegistry[type];
        const requests = awaitingRequests.map(request => pick(request, 'clientId', 'itemIds'));
        awaitingRequests.forEach(({ done }) => done && done());
        const descriptor = { dataType, requests };
        this.requestHelper.requestData(descriptor);
    }
}
