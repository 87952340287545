import { createContext } from 'react';
import { noop } from 'mojito/utils';

const defaultContext = { propsModifier: noop };
const ImageContext = createContext(defaultContext);

/**
 * Context and provider for the {@link Mojito.Presentation.Components.Image | Image} component.
 *
 * @class ImageContext
 * @memberof Mojito.Presentation.Components
 */

/**
 * `ImageContext` provider.
 *
 * @function ImageContextProvider
 *
 * @param {object} props - The properties.
 * @param {{propsModifier: Function}} props.value - Context value.
 * @param {node|Array<node>} props.children - Children to be wrapped by the context provider.
 * @returns {React.ReactElement} The React element to be rendered.
 * @memberof Mojito.Presentation.Components.ImageContext
 */
const ImageContextProvider = ({ value, children }) => (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
);

export { ImageContext, ImageContextProvider };
