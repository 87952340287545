import MojitoCore from 'mojito/core';
import betslipModelFactory from 'services/betslip/model-factory.js';
import { isEqual } from 'mojito/utils';

const IdGenerator = MojitoCore.Base.IdGenerator;

/**
 * Utility functions associated with the match acca services.
 *
 * @class MatchAccaUtils
 * @name utils
 * @memberof Mojito.Services.MatchAcca
 */
export default class MatchAccaUtils {
    /**
     * Get selection from match acca by selection id.
     *
     * @param {object} matchAcca - Match acca object.
     * @param {string} selectionId - Selection id.
     *
     * @returns {object|undefined} Selection object if found.
     *
     * @function Mojito.Services.MatchAcca.utils.getSelectionFromMatchAcca
     */
    static getSelectionFromMatchAcca(matchAcca, selectionId) {
        return matchAcca && matchAcca.selections.find(selection => selection.id === selectionId);
    }

    /**
     * Checks if selection corresponding to provided selection id
     * exists in match acca.
     *
     * @param {object} matchAcca - Match acca object.
     * @param {string} selectionId - Selection id.
     *
     * @returns {boolean} True if selection exists in match acca, false otherwise.
     *
     * @function Mojito.Services.MatchAcca.utils.hasSelection
     */
    static hasSelection(matchAcca, selectionId) {
        return !!MatchAccaUtils.getSelectionFromMatchAcca(matchAcca, selectionId);
    }

    /**
     * Removes selection from selections list by market id that selection belongs to.
     * Note: this function does not mutate the <code>selections</code> list.
     *
     * @param {Array<Mojito.Services.Betslip.types.Selection>} selections - List of selections.
     * @param {string} marketId - Market id that will be used to search for selection.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Selection>} List of updated selections.
     *
     * @function Mojito.Services.MatchAcca.utils.removeSelectionByMarketId
     */
    static removeSelectionByMarketId(selections, marketId) {
        return selections.filter(selection => selection.marketId !== marketId);
    }

    /**
     * Removes selection from selections list by id.
     * Note: this function does not mutate the <code>selections</code> list.
     *
     * @param {Array<Mojito.Services.Betslip.types.Selection>} selections - List of selections.
     * @param {string} selectionId - Selection id.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Selection>} List of updated selections.
     *
     * @function Mojito.Services.MatchAcca.utils.removeSelectionById
     */
    static removeSelectionById(selections, selectionId) {
        return selections.filter(selection => selection.id !== selectionId);
    }

    /**
     * For provided marketId sync selections added to match acca with provided selections list from content.
     * This list, typically, originates from the same market.
     *
     * @param {object} matchAcca - Match acca object.
     * @param {string} marketId - Market id.
     * @param {Array<object>} [selections = []] - List of selection objects from content. Belongs to particular market.
     *
     * @returns {object} Match acca object with updated selections.
     *
     * @function Mojito.Services.MatchAcca.utils.syncSelections
     */
    static syncSelections(matchAcca, marketId, selections = []) {
        const { selections: matchAccaSelections } = matchAcca;
        const affectedSelection = matchAccaSelections.find(
            selection => selection.marketId === marketId
        );
        if (!affectedSelection) {
            return matchAcca;
        }
        const selectionData = selections.find(selection => selection.id === affectedSelection.id);
        const updatedSelections = MatchAccaUtils.removeSelectionById(
            matchAccaSelections,
            affectedSelection.id
        );
        if (!selectionData) {
            return { ...matchAcca, selections: updatedSelections };
        }

        const updatedSelectionPart = betslipModelFactory.getSelection({
            ...selectionData,
            marketId,
        });

        return { ...matchAcca, selections: [...updatedSelections, updatedSelectionPart] };
    }

    /**
     * Check if matchAcca and prevMatchAcca have changes in selections set.
     *
     * @param {object} matchAcca - Match acca object.
     * @param {object} prevMatchAcca - Previous match acca object.
     *
     * @returns {boolean} True if match accas have changes in their selections set.
     *
     * @function Mojito.Services.MatchAcca.utils.hasChanges
     */
    static hasChanges(matchAcca, prevMatchAcca) {
        if (!matchAcca && !prevMatchAcca) {
            return false;
        }
        if (!matchAcca || !prevMatchAcca) {
            return true;
        }
        return !isEqual(matchAcca.selections, prevMatchAcca.selections);
    }

    /**
     * Creates empty match acca prototype object with generated id.
     *
     * @returns {object} Match acca object.
     *
     * @function Mojito.Services.MatchAcca.utils.createMatchAcca
     */
    static createMatchAcca() {
        return {
            id: IdGenerator.generateId(),
            selections: [],
            odds: undefined,
            hasInvalidCombinations: false,
            hasError: false,
        };
    }

    /**
     * Check if matchAcca exists.
     *
     * @param {object} matchAccas - All match accas in state.
     * @param {string} eventId - Event id.
     *
     * @returns {boolean} True if match acca by eventId exists.
     *
     * @function Mojito.Services.MatchAcca.utils.hasMatchAcca
     */
    static hasMatchAcca(matchAccas, eventId) {
        return !!matchAccas[eventId];
    }
}
