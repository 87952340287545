import { isString, isNumber, pickBy, omitBy, isEmpty } from 'mojito/utils';

const MAX_RECURSION_DEPTH = 16;
/**
 * Provides a set of utilities for objects manipulations.
 *
 * @class ObjUtils
 * @name objUtils
 * @memberof Mojito.Core.Base
 */
export default class ObjUtils {
    /**
     * Checks whether the provided value is a plain object.
     * In this context, a plain object is one that is not an array.
     *
     * @param {*} val - Value to check.
     * @returns {boolean} Returns true if the value is a plain object, else false.
     * @function Mojito.Core.Base.objUtils#isPlainObject
     */
    static isPlainObject(val) {
        return typeof val === 'object' && val !== null && !Array.isArray(val);
    }

    /**
     * Flattens the supplied object recursively, removes keys with object values and adds those object keys to the parent object.
     * <br>
     * If the key appears multiple times in the object hierarchy, the resulting value in the flattened object can be any of the values,
     * which one is not determined.
     *
     * @param {object} obj - The object to be flattened.
     * @param {boolean} [stripUndefined = true] - Flag determining whether keys with undefined values should be removed.
     * @returns {undefined}
     * @function Mojito.Core.Base.objUtils#flatten
     */
    static flatten(obj, stripUndefined = true) {
        return ObjUtils._flattenInner(obj, stripUndefined, 0);
    }

    static _flattenInner(obj, stripUndefined, depth) {
        const flattened = {};

        if (depth > MAX_RECURSION_DEPTH) {
            return flattened;
        }

        Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (ObjUtils.isPlainObject(val)) {
                const flatVal = ObjUtils._flattenInner(val, stripUndefined, depth + 1);
                Object.keys(flatVal).forEach(flatValKey => {
                    flattened[flatValKey] = flatVal[flatValKey];
                });
            } else if (!stripUndefined || val !== undefined) {
                flattened[key] = val;
            }
        });

        return flattened;
    }

    /**
     * Recursively merge many objects into one.
     *
     * @param {object} objs - Objects to merge.
     * @returns {object} Returns the merged object.
     * @function Mojito.Core.Base.objUtils#merge
     */
    static merge(...objs) {
        const merged = {};

        objs.forEach(obj => {
            if (Array.isArray(obj)) {
                // Explicitly disallow merging array objects, as it produces nonsense results.
                throw new Error('Cannot merge arrays');
            }

            Object.keys(obj).forEach(key => {
                const sourceVal = obj[key];

                if (sourceVal === undefined) {
                    return; // We ignore keys whose value is undefined
                }

                const targetVal = merged[key];

                const shouldMergeObjects =
                    ObjUtils.isPlainObject(targetVal) && ObjUtils.isPlainObject(sourceVal);

                merged[key] = shouldMergeObjects ? ObjUtils.merge(targetVal, sourceVal) : sourceVal;
            });
        });

        return merged;
    }

    /**
     * Convenience method for merging and flattening multiple objects.
     *
     * @param {object} objs - Objects to merge and flatten.
     * @returns {object} Returns the merged and flattened object.
     * @function Mojito.Core.Base.objUtils#flatMerge
     */
    static flatMerge(...objs) {
        return ObjUtils.flatten(ObjUtils.merge(...objs));
    }

    /**
     * Returns a new object whose key/value pairs are filtered such that the values are either strings or numbers.
     *
     * @param {object} obj - The original object.
     * @returns {object} Returns the filtered object.
     * @function Mojito.Core.Base.objUtils#primitives
     */
    static primitives(obj) {
        if (!obj) {
            throw new TypeError('Bad argument');
        }
        return pickBy(obj, value => {
            return isString(value) || isNumber(value);
        });
    }

    /**
     * Returns a shallow copy of the provided object.
     *
     * @param {object} obj - The object to be shallowly copied.
     * @returns {object} The shallow copy.
     * @function Mojito.Core.Base.objUtils#shallowCopy
     */
    static shallowCopy(obj) {
        return Object.assign({}, obj);
    }

    /* eslint-disable jsdoc/require-description-complete-sentence */
    /**
     * Compares two objects for shallow equality.
     * <br>
     * Returns false if either argument is not an object or is null.
     * <br><br>
     * Note: This comparison is done using strict equality (`===`) for each property. This implies that:
     * <ul>
     * <li>- If an object contains NaN, it won't match itself (NaN !== NaN).</li>
     * <li>- -0 and 0 are not distinguished (-0 === 0).</li>
     * </ul>
     *
     * @param {object} obj1 - The first object to compare.
     * @param {object} obj2 - The second object to compare.
     *
     * @returns {boolean} Returns true if objects are shallowly equal, false otherwise.
     *
     * @function Mojito.Core.Base.objUtils.isShallowEqual
     */
    static isShallowEqual(obj1, obj2) {
        if (
            typeof obj1 !== 'object' ||
            obj1 === null ||
            typeof obj2 !== 'object' ||
            obj2 === null
        ) {
            return false;
        }

        if (obj1 === obj2) {
            return true;
        }

        const obj1Keys = Object.keys(obj1);
        const obj2Keys = Object.keys(obj2);

        if (obj1Keys.length !== obj2Keys.length) {
            return false;
        }

        for (const key of obj1Keys) {
            if (!Object.prototype.hasOwnProperty.call(obj2, key) || obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if all properties of the provided object are undefined.
     *
     * @param {object} obj - The object to check.
     * @returns {boolean} Returns true if all object's values are undefined, false otherwise.
     * @function Mojito.Core.Base.objUtils#lacksValues
     */
    static lacksValues(obj) {
        return isEmpty(omitBy(obj, val => val === undefined));
    }

    /**
     * Determines if the two arrays are deeply equal.
     *
     * @param {Array} arr1 - The first array for comparison.
     * @param {Array} arr2 - The second array for comparison.
     * @returns {boolean} Returns true if arrays are deeply equal, false otherwise.
     * @function Mojito.Core.Base.objUtils#arraysAreEqual
     */
    static arraysAreEqual(arr1, arr2) {
        if (!arr1 || !arr2) {
            return false;
        }
        if (arr1.length !== arr2.length) {
            return false;
        }
        if (arr1.length === 0 && arr2.length === 0) {
            return true;
        }

        for (let idx = 0; idx < arr1.length; idx++) {
            if (arr1[idx] instanceof Array && arr2[idx] instanceof Array) {
                if (!ObjUtils.arraysAreEqual(arr1[idx], arr2[idx])) {
                    return false;
                }
            } else if (arr1[idx] !== arr2[idx]) {
                return false;
            }
        }

        return true;
    }
}
