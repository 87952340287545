import MojitoCore from 'mojito/core';
import EventTypes from 'services/sports-content/events/types.js';

const { OUTRIGHT } = EventTypes.EVENT_TYPE;
const { DateTimeUtils } = MojitoCore.Base;

/**
 * Class containing utility methods related to event groups.
 *
 * @class EventGroupUtils
 * @name utils
 * @memberof Mojito.Services.EventGroups
 */
export default class EventGroupUtils {
    /**
     * Retrieves the number of events in a group, given a map of event groups and an event group key.
     * If no event group key is specified, all events in all event groups are counted.
     *
     * @param {object} eventGroups - A map of event groups.
     * @param {string} [groupId=undefined] - Optional group ID.
     * @param {object} [filter=undefined] - Optional filter to apply on events.
     *
     * @returns {number} Returns the number of events in the group.
     *
     * @function Mojito.Services.EventGroups.utils.countEventsInEventGroups
     */
    static countEventsInEventGroups(eventGroups, groupId, filter) {
        // Sanity check
        if (!eventGroups) {
            return 0;
        }

        let count = 0;

        if (!groupId) {
            // Count all
            Object.keys(eventGroups).forEach(groupItemId => {
                count += EventGroupUtils.countEventsInEventGroups(eventGroups, groupItemId);
            });
        } else {
            const groups = eventGroups[groupId];
            if (groups) {
                count = groups.reduce(
                    (sum, group) =>
                        sum + (filter ? group.events.filter(filter) : group.events).length,
                    0
                );
            }
        }

        return count;
    }

    /**
     * Retrieves a filter function that filters out events with `startTime` too far into the future based on `eventFilterTime`.
     * Note that events of type OUTRIGHT will never be filtered out.
     *
     * @param {object} eventFilterTime - A map of `sportIds` and time in hours. 'default' is used as a fallback for all sports, if it exists.
     * @param {string} sportId - The Sport ID.
     *
     * @returns {Function} Returns a filter function.
     *
     * @function Mojito.Services.EventGroups.utils.getEventTimeFilter
     */
    static getEventTimeFilter(eventFilterTime, sportId) {
        const filterTime = eventFilterTime && (eventFilterTime[sportId] || eventFilterTime.default);
        if (filterTime) {
            return event => {
                const hoursUntilStart = DateTimeUtils.diffInHours(
                    new Date(event.startTime),
                    new Date()
                );

                return hoursUntilStart <= filterTime || event.eventType === OUTRIGHT;
            };
        }
        return event => event;
    }

    /**
     * Filters out events with a `startTime` that is too far into the future based on `eventFilterTime`.
     *
     *@param {Array} events - A list of events with `name` and `startTime`.
     * @param {object} eventFilterTime - Map of `sportIds` and time in hours. 'default' is used as a fallback for all sports, if it exists.
     * @param {string} sportId - The Sport ID.
     *
     * @returns {Function} Returns a filter function.
     *
     * @function Mojito.Services.EventGroups.utils.getTimeFilteredEvents
     */
    static getTimeFilteredEvents(events, eventFilterTime, sportId) {
        if (eventFilterTime) {
            return events.filter(EventGroupUtils.getEventTimeFilter(eventFilterTime, sportId));
        }
        return events;
    }
}
