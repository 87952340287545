import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import SkeletonPartImpl from './skeleton-part.jsx';

/**
 * Component used when creating skeleton loading components to render "boxes" with animation.
 *
 * @class SkeletonPart
 * @memberof Mojito.Presentation.Components
 */

/**
 * SkeletonPart prop types.
 *
 * @property {string} [class] - Class name applied to the root element.
 *
 * @memberof Mojito.Presentation.Components.SkeletonPart
 */
const propTypes = {
    class: PropTypes.string,
};

export default MojitoCore.Presentation.UIView('SkeletonPart', SkeletonPartImpl, propTypes);
