import imsAuthenticationService from 'services/authentication/service/ims-authentication-service';
import ssoAuthenticationService from 'services/authentication/service/sso-authentication-service';
import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('AuthenticationServiceFactory');

const CRITICAL_PROPS = ['service', 'transactionUrl'];

const SSO_SERVICE_TYPE = {
    IMS: 'IMS',
    PAM: 'PAM',
    NONE: 'NONE',
};

const voidResponder = () => log.warn('authenticationService instance is missing.');

const NULL_SERVICE = {
    configure: voidResponder,
    terminate: voidResponder,
    login: voidResponder,
    logout: voidResponder,
    validateSession: voidResponder,
    restoreSession: voidResponder,
    changePassword: voidResponder,
    acceptTermsAndConditions: voidResponder,
    declineTermsAndConditions: voidResponder,
};

/**
 * Singleton object that receives authentication config and spawn service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.Authentication
 */
class ServiceFactory {
    constructor() {
        this.service = NULL_SERVICE;
        this.serviceCreatorRegistry = {
            [SSO_SERVICE_TYPE.IMS]: (config, bettingPlatformAuthService, done) => {
                const { imsConfig, languageCode } = config;
                imsAuthenticationService.configure(
                    imsConfig,
                    bettingPlatformAuthService,
                    languageCode,
                    done
                );
                return imsAuthenticationService;
            },
            [SSO_SERVICE_TYPE.PAM]: (config, bettingPlatformAuthService, done) => {
                const { ssoServiceImpl } = config;
                ssoAuthenticationService.configure(bettingPlatformAuthService, ssoServiceImpl);
                done();
                return ssoAuthenticationService;
            },
            [SSO_SERVICE_TYPE.NONE]: (config, bettingPlatformAuthService, done) => {
                done();
                return bettingPlatformAuthService;
            },
        };
    }

    /**
     * Init factory.
     *
     * @function init
     *
     * @param {Mojito.Services.Authentication.types.AuthenticationConfig} config - Authentication config.
     * @returns {Promise} Promise resolves on service initialisation done.
     *
     * @memberof Mojito.Services.Authentication.serviceFactory
     */
    init(config) {
        this.logMissingProps(config);
        return new Promise(resolve => {
            const {
                service: bettingPlatformAuthService = NULL_SERVICE,
                transactionUrl: serviceUrl,
            } = config;
            bettingPlatformAuthService && bettingPlatformAuthService.configure({ serviceUrl });
            const ssoType = this.detectSSOType(config);
            const createService = this.serviceCreatorRegistry[ssoType];
            this.service = createService(config, bettingPlatformAuthService, resolve);
        });
    }

    /**
     * Service getter.
     *
     * @function getService
     *
     * @returns {Mojito.Services.Authentication.AbstractAuthenticationService} Authentication service instance.
     *
     * @memberof Mojito.Services.Authentication.serviceFactory
     */
    getService() {
        return this.service;
    }

    _withServiceSanity(func) {
        return (...args) => {
            if (!this.authenticationService) {
                log.warn('authenticationService instance is missing');
                return;
            }
            func.apply(this, args);
        };
    }

    detectSSOType(config) {
        const { imsConfig, ssoServiceImpl } = config;
        if (imsConfig) {
            return SSO_SERVICE_TYPE.IMS;
        } else if (ssoServiceImpl) {
            return SSO_SERVICE_TYPE.PAM;
        }
        return SSO_SERVICE_TYPE.NONE;
    }

    logMissingProps(props) {
        CRITICAL_PROPS.forEach(prop => {
            if (!props[prop]) {
                log.error(`No ${prop} was provided to authentication config!`);
            }
        });
    }
}

export default new ServiceFactory();
