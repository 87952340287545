import { isEmpty } from 'mojito/utils';

/**
 * Contains utils specially needed for event list module.
 *
 * @class EventListUtils
 * @memberof Mojito.Modules.EventList
 */
export default class EventListUtils {
    /**
     * Resolves the list of groups which meet limitation settings.
     * It will return no more than maxGroupsToRender groups and will filter out groups which exceed accumulative
     * amount of events controlled by maxEventsToRender param.
     *
     * @param {number} maxGroupsToRender - Number of max allowed groups to be shown.
     * @param {number} maxEventsToRender - Number of max allowed events to be shown across all groups.
     * @param {Array} eventGroups - List of event groups.
     *
     * @returns {Array|undefined} List of event groups which meet limitations.
     * @function Mojito.Modules.EventList.EventListUtils.resolveViewableGroups
     */
    static resolveViewableGroups(maxGroupsToRender, maxEventsToRender, eventGroups) {
        if (!eventGroups) {
            return;
        }
        const hasGroupsLimit = maxGroupsToRender > -1;
        const hasEventsLimit = maxEventsToRender > -1;
        const groups = hasGroupsLimit ? eventGroups.slice(0, maxGroupsToRender) : eventGroups;
        if (!hasEventsLimit) {
            return groups;
        }
        let totalEventCount = 0;
        return groups
            .map(group => {
                const eventsAmountToDrop =
                    totalEventCount + group.events.length - maxEventsToRender;
                const eventsAmountToRender =
                    eventsAmountToDrop > 0
                        ? group.events.length - eventsAmountToDrop
                        : group.events.length;
                totalEventCount += eventsAmountToRender;
                const events = group.events.slice(0, eventsAmountToRender);
                return eventsAmountToDrop > 0 ? { ...group, events } : group;
            })
            .filter(group => group.events.length > 0);
    }

    /**
     * Resolves the list of group ids that should be initially expanded on a view.
     *
     * @param {number} initialExpandedGroups - Number of initially expanded event groups.
     * @param {Array} [eventGroups = []] - List of event groups.
     *
     * @returns {Array} List of initially expanded event group ids.
     * @function Mojito.Modules.EventList.EventListUtils.resolveInitialExpandedGroups
     */
    static resolveInitialExpandedGroups(initialExpandedGroups, eventGroups = []) {
        const expandedCount =
            initialExpandedGroups < 0
                ? eventGroups.length
                : Math.min(initialExpandedGroups, eventGroups.length);
        return eventGroups.slice(0, expandedCount).map(group => group.id);
    }

    /**
     * Resolves l10n translation from optionally nested key structure.
     * Useful for cases when l10n key can be configured in a simple way like 'MARKET_OPTIONS.MATCH_WINNER_HDA' or can have nested dimension e.g., 'MATCH_WINNER_HDA.MATCH_WINNER_HDA.tennis'.
     * In this case l10nKeyBase is 'MARKET_OPTIONS.MATCH_WINNER_HDA' and segment is sport id 'tennis'.
     * Function lookup priority will be 'MATCH_WINNER_HDA.MATCH_WINNER_HDA.tennis' then 'MATCH_WINNER_HDA.MATCH_WINNER_HDA.DEFAULT' and fallback to simple 'MATCH_WINNER_HDA.MATCH_WINNER_HDA'.
     *
     * @param {string} l10nKeyBase - Base l10n key for translation lookup.
     * @param {string} segment - Nested segment extension.
     * @param {Mojito.Core.Base.StringResolver} stringResolver - Instance of string resolver.
     *
     * @returns {string} Localised string.
     * @function Mojito.Modules.EventList.EventListUtils.resolveComplexTranslationString
     */
    static resolveComplexTranslationString(l10nKeyBase, segment, stringResolver) {
        return (
            stringResolver.resolveString(`${l10nKeyBase}.${segment}`, true) ||
            stringResolver.resolveString(`${l10nKeyBase}.DEFAULT`, true) ||
            stringResolver.resolveString(l10nKeyBase, true)
        );
    }

    /**
     * Detects which parts of events list header should be visible for a user.
     *
     * @param {object} config - EventListHeader config object.
     * @param {string} titleText - Title text.
     * @param {Array<string>} marketOptions - List of market options.
     * @param {boolean} isGameLineMode - True if it is game line mode.
     * @param {boolean} isInPlay - True if it is inplay coupon.
     *
     * @returns {object} Object with boolean flags defining which parts of the header are visible.
     * @function Mojito.Modules.EventList.EventListUtils.resolveHeaderAppearance
     */
    static resolveHeaderAppearance(config, titleText, marketOptions, isGameLineMode, isInPlay) {
        const hasTitle = config.showTitle && titleText;
        const hasMarketSelectors = !isEmpty(marketOptions) && config.showMarketSelectors;
        const hasMarketSwitchers = hasMarketSelectors && !isGameLineMode;
        const hasGameLineSwitcher =
            hasMarketSelectors && config.showGameLineSwitcher && isGameLineMode && !isInPlay;
        const nothingToShow = !hasTitle && !hasMarketSwitchers && !hasGameLineSwitcher;
        return { hasTitle, hasMarketSwitchers, hasGameLineSwitcher, nothingToShow };
    }

    /**
     * For multi-column layout it will clean fallback market ids from market infos to not load them if we are in a multi-column markets view.
     * This function is useful because fallback market should be shown only in single column market view.
     *
     * @param {Array<Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo>} marketInfos - Market infos.
     *
     * @returns {Array<Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo>} Market infos.
     * @function Mojito.Modules.EventList.EventListUtils.cleanUpFallbackMarkets
     */
    static cleanUpFallbackMarkets(marketInfos) {
        const isSingleMarketView = marketInfos?.length === 1;
        return marketInfos.map(info => {
            const isFallbackMarket = id => info.fallbackMarketIds?.includes(id);
            const cleanUpFallbacks = marketIds =>
                marketIds.map(marketId => (isFallbackMarket(marketId) ? undefined : marketId));
            // Clean up fallback markets from market ids to not load them if we are in a multi-column markets view.
            // This is because fallback market should be shown only in single column market view.
            const marketIds = isSingleMarketView
                ? info.marketIds
                : cleanUpFallbacks(info.marketIds);
            return { ...info, marketIds };
        });
    }

    /**
     * The function will resolve event info object from event groups for each event id in provided eventIds list.
     *
     * @param {Array<object>} eventGroups - List of event groups.
     * @param {Array<string>} eventIds - List of event ids.
     *
     * @returns {Array<object>} List of event info objects.
     * @function Mojito.Modules.EventList.EventListUtils.resolveEventInfos
     */
    static resolveEventInfos(eventGroups, eventIds) {
        return eventIds.map(eventId => {
            const hasEventId = event => event.id === eventId;
            const eventGroup = eventGroups.find(group => group.events.find(hasEventId));
            return eventGroup.events.find(hasEventId);
        });
    }
}
