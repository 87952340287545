import PropTypes from 'prop-types';
import FormattedLabelWithTotalOddsImpl from './formatted-label-with-total-odds.jsx';
import MojitoCore from 'mojito/core';

/**
 * A component that displays a label and total odds inside a container.
 *
 * @class FormattedLabelWithTotalOdds
 * @memberof Mojito.Presentation.Components
 */

/**
 * `FormattedLabelWithTotalOdds` component prop types.
 *
 * @property {string} [odds] - The odds to display.
 * @property {boolean} [isTotalOddsApplicable = false] - The flag to determine if show/hide total odds.
 *
 * @memberof Mojito.Presentation.Components.FormattedLabelWithTotalOdds
 */
const propTypes = {
    odds: PropTypes.string,
    isTotalOddsApplicable: PropTypes.bool,
};

const defaultProps = {
    isTotalOddsApplicable: false,
};

export default MojitoCore.Presentation.UIView(
    'FormattedLabelWithTotalOdds',
    FormattedLabelWithTotalOddsImpl,
    propTypes,
    defaultProps
);
