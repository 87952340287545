import { useState, useEffect, useRef } from 'react';
import MojitoCore from 'mojito/core';
import { Transition } from 'react-transition-group';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const uiStyle = MojitoCore.Presentation.uiStyle;

const { useStringResolver } = MojitoCore.Presentation.Hooks;

const ScoreCount = props => {
    const {
        score,
        mojitoTools: { style, config, l10n },
    } = props;
    const { goalAnimationDuration } = config;
    const { animatedScore: animatedScoreStyle, goal: goalStyle, score: scoreStyle } = style;

    const [showGoalAnimation, setShowGoalAnimation] = useState(false);
    const [useAnimatedStyle, setUseAnimatedStyle] = useState(false);
    const [scoreCount, setScoreCount] = useState(score);

    const isMounted = useRef(false);
    const stringResolver = useStringResolver(l10n);

    const isScoreCancelled = (newScore, previousScore) => {
        // Score count needs to handle cancellation of a score, where no animation should be shown.
        // Currently we only support sports with scores that are numbers counting up. If the score goes down, it is
        // treated as a cancelled score.
        return parseInt(newScore, 10) - parseInt(previousScore, 10) < 0;
    };
    const timeoutRef = useRef();
    useEffect(() => {
        if (isMounted.current) {
            if (isScoreCancelled(score, scoreCount)) {
                setScoreCount(score);
            } else {
                setShowGoalAnimation(true);
                timeoutRef.current = setTimeout(() => {
                    setShowGoalAnimation(false);
                    setUseAnimatedStyle(false);
                    setScoreCount(score);
                }, goalAnimationDuration);
            }
        } else {
            isMounted.current = true;
        }
        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score, goalAnimationDuration]);

    const renderGoal = (state, goalStringStyle) => {
        return (
            <div className="ta-goal" style={goalStringStyle[state]}>
                {stringResolver.resolveString('$SCORE_COUNT.GOAL')}
            </div>
        );
    };
    const handleGoalAnimationEnded = () => {
        setUseAnimatedStyle(true);
    };
    const transitionRef = useRef();

    return (
        <FlexPane>
            <Transition
                nodeRef={transitionRef}
                in={showGoalAnimation}
                appear
                timeout={goalAnimationDuration}
                onExited={() => handleGoalAnimationEnded()}
            >
                {state => renderGoal(state, goalStyle)}
            </Transition>
            <span
                className="ta-scoreCount"
                style={useAnimatedStyle ? animatedScoreStyle : scoreStyle}
            >
                {scoreCount}
            </span>
        </FlexPane>
    );
};

ScoreCount.getStyle = (config, applicationMode, merge) => {
    const {
        style: { goalContainer, scoreText },
    } = config;
    const goal = {
        position: 'absolute',
        minWidth: '40px',
        width: '100%',
        top: 0,
        zIndex: 999,
        ...goalContainer,
    };
    const goalHidden = { opacity: 0, ...goal };
    const goalVisible = { opacity: 1, ...goal };
    const animatedScore = merge(scoreText, {
        ...uiStyle.createCssAnimation(config, 'score-animation', config.animation),
    });
    return {
        goal: {
            unmounted: goalHidden,
            entering: goalVisible,
            entered: goalVisible,
            exiting: goalVisible,
            exited: goalHidden,
        },
        score: scoreText,
        animatedScore,
    };
};

export default ScoreCount;
