import PropTypes from 'prop-types';
import HideablePaneImpl from './hideable-pane.jsx';
import MojitoCore from 'mojito/core';

/**
 * A component that either displays or hides its contents, with a transition between these states that can be configured.
 *
 * @class HideablePane
 * @memberof Mojito.Presentation.Components
 */

/**
 * `HideablePane` component prop types.
 *
 * @property {boolean} [hidden = false] - Determines the visibility of the pane's content.
 * @memberof Mojito.Presentation.Components.HideablePane
 */
const propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    hidden: false,
};

export default MojitoCore.Presentation.UIView(
    'HideablePane',
    HideablePaneImpl,
    propTypes,
    defaultProps
);
