import Hooks from 'core/presentation/hooks';
import AnalyticsContext from './index.js';
import PropTypes from 'prop-types';
import { isString, isObject, isNumber } from 'mojito/utils';

/**
 * Extending strategy implementation that extends `context.analyticsPath` property
 * by concatenating provided `value` to existing `analyticsPath` value with '-' separator.
 * <br>
 * E.g., if the path was 'inplay' after calling this strategy with `value = 'market'`,
 * the resulting `analyticsPath` will be 'inplay-market'.
 *
 * @function extendAnalyticsPath
 * @param {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} context - Analytics context object of type {@link Mojito.Core.Presentation.AnalyticsContext.ContextDefinition|AnalyticsContextDefinition}.
 * @param {string} value - The string used to extend the existing context path.
 *
 * @returns {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} Extended context.
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */
export function extendAnalyticsPath(context, value) {
    if (!value) {
        return context;
    }
    const path = context.analyticsPath;
    const analyticsPath = path ? `${path}-${value}` : value;
    return { ...context, analyticsPath };
}

/**
 * Extending strategy implementation that extends `context.data` object with provided value using simple merge.
 *
 * @function extendAnalyticsData
 * @param {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} context - Analytics context object of type {@link Mojito.Core.Presentation.AnalyticsContext.ContextDefinition|AnalyticsContextDefinition}.
 * @param {object} value - The object used to extend the context.
 *
 * @returns {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} The extended context.
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */
export function extendAnalyticsData(context, value) {
    if (!value || !isObject(value) || isNumber(value) || isString(value)) {
        return context;
    }
    const currentData = context.data || {};
    const newData = { ...currentData, ...value };
    return { ...context, data: newData };
}

/**
 * Extending strategy implementation that reuse `extendAnalyticsPath` and `extendAnalyticsData`
 * to extend data and analyticsPath and merge them into current context.
 *
 * @function extendAnalyticsContext
 * @param {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} context - Analytics context object of type {@link Mojito.Core.Presentation.AnalyticsContext.ContextDefinition|AnalyticsContextDefinition}.
 * @param {object} value - The object used to extend the context.
 *
 * @returns {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} The extended context.
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */
export function extendAnalyticsContext(context, value) {
    const { analyticsPath } = extendAnalyticsPath(context, value.analyticsPath);
    const { data } = extendAnalyticsData(context, value.data);
    return { ...context, analyticsPath, data };
}

/**
 * A strategy function for extending context.
 *
 * @callback extendingStrategy
 * @property {Mojito.Core.Presentation.AnalyticsContext.AnalyticsContextDefinition} context - Analytics context object of type {@link Mojito.Core.Presentation.AnalyticsContext.ContextDefinition|AnalyticsContextDefinition}.
 * @property {*} value - Object or primitive type used to extend context.
 *
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 */

/**
 * A functional component that extends the analytics context with the `value` prop using
 * a provided `extendingStrategy`. If no strategy is provided, `extendAnalyticsContext`
 * function is used as default strategy.
 * <br>
 * In the following example, the usage of the BetslipView component is illustrated. It should be noted that an extended analytics context is received by this component and all its child components. Specifically, the value 'Betslip' is assigned to analyticsPath in this context, facilitating the emission of analytics.
 *
 * @example import MojitoCore from 'mojito/core';
 * import React from 'react';
 *
 * const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;
 * const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
 * const BetslipView = MojitoModules.BetslipView.View;
 *
 * class BetslipControllerView extends React.Component {
 *    constructor(props) {
 *      super(props);
 *      this.extendingContext = new AnalyticsContextBuilder().withAnalyticsPath('Betslip').build();
 *    }
 *
 *    render() {
 *      return (
 *        <AnalyticsContextExtender value={this.extendingContext}>
 *          <BetslipView />
 *        </AnalyticsContextExtender>
 *      );
 *    }
 * }
 *
 * @function AnalyticsContextExtender
 * @memberof Mojito.Core.Presentation.AnalyticsContext
 *
 * @param {*} props - Props object.
 * @param {Function} [props.extendingStrategy = Mojito.Core.Presentation.AnalyticsContext.extendAnalyticsContext] - Strategy function to extend context.
 * @param {*} props.value - Value to extend the current analytics context.
 * @param {node} props.children - Children that will receive extended context.
 * @returns {React.ReactElement} A React element ready to be rendered.
 */
export default function AnalyticsContextExtender(props) {
    const context = Hooks.useAnalyticsContext();
    const extendedContext = Hooks.useContextExtender(
        context,
        props.value,
        props.extendingStrategy || extendAnalyticsContext
    );

    return (
        <AnalyticsContext.Provider value={extendedContext}>
            {props.children}
        </AnalyticsContext.Provider>
    );
}

AnalyticsContextExtender.propTypes = {
    extendingStrategy: PropTypes.func,
    value: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
};
