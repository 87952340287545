import { useCallback, useEffect, useRef, useState } from 'react';
import { useTimeout } from 'presentation/hooks';

/**
 * Loader suspense related hooks.
 *
 * @name loaderSuspenseHooks
 * @memberof Mojito.Presentation
 */

/**
 * The hook defines the visibility state of a loader component. Handy to be used on a views that are waiting for
 * content load from a server. The hook returns true if isContentPending is true and if min show up delay is not over.
 * By default, hook supports only one time loader appearance per component life cycle which means that it reacts on one change of isContentPending flag from true to false.
 * In order to show loader each time isContentPending is set to true, the unique contentHash argument should be provided on each new loading cycle.
 *
 * @function useLoaderVisibility
 *
 * @param {number} minDelay - Minimum delay in ms until loader visibility is guaranteed. No matter how fast the content was, the loader will be shown at least that amount of time.
 * @param {boolean} isContentPending - True if content is in still in pending state. Hence, the loader should be visible.
 * @param {*} [contentHash] - Content hash used to identify the content type change and force loader to be shown again.
 *
 * @returns {boolean} True if loader should be shown, otherwise false.
 * @memberof Mojito.Presentation.loaderSuspenseHooks
 */
export const useLoaderVisibility = (minDelay, isContentPending, contentHash) => {
    const [isTimerOver, setTimerOver] = useState();
    const [contentCache, addContentHash] = useContentCache();

    const isNeverExistedContent = contentCache[contentHash] === undefined;
    const isNeverLoadedContent = isNeverExistedContent || contentCache[contentHash] === false;
    const isPendingNeverLoadedContent = isContentPending && isNeverLoadedContent;
    const isContentReadyOnMount = !isContentPending && isNeverExistedContent;
    // Storing content loading state in a cache to not show loader for it any more even if content is being reload again.
    if (isNeverExistedContent || !isContentPending) {
        addContentHash(contentHash, !isContentPending);
    }
    const { startTimer, isRunning: isTimerRunning } = useTimeout(minDelay, () =>
        setTimerOver(true)
    );

    // We will start timer if content is loading for the first time.
    useEffect(() => {
        if (isPendingNeverLoadedContent) {
            startTimer();
            setTimerOver(false);
        } else if (!isTimerRunning) {
            setTimerOver(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPendingNeverLoadedContent]);

    // Need to display loader if content is still loading for the first time and if min loader timeout is not over.
    const isLoaderVisible =
        (isPendingNeverLoadedContent || isTimerOver === false) && !isContentReadyOnMount;

    // We will postpone loader removal to the next macrotask to allow potential content to render itself.
    return usePostponedVisibility(isLoaderVisible);
};

const usePostponedVisibility = isVisible => {
    const [postponedVisibility, setPostponedVisibility] = useState(isVisible);
    useEffect(() => {
        let timeout;
        if (!isVisible) {
            timeout && clearTimeout(timeout);
            timeout = setTimeout(() => setPostponedVisibility(false));
        } else {
            setPostponedVisibility(true);
        }
        return () => clearTimeout(timeout);
    }, [isVisible]);

    return isVisible ? isVisible : postponedVisibility;
};

const useContentCache = () => {
    const cacheRef = useRef({});
    const add = useCallback((hash, isLoaded) => {
        cacheRef.current[hash] = isLoaded;
    }, []);
    return [cacheRef.current, add];
};
