import PropTypes from 'prop-types';
import SelectableButtonImpl from './selectable-button.jsx';
import MojitoCore from 'mojito/core';
import {
    propTypes as buttonPropTypes,
    defaultProps as buttonDefaultProps,
} from 'presentation/components/button/index.jsx';

/**
 * SelectableButton component. Should be used whenever there is a clickable UI element with a selected/clicked indicator.
 *
 * @class SelectableButton
 * @memberof Mojito.Presentation.Components
 */

/**
 * Prop types for the `SelectableButton` component.
 *
 * @property {boolean} [disabled = false] - Flag controlling the button's disabled state. A disabled button is not clickable and has additional styles.
 * @property {boolean} [active = false] - Flag controlling the button's active state. An active button has additional styles.
 * @property {boolean} [hidden = false] - Flag controlling the button's visibility. A hidden button is not visible but still affects the layout.
 * @property {string} [hrefLink] - A string for the `href` attribute of the `<a>` link for SEO purposes. If not provided or if `config.enableAHref` is not set, a `<div>` is used in place of an `<a>`.
 * @property {Function} [onActiveChange = ()=>{}] - Callback on active state change. The callback gets true or false based on the active state.
 * @property {Function} [onClick = ()=>{}] - Click callback. Returns the onClick event and onClickData.
 * @property {*} [onClickData] - Data returned with the onClick callback as the second argument.
 * @property {object | Function} [buttonElementRef] - Ref to the button's rendered element.
 * @property {string} [class] - Class names assigned to the button's root element.
 * @property {boolean} [selected = false] - Flag controlling the button's selectedIndicator visibility.
 * @property {node|Array<node>} [selectedIndicatorContent] - Content to be displayed in the selectedIndicator.
 * @property {string} [selectableKey] - Key that identifies the current selected button when used.
 *
 * @memberof Mojito.Presentation.Components.SelectableButton
 */
const propTypes = {
    ...buttonPropTypes,
    selected: PropTypes.bool,
    selectedIndicatorContent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    selectableKey: PropTypes.string,
};

const defaultProps = {
    ...buttonDefaultProps,
    selected: false,
};

export default MojitoCore.Presentation.UIView(
    'SelectableButton',
    SelectableButtonImpl,
    propTypes,
    defaultProps
);
