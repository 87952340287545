/**
 * Services related core classes.
 *
 * @namespace Services
 * @memberof Mojito.Core
 */

import ConfigRegistry, { configRegistry } from 'core/config/config-registry.js';
import * as ConfigUtils from 'core/config/utils.js';
import ConfigFactory, { configFactory, RESERVED_PROPS } from 'core/config/config-factory.js';

/**
 * @namespace Config
 * @memberof Mojito.Core.Services
 */
// Not tree shakable
const Config = {
    Utils: ConfigUtils,
    configRegistry: configRegistry,
    ConfigRegistry: ConfigRegistry,
    configFactory,
    ConfigFactory,
    RESERVED_PROPS,
};

export { default as AbstractContentTransporter } from './transporter/abstract-content-transporter.js';
export { default as ApiProvider } from './api-provider/api-provider.js';
export { Config };
export * as Content from './content/_namespace.js';
export { default as CurrencyConfig } from './currency-config';
export { default as DataProvider } from './data-provider/data-provider.js';
export { default as RequestDataHelper } from './request-data-helper';
export * as Storage from './storage/_namespace.js';
export * as CookieConsent from './cookie-consent/_namespace.js';
export * as SystemSettings from './system-settings/_namespace.js';
export { default as TaskExecutor } from './task-executor/index.js';
export * as Transactions from './transactions/_namespace.js';
export { default as TranslationService } from './localization/translation-service';
export { default as transporterFactory } from './transporter/transporter-factory.js';
export { default as redux } from './redux-instance';
export * as reduxActions from './redux-instance/actions.js';
export * as Performance from './performance/_namespace.js';
