import InfiniteListImpl from './infinite-list.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';
const UIView = MojitoCore.Presentation.UIView;

/**
 * InfiniteList is a component that displays a long list of elements within a scrollable container.
 * The size of the list can dynamically increase by fetching more elements when the end of the list is reached.
 *
 * @class InfiniteList
 * @memberof Mojito.Presentation.Components
 */

/**
 * `InfiniteList` component prop types.
 *
 * @property {boolean} [hasMore = false] - If true, more elements can be fetched.
 * @property {Function} [fetchMore = ()=>{}] - Invoked when the end of the list is reached to fetch more elements. Previous elements should be included in the updated list when new ones are added.
 * @property {Array<node>} children - The elements to display in the list.
 * @property {node} [pendingStateComponent] - A custom component that overrides the standard one which displays a spinner when fetching more list elements.
 * @property {node|string} [scrollableTarget] - Optional. A reference to a (parent) DOM element that is already providing overflow scrollbars to the InfiniteList component. It is preferable to provide the id of the DOM node.
 *
 * @memberof Mojito.Presentation.Components.InfiniteList
 */
const propTypes = {
    hasMore: PropTypes.bool,
    fetchMore: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    pendingStateComponent: PropTypes.object,
    scrollableTarget: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const defaultProps = {
    hasMore: false,
    fetchMore: noop,
};

export default UIView('InfiniteList', InfiniteListImpl, propTypes, defaultProps);
