/**
 *
 * Abstract analytics emitter to be implemented for emitting analytics data.
 *
 * @class AbstractAnalyticsEmitter
 * @memberof Mojito.Core.Presentation
 */
export default class AbstractAnalyticsEmitter {
    /**
     * Emits analytics data.
     *
     * @param {string} type - Type of analytics event.
     * @param {*} data - Any type of data to be used in the analytics report.
     * @function Mojito.Core.Presentation.AbstractAnalyticsEmitter#emitAnalytics
     */
    // eslint-disable-next-line no-unused-vars
    emitAnalytics(type, data) {
        // To be implemented by subclasses
    }
}
