import MojitoCore from 'mojito/core';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
const NamedStorageService = MojitoCore.Services.Storage.NamedService;
import { selectBannerClickCount } from './selectors.js';

const reduxInstance = MojitoCore.Services.redux;
const STORAGE_KEY = 'appBannerCounter';

const appBannerStorage = new NamedStorageService(STORAGE_KEY);

/**
 * Defines the state of the app banner.
 *
 * @typedef AppBannerState
 *
 * @property {number} bannerClickCount - Banner click counter.
 * @property {number} defaultHideNumber - Default value for maximum close clicks before hiding AppBanner.
 * @property {boolean} bannerClosed - Flag indicating if banner is closed.
 * @memberof Mojito.Services.AppBanner
 */

/**
 * The name of the banner app store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.AppBanner
 */
export const STORE_KEY = 'appBannerStore';

export const INITIAL_STATE = {
    bannerClickCount: parseInt(appBannerStorage.getItem(STORAGE_KEY)) || 0,
    defaultHideNumber: 0,
    bannerClosed: false,
};

export const { reducer, actions } = createSlice({
    name: 'appBanner',
    initialState: INITIAL_STATE,
    reducers: {
        onCloseClick(state) {
            state.bannerClickCount++;
            state.bannerClosed = true;
        },
        onLinkClick(state) {
            state.bannerClickCount = state.defaultHideNumber;
        },
        configure(state, { payload }) {
            state.defaultHideNumber = payload.defaultHideNumber;
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * App banner related actions.
 *
 * @class AppBannerActions
 * @name actions
 * @memberof Mojito.Services.AppBanner
 */

/**
 * Increment close clicks number.
 *
 * @function onCloseClick
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.AppBanner.actions
 */

/**
 * Increment link clicks number.
 *
 * @function onLinkClick
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.AppBanner.actions
 */

/**
 * Set the configurations of app banner.
 *
 * @function configure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.AppBanner.actions
 */

/**
 * Reset app banner state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.AppBanner.actions
 */

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(actions.onCloseClick, actions.onLinkClick),
    effect: (action, listenerApi) => {
        appBannerStorage.setItem(selectBannerClickCount(listenerApi.getState()));
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
