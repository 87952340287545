import MojitoCore from 'mojito/core';
import AbstractMatchAccaService from './abstract-match-acca-service.js';

const RequestFactory = MojitoCore.Services.Transactions.RequestFactory;
const BASE_URL = '/betslip';
const logger = MojitoCore.logger.get('MatchAccaService');
const CALCULATE_ODDS_URL = 'utils/calculate-odds';

/**
 * Match acca service config.
 *
 * @typedef MatchAccaServiceConfig
 * @type {object}
 * @property {string} serviceUrl - MatchAcca API endpoint URL.
 *
 * @memberof Mojito.Services.MatchAcca
 */

/**
 *
 * @class MatchAccaService
 * @name service
 * @extends Mojito.Services.MatchAcca.AbstractMatchAccaService
 * @memberof Mojito.Services.MatchAcca
 */
class MatchAccaService extends AbstractMatchAccaService {
    constructor() {
        super();
    }
    /**
     * Configure service instance.
     *
     * @param {Mojito.Services.MatchAcca.service.MatchAccaServiceConfigure} config - Service configuration object.
     *
     * @function Mojito.Services.MatchAcca.service#configure
     */
    configure(config) {
        this.serviceUrl = config.serviceUrl;
        this.requestFactory = new RequestFactory(logger);
        this.apiUrl = `${this.serviceUrl + BASE_URL}`;
    }

    /**
     * Calculate odds.
     *
     * @param {Array<Mojito.Services.Betslip.types.Selection>} selectionsLine - List of selections.
     * @param {Mojito.Services.Betslip.types.LEG_SORT} legSort - Leg sort.
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
     *
     * @returns {Promise} Calculate odds promise.
     * @function Mojito.Services.MatchAcca.MatchAccaService#calculateOdds
     */
    calculateOdds(selectionsLine, legSort, oddsFormat) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(this.getCalculateOddsUrl())
            .withRawBodyResponse()
            .withDescription('calculate odds')
            .withCredentials()
            .send({ selectionsLine, legSort, oddsFormat });
    }

    /**
     * Generate api endpoint url for api call to calculate odds.
     *
     * @returns {string} API call url.
     * @function Mojito.Services.MatchAcca.service#getCalculateOddsUrl
     */
    getCalculateOddsUrl() {
        return `${this.apiUrl}/${CALCULATE_ODDS_URL}`;
    }

    /**
     * Validate service url.
     *
     * @private
     * @returns {boolean} True if service url exists, else false.
     */
    validateServiceUrl() {
        if (!this.serviceUrl) {
            logger.error('Service URL is not set');
            return false;
        }
        return true;
    }
}

export default new MatchAccaService();
