import { noop, once } from 'mojito/utils';
import TransactionRequest from './transaction-request';

/**
 * Callback to notify that there was intention to execute request.
 * Used to register request for delayed execution.
 *
 * @param {Mojito.Core.Services.Transactions.PostponedRequest} self - The reference to self postponed request object.
 * @callback Mojito.Core.Services.Transactions.PostponedRequest.sendCallback
 */

/**
 * Request constructor.
 *
 * @class PostponedRequest
 * @param {Mojito.Core.Services.Transactions.TransactionRequest} request - Transaction request to be wrapped.
 * @param {Mojito.Core.Services.Transactions.PostponedRequest.sendCallback} [sendCallback = noop] - Send request callback. Executes once send is called.
 * @classdesc Class offering generic functionality for requests towards transactional mojito API with possibility to be postponed ignoring immediate request execution.
 * Instead, <code>sendCallback</code> will be triggered to notify that there was intention to execute request.
 *
 * @extends Mojito.Core.Services.Transactions.TransactionRequest
 * @memberof Mojito.Core.Services.Transactions
 */
export default class PostponedRequest extends TransactionRequest {
    constructor(request, sendCallback = noop) {
        super(request.url, request.method, request.logger);
        this.sendCallback = once(sendCallback);
        this.resolveSend = noop;
        this.rejectSend = noop;
        this.payload = undefined;
    }

    /**
     * Prepares send `data` as the request body. Delegates call to fetch() api.
     *
     * @param  {object} data - Data object will be passed to fetch API function.
     * @returns {Promise} Promise resolves on fetch response processed. Rejects on network error or if response has status other than 2XX.
     * @function Mojito.Core.Services.Transactions.PostponedRequest#send
     */
    send(data) {
        this.payload = data;
        return new Promise((resolve, reject) => {
            this.sendCallback(this);
            this.resolveSend = resolve;
            this.rejectSend = reject;
        });
    }

    /**
     * Execute request. Delegates call to {@link Mojito.Core.Services.Transactions.TransactionRequest#send|send method}.
     *
     * @function Mojito.Core.Services.Transactions.PostponedRequest#execute
     */
    execute() {
        super.send(this.payload).then(this.resolveSend).catch(this.rejectSend);
    }
}
