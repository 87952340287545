import MojitoCore from 'mojito/core';
const ApiProvider = MojitoCore.Services.ApiProvider;

const log = MojitoCore.logger.get();

/**
 * Abstract promotions service to be implemented by different integrations.
 *
 * @class AbstractPromotionsService
 * @abstract
 * @memberof Mojito.Services.Promotions
 */
export default class AbstractPromotionsService extends ApiProvider {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {{}} config - Config object.
     *
     * @function Mojito.Services.Promotions.AbstractPromotionsService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Get promotions.
     *
     * @function Mojito.Services.Promotions.AbstractPromotionsService#getPromotions
     */
    getPromotions() {
        log.error('getPromotions must be implemented by concrete subclass!');
    }
}
