import MojitoCore from 'mojito/core';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { selectEventSubscription, selectFollowEventInfo, selectIsPending } from './selectors.js';
import serviceFactory from './service/service-factory';
import { omit, pickBy } from 'mojito/utils';
const reduxInstance = MojitoCore.Services.redux;
const { NamedService: NamedStorageService } = MojitoCore.Services.Storage;

const subscriptionsStorage = new NamedStorageService('notificationSubscriptions');
const onboardingAcknowledgeStorage = new NamedStorageService(
    'isNotificationSubscriptionsAcknowledged'
);
const log = MojitoCore.logger.get('ContentNotificationStore');

/**
 * Defines the state of the sports content notifications store.
 * Subscriptions for notification are stored persistently in local storage.
 *
 * @typedef SportsContentNotificationsState
 * @property {object} subscriptions - Notifications subscriptions. Key is event ID, value - {@link Mojito.Services.SportsContent.ContentNotifications.types.EventSubscriptionInfo|EventSubscriptionInfo}.
 * @property {object} pendingFollowEventIds - Pending follow requests. Key is event ID, value - boolean flag if event is pending.
 * @property {object} pendingUnfollowEventIds - Pending unfollow requests. Key is event ID, value - boolean flag if event is pending.
 * @property {boolean} onboardingAcknowledged - True if onboarding is acknowledged, false otherwise.
 * @property {{daysUntilDeletion: number, daysUntilDeletionForOutrights: number}} options - Defines for how long to keep subscriptions.
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */

/**
 * The name of the sports content notifications state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */
export const STORE_KEY = 'contentNotificationsStore';

/**
 * Sports content notifications configuration.
 *
 * @typedef ContentNotificationStoreConfig
 * @type {object}
 * @property {Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService} service - Notification service implementation.
 * @property {number} daysUntilDeletion - How many days to keep notification subscriptions for event in local storage.
 * @property {number} daysUntilDeletionForOutrights - How many days to keep notification subscriptions for outright event in local storage.
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */

/**
 * Sports content notifications initial state.
 *
 * @typedef initialState
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */
export const initialState = {
    subscriptions: {},
    pendingFollowEventIds: {}, // Used as a set
    pendingUnfollowEventIds: {}, // Used as a set
    onboardingAcknowledged: false,
    options: {
        daysUntilDeletion: 5,
        daysUntilDeletionForOutrights: 300,
    },
};

export const { reducer, actions } = createSlice({
    name: 'sportsContentNotifications',
    initialState: initialState,
    reducers: {
        followEventSuccess(state, { payload }) {
            delete state.pendingFollowEventIds[payload.eventId];
            state.subscriptions[payload.eventId] = payload.subscription;
        },
        unfollowEventSuccess(state, { payload: eventId }) {
            delete state.pendingUnfollowEventIds[eventId];
            delete state.subscriptions[eventId];
        },
        serviceCallStarted(state, { payload }) {
            if (payload.method === 'followEvent') {
                state.pendingFollowEventIds[payload.eventId] = true;
            } else {
                state.pendingUnfollowEventIds[payload.eventId] = true;
            }
        },
        serviceCallFailed(state, { payload: eventId }) {
            delete state.pendingFollowEventIds[eventId];
            delete state.pendingUnfollowEventIds[eventId];
        },
        resetToExternalState(state, { payload }) {
            return {
                ...initialState,
                onboardingAcknowledged: payload.onboardingAcknowledged,
                subscriptions: payload.subscriptions,
            };
        },
        acknowledgeOnboarding(state) {
            state.onboardingAcknowledged = true;
        },
        reset() {
            return { ...initialState };
        },
        configure(state, { payload: options }) {
            state.options = { ...options, ...initialState.options };
        },
    },
});

actions.init = config => dispatch => {
    serviceFactory.init(config.service);
    dispatch(actions.configure(omit(config, 'service')));
};

actions.loadFromExternalState = () => dispatch => {
    const subscriptions = sanitizeSubscriptionsInLocalStorage();
    const onboardingAcknowledged = !!onboardingAcknowledgeStorage.getItem();
    dispatch(actions.resetToExternalState({ subscriptions, onboardingAcknowledged }));
};

const handleEvents = handleEvent => eventIds => (dispatch, getState) => {
    const service = serviceFactory.getService();
    if (service.isActivated()) {
        eventIds.forEach(id => handleEvent(id, dispatch, getState()));
    } else {
        service
            .activate()
            .then(() => eventIds.forEach(id => handleEvent(id, dispatch, getState())));
    }
};

actions.followEvents = handleEvents(followEvent);
actions.unfollowEvents = handleEvents(unfollowEvent);

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(actions.followEventSuccess, actions.unfollowEventSuccess),
    effect: (_, listenerApi) => {
        const state = listenerApi.getState()[STORE_KEY];
        subscriptionsStorage.setItem(state.subscriptions);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.acknowledgeOnboarding,
    effect: () => onboardingAcknowledgeStorage.setItem(true),
});

reduxInstance.injectReducer(STORE_KEY, reducer);

function sanitizeSubscriptionsInLocalStorage() {
    const subscriptions = subscriptionsStorage.getItem() || {};
    const validSubscriptions = pickBy(subscriptions, item => item.expired > Date.now());
    subscriptionsStorage.setItem(validSubscriptions);
    return validSubscriptions;
}

function followEvent(eventId, dispatch, state) {
    if (selectIsPending(eventId, state)) {
        return;
    }
    const eventInfo = selectFollowEventInfo(eventId, state);
    const subscription = selectEventSubscription(eventId, state);
    callService(
        'followEvent',
        eventInfo,
        () => dispatch(actions.followEventSuccess({ eventId, subscription })),
        error => {
            log.warn(`Error subscribing for notification for event with ID "${eventId}"`, error);
            dispatch(actions.serviceCallFailed(eventId));
        },
        dispatch
    );
}

function unfollowEvent(eventId, dispatch, state) {
    if (selectIsPending(eventId, state)) {
        return;
    }
    const eventInfo = selectFollowEventInfo(eventId, state);
    callService(
        'unfollowEvent',
        eventInfo,
        () => dispatch(actions.unfollowEventSuccess(eventId)),
        error => {
            log.warn(`Error unsubscribing from notification for event with ID "${eventId}"`, error);
            dispatch(actions.cancelPending(eventId));
        },
        dispatch
    );
}

function callService(method, eventInfo, onSuccess, onError, dispatch) {
    if (eventInfo) {
        dispatch(actions.serviceCallStarted({ eventId: eventInfo.eventId, method }));
        serviceFactory.getService()[method](eventInfo).then(onSuccess).catch(onError);
    }
}

/**
 * Follow event call has succeeded.
 *
 * @function followEventSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{eventId: string, subscription: Mojito.Services.SportsContent.ContentNotifications.types.EventSubscriptionInfo}} payload - Event ID and subscription.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Unfollow event call has succeeded.
 *
 * @function unfollowEventSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} payload - Event ID.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Follow/Unfollow call has started.
 *
 * @function serviceCallStarted
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{method: string, eventId: string}} payload - Method (follow/unfollow) and event ID.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Follow/Unfollow call has failed.
 *
 * @function serviceCallFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} payload - Event ID.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Reset to external state.
 *
 * @function resetToExternalState
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{onboardingAcknowledged: boolean, subscriptions: Array<Mojito.Services.SportsContent.ContentNotifications.types.EventSubscriptionInfo>}} payload - Onboarding acknowledged flag and subscriptions.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Reset sports content notifications state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Configure store.
 *
 * @function configure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.SportsContent.ContentNotifications.ContentNotificationStoreConfig} config - Content notifications options, service is not included.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */
/**
 * Init content notifications service and configure store.
 *
 * @function init
 * @param {Mojito.Services.SportsContent.ContentNotifications.ContentNotificationStoreConfig} config - Content notifications config.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Acknowledge event notifications onboarding.
 *
 * @function acknowledgeOnboarding
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Follow events.
 *
 * @function followEvents
 * @param {Array} eventIds - Event IDs.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Unfollow events.
 *
 * @function unfollowEvents
 * @param {Array} eventIds - Event IDs.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */

/**
 * Load notification subscriptions and acknowledgement status from external state (currently local storage).
 *
 * @function loadFromExternalState
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.SportsContent.ContentNotifications.actions
 */
