import MojitoCore from 'mojito/core';
import SettingsMenuPopupImpl from './settings-menu-popup.jsx';

/**
 * A View that provides a popup for the Settings menu.
 *
 * @class SettingsMenuPopup
 * @memberof Mojito.Modules
 */
export default MojitoCore.Presentation.UIView('SettingsMenuPopup', SettingsMenuPopupImpl);
