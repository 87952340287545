import PropTypes from 'prop-types';
import SearchResultItemImpl from './search-result-item.jsx';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;
const { RESULT_TYPE } = MojitoServices.Search.types;

/**
 * View for search result item presentation.
 *
 * @class SearchResultItem
 * @memberof Mojito.Application.Search
 */

/**
 * SearchResultItem prop types.
 *
 * @property {Mojito.Services.Search.types.RESULT_TYPE} resultType - Result type.
 * @property {string} id - ID of the search result item. Depending on `resultType`, it can be event, sport ID etc.
 * @property {string} name - Name of the result item.
 * @property {string} sportId - Sports ID.
 * @property {string} [parentName] - Name of the result item container.
 * @property {string} [sportClassName] - Name of the sport class name (region) for result item container.
 * @property {string} [classId] - Class ID.
 * @property {boolean} [inPlay] - In-play flag for the event.
 * @property {string} [startTime] - Start time, used only for event.
 * @property {string} [raceMeetingId] - Race meeting ID.
 * @property {boolean} [isVirtual] - Whether event virtual.
 * @property {string} [afterRedirection = ()=>{}] - Click on item redirects user, then callback invoked.
 * @property {boolean} [isOutright] - Whether event outright.
 * @memberof Mojito.Application.Search.SearchResultItem
 */

const propTypes = {
    resultType: PropTypes.oneOf(Object.values(RESULT_TYPE)).isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sportId: PropTypes.string.isRequired,
    parentName: PropTypes.string,
    sportClassName: PropTypes.string,
    classId: PropTypes.string,
    inPlay: PropTypes.bool,
    startTime: PropTypes.string,
    raceMeetingId: PropTypes.string,
    isVirtual: PropTypes.bool,
    afterRedirection: PropTypes.func,
    isOutright: PropTypes.bool,
};

const defaultProps = {
    afterRedirection: noop,
};

export default UIView('SearchResultItem', SearchResultItemImpl, propTypes, defaultProps);
