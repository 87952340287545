import StorageService from 'core/services/storage/';
import ExecutionMode from 'core/base/execution-mode';
import Build from 'core/generated/build.js';
/**
 * Generic core-level utility functions.
 *
 * @class CoreUtils
 * @memberof Mojito.Core
 */

const ADDITIONAL_CONTEXT_STORAGE_KEY = 'AdditonalContext';
const ADDITIONAL_CONTEXT_QUERY_PARAM_MATCHER = /[?&]additionalContext=([a-zA-Z]*)/;

export default {
    /**
     * Retrieves additional context that has been set via 'setAdditionalContext'.
     *
     * @returns {string|undefined} Additional context if it exists, otherwise undefined.
     * @function Mojito.Core.CoreUtils#getAdditionalContext
     */
    getAdditionalContext: () => {
        if (StorageService.hasItem(ADDITIONAL_CONTEXT_STORAGE_KEY)) {
            return StorageService.getItem(ADDITIONAL_CONTEXT_STORAGE_KEY);
        }
    },

    /**
     * Sets or removes values in Local Storage.
     *
     * To save values to Local Storage, use the following format:
     * <pre>http://url?additionalContext=myContext</pre>.
     *
     * To remove values from Local Storage, use the following format:
     * <pre>http://url?additionalContext=</pre>.
     *
     * Only one 'additionalContext' is supported. The name can only contain lower-case or upper-case alphabetical characters (a-z).
     *
     * @param {string} urlSearchString - The URL's search string (window.location.search) from which to retrieve the additional context.
     * @function Mojito.Core.CoreUtils#setAdditionalContext
     */
    setAdditionalContext: urlSearchString => {
        const match = urlSearchString.match(ADDITIONAL_CONTEXT_QUERY_PARAM_MATCHER);

        if (match) {
            // Always set and return the additional context if it's not empty
            if (match.length === 2 && match[1]) {
                StorageService.setItem(ADDITIONAL_CONTEXT_STORAGE_KEY, match[1]);
            }
            // If the given additional context was empty - it's a signal to remove it
            else if (StorageService.hasItem(ADDITIONAL_CONTEXT_STORAGE_KEY)) {
                StorageService.removeItem(ADDITIONAL_CONTEXT_STORAGE_KEY);
            }
        }
    },

    /**
     * The version of mojito application.
     *
     * @returns {string} Mojito version.
     * @function Mojito.Core.CoreUtils#appVersion
     */
    appVersion: () => Build.appVersion,

    /**
     * Git commit SHA of mojito sources.
     *
     * @returns {string} Commit SHA.
     * @function Mojito.Core.CoreUtils#gitVersion
     */
    gitVersion: () => Build.gitVersion,

    /**
     * Returns true if currently executing in debug mode.
     *
     * @returns {boolean} True if debug mode is activated, otherwise false.
     *
     * @function Mojito.Core.CoreUtils#isDebugMode
     */
    isDebugMode: () => ExecutionMode.isDebugMode(),

    /**
     * Redirects to the specified URL.
     *
     * @param {string} url - URL to redirect to.
     * @param {string} windowName - Window name.
     * @param {string} windowFeatures - Window features.
     * @function Mojito.Core.CoreUtils#openUrl
     */
    openUrl: (url, windowName, windowFeatures) => {
        if (url) {
            if (windowName === undefined) {
                window.location.assign(url);
            } else {
                window.open(url, windowName, windowFeatures);
            }
        }
    },

    /**
     * Generates a universally unique identifier (UUID) in vanilla javascript. If browser supports it, use window.crypto.randomUUID()
     * function, otherwise generate alphanumeric string based using Math.random() function.
     *
     * @returns {string} Generated UUID.
     * @function Mojito.Core.CoreUtils#generateUUID
     */
    generateUUID: () => {
        if (window.crypto && window.crypto.randomUUID) {
            return window.crypto.randomUUID();
        }
        const s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
        return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    },
};
