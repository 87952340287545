import MojitoCore from 'mojito/core';
const ApiProvider = MojitoCore.Services.ApiProvider;

const log = MojitoCore.logger.get();

/**
 * Abstract user info service to be implemented by different integrations.
 * Note: All methods must be implemented in a asynchronous way, hence
 * the specified callbacks must not be called when inside the called function.
 *
 * @class AbstractUserInfoService
 * @abstract
 * @memberof Mojito.Services.UserInfo
 */
export default class AbstractUserInfoService extends ApiProvider {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     *
     * @function Mojito.Services.UserInfo.AbstractUserInfoService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Get balance request.
     *
     * @param {string} currency - User currency code.
     *
     * @returns {Promise} Get balance promise.
     * @function Mojito.Services.UserInfo.AbstractUserInfoService#getBalance
     */
    // eslint-disable-next-line no-unused-vars
    getBalance(currency) {
        log.error('getBalance must be implemented by concrete subclass');
        return Promise.reject();
    }
}
