import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import EventPageImpl from './event-page.jsx';

/**
 * @namespace EventPage
 * @memberof Mojito.Application.Pages
 */

/**
 * Page that displays a single event.
 * <br>
 * This page expects the route params to include an eventId.
 *
 * @class EventPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Application.Pages.EventPage
 */

/**
 * EventPage prop types.
 *
 * @property {boolean} [isInplayPage = false] - Flag to render in-play content on page.
 *
 * @memberof Mojito.Application.Pages.EventPage
 */

const propTypes = {
    isInplayPage: PropTypes.bool,
};

const defaultProps = {
    isInplayPage: false,
};

export default UIPage('EventPage', EventPageImpl, propTypes, defaultProps);
