import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Meta information selectors.
 *
 * @class MetaInformationSelectors
 * @name selectors
 * @memberof Mojito.Services.MetaInformation
 */

/**
 * Selects meta information state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.MetaInformationState} The state of the meta information.
 *
 * @memberof Mojito.Services.MetaInformation.selectors
 */

export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select meta information.
 *
 * @function selectMetaInformation
 *
 * @param {string} type - Type of wanted meta information value.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Meta information by type.
 *
 * @memberof Mojito.Services.MetaInformation.selectors
 */
export const selectMetaInformation = (type, state) => {
    return selectState(state)[type] || '0';
};
