import PropTypes from 'prop-types';
import SelectionButtonImpl from './selection-button.jsx';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import SelectionButtonTypes from './types';

const { UIView } = MojitoCore.Presentation;
const { types: BetslipTypes } = MojitoServices.Betslip;

/**
 * Module to display the selection view.
 * For appropriate analytics reporting, this component should be within:
 * - {@link Mojito.Core.Presentation.AnalyticsContext.Context|AnalyticsContext} with a defined <code>analyticsPath</code> providing additional context about the component's position in the analytics-aware hierarchy (e.g., "Betslip").
 * - {@link Mojito.Modules.SelectionButton.Context.SelectionsContext|SelectionsContext} for emphasizing the selection state (`active`, `inactive`, `pending`) and managing `addSelection` and `removeSelection` actions on button toggle.
 * It's used to highlight selection states (`active`, `inactive`, `pending`), and to handle `addSelection` and `removeSelection` actions on button toggle.
 *
 * @class SelectionButton
 * @name module
 * @memberof Mojito.Modules.SelectionButton
 */

/**
 * A callback triggered upon state change of the button.
 *
 * @param {string} name - The name of the selection.
 * @param {Mojito.Modules.SelectionButton.types.STATES} state -  The current state of the selection.
 *
 * @callback Mojito.Modules.SelectionButton.module.stateChangeCallback
 */

/**
 * `SelectionButton` prop types.
 *
 * @property {object} selection - The selection to be displayed.
 * @property {object} event - The parent event.
 * @property {string} marketStatus - The status of the parent market.
 * @property {string} marketType - The type of the parent market.
 * @property {string} marketId - The id of the parent market.
 * @property {Mojito.Services.Betslip.types.BETTING_CONTEXT} [bettingContext = BETTING_CONTEXT.DEFAULT] - Selection betting context. This context represents betting flow
 * that will be proposed for a user once selection has been added to the betslip, typically, selection is a normal probability result for particular event that user can bet on.
 * But sometimes selection can represent already prebuilt accumulator bets, e.g. Such exotic things like prebuilt match accas or prebuilt accas.
 * @property {string} [noPricesAvailableString] - If no prices are available for the selection, the default string `GENERIC.UNAVAILABLE` is displayed. This string overrides the default if provided (optional).
 * @property {string} [suspendedString] - The string to display in place of a lock icon if the selection is suspended (optional).
 * @property {Mojito.Modules.SelectionButton.types.NAME_DISPLAY} [nameDisplay = undefined] - The display type for the selection name (optional).
 * @property {object | Function} [buttonRef] - Ref to the rendered button element.
 * @property {Function} [onOverflowText = () => {}] - Callback fired when text overflown.
 *
 * @memberof Mojito.Modules.SelectionButton.module
 */
export const propTypes = {
    selection: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    marketStatus: PropTypes.string,
    marketType: PropTypes.string,
    marketId: PropTypes.string,
    nameDisplay: PropTypes.oneOf(Object.values(SelectionButtonTypes.NAME_DISPLAY)),
    noPricesAvailableString: PropTypes.string,
    suspendedString: PropTypes.string,
    buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onOverflowText: PropTypes.func,
    bettingContext: PropTypes.string,
};

const defaultProps = {
    nameDisplay: undefined,
    bettingContext: BetslipTypes.BETTING_CONTEXT.DEFAULT,
};

export default UIView('SelectionButton', SelectionButtonImpl, propTypes, defaultProps);
