import ScoreCountImpl from './score-count.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

/**
 * ScoreCount component that is typically used to display a score and show an animation when the score changes.
 * <br><br>
 * The component only supports scores represented as numbers which are counted up. The animation will
 * only be shown when the score is increased and not when it is decreased (cancelled).
 *
 * @class ScoreCount
 * @memberof Mojito.Presentation.Components
 */

/**
 * ScoreCount prop types.
 *
 * @property {string} score - Score of the game. Should be a string representing a number.
 *
 * @memberof Mojito.Presentation.Components.ScoreCount
 */
const propTypes = {
    score: PropTypes.string.isRequired,
};

export default MojitoCore.Presentation.UIView('ScoreCount', ScoreCountImpl, propTypes);
