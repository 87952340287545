import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

const LoginActions = MojitoModules.Login.actions;
const authenticationSelectors = MojitoServices.Authentication.selectors;
const IntentActionTrigger = MojitoPresentation.Base.Intent.IntentActionTrigger;

const {dispatch} = MojitoCore.Services.redux.store;

export function LoginRoute() {
    const userLoggedIn = useSelector(state => authenticationSelectors.isLoggedIn(state));

    useEffect(() => {
        // Need to go back home to show main page on background
        IntentActionTrigger.showHomePage();

        if (!userLoggedIn) {
            dispatch(LoginActions.showLoginView());
        }
    }, [userLoggedIn]);

    return null;
}
