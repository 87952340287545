/**
 * @class AuthenticationTypes
 * @name types
 * @memberof Mojito.Services.Authentication
 */
export default class AuthenticationTypes {}

AuthenticationTypes.AUTH_TOKEN_COOKIE = 'auth_token';
AuthenticationTypes.LOGIN_TIME_STORAGE_KEY = 'login_time';

/**
 * Credentials public factor type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.CREDENTIALS_PUBLIC_TYPE = {
    USERNAME: 'USERNAME',
    CARD: 'CARD',
};

/**
 * Credentials private factor type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.CREDENTIALS_PRIVATE_TYPE = {
    PASSWORD: 'PASSWORD',
    PIN: 'PIN',
    TOKEN: 'TOKEN',
};

/**
 * User authentication states.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.STATES = {
    UNKNOWN: 'UNKNOWN',
    LOGGED_OUT: 'LOGGED_OUT',
    LOGGING_IN: 'LOGGING_IN',
    LOGGED_IN: 'LOGGED_IN',
};

/**
 * States for the password change process.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.PASSWORD_CHANGE_STATE = {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    CHANGED: 'CHANGED',
    FAILED: 'FAILED',
};

/**
 * Login errors.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.LOGIN_ERRORS = {
    TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED: 'terms-and-conditions-acceptance-is-required',
    CHANGE_PASSWORD_IS_REQUIRED: 'change-password-is-required',
    CHANGE_PIN_IS_REQUIRED: 'change-pin-is-required',
    WRONG_CREDENTIALS: 'wrong-credentials',
    ACCOUNT_IS_LOCKED: 'account-is-locked',
    ACCOUNT_TEMPORARILY_LOCKED: 'account-temporarily-locked',
    UNKNOWN: 'unknown',
    IMS_ERROR: 'ims-error',
};

/**
 * Type used for understanding the reason of logout.
 *
 * @typedef LOGOUT_REASON
 * @type {{AUTO_LOGOUT_BY_INACTIVITY: string, MANUAL_LOGOUT: string}}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.LOGOUT_REASON = {
    // Auto logout message due to user's inactivity against app
    AUTO_LOGOUT_BY_INACTIVITY: 'AUTO_LOGOUT_BY_INACTIVITY',
    // Logout message due to user click on logout button
    MANUAL_LOGOUT: 'MANUAL_LOGOUT',
    // Logout when session expired
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    // Logout when session invalid
    SESSION_INVALID: 'SESSION_INVALID',
    // Logout when session was lost due to unexpected reason. Typically, happens when API endpoint responds with INVALID_SESSION error type while user is logged in.
    // Might indicate that server has lost user session due to unknown reasons.
    UNEXPECTED_SESSION_LOST: 'UNEXPECTED_SESSION_LOST',
};

/**
 * Type used for explicitly specifying content targeting types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.CONTENT_TARGET_TYPE = {
    API_PREFIX: 'api_prefix',
};

/**
 * Type used for specifying user session validation result.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Authentication.types
 */
AuthenticationTypes.SESSION_VALIDATION_RESULT = {
    SUCCESS: 'SUCCESS',
    INVALID_SESSION: 'INVALID_SESSION',
};

/**
 * Config object used to initialise authentication service layer.
 *
 * @typedef AuthenticationConfig
 * @type {object}
 * @property {string} transactionUrl - Url used to perform authentication transactions.
 * @property {Mojito.Services.Authentication.AbstractAuthenticationService} service - Authentication service implementation. Used to handle user session within betting platform.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * User authentication credentials object.
 * Contains information about credential types and credential values itself.
 * Typically passed into authentication service's login methods.
 *
 * @typedef AuthCredentials
 * @type {object}
 * @property {string} publicType - Defines type of public credentials factor, e.g., username, card number, email etc.
 * @property {string} privateType - Defines type of private credentials factor, e.g., password, pin, token etc.
 * @property {Mojito.Services.Authentication.types.CredentialFactors} data - Object contains user login credentials.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * User credentials object.
 * Contain public and private credential factors.
 *
 * @typedef CredentialFactors
 * @type {object}
 * @property {string} publicFactor - Defines public credential value, e.g., username, card number, email etc.
 * @property {string} privateFactor - Defines private credentials value, e.g., password, pin, token etc.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * User authentication object.
 *
 * @typedef AuthInfo
 * @type {object}
 * @property {Mojito.Services.Authentication.types.UserSessionInfo} sessionInfo - Object contains information about user session.
 * @property {Mojito.Services.Authentication.types.UserInfo} userInfo - Object contains user specific information.
 * @property {object} contentTargeting - Object contains information about content targeting specifics applicable for current user.
 * Key is one of {@link Mojito.Services.Authentication.types.CONTENT_TARGET_TYPE|CONTENT_TARGET_TYPE}, value is actual target. Typically content endpoint prefix.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * User session information.
 *
 * @typedef UserSessionInfo
 * @type {object}
 * @property {string} sessionId - Identifier of the user session.
 * @property {string} creationTime - Time when user session has been created.
 * @property {object} integrationProperties - Object defines integration properties. Typically only intended to be sent to third party systems.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * User specific information.
 *
 * @typedef UserInfo
 * @type {object}
 * @property {string} userId - Identifier of the user.
 * @property {string} userName - User name.
 * @property {object} balances - Object defines user balances. Key is one of {@link Mojito.Services.UserInfo.types.BALANCE_TYPES|BALANCE_TYPES}, value is actual balance amount.
 * @property {string} currency - Currency code.
 * @property {string} country - Country code.
 * @property {boolean} balanceUncertain - Value that returns true if the balance amount is considered to be uncertain, e.g., if client lost connection to balance service or network error occurred during balance fetch. In such cases client still keeps previously loaded balance amount but there is no guarantee if data is still valid. This flag can affect some balance aware user interactions like bet placement.
 * @property {object} dueDiligenceFlags - Object defines user due diligence status. Key is one of {@link Mojito.Services.UserInfo.types.DUE_DILIGENCE_FLAGS|DUE_DILIGENCE_FLAGS}, value is actual flag payload.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * Object defines password (private credential) change.
 *
 * @typedef PasswordUpdate
 * @type {object}
 * @property {string} currentPassword - Current value of private credential, e.g., current password.
 * @property {string} newPassword - New value of private credential, e.g., new password.
 * @property {Mojito.Services.Authentication.types.CREDENTIALS_PRIVATE_TYPE} type - Type of the private credential.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * The state of authentication store slice.
 *
 * @typedef AuthenticationStoreState
 * @type {object}
 * @property {Mojito.Services.Authentication.types.UserSessionInfo} sessionInfo - User session info.
 * @property {Mojito.Core.Services.Transactions.types.Error} errorInfo - Authentication error.
 * @property {Mojito.Services.Authentication.types.STATES} loginState - Current logged in state.
 * @property {Mojito.Services.Authentication.types.PASSWORD_CHANGE_STATE} passwordChangeState - The state of password change process.
 * @property {Mojito.Services.Authentication.types.LOGOUT_REASON} logoutReason - Logout reason.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * Session validation object.
 *
 * @typedef SessionInfo
 * @type {object}
 * @property {Mojito.Services.Authentication.types.SESSION_VALIDATION_RESULT } status - Session validation status.
 *
 * @memberof Mojito.Services.Authentication.types
 */

/**
 * Callback executed on successful login.
 *
 * @param {Mojito.Services.Authentication.types.AuthInfo} authInfo - Authentication info.
 * @callback Mojito.Services.Authentication.types.loginSuccessCallback
 */

/**
 * Callback executed on failed login.
 *
 * @param {Mojito.Core.Services.Transactions.types.Error} [errorInfo] - Error info.
 * @callback Mojito.Services.Authentication.types.loginFailCallback
 */

/**
 * Callback executed on successful logout.
 *
 * @callback Mojito.Services.Authentication.types.logoutSuccessCallback
 */

/**
 * Callback executed on failed logout.
 *
 * @callback Mojito.Services.Authentication.types.logoutFailCallback
 */

/**
 * Callback executed on successful validate session.
 *
 * @param {Mojito.Services.Authentication.types.SessionInfo} sessionInfo - Validation session information.
 * @callback Mojito.Services.Authentication.types.validateSessionSuccess
 */

/**
 * Callback executed on failed validate session.
 *
 * @param {Mojito.Services.Authentication.types.SessionInfo} sessionInfo - Validation session information.
 * @callback Mojito.Services.Authentication.types.validateSessionFail
 */

/**
 * Callback executed on successful restore session.
 *
 * @param {(Mojito.Services.Authentication.types.SessionInfo|Mojito.Services.Authentication.types.AuthInfo)} sessionInfo - Validation session information.
 *
 * @callback Mojito.Services.Authentication.types.restoreSessionSuccess
 */

/**
 * Callback executed on failed restore session.
 *
 * @param {(Mojito.Services.Authentication.types.SessionInfo|Mojito.Core.Services.Transactions.types.Error)} sessionInfo - Validation session information.
 *
 * @callback Mojito.Services.Authentication.types.restoreSessionFail
 */

/**
 * Callback executed on success changing password.
 *
 * @callback Mojito.Services.Authentication.types.changePasswordSuccess
 */

/**
 * Callback executed on failed changing password.
 *
 * @callback Mojito.Services.Authentication.types.changePasswordFail
 */

/**
 * Callback executed on successful accepted terms and conditions.
 *
 * @callback Mojito.Services.Authentication.types.acceptTermAndConditionSuccess
 */

/**
 * Callback executed on failed for terms and conditions.
 *
 * @callback Mojito.Services.Authentication.types.acceptTermAndConditionFail
 */

/**
 * Callback executed on successful declined terms and conditions.
 *
 * @callback Mojito.Services.Authentication.types.declineTermAndConditionSuccess
 */

/**
 * Callback executed on failed for declined terms and conditions.
 *
 * @callback Mojito.Services.Authentication.types.declineTermAndConditionFail
 */
