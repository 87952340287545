import {settingsStorage} from 'mojito/core/services/system-settings/slice';

import {Localization} from 'core/localization';

settingsStorage.setApplicationMode = () => {
    // do nothing
    // Do not store app mode - it has no sense
};

settingsStorage.getApplicationMode = () => {
    // do nothing
};

settingsStorage.setAdditionalContext = () => {
    // do nothing
    // Do not store additional context here, the one valid additional context is stored inside system-settings store
};

settingsStorage.getAdditionalContext = () => {
    // do nothing
    // use valid additional context from system-settings store
};

// need to save this method to detect current language
settingsStorage.getAppLanguage = settingsStorage.getLanguage;

settingsStorage.getLanguage = () => {
    return Localization.getCurrentLanguage();
};
