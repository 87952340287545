import RedCardImpl from './red-card.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

/**
 * RedCard component that is typically used to display one or more red cards. When the number of red cards increase, the newest red card (added from left) can be configured to show up using an animation.
 *
 * @class RedCard
 * @memberof Mojito.Presentation.Components
 */

/**
 * RedCard prop types.
 *
 * @property {number} cardsNumber - Number of red cards.
 * @property {isAnimationEnabled} [isAnimationEnabled = false] - Whether card animation is enabled or not.
 *
 * @memberof Mojito.Presentation.Components.RedCard
 */
const propTypes = {
    cardsNumber: PropTypes.number.isRequired,
    isAnimationEnabled: PropTypes.bool,
};

const defaultProps = {
    isAnimationEnabled: false,
};

export default MojitoCore.Presentation.UIView('RedCard', RedCardImpl, propTypes, defaultProps);
