import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import DropdownImpl from './dropdown.jsx';
import { noop } from 'mojito/utils';

const { requiredIf } = MojitoCore.Base.ReactUtils;

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view component that displays a dropdown.
 * The dropdown items can either be grouped with a header or displayed as a single ungrouped list.
 * If using grouped items, the `itemGroups` property should include an array of `DropdownItemGroup` objects.
 * If using ungrouped items, the `items` property should include an array of `DropdownItem` objects.
 *
 * **Note:** It is necessary to provide one of these two properties to the component. If both properties are provided, only `items` will be considered.
 *
 * @class Dropdown
 * @memberof Mojito.Presentation.Components
 */

/**
 * Represents a group of items in the `Dropdown` component.
 *
 * @typedef DropdownItemGroup
 * @type {object}
 * @property {string} label - Dropdown group label.
 * @property {Mojito.Presentation.Components.Dropdown.DropdownItem[]} items - Dropdown group items.
 *
 * @memberof Mojito.Presentation.Components.Dropdown
 */

/**
 * Represents an item in the `Dropdown` component.
 *
 * @typedef DropdownItem
 * @type {object}
 * @property {string} value - Item unique value.
 * @property {string} label - Item label.
 *
 * @memberof Mojito.Presentation.Components.Dropdown
 */

/**
 * `Dropdown` component prop types.
 *
 * @property {Mojito.Presentation.Components.Dropdown.DropdownItemGroup[]} [itemGroups] - Item groups to display in the dropdown. Required if `items` prop is not used.
 * @property {Mojito.Presentation.Components.Dropdown.DropdownItem[]} [items] - Items to display in the dropdown. Required if `itemGroups` prop is not used.
 * @property {string} [selectedItemValue] - The value of the selected item.
 * @property {boolean} [isSelected] - Flag to determine whether the selected item is in the Dropdown.
 * @property {Function} [cbOnChange = () => {}] - Callback executed when selected item changes. Parameters: value, id.
 * @property {boolean} [triggerOverlayInteraction = true] - If true, the dropdown will trigger the {@link Mojito.Core.Presentation.AppContext.ContextDefinition#overlayInteraction|overlayInteraction} action of dropdown expand.
 * @property {string} [id] - Optional id of the dropdown that will be passed in the callback.
 * @property {string} [controlText] - Optional text to show in the control. If none provided, the selected dropdown value will be used.
 * @property {boolean} [disabled = false] - True if the dropdown should be disabled (not clickable), false otherwise.
 *
 * @memberof Mojito.Presentation.Components.Dropdown
 */
const propTypes = {
    itemGroups: requiredIf(
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.string,
                        label: PropTypes.string,
                    })
                ),
            })
        ),
        props => props.items === undefined
    ),
    items: requiredIf(
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            })
        ),
        props => props.itemGroups === undefined
    ),
    controlText: PropTypes.string,
    selectedItemValue: PropTypes.string,
    isSelected: PropTypes.bool,
    cbOnChange: PropTypes.func,
    triggerOverlayInteraction: PropTypes.bool,
    id: PropTypes.string,
    class: PropTypes.string,
    disabled: PropTypes.bool,
};

const defaultProps = {
    cbOnChange: noop,
    triggerOverlayInteraction: true,
    isSelected: false,
    disabled: false,
};

export default UIView('Dropdown', DropdownImpl, propTypes, defaultProps);
