import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * Abstract betslip service to be implemented by different integrations.
 * Note: All methods must be implemented in a asynchronous way and return promise.
 *
 * @class AbstractBetslipService
 * @abstract
 * @memberof Mojito.Services.Betslip
 */
export default class AbstractBetslipService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @function Mojito.Services.Betslip.AbstractBetslipService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Init betslip.
     *
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Init betslip promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#initBetslip
     */
    // eslint-disable-next-line no-unused-vars
    initBetslip(oddsFormat, state) {
        log.error('initBetslip must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Add betslip part.
     *
     * @param {Mojito.Services.Betslip.types.AddSelectionPartPayload} payload - Add part payload data.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Add part promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#addPart
     */
    // eslint-disable-next-line no-unused-vars
    addPart(payload, state) {
        log.error('addPart must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Update betslip part.
     *
     * @param {Array<Mojito.Services.Betslip.types.Selection>} selections - Betslip selections.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Update part promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#updatePart
     */
    // eslint-disable-next-line no-unused-vars
    updatePart(selections, state) {
        log.error('updatePart must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Remove betslip part.
     *
     * @param {string} selectionId - Selection id.
     * @param {string} betId - Bet id.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Remove part promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#removePart
     */
    // eslint-disable-next-line no-unused-vars
    removePart(selectionId, betId, state) {
        log.error('removePart must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Switch betslip part selection.
     *
     * @param {string} selectionId - Selection id that needs to be replaced.
     * @param {string} betRef - Bet references to the selections that should applied instead of replacing selection.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Switch part selection promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#switchPartSelection
     */
    // eslint-disable-next-line no-unused-vars
    switchPartSelection(selectionId, betRef, state) {
        log.error('switchPartSelection must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Add betslip composite.
     *
     * @param {Mojito.Services.Betslip.types.AddCompositePayload} payload - Add betslip composite payload.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Add composite promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#addComposite
     */
    // eslint-disable-next-line no-unused-vars
    addComposite(payload, state) {
        log.error('addComposite must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Update betslip composite.
     *
     * @param {Mojito.Services.Betslip.types.UpdateCompositePayload} payload - Update betslip composite payload.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Update composite promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#updateComposite
     */
    // eslint-disable-next-line no-unused-vars
    updateComposite(payload, state) {
        log.error('updateComposite must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Remove betslip composite.
     *
     * @param {Array} selectionIds - List of selection ids within composite.
     * @param {string} betId - Bet id.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Remove composite promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#removeComposite
     */
    // eslint-disable-next-line no-unused-vars
    removeComposite(selectionIds, betId, state) {
        log.error('removeComposite must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Set stake.
     *
     * @param {Mojito.Services.Betslip.types.SetStakeRequest} payload - Set stake payload data.
     * @param {*} state - Betslip state.
     * @param {AbortSignal} [abortSignal] - Abort signal instance to allow pending request cancellation.
     *
     * @returns {Promise} Set stake promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#setStake
     */
    // eslint-disable-next-line no-unused-vars
    setStake(payload, state, abortSignal) {
        log.error('setStake must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Remove stake group.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Remove stake group promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#removeStakeGroup
     */
    // eslint-disable-next-line no-unused-vars
    removeStakeGroup(stakeGroupName, state) {
        log.error('removeStakeGroup must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Activate stake group.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Activate stake group promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#removeStakeGroup
     */
    // eslint-disable-next-line no-unused-vars
    activateStakeGroup(stakeGroupName, state) {
        log.error('activateStakeGroup must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Activate teaser type.
     *
     * @param {Mojito.Services.Betslip.types.TEASER_TYPE} type - Stake group name.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Activate teaser type promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#activateTeaserType
     */
    // eslint-disable-next-line no-unused-vars
    activateTeaserType(type, state) {
        log.error('activateTeaserType must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Validate bonuses.
     *
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Validate bonuses promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#validateBonuses
     */
    // eslint-disable-next-line no-unused-vars
    validateBonuses(state) {
        log.error('validateBonuses must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Add bonus offers.
     *
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Add bonus offers promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#refreshBonusOffers
     */
    // eslint-disable-next-line no-unused-vars
    refreshBonusOffers(state) {
        log.error('refreshBonusOffers must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Add free bet.
     *
     * @param {string} token - Token.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Add free bet promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#addFreeBet
     */
    // eslint-disable-next-line no-unused-vars
    addFreeBet(token, state) {
        log.error('addFreeBet must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Remove free bet.
     *
     * @param {string} token - Token.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Remove free bet promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#removeFreeBet
     */
    // eslint-disable-next-line no-unused-vars
    removeFreeBet(token, state) {
        log.error('removeFreeBet must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Execute actions.
     *
     * @param {Array<Mojito.Services.Betslip.types.Action>} actions - List of actions.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Execute actions promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#executeActions
     */
    // eslint-disable-next-line no-unused-vars
    executeActions(actions, state) {
        log.error('executeActions must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Place betslip.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {Array<Mojito.Services.Betslip.types.PRICE_CHANGE_POLICY>} priceChangePolicies - Price change policies list.
     * @param {string} currency - Currency.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Place betslip promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#placeBetslip
     */
    // eslint-disable-next-line no-unused-vars
    placeBetslip(stakeGroupName, priceChangePolicies, currency, state) {
        log.error('placeBetslip must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Clear betslip.
     *
     * @param {Array<Mojito.Services.Betslip.types.BETSLIP_PART>} retainParts - List of betslip parts.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Clear betslip promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#clearBetslip
     */
    // eslint-disable-next-line no-unused-vars
    clearBetslip(retainParts, state) {
        log.error('clearBetslip must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Get overask status.
     *
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Overask status promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#getOveraskStatus
     */
    // eslint-disable-next-line no-unused-vars
    getOveraskStatus(state) {
        log.error('getOveraskStatus must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Update overask.
     *
     * @param {Mojito.Services.Betslip.types.OveraskUpdatePayload} payload - Payload.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Overask update promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#updateOverask
     */
    // eslint-disable-next-line no-unused-vars
    updateOverask(payload, state) {
        log.error('updateOverask must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Toggle banker for bet.
     *
     * @param {string} betId - Bet ID.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Toggle banker promise.
     * @function Mojito.Services.Betslip.AbstractBetslipService#toggleBanker
     */
    // eslint-disable-next-line no-unused-vars
    toggleBanker(betId, state) {
        log.error('toggleBanker must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Stores betslip data. Depending on implementation it can be stored in local storage, indexedDB, realm on native or on server DB.
     *
     * @param {object} data - Betslip data.
     * @param {string} dataType - The type of the data to be stored.
     *
     * @function Mojito.Services.Betslip.AbstractBetslipService#storeBetslipData
     */
    // eslint-disable-next-line no-unused-vars
    storeBetslipData(data, dataType) {
        log.error('storeBetslipData must be implemented by concrete subclass');
    }

    /**
     * Cleans betslip data that was stored by {@link Mojito.Services.Betslip.AbstractBetslipService#storeBetslipData|storeBetslipData} call.
     *
     * @param {string} dataType - The type of the data to be cleaned.
     *
     * @function Mojito.Services.Betslip.AbstractBetslipService#cleanBetslipData
     */
    // eslint-disable-next-line no-unused-vars
    cleanBetslipData(dataType) {
        log.error('cleanBetslipData must be implemented by concrete subclass');
    }

    /**
     * Fetch betslip data from a local storage.
     *
     * @param {string} dataType - The type of the data to be fetched.
     * @returns {object|undefined} Betslip data.
     *
     * @function Mojito.Services.Betslip.service#fetchBetslipData
     */
    // eslint-disable-next-line no-unused-vars
    fetchBetslipData(dataType) {
        log.error('fetchBetslipData must be implemented by concrete subclass');
        return undefined;
    }
}
