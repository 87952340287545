import MojitoCore from 'mojito/core';
import SettingsMenuImpl from './settings-menu.jsx';

/**
 * A View that represents the Settings menu.
 *
 * @class SettingsMenu
 * @memberof Mojito.Modules
 */
export default MojitoCore.Presentation.UIView('SettingsMenu', SettingsMenuImpl);
