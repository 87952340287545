import { omit } from 'mojito/utils';
import { useMemo } from 'react';
import MojitoCore from 'mojito/core';
const { merge } = MojitoCore.Presentation.uiStyle;
import SkeletonPart from 'presentation/components/skeleton-part/index.jsx';
import Spinner from 'presentation/components/spinner';

const MODE = {
    SKELETON: 'skeleton',
    SPINNER: 'spinner',
};

export default function LoadingView(props) {
    const {
        mojitoTools: { config },
        name,
    } = props;
    const { extensionConfigs } = config;
    const configExt = useMemo(() => {
        const extensionConfig = extensionConfigs[name];
        const cleanConfig = omit(config, 'extensionConfigs');
        return extensionConfig ? merge(cleanConfig, extensionConfig) : cleanConfig;
    }, [name, config, extensionConfigs]);

    if (configExt.hide) {
        return null;
    }
    const className = `ta-LoadingView ta-${name}`;
    const viewMode = {
        [MODE.SKELETON]: <SkeletonPart config={configExt.skeleton} />,
        [MODE.SPINNER]: <Spinner config={configExt.spinner} />,
    };
    return (
        <div style={configExt.style} className={className}>
            {viewMode[configExt.mode]}
        </div>
    );
}
