/**
 * Sport types.
 *
 * @class SportTypes
 * @name types
 * @memberof Mojito.Services.SportsContent.Sports
 */
export default class SportTypes {}

/**
 * Virtual sports id.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.Sports.types
 */
SportTypes.SPORT_ID = {
    /* Virtuals sport id. */
    VIRTUALS: 'virtuals',
};
