import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const log = MojitoCore.logger.get('MatchAccaServiceFactory');

const voidResponder = () => {
    log.warn('MatchAccaService instance is missing.');
    return Promise.reject();
};
const NULL_SERVICE = {
    configure: noop,
    calculateOdds: voidResponder,
};

/**
 * Singleton object that receives match acca config and spawn service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.MatchAcca
 */
class ServiceFactory {
    constructor() {
        this.service = NULL_SERVICE;
    }

    /**
     * Init factory.
     *
     * @function init
     *
     * @param {Mojito.Services.MatchAcca.MatchAccaConfig} [config = {}] - Match acca config.
     *
     * @memberof Mojito.Services.MatchAcca.serviceFactory
     */
    init({ service } = {}) {
        this.service = service || NULL_SERVICE;
    }

    /**
     * Service getter.
     *
     * @function getService
     *
     * @returns {Mojito.Services.Betslip.AbstractBetslipService} Betslip service instance.
     *
     * @memberof Mojito.Services.MatchAcca.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
