import { isEmpty } from 'mojito/utils';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import FreeBetItems from 'modules/bonuses/free-bet-items/index.jsx';

const { Image, ExpandableView, Text, ProgressBar, DateTime, FlexPane } =
    MojitoPresentation.Components;
const CurrencyHelper = MojitoPresentation.Utils.CurrencyHelper;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const NumberUtils = MojitoCore.Base.NumberUtils;

export default class ActiveBonuses extends UIViewImplementation {
    renderProgressContent(target, wagered, remaining) {
        const currencyCode = this.props.currencyCode;
        const progressRatio = NumberUtils.numberToPrecision(wagered / target, 2);
        const remainingRatio = 1 - progressRatio;
        const remainingLabel =
            remainingRatio > this.config.remainingProgressVisibilityLimit / 100
                ? CurrencyHelper.formatCurrency(remaining, currencyCode)
                : undefined;

        return (
            <ProgressBar
                config={this.config.progressBar}
                progress={progressRatio}
                endLabel={CurrencyHelper.formatCurrency(target, currencyCode)}
                progressLabel={CurrencyHelper.formatCurrency(wagered, currencyCode)}
                remainLabel={remainingLabel}
            />
        );
    }

    renderCampaignItem(campaign) {
        const target = NumberUtils.numberToPrecision(Number(campaign.target), 2);
        const amount = NumberUtils.numberToPrecision(Number(campaign.amount), 2);
        const wagered = NumberUtils.numberToPrecision(Number(campaign.wagered), 2);
        const remaining = NumberUtils.numberToPrecision(Number(campaign.remaining), 2);

        return (
            <div style={this.style.item} className="item" key={campaign.id}>
                <div style={this.style.itemSections}>
                    <div style={this.style.itemSectionData}>
                        <div style={this.style.itemRow}>
                            <div className="itemName" style={this.style.itemName}>
                                {campaign.name}
                            </div>
                            <div style={this.style.itemAmount}>
                                <Image config={this.config.icon} />
                                {CurrencyHelper.formatCurrency(amount, this.props.currencyCode)}
                            </div>
                        </div>

                        <div style={this.style.itemRow}>
                            <div style={this.style.itemBalanceLabel}>
                                {this.resolveString('$ACTIVE_BONUSES.BALANCE')}
                            </div>
                            <div style={this.style.itemBalanceValue}>
                                {CurrencyHelper.formatCurrency(wagered, this.props.currencyCode)}
                            </div>
                        </div>

                        {campaign.expireDate && (
                            <div style={this.style.itemRow}>
                                <div style={this.style.itemExpireLabel}>
                                    {this.resolveAndFormatString('$ACTIVE_BONUSES.EXPIRY', '')}
                                </div>
                                <div style={this.style.itemExpireDate}>
                                    <DateTime
                                        config={this.config.campaignExpireDateTimeFormat}
                                        dateTime={campaign.expireDate}
                                    />
                                </div>
                            </div>
                        )}

                        <div style={this.style.itemInformation}>{campaign.description}</div>
                    </div>

                    <div style={this.style.itemSectionProgress}>
                        {this.renderProgressContent(target, wagered, remaining)}
                    </div>
                </div>
            </div>
        );
    }

    renderNoItemsAvailableInformation(items, infoString) {
        if (!isEmpty(items)) {
            return null;
        }

        return (
            <Text class="ActiveBonuses-NoBonus" config={this.config.bonusItemsNotAvailable}>
                {infoString}
            </Text>
        );
    }

    render() {
        const { freeBets, campaigns, currencyCode } = this.props;
        const activeBonuses = campaigns.map(this.renderCampaignItem, this);

        return (
            <FlexPane config={this.config.container}>
                {this.props.showPrewager && (
                    <ExpandableView
                        headerText={this.resolveString(`$ACTIVE_BONUSES.PREWAGER`)}
                        config={this.config.header}
                        class="ta-bonusesList-prewager"
                    >
                        <div className="ActiveBonuses">{activeBonuses}</div>
                        {this.renderNoItemsAvailableInformation(
                            campaigns,
                            this.resolveString(`$ACTIVE_BONUSES.NO_PREWAGER`)
                        )}
                    </ExpandableView>
                )}
                <ExpandableView
                    headerText={this.resolveString(`$ACTIVE_BONUSES.FREE_BET`)}
                    config={this.config.header}
                    onExpandedChanged={this.handleFreeBetsExpandedChanged}
                    class="ta-bonusesList-freebet"
                >
                    <FreeBetItems
                        freeBets={freeBets}
                        currencyCode={currencyCode}
                        config={this.config.freeBetItems}
                    />
                    {this.renderNoItemsAvailableInformation(
                        freeBets,
                        this.resolveString(`$ACTIVE_BONUSES.NO_FREE_BETS`)
                    )}
                </ExpandableView>
            </FlexPane>
        );
    }
}

ActiveBonuses.getStyle = function (config, applicationMode, merge) {
    return {
        item: merge({}, config.item),
        itemRow: {
            padding: config.itemRow.padding,
            margin: config.itemRow.margin,
            display: 'flex',
            justifyContent: 'space-between',
        },
        itemSections: {
            padding: config.itemSections.padding,
            margin: config.itemSections.margin,
            display: 'flex',
            flexDirection: config.itemSections.flexDirection,
            justifyContent: 'space-between',
        },
        itemSectionData: {
            padding: config.itemSectionData.padding,
            margin: config.itemSectionData.margin,
            width: '100%',
        },
        itemSectionProgress: merge(
            {
                padding: config.itemSectionProgress.padding,
                margin: config.itemSectionProgress.margin,
                width: '100%',
            },
            config.itemSectionProgress.border
        ),
        itemName: merge(
            {
                opacity: config.itemName.opacity,
            },
            config.itemName.textStyle
        ),
        itemAmount: merge(
            {
                display: 'flex',
                alignItems: 'center',
                opacity: config.itemAmount.opacity,
            },
            config.itemAmount.textStyle
        ),
        itemBalanceLabel: merge(
            {
                opacity: config.itemBalanceLabel.opacity,
            },
            config.itemBalanceLabel.textStyle
        ),
        itemBalanceValue: merge(
            {
                opacity: config.itemBalanceValue.opacity,
            },
            config.itemBalanceValue.textStyle
        ),
        itemExpireLabel: merge(
            {
                opacity: config.itemExpireLabel.opacity,
            },
            config.itemExpireLabel.textStyle
        ),
        itemExpireDate: merge(
            {
                opacity: config.itemExpireDate.opacity,
            },
            config.itemExpireDate.textStyle
        ),
        itemInformation: merge(
            {
                opacity: config.itemInformation.opacity,
            },
            config.itemInformation.textStyle
        ),
    };
};
