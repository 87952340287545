import MojitoCore from 'mojito/core';
import ProtectedImpl from './protected.jsx';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * Protected module to protect navigation from unauthenticated users.
 * Module that wraps around pages that are accessible only to logged in users. Redirects client to homepage in case
 * user is not logged in.
 *
 * @class Protected
 * @memberof Mojito.Application
 */
/**
 * Protected prop types.
 *
 * @property {node} [children] - Protected children that will be rendered when user is logged in.
 *
 * @memberof Mojito.Application.Protected
 */
const propTypes = {
    children: PropTypes.node,
};

export default UIView('Protected', ProtectedImpl, propTypes, {});
