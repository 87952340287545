import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';
import ServicesTypes from 'services/common/types.js';
import ServicesUtils from 'services/common/utils.js';
import { omit, isEmpty } from 'mojito/utils';
import MarketGroupsDataDescriptor from './descriptor.js';
import channelFactory from 'services/common/content/content-channel-factory.js';
import marketGroupsProvider from 'services/sports-content/content-provider/market-groups-provider';

const { MARKET_GROUPS } = MarketGroupsDataDescriptor.DATA_TYPES;
const { actionsRegistry } = MojitoCore.Services.Content;

export const getMarketGroupsChannel = () =>
    channelFactory.getChannel(marketGroupsProvider, MARKET_GROUPS);

const reduxInstance = MojitoCore.Services.redux;
const { UNKNOWN, AVAILABLE, UNAVAILABLE } = ServicesTypes.CONTENT_STATE;

/**
 * Defines the state of the market groups store. Contains market groups.
 *
 * @typedef MarketGroupsState
 *
 * @property {object} groups - Map of market groups. Key is event id and value is event content object.
 * @property {Object<string, Mojito.Services.Common.types.CONTENT_STATE>} groupsState - Map of market groups lifetime state. Key is group id and value is of type {@link Mojito.Services.Common.types.CONTENT_STATE|CONTENT_STATE}.
 *
 * @memberof Mojito.Services.SportsContent.MarketGroups
 */

/**
 * The name of the market groups store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.MarketGroups
 */
export const STORE_KEY = 'marketGroupsStore';

export const INITIAL_STATE = {
    groups: {},
    groupsState: {},
};

export const { reducer, actions } = createSlice({
    name: 'market groups',
    initialState: INITIAL_STATE,
    reducers: {
        updateMarketGroup(state, { payload }) {
            const { groupId, group } = payload;
            if (group) {
                state.groups[groupId] = adaptMarketGroup(group);
                state.groupsState[groupId] = AVAILABLE;
            } else {
                delete state.groups[groupId];
                state.groupsState[groupId] = UNAVAILABLE;
            }
        },
        removeMarketGroups(state, { payload }) {
            const { groupIds = [] } = payload;
            state.groups = omit(state.groups, groupIds);
            groupIds.forEach(eventId => (state.groupsState[eventId] = UNKNOWN));
        },
        pendingMarketGroups: ServicesUtils.createPendingHandler('groupsState'),
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

const adaptMarketGroup = marketGroup => {
    if (!marketGroup) {
        return undefined;
    }
    // Ensure market info types.
    const { aggregatedMarkets: marketInfos, ...otherGroupProps } = marketGroup;
    return { ...otherGroupProps, marketInfos };
};

/**
 * Market groups store actions.
 *
 * @class MarketGroupsActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.MarketGroups
 */

/**
 * Subscribes to market groups.
 *
 * @function subscribeMarketGroups
 *
 * @param {{clientId: string, groupIds: string}} payload - Subscription payload. Contains the ids of market groups to subscribe to and the id of subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to market groups thunk.
 * @memberof Mojito.Services.SportsContent.MarketGroups.actions
 */
actions.subscribeMarketGroups = payload => {
    return dispatch => {
        const { clientId, groupIds } = payload;
        const pendingGroupIds = getMarketGroupsChannel().subscribe(
            groupIds,
            clientId,
            (groupId, group) => dispatch(actions.updateMarketGroup({ groupId, group }))
        );
        !isEmpty(pendingGroupIds) && dispatch(actions.pendingMarketGroups(pendingGroupIds));
    };
};

/**
 * Unsubscribe client from all market groups.
 *
 * @function unsubscribeMarketGroups
 *
 * @param {string} clientId - The id of subscriber which aims to be unsubscribed.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from market groups thunk.
 * @memberof Mojito.Services.SportsContent.MarketGroups.actions
 */
actions.unsubscribeMarketGroups = clientId => {
    return dispatch => {
        getMarketGroupsChannel().unsubscribeAll(clientId, groupIds => {
            dispatch(actions.removeMarketGroups({ groupIds }));
        });
    };
};

// Register thunks in common registry to use them by request data helper.
actionsRegistry.addSubscription(
    MARKET_GROUPS,
    actions.subscribeMarketGroups,
    actions.unsubscribeMarketGroups
);

/**
 * Update market group.
 *
 * @function updateMarketGroup
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Payload for event update.
 * @param {string} payload.groupId - Market group id.
 * @param {object|undefined} payload.group - Market group object.
 * @memberof Mojito.Services.SportsContent.MarketGroups.actions
 */

/**
 * Remove market groups.
 *
 * @function removeMarketGroups
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Payload for event update.
 * @param {string} payload.groupIds - Group ids to remove.
 * @memberof Mojito.Services.SportsContent.MarketGroups.actions
 */

/**
 * Pending market groups.
 *
 * @function pendingMarketGroups
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{groupIds: Array<string>}} payload - Payload contains the ids of pending market groups.
 * @memberof Mojito.Services.SportsContent.MarketGroups.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
