import CurrencyHelper from 'presentation/formatters/currency/index.js';
import GenericBetFunds from 'presentation/components/bet-funds/generic-info/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const PrewagerPayoutInfo = props => {
    const { prewager, isPotential, currencyCode, mojitoTools } = props;
    const { stringResolver, config } = mojitoTools;

    const { cash = 0, bonus = 0 } = (prewager && prewager.payout) || {};

    if (bonus === 0) {
        return null;
    }

    return (
        <FlexPane config={config.container}>
            <GenericBetFunds
                config={config.cash}
                value={CurrencyHelper.formatCurrency(cash, currencyCode)}
                label={stringResolver.resolveString(getLabelKey(isPotential, true))}
            />
            <GenericBetFunds
                config={config.bonus}
                value={CurrencyHelper.formatCurrency(bonus, currencyCode)}
                label={stringResolver.resolveString(getLabelKey(isPotential))}
            />
        </FlexPane>
    );
};

const getLabelKey = (isPotential, isCash = false) => {
    if (isCash) {
        return isPotential ? '$BET_FUNDS.POTENTIAL_CASH_PAYOUT' : '$BET_FUNDS.CASH_PAYOUT';
    }

    return isPotential ? '$BET_FUNDS.POTENTIAL_BONUS_PAYOUT' : '$BET_FUNDS.BONUS_PAYOUT';
};

export default PrewagerPayoutInfo;
