import MojitoCore from 'mojito/core';
import AuthenticationTypes from './types.js';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;
const { STATES } = AuthenticationTypes;

/**
 * Authentication related selectors.
 *
 * @module AuthenticationSelectors
 * @name selectors
 * @memberof Mojito.Services.Authentication
 */

/**
 * Selects authentication state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.Authentication.types.AuthenticationStoreState} Authentication state.
 * @memberof Mojito.Services.Authentication.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects login state.
 *
 * @function selectLoginState
 *
 * @param {object} [state] - Application state object.
 * @returns {Mojito.Services.Authentication.types.STATES} Authentication state.
 * @memberof Mojito.Services.Authentication.selectors
 */
export const selectLoginState = state => selectState(state).loginState;

/**
 * Get time when user logged in. Returns undefined if user is not logged in.
 *
 * @function selectLoginTime
 *
 * @param {object} [state] - Application state object.
 *
 * @returns {string|undefined} Login time.
 * @memberof Mojito.Services.Authentication.selectors
 */
export const selectLoginTime = state => selectState(state).loginTime;

/**
 * Boolean indicator either user is logged in or not.
 *
 * @function isLoggedIn
 *
 * @param {object} [state] - Application state object.
 * @returns {boolean} True if user is logged in.
 * @memberof Mojito.Services.Authentication.selectors
 */
export const isLoggedIn = state => selectLoginState(state) === STATES.LOGGED_IN;

/**
 * Selects password change state.
 *
 * @function selectPasswordChangeState
 *
 * @param {object} [state] - Application state object.
 * @returns {Mojito.Services.Authentication.types.PASSWORD_CHANGE_STATE} The state of password change process.
 * @memberof Mojito.Services.Authentication.selectors
 */
export const selectPasswordChangeState = state => selectState(state).passwordChangeState;

/**
 * Subscribes to the changes of particular authentication state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Services.Authentication.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
