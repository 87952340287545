import MojitoPresentation from 'mojito/presentation';
import PrebuiltBet from './prebuilt-bet/index.jsx';
import PrebuiltBetsSkeleton from './skeleton/index.jsx';
import { useMarketsLoadDone, useEventsLoadDone } from 'modules/common/hooks';
import { useEffect } from 'react';
import { range } from 'mojito/utils';

const { Swiper, LoaderSuspense, FlexPane, Text } = MojitoPresentation.Components;

const PrebuiltBets = props => {
    const {
        betItems,
        mojitoTools: { config, stringResolver },
        onPrepareCardsToRender,
    } = props;
    const skeleton = <PrebuiltBetsSkeleton config={config.prebuiltBetsSkeleton} />;
    const headerLabel = stringResolver.resolveString('$PREBUILT_BETS.TITLE_HEADER');

    const eventIds = betItems?.map(bet => bet.eventId);
    const marketIds = betItems?.map(bet => bet.marketId);
    const isEventsLoaded = useEventsLoadDone(eventIds);
    const isMarketsLoaded = useMarketsLoadDone(marketIds);

    useEffect(() => {
        if (!config.showCarousel) {
            onPrepareCardsToRender(range(betItems?.length));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betItems?.length, config.showCarousel]);

    return (
        <FlexPane class="ta-PrebuiltBets" config={config.container}>
            {config.prebuiltBet.showCardHeader && (
                <Text class="ta-PrebuiltBetsCarouselTitle" config={config.carouselTitle}>
                    {headerLabel}
                </Text>
            )}
            <LoaderSuspense
                config={config.skeletonLoader}
                isContentPending={!isEventsLoaded || !isMarketsLoaded}
                loader={skeleton}
            >
                {config.showCarousel ? (
                    <FlexPane class="ta-PrebuiltBetsCarousel" config={config.carouselContainer}>
                        <Swiper
                            config={config.swiper}
                            onPrepareSlidesToRender={onPrepareCardsToRender}
                            hideNavigationArrows={
                                betItems?.length < config.minBetsForEnablingArrowNav
                            }
                        >
                            {betItems?.map(betInfo => (
                                <PrebuiltBet
                                    key={betInfo.marketId}
                                    config={config.prebuiltBet}
                                    eventId={betInfo.eventId}
                                    marketId={betInfo.marketId}
                                    shouldRequestData={props.shouldRequestData}
                                />
                            ))}
                        </Swiper>
                    </FlexPane>
                ) : (
                    <FlexPane class="ta-PrebuiltBetsContainer" config={config.betsContainer}>
                        {betItems?.map(betInfo => (
                            <PrebuiltBet
                                key={betInfo.marketId}
                                config={config.prebuiltBet}
                                eventId={betInfo.eventId}
                                marketId={betInfo.marketId}
                                shouldRequestData={props.shouldRequestData}
                            />
                        ))}
                    </FlexPane>
                )}
            </LoaderSuspense>
        </FlexPane>
    );
};

export default PrebuiltBets;
