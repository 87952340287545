import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';

const NamedStorageService = MojitoCore.Services.Storage.NamedService;
const reduxInstance = MojitoCore.Services.redux;

const SPORTS_MENU_KEY = 'sportsMenu';
const SHOW_ALL_SPORTS_KEY = 'showAllSports';
const FAVORITE_SPORTS_KEY = 'favoriteSports';

const DEFAULT_SHOW_ALL_SPORTS_STATE = true;
const sportsMenuStorage = new NamedStorageService(SPORTS_MENU_KEY);
const favoriteSportsStorage = new NamedStorageService(FAVORITE_SPORTS_KEY);

/**
 * Defines the state of the sports menu store.
 *
 * @typedef SportsMenuState
 *
 * @property {number} [favoriteSportsMaxAmount = 5] - Max number of favorite sports. Setting more sports as favorites will unset the oldest favorite.
 * @property {boolean} showAllSports - Boolean to control if all sports should be shown. This does not include favorites.
 * @property {string[]} favoriteSports - Array of favorite sports.
 *
 * @memberof Mojito.Modules.SportsMenu
 */

/**
 * The name of the sports menu state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Modules.SportsMenu
 */
export const STORE_KEY = 'modules/sports-menu';

const showAllSportsStorageObject = sportsMenuStorage.getItem();
export const INITIAL_STATE = {
    favoriteSportsMaxAmount: 5,
    showAllSports: showAllSportsStorageObject
        ? showAllSportsStorageObject[SHOW_ALL_SPORTS_KEY]
        : DEFAULT_SHOW_ALL_SPORTS_STATE,
    favoriteSports: favoriteSportsStorage.getItem() || [],
};

export const { reducer, actions } = createSlice({
    name: 'sportsMenuModule',
    initialState: INITIAL_STATE,
    reducers: {
        showAllSports(state, { payload }) {
            state.showAllSports = payload;
        },
        setFavoriteSportsMaxAmount(state, { payload }) {
            state.favoriteSportsMaxAmount = payload;
        },
        toggleFavoriteSport(state, { payload: sportId }) {
            const { favoriteSports, favoriteSportsMaxAmount } = state;

            if (favoriteSports.includes(sportId)) {
                state.favoriteSports = favoriteSports.filter(id => id !== sportId);
            } else {
                state.favoriteSports = [sportId, ...favoriteSports].slice(
                    0,
                    favoriteSportsMaxAmount
                );
            }
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Sports Menu related actions.
 *
 * @class SportsMenuActions
 * @name actions
 * @memberof Mojito.Modules.SportsMenu
 */

/**
 * Show or hide all sports.
 *
 * @function showAllSports
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {boolean} payload - Show or hide all sports.
 * @memberof Mojito.Modules.SportsMenu.actions
 */

/**
 * Set the favorite sports max amount to show.
 *
 * @function setFavoriteSportsMaxAmount
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {number} payload - Max amount of favorite sports to show.
 * @memberof Mojito.Modules.SportsMenu.actions
 */

/**
 * Add or remove favorite sport.
 *
 * @function toggleFavoriteSport
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {string} id - Id of the sport to be added or removed from favorite sports.
 * @memberof Mojito.Modules.SportsMenu.actions
 */

reduxInstance.actionListener.startListening({
    actionCreator: actions.showAllSports,
    effect: (action, listenerApi) => {
        const state = listenerApi.getState()[STORE_KEY].showAllSports;
        sportsMenuStorage.setItem({
            ...sportsMenuStorage.getItem(),
            [SHOW_ALL_SPORTS_KEY]: state,
        });
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.toggleFavoriteSport,
    effect: (action, listenerApi) => {
        const state = listenerApi.getState()[STORE_KEY].favoriteSports;
        favoriteSportsStorage.setItem(state);
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
