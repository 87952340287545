import MojitoApplication from 'mojito/application';

const {RouteResolver} = MojitoApplication;

RouteResolver.prototype.enrichWithRoot = function (path) {
    let root = this.getRoot() || '/';
    if (path === '') {
        return root; // do not add extra slash if path is empty (typically homepage)
    }
    if (root !== '/') {
        root = `${root}/`;
    }
    // Prevent slashes duplication
    return `${root}${path}`.replace(/\/+/g, '/');
};
