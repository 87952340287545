import {
    breadcrumbsIntegration,
    browserTracingIntegration,
    inboundFiltersIntegration,
    dedupeIntegration,
    httpContextIntegration,
    linkedErrorsIntegration,
} from '@sentry/react';
import {functionToStringIntegration} from '@sentry/core';

export const getIntegrations = () => [
    inboundFiltersIntegration(),
    functionToStringIntegration(),

    // browserApiErrorsIntegration(), // < - disabled
    breadcrumbsIntegration(),
    // globalHandlersIntegration(), // < - disabled
    linkedErrorsIntegration(),
    dedupeIntegration(),
    httpContextIntegration(),
    browserTracingIntegration({
        idleTimeout: 5 * 1000, // Send transaction after 5 secs of idling
        heartbeatInterval: 10 * 1000, // Heartbeat each 10 seconds. Force tracing after 3 heartbeats if stale spans detected, i.e. 30 secs
        finalTimeout: 60 * 1000, // Maximum wait time. Force transaction finish after 60 secs
        instrumentNavigation: false, // Disables creation of navigation transaction on history changes
    }),
];
