import PropTypes from 'prop-types';
import CashStakeImpl from './cash-stake-info.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Component to display cash stake info of bet funds.
 *
 * @class CashStakeInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * CashStakeInfo props.
 *
 * @property {number} [value] - Stake value.
 * @property {string} currencyCode - Currency.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.CashStakeInfo
 */
const propTypes = {
    value: PropTypes.number,
    currencyCode: PropTypes.string.isRequired,
};

export default UIView('CashStakeInfo', CashStakeImpl, propTypes);
