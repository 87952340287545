import {PaletteRetriever} from 'core/application/modules/palette-retriever';
import BASIC_PALETTE_SCHEMA from './basic.palette-schema.yaml';
import {Logger} from 'core/utils/logger';
import {registerPaletteSchema} from '../schema-registry';
import {deepFreezeAllEnumerables} from 'core/utils/config-utils';

const log = Logger('BasicPalette');

export const BasicPalette = {
    palette: {},

    load: function (config) {
        let validator = new PaletteRetriever(log);
        validator.constructPaletteBySchema(config, BASIC_PALETTE_SCHEMA);
        this.palette = validator.intermediateCache; // not resulted palette, but with dark/light colors
        process.env.NODE_ENV === 'development' && deepFreezeAllEnumerables(this.palette);
    },
};

registerPaletteSchema(BASIC_PALETTE_SCHEMA);
