import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import watch from 'redux-watch';
import { get, isEmpty } from 'mojito/utils';
import { initState, resetState } from './actions.js';

/**
 * Instance responsible for creating redux store. Provides additional features like
 * dynamic reducer injection and state watch.
 *
 * @class ReduxInstance
 * @name redux
 * @memberof Mojito.Core.Services
 */
class ReduxInstance {
    constructor() {
        this.reducers = {};
        this.listenerMiddleware = createListenerMiddleware();
        this.initialState = {};
        this.init(this.reducers);
    }

    /**
     * Checks if store has been created.
     *
     * @returns {boolean} True if store initialised.
     * @function Mojito.Core.Services.redux#initialized
     */
    get initialized() {
        return !!this.store;
    }

    /**
     * Gets the instance of redux store produced on {@link Mojito.Core.Services.redux#init|init} stage.
     *
     * @returns {object} The instance of the redux store.
     * @function Mojito.Core.Services.redux#store
     */
    get store() {
        return this.storeInstance;
    }

    /**
     * Gets action listener. A {@link https://redux-toolkit.js.org/api/createListenerMiddleware|Redux middleware} that lets you define "listener" entries that
     * contain an "effect" callback with additional logic, and a way to specify when that callback should run based on dispatched actions or state changes.
     *
     * @returns {object} The instance of listener middleware.
     * @function Mojito.Core.Services.redux#actionListener
     */
    get actionListener() {
        return this.listenerMiddleware;
    }

    /**
     * Init redux store instances. Using {@link https://redux-toolkit.js.org/api/configureStore|configureStore} Redux toolkit function for store creation.
     *
     * @param {object} reducers - An object of slice reducers that will be passed to `combineReducers()` for store creation.
     * @param {object} [state] - Initial application state.
     *
     * @function Mojito.Core.Services.redux#init
     */
    init(reducers, state) {
        const appReducer = !isEmpty(reducers) ? combineReducers(reducers) : reducer => reducer;
        this.storeInstance = configureStore({
            reducer: this.createRootReducer(appReducer),
            preloadedState: state,
            devTools: process.env.NODE_ENV !== 'production',
            middleware: getDefaultMiddleware =>
                getDefaultMiddleware().prepend(this.listenerMiddleware.middleware),
        });
        this.initialState = state || {};
        this.reducers = reducers;
    }

    /**
     * Gives possibility to watch to particular property changes of the state,
     * so that you can be notified when the state changes.
     *
     * @param {string|Array} statePath - The path to the state property, see {@link https://www.npmjs.com/package/object-path|object-path}.
     * @param {Function} onChange - Callback function executed on observable state fragment change. Accepts changed value and store state.
     *
     * @returns {Function|undefined} Unwatch function.
     *
     * @function Mojito.Core.Services.redux#watch
     */
    watch(statePath, onChange) {
        if (!this.initialized) {
            return;
        }
        const watcher = watch(this.store.getState, statePath);
        return this.store.subscribe(
            watcher(() => {
                const state = this.store.getState();
                onChange(get(state, statePath), state);
            })
        );
    }

    /**
     * Attach reducer to the store instance.
     * Can be used to dynamically infect reducers which is helpful for code splitting.
     *
     * @param {string} key - The path to the state property, see {@link https://www.npmjs.com/package/object-path|object-path}.
     * @param {Function} reducer - Reducer function.
     *
     * @function Mojito.Core.Services.redux#injectReducer
     */
    injectReducer(key, reducer) {
        const newReducers = { ...this.reducers, [key]: reducer };
        if (!this.initialized) {
            this.init(newReducers);
        } else {
            this.reducers = newReducers;
            const appReducers = combineReducers(this.reducers);
            const rootReducer = this.createRootReducer(appReducers);
            this.store.replaceReducer(rootReducer);
            this.initialState = { ...this.initialState, [key]: this.store.getState()[key] };
        }
    }

    /**
     * Subscribes to the changes of a particular events state property.
     * <br>
     * The code in the following example will trigger the `onLoginTimeChange` function every time [`loginTime`](Mojito.Services.Authentication.types.AuthenticationStoreState.loginTime) property updates.
     *
     * @example const onLoginTimeChange = loginTime => console.log('Login time updated: ', loginTime);
     * subscribe('loginTime', onLoginTimeChange);
     *
     * @function getSubscriber
     * @param {string} storeKey - The identifier of the store slice. The same key which was used during [`injectReducer`](Mojito.Core.Services.redux.injectReducer) when registering the reducer in redux.
     * @returns {Mojito.Core.Services.redux.SubscriberFunction} Function used to subscribe to a particular store state property.
     * @memberof Mojito.Core.Services.redux
     */
    getSubscriber(storeKey) {
        return (stateProp, onChange) =>
            this.watch(`${storeKey}.${stateProp}`, (value, reduxState) =>
                onChange(value, reduxState[storeKey])
            );
    }

    createRootReducer(appReducer) {
        return (state, action) => {
            switch (action.type) {
                case `${initState}`:
                    return action.payload;
                case `${resetState}`:
                    return this.initialState;
                default:
                    return appReducer(state, action);
            }
        };
    }
}

export default new ReduxInstance();

/**
 * A Thunk function that takes two arguments: the Redux store dispatch method and the Redux store getState method.
 * Thunk functions are not directly invoked by application code, but are passed to the store.dispatch() function.
 *
 * @function ThunkFunction
 *
 * @param {Function} dispatch - Redux dispatch function to trigger actions from thunk.
 * @param {Function} getState - A function that returns the current state of the Redux store.
 *
 * @returns {Function} Typically, thunks resolve with a Promise (if they involve async actions) or return nothing.
 * @memberof Mojito.Core.Services.redux
 */

/**
 * Redux action.
 *
 * @typedef Action
 *
 * @property {string} type - Redux action type.
 * @property {*} payload - Action payload.
 *
 * @memberof Mojito.Core.Services.redux
 */

/**
 * Action creators are functions that create actions.
 *
 * @callback ActionCreator
 * @returns {Mojito.Core.Services.redux.Action} The generated Redux action.
 *
 * @memberof Mojito.Core.Services.redux
 */

/**
 * Subscribes to the changes of a particular store slice state property.
 *
 * @example <caption>The following code will trigger the `onLoginTimeChange` function every time the `loginTime` property updates on a particular store slice.</caption>
 * const onLoginTimeChange = loginTime => console.log('Updated Login Time: ', loginTime);
 * subscribe('loginTime', onLoginTimeChange);
 *
 * @callback SubscriberFunction
 * @param {string} stateProp - Property name within the observable state slice.
 * @param {Function} onChange - Callback function executed on state fragment change. Accepts changed value and observed store slice.
 * @returns {Function} Unsubscribe function.
 * @memberof Mojito.Core.Services.redux.
 */
