import { createRef, Children, cloneElement } from 'react';
import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import SelectedIndicator from 'presentation/components/selected-indicator/index.jsx';
import { pick } from 'mojito/utils';
const classUtils = MojitoCore.Base.classUtils;

export default class SelectableButtonGroup extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.state = {
            selectedButtonRenderData: undefined,
        };

        this.onSelectableButtonClick = selectableKey => () => {
            this.props.onSelectionChange(selectableKey);
        };

        this.selectedButtonRef = this.props.selectedButtonElementRef || createRef();
    }

    componentDidMount() {
        this.updateSlidingSelectedIndicator();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedKey !== prevProps.selectedKey) {
            this.updateSlidingSelectedIndicator();
        }
    }

    updateSlidingSelectedIndicator() {
        if (!this.config.showSlidingSelectedIndicator) {
            return;
        }

        const selectedButtonEl = this.selectedButtonRef.current;
        const selectedButtonRenderData =
            selectedButtonEl &&
            pick(selectedButtonEl, 'offsetWidth', 'offsetHeight', 'offsetLeft', 'offsetTop');
        this.setState({ selectedButtonRenderData });
    }

    renderSlidingSelectedIndicator() {
        if (!this.state.selectedButtonRenderData) {
            return null;
        }

        const { offsetWidth, offsetHeight, offsetLeft, offsetTop } =
            this.state.selectedButtonRenderData;

        const containerStyle = {
            ...this.style.selectedIndicatorContainer,
            transform: `translate3d(${offsetLeft}px, ${offsetTop}px, 0px)`,
            width: offsetWidth,
            height: offsetHeight,
        };

        return (
            <div style={containerStyle}>
                <SelectedIndicator config={this.config.slidingSelectedIndicator} />
            </div>
        );
    }

    getSelectableButtons() {
        const children = this.props.children.filter(child => !!child);
        return Children.map(children, child => {
            const isSelected = child.props.selectableKey === this.props.selectedKey;
            return cloneElement(child, {
                selected: isSelected,
                disabled: child.props.disabled || (this.config.disableSelectedButton && isSelected),
                onClick: this.onSelectableButtonClick(child.props.selectableKey),
                onClickData: child,
                config: this.config.selectableButton,
                buttonElementRef: isSelected ? this.selectedButtonRef : undefined,
                class: classUtils.classNames(
                    child.props.class || `ta-${child.props.selectableKey}`,
                    isSelected && 'ta-selected'
                ),
            });
        });
    }

    render() {
        return (
            <FlexPane
                config={this.config.container}
                class={`ta-SelectableButtonGroup ${this.props.class || ''}`}
            >
                {this.config.showSlidingSelectedIndicator && this.renderSlidingSelectedIndicator()}
                {this.getSelectableButtons()}
            </FlexPane>
        );
    }
}

SelectableButtonGroup.getStyle = () => ({
    selectedIndicatorContainer: {
        position: 'absolute',
        transitionProperty: 'transform, width, height',
        transitionDuration: '0.2s',
        willChange: 'transform',
    },
});
