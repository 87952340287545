import { createAction, isPlain } from '@reduxjs/toolkit';

/**
 * Object with intent action creators.
 * <br>
 * Intent actions are used to indicate that an action of some kind should be performed.
 * Typically used for 'navigating' to a specific page in the application.
 * <br>
 * Usually, this object is not used directly, instead the IntentActionTrigger is used to trigger an intent of a certain type.
 *
 * @see Mojito.Presentation.Base.Intent.IntentActionTrigger
 *
 * @typedef IntentActions
 * @name intentActions
 * @memberof Mojito.Core.Intents
 */
const actions = {
    /**
     * @function publishIntent - Create an intent action that other stores may react to.
     *
     * @see Mojito.Presentation.Base.Intent.IntentTypes
     *
     * @param {Mojito.Presentation.Base.Intent.IntentTypes} type - Type of intent to publish.
     * @param {object | string} data - Optional data to supply with the publishing of the intent.
     *
     * @returns {Mojito.Core.Services.redux.Action} An action object with payload property which includes intent type and data.
     */
    publishIntent: createAction('publishIntent', (type, data) => ({
        payload: {
            type,
            data: isPlain(data) ? data : { ...data },
        },
    })),
};

export default actions;
