import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * Abstract video streaming service to be implemented by different integrations.
 * Note: All methods must be implemented in a asynchronous way and return promise.
 *
 * @class AbstractVideoStreamingService
 * @abstract
 * @memberof Mojito.Services.VideoStreaming
 */
export default class AbstractVideoStreamingService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @function Mojito.Services.VideoStreaming.AbstractVideoStreamingService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Get video streaming data.
     *
     * @param {Mojito.Services.VideoStreaming.types.VideoStreamingRequest} payload - Video streaming request payload.
     *
     * @returns {Promise} Get video streaming promise.
     * @function Mojito.Services.VideoStreaming.AbstractVideoStreamingService#getStreaming
     */
    // eslint-disable-next-line no-unused-vars
    getStreaming(payload) {
        log.error('getStreaming must be implemented by concrete subclass');
        return Promise.reject();
    }
}
