import { useState } from 'react';
import MojitoServices from 'mojito/services';
import EventFormatter from 'presentation/formatters/event/formatter.js';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import TeamIcon from 'presentation/components/team-icon/index.jsx';

const EventsUtils = MojitoServices.SportsContent.Events.utils;

export default function Participants(props) {
    const {
        event,
        mojitoTools: { config, stringResolver },
        drawLabel,
        forceDisplayEventName,
    } = props;

    const hasMoreThanOneParticipant = event.participants?.length > 1;
    const showEventName =
        forceDisplayEventName ||
        EventFormatter.shouldDisplayAsOutright(event) ||
        !hasMoreThanOneParticipant;
    const isThreeParticipantsEvent = EventsUtils.isThreeParticipantsEvent(event);
    const { enabledSports } = config.teamIcon;
    const isSportEnabled = enabledSports.includes(event.sportId);
    const [showTeamLogo, setShowTeamLogo] = useState(isSportEnabled && config.showTeamLogo);
    const teamValidIcons = {};

    const isSomeTeamIconsValid = () =>
        // check if at least one participant has a valid team icon, otherwise hide all team icons.
        event.participants.some(participant => teamValidIcons[participant.id]);

    const updateTeamValidIcons = (id, hasTeamIcon = false) => {
        teamValidIcons[id] = hasTeamIcon;
        // update state only when all team icons are loaded.
        if (Object.keys(teamValidIcons).length === event.participants.length) {
            setShowTeamLogo(isSomeTeamIconsValid());
        }
    };

    return (
        <FlexPane config={config.container} class="ta-Participants">
            {showEventName ? (
                <EventNameLabel event={event} config={config} />
            ) : (
                <>
                    <Participant
                        {...props}
                        participant={event.participants[0]}
                        updateTeamValidIcons={updateTeamValidIcons}
                        showTeamLogo={showTeamLogo}
                    />
                    {config.showSeparator && (
                        <SeparatorLabel
                            hasTeamLogo={showTeamLogo}
                            config={config}
                            text={resolveSeparatorLabel(event.homeAway, stringResolver)}
                        />
                    )}
                    <Participant
                        {...props}
                        participant={event.participants[1]}
                        updateTeamValidIcons={updateTeamValidIcons}
                        showTeamLogo={showTeamLogo}
                    />
                    {isThreeParticipantsEvent && (
                        <Participant
                            {...props}
                            participant={event.participants[2]}
                            updateTeamValidIcons={updateTeamValidIcons}
                            showTeamLogo={showTeamLogo}
                        />
                    )}
                    <DrawLabel config={config} text={drawLabel} />
                </>
            )}
        </FlexPane>
    );
}

Participants.getStyle = (config, mode, merge) => {
    return {
        oneLineText: merge(config.text, { ellipsisOnLineNumber: 1 }),
    };
};

const EventNameLabel = ({ event, config }) => {
    if (EventsUtils.isOutrightEventType(event.eventType)) {
        return (
            <Text config={config.outrightText} class="ta-outRightType">
                {event.name}
            </Text>
        );
    }

    return (
        <>
            {event.class && <Text config={config.outrightText}>{event.class}</Text>}
            {event.name && <Text config={config.outrightText}>{event.name}</Text>}
        </>
    );
};

const Participant = props => {
    const {
        event,
        participant,
        updateTeamValidIcons,
        showTeamLogo,
        mojitoTools: { config, style },
    } = props;
    const { id, name, shortName, startingPitcherName } = participant;
    const textStyle = startingPitcherName ? style.oneLineText : config.text;
    const [hasTeamIcon, setHasTeamIcon] = useState(true);
    const participantName = config.showShortName && showTeamLogo ? shortName : name;

    const teamIconOnInit = iconData => {
        const { hasTeamIconMapping } = iconData;
        setHasTeamIcon(hasTeamIconMapping);
    };

    const teamIconOnLoad = () => {
        //  if team icon exists and is loaded successfully, set it as valid.
        updateTeamValidIcons(id, hasTeamIcon);
    };

    const teamIconOnError = () => {
        // if team icon failed to load, set it as invalid.
        updateTeamValidIcons(id, false);
    };

    return (
        <FlexPane config={config.participantWrapper}>
            {config.showTeamLogo && (
                <TeamIcon
                    sportCode={event.sportId}
                    teamId={id}
                    onInit={teamIconOnInit}
                    onLoad={teamIconOnLoad}
                    onError={teamIconOnError}
                    config={showTeamLogo ? config.teamIcon : config.teamIconHidden}
                />
            )}
            <FlexPane config={config.textWrapper}>
                <Text class="ta-participantName" config={textStyle}>
                    {participantName}
                </Text>
                {config.showStartingPitcher && startingPitcherName && (
                    <Text class="ta-startingPitcher" config={config.startingPitcherName}>
                        {startingPitcherName}
                    </Text>
                )}
            </FlexPane>
        </FlexPane>
    );
};

const DrawLabel = ({ config, text }) => {
    if (!text) {
        return null;
    }

    return (
        <FlexPane config={config.drawLabelTextWrapper}>
            <Text class="ta-drawLabel" config={config.text}>
                {text}
            </Text>
        </FlexPane>
    );
};

const SeparatorLabel = ({ config, text, hasTeamLogo }) => {
    if (!text) {
        return null;
    }

    const separatorWrapperConfig = hasTeamLogo
        ? config.separatorWrapperWithTeamLogo
        : config.separatorWrapper;

    return (
        <FlexPane config={separatorWrapperConfig}>
            <Text class="ta-separatorLabel" config={config.separatorText}>
                {text}
            </Text>
        </FlexPane>
    );
};

const resolveSeparatorLabel = (homeAway, stringResolver) => {
    const l10nString = homeAway
        ? '$PARTICIPANTS.SEPARATOR.HOME_AWAY'
        : '$PARTICIPANTS.SEPARATOR.AWAY_HOME';
    return stringResolver.resolveString(l10nString);
};
