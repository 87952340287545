import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { omit, isString } from 'mojito/utils';

const { selectLanguage } = MojitoCore.Services.SystemSettings.selectors;
const CurrencyConfig = MojitoCore.Services.CurrencyConfig;
const { selectCurrency } = MojitoServices.UserInfo.selectors;
const { selectCountryCode } = MojitoServices.UserSettings.selectors;
const { StringUtils, NumberUtils } = MojitoCore.Base;
const { RESERVED_PROPS } = MojitoCore.Services.Config;

const numberFormatMap = new Map();
const decimalMap = new Map();
// A decimal number to use with the ECMAScript Internationalization API for finding out the decimal separator for a specific locale.
const A_DECIMAL_NUMBER = 1.2;
const DECIMAL_SEPARATOR_MATCHER = /[,.]/g;
const DECIMAL = {
    COMMA: ',',
    POINT: '.',
};

/**
 * The `CurrencyHelper` provides helper methods for currency-related operations.
 *
 * The locale used for formatting a currency can be configured in `Currencies.currencyLocaleOverrides`. If no configuration is
 * provided for a certain currency, the locale specified by the chosen language for the application will be used instead.
 *
 * @class CurrencyHelper
 * @memberof Mojito.Presentation.Utils
 */
export default class CurrencyHelper {
    /**
     * Format the given value into a currency string according to the locale used.
     * The ECMAScript Internationalization API is utilized to format the currency.
     *
     * @param {number|string} amount - The amount of money.
     * @param {string} [currencyCode] - The currency code (e.g., EUR, GBP, SEK, etc).
     * @returns {string} The formatted currency string.
     * @function Mojito.Presentation.Utils.CurrencyHelper.formatCurrency
     */
    static formatCurrency(amount, currencyCode) {
        const {
            defaultCurrencyCode,
            defaultOptions = {},
            optionsByCurrency = {},
        } = CurrencyConfig.getCurrencies();
        const currency = currencyCode || defaultCurrencyCode;
        const locale = CurrencyHelper.getLocal(currency);
        const useCurrency = typeof currency === 'string';
        const key = CurrencyHelper.getKey(locale, currency);
        // Allow to override locale formatted currency symbol since it is a bit incomplete. COP for example.

        if (!numberFormatMap.has(key)) {
            const currencySpecificOptions = optionsByCurrency[currency];
            const extendedOptions = currencySpecificOptions || defaultOptions;
            const options = useCurrency
                ? {
                      ...omit(extendedOptions, RESERVED_PROPS),
                      style: 'currency',
                      currency: currency,
                  }
                : {};
            numberFormatMap.set(key, new Intl.NumberFormat(locale, options));
        }
        const numberFormat = numberFormatMap.get(key);
        return CurrencyHelper.overrideCurrencySymbol(numberFormat.format(amount), currency);
    }

    /**
     * Format the given value into a currency string according to the locale used.
     * The ECMAScript Internationalization API is utilized to format the currency.
     *
     * @param {number|string} amount - The amount of money.
     * @param {string} [currencyCode] - The currency code (EUR, GBP, SEK, etc).
     * @param {object} [customCurrencyOptions] - Currency formatting options.
     * @returns {string} The formatted currency string.
     * @function Mojito.Presentation.Utils.CurrencyHelper.formatCurrencyWithSpecificOptions
     */
    static formatCurrencyWithSpecificOptions(amount, currencyCode, customCurrencyOptions) {
        const {
            defaultCurrencyCode,
            defaultOptions = {},
            optionsByCurrency = {},
        } = CurrencyConfig.getCurrencies();
        const currency = currencyCode || defaultCurrencyCode;
        const locale = CurrencyHelper.getLocal(currency);
        const useCurrency = isString(currency);
        const currencySpecificOptions = optionsByCurrency[currency];
        const extendedOptions = currencySpecificOptions || defaultOptions;

        const options = useCurrency
            ? {
                  ...omit(extendedOptions, '_metadata'),
                  ...customCurrencyOptions,
                  style: 'currency',
                  currency: currency,
              }
            : {};

        const numberFormat = new Intl.NumberFormat(locale, options);

        return numberFormat.format(amount);
    }

    /**
     * Returns a decimal separator according to the format for the used locale.
     *
     * @returns {string} The decimal separator.
     * @function Mojito.Presentation.Utils.CurrencyHelper.getCurrencyDecimalSeparator
     */
    static getCurrencyDecimalSeparator() {
        const { defaultCurrencyCode } = CurrencyConfig.getCurrencies();
        const currencyCode = selectCurrency() || defaultCurrencyCode;
        const locale = CurrencyHelper.getLocal(currencyCode);
        const key = CurrencyHelper.getKey(locale, currencyCode);

        if (!decimalMap.has(key)) {
            const currency = CurrencyHelper.formatCurrency(A_DECIMAL_NUMBER, currencyCode);
            const decimalSeparator = currency.charAt(currency.search(DECIMAL_SEPARATOR_MATCHER));
            decimalMap.set(key, decimalSeparator);
        }

        return decimalMap.get(key);
    }

    /**
     * Changes the decimal separator for a value string according to the format for the used locale. If the value has no
     * decimal separator, it will be returned unchanged.
     * <br><br>
     * Note!<br>
     * The value string is expected to only contain a potential decimal separator and no thousands separator.
     *
     * @param {string} value - A value in string format.
     * @returns {string} The value using locale format for the decimal separator.
     * @function Mojito.Presentation.Utils.CurrencyHelper.formatToLocaleDecimalSeparator
     */
    static formatToLocaleDecimalSeparator(value) {
        return value.replace(
            DECIMAL_SEPARATOR_MATCHER,
            CurrencyHelper.getCurrencyDecimalSeparator()
        );
    }

    /**
     * Changes the decimal separator for a value string to be a point. If the value has no decimal separator, it will be
     * returned unchanged. This function is useful when the value is using comma as decimal separator, and you need to
     * parse it as a Number to, for example, use it in an arithmetic operation.
     * <br><br>
     * Note:
     * The value string is expected to only contain a potential decimal separator and no thousands separator.
     *
     * @param {string} value - A value string.
     * @returns {string} The value using a point decimal separator.
     * @function Mojito.Presentation.Utils.CurrencyHelper.formatToPointDecimalSeparator
     */
    static formatToPointDecimalSeparator(value) {
        return value.replace(DECIMAL.COMMA, DECIMAL.POINT);
    }

    /**
     * Returns a key including the locale and currencyCode strings.
     *
     * @param {(string|string[])} locales - An array of locales or a locale string.
     * @param {string} currencyCode - A currency code.
     * @returns {string} A key made from combining locale and currencyCode.
     * @private
     * @function Mojito.Presentation.Utils.CurrencyHelper.getKey
     */
    static getKey(locales, currencyCode) {
        return `${locales}${currencyCode ? currencyCode : ''}`;
    }

    /**
     * Converts an amount into a string with the overridden currency symbol.
     *
     * @param {number|string} amount - The amount of money to be converted.
     * @param {string} currency - The currency code indicating which symbol will be used.
     * @returns {string} The amount represented as a string with the overridden currency symbol.
     * @private
     * @function Mojito.Presentation.Utils.CurrencyHelper.overrideCurrencySymbol
     */
    static overrideCurrencySymbol(amount, currency) {
        const { symbolOverrides } = CurrencyConfig.getCurrencies();
        const currencySymbolOverride = symbolOverrides && symbolOverrides[currency];
        // Replace anything that is not a digit, comma, period, hyphen or whitespace with the chosen currency symbol.
        return currencySymbolOverride === undefined
            ? amount
            : amount.replace(/[^\d,.\-\s]+/, currencySymbolOverride);
    }

    /**
     * Returns an array with locales or a locale as string.
     *
     * @param {string} currency - Type of currency, e.g. EUR, GBP, SEK etc.
     * @returns {(string|string[])} Array with locales or string locale.
     * @private
     * @function Mojito.Presentation.Utils.CurrencyHelper.getLocal
     */
    static getLocal(currency) {
        const { currencyLocaleOverrides } = CurrencyConfig.getCurrencies();

        if (currencyLocaleOverrides && currencyLocaleOverrides[currency]) {
            return currencyLocaleOverrides[currency];
        }

        const userCountryCode = selectCountryCode();
        const currentLang = selectLanguage();
        const locales = [];
        if (userCountryCode && !StringUtils.isRegionalLanguage(currentLang)) {
            locales.push(currentLang.concat(`-${userCountryCode}`));
        }
        locales.push(currentLang);
        return locales;
    }

    /**
     * Convert currency represented as floating point to string. Add tailing zeroes after comma if necessary.
     *
     * @param {number} value - Currency value.
     * @param {boolean} keepAllDecimals - Add tailing zeroes after comma if true.
     * @param {number} decimalAmount - Maximum number of digits after comma.
     * @returns {string} Currency as string or empty string if not a number.
     * @function Mojito.Presentation.Utils.CurrencyHelper.formatCurrencyNoCode
     */
    static formatCurrencyNoCode(value, keepAllDecimals, decimalAmount) {
        if (isNaN(value)) {
            return '';
        }

        const valueAsString = keepAllDecimals
            ? value.toString()
            : NumberUtils.toFixed(value, decimalAmount);

        return CurrencyHelper.formatToPointDecimalSeparator(valueAsString);
    }

    /**
     * Reformat currency by adding tailing zeroes after comma if necessary.
     *
     * @param {string} currencyString - Currency value with no currency code as string.
     * @param {boolean} keepAllDecimals - Add tailing zeroes after comma if true.
     * @param {number} decimalAmount - Maximum number of digits after comma.
     * @returns {string} Currency as string or empty string if not a number.
     * @function Mojito.Presentation.Utils.CurrencyHelper.reformatCurrencyString
     */
    static reformatCurrencyString(currencyString, keepAllDecimals, decimalAmount) {
        return CurrencyHelper.formatCurrencyNoCode(
            CurrencyHelper.parseCurrencyString(currencyString),
            keepAllDecimals,
            decimalAmount
        );
    }

    /**
     * Convert currency string to floating point.
     *
     * @param {string} currencyString - Currency value with no currency code as string.
     * @returns {number} Currency as floating point number.
     * @function Mojito.Presentation.Utils.CurrencyHelper.parseCurrencyString
     */
    static parseCurrencyString(currencyString) {
        return parseFloat(CurrencyHelper.formatToPointDecimalSeparator(currencyString));
    }

    /**
     * Clear number formats and decimal formats maps. Utility function for testing.
     */
    static clearMaps() {
        numberFormatMap.clear();
        decimalMap.clear();
    }
}
