/**
 * User Settings types.
 *
 * @class UserSettingsTypes
 * @name types
 * @memberof Mojito.Services.UserSettings
 */
export default class UserSettingsTypes {}

/**
 * Available odds formats.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.UserSettings.types
 */
UserSettingsTypes.ODDS_FORMAT = {
    DECIMAL: 'decimal',
    FRACTIONAL: 'fractional',
    AMERICAN: 'american',
};

/**
 * Supported local-time format.
 *
 * @typedef LOCAL_TIME
 * @type {string}
 * @default 'localTime'
 * @memberof Mojito.Services.UserSettings.types
 */
UserSettingsTypes.LOCAL_TIME = 'localTime';

/**
 * Supported odds formats.
 *
 * @typedef ODDS_FORMATS_SUPPORTED
 * @memberof Mojito.Services.UserSettings.types
 */
UserSettingsTypes.ODDS_FORMATS_SUPPORTED = Object.values(UserSettingsTypes.ODDS_FORMAT);

/**
 * Supported onboarding types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.UserSettings.types
 * @public
 */
UserSettingsTypes.ONBOARDING_TYPES = {
    AUTO_CASHOUT: 'autoCashout',
    EVENTS_QUICK_NAVIGATION: 'eventsQuickNavigation',
    LEAGUES_QUICK_NAVIGATION: 'leaguesQuickNavigation',
    BETSLIP_FREE_BET_HINT: 'betslipFreeBetHint',
    MATCH_ACCA: 'matchAcca',
    FAST_MARKETS: 'fastMarkets',
    QUICK_START_GUIDE_BANNER: 'quickStartGuideBanner',
};

/**
 * Onboarding types which are initially enabled.
 * Some should be enabled depending on configuration or navigation to a specific page.
 *
 * @typedef ONBOARDINGS_INITIALLY_ENABLED
 * @memberof Mojito.Services.UserSettings.types
 * @public
 */
UserSettingsTypes.ONBOARDINGS_INITIALLY_ENABLED = {
    [UserSettingsTypes.ONBOARDING_TYPES.AUTO_CASHOUT]: true,
    [UserSettingsTypes.ONBOARDING_TYPES.LEAGUES_QUICK_NAVIGATION]: true,
    [UserSettingsTypes.ONBOARDING_TYPES.EVENTS_QUICK_NAVIGATION]: true,
};

/**
 * Onboarding types which are allowed for a new user.
 * Usually, onboarding is shown for user who previously used the site, but not a feature.
 *
 * @typedef ONBOARDINGS_FOR_NEW_USER
 * @memberof Mojito.Services.UserSettings.types
 * @public
 */
UserSettingsTypes.ONBOARDINGS_FOR_NEW_USER = {
    [UserSettingsTypes.ONBOARDING_TYPES.AUTO_CASHOUT]: true,
};

/**
 * User settings service layer config.
 *
 * @typedef UserSettingsConfig
 * @type {object}
 * @property {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - The format of odds presentation.
 * @property {Mojito.Services.UserSettings.types.DateTimeFormat} dateTimeFormat - Date time format options.
 * @property {string} countryCode - Two letter ISO 3166-1 alpha-2 country code.
 *
 * @memberof Mojito.Services.UserSettings.types
 */

/**
 * Date time format options.
 * Used for date and time formatting across application based on defined preferences, such as user locale, selected GMT time offset or time zone, etc.
 *
 * @typedef DateTimeFormat
 * @type {object}
 * @property {string|string[]} locales - A string with a BCP 47 language tag, or an array of such strings.
 * @property {string} timeZone - IANA time zone name, e.g. `Europe/Stockholm`. Takes precedence over <code>timeOffset</code>.
 * @property {number|string} timeOffset - Numeric offset from GMT (-12 - 12) or string 'localTime' if system time should be used. This property will be ignored if `timeZone` is set.
 *
 * @memberof Mojito.Services.UserSettings.types
 */
