import MojitoServices from 'mojito/services';

const {AbstractBalanceService} = MojitoServices.UserInfo.Balance;

export class DummyBalanceService extends AbstractBalanceService {
    // eslint-disable-next-line no-unused-vars
    subscribeToBalanceUpdates(currency, success) {
        if (this.isSubscribed) return;

        super.subscribeToBalanceUpdates(...arguments);

        // Do not call success. No balances arrived - no success
        // success();
    }
}
