import {Logger} from '#core/utils/logger.js';
import {countProperties} from '#core/utils/config-utils.js';

const log = Logger('Statistics');

let totalGeneratedPropertiesCount = 0;
let generatedPropertiesCountById = {};

let totalManualCount = 0;
let manualCountById = {};

/**
 * Count properties of provided object, and increase statistics
 * @param id {string}
 * @param generated {object}
 * @param manual {object}
 */
export function countAndIncreaseAutomatedPropertiesCount(id, generated, manual = null) {
    if (process.env.NODE_ENV !== 'development') {
        return;
    }

    const _generated = generated ? countProperties(generated) : 0;
    const _manual = manual ? countProperties(manual) : 0;

    generatedPropertiesCountById[id] = (generatedPropertiesCountById[id] || 0) + _generated;
    totalGeneratedPropertiesCount += _generated;

    manualCountById[id] = (manualCountById[id] || 0) + _manual;
    totalManualCount += _manual;
}

export function printAutomationStatistics(fullConfig) {
    const totalPropertiesCount = countProperties(fullConfig);
    const keys = Object.keys(manualCountById);
    const table = {};
    keys.forEach(id => {
        const manual = manualCountById[id];
        const generated = generatedPropertiesCountById[id];
        table[id] = {
            'Generated props': generated,
            'Custom props': manual,
        };
    });

    log.groupCollapsed(
        `\n%cGenerated visual: ${totalGeneratedPropertiesCount}` +
            `\nMojito custom properties: ${totalManualCount}` +
            `\nTotal properties passed to mojito: ${totalPropertiesCount}.`,
        Logger.STYLE_DEBUG
    );
    log.table(table);
    log.groupEnd();

    const result = {
        client: CLIENT_NAME,
        gitHash: GIT_HASH,
        timestamp: Date.now(),
        totalProperties: totalPropertiesCount,
        totalGenerated: totalGeneratedPropertiesCount,
        totalManual: totalManualCount,
        details: keys.map(factoryId => {
            return {
                id: factoryId,
                generated: generatedPropertiesCountById[factoryId],
                manual: manualCountById[factoryId],
            };
        }),
    };
    return result;
}
