import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import VirtualSportPageViewImpl from './virtual-sport-page-view.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View that layouts the content of the VirtualSportPage.
 *
 * @class VirtualSportPageView
 * @memberof Mojito.Application.Pages.VirtualSportPage
 */

/**
 * VirtualSportPageView prop types.
 *
 * @property {string} [sportId] - The virtual sport id to display.
 * @property {string} [classId] - The virtual sport class id to display. For some integrations (e.g. Quantum) it can be the same as sport id.
 * @property {string} [selectedEventId] - Event id that should be selected.
 * @property {object} [sportInfo] - Object contains localized data for current virtual sport.
 *
 * @memberof Mojito.Application.Pages.VirtualSportPage.VirtualSportPageView
 */

const propTypes = {
    sportId: PropTypes.string,
    classId: PropTypes.string,
    selectedEventId: PropTypes.string,
    sportInfo: PropTypes.object,
};

export default UIView('VirtualSportPageView', VirtualSportPageViewImpl, propTypes);
