import GenericBetFundsInfo from 'presentation/components/bet-funds/generic-info/index.jsx';
import CurrencyHelper from 'presentation/formatters/currency/index.js';
import { isNumber } from 'mojito/utils';

const PayableInfo = ({ value, previousValue, currencyCode, isTotal, hasDivider, mojitoTools }) => {
    const { stringResolver, config } = mojitoTools;

    if (!isNumber(value)) {
        return null;
    }

    const label = isTotal
        ? stringResolver.resolveString('$BET_FUNDS.TOTAL_PAYABLE')
        : stringResolver.resolveString('$BET_FUNDS.PAYABLE');

    const labelWithDivider = `${label}${stringResolver.resolveString('$BET_FUNDS.DIVIDER')}`;
    const previous =
        previousValue > 0 ? CurrencyHelper.formatCurrency(previousValue, currencyCode) : '';
    return (
        <GenericBetFundsInfo
            class="ta-Payable"
            config={config.betInfo}
            label={hasDivider ? labelWithDivider : label}
            valueClass={isTotal ? 'ta-TotalPayableValue' : 'ta-PayableValue'}
            value={
                isNaN(value)
                    ? stringResolver.resolveString(config.notAvailableValuel10nKey)
                    : CurrencyHelper.formatCurrency(value, currencyCode)
            }
            previousValue={previous}
        />
    );
};

export default PayableInfo;
