import {PALETTE_IDS} from 'core/application/modules/ids';
import {COLOR} from '../theme';

export function getBonusesConfig() {
    const CUSTOM_CONFIG = {
        Bonuses: {
            _mobile_showTabSwitcher: true,
        },
    };

    return {
        custom: {
            [PALETTE_IDS.BONUSES]: CUSTOM_CONFIG,
        },
        palette: {
            [PALETTE_IDS.BONUSES]: {
                BONUSES_SECTION_TITLE_TEXT: COLOR.BRAND.FOREGROUND1,
                BONUS_ITEM_TITLE_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    textTransform: 'uppercase',
                },
            },
        },
    };
}
