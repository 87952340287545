import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Application selectors.
 *
 * @module ApplicationSelectors
 * @name selectors
 * @memberof Mojito.Application
 */

/**
 * Selects application state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Application.ApplicationState} Application state.
 * @memberof Mojito.Application.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Returns the current active sliding view that should be shown.
 *
 * @function selectActiveSlidingViewType
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Application.AppView.SLIDING_VIEW_TYPES|undefined} The current sliding view type or undefined if nothing is active.
 * @memberof Mojito.Application.selectors
 */
export const selectActiveSlidingViewType = state => selectState(state).activeSlidingViewType;

/**
 * Returns the current active sliding data that can be used for particular view.
 *
 * @function selectActiveSlidingViewData
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Sliding view data object.
 * @memberof Mojito.Application.selectors
 */
export const selectActiveSlidingViewData = state => selectState(state).activeSlidingViewData;

/**
 * Returns if Mojito has been initialized or not.
 *
 * @function selectIsInitialized
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} If Mojito has been initialized or not.
 * @memberof Mojito.Application.selectors
 */
export const selectIsInitialized = state => selectState(state).isInitialized;

/**
 * Subscribes to the changes of particular application state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Application.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
