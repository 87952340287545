import { configRegistry } from 'core/config/config-registry.js';
import { configFactory } from 'core/config/config-factory.js';
import currenciesDefaultConfig from 'core/generated/configs/_currencies.js';
configRegistry.add(currenciesDefaultConfig);

/**
 * A class that provides configuration for currencies.
 *
 * @class CurrencyConfig
 * @memberof Mojito.Core.Services
 */
export default class CurrencyConfig {
    /**
     * Get config object for Currencies.
     *
     * @returns {object} Config object for Currencies. Will default to empty object if no such config exists.
     */
    static getCurrencies() {
        return configFactory.getConfig('Currencies') || {};
    }
}
