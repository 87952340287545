import BetradarMatchTrackerTypes from 'presentation/components/betradar/types.js';

const SIR_API = 'sirApi';
const BETRADAR_WIDGET_LOADER_SCRIPT_ID = 'BETRADAR_WIDGET_LOADER_SCRIPT';

const { BETRADAR_API_METHODS } = BetradarMatchTrackerTypes;
const { UPDATE_WIDGET, ADD_WIDGET } = BETRADAR_API_METHODS;
const ASYNC_METHODS = [UPDATE_WIDGET, ADD_WIDGET];

/**
 * BetradarWidgetLoader handles the everything related to the Sportradar SIR API. It fetches the resource and exposes the API for components to use.
 *
 * @class BetradarWidgetLoader
 * @name widgetLoader
 * @memberof Mojito.Presentation.Components.BetradarMatchTracker
 */
class BetradarWidgetLoader {
    /**
     * Fetches the Sportradar SIR API.
     *
     * @param {string} widgetLoaderUrl - The URL to the SIR widget loader. The client ID must be included in the URL, formatted as: https://widgets.sir.sportradar.com/<clientId>/widgetloader.
     * @param {object} sirParameters - An object of parameters for the widget loader. The available options can be found at https://widgets.sir.sportradar.com/docs/SIR.html.
     *
     * @returns {Promise} A Promise that resolves with no value upon successful completion, and rejects with an error in case of any failures.
     *
     * @function Mojito.Presentation.Components.BetradarMatchTracker.widgetLoader#init
     */
    static init(widgetLoaderUrl, sirParameters) {
        return new Promise((resolve, reject) => {
            if (window[SIR_API]) {
                resolve();
                return;
            }
            if (!window.SIR && !this.widgetLoaderElementExists()) {
                // prettier-ignore
                (function (a, b, c, d, e, f, g, h, i) { // NOSONAR
                    a[e] || // NOSONAR
                        ((i = a[e] = function () {
                            // eslint-disable-next-line prefer-rest-params
                            (a[e].q = a[e].q || []).push(arguments); // NOSONAR
                        }), // NOSONAR
                        (i.l = 1 * new Date()),
                        (i.o = f),
                        (g = b.createElement(c)),
                        (h = b.getElementsByTagName(c)[0]),
                        (g.async = 1),
                        (g.src = d),
                        g.setAttribute('n', e),
                        g.setAttribute('id', BETRADAR_WIDGET_LOADER_SCRIPT_ID),
                        (g.onload = () => resolve()),
                        (g.onerror = error => reject(error)),
                        h.parentNode.insertBefore(g, h));
                })(
                    window,
                    document,
                    'script',
                    widgetLoaderUrl,
                    SIR_API,
                    sirParameters
                );
            }
        });
    }

    /**
     * Call the Sportradar SIR API with the provided arguments.
     *
     * @param {any} args - The arguments provided to the SIR API. Make sure to not include the "callback opt".
     *
     * @returns {Promise} Returns a Promise which resolves no value on success and rejects with an error if it fails.
     *
     * @function Mojito.Presentation.Components.BetradarMatchTracker.widgetLoader#betradarApi
     */
    static betradarApi(...args) {
        return new Promise((resolve, reject) => {
            try {
                const isAsync = ASYNC_METHODS.includes(args[0]);
                if (isAsync) {
                    window[SIR_API](...args, resolve);
                } else {
                    window[SIR_API](...args);
                    resolve();
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Determines if the Sportradar SIR API is loaded.
     *
     * @returns {boolean} Returns true if the API is available, otherwise false.
     *
     * @function Mojito.Presentation.Components.BetradarMatchTracker.widgetLoader#isBetradarApiLoaded
     */
    static isBetradarApiLoaded() {
        return !!window[SIR_API];
    }

    /**
     * Destructs Sportradar SIR API.
     *
     * @function Mojito.Presentation.Components.BetradarMatchTracker.widgetLoader#destruct
     */
    static destruct() {
        const widgetLoader = this.getWidgetLoaderElement();
        widgetLoader && widgetLoader.remove();
        window[SIR_API] = undefined;
    }

    /**
     * Determines if the script tag used to fetch the Sportradar SIR API is present in the DOM.
     *
     * @returns {boolean} Returns true if the script tag is available, otherwise false.
     *
     * @private
     * @function Mojito.Presentation.Components.BetradarMatchTracker.widgetLoader#widgetLoaderElementExists
     */
    static widgetLoaderElementExists() {
        const el = this.getWidgetLoaderElement();
        return !!el;
    }

    /**
     * Returns the script tag used to fetch the Sportradar SIR API.
     *
     * @returns {any} Returns a DOM Element if the script tag is present in the DOM, otherwise undefined.
     *
     * @private
     * @function Mojito.Presentation.Components.BetradarMatchTracker.widgetLoader#getWidgetLoaderElement
     */
    static getWidgetLoaderElement() {
        return document.getElementById(BETRADAR_WIDGET_LOADER_SCRIPT_ID);
    }
}

export default BetradarWidgetLoader;
