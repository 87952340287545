import GenericBetFundsInfo from 'presentation/components/bet-funds/generic-info/index.jsx';
import CurrencyHelper from 'presentation/formatters/currency/index.js';
import { isNumber } from 'mojito/utils';

const StakeInfo = ({
    value,
    previousValue,
    isTotal,
    currencyCode,
    showFreeBetIcon,
    mojitoTools,
}) => {
    const { stringResolver, config } = mojitoTools;

    if (!isNumber(value)) {
        return null;
    }

    const label = isTotal
        ? stringResolver.resolveString('$BET_FUNDS.TOTAL_STAKE')
        : stringResolver.resolveString('$BET_FUNDS.STAKE');

    return (
        <GenericBetFundsInfo
            class="ta-Stake"
            config={config.betInfo}
            label={label}
            showIcon={showFreeBetIcon}
            valueClass={isTotal ? 'ta-TotalStakeValue' : 'ta-StakeValue'}
            value={
                isNaN(value)
                    ? stringResolver.resolveString(config.notAvailableValuel10nKey)
                    : CurrencyHelper.formatCurrency(value, currencyCode)
            }
            previousValue={
                previousValue ? CurrencyHelper.formatCurrency(previousValue, currencyCode) : ''
            }
        />
    );
};

export default StakeInfo;
