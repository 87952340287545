import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import RaceMeetingsPageImpl from './race-meetings-page.jsx';
const UIPage = MojitoCore.Presentation.UIPage;
const { DAY } = MojitoPresentation.Utils.Filters;

/**
 * @namespace RaceMeetingsPage
 * @memberof Mojito.Application.Pages
 */

/**
 * Page that displays meetings page for a specific racing sport.
 *
 * @class RaceMeetingsPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Application.Pages.RaceMeetingsPage
 */

/**
 * RaceMeetingsPage prop types.
 *
 * @property {Mojito.Presentation.Utils.Filters.DAY} [filter] - Filters events to only display those occurring today or tomorrow.
 *
 * @memberof Mojito.Application.Pages.RaceMeetingsPage
 */
const propTypes = {
    filter: PropTypes.oneOf(Object.values(DAY)),
};

export default UIPage('RaceMeetingsPage', RaceMeetingsPageImpl, propTypes);
