import MojitoCore from 'mojito/core';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ERROR_CODE, STREAM_STATE } from './types.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import serviceFactory from './service/service-factory.js';

const TransactionsTypes = MojitoCore.Services.Transactions.types;
const { INVALID_SESSION } = TransactionsTypes.ERROR_CODE;
const reduxInstance = MojitoCore.Services.redux;

/**
 * Defines the state of the Video stream store.
 *
 * @typedef VideoStreamingState
 *
 * @property {Mojito.Services.VideoStreaming.types.VideoStreamingData} streamData - Stream data object.
 * @property {Mojito.Services.VideoStreaming.types.STREAM_STATE} StreamState - Current stream state.
 *
 * @memberof Mojito.Services.VideoStreaming
 */

/**
 * The name of the Video stream state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.VideoStreaming
 */
export const STORE_KEY = 'VideoStreamingStore';

/**
 * Video stream initial state.
 *
 * @typedef INITIAL_STATE
 * @memberof Mojito.Services.VideoStreaming
 */
export const INITIAL_STATE = {
    streamData: undefined,
    streamState: STREAM_STATE.UNAVAILABLE,
};

export const { reducer, actions } = createSlice({
    name: 'VideoStream',
    initialState: INITIAL_STATE,
    reducers: {
        startWatch(state) {
            state.streamState = STREAM_STATE.PLAYING;
        },
        stopWatch(state) {
            state.streamState = STREAM_STATE.STOPPED;
        },
        endStream(state) {
            state.streamState = STREAM_STATE.ENDED;
            state.streamData = undefined;
        },
        requestStreamingDataPending(state) {
            state.streamState = STREAM_STATE.LOADING;
            state.streamData = undefined;
        },
        requestStreamingDataSuccess(state, { payload: streamData }) {
            state.streamState = STREAM_STATE.READY;
            state.streamData = streamData;
        },
        requestStreamingDataError(state, { payload: streamData }) {
            state.streamState = STREAM_STATE.UNAVAILABLE;
            state.streamData = streamData;
        },
        reset() {
            return INITIAL_STATE;
        },
        extraReducers: builder => {
            builder.addMatcher(
                isAnyOf(authenticationActions.disposeSession, bootstrapActions.dispose),
                () => INITIAL_STATE
            );
        },
    },
});

/**
 * Video streaming related actions.
 *
 * @module VideoStreamingActions
 * @name actions
 * @memberof Mojito.Services.VideoStreaming
 */

/**
 * Request streaming data thunk.
 *
 * @function requestStreamingData
 *
 * @param {Mojito.Services.VideoStreaming.types.VideoStreamingRequest} streamSpec - Stream spec request.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Request stream thunk. Dispatches loginSuccess or loginFailed actions once login process is finalised.
 * @memberof Mojito.Services.VideoStreaming.actions
 */
actions.requestStreamingData = streamSpec => {
    return dispatch => {
        const { provider, eventId } = streamSpec;
        const enrichResponse = (data = {}) => ({ provider, eventId, errors: [], ...data });
        dispatch(actions.requestStreamingDataPending(streamSpec));
        return serviceFactory
            .getService()
            .getStreaming(streamSpec)
            .then(data => {
                dispatch(actions.requestStreamingDataSuccess(enrichResponse(data)));
            })
            .catch(err => {
                const code =
                    err && err.type === INVALID_SESSION
                        ? ERROR_CODE.USER_NOT_LOGGED_IN
                        : ERROR_CODE.UNKNOWN;
                const faultyStream = enrichResponse({ errors: [{ code }] });
                dispatch(actions.requestStreamingDataError(faultyStream));
            });
    };
};

/**
 * Set appropriate stream state on start watching stream.
 *
 * @function startWatch
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.VideoStreaming.actions
 */

/**
 * Set appropriate stream state on stop watching stream.
 *
 * @function stopWatch
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.VideoStreaming.actions
 */

/**
 * Set appropriate stream state and data on end stream.
 *
 * @function endStream
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.VideoStreaming.actions
 */

/**
 * Request streaming data is pending.
 *
 * @function requestStreamingDataPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.VideoStreaming.types.VideoStreamingRequest} streamSpec - Stream specification object.
 *
 * @memberof Mojito.Services.VideoStreaming.actions
 */

/**
 * Set requested streaming data on successful load.
 *
 * @function requestStreamingDataSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.VideoStreaming.types.VideoStreamingData} streamData - Stream data object.
 *
 * @memberof Mojito.Services.VideoStreaming.actions
 */

/**
 * Set requested streaming data on failed load.
 *
 * @function requestStreamingDataError
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {Mojito.Services.VideoStreaming.types.VideoStreamingData} streamData - Stream data object.
 *
 * @memberof Mojito.Services.VideoStreaming.actions
 */

/**
 * Reset video stream state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.VideoStreaming.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
