import ApiRequestProvider from 'services/common/api-request/request-provider.js';
import { actions as pdfActions } from './slice.js';
import PdfUtils from './utils';
import MojitoCore from 'mojito/core';

const { dispatch } = MojitoCore.Services.redux.store;
const PDF_API_PATH = '/pdf/generate';
const log = MojitoCore.logger.get('PdfService');

/**
 * PDF Service.
 *
 * @class PdfService
 * @name pdfService
 * @memberof Mojito.Services.Pdf
 */
class PdfService {
    init(pdfServiceUrl) {
        this.pdfServiceBaseUrl = pdfServiceUrl;
        this.requestFactory = new ApiRequestProvider(log);
    }

    /**
     * Downloads a betting receipt.
     *
     * @param {*} bets - The bets data.
     * @param {*} labels - The labels to be included in the receipt.
     * @param {*} filename - The filename for the downloaded receipt.
     *
     * @function Mojito.Services.Pdf.pdfService#downloadBetReceipt
     */
    downloadBetReceipt(bets, labels, filename) {
        if (!this.pdfServiceBaseUrl) {
            log.error('No pdf service configured!');
            return;
        }

        const pdfRequest = PdfUtils.buildPdfRequest(bets, labels);

        dispatch(pdfActions.updateError(false));
        dispatch(pdfActions.updateIsFetching(true));

        this.requestFactory
            .post(this.pdfServiceBaseUrl + PDF_API_PATH)
            .withCredentials()
            .withRawBodyResponse()
            .send(pdfRequest)
            .then(res => {
                PdfUtils.writePdfToFile(res, filename);
                dispatch(pdfActions.updateIsFetching(false));
            })
            .catch(() => {
                dispatch(pdfActions.updateError(true));
                dispatch(pdfActions.updateIsFetching(false));
            });
    }
}

export default new PdfService();
