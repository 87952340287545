import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useRequest } from 'modules/common/hooks/index.js';

const { createInitialisationDescriptor } = MojitoServices.SystemInformation.descriptor;
const { FlexPane, Text } = MojitoPresentation.Components;
const { selectState } = MojitoServices.SystemInformation.selectors;

export const useSystemInformationRequest = () => {
    const descriptors = useMemo(() => [createInitialisationDescriptor()], []);
    useRequest(descriptors);
};

export default function SystemInitialisation({ mojitoTools }) {
    useSystemInformationRequest();
    const { config, stringResolver } = mojitoTools;
    const { secondsRemaining, complete = true } = useSelector(selectState);
    if (complete) {
        return null;
    }
    const minutes = Math.ceil(secondsRemaining / 60);
    const remainingTime = stringResolver.resolveAndFormatString(
        '$SYSTEM_INITIALISATION.REMAINING_TIME',
        minutes
    );

    return (
        <FlexPane config={config.container} class={'ta-SystemInitialisation'}>
            <Text config={config.title}>
                {stringResolver.resolveString('$SYSTEM_INITIALISATION.TITLE')}
            </Text>
            <Text config={config.timeRemaining}>{remainingTime}</Text>
        </FlexPane>
    );
}
