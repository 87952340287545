import AuthenticationTypes from 'services/authentication/types.js';
const { CREDENTIALS_PRIVATE_TYPE } = AuthenticationTypes;

/**
 * @typedef credentials
 * @type {object}
 * @property {Mojito.Services.Authentication.credentials.CredentialsBuilder} CredentialsBuilder - Credentials builder.
 * @property {Mojito.Services.Authentication.credentials.TokenCredentialsBuilder} TokenCredentialsBuilder - Token credentials builder.
 *
 * @memberof Mojito.Services.Authentication
 */

/**
 * Class used to build {@link Mojito.Services.Authentication.types.AuthCredentials|AuthCredentials} object.
 *
 * @class CredentialsBuilder
 * @memberof Mojito.Services.Authentication.credentials
 */
export class CredentialsBuilder {
    /**
     * Assign credentials public type value.
     *
     * @param {Mojito.Services.Authentication.types.CREDENTIALS_PUBLIC_TYPE} value - Type of the public credential factor.
     * @returns {Mojito.Services.Authentication.credentials.CredentialsBuilder} Credentials builder instance.
     *
     * @function Mojito.Services.Authentication.credentials.CredentialsBuilder#withPublicType
     */
    withPublicType(value) {
        this.publicType = value;
        return this;
    }

    /**
     * Assign credentials private type value.
     *
     * @param {Mojito.Services.Authentication.types.CREDENTIALS_PRIVATE_TYPE} value - Type of the private credential factor.
     * @returns {Mojito.Services.Authentication.credentials.CredentialsBuilder} Credentials builder instance.
     *
     * @function Mojito.Services.Authentication.credentials.CredentialsBuilder#withPrivateType
     */
    withPrivateType(value) {
        this.privateType = value;
        return this;
    }

    /**
     * Assign public factor value.
     *
     * @param {string} value - Value of the public credential factor, e.g., user name or pin number.
     * @returns {Mojito.Services.Authentication.credentials.CredentialsBuilder} Credentials builder instance.
     *
     * @function Mojito.Services.Authentication.credentials.CredentialsBuilder#withPublicFactor
     */
    withPublicFactor(value) {
        this.publicFactor = value;
        return this;
    }

    /**
     * Assign private factor value.
     *
     * @param {string} value - Value of the private credential factor, e.g., password or authentication token.
     * @returns {Mojito.Services.Authentication.credentials.CredentialsBuilder} Credentials builder instance.
     *
     * @function Mojito.Services.Authentication.credentials.CredentialsBuilder#withPrivateFactor
     */
    withPrivateFactor(value) {
        this.privateFactor = value;
        return this;
    }

    /**
     * Builds the instance of {@link Mojito.Services.Authentication.types.AuthCredentials|AuthCredentials}.
     *
     * @returns {Mojito.Services.Authentication.types.AuthCredentials} Authentication credentials instance.
     *
     * @function Mojito.Services.Authentication.credentials.CredentialsBuilder#build
     */
    build() {
        return {
            publicType: this.publicType,
            privateType: this.privateType,
            data: {
                publicFactor: this.publicFactor,
                privateFactor: this.privateFactor,
            },
        };
    }
}

/**
 * Class used to build {@link Mojito.Services.Authentication.types.AuthCredentials|AuthCredentials} object
 * with predefined <code>privateType</code> property equals to {@link Mojito.Services.Authentication.types.CREDENTIALS_PRIVATE_TYPE.TOKEN|TOKEN}.
 *
 * @class TokenCredentialsBuilder
 * @memberof Mojito.Services.Authentication.credentials
 */
export class TokenCredentialsBuilder extends CredentialsBuilder {
    constructor() {
        super();
        this.withPrivateType(CREDENTIALS_PRIVATE_TYPE.TOKEN);
    }

    /**
     * Assign authentication token.
     *
     * @param {string} value - Authentication token.
     * @returns {Mojito.Services.Authentication.credentials.CredentialsBuilder} Credentials builder instance.
     *
     * @function Mojito.Services.Authentication.credentials.TokenCredentialsBuilder#withToken
     */
    withToken(value) {
        return super.withPrivateFactor(value);
    }
}
