import MojitoCore from 'mojito/core';
import {WidgetsProviderService} from 'core/application/widgets-provider';
import {getAllParamsFromURL} from 'core/utils/url-utils';

const {UIViewImplementation} = MojitoCore.Presentation;

export default class AbstractWidget extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.isFullscreenWidget = WidgetsProviderService.isFullscreenWidget();
        this.params = this.isFullscreenWidget ? getAllParamsFromURL() : (this.params = props);
    }
}
