import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import SettingsMenu from 'modules/settings-menu/index.jsx';

const { Popup, Button, Image } = MojitoPresentation.Components;

export default class SettingsMenuPopup extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.state = {
            isSettingsMenuPopupOpen: false,
        };

        this.onSettingsButtonClicked = this.onSettingsButtonClicked.bind(this);
    }

    onSettingsButtonClicked() {
        this.setState(state => ({
            isSettingsMenuPopupOpen: !state.isSettingsMenuPopupOpen,
        }));
    }

    render() {
        const { isSettingsMenuPopupOpen } = this.state;
        return (
            <Button
                config={this.config.button}
                onClick={this.onSettingsButtonClicked}
                active={isSettingsMenuPopupOpen}
                class="ta-settingsButton"
            >
                <Image
                    config={this.config.icon}
                    fill={this.config.iconColor[isSettingsMenuPopupOpen ? 'open' : 'closed']}
                />
                {isSettingsMenuPopupOpen && (
                    <Popup
                        visible={isSettingsMenuPopupOpen}
                        config={this.config.popup}
                        class="ta-settingsMenu"
                    >
                        <SettingsMenu />
                    </Popup>
                )}
            </Button>
        );
    }
}
