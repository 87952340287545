import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Keypad module store selectors.
 *
 * @module Keypad
 * @name selectors
 * @memberof Mojito.Modules.Keypad
 */

/**
 * Selects search state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Search.types.SearchState} Search state.
 * @memberof Mojito.Services.Search.selectors
 */
export const selectState = state => {
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects active input id.
 *
 * @function selectActiveInputId
 *
 * @param {object} [state] - Application state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {number|undefined} Selected active input id or undefined.
 * @memberof Mojito.Modules.Keypad.selectors
 */
export const selectActiveInputId = state => selectState(state).activeInput;

/**
 * Selects is active input state.
 *
 * @function selectIsActive
 *
 * @param {object} [state] - Application state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if activeInput is not undefined.
 * @memberof Mojito.Modules.Keypad.selectors
 */
export const selectIsActive = state => !!selectState(state).activeInput;

/**
 * Selects isHidden property.
 *
 * @function selectIsHidden
 *
 * @param {object} [state] - Application state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} Flag isHidden true/false for open/closed keyboard detection.
 * @memberof Mojito.Modules.Keypad.selectors
 */
export const selectIsHidden = state => selectState(state).isHidden;

/**
 * Subscribes to the changes of particular keypad state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Modules.Keypad.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
