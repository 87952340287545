import MojitoServices from 'mojito/services';
import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {DummyBalanceService} from './dummy-balance-service';
import {ensureHierarchy} from 'core/utils/config-utils';
import SCHEMA from './balance.schema.yaml';

const balanceService = MojitoServices.UserInfo.Balance.service;

class BalanceFeature extends AbstractFeature {
    get name() {
        return 'Balance';
    }

    get configSchema() {
        return SCHEMA;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const config = this.config;

        // Push default balance service that doing roundtrip client<->user-service<->integration-service<->ims
        // Only if pollingInterval > 0
        if (config.pollingInterval !== 0) {
            // services.userInfoService.balanceService
            const userInfoService = ensureHierarchy(mojitoConfig, 'services.userInfoService');
            userInfoService.balanceService = balanceService.configure({
                balanceUpdateIntervalSeconds: config.pollingInterval,
            });
        }

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const userInfoService = ensureHierarchy(mojitoConfig, 'services.userInfoService');
        if (!userInfoService.balanceService) {
            // Push something if no balance services are provided (prevent mojito crashing)
            // Note: Balance could be updated via SportsbookAPI
            userInfoService.balanceService = new DummyBalanceService();
        }
        super.afterMojitoConfigBuild(mojitoConfig);
    }
}

export const Balance = new BalanceFeature(allFeatures);
