import React from 'react';
import {Helmet} from 'react-helmet';
import MojitoCore from 'mojito/core';
import {actions as metaDescriptionActions} from './store/slice.js';
import {absoluteUrlToInternalResource, stripRoutingPrefix} from 'core/utils/url-utils';
import {SEO} from './index.js';

import documentHeadDefaultConfig from 'mojito/core/generated/configs/_document-head.js';

const {dispatch} = MojitoCore.Services.redux.store;

MojitoCore.Services.Config.configRegistry.add(documentHeadDefaultConfig);

export default class DocumentHeadView extends MojitoCore.Presentation.UIViewImplementation {
    getMetaData(metaFactory, data) {
        if (metaFactory.setParameters) {
            // This factory is derived from DBX BaseMetaFactory
            // temp workaround for MOJ-11146
            metaFactory.setParameters({
                data,
                routeResolver: this.appContext().routeResolver,
            });
        }

        const factoryTitle = data && data.title && metaFactory.getTitle(data);
        const title = factoryTitle || this.resolveString('$DOCUMENT_HEAD.PAGE_TITLE');

        const factoryDescription = data && data.description && metaFactory.getDescription(data);
        const description = factoryDescription || this.resolveString('$DOCUMENT_HEAD.PAGE_DESCRIPTION');

        const factoryKeywords = data && data.keywords && metaFactory.getKeywords(data);
        const keywords = factoryKeywords || this.resolveString('$DOCUMENT_HEAD.PAGE_KEYWORDS');

        const relativeCanonical =
            (data && metaFactory.getRelativeCanonical && metaFactory.getRelativeCanonical(data)) ||
            stripRoutingPrefix(window.location.pathname);

        let robotsRules = SEO.robotsMetaTags.all ? SEO.robotsMetaTags.all : [];
        if (data && metaFactory.getRobots) {
            robotsRules = robotsRules.concat(metaFactory.getRobots(data));
        }
        const robots = robotsRules.length > 0 ? robotsRules.join(', ') : undefined;
        const additionalMetaTags = (metaFactory.getAdditionalMetaTags && metaFactory.getAdditionalMetaTags(data)) || {};

        const og = {
            title,
            description,
            relativeUrl: relativeCanonical,
            // TODO: also handle og:locale tag
            images: SEO.opengraphImages.map(img => {
                return {
                    absoluteUrl: absoluteUrlToInternalResource(img.url),
                    width: img.width,
                    height: img.height,
                };
            }),
        };
        return {title, description, keywords, relativeCanonical, og, robots, additionalMetaTags};
    }

    componentDidMount() {
        if (Sportsbook.isEmbedded) {
            const {metaFactory, data} = this.props;
            const meta = this.getMetaData(metaFactory, data);

            dispatch(metaDescriptionActions.setMetaDescription(meta));
        }
    }

    componentDidUpdate(prevProps) {
        if (Sportsbook.isEmbedded) {
            const prevMeta = this.getMetaData(prevProps.metaFactory, prevProps.data);
            const meta = this.getMetaData(this.props.metaFactory, this.props.data);

            if (
                prevMeta.title !== meta.title ||
                prevMeta.description !== meta.description ||
                prevMeta.keywords !== meta.keywords
            ) {
                dispatch(metaDescriptionActions.setMetaDescription(meta));
            }
        }
    }

    render() {
        const {metaFactory, data} = this.props;
        const metaData = this.getMetaData(metaFactory, data);

        if (!this.config.enable || Sportsbook.isEmbedded) {
            return null;
        }

        let routingPrefix = Sportsbook._routingPrefix;
        routingPrefix = routingPrefix === '/' ? '' : routingPrefix;

        const metaTags = [];
        metaData.description && metaTags.push({name: 'description', content: metaData.description});
        metaData.keywords && metaTags.push({name: 'keywords', content: metaData.keywords});
        metaData.robots && metaTags.push({name: 'robots', content: metaData.robots});
        if (metaData.og) {
            const og = metaData.og;
            metaTags.push({property: 'og:type', content: 'website'});
            og.title && metaTags.push({property: 'og:title', content: og.title});
            og.description && metaTags.push({property: 'og:description', content: og.description});
            og.relativeUrl &&
                metaTags.push({
                    property: 'og:url',
                    content: new URL(routingPrefix + og.relativeUrl, window.location.origin).toString(),
                });

            if (og.images) {
                og.images.forEach(image => {
                    if (!image.absoluteUrl) return;
                    metaTags.push({property: 'og:image:url', content: image.absoluteUrl});
                    image.width && metaTags.push({property: 'og:image:width', content: image.width});
                    image.height && metaTags.push({property: 'og:image:height', content: image.height});
                });
            }
        }
        if (metaData.additionalMetaTags) {
            for (const [key, value] of Object.entries(metaData.additionalMetaTags)) {
                metaTags.push({name: key, content: value});
            }
        }

        return (
            <Helmet
                meta={metaTags}
                link={[
                    metaData.relativeCanonical
                        ? {
                              rel: 'canonical',
                              href: new URL(
                                  routingPrefix + metaData.relativeCanonical,
                                  window.location.origin
                              ).toString(),
                          }
                        : {},
                ]}
                title={metaData.title}
            />
        );
    }
}
