import { useEffect, useLayoutEffect, useMemo, useRef, useState, useCallback } from 'react';
import { noop, debounce, throttle } from 'mojito/utils';
import { useArrayComparator } from 'presentation/hooks';
import SwiperUtils from 'presentation/components/swiper/utils.js';

/**
 * Contains custom hooks for swiper component.
 *
 * @class Hooks
 * @name swiperHooks
 * @memberof Mojito.Presentation.Components
 */

/**
 * Creates swiper loop config object that will be used to organize slides loop.
 * Contains bufferLength, isMultiSlideView and centerViewableSlide properties.
 *
 * @function
 * @param {Mojito.Core.Services.Config.ConfigObject} config - Swiper config object.
 * @param {HTMLElement} container - Swiper container element.
 * @param {Array} children - Swiper children list.
 *
 * @returns {object} Swiper loop config.
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useLoopConfig = (config, container, children = []) => {
    return useMemo(
        () =>
            config.loop && SwiperUtils.isScrollable(container)
                ? {
                      bufferLength: SwiperUtils.getLoopBufferLength(container),
                      isMultiSlideView: SwiperUtils.isMultiSlideView(container),
                      centerViewableSlide: config.centerViewableSlide,
                  }
                : undefined,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [config.loop, config.centerViewableSlide, children.length, container]
    );
};

/**
 * Scrolls looped swiper container to the first slide.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 * @param {object} loopConfig - Loop config object.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useLoopStart = (container, loopConfig) => {
    // On loop init we will scroll to the first origin slide, this is needed because we have loop buffer from left and right of our children.
    useLayoutEffect(() => {
        SwiperUtils.scrollToSlide(container, 0, loopConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container]);
};

/**
 * Starts automatic slides swiping with provided config.autoPlayInterval and config.scrollBehavior.
 * If config.autoPlay is set to false then autoplay will not start.
 *
 * @function
 * @param {Mojito.Core.Services.Config.ConfigObject} config - Swiper config object.
 * @param {HTMLElement} container - Swiper container element.
 * @param {object} loopConfig - Swiper loop config.
 *
 * @returns {Array} Array of callbacks. First element in array is autoPlayStart function and second is autoPlayStop.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useAutoPlay = (config, container, loopConfig) => {
    const intervalRef = useRef();
    const { autoPlay, autoPlayInterval, scrollBehavior } = config;

    const containerWidth = SwiperUtils.getElementWidth(container);

    const autoPlayStart = useCallback(() => {
        clearInterval(intervalRef.current);
        intervalRef.current = autoPlay
            ? setInterval(() => {
                  const nextSlideIndex = SwiperUtils.getNextVisibleChildIndex(container);
                  SwiperUtils.scrollToChild(container, nextSlideIndex, loopConfig, scrollBehavior);
              }, autoPlayInterval)
            : undefined;
        // We need containerWidth as dep here to react on resize which affects getNextVisibleChildIndex and scrollToChild evaluations.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoPlay, autoPlayInterval, loopConfig, container, containerWidth, scrollBehavior]);

    const autoPlayStop = useCallback(() => clearInterval(intervalRef.current), []);

    useEffect(() => {
        autoPlayStart();
        return () => autoPlayStop();
    }, [autoPlayStart, autoPlayStop]);

    return [autoPlayStart, autoPlayStop];
};

/**
 * Listens to the pointer events and returns boolean indicating if pointer hovers the container.
 * This hook can be useful to detect when user is hovering the swiper to stop autoplay and to show navigation buttons.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 *
 * @returns {boolean} True if pointer is hovering container.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const usePointerHover = container => {
    const [cursorHover, setCursorHover] = useState(false);
    useEffect(() => {
        const onMouseEnter = () => setCursorHover(true);
        const onMouseLeave = () => setCursorHover(false);
        const enterEvents = ['touchstart', 'mouseenter'];
        const leaveEvents = ['touchend', 'mouseleave'];

        enterEvents.forEach(eventName => container?.addEventListener(eventName, onMouseEnter));
        leaveEvents.forEach(eventName => container?.addEventListener(eventName, onMouseLeave));
        return () => {
            enterEvents.forEach(eventName =>
                container?.removeEventListener(eventName, onMouseEnter)
            );
            leaveEvents.forEach(eventName =>
                container?.removeEventListener(eventName, onMouseLeave)
            );
        };
    }, [container]);
    return cursorHover;
};

/**
 * Blocks wheel event on container.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useWheelDisabled = container => {
    useEffect(() => {
        const onWheel = e => e.preventDefault();
        container?.addEventListener('wheel', onWheel);
        return () => container?.removeEventListener('wheel', onWheel);
    }, [container]);
};

/**
 * Listens to the touchmove events and triggers callback.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 * @param {Function} onTouchMove - Callback triggered on touchmove event.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useTouchMove = (container, onTouchMove) => {
    useEffect(() => {
        container?.addEventListener('touchmove', onTouchMove);
        return () => container?.removeEventListener('touchmove', onTouchMove);
    }, [onTouchMove, container]);
};

/**
 * Calls onScroll callback once scroll event triggered on container and onScrollEnd once scrolling is done.
 * Note: Safari doesn't support scrollend event, that is why we mimic by calling onScrollEnd in 100ms after the lastest scroll event fired.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 * @param {Function} [onScroll] - Callback on scroll event.
 * @param {Function} [onScrollEnd] -  Callback on scroll end event.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useScroll = (container, onScroll, onScrollEnd) => {
    // We can't use scrollend event due to lack of support in Safari and due to a fact that it is not triggered it after programmatic scroll either which happens during loop restore.
    // Instead, we will call onScrollEnd in 100ms after the latest scroll event fired, this will mimic scrollend event.
    const debouncedScrollEnd = useMemo(
        () => (onScrollEnd ? debounce(onScrollEnd, 100) : noop),
        [onScrollEnd]
    );
    const scrollHandler = useCallback(
        event => {
            onScroll && onScroll(event);
            debouncedScrollEnd(event);
        },
        [onScroll, debouncedScrollEnd]
    );
    useEventListener(container, 'scroll', scrollHandler);
};

/**
 * Detects if scrolling of provided container is in progress.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 *
 * @returns {Array} First item is boolean which indicates if scrolling is in progress, second is setScrolling callback.
 * To change scrolling boolean from outside the hook.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useScrollInProgress = container => {
    const [scrolling, setScrolling] = useState(false);
    const onScroll = useCallback(() => setScrolling(true), []);
    const onScrollEnd = useCallback(() => setScrolling(false), []);
    useScroll(container, onScroll, onScrollEnd);
    return [scrolling, setScrolling];
};

/**
 * Attaches event listener to container element. Calls onEvent callback once event is triggered.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 * @param {string} eventType - Event type to subscribe.
 * @param {Function} onEvent - Callback executed on event triggers.
 *
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useEventListener = (container, eventType, onEvent) => {
    const abortControllerRef = useRef(new AbortController());
    useEffect(() => {
        abortControllerRef.current.abort();
        if (container && onEvent) {
            abortControllerRef.current = new AbortController();
            container.addEventListener(eventType, onEvent, {
                signal: abortControllerRef.current.signal,
            });
        }
        return () => abortControllerRef.current.abort();
    }, [container, eventType, onEvent]);
};

/**
 * Resolves visible slide ids in provided swiper container.
 * The slide is considered to be visible if it is fully or partially viewable within container visible area.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 *
 * @returns {Array|undefined} List of visible slide ids.
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useVisibleSlides = container => {
    const [viewableSlideIds, setViewableSlideIds] = useState();
    const isVisibleSlidesChange = useArrayComparator();

    const containerWidth = container?.clientWidth;
    const resolveVisibleSlides = useCallback(() => {
        const visibleSlides = containerWidth > 0 ? SwiperUtils.getVisibleChildren(container) : [];
        const slideIds = visibleSlides.map(slide => SwiperUtils.parseSlideId(slide.id));
        isVisibleSlidesChange(slideIds) && setViewableSlideIds(slideIds);
    }, [container, containerWidth, isVisibleSlidesChange]);

    useScroll(container, resolveVisibleSlides);
    useEffect(resolveVisibleSlides, [container, resolveVisibleSlides]);

    return viewableSlideIds;
};

/**
 * Creates function wrapper that allows to call incoming argument function executor with ignoring
 * scroll snap behaviour on container. This can be useful to programmatically change container scroll position
 * avoiding glitches caused by scroll snap setup, for example, scrollSnapType: 'x mandatory'.
 * After scroll ends the scroll snap behaviour will be restored.
 *
 * @function
 * @param {HTMLElement} container - Swiper container element.
 * @param {Mojito.Core.Services.Config.ConfigObject} config - Swiper config object.
 *
 * @returns {Function} Ignore scroll snap function wrapper. Accepts function to be executed.
 * This function typically performs programmatic scroll position change on swiper container.
 * @memberof Mojito.Presentation.Components.swiperHooks
 */
export const useScrollSnapIgnore = (container, config) => {
    const scrollSnapDisabledRef = useRef(false);
    const { overflow } = config.style;
    const isManuallyScrollable = overflow !== 'hidden';

    const ignoreScrollSnap = useCallback(
        func => {
            if (isManuallyScrollable) {
                container.style.overflow = 'hidden';
                scrollSnapDisabledRef.current = true;
            }
            func();
        },
        [container, isManuallyScrollable]
    );

    const restoreOverflow = useCallback(() => {
        if (scrollSnapDisabledRef.current) {
            container.style.overflow = overflow;
            scrollSnapDisabledRef.current = false;
        }
    }, [container, overflow]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const restoreOverflowOnScroll = useCallback(throttle(restoreOverflow, 50), [restoreOverflow]);
    const onScroll = isManuallyScrollable ? restoreOverflowOnScroll : undefined;
    useScroll(container, onScroll);
    useEffect(() => () => restoreOverflowOnScroll.cancel(), [restoreOverflowOnScroll]);

    return ignoreScrollSnap;
};
