import QuickStartGuideImpl from './quick-start-guide.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Quick start guide module provides the possibility to interact with user guides.
 *
 * @function QuickStartGuide
 * @name module
 * @memberof Mojito.Modules.QuickStartGuides
 */

export default UIView('QuickStartGuide', QuickStartGuideImpl);
