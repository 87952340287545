import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import BetHistoryDetails from './view/index.jsx';
import MojitoPresentation from 'mojito/presentation';
import { useSelector, shallowEqual } from 'react-redux';

const { selectBets, selectIsFetching } = MojitoServices.Bets.betHistorySelectors;
const { selectError: selectPdfError, selectIsFetching: selectIsPdfFetching } =
    MojitoServices.Pdf.selectors;
const { Text, FlexPane, Spinner } = MojitoPresentation.Components;
const log = MojitoCore.logger.get('BetHistoryDetailsController');

export default function BetHistoryDetailsController({
    betId,
    onCloseClick,
    mojitoTools: { config, stringResolver },
}) {
    const pdfData = useSelector(
        state => ({
            isFetching: selectIsPdfFetching(state),
            hasError: selectPdfError(state),
        }),
        shallowEqual
    );
    const { bets, isFetchingBetHistory } = useSelector(
        state => ({
            bets: selectBets(state),
            isFetchingBetHistory: selectIsFetching(state),
        }),
        shallowEqual
    );
    if (isFetchingBetHistory) {
        return <FetchingBetHistory config={config} stringResolver={stringResolver} />;
    }

    const bet = bets.find(({ id }) => id === betId);
    if (!bet) {
        log.warn(`No bet found for bet id: ${betId}`);
        return null;
    }

    return (
        <BetHistoryDetails
            config={config.betHistoryDetails}
            bet={bet}
            pdfData={pdfData}
            onCloseClick={onCloseClick}
        />
    );
}

function FetchingBetHistory({ config, stringResolver }) {
    return (
        <FlexPane config={config.emptyContent}>
            <Text config={config.loadingLabel}>
                {stringResolver.resolveString('$BET_HISTORY.FETCHING_BET')}
            </Text>
            <Spinner config={config.spinner} />
        </FlexPane>
    );
}
