import PropTypes from 'prop-types';
import OverlayImpl from './overlay.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * An overlay view covering the entire window.
 * This view is designed to consume all user activities, such as clicks,
 * thereby preventing the user from interacting with underlying views.
 *
 * @class Overlay
 * @memberof Mojito.Presentation.Components
 */

/**
 * Overlay prop types.
 *
 * @property {boolean} [visible = true] - Component visibility flag. Changing it will trigger a transition animation.
 * @property {node} [children] - The content that should be displayed on the overlay, e.g., a dialog.
 * @property {Function} [onClicked = ()=>{}] - The click handler callback invoked when overlay is clicked.
 *
 * @memberof Mojito.Presentation.Components.Overlay
 */
const propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.node,
    onClicked: PropTypes.func,
};

const defaultProps = {
    visible: true,
    onClicked: noop,
};

export default UIView('Overlay', OverlayImpl, propTypes, defaultProps);
