/**
 * API transactions types.
 *
 * @class TransactionsTypes
 * @name types
 * @memberof Mojito.Core.Services.Transactions
 */
export default class TransactionsTypes {}

/**
 * Error codes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Transactions.types
 */
TransactionsTypes.ERROR_CODE = {
    /** Unknown error code. Used when there are no any specifics provided in error received from backend. */
    UNKNOWN: 'UNKNOWN',
    /** Invalid session error code. */
    INVALID_SESSION: 'INVALID_SESSION',
    /** Request has been aborted. */
    ABORTED: 'ABORTED',
    /** Transport protocol is not supported. */
    UNSUPPORTED_PROTOCOL: `UNSUPPORTED_PROTOCOL`,
};

/**
 * DOMException error names.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Transactions.types
 */
TransactionsTypes.DOM_ERROR_NAME = {
    /** The operation was aborted. */
    AbortError: 'AbortError',
};

/**
 * Errors mapping from mojito API error statuses
 * to {@link Mojito.Core.Services.Transactions.types.ERROR_CODE|ERROR_CODE} type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Transactions.TransactionRequest
 */
TransactionsTypes.ERRORS_MAP = {
    /* Unknown status from API. */
    UNKNOWN: TransactionsTypes.ERROR_CODE.UNKNOWN,
    /* Error status from API. */
    ERROR: TransactionsTypes.ERROR_CODE.UNKNOWN,
    /* Invalid session status from API. */
    INVALID_SESSION: TransactionsTypes.ERROR_CODE.INVALID_SESSION,
};

/**
 * Errors mapping from DOMException name to {@link Mojito.Core.Services.Transactions.types.ERROR_CODE|ERROR_CODE} type.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Transactions.TransactionRequest
 */
TransactionsTypes.DOM_ERRORS_MAP = {
    /* Aborted request status. */
    AbortError: TransactionsTypes.ERROR_CODE.ABORTED,
};

/**
 * Transaction request method. Reflects standard HTTP methods used for transaction requests.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Transactions.TransactionRequest
 */
TransactionsTypes.REQUEST_METHOD = {
    GET: 'get',
    PATCH: 'patch',
    POST: 'post',
};

/**
 * Transaction content types. See more info in {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types|MIME types}.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Transactions.TransactionRequest
 */
TransactionsTypes.CONTENT_TYPES = {
    /* application/json type. */
    JSON: 'application/json',
    /* application/pdf type. */
    PDF: 'application/pdf',
};

/**
 * Transaction request header.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Transactions.TransactionRequest
 */
TransactionsTypes.HEADER = {
    /* Token used to protect against cross-site request forgery (CSRF) attacks. */
    XSRF_TOKEN: 'X-XSRF-TOKEN',
    /* Correlation id for request traceability across distributed systems. */
    CORRELATION_ID: 'X-Correlation-ID',
    /* Content type. */
    CONTENT_TYPE: 'Content-Type',
};

/**
 * Transaction error object.
 *
 * @typedef Error
 * @type {object}
 * @property {string} type - Error type. Usually defined by concrete service.
 * @property {Array} messages - Error messages list. Typically list of strings.
 * @property {object} additionalInfo - Object contains any additional info that describes error.
 *
 * @memberof Mojito.Core.Services.Transactions.types
 */

/**
 * Transaction error object.
 *
 * @callback ErrorHandler
 * @property {Mojito.Core.Services.Transactions.types.Error} error - Error object.
 *
 * @memberof Mojito.Core.Services.Transactions.types
 */
