import TaskRunner from '../common/task-runner/task-runner';
import PollUserPromotionsTask from '../promotions/task/poll-user-promotions-task';

const DEFAULT_PROMOTIONS_INTERVAL = 60 * 2 * 1000;

/**
 * Promotions data retriever.
 *
 * @class PromotionsRetriever
 * @name dataRetriever
 * @memberof Mojito.Services.Promotions
 */
class PromotionsRetriever {
    init(config, transactionUrl) {
        const promotionsService = config.service;

        if (promotionsService) {
            this.pollUserPromotionsInterval =
                (config.pollUserPromotionsIntervalSec &&
                    config.pollUserPromotionsIntervalSec * 1000) ||
                DEFAULT_PROMOTIONS_INTERVAL;

            promotionsService.configure({ serviceUrl: transactionUrl });
            this.userPromotionsPoller = new TaskRunner(this.pollUserPromotionsInterval);
        }

        this.promotionsService = promotionsService;
    }

    hasService() {
        return Boolean(this.promotionsService);
    }

    pollUserPromotions() {
        if (!this.hasService() || this.userPromotionsPoller.isExecuting) {
            return;
        }
        const task = new PollUserPromotionsTask(this.promotionsService);
        this.userPromotionsPoller.run(task);
    }

    stopPollingUserPromotions() {
        if (this.userPromotionsPoller) {
            this.userPromotionsPoller.reset();
        }
    }

    subscriptionDisposed(/* itemId */) {
        // To be implemented by subclasses
    }
}

const instance = new PromotionsRetriever();
export default instance;
