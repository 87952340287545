import MojitoCore from 'mojito/core';
import {settingsStorage} from 'mojito/core/services/system-settings/slice';
import MojitoApplication, {bootstrap as mojitoBootstrap} from 'mojito/application';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoNGEN from 'mojito/ngen';

import './utils/dinosaur-detector.js';
import {ApplicationConfig} from '#core/config/application-config.js';
import customViewsConfig from '#core/config/custom-views-config.js';
import {
    absoluteUrlToInternalResource,
    currentScriptOrigin,
    currentScriptParams,
    currentScriptSrc,
    ensureStartingSlash,
    getDetectedHostURL,
    isInternalUrl,
    setInternalResourcesPath,
    stripRoutingPrefix,
} from '#core/utils/url-utils.js';
import {allFeatures, registerTypeTransformHandler} from './application/abstract-feature.js';
import {Localization} from '#core/localization.js';
import VersionedAPI from 'mojito/versioned_api';
import {Logger} from '#core/utils/logger.js';
import {
    assignRecursively,
    deepFreezeAllEnumerables,
    merge,
    pickProperties,
    setObjectProperty,
} from '#core/utils/config-utils.js';
import {isMobile, isNative, resolveConfigByCurrentContext, getAdditionalContext} from '#core/utils/context-utils.js';
import {
    checkFontFaceAvailable,
    getCSSFromFontsArray,
    getResettingCSSForContainerId,
} from '#core/utils/embedding-utils.js';
import {isValidIntent, openInternalIntent} from '#core/utils/intent-utils.js';
import {SpriteMapStorage} from '#core/utils/spritemap-storage/index.js';
import {Application, applicationModulesPromise} from '#core/application/index.js';
import '#core/portal/portal-listener.js';
import '#core/features/sentry/index.js';
import {WidgetBetHistory} from '#core/widgets/widget-bet-history.jsx';
import {WidgetBonusHistory} from '#core/widgets/widget-bonus-history.jsx';
import {WidgetActiveBonuses} from '#core/widgets/widget-active-bonuses.jsx';
import {WidgetInProgressBonuses} from '#core/widgets/widget-in-progress-bonuses.jsx';
import {WidgetSelectionButton} from '#core/widgets/widget-selection-button.jsx';
import {WidgetsProviderService} from '#core/application/widgets-provider/index.js';
import 'core/features/playground'; // Should have the highest priority and imported after widgets-provider
import 'core/features/ims';
import 'core/features/analytics/google-analytics-4';
import 'core/features/analytics/gtm';
import 'core/features/analytics/gtag';
import 'core/features/analytics/facebook-pixel';
import 'core/features/codesnippets';
import 'core/features/affiliates';
import 'core/features/deep-linking';

import {actionsProxyStorage} from 'core/application/actions-proxy/actions-proxy';
import {DebugFlags} from 'core/application/debug';
import {DrivenSSOService} from 'core/application/embedded-app/driven-sso-service';
import {handle32010message, updateBalances} from 'core/application/balances/store/utils';
import * as BalancesSelectors from 'core/application/balances/store/selectors';
import {actions as BalancesActions} from 'core/application/balances/store/slice';

import 'core/features/mozaic';
import {countAndIncreaseAutomatedPropertiesCount, printAutomationStatistics} from 'core/utils/statistics';

import {DATA_TYPE} from 'core/utils/data-validation.cjs';
import {getExternalUrlIntent} from 'core/utils/intent-factory';
import {Intents} from 'core/application/intents';

import COMMON_FONTS from 'core/config/fonts.yaml';
import {actions as metaDescriptionActions} from './application/seo/store/slice';
import {AnalyticsProxyService} from './application/analytics-proxy';
import {BasicPalette} from './application/modules/basic-palette';
import {DBXPerformanceService} from './services/performance-reporting-service';
import {DBXAbstractPerformanceLogger} from './services/performance-reporting-service/abstract-performance-logger';
import {getCollectedTime} from './services/performance-reporting-service/utils.js';
import {generateUniqueId} from './utils/utils';

const log = Logger('DBX');
const apiLog = Logger('Sportsbook API');

const STYLE_HEAD = 'color: dodgerblue; font-weight: bold';

const {ADD_FIRST_SELECTION} = MojitoApplication.ApplicationTypes.SHOW_BETSLIP_CONDITION;
const {BETSLIP_TYPE} = MojitoModules.SlidingBetslip.types;

const homePageUrl = getDetectedHostURL();

const Config = MojitoCore.Services.Config;
const IntentActions = MojitoCore.Intents.actions;
const CookieConsentActions = MojitoCore.Services.CookieConsent.actions;
const AuthenticationActions = MojitoServices.Authentication.actions;
const UserSettingsActions = MojitoServices.UserSettings.actions;
const AuthenticationTypes = MojitoServices.Authentication.types;
const UserSettingsTypes = MojitoServices.UserSettings.types;
const {actions: SystemSettingsActions, types: SystemSettingsTypes} = MojitoCore.Services.SystemSettings;
const {dispatch} = MojitoCore.Services.redux.store;
const reduxInstance = MojitoCore.Services.redux;
const {selectors: routerSelectors, actions: routerActions} = MojitoApplication.Router;
const {APPLICATION_MODE} = SystemSettingsTypes;
const {types: ServicesTypes} = MojitoServices.Common;
const {NgenContentTransporter} = MojitoNGEN;
const {prefetchDataSuccess} = MojitoServices.Prefetch.actions;

window.mojito_versioned_api = VersionedAPI;

function printDebugInfo(mojitoConfig) {
    log.group('%cDebug info', Logger.STYLE_DEBUG);
    log.info(
        `%cDBX is a global object with various info/functionality for debugging
    DBX.mojitoConfig       - configuration sent to Mojito
    DBX.appConfig          - application config (in ApplicationConfig)` +
            (Sportsbook.isEmbedded
                ? ''
                : `
    To wrap all translations click ${homePageUrl}/?_wrapPhrases
    To show all translation keys click ${homePageUrl}/?_keysAsPhrases
    To turn off all 3rd parties click ${homePageUrl}/?_no3rdParties
    To enable extensive logging click ${homePageUrl}/?_allLogs
    To analyze config click ${homePageUrl}/?_analyzeConfigs
    This setting will be valid till you close tab or restart browser
      `),
        Logger.STYLE_DEBUG
    );

    window.DBX.statistics = printAutomationStatistics(mojitoConfig.services.configurations);

    log.groupEnd();
}

const supplementaryContainerId = 'sportsbook-supplementary';

function mountSupplementary(configuration) {
    if (document.getElementById(supplementaryContainerId)) {
        return;
    }

    const fonts = COMMON_FONTS.concat(configuration?.fonts || []);
    let notLoadedFonts = [];

    for (let i = 0; i < fonts.length; i++) {
        if (!checkFontFaceAvailable(fonts[i].family)) {
            notLoadedFonts.push(fonts[i]);
        }
    }

    const supplementalEl = document.createElement('DIV');
    supplementalEl.id = supplementaryContainerId;

    SpriteMapStorage.init(supplementalEl, configuration?.customSportIconsMapping || {}, config.postInitConfig);

    if (notLoadedFonts.length > 0) {
        const style = document.createElement('style');
        style.id = `${supplementalEl.id}-font-styles`;
        style.innerHTML = getCSSFromFontsArray(notLoadedFonts);
        supplementalEl.appendChild(style);
    }

    document.body.appendChild(supplementalEl);
}

function unmountSupplementary() {
    const el = document.getElementById(supplementaryContainerId);
    if (!el) {
        log.error('Cannot unmount supplementary');
        return;
    }
    el.parentNode.removeChild(el);

    SpriteMapStorage.dispose();
}

function featureAllowed(feature) {
    if (DebugFlags.no3rdParties || WidgetsProviderService.isFullscreenWidget()) {
        if (feature.isThirdParty) return false;
    }

    return true;
}

function setDefaultConfigValues(configs = {}) {
    for (const configId in configs) {
        const configValue = configs[configId];

        Config.configRegistry.add({
            ...configValue,
            name: configId,
        });
    }
}

function init(bundledAppConfig, externalAppConfig) {
    let buildDate = new Date();

    registerTypeTransformHandler(DATA_TYPE.INTENT, getExternalUrlIntent);

    buildDate.setTime(BUILD_TIMESTAMP);
    buildDate = buildDate.toLocaleString('en-GB', {hour12: false});

    log.info(
        `%cVer. ${APP_VERSION}, sha: ${GIT_HASH}` + (process.env.NODE_ENV === 'development' ? ' / DEBUG mode' : ''),
        STYLE_HEAD
    );
    log.info(`%cBuild date: ${buildDate} EEST`, STYLE_HEAD);

    // Register default widgets
    WidgetsProviderService.registerWidget('bet-history', 'widget-bet-history', WidgetBetHistory);
    WidgetsProviderService.registerWidget('bonus-history', 'widget-bonus-history', WidgetBonusHistory);
    WidgetsProviderService.registerWidget('active-bonuses', 'widget-active-bonuses', WidgetActiveBonuses);
    WidgetsProviderService.registerWidget('in-progress-bonuses', 'widget-in-progress-bonuses', WidgetInProgressBonuses);
    WidgetsProviderService.registerWidget('selection-button', null, WidgetSelectionButton);
    (config.widgets || []).forEach(item => WidgetsProviderService.registerWidget(item.name, item.path, item.component));

    bundledAppConfig.assetsLocation && setInternalResourcesPath(bundledAppConfig.assetsLocation);

    return new Promise((success, fail) => {
        log.info('%c[1/7] Loading external config and translations...', Logger.STYLE_PROGRESS);

        const translationsPromise = Localization.init(config.language);
        const configPromise = ApplicationConfig.init(bundledAppConfig, externalAppConfig, {
            configNameToLoad: config.configNameToLoad,
        });

        Promise.all([configPromise, translationsPromise, applicationModulesPromise])
            .then(() => {
                performance.mark('dbx:config-loaded');
                if (ApplicationConfig.configAccessViolated) return;

                Localization.configure(ApplicationConfig.config.contentLocales, ApplicationConfig.config.translations);

                // --- print some info
                ApplicationConfig.apiUrl !== currentScriptOrigin &&
                    log.debug(`%cAPI url is set to ${ApplicationConfig.apiUrl}`, Logger.STYLE_DEBUG);
                ApplicationConfig.assetsLocation !== currentScriptOrigin + '/' &&
                    log.info(`%cAssets location is set to ${ApplicationConfig.assetsLocation}`, Logger.STYLE_DEBUG);

                log.info(`%cCurrent language is "${Localization.getCurrentLanguage()}"`, Logger.STYLE_DEBUG);
                // ---

                reduxInstance.actionListener.startListening({
                    actionCreator: AuthenticationActions.disposeSession,
                    effect: restoreSessionIfLost,
                });

                if (DebugFlags.analyzeConfigs) ApplicationConfig.analyzeConfigs();

                allFeatures.setupConfigs(
                    resolveConfigByCurrentContext(ApplicationConfig.config),
                    config,
                    featureAllowed
                );

                allFeatures.cookieConsentRestrictionsInit();

                mountSupplementary(ApplicationConfig.config);

                log.info('%c[2/7] Loading external dependencies...', Logger.STYLE_PROGRESS);

                let mojCustomBundledStyles = {};
                allFeatures
                    .beforeMojito()
                    .then(() => {
                        log.info('%c[3/7] Preparing tokens...', Logger.STYLE_PROGRESS);

                        allFeatures.beforeTokensBuild();
                        const startTokens = performance.now();
                        BasicPalette.load(ApplicationConfig.config);
                        if (config.getTokensFn) {
                            const tokens = config.getTokensFn();

                            // ---- Store tokens into config. TODO: everything should be passed via config
                            for (const id in tokens.palette || {}) {
                                const value = tokens.palette[id];
                                setObjectProperty(ApplicationConfig.bundledConfig, id, value);
                            }
                            for (const id in tokens.tile || {}) {
                                const value = tokens.tile[id];
                                setObjectProperty(ApplicationConfig.bundledConfig, id, value);
                            }

                            const customs = tokens.custom;
                            for (const id in customs) {
                                assignRecursively(mojCustomBundledStyles, customs[id]);
                                countAndIncreaseAutomatedPropertiesCount(id, 0, customs[id]);
                            }

                            ApplicationConfig._rebuildConfigs();
                        }
                        performance.measure('dbx:build-tokens', {start: startTokens});
                        allFeatures.afterTokensBuild();

                        // Split execution flow to small tasks to avoid blocking
                        // of user interaction (hehe) - TBT metric in Lighthouse.
                        // In other words this is needed to make Lighthouse happy :)
                        setTimeout(() => {
                            try {
                                log.info('%c[4/7] Building mojito configs...', Logger.STYLE_PROGRESS);
                                const defaultMojitoConfig = {};
                                allFeatures.beforeMojitoConfigBuild(defaultMojitoConfig);

                                const mojitoConfig = merge(
                                    defaultMojitoConfig,
                                    {
                                        application: {
                                            stores: {
                                                application: {
                                                    betslipAutoShowUp: {
                                                        [ADD_FIRST_SELECTION]: BETSLIP_TYPE.QUICK_BETSLIP,
                                                    },
                                                },
                                            },
                                        },
                                        core: {
                                            executionMode:
                                                process.env.NODE_ENV === 'production'
                                                    ? MojitoCore.EXECUTION_MODES.RELEASE
                                                    : MojitoCore.EXECUTION_MODES.DEBUG,
                                            baseUrl: ApplicationConfig.apiUrl,
                                            translations: Localization.getMojitoConfig(),
                                            deviceGuesser: {
                                                isProbablyHandHeldDevice: isMobile,
                                            },
                                            defaultCookieConsentGiven: config.cookieConsentGiven,
                                            contentTransporter: new NgenContentTransporter({
                                                maxConcurrentRequests: 30,
                                                baseUrl: ApplicationConfig.apiUrl,
                                            }),
                                            services: {
                                                performanceService: {
                                                    service: DBXPerformanceService,
                                                    // serviceConfig: {} // Nothing to configure here. DBXPerformanceService could be configured in runtime
                                                },
                                            },
                                        },
                                        services: merge(Application.getServicesConfig(), {
                                            configurations: {
                                                AppControllerView: {},
                                                Image: {
                                                    svgSpritemap: {
                                                        idPrefix: 'svgsprite-',
                                                        path: '',
                                                    },
                                                },
                                                Overlay: {
                                                    rootElementId: state.overlayId,
                                                    style: {
                                                        container: {
                                                            zIndex: config.overlayPlaneZIndex,
                                                        },
                                                    },
                                                },
                                                ConfigFactory: {
                                                    strict: false,
                                                },
                                            },
                                            stores: {
                                                bets: {}, // Without this mojito will print warnings
                                                systemSettings: {
                                                    applicationMode: isMobile()
                                                        ? APPLICATION_MODE.MOBILE
                                                        : APPLICATION_MODE.DESKTOP,
                                                    language: Localization.getCurrentLanguage(),
                                                    additionalContext: getAdditionalContext(),
                                                    contextToChannelMap: {
                                                        native: GENBET_BASED
                                                            ? ServicesTypes.CHANNEL.NATIVE
                                                            : ServicesTypes.CHANNEL.MOBILE, // not possible to configure promotions for the native context on Quantum-based operators since the native context is not supported by Apollo CMS
                                                    },
                                                },
                                            },
                                        }),
                                        resources: {
                                            getImage: function (path) {
                                                return absoluteUrlToInternalResource(`/images/${path}`);
                                            },
                                            getFixture: function (path) {
                                                return absoluteUrlToInternalResource(`/fixtures/${path}`);
                                            },
                                        },
                                    },
                                    config.getMojitoConfigFn && config.getMojitoConfigFn(ApplicationConfig.config),
                                    ApplicationConfig.mojitoConfiguration,
                                    {
                                        services: {
                                            configurations: assignRecursively(mojCustomBundledStyles, {
                                                Currencies: config.currencyCode
                                                    ? {
                                                          defaultCurrencyCode: config.currencyCode,
                                                      }
                                                    : {},
                                            }),
                                        },
                                    }
                                );

                                allFeatures.afterMojitoConfigBuild(mojitoConfig);
                                const mojDefConfigsStart = performance.now();
                                setDefaultConfigValues(customViewsConfig);
                                performance.measure('dbx:mojito-set-default-configs', {start: mojDefConfigsStart});

                                window.DBX = {
                                    ...window.DBX,
                                    mojitoConfig,
                                    spriteMapStorage: SpriteMapStorage,
                                    appConfig: ApplicationConfig.config,
                                    publishIntent(intentType, intentData) {
                                        dispatch(IntentActions.publishIntent(intentType, intentData));
                                    },
                                    parseAndPublishIntent(intentStr) {
                                        const intent = getExternalUrlIntent(intentStr);
                                        this.publishIntent(intent.type, intent.data);
                                    },
                                };
                                if (process.env.NODE_ENV === 'development') {
                                    printDebugInfo(mojitoConfig);
                                }

                                // Make Lighthouse happy again :)
                                setTimeout(() => {
                                    const mojitoStart = performance.now();
                                    log.info('%c[5/7] Starting up mojito...', Logger.STYLE_PROGRESS);

                                    try {
                                        mojitoBootstrap.init(mojitoConfig);

                                        performance.measure('dbx:init-mojito', {start: mojitoStart});
                                        log.info('%c[6/7] Loading external dependencies(2)...', Logger.STYLE_PROGRESS);
                                        allFeatures
                                            .afterMojito()
                                            .then(() => {
                                                log.info('%c[7/7] Done.', Logger.STYLE_PROGRESS);
                                            })
                                            .then(success);
                                    } catch (err) {
                                        log.error('Failed to init Mojito:', err);
                                    }
                                });
                            } catch (err) {
                                log.error('Failed to build configs', err);
                            }
                        });
                    })
                    .catch(error => {
                        log.error('Initialization failed (2).', error);
                        fail(error);
                    });
            })
            .catch(error => {
                log.error('Initialization failed (1).', error);
                fail(error);
            });
    });
}

let BUNDLED_CONFIGURATION; // will be frozen after configureSportsbook

export function configureSportsbook(conf) {
    BUNDLED_CONFIGURATION = merge(
        {
            configuration: {
                apiUrl: undefined,
                assetsLocation: undefined,
            },
            configNameToLoad: 'config', // external config name from where to load configuration file, by default its config.json
            postInitConfig: undefined,

            getMojitoConfigFn: undefined,
            getTokensFn: undefined,

            cookieConsentGiven: false,
            routingPrefix: '/',
            sportsbookPlaneZIndex: 9,
            overlayPlaneZIndex: 1000,
            containerId: 'app',
            overlayContainerId: undefined,
            currencyCode: undefined,
            uiContext: undefined,
            language: undefined,
            authMethod: 'external',
            widgets: [],
        },
        conf
    );
    if (process.env.NODE_ENV === 'development') {
        BUNDLED_CONFIGURATION = deepFreezeAllEnumerables(BUNDLED_CONFIGURATION);
    }
}

let config;

const INIT_NONE = '';
const INIT_INITIALIZING = 'INITIALIZING';
const INIT_DONE = 'INITIALIZED';

const state = {
    initializationState: INIT_NONE,
    initializationPromise: null,
    uid: '',
    overlayId: '',

    containerNode: null,
    stylesNode: null,
    reactAppNode: null,
    overlayContainerNode: null, // we do not own it
    overlayNode: null, // we own it

    isBalancesListenerSet: false,
    isSetMetaDescription: false,
};

const EXTERNAL_METHODS = {
    SHOW_LOGIN: 'SHOW_LOGIN',
    SHOW_DEPOSIT: 'SHOW_DEPOSIT',
    BALANCE_UPDATED: 'BALANCE_UPDATED',
    GET_SESSION_INFO: 'GET_SESSION_INFO',
    LOG_OUT: 'LOG_OUT',
    PAGE_METATAGS_UPDATED: 'PAGE_METATAGS_UPDATED',
    NAVIGATE: 'NAVIGATE',
    ANALYTICS_EVENT: 'ANALYTICS_EVENT',
    PERFORMANCE_LOG: 'PERFORMANCE_LOG',
    INTERNAL_ROUTE: 'INTERNAL_ROUTE',
    SPORTSBOOK_LOADED: 'SPORTSBOOK_LOADED',
};

const externalHandlers = Object.create(null);
let performanceLogHandler = null;

function callExternal(id, data) {
    apiLog.debug(...arguments);
    const fn = externalHandlers[id];
    if (!fn) {
        // do nothing
        apiLog.warn(`External method "${id}" is not set. Doing nothing`);
        return;
    }
    try {
        return fn(data);
    } catch (e) {
        apiLog.warn(`Failed to call external method "${id}": ${e.message}`);
    }
}

function wrapExternal(id) {
    return function () {
        apiLog.debug(id, ...arguments);
        const fn = externalHandlers[id];
        if (!fn) {
            // do nothing
            apiLog.warn(`External method "${id}" is not set. Doing nothing`);
            return;
        }
        try {
            return fn(...arguments);
        } catch (e) {
            apiLog.warn(`Failed to call external method "${id}": ${e.message}`);
        }
    };
}

function notifyBalancesUpdated() {
    callExternal(EXTERNAL_METHODS.BALANCE_UPDATED, BalancesSelectors.selectBalances());
}

function metaDescriptionUpdated(state) {
    callExternal(EXTERNAL_METHODS.PAGE_METATAGS_UPDATED, state.payload);
}

function notifyAnalyticsEvent(data) {
    callExternal(EXTERNAL_METHODS.ANALYTICS_EVENT, data);
}

function notifyInternalRouteEvent(route) {
    if (isInternalUrl(route.payload)) callExternal(EXTERNAL_METHODS.INTERNAL_ROUTE, route.payload);
}

function restoreSessionIfLost({payload: reason}) {
    if (reason === AuthenticationTypes.LOGOUT_REASON.UNEXPECTED_SESSION_LOST) {
        dispatch(AuthenticationActions.restoreSession());
    }
}

class SBLoadedNotifier extends DBXAbstractPerformanceLogger {
    constructor() {
        super();
        this.triggered = false;
        this.notifySBLoaded = this.notifySBLoaded.bind(this);
        this.SBLoadingNotificationTimer = setTimeout(this.notifySBLoaded, 30 * 1000);
    }

    wsActivityMostlyFinished() {
        // Called when websocket activity is mostly finished
        // We treat it as sportsbook loaded
        DBXPerformanceService.reportDuration('dbx.moj_timings.get_config_duration', getCollectedTime('getConfig'));

        setTimeout(this.notifySBLoaded, 0);
    }

    notifySBLoaded() {
        if (this.triggered) return;
        try {
            this.triggered = true;
            apiLog.debug('Sportsbook loaded.');
            clearTimeout(this.SBLoadingNotificationTimer);
            DBXPerformanceService.removeLogger(this); // dont like idea it unregister itself, but it is much easier and less code

            const fn = externalHandlers[EXTERNAL_METHODS.SPORTSBOOK_LOADED];
            if (fn) fn();
        } catch (e) {
            apiLog.warn(`Failed to call external method "${EXTERNAL_METHODS.SPORTSBOOK_LOADED}": ${e.message}`);
        }
    }
}

function error(error) {
    log.error(error);
    return Promise.reject(error);
}

function forwardMetrics() {
    DBXPerformanceService.reportSystemMeasurementStartTime('dbx:init:start', 'dbx.init.start');
    DBXPerformanceService.reportSystemMeasurementDuration('dbx:sportsbook:init', 'dbx.init.duration');
}

window.Sportsbook = {
    ...(window.Sportsbook || {}),

    init: function (options = {}) {
        if (state.initializationState !== INIT_NONE) {
            log.error('Sportsbook is already initialized. Ignoring "init" call...');
            return;
        }
        state.initializationState = INIT_INITIALIZING;
        performance.mark('dbx:init:start');
        apiLog.debug('init', ...arguments);
        state.uid = generateUniqueId(); // will be required and referenced later
        state.overlayId = `overlays-${state.uid}`;

        config = assignRecursively(
            {},
            BUNDLED_CONFIGURATION,
            pickProperties(options, [
                'APIVersion',
                'containerId',
                'routingPrefix',
                'language',
                'sportsbookPlaneZIndex',
                'overlayPlaneZIndex',
                'overlayContainerId',
                'cookieConsentGiven',
                'currencyCode',
                'uiContext',
                'authMethod',
                'configNameToLoad',
                'postInitConfig',
            ])
        );

        const bundledAppConfig = config.configuration || {};
        delete config.configuration;
        // === configuration
        const externalAppConfig = options.configuration || {};

        //----- Now checking and building preliminary configurations
        try {
            // === APIVersion
            switch (options.APIVersion) {
                case 5:
                case 4:
                case 3:
                    break;
                case 2:
                case 1:
                    return error('Sportsbook API version < 3 is not supported anymore');
                default:
                    return error(
                        'APIVersion is not specified or invalid. Probably you are calling .init without APIVersion parameter specified'
                    );
            }
            // === routingPrefix
            config.routingPrefix = ensureStartingSlash(config.routingPrefix);
            Sportsbook.isEmbedded = !options.standalone;
            //-----

            bundledAppConfig.apiUrl =
                currentScriptParams.get('apiUrl') ||
                currentScriptParams.get('baseUrl') ||
                options.apiUrl ||
                bundledAppConfig.apiUrl ||
                currentScriptOrigin;

            bundledAppConfig.assetsLocation =
                currentScriptParams.get('assetsLocation') ||
                options.assetsLocation ||
                bundledAppConfig.assetsLocation ||
                currentScriptOrigin +
                    currentScriptSrc.pathname.substring(0, currentScriptSrc.pathname.lastIndexOf('/') + 1);

            const additionalContext = config.uiContext || settingsStorage.getAdditionalContextFromUrl() || '';
            dispatch(SystemSettingsActions.updateAdditionalContext({additionalContext})); // Configure it prior mojito

            // --- Init perf service
            const navigator = window.navigator;
            const connection = navigator.connection;
            const perfEntries = performance.getEntriesByName(currentScriptSrc);
            const perfEntry = perfEntries.length > 0 ? perfEntries[0] : null;
            const jsLoadTime = perfEntry ? Math.round(perfEntry.duration) : 0;
            DBXPerformanceService.setTags({
                initializedOnSportsbookUrl: window.location.pathname.startsWith(config.routingPrefix),

                deviceMemory: navigator.deviceMemory,
                native: isNative(),
                jsCached: perfEntry ? perfEntry.transferSize < 4000 : jsLoadTime < 200, // less then 4k (cookies!). Fallback is: faster than 200ms
                connectionType: connection ? connection.effectiveType : null,
                connection_rtt: connection ? `${connection.rtt} ms` : null,
                connection_downlink: connection ? `${connection.downlink} Mbps` : null,
            });
            DBXPerformanceService.reportDuration('dbx.js_load_time', jsLoadTime);
            connection && DBXPerformanceService.reportMetric('dbx.connection_downlink', connection.downlink, 'Mbps');
            // ---
            state.initializationPromise = init(bundledAppConfig, externalAppConfig).then(result => {
                state.initializationState = INIT_DONE;
                performance.mark('dbx:init:end');
                performance.measure('dbx:sportsbook:init', 'dbx:init:start', 'dbx:init:end');
                forwardMetrics();
                return result;
            });
            return state.initializationPromise;
        } catch (e) {
            log.error(`Initialization failed (2)`, e);
            performance.mark('dbx:init:end');
            performance.measure('dbx:sportsbook:init', 'dbx:init:start', 'dbx:init:end');
            forwardMetrics();
            state.initializationState = INIT_NONE;
            return Promise.reject(e);
        }
    },

    terminate: function () {
        apiLog.debug('terminate', ...arguments);
        try {
            if (state.containerNode) {
                Sportsbook.unmountSportsbook();
            }
            WidgetsProviderService.unmountAllWidgets();
            unmountSupplementary();

            if (state.isBalancesListenerSet) {
                reduxInstance.actionListener.stopListening({
                    actionCreator: BalancesActions.updateBalance,
                    effect: notifyBalancesUpdated,
                });
                state.isBalancesListenerSet = false;
            }

            if (state.isSetMetaDescription) {
                reduxInstance.actionListener.stopListening({
                    actionCreator: metaDescriptionActions.setMetaDescription,
                    effect: metaDescriptionUpdated,
                });
                state.isSetMetaDescription = false;
            }

            reduxInstance.actionListener.stopListening({
                actionCreator: AuthenticationActions.disposeSession,
                effect: restoreSessionIfLost,
            });

            actionsProxyStorage.setNavigateHostHandler(null);
            AnalyticsProxyService.removeHandler(notifyAnalyticsEvent);

            allFeatures.dispose();
            mojitoBootstrap.terminate();
            state.initializationState = INIT_NONE;
            state.initializationPromise = null;
            return true;
        } catch (e) {
            state.initializationState = INIT_NONE;
            state.initializationPromise = null;
            log.error(e);
            return false;
        }
    },

    dispose() {
        this.terminate();
    },

    loadDependencies() {
        // override this method to allow to start from sportsbook.js, intead of bootstrap-embedded.js
        return Promise.resolve();
    },

    sendMessage: function (id, data) {
        apiLog.debug('sendMessage', ...arguments);
        try {
            switch (id) {
                case 'SYNC_SESSION':
                    if (data) {
                        dispatch(AuthenticationActions.login(data)); // We assume that DriveSSOService is used
                    } else if (data === null) {
                        dispatch(AuthenticationActions.logout());
                    } else {
                        // data is not set (undefined)
                        dispatch(AuthenticationActions.restoreSession());
                    }

                    return Promise.resolve();
                case 'CHANGE_TIMEZONE':
                    dispatch(UserSettingsActions.updateTimeOffset(data));
                    return Promise.resolve();
                case 'CHANGE_ODDS_FORMAT': {
                    const ODDS_FORMAT = UserSettingsTypes.ODDS_FORMAT;
                    if (
                        data !== ODDS_FORMAT.DECIMAL ||
                        data !== ODDS_FORMAT.FRACTIONAL ||
                        data !== ODDS_FORMAT.AMERICAN
                    ) {
                        log.error(`Invalid odds format: ${data}`);
                        return Promise.reject();
                    }
                    dispatch(UserSettingsActions.updateOddsFormat(data));
                    return Promise.resolve();
                }
                case 'GET_BALANCE': {
                    const result = BalancesSelectors.selectBalances();
                    return Promise.resolve(result);
                }
                case 'SET_BALANCE': {
                    updateBalances(data);
                    return Promise.resolve();
                }
                case 'OAPI_RESPONSE': {
                    switch (data.ID) {
                        case 32010:
                            handle32010message(data);
                            break;
                    }

                    return Promise.resolve();
                }
                case 'GIVE_COOKIE_CONSENT': {
                    if (data.analytics) {
                        dispatch(CookieConsentActions.giveConsent());
                    }
                    return Promise.resolve();
                }
                case 'PERFORMANCE_METRIC': {
                    let metrics = Array.isArray(data) ? data : [data];
                    for (let metric of metrics)
                        DBXPerformanceService.reportMetric(metric.name, metric.value, metric.unit);
                    return Promise.resolve();
                }
                case 'PREFETCH_DATA':
                    dispatch(prefetchDataSuccess(data));
                    return Promise.resolve();
                default:
                    return Promise.resolve(data);
            }
        } catch (e) {
            log.error(`Failed to invoke method ${id}`, e);
        }
    },

    // Handler can be changed in runtime
    setListener: function (id, fn) {
        apiLog.debug('setListener', ...arguments);
        externalHandlers[id] = fn;
        try {
            switch (id) {
                case EXTERNAL_METHODS.SHOW_LOGIN:
                    actionsProxyStorage.setShowLoginHandler(wrapExternal(id), true);
                    break;
                case EXTERNAL_METHODS.PAGE_METATAGS_UPDATED:
                    if (!state.isSetMetaDescription) {
                        reduxInstance.actionListener.startListening({
                            actionCreator: metaDescriptionActions.setMetaDescription,
                            effect: metaDescriptionUpdated,
                        });
                        state.isSetMetaDescription = true;
                    }
                    break;
                case EXTERNAL_METHODS.SHOW_DEPOSIT:
                    actionsProxyStorage.setDepositHandler(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.NAVIGATE:
                    actionsProxyStorage.setNavigateHostHandler(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.BALANCE_UPDATED:
                    if (!state.isBalancesListenerSet) {
                        reduxInstance.actionListener.startListening({
                            actionCreator: BalancesActions.updateBalance,
                            effect: notifyBalancesUpdated,
                        });
                        state.isBalancesListenerSet = true;
                    }
                    break;
                case EXTERNAL_METHODS.ANALYTICS_EVENT:
                    AnalyticsProxyService.addHandler(notifyAnalyticsEvent);
                    break;
                case EXTERNAL_METHODS.GET_SESSION_INFO:
                    DrivenSSOService.setCredentialsRetriever(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.LOG_OUT:
                    actionsProxyStorage.setLogoutHandler(wrapExternal(id));
                    break;
                case EXTERNAL_METHODS.PERFORMANCE_LOG:
                    if (fn) {
                        DBXPerformanceService.removeLogger(performanceLogHandler);
                        performanceLogHandler = fn;
                        DBXPerformanceService.addLogger(performanceLogHandler);
                    } else {
                        DBXPerformanceService.removeLogger(performanceLogHandler);
                        performanceLogHandler = null;
                    }
                    break;
                case EXTERNAL_METHODS.SPORTSBOOK_LOADED:
                    // externalHandlers already set
                    break;
                case EXTERNAL_METHODS.INTERNAL_ROUTE:
                    reduxInstance.actionListener.startListening({
                        actionCreator: routerActions.routeChange,
                        effect: notifyInternalRouteEvent,
                    });
                    break;
                default:
                    log.warn(`Sportsbook.setListener: Unknown event id: ${id}`);
                    return false;
            }
            return true;
        } catch (e) {
            log.error(e);
            return false;
        }
    },

    removeListener: function (id) {
        apiLog.debug('removeListener', ...arguments);
        externalHandlers[id] = null;
        try {
            switch (id) {
                case EXTERNAL_METHODS.SHOW_LOGIN:
                    actionsProxyStorage.removeShowLoginHandler();
                    break;
                case EXTERNAL_METHODS.PAGE_METATAGS_UPDATED:
                    if (state.isSetMetaDescription) {
                        reduxInstance.actionListener.stopListening({
                            actionCreator: metaDescriptionActions.setMetaDescription,
                            effect: metaDescriptionUpdated,
                        });
                        state.isSetMetaDescription = false;
                    }
                    break;
                case EXTERNAL_METHODS.SHOW_DEPOSIT:
                    actionsProxyStorage.removeDepositHandler();
                    break;
                case EXTERNAL_METHODS.NAVIGATE:
                    actionsProxyStorage.removeNavigateHostHandler();
                    break;
                case EXTERNAL_METHODS.BALANCE_UPDATED:
                    if (state.isBalancesListenerSet) {
                        reduxInstance.actionListener.stopListening({
                            actionCreator: BalancesActions.updateBalance,
                            effect: notifyBalancesUpdated,
                        });
                        state.isBalancesListenerSet = false;
                    }
                    break;
                case EXTERNAL_METHODS.ANALYTICS_EVENT:
                    AnalyticsProxyService.removeHandler(notifyAnalyticsEvent);
                    break;
                case EXTERNAL_METHODS.GET_SESSION_INFO:
                    DrivenSSOService.removeCredentialsRetriever();
                    break;
                case EXTERNAL_METHODS.LOG_OUT:
                    actionsProxyStorage.removeLogoutHandler();
                    break;
                case EXTERNAL_METHODS.INTERNAL_ROUTE:
                    reduxInstance.actionListener.stopListening({
                        actionCreator: routerActions.routeChange,
                        effect: notifyInternalRouteEvent,
                    });
                    break;
                default:
                    log.warn(`Sportsbook.removeListener: Unknown event id: ${id}`);
                    return false;
            }
            return true;
        } catch (e) {
            log.error(e);
            return false;
        }
    },

    mountSportsbook(containerId, params = {}) {
        apiLog.debug('mountSportsbook', ...arguments);
        if (state.initializationState !== INIT_DONE) {
            log.error('Cannot mount because Sportsbook is not initialized. Please call .init() first');
            return false;
        }
        if (!containerId) {
            containerId = config.containerId;
            if (!containerId) {
                log.error('Cannot mount because container ID is not specified');
                return false;
            }
        }
        if (state.containerNode && state.containerNode.id === containerId) {
            // Already mounted at the same node. Ignoring
            return true;
        }

        const containerEl = document.getElementById(containerId);
        if (!containerEl) {
            log.error(`Cannot mount Sportsbook. No DOM node found with provided id: ${containerId}`);
            return false;
        }

        if (window.$MOJITO_PREFETCH) {
            dispatch(prefetchDataSuccess(window.$MOJITO_PREFETCH));
            delete window.$MOJITO_PREFETCH;
        }

        config.containerId = containerId;
        try {
            if (params.routingPrefix) {
                config.routingPrefix = ensureStartingSlash(params.routingPrefix);
                routerSelectors.getRouteResolver().setRoot(config.routingPrefix);
            }

            if (state.containerNode) {
                // Already mounted at different container
                // Need to transfer from old node to new containerId

                state.containerNode.removeChild(state.reactAppNode);
                state.stylesNode && state.containerNode.removeChild(state.stylesNode);
                state.overlayContainerNode.removeChild(state.overlayNode);

                containerEl.innerHTML = ''; // Clear all contents
                state.stylesNode && containerEl.appendChild(state.stylesNode);
                state.overlayContainerNode =
                    (config.overlayContainerId && document.getElementById(config.overlayContainerId)) || containerEl;
                state.overlayContainerNode.appendChild(state.overlayNode);
                containerEl.appendChild(state.reactAppNode);

                state.containerNode = containerEl;
                return true;
            }

            const uid = state.uid;
            const reactAppId = `sportsbook-${uid}`;
            allFeatures.beforeMount(containerEl);

            containerEl.innerHTML = ''; // Clear all contents

            if (Sportsbook.isEmbedded) {
                const style = (state.stylesNode = document.createElement('style'));
                style.id = `reset-styles-${uid}`;
                style.innerHTML = getResettingCSSForContainerId(reactAppId);
                containerEl.appendChild(style);
            } else {
                state.stylesNode = null;
            }

            let reactAppNode = (state.reactAppNode = document.createElement('div'));
            reactAppNode.id = reactAppId;
            if (Sportsbook.isEmbedded) {
                reactAppNode.style.position = 'relative';
                reactAppNode.style.zIndex = config.sportsbookPlaneZIndex;
            }
            containerEl.appendChild(reactAppNode);

            state.overlayContainerNode =
                (config.overlayContainerId && document.getElementById(config.overlayContainerId)) || containerEl;

            let overlay = (state.overlayNode = document.createElement('div'));
            overlay.id = state.overlayId;
            overlay.classList.add('notranslate'); //DBX-10930: Disabled Google Translate on overlays (including mobile betslip)
            state.overlayContainerNode.appendChild(overlay);

            // ----  Adjust DOM
            const start = performance.now();
            DBXPerformanceService.reportTiming('dbx.render_start', start);
            mojitoBootstrap.render(reactAppNode.id);
            state.containerNode = containerEl;
            performance.measure('dbx:mojito-render', {start});
            allFeatures.afterMount(containerEl);
            if (Intents.SPORTSBOOK_MOUNTED) {
                dispatch(IntentActions.publishIntent(Intents.SPORTSBOOK_MOUNTED.type, Intents.SPORTSBOOK_MOUNTED.data));
            }

            DBXPerformanceService.addLogger(new SBLoadedNotifier()); // it will remove itself after 30 sec
            return true;
        } catch (e) {
            log.error(e);
            return false;
        }
    },

    unmountSportsbook() {
        apiLog.debug('unmountSportsbook', ...arguments);
        try {
            if (!state.containerNode) {
                log.warn('Trying to unmount Sportsbook that was not mounted. Ignored');
                return false;
            }
            allFeatures.beforeUnmount();
            mojitoBootstrap.unmount();
            state.containerNode.innerHTML = '';
            state.containerNode = null;
            state.reactAppNode = null;
            state.overlayContainerNode = null;
            state.overlayNode = null;
            state.stylesNode = null;
            dispatch(routerActions.reset()); // TODO temporary workaround for old Portal on HollandCasino, see details at DBX-12447
            return true;
        } catch (e) {
            log.error(e);
            return false;
        }
    },

    getWidgetByCurrentRoute: function () {
        return WidgetsProviderService.getWidgetByCurrentRoute();
    },

    mountWidget: function (widgetId, regionId, params) {
        apiLog.debug('mountWidget', ...arguments);
        return WidgetsProviderService.mountWidget(widgetId, regionId, params);
    },

    unmountWidget: function (regionId) {
        apiLog.debug('unmountWidget', ...arguments);
        return WidgetsProviderService.unmountWidget(regionId);
    },

    navigate: function (method, ...params) {
        apiLog.debug('navigate', ...arguments);

        let intent;
        if (!state.containerNode) {
            log.warn('Cannot navigate. Sportsbook is not mounted');
            return false;
        }

        if (!method) {
            // Sync router store with current history location path.
            // This is needed for a cases when navigation is done outside sportsbook.
            const pathChanged = window.location.pathname !== routerSelectors.selectRoute();
            if (pathChanged) {
                dispatch(routerActions.routeChange(window.location.pathname));
            }
            return true;
        }

        if (method === stripRoutingPrefix(window.location.pathname)) {
            method = method.substring(1);
            intent = openInternalIntent('toCustom', [method]);
            intent.data.isRedirect = true;
        } else if (method === '/') {
            intent = openInternalIntent('toHome');
        } else if (method[0] === '/') {
            method = method.substring(1); // delete leading slash (moj specific)
            intent = openInternalIntent('toCustom', [method]);
        } else {
            intent = openInternalIntent(method, params);
        }

        if (isValidIntent(intent)) {
            dispatch(IntentActions.publishIntent(intent.type, intent.data));
            return true;
        }

        return false;
    },

    get initializationState() {
        return state.initializationState;
    },

    get initializationPromise() {
        return state.initializationPromise;
    },

    get isMounted() {
        return !!state.containerNode;
    },

    get language() {
        return config?.language;
    },

    get usedLanguage() {
        return Localization.currentLanguage;
    },

    get _state() {
        return Object.assign({}, state);
    },

    get _config() {
        return Object.assign({}, config);
    },

    get _routingPrefix() {
        return config?.routingPrefix;
    },
};
