import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import BannerPromotionImpl from './banner-promotion.jsx';
import { noop } from 'mojito/utils';

/**
 * View represents banner promotion data.
 *
 * @class BannerPromotion
 * @memberof Mojito.Modules.Promotions
 */

/**
 * Callback to notify on promotion click.
 *
 * @param {Mojito.Services.Promotions.types.Promotion} promotion - Promotion data.
 * @callback Mojito.Modules.BannerPromotion.onClickCallback
 */

/**
 * BannerPromotion prop types.
 *
 * @property {Mojito.Services.Promotions.types.Promotion} promotion - Promotion data.
 * @property {Mojito.Modules.BannerPromotion.onClickCallback} [onClick = ()=>{}] - On click promotion callback.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data for market banner type. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 * @memberof Mojito.Modules.Promotions.BannerPromotion
 */
const propTypes = {
    promotion: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    shouldRequestData: PropTypes.bool,
};

const defaultProps = {
    onClick: noop,
    shouldRequestData: true,
};

export default MojitoCore.Presentation.UIView(
    'BannerPromotion',
    BannerPromotionImpl,
    propTypes,
    defaultProps
);
