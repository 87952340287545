import InplayFallbackEventCardImpl from './inplay-fallback-event-card.jsx';
import MojitoCore from 'mojito/core';

/**
 * Fallback card for inplay events promotion.
 *
 * @class InplayFallbackEventCard
 * @name inplay
 *
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.EventCards.Fallback.types
 */

export default MojitoCore.Presentation.UIControllerView(
    'InplayFallbackEventCard',
    InplayFallbackEventCardImpl
);
