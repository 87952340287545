import CurrencyHelper from 'presentation/formatters/currency/index.js';
import GenericBetFunds from 'presentation/components/bet-funds/generic-info/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const PrewagerStakeInfo = props => {
    const { prewager, currencyCode, mojitoTools } = props;
    const { stringResolver, config } = mojitoTools;

    const { cash = 0, bonus = 0 } = (prewager && prewager.stake) || {};

    if (bonus === 0) {
        return null;
    }

    return (
        <FlexPane config={config.container}>
            <GenericBetFunds
                config={config.cash}
                value={CurrencyHelper.formatCurrency(cash, currencyCode)}
                label={stringResolver.resolveString('$BET_FUNDS.PREWAGER_CASH_STAKE')}
            />
            <GenericBetFunds
                config={config.bonus}
                value={CurrencyHelper.formatCurrency(bonus, currencyCode)}
                label={stringResolver.resolveString('$BET_FUNDS.PREWAGER_BONUS_STAKE')}
            />
        </FlexPane>
    );
};

export default PrewagerStakeInfo;
