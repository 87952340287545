import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';
import BetBonusPromotionsImpl from './bet-bonus-promotions.jsx';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View which displays bet bonus promotions.
 * Typically used for acca boost and acca insurance bonuses on betslip.
 *
 * @class BetBonusPromotions
 * @memberof Mojito.Modules
 */

/**
 * BetBonusPromotions prop types.
 *
 * @property {Array<Mojito.Services.Betslip.types.BetBonus>} bonuses - Bet bonuses list.
 * @property {string} [currencyCode] - Currency code.
 * @property {boolean} [isActive = true] - True if bonuses are active, false otherwise.
 * @property {Function} [onReadMoreClick = () => {}] - Callback triggered on event triggered bonus read more click.
 *
 * @memberof Mojito.Modules.BetBonusPromotions
 */
const propTypes = {
    bonuses: PropTypes.array.isRequired,
    currencyCode: PropTypes.string,
    isActive: PropTypes.bool,
    onReadMoreClick: PropTypes.func,
};

const defaultProps = {
    isActive: true,
    onReadMoreClick: noop,
};

export default UIView('BetBonusPromotions', BetBonusPromotionsImpl, propTypes, defaultProps);
