import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import MainControllerViewImpl from './controllerview.jsx';
const UIControllerView = MojitoCore.Presentation.UIControllerView;

const propTypes = {
    customRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            component: PropTypes.func,
        })
    ),
};

const defaultProps = {
    customRoutes: [],
};

export default UIControllerView(
    'MainControllerView',
    MainControllerViewImpl,
    propTypes,
    defaultProps
);
