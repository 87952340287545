import PropTypes from 'prop-types';
import ApplicationHeaderImpl from './applicationheader.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * This class is responsible for rendering the root component of the header, which includes the CookieConsent,
 * ApplicationMenu, MainNavigationBar, and SystemInitialisation components.
 * <br><br>
 * The 'layout' configuration can affect the rendering position of SystemInitialisation. If it's set to 'compact',
 * SystemInitialisation is rendered at the bottom of the header. If set to 'normal', the ApplicationMenu component
 * is responsible for rendering the SystemInitialisation component.
 *
 * @class ApplicationHeader
 * @memberof Mojito.Application
 */

/**
 * ApplicationHeader prop types.
 *
 * @property {Mojito.Services.Authentication.types.UserInfo} [userInfo] - Object containing user info data.
 * @property {Mojito.Services.Bonus.types.FreeBet[]} [freeBets] - List of free bets.
 *
 * @memberof Mojito.Application.ApplicationHeader
 */
const propTypes = {
    userInfo: PropTypes.object,
    freeBets: PropTypes.array,
};

export default UIView('ApplicationHeader', ApplicationHeaderImpl, propTypes);
