/**
 * Class for the MatchAcca types.
 *
 * @class MatchAccaTypes
 * @name Types
 * @memberof Mojito.Modules.MatchAcca
 */
export default class MatchAccaTypes {}

/**
 * Enumeration of show modes for MatchAccaTypes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.MatchAcca.Types
 */
MatchAccaTypes.SHOW_MODE = {
    AUTO_SHOW_HIDE: 'auto',
    SHOW: 'show',
    HIDE: 'hide',
};

/**
 * Enumeration of selection positions for MatchAccaTypes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.MatchAcca.Types
 */
MatchAccaTypes.SELECTION_POSITION = {
    FIRST: 'FIRST',
    LAST: 'LAST',
};

/**
 * Enumeration of selection types for MatchAccaTypes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.MatchAcca.Types
 */
MatchAccaTypes.SELECTION_TYPE = {
    MARKET: 'MARKET',
    ODDS: 'ODDS',
};
