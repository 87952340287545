import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';

import SCHEMA from './codesnippets.schema.yaml';

class CodeSnippetsFeature extends AbstractFeature {
    get name() {
        return 'CodeSnippets';
    }

    get enabled() {
        return this.config.codeSnippets && this.config.codeSnippets.length !== 0;
    }

    get isThirdParty() {
        return true;
    }

    get isTracker() {
        return true;
    }

    get configSchema() {
        return SCHEMA;
    }

    getConfiguration(globalConfig) {
        const {codeSnippets} = globalConfig; // because schema doesn't have ROOT

        return super.getConfiguration({codeSnippets});
    }

    beforeMojito() {
        return Promise.allSettled([
            new Promise(resolve => {
                const codeSnippets = this.config.codeSnippets;

                codeSnippets.forEach(code => {
                    let script = document.createElement('script');
                    script.text = code;
                    document.body.appendChild(script);
                });

                // finish on next iteration of eventloop
                setTimeout(resolve, 0);
            }),
            super.beforeMojito(),
        ]);
    }
}

export const CodeSnippets = new CodeSnippetsFeature(allFeatures);
