import MojitoPresentation from 'mojito/presentation';
import { resolveMarketLayout, resolveSelectionsHeaders } from 'modules/market/helper/market-helper';

const { FlexPane, Text } = MojitoPresentation.Components;

const SelectionHeaders = props => {
    const { mojitoTools, eventItem, market, shouldShowDrawHeader } = props;
    const { stringResolver, config } = mojitoTools;

    const layoutDescriptor = resolveMarketLayout(market, config.layoutDescriptors);
    const headers = resolveSelectionsHeaders(
        layoutDescriptor,
        eventItem,
        stringResolver,
        shouldShowDrawHeader
    ).filter(Boolean);

    return (
        headers.length > 0 && (
            <FlexPane config={config.container}>
                {headers.map((header, index) => {
                    const key = `${header}-${index}`;
                    return (
                        <Text key={key} class="ta-SelectionsHeader" config={config.label}>
                            {header}
                        </Text>
                    );
                })}
            </FlexPane>
        )
    );
};

export default SelectionHeaders;
