import MojitoCore from 'mojito/core';
import Button from 'presentation/components/button/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import SelectedIndicator from 'presentation/components/selected-indicator/index.jsx';
import { pick } from 'mojito/utils';

const UIViewUtils = MojitoCore.Presentation.UIViewUtils;

const SelectableButton = props => {
    const { mojitoTools, children, selectedIndicatorContent, selected } = props;
    const { config, style } = mojitoTools;
    const buttonKey = selected ? 'selectedButton' : 'unselectedButton';
    const buttonConfig = selected ? config.selected : config.unselected;
    const flexPaneConfig = selected
        ? style.selectedChildrenContainer
        : style.unselectedChildrenContainer;

    return (
        <Button
            key={buttonKey + props.disabled}
            config={buttonConfig}
            buttonElementRef={props.buttonElementRef}
            {...UIViewUtils.omitComponentOwnProps(
                props,
                'selected',
                'selectedIndicatorContent',
                'selectableKey'
            )}
        >
            {selected && (
                <SelectedIndicator config={config.indicator} selected={props.selected}>
                    {selectedIndicatorContent}
                </SelectedIndicator>
            )}
            <FlexPane config={flexPaneConfig}>
                {
                    // We need to stick the children in a container to guarantee that they get
                    // a stacking context. Otherwise individual children might end up beneath
                    // the SelectedIndicator.
                    children
                }
            </FlexPane>
        </Button>
    );
};
export default SelectableButton;

SelectableButton.getStyle = (config /* mode, merge */) => ({
    unselectedChildrenContainer: {
        createStackingContext: true,
        style: pick(config.unselected.style.base, 'flexDirection', 'alignItems', 'justifyContent'),
    },
    selectedChildrenContainer: {
        createStackingContext: true,
        style: pick(config.selected.style.base, 'flexDirection', 'alignItems', 'justifyContent'),
    },
});
