import PropTypes from 'prop-types';
import PrewagerStakeInfoImpl from './prewager-stake-info.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to display stake info of bet funds prewager.
 *
 * @class PrewagerStakeInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * PrewagerStakeInfo props.
 *
 * @property {Mojito.Services.Bets.types.Prewager} [prewager] - Payout value.
 * @property {string} [currencyCode] - Currency.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.PrewagerStakeInfo
 */
const propTypes = {
    prewager: PropTypes.object,
    currencyCode: PropTypes.string,
};

export default UIView('PrewagerStakeInfo', PrewagerStakeInfoImpl, propTypes);
