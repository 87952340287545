import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';

const { FlexPane, Participants, MiniScoreboard, Button } = MojitoPresentation.Components;
const { UIViewImplementation } = MojitoCore.Presentation;

export default class EventListItemDetails extends UIViewImplementation {
    renderScoreboard(event) {
        // No scoreboard if not in play
        if (!event.details.inPlay) {
            return;
        }
        const hasDraw = !!this.props.drawLabelText;
        return (
            <MiniScoreboard
                config={hasDraw ? this.style.threeWayScoreBoardConfig : this.config.scoreboard}
                event={event}
            />
        );
    }

    renderParticipantsAndScore(event) {
        return (
            <FlexPane config={this.config.participantsAndScoreContainer}>
                <Participants
                    config={this.config.participants}
                    event={event}
                    forceDisplayEventName={this.props.forceDisplayEventName}
                    drawLabel={this.props.drawLabelText}
                />
                {this.renderScoreboard(event)}
            </FlexPane>
        );
    }

    render() {
        const { event } = this.props;

        return (
            <Button
                hrefLink={this.props.hrefLink}
                onClick={this.props.cbClick}
                class="EventListItemDetails"
                disabled={!this.props.cbClick}
            >
                <FlexPane config={this.config.container}>
                    {this.renderParticipantsAndScore(event)}
                </FlexPane>
            </Button>
        );
    }
}

EventListItemDetails.getStyle = config => {
    return {
        threeWayScoreBoardConfig: { ...config.scoreboard, threeWayLayout: true },
    };
};
