import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import DotNotificationImpl from './dot-notification.jsx';

/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 * The Dot Notification component is used to indicate something through a nicely colored dot that is either
 * adhered to a target element or configured to stand alone.
 * <br>
 * Options:
 * <ul>
 * <li>- Do not pass any child, so the dot will be rendered and configured as an independent component.</li>
 * <li>- Pass child(target), so the dot will be relatively positioned to the target element.</li>
 * </ul>
 *
 * @class DotNotification
 * @memberof Mojito.Presentation.Components
 */
/* eslint-enable jsdoc/require-description-complete-sentence */

/**
 * `DotNotification` component prop types.
 *
 * @property {string} [text = ''] - Text string will be rendered inside dot bounds. Could be used as notification amount or short label presenter.
 * @property {boolean} [isVisible = false] - Defines is dot notification will be presented or not.
 * @property {node} [children = null] - Possible children element, bounds of which will be used for positioning.
 *
 * @memberof Mojito.Presentation.Components.DotNotification
 */
const propTypes = {
    text: PropTypes.string,
    isVisible: PropTypes.bool,
    children: PropTypes.node,
};

const defaultProps = {
    text: '',
    isVisible: false,
    children: null,
};

export default MojitoCore.Presentation.UIView(
    'DotNotification',
    DotNotificationImpl,
    propTypes,
    defaultProps
);
