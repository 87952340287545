import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Selectors related to the AppSettings module.
 *
 * @module AppSettingsSelectors
 * @name selectors
 * @memberof Mojito.Modules.AppSettings
 */

/**
 * Selects the AppSettings module state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used, if not provided.
 * @returns {Mojito.Modules.AppSettings.AppSettingsModuleState} The state of the AppSettings module store.
 * @memberof Mojito.Modules.AppSettings.selectors
 */
export const selectState = state => {
    // Once we fully migrate to Redux, there should be no need to fall back to the global state.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects the view visibility state.
 *
 * @function selectAppSettingsViewVisibility
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used, if not provided.
 * @returns {boolean} Boolean indicating the view visibility state.
 * @memberof Mojito.Modules.AppSettings.selectors
 */
export const selectAppSettingsViewVisibility = state => selectState(state).isViewVisible;

/**
 * Establishes a subscription to detect modifications to the specified app-settings state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Modules.AppSettings.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
