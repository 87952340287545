import MojitoCore from 'mojito/core';
import IntentTypes from './types.js';
import NavigationPathBuilder from 'presentation/base/navigation/navigation-path-builder.js';
const { dispatch } = MojitoCore.Services.redux.store;

const intentActions = MojitoCore.Intents.actions;
const buildDataPayload = (data, isRedirect) => (isRedirect ? { ...data, isRedirect } : data);
/**
 * Utility class for triggering intentActions.
 * <br>
 * This class can be used to in a simple way trigger different types of intentActions.
 *
 * @see Mojito.Core.Intents.actions
 *
 * @class IntentActionTrigger
 * @memberof Mojito.Presentation.Base.Intent
 */
export default class IntentActionTrigger {
    /**
     * Trigger intent to show the in-play event page.
     *
     * @param {string} eventId - ID of the event to show on the page.
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showInPlayEvent
     */
    static showInPlayEvent(eventId) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                NavigationPathBuilder.toInplayEvent(eventId)
            )
        );
    }

    /**
     * Trigger intent to show the sport event page.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} eventId - ID of the event to show on the page.
     * @param {boolean} [isRedirect = false] - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showSportEvent
     */
    static showSportEvent(sportId, eventId, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(NavigationPathBuilder.toSportEvent(sportId, eventId), isRedirect)
            )
        );
    }

    /**
     * Trigger intent to show matches subpage for sport page.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} [filter] - Filter value that can be used to show proper events coupon on sport matches sub page. E.g., inplay, today, tomorrow etc.
     * @param {boolean} [isRedirect = false] - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showSportsMatches
     */
    static showSportsMatches(sportId, filter, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(NavigationPathBuilder.toSportMatches(sportId, filter), isRedirect)
            )
        );
    }

    /**
     * Trigger intent to show the in-play page.
     *
     * @param {string} [sportId] - ID of the sport to show in the page. If non provided, the "All" inplay page is shown.
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showInPlay
     */
    static showInPlay(sportId) {
        dispatch(
            intentActions.publishIntent(IntentTypes.VIEW, NavigationPathBuilder.toInplay(sportId))
        );
    }

    /**
     * Trigger intent to show a specific sport.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {boolean} [isRedirect = false] - True if URL should be replaced.
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showSport
     */
    static showSport(sportId, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(NavigationPathBuilder.toSport(sportId), isRedirect)
            )
        );
    }

    /**
     * Trigger intent to show virtual sports.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showVirtualSportsPage
     */
    static showVirtualSportsPage() {
        dispatch(
            intentActions.publishIntent(IntentTypes.VIEW, NavigationPathBuilder.toVirtualSports())
        );
    }

    /**
     * Trigger intent to show a specific virtual sport.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} sportClassId - ID of the sport class to show in the page.
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showVirtualSportPage
     */
    static showVirtualSportPage(sportId, sportClassId) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                NavigationPathBuilder.toVirtualSport(sportId, sportClassId)
            )
        );
    }

    /**
     * Trigger intent to redirect to specific virtual sport.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} sportClassId - ID of the virtual sport class to show in the page.
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#redirectToVirtualSportPage
     */
    static redirectToVirtualSportPage(sportId, sportClassId) {
        const data = NavigationPathBuilder.toVirtualSport(sportId, sportClassId);
        dispatch(intentActions.publishIntent(IntentTypes.VIEW, buildDataPayload(data, true)));
    }

    /**
     * Trigger intent to redirect to specific virtual event.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} sportClassId - ID of the virtual sport class to show in the page.
     * @param {string} eventId - ID of the event to show in the page.
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#redirectToVirtualEventPage
     */
    static redirectToVirtualEventPage(sportId, sportClassId, eventId) {
        const data = NavigationPathBuilder.toVirtualSportEvent(sportId, sportClassId, eventId);
        dispatch(intentActions.publishIntent(IntentTypes.VIEW, buildDataPayload(data, true)));
    }

    /**
     * Trigger intent to show a specific virtual sport.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} sportClassId - ID of the virtual sport class to show in the page.
     * @param {string} eventId - ID of the event to show in the page.
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showVirtualEventPage
     */
    static showVirtualEventPage(sportId, sportClassId, eventId) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                NavigationPathBuilder.toVirtualSportEvent(sportId, sportClassId, eventId)
            )
        );
    }

    /**
     * Trigger intent to show the Home page.
     *
     * @param {boolean} isRedirect - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showHomePage
     */
    static showHomePage(isRedirect) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(NavigationPathBuilder.toHome(), isRedirect)
            )
        );
    }

    /**
     * Trigger intent to show the Account page.
     *
     * @param {string} [itemId] - Account page ID.
     * @param {string} [partId] - Account page part ID. Usually sub page.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showAccountPage
     */
    static showAccountPage(itemId, partId) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                NavigationPathBuilder.toAccount(itemId, partId)
            )
        );
    }

    /**
     * Trigger intent to show the Race Meetings Page.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {boolean} [isRedirect=false] - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showRaceMeetingsPage
     */
    static showRaceMeetingsPage(sportId, isRedirect) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(NavigationPathBuilder.toSportMeetings(sportId), isRedirect)
            )
        );
    }

    /**
     * Trigger intent to show the racing sport meeting page.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} meetingId - ID of the meeting to show in the page.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showRaceMeetingsPage
     */
    static showRaceMeetingPage(sportId, meetingId) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                NavigationPathBuilder.toSportMeeting(sportId, meetingId)
            )
        );
    }

    /**
     * Trigger intent to show the future race event page.
     *
     * @param {string} sportId - Id of the sport to show in the page.
     * @param {string} raceId - Race to show in the page.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showFutureRaceEventPage
     */
    static showFutureRaceEventPage(sportId, raceId) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                NavigationPathBuilder.toFutureRaceEvent(sportId, raceId)
            )
        );
    }

    /**
     * Trigger intent to show the future races page.
     *
     * @param {string} sportId - Id of the sport to show in the page.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showFutureRacesPage
     */
    static showFutureRacesPage(sportId) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                NavigationPathBuilder.toFutureRaces(sportId)
            )
        );
    }

    /**
     * Trigger intent to show bad request page.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showBadRequestPage
     */
    static showBadRequestPage() {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(NavigationPathBuilder.toBadRequest(), true)
            )
        );
    }

    /**
     * Trigger intent to show the Match Sport Outrights Page.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} [typeId] - Type ID to show in the page (optional).
     * @param {boolean} [isRedirect=false] - True if url should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showMatchSportOutrights
     */
    static showMatchSportOutrights(sportId, typeId, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(
                    NavigationPathBuilder.toSportOutrightsLeague(sportId, typeId),
                    isRedirect
                )
            )
        );
    }

    /**
     * Trigger intent to show the Match Sport Competitions Page.
     *
     * @param {string} sportId - Id of the sport to show in the page.
     * @param {string} [typeId] - Sport type to show in the page (optional).
     * @param {boolean} [isRedirect = false] - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showMatchSportCompetitions
     */
    static showMatchSportCompetitions(sportId, typeId, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(
                    NavigationPathBuilder.toSportCompetitionsLeague(sportId, typeId),
                    isRedirect
                )
            )
        );
    }

    /**
     * Trigger intent to show the Competitions Page with anchor link to class.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} classId - Type ID to show in the page.
     * @param {boolean} [isRedirect=false] - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showCompetitionsClass
     */
    static showCompetitionsClass(sportId, classId, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(
                    NavigationPathBuilder.toSportCompetitions(sportId, classId),
                    isRedirect
                )
            )
        );
    }

    /**
     * Trigger intent to show the Outrights Page with anchor link to class.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} classId - Type ID to show in the page.
     * @param {boolean} [isRedirect=false] - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showOutrightClass
     */
    static showOutrightClass(sportId, classId, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(
                    NavigationPathBuilder.toSportOutright(sportId, classId),
                    isRedirect
                )
            )
        );
    }

    /**
     * Trigger intent to show the Meetings and Races Page.
     *
     * @param {string} sportId - ID of the sport to show in the page.
     * @param {string} meetingId - Meeting to show in the page.
     * @param {string} [raceId] - Race to show in the page.
     * @param {boolean} [isRedirect=false] - True if URL should be replaced.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#showMeetingsRaces
     */
    static showMeetingsRaces(sportId, meetingId, raceId, isRedirect = false) {
        dispatch(
            intentActions.publishIntent(
                IntentTypes.VIEW,
                buildDataPayload(
                    NavigationPathBuilder.toRaceEvent(sportId, meetingId, raceId),
                    isRedirect
                )
            )
        );
    }

    /**
     * Trigger intent when my account popup is closed.
     *
     * @function Mojito.Presentation.Base.Intent.IntentActionTrigger#closedAccountPopup
     */
    static closedAccountPopup() {
        dispatch(intentActions.publishIntent(IntentTypes.HIDE_ACCOUNT_POPUP));
    }
}
