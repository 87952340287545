import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import { createSelector } from '@reduxjs/toolkit';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Search store selectors.
 *
 * @class SearchSelectors
 * @name selectors
 * @memberof Mojito.Services.Search
 */

/**
 * Selects search state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Search.types.SearchState} Search state.
 * @memberof Mojito.Services.Search.selectors
 */
export const selectState = state => {
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

const selectHistoryItems = state => selectState(state).history.items;

/**
 * Get recent searches.
 *
 * @function selectRecentSearches
 *
 * @param {object} [state] - Application state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array.<string>} Recent searches, from newest to oldest.
 * @memberof Mojito.Services.Search.selectors
 */
export const selectRecentSearches = createSelector([selectHistoryItems], items =>
    items.map(item => item.query).reverse()
);

/**
 * Get the latest search result.
 *
 * @function selectResult
 *
 * @param {object} [state] - Application state object.
 * @returns {Mojito.Services.Search.types.SearchResult} Search result.
 * @memberof Mojito.Services.Search.selectors
 */
export const selectResult = state => {
    return selectState(state).search.result;
};
