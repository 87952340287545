import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './bet-history-slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Bet history related selectors.
 *
 * @module BetHistorySelectors
 * @name betHistorySelectors
 * @memberof Mojito.Services.Bets
 */

/**
 * Selects bet history state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.Bets.BetHistoryState} Bets history store state.
 * @memberof Mojito.Services.Bets.betHistorySelectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects history bets.
 *
 * @function selectBets
 * @param {Mojito.Services.Bets.BetHistoryState} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Bets.types.Bet>} History bets.
 * @memberof Mojito.Services.Bets.betHistorySelectors
 */
export const selectBets = state => selectState(state).bets;

/**
 * Selects pagination info.
 *
 * @function selectPagination
 * @param {Mojito.Services.Bets.BetHistoryState} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Bet.types.Pagination} Pagination data.
 * @memberof Mojito.Services.Bets.betHistorySelectors
 */
export const selectPagination = state => selectState(state).pagination;

/**
 * Selects fetching state.
 *
 * @function selectIsFetching
 * @param {Mojito.Services.Bets.BetHistoryState} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if fetching is in progress.
 * @memberof Mojito.Services.Bets.betHistorySelectors
 */
export const selectIsFetching = state => selectState(state).isFetching;

/**
 * Selects bet history fetch error.
 *
 * @function selectError
 * @param {Mojito.Services.Bets.BetHistoryState} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Core.Services.Transactions.types.Error|undefined} Error.
 * @memberof Mojito.Services.Bets.betHistorySelectors
 */
export const selectError = state => selectState(state).error;

/**
 * Selects status filter value.
 *
 * @function selectStatusFilter
 * @param {Mojito.Services.Bets.BetHistoryState} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Currently selected bet history status filter.
 * @memberof Mojito.Services.Bets.betHistorySelectors
 */
export const selectStatusFilter = state => selectState(state).statusFilter;

/**
 * Selects range filter value.
 *
 * @function selectRangeFilter
 * @param {Mojito.Services.Bets.BetHistoryState} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Currently selected bet history range filter.
 * @memberof Mojito.Services.Bets.betHistorySelectors
 */
export const selectRangeFilter = state => selectState(state).rangeFilter;
