import RestorableService from './restorable-service';
import MojitoNGen from 'mojito/ngen';
const { logger } = MojitoNGen;
const log = logger.get('AbstractBalanceService', { level: logger.INFO });
/**
 * Abstract balance service to be implemented by different integrations.
 * Should be responsible for balance loading and fetching. Either through
 * pull or push mechanic.
 *
 * @class AbstractBalanceService
 * @abstract
 * @extends Mojito.Services.UserInfo.Balance.RestorableService
 * @memberof Mojito.Services.UserInfo.Balance
 */
export default class AbstractBalanceService extends RestorableService {
    /**
     * Request additional balance update.
     *
     * @param {string} currency - User currency code.
     *
     * @throws {Error} Will throw an error if there is no subscription.
     * @function Mojito.Services.UserInfo.Balance.AbstractBalanceService#fetchBalance
     */
    // eslint-disable-next-line no-unused-vars
    fetchBalance(currency) {
        if (!this.isSubscribed) {
            throw new Error('Balance subscription is missing.');
        }
    }

    /**
     * Subscribe to balance update.
     *
     * @param {string} currency - User currency code.
     * @param {Function} successCallback - Success callback function.
     * @param {Function} failedCallback - Failed callback function.
     *
     * @throws Will throw an error when subscription already exist.
     * @function Mojito.Services.UserInfo.Balance.AbstractBalanceService#subscribeToBalanceUpdates
     */
    // eslint-disable-next-line no-unused-vars
    subscribeToBalanceUpdates(currency, successCallback, failedCallback) {
        if (this.isSubscribed) {
            log.error('Only one balance subscription per instance supported');
        } else {
            this.isSubscribed = true;
        }
    }

    /**
     * Unsubscribe from balance updates.
     *
     * @function Mojito.Services.UserInfo.Balance.AbstractBalanceService#unsubscribeFromBalanceUpdates
     */
    unsubscribeFromBalanceUpdates() {
        this.isSubscribed = false;
    }
}
