import AbstractAuthenticationService from './abstract-authentication-service.js';
import { merge } from 'mojito/utils';
import { TokenCredentialsBuilder } from 'services/authentication/credentials';
import AuthenticationTypes from 'services/authentication/types.js';

const { CREDENTIALS_PUBLIC_TYPE } = AuthenticationTypes;

/**
 * Generic SSO authentication service to support custom SSO implementations.
 *
 * @class SSOAuthenticationService
 * @memberof Mojito.Services.Authentication
 */
class SSOAuthenticationService extends AbstractAuthenticationService {
    constructor() {
        super();

        this.authService = undefined;
        this.ssoServiceImpl = undefined;
    }

    /**
     * Configure the service.
     *
     * @param {Mojito.Services.Authentication.AbstractAuthenticationService} authService - Auth service backing this implementation.
     * @param {Mojito.Services.Authentication.AbstractSSOService} ssoServiceImpl - SSO service implementation.
     * @function Mojito.Services.Authentication.SSOAuthenticationService#configure
     */
    configure(authService, ssoServiceImpl) {
        this.authService = authService;
        this.ssoServiceImpl = ssoServiceImpl;
    }

    /**
     * Login request using credentials.
     *
     * @param {Mojito.Services.Authentication.types.AuthCredentials} credentials - User authentication credentials.
     * @param {Mojito.Services.Authentication.types.loginSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.loginFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.SSOAuthenticationService#login
     */
    login(credentials, successCallback, errorCallback) {
        this.ssoServiceImpl.login(
            credentials,
            (userName, sessionToken, sessionCreationTime) => {
                const tokenCredentials = new TokenCredentialsBuilder()
                    .withPublicFactor(userName)
                    .withPublicType(CREDENTIALS_PUBLIC_TYPE.USERNAME)
                    .withToken(sessionToken)
                    .build();
                this.authService.login(
                    tokenCredentials,
                    authInfo => {
                        // We should always relay on session creation time
                        // received back from SSO implementation.
                        this._applySessionCreationTime(authInfo, sessionCreationTime);
                        successCallback(authInfo);
                    },
                    errorCallback
                );
            },
            errorCallback
        );
    }

    /**
     * Logout request.
     *
     * @param {Mojito.Services.Authentication.types.logoutSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.logoutFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.SSOAuthenticationService#logout
     */
    logout(successCallback, errorCallback) {
        this.authService.logout(successCallback, errorCallback);
        this.ssoServiceImpl.logout();
    }

    /**
     * Validate existing user session.
     *
     * @param {Mojito.Services.Authentication.types.validateSessionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.validateSessionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.SSOAuthenticationService#validateSession
     */
    validateSession(successCallback, errorCallback) {
        this.authService.validateSession(successCallback, errorCallback);
    }

    /**
     * Tries to restore user session by calling `Mojito.Services.Authentication.AbstractSSOService#checkIfLoggedInBySSO` of
     * SSO service implementation. If check was successful and SSO implementation thinks that user has valid session towards wallet integration (a.k. Alira, PAM, IMS),
     * then `Mojito.Services.Authentication.AbstractAuthenticationService#login` of authentication service implementation will be called.
     * Typically happens on page load when user already has been authenticated by third party SSO service
     * or if session cookie is still valid.
     *
     * @param {Mojito.Services.Authentication.types.validateSessionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.validateSessionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.SSOAuthenticationService#restoreSession
     */
    restoreSession(successCallback, errorCallback) {
        this.ssoServiceImpl.checkIfLoggedInBySSO((userName, sessionToken, sessionCreationTime) => {
            const credentials = new TokenCredentialsBuilder()
                .withPublicFactor(userName)
                .withPublicType(CREDENTIALS_PUBLIC_TYPE.USERNAME)
                .withToken(sessionToken)
                .build();
            this.authService.login(
                credentials,
                authInfo => {
                    merge(authInfo, { sessionInfo: { creationTime: sessionCreationTime } });
                    successCallback(authInfo);
                },
                errorCallback
            );
        });
    }

    /**
     * Change the user password.
     *
     * @param {Mojito.Services.Authentication.types.PasswordUpdate} passwordUpdate - Object defines password change data.
     * @param {Mojito.Services.Authentication.types.changePasswordSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.changePasswordFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.SSOAuthenticationService#changePassword
     */
    changePassword(passwordUpdate, successCallback, errorCallback) {
        this.authService.changePassword(passwordUpdate, successCallback, errorCallback);
    }

    _applySessionCreationTime(authInfo, loginTime) {
        return merge(authInfo, { sessionInfo: { creationTime: loginTime } });
    }
}

export default new SSOAuthenticationService();
