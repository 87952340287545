import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import channelFactory from 'services/common/content/content-channel-factory.js';
import menusProvider from 'services/sports-content/content-provider/menus-provider';
import MenusDescriptor from './descriptor.js';
import { omit, mapValues, keyBy } from 'mojito/utils';

const reduxInstance = MojitoCore.Services.redux;
const { SPORT_MENU } = MenusDescriptor.DATA_TYPES;
const { CONTENT_STATE } = ServicesTypes;
const { actionsRegistry } = MojitoCore.Services.Content;
export const getMenusChannel = () => channelFactory.getChannel(menusProvider, SPORT_MENU);

/**
 * Defines the state of the menus store.
 *
 * @typedef MenusState
 *
 * @property {Mojito.Services.SportsContent.Menus.types.SportMenusByTimeZone} sportMenusByTimeZone - Sport menus by time zone. Object where keys are time zones and values are [sports with content information]{@link Mojito.Services.SportsContent.Menus.types.SportMenus}.
 * @property {Object<string, Mojito.Services.Common.types.CONTENT_STATE>} sportMenusState - Sport menus state information. Object where keys are list ids and values are [content state]{@link Mojito.Services.Common.types.CONTENT_STATE}.
 *
 * @memberof Mojito.Services.SportsContent.Menus
 */

/**
 * The name of the menus state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.Menus
 */
export const STORE_KEY = 'menusStore';

export const INITIAL_STATE = {
    sportMenusByTimeZone: {},
    sportMenusState: {},
};

export const { reducer, actions } = createSlice({
    name: 'menus',
    initialState: INITIAL_STATE,
    reducers: {
        updateMenus(state, { payload }) {
            const { listId, menuData } = payload;

            if (!menuData) {
                delete state.sportMenusByTimeZone[listId];
                state.sportMenusState[listId] = CONTENT_STATE.UNAVAILABLE;
            } else {
                const { id: timeZone, sports = [] } = menuData;

                const menusMap = keyBy(sports, menu => menu.id);
                state.sportMenusByTimeZone[timeZone] = mapValues(menusMap, menu => menu.content);
                state.sportMenusState[timeZone] = CONTENT_STATE.AVAILABLE;
            }
        },
        menusPending(state, { payload }) {
            const { listIds } = payload;

            listIds?.forEach(listId => {
                state.sportMenusState[listId] = CONTENT_STATE.PENDING;
            });
        },
        removeMenus(state, { payload }) {
            const { listIds } = payload;

            listIds.forEach(listId => (state.sportMenusState[listId] = CONTENT_STATE.UNKNOWN));
            state.sportMenusByTimeZone = omit(state.sportMenusByTimeZone, listIds);
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Menus related actions.
 *
 * @class MenusActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.Menus
 */

/**
 * Update menus.
 *
 * @function updateMenus
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listId: string, menuData: object}} payload - Payload for menus update.
 *
 * @memberof Mojito.Services.SportsContent.Menus.actions
 */

/**
 * Pending menus.
 *
 * @function menusPending
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload that contains the ids of pending menus.
 *
 * @memberof Mojito.Services.SportsContent.Menus.actions
 */

/**
 * Remove menus.
 *
 * @function removeMenus
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{listIds: Array<string>}} payload - Payload for menus removal.
 *
 * @memberof Mojito.Services.SportsContent.Menus.actions
 */

/**
 * Reset menus state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.Menus.actions
 */

/**
 * Subscribes to menu by list id.
 *
 * @function subscribe
 *
 * @param {{clientId: string, listId: string}} payload - Subscription payload. Contains the subscribed listId and clientId of subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to sport menu thunk.
 * @memberof Mojito.Services.SportsContent.Menus.actions
 */
actions.subscribe =
    ({ clientId, listId }) =>
    dispatch => {
        const onData = (listId, menuData) => {
            dispatch(actions.updateMenus({ listId, menuData }));
        };
        const pendingMenusIds = getMenusChannel().subscribe([listId], clientId, onData);
        dispatch(actions.menusPending({ listIds: pendingMenusIds }));
    };

/**
 * Unsubscribe from menus.
 *
 * @function unsubscribe
 *
 * @param {string} clientId - The clientId of subscriber to unsubscribe.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from menus thunk.
 * @memberof Mojito.Services.SportsContent.Menus.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getMenusChannel().unsubscribeAll(clientId, listIds => {
        dispatch(actions.removeMenus({ listIds }));
    });
};
actionsRegistry.addSubscription(SPORT_MENU, actions.subscribe, actions.unsubscribe);

reduxInstance.injectReducer(STORE_KEY, reducer);
