import PropTypes from 'prop-types';
import MarketImpl from './banner-market.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View for viewing one or more promotion items.
 *
 * @class BannerMarket
 * @memberof Mojito.Modules.Promotions
 */

/**
 * BannerMarket prop types.
 *
 * @property {event} [event] - The event object holding data and market for this bannermarket.
 * @property {marketId} marketId - The market id for finding the market on the event.
 * @property {betslip} [betslip] - Betslip for odds banners, allowing user to click odds banner selections.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 *
 * @memberof Mojito.Modules.Promotions.BannerMarket
 */
const propTypes = {
    event: PropTypes.object,
    marketId: PropTypes.string.isRequired,
    betslip: PropTypes.object,
    shouldRequestData: PropTypes.bool,
};

const defaultProps = {
    shouldRequestData: true,
};

export default UIView('BannerMarket', MarketImpl, propTypes, defaultProps);
