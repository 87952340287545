import MarketTypes from 'modules/market/types';
import MojitoPresentation from 'mojito/presentation';
import { isString, omit, curry, isEmpty } from 'mojito/utils';

const { HEADER_CONTENT, LAYOUT_TYPE } = MarketTypes;
const { SelectionHelper } = MojitoPresentation.Utils;

export const SELECTION_TYPE_PROP = 'type';
export const SELECTION_NAME_PROP = 'name';

const HOME_TYPES = ['H', '1', 'P1', '1X'];
const AWAY_TYPES = ['A', '2', 'P2', 'X2'];
const UNKNOWN = 'UNKNOWN';

const selectionMatcher = key => ({
    match: (value, selection) => value === selection[key],
    propName: key,
});
const matchName = selectionMatcher(SELECTION_NAME_PROP);
const matchType = selectionMatcher(SELECTION_TYPE_PROP);

/**
 * Helper class offers functionality for aggregated market view.
 *
 * @class MarketHelper
 * @memberof Mojito.Modules
 */

/**
 * Resolves headers typically used for selection groups titling within aggregated market.
 *
 * @function resolveSelectionsHeaders
 *
 * @param {object} descriptor - Market layout descriptor.
 * @param {object} event - Event data object.
 * @param {Mojito.Core.Base.StringResolver} stringResolver - Instance of string resolver.
 * @param {boolean} [shouldShowDrawHeader = true] - Flag indicates if the header for draw result should be shown.
 *
 * @returns {Array<string>} List of selection headers.
 * @memberof Mojito.Modules.MarketHelper
 */
export const resolveSelectionsHeaders = (
    descriptor,
    event,
    stringResolver,
    shouldShowDrawHeader = true
) => {
    const { selectionHeadersMap = {}, selectionTypes = [] } = descriptor;
    switch (descriptor.selectionsHeaderContent) {
        case HEADER_CONTENT.PARTICIPANT:
            return resolveHeadersForParticipants(
                descriptor,
                event,
                stringResolver,
                shouldShowDrawHeader
            );
        case HEADER_CONTENT.TYPE:
            return selectionTypes.map(type =>
                stringResolver.resolveString(selectionHeadersMap[type])
            );
    }
    return [];
};

/**
 * Resolves selection groups for the market based on the provided <code>groupingMask</code>.
 * Typically used to display selections in multiple columns which reflect betting ways.
 * This function guarantee line integrity with "bogus" selections.
 * Example: If three way market has groupingMask: [1, X, 2] and there are three selections exist on a market then three
 * groups will be generated per each bet way with single selection in each group. If for the same market only two selections exist
 * then missing selection will be replaced with "bogus" selection object to ensure proper three way layout.
 * If provided <code>groupingMask</code> doesn't comply with real selection types existing on selection objects and strictGrouping is enabled then selections will be equally divided by groups using numberOfSelectionGroups param.
 *
 * @function resolveSelectionGroups
 *
 * @param {Mojito.Modules.MarketHelper.SelectionMatcher} matcher - Selection mather instance. Contains predicate function. If returns true then selection matches grouping criteria
 * provided in <code>groupingMask</code> property and will be added to corresponding group.
 * @param {object} market - Market data object. Selections from this object will be grouped. Note: this function does not mutate market object.
 * @param {Array<string>} groupingMask - List of groups identifiers. Will be used as criteria to generate selection group per identifier.
 * Selections will be organized between groups according to this mask. The groups order will reflect the order of corresponding identifiers withing mask list.
 * @param {boolean} switchHomeAway - True if home and away groups are suppose to switch places.
 * Mainly used in american sports where home/away selections have reverse order within market line.
 * @param {number} [numberOfSelectionGroups = 1] - Number of market groups columns. It will only be used if the groupingMask is empty - otherwise it will be based on the groupingMask.
 * @param {boolean} [strictGrouping = false] - If true then function will validate if <code>groupingMask</code> complies with real selection types existing on selection objects and will apply mask only if the result of such checking is positive.
 *
 * @returns {Array<Array<object>>} List of selection groups. Each selections group is a list of selections.
 *
 * @memberof Mojito.Modules.MarketHelper
 */
export const resolveSelectionGroups = (
    matcher,
    market,
    groupingMask,
    switchHomeAway,
    numberOfSelectionGroups = 1,
    strictGrouping = false
) => {
    const selections = (market && market.selections && market.selections.slice()) || [];
    const selectionsComparator = SelectionHelper.composeComparators(
        SelectionHelper.compareBySortOrder,
        SelectionHelper.compareByPrice
    );
    selections.sort(selectionsComparator);
    const result = isApplicableMask(groupingMask, selections, matcher, strictGrouping)
        ? generateLine(selections, groupingMask, matcher)
        : splitIntoGroups(selections, numberOfSelectionGroups);

    return switchHomeAway && isHomeAwayLine(getLayoutType(groupingMask), result)
        ? swapHomeAwayGroups(result)
        : result;
};

/**
 * This function is like {@link Mojito.Modules.MarketHelper.resolveSelectionGroups} except that
 * it matches <code>groupingMask</code> values to <code>selection.name</code> property. It allows to group selections by desired names mask.
 *
 * @function resolveSelectionGroupsByName
 *
 * @param {object} market - Market data object. Selections from this object will be grouped.
 * @param {Array<string>} groupingMask - List of selection names. Will be used as criteria to generate selection group per name.
 * @param {boolean} switchHomeAway - True if home and away groups are suppose to switch places.
 * @param {number} [numberOfSelectionGroups = 1] - Number of market groups columns. It will only be used if the groupingMask is empty - otherwise it will be based on the groupingMask.
 * @param {boolean} [strictGrouping = false] - If true then function will validate if <code>groupingMask</code> complies with real selection types existing on selection objects and will apply mask only if the result of such checking is positive.
 *
 * @returns {Array<Array<object>>} List of selection groups. Each selections group is a list of selections.
 *
 * @memberof Mojito.Modules.MarketHelper
 */
export const resolveSelectionGroupsByName = curry(resolveSelectionGroups)(matchName);

/**
 * This function is like {@link Mojito.Modules.MarketHelper.resolveSelectionGroups} except that
 * it matches <code>groupingMask</code> values to <code>selection.type</code> property. It allows to group selections by desired types mask.
 *
 * @function resolveSelectionGroupsByType
 *
 * @param {object} market - Market data object. Selections from this object will be grouped.
 * @param {Array<string>} groupingMask - List of selection types. Will be used as criteria to generate selection group per type.
 * @param {boolean} switchHomeAway - True if home and away groups are suppose to switch places.
 * @param {number} [numberOfSelectionGroups = 1] - Number of market groups columns. It will only be used if the groupingMask is empty - otherwise it will be based on the groupingMask.
 * @param {boolean} [strictGrouping = false] - If true then function will validate if <code>groupingMask</code> complies with real selection types existing on selection objects and will apply mask only if the result of such checking is positive.
 *
 * @returns {Array<Array<object>>} List of selection groups. Each selections group is a list of selections.
 *
 * @memberof Mojito.Modules.MarketHelper
 */
export const resolveSelectionGroupsByType = curry(resolveSelectionGroups)(matchType);

/**
 * Splits selections into groups.
 *
 * @function splitIntoGroups
 *
 * @param {Array<object>} selections - Selections list.
 * @param {number} groupsCount - Number of desired groups.
 *
 * @returns {Array<Array<object>>} List of selection groups. Each selections group is a list of selections.
 *
 * @memberof Mojito.Modules.MarketHelper
 */
export const splitIntoGroups = (selections, groupsCount) => {
    // Free form markets always present selections in predefined number of columns based on the amount of groups.
    // This property is configurable per market type, market category and has default value.
    const result = new Array(groupsCount).fill().map(() => []);
    selections.forEach((selection, index) => {
        const groupIndex = index % groupsCount;
        result[groupIndex].push(selection);
    });
    return selections.length ? result : [];
};

/**
 * Resolves market layout descriptor. Helpful for rendering market selections and headers.
 *
 * @function resolveMarketLayout
 *
 * @param {object} market - Event object.
 * @param {object} layoutDescriptors - Participant index in participants list.
 *
 * @returns {object} Market layout descriptor.
 *
 * @memberof Mojito.Modules.MarketHelper
 */
export const resolveMarketLayout = (market, layoutDescriptors) => {
    let marketLayout;
    if (market) {
        marketLayout = {
            ...layoutDescriptors.default,
            ...layoutDescriptors.categories[market.typeCategory],
            ...layoutDescriptors.types[market.type],
        };
    }
    return {
        ...marketLayout,
        ...omit(layoutDescriptors, 'categories', 'types', 'selectionHeaders'),
        selectionHeadersMap: layoutDescriptors.selectionHeaders,
    };
};

/**
 * Gets participant name from event.
 *
 * @function getParticipantName
 *
 * @param {object} event - Event object.
 * @param {number} index - Participant index in participants list.
 *
 * @returns {string} Participant name.
 *
 * @memberof Mojito.Modules.MarketHelper
 */
export const getParticipantName = (event, index) => {
    const participant = event.participants && event.participants[index];
    return participant ? participant.name : '';
};

const resolveHeadersForParticipants = (descriptor, event, stringResolver, shouldShowDrawHeader) => {
    if (!event) {
        return [];
    }
    const { selectionHeadersMap = {}, selectionTypes = [] } = descriptor;
    const isThreeWay = getLayoutType(selectionTypes) === LAYOUT_TYPE.THREE_WAY;
    // Note: US sports participants are switched in events slice once received from content so participant[0] will in that case
    // be the away team. The result is that the code below will map '0' to the away team for US Sports.
    // This is the intended behavior.
    const firstParticipant = getParticipantName(event, 0);
    const draw =
        isThreeWay &&
        shouldShowDrawHeader &&
        stringResolver.resolveString(selectionHeadersMap[selectionTypes[1]]);
    const secondParticipant = getParticipantName(event, 1);
    return [firstParticipant, draw, secondParticipant].filter(isString);
};

const generateLine = (selections, groupingMask, matcher) => {
    const bogusGenerator = bogusSelectionGenerator();
    return groupingMask.map(criterion => {
        const { match, propName } = matcher;
        const bogusProto = { [propName]: criterion };
        return resolveSelections(selections, bogusGenerator(bogusProto), sel =>
            match(criterion, sel)
        );
    });
};

const resolveSelections = (selections, generateBogus, predicate) => {
    const foundSelections = selections.filter(predicate);
    foundSelections.sort(SelectionHelper.compareBySortOrder);
    return foundSelections.length ? foundSelections : [generateBogus()];
};

const isHomeAwayLine = (layoutType, groupsLine) => {
    const isTwoWayOrThreeWay =
        layoutType === LAYOUT_TYPE.TWO_WAY || layoutType === LAYOUT_TYPE.THREE_WAY;
    if (isTwoWayOrThreeWay) {
        const isHomeType = groupHasType(groupsLine, 0, HOME_TYPES);
        const isAwayType = groupHasType(groupsLine, groupsLine.length - 1, AWAY_TYPES);
        return isHomeType || isAwayType;
    }
    return false;
};

const isApplicableMask = (groupingMask, selections, matcher, strictGrouping) => {
    if (isEmpty(groupingMask)) {
        return false;
    }
    const { match } = matcher;
    return strictGrouping
        ? selections.every(sel => groupingMask.find(criterion => match(criterion, sel)))
        : true;
};

/**
 * This method swaps places of home/away selection groups.
 * This is typically needed for american sports where away participant is shown as first one and home participant as last one.
 * To make coupons look consistent the selections are also swapped within markets respectively.
 * E.g., If market has selections with types [H, A], this method will swap them to be [A, H].
 * For three way markets: [H, A, X] -> [A, H, X]. The draw selection place always stays untouched.
 * Note: this method mutates groupsLine parameter.
 *
 * @function swapHomeAwayGroups
 * @param {Array<Array<object>>} groupsLine - Groups line. List of selection groups. Each selections group is a list of selections.
 *
 * @returns {Array<Array<object>>} List of selection groups. Each selections group is a list of selections.
 * @private
 *
 * @memberof Mojito.Modules.MarketHelper
 */
const swapHomeAwayGroups = groupsLine => {
    const homeGroup = findGroupInTypes(HOME_TYPES, groupsLine);
    const awayGroup = findGroupInTypes(AWAY_TYPES, groupsLine);
    const homeIndex = groupsLine.indexOf(homeGroup);
    const awayIndex = groupsLine.indexOf(awayGroup);
    groupsLine[awayIndex] = homeGroup;
    groupsLine[homeIndex] = awayGroup;
    return groupsLine;
};

const findGroupInTypes = (types, groupsLine) => {
    let result;
    types.some(type => {
        result = findGroupByType(groupsLine, type);
        return !!result;
    });
    return result;
};

/**
 * Generator that is used to create bogus selection objects.
 * This kind of objects will be created in place of missing selections in order to keep
 * integrity in selections line. E.g., Match betting market has selections mask: [1, X, 2].
 * If 'X' selection is missing from content it will be replaced with BOGUS selection with type: 'X'.
 *
 * @function bogusSelectionGenerator
 *
 * @returns {Function} Generator function.
 * @private
 *
 * @memberof Mojito.Modules.MarketHelper
 */
const bogusSelectionGenerator = () => {
    let index = 0;
    return (bogusProto = {}) => {
        return () => {
            index++;
            return { id: `BOGUS-${index}`, type: UNKNOWN, ...bogusProto };
        };
    };
};

const groupHasType = (groups, index, types) => {
    const group = groups[index] || [];
    return group.some(selection => types.includes(selection.type));
};

const findGroupByType = (groups, type) =>
    groups.find(selectionsGroup => selectionsGroup.some(selection => selection.type === type));

const getLayoutType = selectionTypes => (isEmpty(selectionTypes) ? 0 : selectionTypes.length);

/**
 * Matcher object that contains match function and selection property name
 * which value will be used during comparison.
 *
 * @typedef SelectionMatcher
 *
 * @property {Mojito.Modules.MarketHelper.selectionMatches} match - Selection match function.
 * @property {string} propName - Name of the selection property, e.g., `type`, `name` etc.
 *
 * @memberof Mojito.Modules.MarketHelper
 */

/**
 * Function that checks if selection matches criteria.
 *
 * @callback selectionMatches
 *
 * @property {*} groupingCriterion - Value that defines criteria which needs to be fulfilled by selection to be added to the group.
 * @property {object} selection - Selection data object.
 * @returns {boolean} True if selection matches criteria, false otherwise.
 *
 * @memberof Mojito.Modules.MarketHelper
 */

export default {
    resolveSelectionsHeaders,
    resolveSelectionGroupsByName,
    resolveSelectionGroupsByType,
    resolveSelectionGroups,
    splitIntoGroups,
    getParticipantName,
};
