import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import FutureRacePageImpl from './future-race-page.jsx';

/**
 * @namespace FutureRacePage
 * @memberof Mojito.Application.Pages
 */

/**
 * A page dedicated to displaying the future racing events for a specific racing event.
 *
 * @class FutureRacePage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Application.Pages.FutureRacePage
 */

export default UIPage('FutureRacePage', FutureRacePageImpl);
