import MojitoCore from 'mojito/core';
import { noop, debounce } from 'mojito/utils';
import { ruleTemplates } from './scroll-pane-style';

const cssRuleManager = MojitoCore.Presentation.cssRuleManager;
const log = MojitoCore.logger.get('ScrollPane');
const { DESKTOP } = MojitoCore.Services.SystemSettings.types.APPLICATION_MODE;
const classUtils = MojitoCore.Base.classUtils;
const { resizeDetector } = MojitoCore.Base.resizeDetector;

const clamp = (val, minVal, maxVal) => Math.max(Math.min(val, maxVal), minVal);

export const onResizeNoop = noop;
export default class ScrollPane extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);

        if (!['x', 'y'].includes(this.config.direction)) {
            throw new Error('ScrollPane.direction is required');
        }
        this.activeScrollingToPosition = undefined;
        this.onScrollFinished = debounce(this.onScrollFinished.bind(this), 100);
        this.mouseDragScroll =
            this.config.mouseDragScroll &&
            this.appContext().systemSettings().applicationMode === DESKTOP;

        // Added debounce for better performance and to avoid scroll jitter, we only need the last call while scrolling.
        this.onScroll = debounce(this.onScroll.bind(this), 300);
        this.onResize = debounce(this.onResize.bind(this), 300);
        this.onContainerRefChanged = this.onContainerRefChanged.bind(this);

        this.shouldHideScrollBar() && this.initializeClasses();
    }

    shouldHideScrollBar() {
        const { direction, scrollBarYDirectionOff, scrollBarXDirectionOff } = this.config;

        if (direction === 'x') {
            return scrollBarXDirectionOff;
        }
        return scrollBarYDirectionOff;
    }

    initializeClasses() {
        this.classUUID = classUtils.createClassUUID('scrollBar-hide');
        const cssVariables = { '%class%': this.classUUID };
        const cssRules = cssRuleManager.buildRules(cssVariables, ruleTemplates);
        cssRuleManager.addRules(this.instanceId, this.classUUID, cssRules);
    }

    shouldListenToSizeChanges() {
        // For performance reasons, only listen to size changes if there is an
        // explicitly set onResize callback function.
        return this.props.onResize !== onResizeNoop;
    }

    componentDidMount() {
        if (this.shouldListenToSizeChanges()) {
            resizeDetector.listen(this.containerEl, this.onResize);
        }

        if (this.mouseDragScroll) {
            this.onMouseDown = ev => {
                if (!this.containerEl.contains(ev.target)) {
                    return;
                }
                this.prevMousePosition = { x: ev.screenX, y: ev.screenY };
                window.addEventListener('mousemove', this.onMouseMove);
            };

            this.onMouseMove = ev => {
                const { direction } = this.config;
                const mousePosition = { x: ev.screenX, y: ev.screenY };
                const scrollPropName = { x: 'scrollLeft', y: 'scrollTop' }[direction];
                this.containerEl[scrollPropName] +=
                    this.prevMousePosition[direction] - mousePosition[direction];
                this.prevMousePosition = mousePosition;
            };

            this.onMouseUp = () => window.removeEventListener('mousemove', this.onMouseMove);

            window.addEventListener('mousedown', this.onMouseDown);
            window.addEventListener('mouseup', this.onMouseUp);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { targetPosition, onScroll } = this.props;
        const { viewportSize, scrollSize } = this.state;

        if (targetPosition === undefined && this.currentScrollAnimation) {
            cancelAnimationFrame(this.currentScrollAnimation);
        }

        if (targetPosition !== prevProps.targetPosition && targetPosition !== undefined) {
            this.scrollTo(targetPosition);
        }

        const viewSizeChanged =
            prevState && 'viewportSize' in prevState && prevState.viewportSize !== viewportSize;
        const scrollSizeChanged =
            prevState && 'scrollSize' in prevState && prevState.scrollSize !== scrollSize;

        if (viewSizeChanged || scrollSizeChanged) {
            this.props.onResize(this.getContainerInfo());
        }

        if (onScroll !== prevProps.onScroll) {
            log.warn('ScrollPane does not support dynamically switching onScroll callback');
        }
    }

    componentWillUnmount() {
        this.onScrollFinished.cancel();
        resizeDetector.unlisten(this.containerEl);
        this.currentScrollAnimation && cancelAnimationFrame(this.currentScrollAnimation);

        if (this.mouseDragScroll) {
            window.removeEventListener('mousedown', this.onMouseDown);
            window.removeEventListener('mousemove', this.onMouseMove);
            window.removeEventListener('mouseup', this.onMouseUp);
        }

        cssRuleManager.removeRules(this.instanceId, this.classUUID);
    }

    scrollTo(targetPosition, animate = true) {
        const { maxScrollPosition } = this.getContainerInfo();
        targetPosition = clamp(targetPosition, 0, maxScrollPosition);
        const scrollPropName = { x: 'scrollLeft', y: 'scrollTop' }[this.config.direction];
        // if in the middle of scrolling process,
        // prevents unneeded scroll calculation for the same targetPosition scroll action
        if (
            this.activeScrollingToPosition !== undefined &&
            this.activeScrollingToPosition === targetPosition
        ) {
            return;
        }
        this.activeScrollingToPosition = targetPosition;

        if (!animate) {
            this.containerEl[scrollPropName] = targetPosition;
            return;
        }

        const containerSideName = { x: 'left', y: 'top' }[this.config.direction];
        this.containerEl.scrollTo({
            [containerSideName]: targetPosition,
            behavior: 'smooth',
        });
    }

    getContainerInfo() {
        const [scrollPropName, clientSizePropName, scrollSizePropName] = {
            x: ['scrollLeft', 'clientWidth', 'scrollWidth'],
            y: ['scrollTop', 'clientHeight', 'scrollHeight'],
        }[this.config.direction];
        return {
            scrollPosition: this.containerEl[scrollPropName],
            maxScrollPosition:
                this.containerEl[scrollSizePropName] - this.containerEl[clientSizePropName],
            viewportSize: this.containerEl[clientSizePropName],
        };
    }

    onScroll() {
        this.onScrollFinished();
        this.props.onScroll(this.getContainerInfo());
    }

    onScrollFinished() {
        this.activeScrollingToPosition = undefined;
        this.props.onScrollFinished();
    }

    onResize() {
        const containerInfo = this.getContainerInfo();
        this.setState({
            viewportSize: containerInfo.viewportSize,
            scrollSize: containerInfo.scrollSize,
        });
    }

    onContainerRefChanged(el) {
        if (this.containerEl) {
            return;
        }

        this.containerEl = el;

        if (this.props.targetPosition !== undefined) {
            this.scrollTo(this.props.targetPosition, false);
        }

        const containerInfo = this.getContainerInfo();
        this.setState({
            viewportSize: containerInfo.viewportSize,
            scrollSize: containerInfo.scrollSize,
        });
        this.props.onInit(containerInfo, el);
    }

    isContentOverflowing() {
        return Boolean(this.containerEl) && 0 < this.getContainerInfo().maxScrollPosition;
    }

    render() {
        let { container: style } = this.style;
        if (this.mouseDragScroll && this.isContentOverflowing()) {
            style = { ...style, cursor: 'move' };
        }
        const className = classUtils.classNames(
            'ta-ScrollPane',
            this.props.class,
            this.shouldHideScrollBar() && this.classUUID
        );

        return (
            <div
                id={this.props.id}
                style={style}
                onScroll={this.onScroll}
                onTouchMove={this.props.onTouchMove}
                ref={this.onContainerRefChanged}
                className={className}
            >
                {this.props.children}
            </div>
        );
    }
}

ScrollPane.getStyle = config => {
    const { direction, nativeScroll, display, width = 'auto' } = config;
    const scrollBarStyle = nativeScroll ? 'auto' : 'hidden';
    const displaySetting = display ? { display } : {};
    return {
        container: {
            flexGrow: 1,
            overflowX: direction === 'x' ? scrollBarStyle : 'hidden',
            overflowY: direction === 'y' ? scrollBarStyle : 'hidden',
            WebkitOverflowScrolling: 'touch', // Not handled by prefixer
            ...displaySetting,
            width,
        },
    };
};
