import PropTypes from 'prop-types';
import ChangedValueIndicatorImpl from './changed-value-indicator.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View for displaying a value together with a changed value.
 * Used e.g., when displaying values that have been offered during an overask process.
 *
 * @class ChangedValueIndicator
 * @memberof Mojito.Presentation.Components
 */

/**
 * `ChangedValueIndicator` component prop types.
 *
 * @property {string|number} previousValue - Previous value.
 * @property {string|number} newValue - New value.
 * @property {string} [class] - Class name applied to the root element.
 *
 * @memberof Mojito.Presentation.Components.ChangedValueIndicator
 */
const propTypes = {
    previousValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    class: PropTypes.string,
};

export default UIView('ChangedValueIndicator', ChangedValueIndicatorImpl, propTypes);
