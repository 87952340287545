import { createContext } from 'react';

const defaultContext = { numberOfEvents: 0 };
const EventListContext = createContext(defaultContext);

/**
 * Context and provider for {@link Mojito.Modules.EventList | EventList}.
 *
 * @class EventListContext
 * @memberof Mojito.Modules.EventList
 */

/**
 * EventListContext provider.
 *
 * @function EventListProvider
 *
 * @param {object} props - The properties passed to the context provider.
 * @param {{numberOfEvents: number}} props.value - The context value.
 * @param {node|Array<node>} props.children - Children to be wrapped by context provider.
 * @returns {React.ReactElement} React element to be rendered.
 * @memberof Mojito.Modules.EventList.EventListProvider
 */
const EventListProvider = ({ value, children }) => (
    <EventListContext.Provider value={value}>{children}</EventListContext.Provider>
);

export { EventListContext, EventListProvider };
