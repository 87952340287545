import EventCardsSkeletonImpl from './event-cards-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Event cards skeleton component used to represent loading event cards.
 *
 * @function EventCardsSkeleton
 * @memberof Mojito.Modules.EventCards
 */

export default UIView('EventCardsSkeleton', EventCardsSkeletonImpl);
