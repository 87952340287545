import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import channelFactory from 'services/common/content/content-channel-factory';
import MeetingsDescriptor from './descriptors.js';
import meetingsProvider from 'services/sports-content/content-provider/meeting-provider';

const reduxInstance = MojitoCore.Services.redux;
const { actionsRegistry } = MojitoCore.Services.Content;
const { MEETING } = MeetingsDescriptor.DATA_TYPES;
const { UNKNOWN, AVAILABLE, UNAVAILABLE } = ServicesTypes.CONTENT_STATE;

export const getMeetingsChannel = () => channelFactory.getChannel(meetingsProvider, MEETING);

/**
 * Defines the state of the meetings store.
 *
 * @typedef MeetingsState
 *
 * @property {object} meetings - Map of meetings where key is meeting id and value is meeting object.
 * @property {Object<string, Mojito.Services.Common.types.CONTENT_STATE>} meetingsState - Map of meetings lifetime state. Key is meeting id and value is of type {@link Mojito.Services.SportsContent.Common.types.CONTENT_STATE|CONTENT_STATE}.
 *
 * @memberof Mojito.Services.SportsContent.Meetings
 */

/**
 * The name of the meetings state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.Meetings
 */
export const STORE_KEY = 'meetingsStore';

export const initialState = {
    meetings: {},
    meetingsState: {},
};

export const { reducer, actions } = createSlice({
    name: 'meetings',
    initialState,
    reducers: {
        updateMeeting(state, { payload }) {
            const { meetingId, meeting } = payload;

            if (meeting) {
                state.meetings[meetingId] = meeting;
                state.meetingsState[meetingId] = AVAILABLE;
            } else {
                delete state.meetings[meetingId];
                state.meetingsState[meetingId] = UNAVAILABLE;
            }
        },
        removeMeeting(state, { payload: meetingId }) {
            delete state.meetings[meetingId];
            state.meetingsState[meetingId] = UNKNOWN;
        },
        reset() {
            return { ...initialState };
        },
    },
});

/**
 * Meetings store actions.
 *
 * @class MeetingsActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.Meetings
 */

/**
 * Update meeting. Meeting will be deleted if only meetingId is provided.
 *
 * @function updateMeeting
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {object} payload - Payload for meeting update.
 * @param {string} payload.meetingId - Meeting id.
 * @param {object|undefined} [payload.meeting] - Meeting object.
 * @memberof Mojito.Services.SportsContent.Meetings.actions
 */

/**
 * Remove meeting.
 *
 * @function removeMeeting
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {string} meetingId - Meeting id.
 * @memberof Mojito.Services.SportsContent.Meetings.actions
 */

/**
 * Reset meetings state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.Meetings.actions
 */

/**
 * Subscribe to meeting.
 *
 * @function subscribe
 *
 * @param {{clientId: string, meetingId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to meeting thunk.
 * @memberof Mojito.Services.SportsContent.Meetings.actions
 */
actions.subscribe =
    ({ clientId, meetingId }) =>
    dispatch => {
        const onData = (meetingId, meeting) => {
            dispatch(actions.updateMeeting({ meetingId, meeting }));
        };
        getMeetingsChannel().subscribe([meetingId], clientId, onData);
    };

/**
 * Unsubscribe from meetings.
 *
 * @function unsubscribe
 *
 * @param {string} clientId - The clientId of subscriber to unsubscribe.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from meetings thunk.
 * @memberof Mojito.Services.SportsContent.Meetings.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getMeetingsChannel().unsubscribeAll(clientId, meetingId => {
        dispatch(actions.removeMeeting(meetingId));
    });
};

actionsRegistry.addSubscription(MEETING, actions.subscribe, actions.unsubscribe);

reduxInstance.injectReducer(STORE_KEY, reducer);
