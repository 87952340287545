import MojitoCore from 'mojito/core';
import Image from 'presentation/components/image/index.jsx';
const classUtils = MojitoCore.Base.classUtils;

export default class RotatableImage extends MojitoCore.Presentation.UIViewImplementation {
    render() {
        const style = {
            transform: `rotate(${this.props.angle})`,
            ...this.config.style,
        };

        return (
            <div
                style={style}
                className={classUtils.classNames('ta-RotatableImage', this.props.class)}
            >
                <Image config={this.config.image} />
            </div>
        );
    }
}
