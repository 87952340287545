import { Fragment } from 'react';
import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';

const { UIViewImplementation } = MojitoCore.Presentation;

export default class FixedWidthTime extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.addSeparator = this.addSeparator.bind(this);
    }

    addSeparator() {
        return this.props.outputSeparator.charAt(0);
    }

    renderDigit(digit, key) {
        return (
            <Text class={`ta-time-${key}`} config={this.config.timePart} key={key}>
                {digit}
            </Text>
        );
    }

    renderDigits(digits, index) {
        return (
            <Fragment key={index}>
                {digits.map((digit, digitIndex) =>
                    this.renderDigit(digit, `${index}-${digitIndex}`)
                )}
            </Fragment>
        );
    }

    renderTimePart(part, index) {
        if (part.length > 1) {
            const digits = [...part];
            return this.renderDigits(digits, index);
        }
        return this.renderDigit(part[0], `${index}-0`);
    }

    render() {
        const time = this.props.time.split(this.props.inputSeparator.charAt(0));

        return (
            <FlexPane
                class="ta-FixedWidthTime"
                separator={this.addSeparator}
                config={this.config.timeBox}
            >
                {time.map((part, index) => this.renderTimePart(part, index))}
            </FlexPane>
        );
    }
}
