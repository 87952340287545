import MojitoNGen from 'mojito/ngen';
const log = MojitoNGen.logger.get('TranslationService');
import { get } from 'mojito/utils';

/**
 * Translation service providing localized strings based on the configured translations.
 *
 * @class TranslationService
 * @memberof Mojito.Core.Services
 */
class TranslationService {
    /**
     * Configures the translation service with the available translations.
     * The language objects must include a property with key LANGUAGE_NATIVE_NAME.
     * Please note: If the specified configuration is invalid, the service's configuration will remain as priorly set up.
     *
     * @example <caption>Example of a translation configuration object:</caption>
     * configure({
     *   languages: {
     *      'en-US': {
     *          LANGUAGE_NATIVE_NAME: 'English'
     *      },
     *      'de': {
     *          LANGUAGE_NATIVE_NAME: 'Deutsch'
     *      }
     *   }
     * })
     *
     * @param {{}} config - The configuration object.
     *
     * @function Mojito.Core.Services.TranslationService#configure
     */
    configure(config) {
        if (!config || !config.languages) {
            log.warn('No languages configured!');
            return;
        }
        this.config = config;
    }

    /**
     * Get information about the available languages.
     * This information includes the language code and the language's native name.
     *
     * @example <caption>Example of a language information array:</caption>
     * [
     *   {
     *      languageCode: 'en-US',
     *      name: 'English'
     *   },
     *   {
     *      languageCode: 'de',
     *      name: 'Deutsch'
     *   }
     * ]
     *
     * @returns {object[]} An array of language information.
     *
     * @function Mojito.Core.Services.TranslationService#getAvailableLanguages
     */
    getAvailableLanguages() {
        // Sanity check
        if (!this.config || !this.config.languages) {
            return [];
        }

        return Object.keys(this.config.languages).map(languageCode => {
            return {
                languageCode: languageCode,
                name: this.config.languages[languageCode].LANGUAGE_NATIVE_NAME,
            };
        });
    }

    /**
     * Get the translations for the specified language.
     * <br>
     * If no language code is specified, or the specified language is not available among the configured translations then
     * empty object is returned.
     *
     * @param {string} languageCode - Language code.
     *
     * @returns {object} Object containing the translations or empty object
     * if not translations found for provided <code>languageCode</code>.
     *
     * @function Mojito.Core.Services.TranslationService#getTranslations
     */
    getTranslations(languageCode) {
        if (!languageCode) {
            return {};
        }
        return get(this, `config.languages.${languageCode}`, {});
    }

    /**
     * Check if the specified language is available among the configured languages.
     *
     * @param {string} languageCode - Language code.
     *
     * @returns {boolean} True if the language is available, otherwise false.
     * @function Mojito.Core.Services.TranslationService#isLanguageAvailable
     */
    isLanguageAvailable(languageCode) {
        return !!this.config?.languages?.[languageCode];
    }
}

export default new TranslationService();
