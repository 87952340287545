import MojitoCore from 'mojito/core';
import TaskRunner from 'services/common/task-runner/task-runner';
import BonusTypes from 'services/bonus/types.js';
import { actions as bonusCampaignActions } from 'services/bonus-campaign/slice.js';
import { actions as bonusFreeBetsActions } from './slice.js';
import PollFreeBetsTask from './task/poll-free-bets-task';

const { dispatch } = MojitoCore.Services.redux.store;
const DEFAULT_FREE_BETS_POLL_INTERVAL = 10 * 1000;

/**
 * @typedef FreeBetsDataRetrieverConfig
 * @type {object}
 * @property {Mojito.Services.Bonus.AbstractBonusService} service - Bonus service.
 * @property {number} [pollFreeBetsIntervalSec = 10] - Poll period in seconds to trigger a new free bet request. Default poll period is 10 seconds. If set to 0 the polling task will be disabled.
 * @memberof Mojito.Services.BonusFreeBets.dataRetriever
 */

/**
 * Data retriever used delegate calls to load free bets from Bonus service.
 * Will poll free bets periodically (if configured) when triggered.
 *
 * @class FreeBetsDataRetriever
 * @name dataRetriever
 * @memberof Mojito.Services.BonusFreeBets
 */
class FreeBetsDataRetriever {
    /**
     * Initialize data retriever.
     *
     * @param {Mojito.Services.BonusFreeBets.dataRetriever.FreeBetsDataRetrieverConfig} config - Config.
     * @function Mojito.Services.BonusFreeBets.dataRetriever#init
     */
    init(config) {
        if (!config) {
            return;
        }
        this.bonusService = config.service;

        if (config.pollFreeBetsIntervalSec !== 0) {
            this.pollFreeBetsInterval = config.pollFreeBetsIntervalSec
                ? config.pollFreeBetsIntervalSec * 1000
                : DEFAULT_FREE_BETS_POLL_INTERVAL;
            this.freeBetsTaskRunner = new TaskRunner(this.pollFreeBetsInterval);
        }
    }

    hasService() {
        return Boolean(this.bonusService);
    }

    hasTaskRunner() {
        return Boolean(this.freeBetsTaskRunner);
    }

    /**
     * Initiates an additional fetch request to the bonus service.
     * To create the task, you should call {@link Mojito.Services.BonusFreeBets.dataRetriever#pollFreeBets}.
     *
     * @function Mojito.Services.BonusFreeBets.dataRetriever#fetchFreeBets
     */
    fetchFreeBets() {
        if (!this.hasService() || !this.hasTaskRunner() || !this.pollFreeBetsTask) {
            return;
        }
        this.freeBetsTaskRunner.run(this.pollFreeBetsTask);
    }

    /**
     * Create polling task with currency and initiate free bets polling.
     *
     * @param {string} currency - Currency.
     * @function Mojito.Services.BonusFreeBets.dataRetriever#pollFreeBets
     */
    pollFreeBets(currency) {
        if (!this.hasService() || !this.hasTaskRunner()) {
            return;
        }
        this.pollFreeBetsTask = new PollFreeBetsTask(this.bonusService, currency);
        this.freeBetsTaskRunner.run(this.pollFreeBetsTask);
    }

    /**
     * Stops free bets polling.
     *
     * @function Mojito.Services.BonusFreeBets.dataRetriever#stopPollingFreeBets
     */
    stopPollingFreeBets() {
        if (!this.hasService() || !this.hasTaskRunner()) {
            return;
        }
        this.freeBetsTaskRunner.reset();
    }

    /**
     * Get active free bets.
     *
     * @param {string} currency - Currency.
     * @function Mojito.Services.BonusFreeBets.dataRetriever#getActiveFreeBets
     */
    getActiveFreeBets(currency) {
        if (!this.hasService()) {
            return;
        }

        this.bonusService
            .getFreeBets(currency, BonusTypes.BONUS_FILTER.ACTIVE)
            .then(freebets => dispatch(bonusFreeBetsActions.fetchActiveFreeBetsSuccess(freebets)))
            .catch(error => dispatch(bonusFreeBetsActions.fetchFreeBetsFailure(error)));
    }

    /**
     * Get historic free bets.
     *
     * @param {string} currency - Currency.
     * @function Mojito.Services.BonusFreeBets.dataRetriever#getHistoricFreeBets
     */
    getHistoricFreeBets(currency) {
        if (!this.hasService()) {
            return;
        }

        this.bonusService
            .getFreeBets(currency, BonusTypes.BONUS_FILTER.HISTORIC)
            .then(freebets => dispatch(bonusFreeBetsActions.fetchHistoricFreeBetsSuccess(freebets)))
            .catch(error => dispatch(bonusFreeBetsActions.fetchFreeBetsFailure(error)));
    }

    /**
     * Add free bet code.
     *
     * @param {string} freeBetCode - Free bet code id.
     * @function Mojito.Services.BonusFreeBets.dataRetriever#addFreeBet
     */
    addFreeBet(freeBetCode) {
        if (!this.hasService()) {
            return;
        }

        this.bonusService
            .addFreeBet(freeBetCode)
            .then(() => {
                dispatch(bonusFreeBetsActions.addFreeBetSuccess());
                dispatch(bonusCampaignActions.getCampaigns(BonusTypes.BONUS_FILTER.ACTIVE));
            })
            .catch(error => dispatch(bonusFreeBetsActions.addFreeBetFailure(error)));
    }
}

export default new FreeBetsDataRetriever();
