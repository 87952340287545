import MojitoNGen from 'mojito/ngen';
const logger = MojitoNGen.logger;

/**
 * Abstract system settings storage manager to be implemented by different integrations.
 *
 * @abstract
 * @memberof Mojito.Core.Services.SystemSettings.Storage
 */
export default class AbstractSystemSettingsStorage {
    /**
     * Set system language.
     *
     * @param {string} language - Language code.
     *
     * @function Mojito.Core.Services.SystemSettings.AbstractSystemSettingsStorage#setLanguage
     */
    // eslint-disable-next-line no-unused-vars
    setLanguage(language) {
        logger.error('setLanguage must be implemented by concrete subclass');
    }

    /**
     * Get system language.
     *
     * @returns {string|undefined} Language code.
     * @function Mojito.Core.Services.SystemSettings.Storage.AbstractSystemSettingsStorage#getLanguage
     */
    getLanguage() {
        logger.error('getLanguage must be implemented by concrete subclass');
        return undefined;
    }

    /**
     * Set system application mode.
     *
     * @param {Mojito.Core.Services.SystemSettings.types.APPLICATION_MODE} mode - Application mode.
     *
     * @function Mojito.Core.Services.SystemSettings.Storage.AbstractSystemSettingsStorage#setApplicationMode
     */
    // eslint-disable-next-line no-unused-vars
    setApplicationMode(mode) {
        logger.error('setApplicationMode must be implemented by concrete subclass');
    }

    /**
     * Get system application mode.
     *
     * @returns {Mojito.Core.Services.SystemSettings.types.APPLICATION_MODE|undefined} Application mode.
     * @function Mojito.Core.Services.SystemSettings.Storage.AbstractSystemSettingsStorage#getApplicationMode
     */
    getApplicationMode() {
        logger.error('getApplicationMode must be implemented by concrete subclass');
        return undefined;
    }
}
