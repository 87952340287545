import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import VersionedAPI from 'mojito/versioned_api';

import {ApplicationConfig} from 'core/config/application-config';
import {OPEN_EXTERNAL_URL} from 'core/utils/intent-factory';
import {addParamToURL} from 'core/utils/url-utils';
import {buildWindowFeatures} from 'core/utils/utils';

const {dispatch} = MojitoCore.Services.redux.store;
const reduxInstance = MojitoCore.Services.redux;
const IntentActions = MojitoCore.Intents.actions;
const {actions: UserInfoDataActions} = MojitoServices.UserInfo;
const {CLOSE_ACCOUNT_POPUP, HIDE_FOREIGN_WINDOW} = MojitoPresentation.Base.Intent.Types;

class PortalListener {
    constructor() {
        window.addEventListener('message', this._onIFrameMessage);

        reduxInstance.actionListener.startListening({
            actionCreator: IntentActions.publishIntent,
            effect: this.handlePublishIntent.bind(this),
        });
    }

    _onIFrameMessage(event) {
        // Portal sending us a "close" message
        if (!ApplicationConfig.initialized) return;
        if (event.data && event.data.ID === '0401') {
            dispatch(IntentActions.publishIntent(HIDE_FOREIGN_WINDOW, {type: event.data.ID}));
        }
    }

    handlePublishIntent(action) {
        const {payload: intent} = action;
        const data = intent.data || {};

        switch (intent.type) {
            case OPEN_EXTERNAL_URL: {
                let url = data.url;
                if (!url) return true;

                if (data.addBackUrl) {
                    const back_url = window.location;
                    url = addParamToURL(url, 'back_url', back_url);
                }

                if (data.versionedApiMessage) {
                    VersionedAPI.postMessage('custom-view', {url: data.url});
                    return true;
                }

                if (data.windowName !== undefined) {
                    window.open(url, data.windowName, buildWindowFeatures(data));
                } else {
                    window.location.assign(url);
                }

                return true;
            }
            case CLOSE_ACCOUNT_POPUP:
                dispatch(UserInfoDataActions.requestBalanceUpdate());
        }

        return false;
    }
}

export default new PortalListener();
