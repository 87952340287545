import PropTypes from 'prop-types';
import LinkMenuImpl from './link-menu.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

// This function is needed to validate propTypes recursively
const lazyRecursivePropTypeValidator =
    f =>
    (...args) =>
        f().apply(this, args);

/**
 * View displaying a link menu.
 *
 * @class LinkMenu
 * @memberof Mojito.Presentation.Components
 */

/**
 * `LinkMenu` component prop types.
 *
 * @property {object} [header] - The header/title of the component.
 * @property {Array} [items] - The items of the component. If an item has items, it is treated as a subheader.
 * @property {boolean} [onItemClick=()=>{}] - Callback function invoked when an item is clicked.
 * @property {string} [class] - Class name applied to the root element.
 *
 * @memberof Mojito.Presentation.Components.LinkMenu
 */
const propTypes = {
    header: PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                intent: PropTypes.object,
                icon: PropTypes.string,
                iconRenderer: PropTypes.func,
                isSelected: PropTypes.bool,
                hrefLink: PropTypes.string,
                className: PropTypes.string,
            }),
            lazyRecursivePropTypeValidator(() => PropTypes.shape(propTypes)),
        ])
    ),
    onItemClick: PropTypes.func,
    class: PropTypes.string,
};

const defaultPropTypes = {
    onItemClick: noop,
};

export default UIView('LinkMenu', LinkMenuImpl, propTypes, defaultPropTypes);
