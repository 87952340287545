import QuickLinksControllerImpl from './controller.jsx';
import MojitoCore from 'mojito/core';

/**
 * QuickLinksController module.
 *
 * @class QuickLinksController
 * @memberof Mojito.Modules.QuickLinksController
 */

export default MojitoCore.Presentation.UIControllerView(
    'QuickLinksController',
    QuickLinksControllerImpl
);
