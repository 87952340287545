import betslipDataRetriever from './data-retriever.js';
import {
    selectActiveStakeGroup,
    selectBetslip,
    selectBetslipMode,
    selectBetslipSettings,
    selectBetslipState,
    selectBetslipStatus,
    selectEnableBonusValidation,
    selectMaxSelectionsCount,
    selectReceipt,
    selectSelectionsState,
    selectState,
    selectStorageTimeInHours,
    selectValidationErrors,
} from './selectors.js';
import {
    generateMultiplierErrors,
    getRoundedStakes,
    isRelevantBetslip,
    validateStakeGroup,
    VALIDATION_RESULT,
} from './helper.js';
import BetslipTypes from './types.js';
import BetslipUtils from './utils.js';
import BetsTypes from 'services/bets/types.js';
import { isLoggedIn } from 'services/authentication/selectors.js';
import { selectOddsFormat } from 'services/user-settings/selectors.js';
import { uniqBy, xor, isEmpty } from 'mojito/utils';

export default actions => {
    const thunks = {
        /**
         * Init betslip.
         *
         * @function initBetslip
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        initBetslip: () => {
            return (dispatch, getState) => {
                const state = getState();
                const { BETSLIP_STORAGE_TYPE } = BetslipTypes;
                const { data: storedBetslip, settings: betslipSettings } =
                    betslipDataRetriever.fetchBetslipData() || {};
                const betslipState = selectBetslipState(state);
                const maxBetslipAge = selectStorageTimeInHours(state);
                const hasValidBetslipInStorage = isRelevantBetslip(storedBetslip, maxBetslipAge);

                if (!betslipState && !hasValidBetslipInStorage) {
                    dispatch(actions.reset());
                    betslipDataRetriever.cleanBetslipData(BETSLIP_STORAGE_TYPE.DATA);
                }
                dispatch(actions.initPending());
                dispatch(actions.betslipSettingsUpdate(betslipSettings));

                const enableBonusValidation = isLoggedIn(state)
                    ? selectEnableBonusValidation(state)
                    : false;
                betslipDataRetriever.setBonusValidationEnabled(enableBonusValidation);

                const bankersMode = { active: !!storedBetslip?.bankersActivated, manual: false };
                dispatch(actions.bankersModeChange(bankersMode));

                const oddsFormat = selectOddsFormat(state);
                const betslipMode = selectBetslipMode(state);
                const storedBetslipState = hasValidBetslipInStorage
                    ? storedBetslip.state
                    : undefined;
                const initBetslipState = betslipState || storedBetslipState;
                betslipDataRetriever.initBetslip(oddsFormat, betslipMode, initBetslipState);
            };
        },

        /**
         * Dispose betslip.
         *
         * @function disposeBetslip
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Dispose thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        disposeBetslip: () => {
            return dispatch => {
                betslipDataRetriever.setBonusValidationEnabled(false);
                betslipDataRetriever.stopBonusOffersRefreshBeat();
                dispatch(actions.reset());
            };
        },

        /**
         * Activate stake group.
         *
         * @function activateStakeGroup
         *
         * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Activate stake group thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        activateStakeGroup: stakeGroupName => {
            return (dispatch, getState) => {
                const { BETSLIP_MODE, STAKE_GROUP_NAME } = BetslipTypes;
                const state = getState();
                if (selectBetslipMode(state) === BETSLIP_MODE.UK) {
                    const stakeGroup = selectActiveStakeGroup(state);
                    betslipDataRetriever.removeStakeGroup(stakeGroup);
                }
                dispatch(actions.activateStakeGroupPending(stakeGroupName));
                const { MULTIPLES, SYSTEMS } = STAKE_GROUP_NAME;
                if (stakeGroupName === MULTIPLES || stakeGroupName === SYSTEMS) {
                    betslipDataRetriever.activateStakeGroupWithBonusValidation(stakeGroupName);
                } else {
                    betslipDataRetriever.activateStakeGroup(stakeGroupName);
                }
            };
        },

        /**
         * Add selection.
         *
         * @function addSelection
         *
         * @param {Mojito.Services.Betslip.types.AddSelectionPartPayload} payload - Add betslip part payload.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Add selection thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        addSelection: payload => {
            return (dispatch, getState) => {
                const state = getState();
                if (BetslipUtils.isOverask(selectBetslipStatus(state))) {
                    return;
                }
                // Happens if betslip failed and then selection is added
                if (selectBetslipState(state) === undefined) {
                    betslipDataRetriever.initBetslip(
                        selectOddsFormat(state),
                        selectBetslipMode(state)
                    );
                }
                if (BetslipUtils.isPending(selectBetslipStatus(state))) {
                    return;
                }

                const maxSelectionsCount = selectMaxSelectionsCount(state);
                const selectionsState = selectSelectionsState(state);
                const selectionsCount = Object.keys(selectionsState).length;
                const limitReached =
                    maxSelectionsCount > 0 && selectionsCount === maxSelectionsCount;
                if (limitReached) {
                    dispatch(actions.selectionsLimitBreach());
                    return;
                }

                dispatch(actions.selectionPending({ id: payload.selection.id, isPending: true }));
                dispatch(thunks.clearRecentPlacedBets());

                betslipDataRetriever.addPart(payload);
            };
        },

        /**
         * Remove selection.
         * Typically called on remove selection part from coupon.
         *
         * @function removeSelection
         *
         * @param {Mojito.Services.Betslip.types.RemoveSelectionPartPayload} payload - Remove selection part payload.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Remove selection thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        removeSelection: payload => {
            return (dispatch, getState) => {
                const betslipStatus = selectBetslipStatus(getState());
                const betslip = selectBetslip(getState());
                if (
                    BetslipUtils.isPending(betslipStatus) ||
                    BetslipUtils.isOverask(betslipStatus)
                ) {
                    return;
                }
                const { selectionId, source } = payload;
                dispatch(actions.selectionPending({ id: selectionId, isPending: true }));

                const bet = BetslipUtils.getSingleBetBySelectionId(
                    betslip,
                    selectionId,
                    legSort => legSort === BetsTypes.LEG_SORT.DEFAULT
                );
                const requestPayload = { selectionId, bet, source };
                bet && betslipDataRetriever.removePart(requestPayload);
            };
        },

        /**
         * Switch selection part in betslip.
         *
         * @function switchSelection
         *
         * @param {Mojito.Services.Betslip.types.SwitchSelectionPayload} payload - Switch part selection payload.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Switch selection part thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        switchSelection: payload => {
            return dispatch => {
                dispatch(actions.switchSelectionPending());
                betslipDataRetriever.switchPartSelection(payload);
            };
        },

        /**
         * Add composite bet to betslip.
         *
         * @function addComposite
         *
         * @param {Mojito.Services.Betslip.types.AddCompositePayload} payload - Add composite payload.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Add composite thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        addComposite: payload => {
            return (dispatch, getState) => {
                if (BetslipUtils.isOverask(selectBetslipStatus(getState()))) {
                    return;
                }
                dispatch(actions.addCompositePending(payload));
                dispatch(thunks.clearRecentPlacedBets());
                betslipDataRetriever.addComposite(payload);
            };
        },

        /**
         * Remove bet.
         * Typically called on remove bet from betslip.
         *
         * @function removeBet
         *
         * @param {{ betId: string, source: string }} payload - Remove bet payload.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Remove bet thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        removeBet: payload => {
            return (dispatch, getState) => {
                if (BetslipUtils.isOverask(selectBetslipStatus(getState()))) {
                    return;
                }

                dispatch(actions.removeBetPending());

                const { betId, source } = payload;
                const bet = BetslipUtils.getBetById(selectBetslip(getState()), betId);
                const isDefaultBet = bet.legSort === BetsTypes.LEG_SORT.DEFAULT;

                if (isDefaultBet) {
                    const { selectionId } = BetslipUtils.getFirstBetPart(bet);
                    dispatch(actions.selectionPending({ id: selectionId, isPending: true }));
                    const requestPayload = { selectionId, bet, source };
                    betslipDataRetriever.removePart(requestPayload);
                } else {
                    const selectionIds = BetslipUtils.getSelectionIdsFromBet(bet);
                    const requestPayload = { selectionIds, bet, source };
                    betslipDataRetriever.removeComposite(requestPayload);
                }
            };
        },

        /**
         * Remove selections which consists from provided part ids.
         * Typically, called on remove prebuilt match acca.
         *
         * @function removeSelectionsByParts
         *
         * @param {{ partIds: Array<string>, source: string }} payload - Remove selections payload.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Remove selections thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        removeSelectionsByParts: payload => {
            return (dispatch, getState) => {
                const { partIds, source } = payload;
                const betslip = selectBetslip(getState());
                const includesAllMathAccaParts = bet => {
                    const betSelectionIds = BetslipUtils.getSelectionIdsFromBet(bet);
                    return xor(partIds, betSelectionIds);
                };
                const matchAccaBet =
                    BetslipUtils.getMatchAccaBets(betslip).find(includesAllMathAccaParts);

                const bet = BetslipUtils.getBetById(betslip, matchAccaBet.id);
                const requestPayload = { selectionIds: partIds, bet, source };
                betslipDataRetriever.removeComposite(requestPayload);
            };
        },

        /**
         * User initiated set stake action placing something in stake input.
         * This will abort any ongoing set stake requests in a queue.
         *
         * @function prepareSetStake
         *
         * @param {string} betId - Bet id.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Prepare set stake thunk.
         *
         * @memberof Mojito.Services.Betslip.actions
         */
        prepareSetStake: betId => {
            return dispatch => {
                dispatch(actions.setStakePending());
                // This is needed to discard response from outdated set stake input.
                // This can happen if user constantly changes the stake in a very fast manner.
                betslipDataRetriever.abortSetStake(betId);
            };
        },

        /**
         * Invalidate any pending set stake actions for particular bet.
         *
         * @function invalidateSetStake
         *
         * @param {string} betId - Bet id.
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Prepare set stake thunk.
         *
         * @memberof Mojito.Services.Betslip.actions
         */
        invalidateSetStake: betId => {
            return () => betslipDataRetriever.abortSetStake(betId);
        },

        /**
         * Sets the stake.
         *
         * @function setStake
         *
         * @param {object} payload - The object containing information about the stake.
         * @param {number} payload.stake - The value of the stake.
         * @param {string} payload.betId - The identifier for the bet.
         * @param {boolean} payload.isEachWay - Flag indicating whether the stake is each way (E/W). 'True' if it is E/W, otherwise 'false'.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} The thunk function to set the stake.
         * @memberof Mojito.Services.Betslip.actions
         */
        setStake: payload => {
            return dispatch => {
                const { stake, betId, isEachWay } = payload;
                const betWay = isEachWay ? BetsTypes.BET_WAY.EACH_WAY : BetsTypes.BET_WAY.WIN;
                dispatch(actions.setStakePending());
                betslipDataRetriever.setStake({ stake, betId, betWay });
            };
        },

        /**
         * Activate teaser type. Only applicable once {@link Mojito.Services.Betslip.types.STAKE_GROUP_NAME.TEASERS|TEASERS} stake group is active.
         *
         * @function activateTeaserType
         *
         * @param {Mojito.Services.Betslip.types.TEASER_TYPE} teaserType - Teaser type.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Activate teaser type thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        activateTeaserType: teaserType => {
            return dispatch => {
                dispatch(actions.activateTeaserTypePending(teaserType));
                betslipDataRetriever.activateTeaserType(teaserType);
            };
        },

        /**
         * Execute actions.
         *
         * @function executeActions
         *
         * @param {Array<Mojito.Services.Betslip.types.Action>} actionsList - List of execute actions.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Execute actions thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        executeActions: actionsList => {
            return dispatch => {
                dispatch(actions.executeActionsPending());
                betslipDataRetriever.executeActions(actionsList);
            };
        },

        /**
         * Execute set of specific deep linking actions.
         *
         * @function executeDeepLinkingActions
         *
         * @param {object} payload - Object that contains data needed to build execute actions.
         * @param {Array<string>} payload.refs - List of bet references to add to betslip .
         * @param {number} [payload.stake] - Stake to add to betslip.
         * @param {boolean} [payload.clear] - True is betslip should be cleared before adding any bet reference.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Execute deep linking actions thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        executeDeepLinkingActions: payload => {
            return (dispatch, getState) => {
                const { refs, stake, clear } = payload;
                let betslip = selectBetslip(getState());
                const { clearAction, addPartActions } = BetslipUtils.generateDeeplinkingActions({
                    refs,
                    clear,
                });
                const populateBetslipActions = clearAction
                    ? [clearAction, ...addPartActions]
                    : addPartActions;

                const existingSelectionIds = clear
                    ? []
                    : BetslipUtils.getPartsFromSingles(betslip).map(part => part.selectionId);
                let newBetslipSingleBets = [];
                let newBets = [];

                betslipDataRetriever
                    .executeActions(populateBetslipActions)
                    .then(() => {
                        betslip = selectBetslip(getState());
                        // Here we detect which group to activate as default group detection logic is not suitable for us.
                        newBetslipSingleBets = BetslipUtils.getSingleBets(betslip);
                        newBets = newBetslipSingleBets.filter(bet =>
                            BetslipUtils.getSelectionIdsFromBet(bet).some(
                                id => !existingSelectionIds.includes(id)
                            )
                        );
                        dispatch(actions.updateDeepLinkingBets(newBets));

                        // Next we set stake and put in queue right after group activation.
                        const betToSetStake =
                            newBets.length < 2 ? newBets[0] : BetslipUtils.getMultiBet(betslip);
                        const betBuilderBetFound = BetslipUtils.isBetBuilderBet(betToSetStake);
                        const isStakeApplicable =
                            (stake &&
                                betToSetStake &&
                                betToSetStake.betType ===
                                    BetsTypes.RANKED_BY_INDEX[newBets.length]) ||
                            betBuilderBetFound;
                        return (
                            isStakeApplicable && {
                                stake,
                                betId: betToSetStake.id,
                                betWay: BetsTypes.BET_WAY.WIN,
                            }
                        );
                    })
                    .then(
                        stakePayload => stakePayload && betslipDataRetriever.setStake(stakePayload)
                    );
            };
        },

        /**
         * Place betslip.
         *
         * @function placeBetslip
         *
         * @param {object} payload - Place betslip payload.
         * @param {Mojito.Modules.Betslip.types.PRICE_CHANGE_POLICY} payload.priceChangePolicies - Price change policies.
         * @param {Mojito.Services.Authentication.types.UserInfo} payload.userInfo - User information.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Place betslip thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        placeBetslip: payload => {
            const { priceChangePolicies, userInfo } = payload;
            return (dispatch, getState) => {
                dispatch(actions.placeBetslipAttempt());
                const state = getState();
                const betslip = selectBetslip(state);
                const { OK, STAKE_MULTIPLIER_BREACH, FUNDS_BREACH } = VALIDATION_RESULT;
                const stakeGroupName = selectActiveStakeGroup(state);
                const { enableUserFundsValidation } = selectState(state);
                const validationResult = validateStakeGroup(
                    betslip,
                    stakeGroupName,
                    enableUserFundsValidation && userInfo
                );

                const applyValidationErrors = errors => {
                    const stateValidationErrors = selectValidationErrors(state);
                    const validationErrors = [...stateValidationErrors, ...errors];
                    const uniqCriterion = error => `${error.code}_${error.factor}`;
                    dispatch(
                        actions.betslipValidationFailed(uniqBy(validationErrors, uniqCriterion))
                    );
                };

                switch (validationResult) {
                    case OK: {
                        dispatch(actions.placeBetslipPending());
                        betslipDataRetriever.placeBetslip(
                            stakeGroupName,
                            priceChangePolicies,
                            userInfo.currency
                        );
                        break;
                    }
                    case STAKE_MULTIPLIER_BREACH: {
                        const stakeUpdates = getRoundedStakes(betslip, stakeGroupName);
                        stakeUpdates.forEach(stakeUpdate =>
                            betslipDataRetriever.setStake(stakeUpdate)
                        );
                        const updatedBetIds = stakeUpdates.map(stakeUpdate => stakeUpdate.betId);
                        const errors = generateMultiplierErrors(betslip, updatedBetIds);
                        applyValidationErrors(errors);
                        break;
                    }
                    case FUNDS_BREACH: {
                        const fundsBreachError = {
                            code: BetslipTypes.ERROR_CODE.INSUFFICIENT_FUNDS,
                        };
                        applyValidationErrors([fundsBreachError]);
                        break;
                    }
                }
            };
        },

        /**
         * Clear recently placed bets.
         *
         * @function clearRecentPlacedBets
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Clear recently placed bets thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        clearRecentPlacedBets: () => {
            return (dispatch, getState) => {
                const state = getState();
                if (selectReceipt(state)) {
                    const retainParts = [BetslipTypes.BETSLIP_PART.SETTINGS];
                    betslipDataRetriever.clearBetslip(retainParts);
                }
            };
        },

        /**
         * Bankers mode activation toggle.
         *
         * @function toggleBankersMode
         *
         * @param {boolean} active - Flag indicating if bankers mode should be active or not.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Set bankers mode thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        toggleBankersMode: active => {
            return dispatch => {
                dispatch(actions.bankersModeChange({ active, manual: true }));
                const { STAKE_GROUP_NAME, BETSLIP_STORAGE_TYPE } = BetslipTypes;
                const { BANKERS, SYSTEMS } = STAKE_GROUP_NAME;
                // Clear bankers selections when disabling.
                !active && betslipDataRetriever.removeStakeGroup(BANKERS);
                betslipDataRetriever.storeBetslipData(
                    { bankersActivated: active },
                    BETSLIP_STORAGE_TYPE.DATA
                );
                dispatch(thunks.activateStakeGroup(active ? BANKERS : SYSTEMS));
            };
        },

        /**
         * Toggle bet to be banker.
         *
         * @function toggleBanker
         *
         * @param {string} payload - Bet id.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Toggle banker thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        toggleBanker: payload => {
            const betId = payload;
            return dispatch => {
                dispatch(actions.toggleBankerPending(betId));
                betslipDataRetriever.toggleBanker(betId);
            };
        },

        /**
         * Clear betslip.
         *
         * @function clearBetslip
         *
         * @param {Array<Mojito.Services.Betslip.types.BETSLIP_PART>} retainParts - List of retain parts.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Clear betslip thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        clearBetslip: retainParts => {
            return dispatch => {
                dispatch(actions.clearBetslipPending());
                betslipDataRetriever.clearBetslip(retainParts);
                dispatch(actions.bankersModeChange({ active: false }));
            };
        },

        /**
         * Toggle free bet.
         *
         * @function toggleFreeBet
         *
         * @param {object} payload - Toggle free bet payload.
         * @param {string} payload.token - Free bet token.
         * @param {string} payload.name - Free bet name.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Toggle free bet thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        toggleFreeBet: payload => {
            return (dispatch, getState) => {
                const { token, name } = payload;
                const betslip = selectBetslip(getState());
                const shouldRemove = BetslipUtils.hasFreeBet(betslip, token);
                betslipDataRetriever.toggleFreeBet(token, name, shouldRemove);
            };
        },

        /**
         * Update overask.
         *
         * @function overaskUpdate
         *
         * @param {Mojito.Services.Betslip.types.OveraskUpdatePayload} payload - Overask update data.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Overask update thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        overaskUpdate: payload => {
            return dispatch => {
                dispatch(actions.overaskUpdatePending());
                betslipDataRetriever.updateOverask(payload);
            };
        },

        /**
         * Request overask status.
         *
         * @function requestOveraskStatus
         *
         * @param {number} delay - Time in ms to delay status request.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Overask status thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        requestOveraskStatus: delay => {
            return () => {
                betslipDataRetriever.requestOveraskStatus(delay);
            };
        },

        /**
         * Discard overask status.
         *
         * @function discardOveraskStatus
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Discard overask status thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        discardOveraskStatus: () => {
            return () => {
                betslipDataRetriever.discardOveraskStatus();
            };
        },

        /**
         * Handles bonus offers refresh for logged in user.
         * The refresh beat will be started if betslip status is {@link Mojito.Services.Betslip.types.BETSLIP_STATUS.OPEN|OPEN}, betslip exists and user is logged in.
         * Otherwise, it will be stopped.
         *
         * @function syncBonusOffers
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Bonus offers refresh thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        syncBonusOffers: () => {
            return (dispatch, getState) => {
                const state = getState();
                const betslip = selectBetslip(state);
                const betslipStatus = selectBetslipStatus(state);
                // We will stop bonus offers refresh if betslip is not in OPEN state for three reasons:
                // 1. Due to backend limitation in sending proper response once there is receipt or overask;
                // 2. User is logged out since offers refresh only make sense if user is logged in;
                // 3. Because there is no practical reason for that in this case;
                if (!isLoggedIn(state) || !BetslipUtils.isOpen(betslipStatus) || isEmpty(betslip)) {
                    betslipDataRetriever.stopBonusOffersRefreshBeat();
                } else if (!betslipDataRetriever.isBonusOffersRefreshing()) {
                    betslipDataRetriever.startBonusOffersRefreshBeat();
                }
            };
        },

        /**
         * Betslip settings change.
         *
         * @function betslipSettingsChange
         *
         * @param {Mojito.Services.Betslip.types.BetslipSettings} settings - Betslip settings.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Change betslip settings thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        betslipSettingsChange: settings => {
            return (dispatch, getState) => {
                const { BETSLIP_STORAGE_TYPE } = BetslipTypes;
                dispatch(actions.betslipSettingsUpdate(settings));
                // In reducer we merge settings with the existing in state. We will take merged result to save in storage.
                const settingsInStore = selectBetslipSettings(getState());
                betslipDataRetriever.storeBetslipData(
                    settingsInStore,
                    BETSLIP_STORAGE_TYPE.SETTINGS
                );
            };
        },

        /**
         * Betslip update part thunk.
         *
         * @function updatePart
         *
         * @param {Array<Mojito.Services.Betslip.types.Selection>} payload - Selections with betRefs to update in betslip.
         *
         * @returns {Mojito.Core.Services.redux.ThunkFunction} Update betslip part thunk.
         * @memberof Mojito.Services.Betslip.actions
         */
        updatePart: payload => {
            return dispatch => {
                const { betRef, betId } = payload;
                dispatch(actions.discardChanges(betId));
                betslipDataRetriever.updatePart([{ betRef }]);
            };
        },
    };
    return thunks;
};
