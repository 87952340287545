import MojitoCore from 'mojito/core';
import AbstractTask from 'services/common/task-runner/abstract-task';
import { actions as bonusFreeBetsActions } from 'services/bonus-free-bets/slice.js';
import BonusTypes from 'services/bonus/types.js';

const { dispatch } = MojitoCore.Services.redux.store;

/**
 * Task that retrieves active free bets.
 *
 * @class PollFreeBetsTask
 * @memberof Mojito.Services.BonusFreeBets
 */
export default class PollFreeBetsTask extends AbstractTask {
    constructor(service, currency) {
        super();
        this.service = service;
        this.currency = currency;
    }

    execute(callback) {
        this.service
            .getFreeBets(this.currency, BonusTypes.BONUS_FILTER.ACTIVE)
            .then(freebets => {
                dispatch(bonusFreeBetsActions.fetchActiveFreeBetsSuccess(freebets));
                callback();
            })
            .catch(error => {
                dispatch(bonusFreeBetsActions.fetchFreeBetsFailure(error));
                callback();
            });
    }
}
