import ReactDOM from 'react-dom';
import { createRef } from 'react';
import MojitoCore from 'mojito/core';
import { Transition } from 'react-transition-group';

const { flatMerge } = MojitoCore.Base.objUtils;
const { UIViewImplementation, bodyScrollManager } = MojitoCore.Presentation;
const log = MojitoCore.logger.get('Overlay');

export default class Overlay extends UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.state = {
            hasRenderedOverlayEl: false,
        };
        const { rootElementId } = this.config;
        const foundRootElement = document.getElementById(rootElementId);
        if (!foundRootElement) {
            rootElementId &&
                log.warn(
                    `DOM element with id: ${rootElementId} is not found. Empty div placeholder will be appended to the body.`
                );
            // If no root element configured or element has not been found in DOM, we will spawn empty div and mount into it.
            // We will remove it on component unmount.
            this.spawnElement = document.createElement('div');
        }
        this.rootEl = foundRootElement || this.spawnElement;
        this.transitionRef = createRef();
        this.rAf = undefined;
        this.renderOverlay = this.renderOverlay.bind(this);
        this.onOverlayRefChanged = this.onOverlayRefChanged.bind(this);
        this.onOverlayRendered = () => this.setState({ hasRenderedOverlayEl: true });
    }

    componentDidMount() {
        // We append portal root element immediately after mount unless there is
        // explicit setting `mountContainerOnShow === true`, in that case it will be appended only when component becomes visible.
        // We also need to append it if component was created with `visible === true` initially.
        if (!this.config.mountContainerOnShow || this.props.visible) {
            this.appendHolderElement();
            if (this.props.visible) {
                this.config.disableBodyScrolling &&
                    bodyScrollManager.disableBodyScrolling(this.instanceId);

                if (this.config.resetScroll) {
                    bodyScrollManager.setLastScrollPosition(0, 0);
                }
            }
        }
    }

    componentWillUnmount() {
        this.removeHolderElement();
        bodyScrollManager.enableBodyScrolling(this.instanceId);
        this.rAf && cancelAnimationFrame(this.rAf);
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible) {
            if (this.props.visible) {
                // Ensure the body cannot scroll when a portal/overlay is active. (iOS issue)
                this.config.disableBodyScrolling &&
                    bodyScrollManager.disableBodyScrolling(this.instanceId);
                if (this.config.mountContainerOnShow) {
                    this.appendHolderElement();
                }
            } else {
                bodyScrollManager.enableBodyScrolling(this.instanceId);
            }
        }
    }

    onOverlayRefChanged(el) {
        this.overlayEl = el;
        if (!el) {
            this.setState({ hasRenderedOverlayEl: false });
        }
    }

    appendHolderElement() {
        this.spawnElement && document.body.appendChild(this.spawnElement);
    }

    removeHolderElement() {
        if (this.spawnElement && document.body.contains(this.spawnElement)) {
            document.body.removeChild(this.spawnElement);
        }
    }

    renderOverlay(state) {
        const currentState =
            this.overlayEl && this.state.hasRenderedOverlayEl ? state : 'unmounted';
        const style = this.style.overlay[currentState];

        if (this.overlayEl && !this.state.hasRenderedOverlayEl) {
            // We have to make sure that the initial (unmounted) style has been rendered to the DOM before we can move
            // on to the next transition state, otherwise the CSS transition might not be triggered.
            this.overlayEl.offsetHeight; // NOSONAR - Force a reflow (i.e. "render")
            this.rAf = requestAnimationFrame(this.onOverlayRendered);
        }

        const container = this.style.container[currentState];

        return (
            <div
                style={container}
                role={'overlay'}
                className="ta-Overlay"
                onClick={this.props.onClicked}
            >
                <div style={style} ref={this.onOverlayRefChanged} />
                {this.renderChildren(currentState)}
            </div>
        );
    }

    renderChildren(state) {
        if (this.config.mountChildrenOnInit) {
            return this.props.children;
        }

        return state === 'unmounted' ? null : this.props.children;
    }

    render() {
        return ReactDOM.createPortal(
            <Transition
                nodeRef={this.transitionRef}
                in={this.props.visible}
                timeout={this.config.style.overlay.animationDuration * 1000}
                appear
                mountOnEnter={!this.config.mountOnInit}
                unmountOnExit={this.config.unmountOnHide}
            >
                {this.renderOverlay}
            </Transition>,
            this.rootEl
        );
    }
}

Overlay.getStyle = function (config) {
    const { style } = config;

    const overlayBase = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        willChange: 'opacity',
        transition: `opacity ${config.style.overlay.animationDuration}s`,
        ...config.style.overlay.style,
    };

    const containerBase = flatMerge(
        {
            display: 'flex',
            top: '0px',
            left: '0px',
            userSelect: 'none',
            touchAction: 'none',
        },
        style.container
    );

    const overlayVisible = { opacity: 1, ...overlayBase };
    const overlayHidden = { opacity: 0, ...overlayBase };

    const containerVisible = {
        position: 'fixed',
        width: '100vw',
        height: '100%',
        ...containerBase,
    };
    // Once hidden ensure to move container out from the normal document flow
    const containerHidden = { position: 'absolute', width: '0', height: '0', ...containerBase };

    return {
        overlay: {
            unmounted: overlayHidden,
            entering: overlayVisible,
            entered: overlayVisible,
            exiting: overlayHidden,
            exited: overlayHidden,
        },
        container: {
            unmounted: containerHidden,
            entering: containerVisible,
            entered: containerVisible,
            exiting: containerVisible,
            exited: containerHidden,
        },
    };
};
