import PropTypes from 'prop-types';
import ImageImpl from './image.jsx';
import MojitoCore from 'mojito/core';

/**
 * The Image component renders an image from either a direct image reference (e.g., logo.png), or an SVG sprite image id.
 * <br>
 * The rendering element type depends on the `src` property string value. If the `src` value is a file path ending with
 * one of the supported file format suffixes (*), the image is rendered in an `<img>` tag. If not, the `src` value is
 * considered an SVG sprite ID, and an SVG tag is rendered.
 * The SVG tag includes a `use` tag pointing to an SVG sprite map whose ID matches the `src` value.
 * <br>
 * Note that SVG images bundled in an SVG sprite map can have non-functional internal references.
 * As a result, certain SVG features (like gradients or use tags) may not work with SVG sprite images.
 * <br>
 * (*) Supported image formats: PNG, JPEG, GIF, and SVG.
 *
 * @class Image
 * @memberof Mojito.Presentation.Components
 */

/**
 * `Image` component prop types.
 *
 * @property {string} [src] - Path to image resource or the sprite's id within the spritemap.
 * @property {string} [fallbackSrc] - Alternative source, used either if no 'src' value can be found, or if the resource pointed to by 'src' could not be found.
 * @property {string} [title] - String or string key that, if present, will be used as a title for the image element.
 * @property {string} [fill] - Fill color, only applicable to SVG sprite images.
 * @property {string} [stroke] - Stroke color, only applicable to SVG sprite images.
 * @property {string} [class] - Class name applied to the root image element.
 * @property {Function} [onLoad] - Callback triggered on img load event.
 * @property {Function} [onError] - Callback triggered on img load error.
 *
 * @memberof Mojito.Presentation.Components.Image
 */
const propTypes = {
    src: PropTypes.string,
    fallbackSrc: PropTypes.string,
    title: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
    class: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};

export default MojitoCore.Presentation.UIView('Image', ImageImpl, propTypes);
