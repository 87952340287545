import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import {
    useMarketsLoadDone,
    useEventsLoadDone,
    useEventItem,
    useMarketItems,
} from 'modules/common/hooks';
import EventListItemDetails from './details/index.jsx';
import GenericMarketContent from 'modules/aggregated-market-pane/generic/index.jsx';
import GameLineMarketContent from 'modules/aggregated-market-pane/game-line/index.jsx';
import EventListMarketsSection from 'modules/event-list/event-list-markets-section.jsx';
import { useMarketInfos } from 'modules/event-list/hooks/index.js';
import { isEmpty } from 'mojito/utils';
import { useMemo, memo, useEffect } from 'react';

const { FlexPane, EventTimingStatus, ImageButton, Text, Button } = MojitoPresentation.Components;
const { utils: EventUtils } = MojitoServices.SportsContent.Events;
const { types: MarketGroupTypes } = MojitoServices.SportsContent.MarketGroups;
const { EventFormatter } = MojitoPresentation.Utils;
const { createAggregatedMarket, isThreeWayMarket } = EventUtils;
const { AGGREGATED_MARKET_TYPE } = MarketGroupTypes;

const isSingleMarketView = marketInfos => marketInfos?.length === 1;

const EventListItem = memo(function EventListItem(props) {
    const {
        mojitoTools: { config, appContext },
        eventId,
        marketOptions,
        isGameLineMode,
        onClick,
        onDataLoad,
        shouldRequestData,
    } = props;

    const eventItem = useEventItem(eventId, shouldRequestData);
    // Market lines can come as a prop if we are rendering CMS coupon in all other cases it will be taken from event item itself.
    const marketLines = isEmpty(props.marketLines) ? eventItem?.marketLines : props.marketLines;
    const marketInfos = useMarketInfos(marketOptions, marketLines, isGameLineMode);
    const marketIds = useMemo(
        () => marketInfos.flatMap(info => info.marketIds).filter(Boolean),
        [marketInfos]
    );
    const markets = useMarketItems(eventId, marketIds, shouldRequestData);

    // Determining child modules loading state
    const eventLoaded = useEventsLoadDone([eventId]);
    const dataLoaded = useMarketsLoadDone(marketIds) || (marketIds.length === 0 && eventLoaded);

    useEffect(() => {
        if (dataLoaded && onDataLoad) {
            onDataLoad(eventId);
        }
    }, [onDataLoad, dataLoaded, eventId]);

    if (!eventItem) {
        return null;
    }

    const onItemClick = () => {
        const { canonicalName: name, eventType, sportId, id } = eventItem;
        appContext.analyticsEmitter.emitAnalytics('navigationEvent', {
            name,
            type: eventType,
            sport: sportId,
        });
        onClick(id, eventItem);
    };

    const isMatchEvent = EventUtils.isMatchEventType(eventItem.eventType);

    return (
        <FlexPane class="ta-EventListItem" config={config.container}>
            {isMatchEvent && (
                <FlexPane config={config.matchDetailsContainer}>
                    <EventTimingStatus
                        eventItem={eventItem}
                        config={config.eventDetailsRow}
                        onClick={onItemClick}
                    />
                    {!isGameLineMode && isSingleMarketView(marketInfos) && (
                        <FallbackMarket
                            marketInfo={marketInfos[0]}
                            markets={markets}
                            config={config}
                            sportId={eventItem.sportId}
                        />
                    )}
                </FlexPane>
            )}
            <FlexPane config={config.detailsContainer}>
                {isMatchEvent ? (
                    <MatchEventContent
                        {...props}
                        eventItem={eventItem}
                        markets={markets}
                        marketInfos={marketInfos}
                        onItemClick={onItemClick}
                    />
                ) : (
                    <OutrightEventContent
                        {...props}
                        eventItem={eventItem}
                        onItemClick={onItemClick}
                    />
                )}
            </FlexPane>
            {isMatchEvent && (
                <ImageButton
                    config={config.marketCountButton}
                    label={`${eventItem.numberOfMarkets}`}
                    onClick={onItemClick}
                />
            )}
        </FlexPane>
    );
});

function FallbackMarket(props) {
    const { marketInfo, markets, config, sportId } = props;

    const fallbackMarketId = marketInfo.fallbackMarketIds?.[0];
    const fallbackMarket = markets.find(({ id }) => id === fallbackMarketId);

    if (!fallbackMarket) {
        return null;
    }

    const { name, ending } = EventFormatter.splitByEndingDelimiter(
        fallbackMarket.name,
        config.fallbackMarketNameDelimiter
    );

    return (
        <EventListMarketsSection config={config.fallbackMarketNameContainer} sportId={sportId}>
            <Text config={config.fallbackMarketName}>{name}</Text>
            {ending && <Text config={config.fallbackMarketNameEndingInfo}>{ending}</Text>}
        </EventListMarketsSection>
    );
}

function MatchEventContent(props) {
    const { eventItem, onItemClick, isGameLineMode, markets, marketInfos, hrefLink, mojitoTools } =
        props;

    const { config, stringResolver } = mojitoTools;

    return (
        <>
            <EventListItemDetails
                config={isGameLineMode ? config.itemDetailsGameLine : config.itemDetails}
                event={eventItem}
                cbClick={onItemClick}
                hrefLink={hrefLink}
                drawLabelText={resolveDrawLabel(isGameLineMode, markets, stringResolver)}
            />
            <EventListMarketsSection
                class="ta-EventMarketsContainer"
                config={config.marketsSectionContainer}
                sportId={eventItem.sportId}
            >
                {isEmpty(markets) &&
                eventItem.numberOfMarkets > 0 &&
                !config.hideMoreMarketsButton ? (
                    <ImageButton
                        config={config.moreMarketsButton}
                        label={resolveMoreMarketsLabel(eventItem.numberOfMarkets, stringResolver)}
                        onClick={onItemClick}
                    />
                ) : (
                    <MarketsContent
                        mojitoTools={mojitoTools}
                        eventItem={eventItem}
                        markets={markets}
                        marketInfos={marketInfos}
                        isGameLineMode={isGameLineMode}
                    />
                )}
            </EventListMarketsSection>
        </>
    );
}

function OutrightEventContent(props) {
    const {
        eventItem,
        onItemClick,
        hrefLink,
        mojitoTools: { config, stringResolver },
    } = props;
    return (
        <Button config={config.outrightClickableArea} onClick={onItemClick} hrefLink={hrefLink}>
            <Text config={config.eventNameLabel}>{eventItem.name}</Text>
            <ImageButton
                config={config.moreMarketsButton}
                label={stringResolver.resolveAndFormatString(
                    '$EVENT_LIST_ITEM.MORE_OUTRIGHT_MARKETS',
                    eventItem.numberOfMarkets
                )}
            />
        </Button>
    );
}

function MarketsContent({
    isGameLineMode,
    mojitoTools: { config, style },
    eventItem,
    marketInfos,
    markets,
}) {
    return isGameLineMode ? (
        <GameLineMarketContent
            config={config.gameLineMarketContent}
            eventItem={eventItem}
            aggregatedMarket={createAggregatedMarket(findGameLineInfo(marketInfos), markets)}
        />
    ) : (
        marketInfos.map(marketInfo => (
            <GenericMarketContent
                key={marketInfo.id}
                config={
                    isSingleMarketView(marketInfos) && hasFallbackMarket(marketInfo)
                        ? style.fallbackMarketContent
                        : config.genericMarketContent
                }
                eventItem={eventItem}
                showEachWayTerms={false}
                showSelectionHeaders={false}
                aggregatedMarket={createAggregatedMarket(marketInfo, markets)}
            />
        ))
    );
}

const hasFallbackMarket = marketInfo => !isEmpty(marketInfo.fallbackMarketIds);

const findGameLineInfo = marketInfos =>
    marketInfos.find(info => info.type === AGGREGATED_MARKET_TYPE.GAME_LINE);

const resolveMoreMarketsLabel = (numberOfMarkets, stringResolver) => {
    const l10nKey =
        numberOfMarkets === 1
            ? '$EVENT_LIST_ITEM.ONE_MORE_MARKET'
            : '$EVENT_LIST_ITEM.MORE_MARKETS';
    return stringResolver.resolveAndFormatString(l10nKey, numberOfMarkets);
};

const resolveDrawLabel = (isGameLineMode, markets, stringResolver) => {
    const hasThreeWayMarket = markets?.some(isThreeWayMarket);
    return isGameLineMode && hasThreeWayMarket
        ? stringResolver.resolveString('$EVENT_LIST_ITEM.DRAW_LABEL')
        : undefined;
};

EventListItem.getStyle = (config, applicationMode, merge) => ({
    fallbackMarketContent: merge(config.genericMarketContent, config.fallbackMarketContent),
});

export default EventListItem;
