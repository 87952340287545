export const INTENT_TYPES = {
    PROXY_SHOW_LOGIN: 'DBX_SHOW_LOGIN',
    PROXY_SHOW_DEPOSIT: 'DBX_SHOW_DEPOSIT',
    NAVIGATE_HOST: 'NAVIGATE_HOST',
};

export const PROXY_SHOW_LOGIN = {
    type: INTENT_TYPES.PROXY_SHOW_LOGIN,
};

export const PROXY_SHOW_DEPOSIT = {
    type: INTENT_TYPES.PROXY_SHOW_DEPOSIT,
};


export const OPEN_CHAT = 'open-chat';
export const OPEN_KYC_PAGE = 'OPEN_KYC_PAGE';