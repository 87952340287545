/**
 * The types of event card.
 *
 * @class EventCardsTypes
 * @name types
 * @memberof Mojito.Modules.EventCards
 */
export default class EventCardsTypes {}

/**
 * Types of card promotion.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.EventCards.types
 */
EventCardsTypes.CARD_TYPE = {
    /** That type is used to render the outright event card. */
    OUTRIGHT: 'OUTRIGHT',
    /** That type is used to render the match event card. */
    MATCH: 'MATCH',
    /** That type is used to render the banner card. */
    BANNER: 'BANNER',
    /** That type is used to render the selection card. */
    SELECTION: 'SELECTION',
    /** That type is used for render the event card as a fallback default cards. */
    FALLBACK: 'FALLBACK',
};

/**
 * Types of fallback card content.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.EventCards.types
 */
EventCardsTypes.FALLBACK_CONTENT_TYPE = {
    /** That type is used to render fallback card that navigates to inplay coupon. */
    INPLAY: 'inplay',
    /** That type is used to render fallback card that navigates to particular sport coupon. */
    SPORT: 'sport',
};

/**
 * Card promotion.
 *
 * @typedef Card
 * @type {object}
 * @property {string} id - Promotion id.
 * @property {Mojito.Modules.EventCards.types.CARD_TYPE} type - Card type.
 * @property {Mojito.Modules.EventCards.types.EventCardContent|Mojito.Modules.EventCards.types.BannerCardContent|Mojito.Modules.EventCards.types.FallbackCardContent} content - Card content. Differs for each specific card type.
 * @property {number} displayOrder - Display order.
 *
 * @memberof Mojito.Modules.EventCards.types
 */

/**
 * Event card content. The card shows one or more most popular markets from event and navigates to event details on click.
 *
 * @typedef EventCardContent
 * @type {object}
 * @property {string} eventId - Event id.
 * @property {string} marketId - Market id (for single market presentation on a card).
 * @property {string} marketName - Market name.
 * @property {Mojito.Services.EventGroups.types.MarketOption} gameLine - Markets game line to show on event card. This property only available for events that belong to US sports.
 * @property {Map<string, Mojito.Services.SportsContent.Events.types.MarketLine>} marketLines - The map of market lines. Key is line type and value is actual market line. This property only available if <code>gameLine</code> is set.
 *
 * @memberof Mojito.Modules.EventCards.types
 */

/**
 * Banner card content. The card shows static image and navigates to provided URL.
 *
 * @typedef BannerCardContent
 * @type {object}
 * @property {string} imgUrl - The URL to static image to be displayed as a banner.
 * @property {Mojito.Services.Promotions.types.TARGET_TYPE} type - Navigation target type.
 * @property {string} url - URL to navigate on card banner click.
 * @property {boolean} openInNewTab - Should navigate to URL in new browser tab or in the current one.
 *
 * @memberof Mojito.Modules.EventCards.types
 */

/**
 * Fallback card content. This type of card is usually generated on a client and allows to navigate to inplay page or particular sport coupon.
 *
 * @typedef FallbackCardContent
 * @type {object}
 * @property {Mojito.Modules.EventCards.types.FALLBACK_CONTENT_TYPE} type - Fallback navigation type.
 *
 * @memberof Mojito.Modules.EventCards.types
 */

/**
 * Event card market.
 *
 * @typedef EventCardMarket
 * @type {object}
 * @property {string} id - Market id.
 * @property {string} name - Market name.
 *
 * @memberof Mojito.Modules.EventCards.types
 */
