import PropTypes from 'prop-types';
import BannerPromotionsImpl from './banner-promotions.jsx';
import MojitoCore from 'mojito/core';

const { UIControllerView } = MojitoCore.Presentation;

/**
 * Module that displays promotions as a banners set.
 * In default implementation promotions are retrieved from promotions store
 * using locationId config property and applicablePage component prop. Can be displayed in staked carousel or plain list.
 *
 * @class BannerPromotions
 * @memberof Mojito.Modules.Promotions
 */

/**
 * BannerPromotions prop types.
 *
 * @property {string} [applicablePage] - If provided, only promotions with this id will be shown.
 * @memberof Mojito.Modules.Promotions.BannerPromotions
 */
const propTypes = {
    applicablePage: PropTypes.string,
};

export default UIControllerView('BannerPromotions', BannerPromotionsImpl, propTypes);
