import AbstractTask from 'services/common/task-runner/abstract-task.js';
import { noop } from 'mojito/utils';
import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('PollOveraskStatusTask');

/**
 * Task to get overask status when overask is referred to trader when a bet is being placed.
 * This task should poll until trader has responded, either proposing a new offer, rejecting or accepting bet.
 *
 * @class GetOveraskStatusTask
 * @param {Mojito.Services.Betslip.dataRetriever} dataRetriever - Data retriever instance.
 * @param {Function} [success = () => {}] - Success callback fired when task has been successfully finalised.
 * @param {Function} [fail = () => {}] - Fail callback fired when task has been faulty finalised.
 *
 * @classdesc Execute get overask status call on retriever instance.
 * @memberof Mojito.Services.Betslip
 */
export default class GetOveraskStatusTask extends AbstractTask {
    constructor(dataRetriever, success = noop, fail = noop) {
        super();
        this.dataRetriever = dataRetriever;
        this.success = success;
        this.fail = fail;
    }

    execute(callback) {
        if (!this.dataRetriever) {
            log.warn('dataRetriever is missing.');
        }
        this.dataRetriever
            .getOveraskStatus()
            .then(() => {
                callback();
                this.success();
            })
            .catch(() => {
                callback();
                this.fail();
            });
    }
}
