/**
 * Class defining market groups data descriptors.
 *
 * @class MarketGroupsDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.SportsContent.MarketGroups
 */
export default class MarketGroupsDataDescriptor {
    /**
     * Factory method for creating a market group data descriptor.
     *
     * @param {string} groupId - Market group id.
     *
     * @returns {{dataType: string, groupIds: Array<string>}} Market group data descriptor.
     *
     * @function Mojito.Services.SportsContent.MarketGroups.descriptor.createGroupDescriptor
     */
    static createGroupDescriptor(groupId) {
        return MarketGroupsDataDescriptor.createGroupsDescriptor([groupId]);
    }

    /**
     * Factory method for creating a market groups data descriptor.
     *
     * @param {Array<string>} groupIds - List of market group ids.
     *
     * @returns {{dataType: string, groupIds: Array<string>}} Market group data descriptor.
     *
     * @function Mojito.Services.SportsContent.MarketGroups.descriptor.createGroupsDescriptor
     */
    static createGroupsDescriptor(groupIds) {
        return {
            dataType: MarketGroupsDataDescriptor.DATA_TYPES.MARKET_GROUPS,
            groupIds,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPE
 * @property {string} MARKET_GROUPS - Market groups data descriptor type.
 * @memberof Mojito.Services.SportsContent.MarketGroups.descriptor
 */
MarketGroupsDataDescriptor.DATA_TYPES = {
    MARKET_GROUPS: 'market-groups',
};
