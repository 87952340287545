import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Race regions related selectors.
 *
 * @module RaceRegionsSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.RaceRegions
 */

/**
 * Selects race regions state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsConent.RaceRegionsState} The state of the race regions store.
 * @memberof Mojito.Services.SportsContent.RaceRegions.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select all race regions.
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Object containing all race regions, empty if no race regions available in state.
 * @memberof Mojito.Services.SportsContent.RaceRegions.selectors
 */
export const selectRaceRegions = state => selectState(state).raceRegions;

/**
 * Select race region.
 *
 * @param {string} raceRegionId - The id of the race region.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Object containing race region, empty if no race region available in state.
 * @memberof Mojito.Services.SportsContent.RaceRegions.selectors
 */
export const selectRaceRegion = (raceRegionId, state) => selectRaceRegions(state)[raceRegionId];
