import PropTypes from 'prop-types';
import PayoutImpl from './payout-info.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Component to display payout info of bet funds.
 *
 * @class PayoutInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * PayoutInfo props.
 *
 * @property {number} [payout] - Payout value.
 * @property {number} [previousPayout] - Previous payout value.
 * @property {string} labelKey - Translation key for label.
 * @property {string} [currencyCode] - Currency.
 * @property {boolean} [showIcon] - Flag to display icon.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.PayoutInfo
 */
const propTypes = {
    payout: PropTypes.number,
    previousPayout: PropTypes.number,
    labelKey: PropTypes.string.isRequired,
    currencyCode: PropTypes.string,
    showIcon: PropTypes.bool,
};

export default UIView('PayoutInfo', PayoutImpl, propTypes);
