import QuickStartGuideSkeletonImpl from './quick-start-guide-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * The component works as a preloading component for QuickStartGuideBanner to show while the content is loading.
 *
 * @function QuickStartGuideSkeleton
 *
 * @memberof Mojito.Modules.QuickStartGuideBanner
 */

export default UIView('QuickStartGuideSkeleton', QuickStartGuideSkeletonImpl);
