import AbstractPromotionsService from './abstract-promotions-service.js';
import MojitoCore from 'mojito/core';
import { createDefaultServiceUrlValidation } from 'services/utils';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const logger = MojitoCore.logger.get('PromotionsService');

const BASE_URL = '/promotions';

/**
 * Promotions service config.
 *
 * @typedef PromotionsServiceConfig
 * @type {object}
 * @property {string} serviceUrl - Promotions API endpoint URL.
 *
 * @memberof Mojito.Services.Promotions
 */

/**
 * Class offering the possibility to interact with the mojito Promotions API.
 *
 * @class PromotionsService
 * @name service
 * @extends Mojito.Services.Promotions.AbstractPromotionsService
 * @memberof Mojito.Services.Promotions
 */
class PromotionsService extends AbstractPromotionsService {
    constructor() {
        super();
        const withServiceUrlValidation = createDefaultServiceUrlValidation(logger);
        this.getPromotions = withServiceUrlValidation(this.getPromotions.bind(this));
    }

    configure(config) {
        this.serviceUrl = config.serviceUrl;
        this.requestFactory = new ApiRequestProvider(logger);
        this.apiUrl = `${this.serviceUrl + BASE_URL}`;
    }

    getPromotions() {
        return this.requestFactory
            .get(`${this.apiUrl}`)
            .withDescription('get promotions')
            .withCredentials()
            .send();
    }
}

export default new PromotionsService();
