import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import LoginTypes from 'modules/login/types.js';
import loginHelper from './helper';

const intentActions = MojitoCore.Intents.actions;
const reduxInstance = MojitoCore.Services.redux;
/**
 * The name of the authentication store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Modules.Login
 */
export const STORE_KEY = 'modules/login';

/**
 * Defines the state of the login module store.
 *
 * @typedef LoginModuleState
 *
 * @property {boolean} isVisible - Whether login view visible or not.
 * @property {string} showLoginViewReason - The reason of login view show up.
 * @property {Mojito.Modules.Login.types.LOGIN_REASON} loginReason - The login reason.
 * @property {object} config - Store config.
 * @property {boolean} config.ignoreShowLoginView - If true the showLoginView actions will be ignored.
 *
 * @memberof Mojito.Modules.Login
 */

export const INITIAL_STATE = {
    isVisible: false,
    showLoginViewReason: '',
    loginReason: LoginTypes.LOGIN_REASON.DEFAULT,
    config: { ignoreShowLoginView: false },
};

export const { reducer, actions } = createSlice({
    name: 'login',
    initialState: INITIAL_STATE,
    reducers: {
        showLoginView(state, { payload }) {
            if (!state.config.ignoreShowLoginView) {
                state.showLoginViewReason = payload?.showLoginViewReason || '';
                state.loginReason = payload?.loginReason || LoginTypes.LOGIN_REASON.DEFAULT;
                state.isVisible = true;
            }
        },
        hideLoginView(state) {
            if (!state.config.ignoreShowLoginView) {
                state.isVisible = false;
            }
        },
        configure(state, { payload: config }) {
            state.config.ignoreShowLoginView =
                config.ignoreShowLoginView === undefined
                    ? state.config.ignoreShowLoginView
                    : config.ignoreShowLoginView;
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Login module related actions.
 *
 * @module Login
 * @name actions
 * @memberof Mojito.Modules.Login
 */

/**
 * Show login view.
 *
 * @function showLoginView
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{ showLoginViewReason: string, loginReason: Mojito.Modules.Login.types.LOGIN_REASON }} payload - Data needed to show login view.
 *
 * @memberof Mojito.Modules.Login.actions
 */

/**
 * Hide login view.
 *
 * @function hideLoginView
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Modules.Login.actions
 */

/**
 * Set configuration.
 *
 * @function configure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {{ ignoreShowLoginView: boolean }} payload - If true the showLoginView action will be ignored.
 *
 * @memberof Mojito.Modules.Login.actions
 */

/**
 * Reset login module state to initial value.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Modules.Login.actions
 */

reduxInstance.actionListener.startListening({
    actionCreator: intentActions.publishIntent,
    effect: (action, listenerApi) => {
        const intent = action.payload;
        const isLoginRequired = loginHelper.isLoginRequired(intent.type);
        // in case there was intent published that forces user to login,
        // we will show login view unless user is already authenticated
        if (isLoginRequired && !listenerApi.getState()[STORE_KEY]?.config?.ignoreShowLoginView) {
            listenerApi.dispatch(
                actions.showLoginView({
                    loginReason: LoginTypes.ENFORCE_LOGIN_INTENTS[intent.type],
                })
            );
        }
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
