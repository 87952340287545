import PropTypes from 'prop-types';
import EventListGroupImpl from './event-list-group.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View renders the group of event. Typically grouped by league or competition.
 *
 * @class EventListGroup
 * @memberof Mojito.Modules.EventList
 */

/**
 * EventListGroup prop types.
 *
 * @property {Mojito.Services.EventGroups.types.EventGroup} eventGroup - The group of events.
 * @property {Array<Array<string>>} [headerTypes = []] - List of header types. Each item is array represents a header for specific markets column. Each header contains header parts corresponding to each selection in a particular market column.
 * Each type in a list should have corresponding l10n key in EVENT_LIST_GROUP.HEADER_TYPES object. Example: [['1', 'X', '2'], ['O', 'U']] - group represents events with two selected markets in a row. One of them has home draw away selections and an other one is over under.
 * @property {string} sportId - Sport id. Optional property might be used for analytics.
 * @property {boolean} [initiallyExpanded = false] - True if the group should be initially expanded.
 * @property {Function} [onExpandChange = ()=>{}] - Callback triggered on group expand/collapse interaction.
 * @property {node|Array<node>} children - Children, typically, list of event items within a group.
 *
 * @memberof Mojito.Modules.EventList.EventListGroup
 */
const propTypes = {
    eventGroup: PropTypes.object.isRequired,
    headerTypes: PropTypes.array,
    sportId: PropTypes.string,
    initiallyExpanded: PropTypes.bool,
    onExpandChange: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const defaultProps = {
    headerTypes: [],
    initiallyExpanded: false,
    onExpandChange: noop,
};

export default UIView('EventListGroup', EventListGroupImpl, propTypes, defaultProps);
