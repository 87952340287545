import EventUtils from 'services/sports-content/events/utils.js';
/**
 * Class offering factory methods to create betslip model instances.
 *
 * @class ModelFactory
 * @name modelFactory
 * @memberof Mojito.Services.Betslip
 */

/**
 * Creates selection betslip part object from content data.
 *
 * @param {object} selectionData - Selection data object.
 * @param {Array} [places] - List of selection places. Typically used in multicast bets to define selected places for the runner.
 * @returns {Mojito.Services.Betslip.types.Selection} Selection betslip part object.
 *
 * @function Mojito.Services.Betslip.modelFactory.getSelection
 */
const getSelection = (selectionData, places) => {
    return {
        id: selectionData.id,
        marketId: selectionData.marketId,
        betRef: EventUtils.getPrice(selectionData.prices)?.betRef,
        hcap: selectionData.marketHcap,
        odds: {
            current: EventUtils.getPrice(selectionData.prices)?.decimalLabel,
            base: selectionData.basePrice && selectionData.basePrice.decimalLabel,
        },
        places,
    };
};

/**
 * Creates parent betslip part object from content data.
 *
 * @param {object} [eventData = {}] - Event data object.
 * @param {object} [selectionData = {}] - Selection data object.
 * @returns {Mojito.Services.Betslip.types.ParentInfo} Parent betslip part object.
 *
 * @function Mojito.Services.Betslip.modelFactory.getParent
 */
const getParent = (eventData = {}, selectionData = {}) => {
    return {
        eventId: eventData.id,
        sportId: eventData.sportId,
        sportClassId: eventData.classId,
        sportTypeId: eventData.typeId,
        marketId: selectionData.marketId,
    };
};

export default { getSelection, getParent };
