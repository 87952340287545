import MojitoCore from 'mojito/core';
import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {ensureHierarchy} from 'core/utils/config-utils';
import {MainControllerView} from './main-controller-view.jsx';

import './router-resolver-fix';
import './system-settings-storage';
import './native-context-fix.js';
class MojitoFixesFeature extends AbstractFeature {
    get name() {
        return 'Mojito Fixes';
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const viewImplementations = ensureHierarchy(mojitoConfig, 'services.viewImplementations');

        /*
           This fixes issues:
             1) Misuse of react lifeCycle (useEffect is not the same as componentDidMount).
                Misusing makes Safari crashing on portal integrated app (portal just call logout.defer() before render) - DBX-9768
             2) Unmount-mount causes bugs with routing. This fix recreates 'history' on component creation (not app start)
                that resets history cache (DBX-9946)
           Code is cloned & fixed from 22.5 version
        */
        viewImplementations.MainControllerView = {
            implementation: MainControllerView,
        };

        return super.afterMojitoConfigBuild(mojitoConfig);
    }

    afterMojito() {
        // Mute annoying and useless PromotionUtils warnings
        MojitoCore.logger.configureLogger('PromotionUtils', {level: MojitoCore.logger.NONE});

        return super.afterMojito();
    }
}

new MojitoFixesFeature(allFeatures);
