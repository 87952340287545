/**
 * @typedef SportsMenuTypes
 * @name types
 * @memberof Mojito.Modules.SportsMenu
 */
export default {
    /**
     * List of available sport types. Could be used for making different representation, grouping, filtering, etc.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Modules.SportsMenu.types
     */
    SPORT_TYPES: {
        DEFAULT: 'default',
        VIRTUAL: 'virtual',
    },

    /**
     * List of available representation types. Can be used for showing different representation.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Modules.SportsMenu.types
     */
    VIEW_TYPES: {
        DEFAULT: 'default',
        ONLY_HIGHLIGHTED: 'only_highlighted',
    },
};
