import {AbstractFeature} from 'core/application/abstract-feature';
import {IMS, IMSMessageService, IMS_MESSAGE_IDS} from 'core/features/ims';
import SCHEMA from './last-login.schema.yaml';

class LastLoginFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);

        this.initialized = false;
    }

    get name() {
        return 'Lastlogin';
    }

    get configSchema() {
        return SCHEMA;
    }

    get ready() {
        return this.initialized && this.config.enabled;
    }

    beforeMojito() {
        if (this.initialized) {
            return Promise.resolve();
        }

        this.initialized = true;

        IMSMessageService.addListeners({
            [IMS_MESSAGE_IDS.LOGIN_SUCCESS]: ({MessagingService}) => {
                const setPlayerTagMessage = {
                    ID: IMS_MESSAGE_IDS.SET_PLAYER_TAGS_REQUEST,
                    description: this.config.description,
                    tags: [{name: this.config.name, value: new Date().toISOString(), type: 'date'}],
                };
                MessagingService.sendMessage(setPlayerTagMessage);
            },
        });

        return super.beforeMojito();
    }
}

IMS_BASED && new LastLoginFeature(IMS);
