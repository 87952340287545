import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import PriceBoostCouponImpl from './price-boost-coupon.jsx';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * PriceBoostCoupon is a component that renders a coupon with grouped price enhanced (boosted) markets.
 * Will fetch events group using provided boostedGroupId to display boosted markets for each event in a group.
 *
 * @class PriceBoostCoupon
 * @memberof Mojito.Modules
 */

/**
 * PriceBoostCoupon propTypes.
 *
 * @property {string} [boostedGroupId] - The group id parameter used to fetch price boost events.
 * @property {boolean} [showSportTitles = false] - Defines if sport name should be shown for each events group as a title.
 * @property {Function} [onDataLoad = () => {}] - Callback triggered when all child elements have loaded.
 * Usually will be set to true if component is used to present {@link Mojito.Services.EventGroups.types.PRICE_BOOST_EVENT_GROUP_NAME.ALL_SPORTS|ALL_SPORTS} boosted events to distinguish between event groups that belong to different sports.
 * @memberof Mojito.Modules.PriceBoostCoupon
 */
const propTypes = {
    boostedGroupId: PropTypes.string,
    showSportTitles: PropTypes.bool,
    onDataLoad: PropTypes.func,
};

const defaultProps = {
    showSportTitles: false,
    onDataLoad: noop,
};

export default UIView('PriceBoostCoupon', PriceBoostCouponImpl, propTypes, defaultProps);
