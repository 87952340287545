import MojitoApplication from 'mojito/application';
import {isEmpty} from '#core/utils/config-utils.js';

const {selectors: routerSelectors} = MojitoApplication.Router;
export const getDetectedHostURL = () => {
    return window.location.protocol + '//' + window.location.host;
};

export const isLocalhost = () => {
    return window.location.hostname === 'localhost';
};

export const convertToHttpsProtocol = url => {
    return url.replace('http://', 'https://');
};

export const addParamToURL = (url, name, value) => {
    if (isEmpty(value)) {
        return url;
    }
    url = new URL(url, window.location.origin); // second argument (base) is used only when ${url} is a relative URL, otherwise it will be ignored.
    url.searchParams.set(name, value);
    return url.toString();
};

export const removeParamFromURL = (url, name) => {
    url = new URL(url);
    url.searchParams.delete(name);
    return url.toString();
};

export const removeAllParamsFromURL = url => {
    let parts = url.split('?');
    url = new URL(parts[0], window.location.origin);
    return url.toString();
};

/**
 *
 * @param paramName - Parameter name
 * @param url - Full url
 * @return {string|object} Value of param specified by paramName. Null if param not found
 */
export const getParamFromURL = (paramName, url = window.location.href) => {
    url = new URL(url);
    let parameter = url.searchParams.get(paramName);
    return parameter;
};

export const getAllParamsFromURL = (url = window.location.href) => {
    url = new URL(url);
    return Object.fromEntries(url.searchParams);
};

export const isCrawlerBot = () => {
    const robots = new RegExp(
        'googlebot|prerender|bingbot|yandex|baiduspider|twitterbot|facebookexternalhit|rogerbot' +
            '|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest/0.|pinterestbot|slackbot|vkShare|W3C_Validator|whatsapp',
        'i'
    );

    return robots.test(navigator.userAgent);
};

/**
 * Make sure url is starting with slash
 * @param url {string}
 * @return {string}
 */
export const ensureStartingSlash = url => {
    if (!url || url.length === 0 || url[0] === '/') {
        return url;
    }

    return '/' + url;
};

/**
 * Make sure url is NOT starting with slash
 * @param url {string}
 * @return {string}
 */
export const removeStartingSlash = url => {
    if (!url || url.length === 0) {
        return url;
    }
    if (url[0] === '/') {
        url = url.substring(1);
    }
    return url;
};

export const currentScriptSrc = new URL(document.currentScript.src);
export const currentScriptParams = currentScriptSrc.searchParams;
export const currentScriptOrigin = currentScriptSrc.origin;

let internalResourcesLocation = undefined;

export const setInternalResourcesPath = url => {
    try {
        internalResourcesLocation = new URL(url, currentScriptOrigin).toString();
        if (internalResourcesLocation[internalResourcesLocation.length - 1] !== '/') {
            internalResourcesLocation += '/';
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to set internal resources location', e);
    }
};

export const absoluteUrlToInternalResource = url => {
    if (url.startsWith('/')) {
        url = url.substring(1);
    }
    return new URL(url, internalResourcesLocation || currentScriptOrigin).toString();
};

/**
 * @param url {string}
 * @return {string} Url starting from slash
 */
export const stripRoutingPrefix = url => {
    const prefix = Sportsbook._routingPrefix;
    if (prefix === '/') return url;

    url = ensureStartingSlash(url);
    if (url.startsWith(prefix)) {
        return url.substring(prefix.length);
    } else {
        return url;
    }
};

export function isInternalUrl(url) {
    const routeResolver = routerSelectors.getRouteResolver();
    const root = (routeResolver && routeResolver.getRoot()) || '/';

    return url.startsWith(root) || url.startsWith(`${document.location.origin}${root}`);
}
