import { useMemo, memo, useCallback, useContext, useRef } from 'react';
import MojitoPresentation from 'mojito/presentation';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { sumBy } from 'mojito/utils';
import EventListHeader from './header/index.jsx';
import EventListGroup from './group/index.jsx';
import EventListItem from './item/index.jsx';
import { useMarketColumnCount, useMarketSwitchers, useMarketsPreload } from './hooks';
import EventListUtils from './utils.js';
import { EventListContext } from 'modules/event-list/context/event-list-context.jsx';
import { useContentPreload } from 'modules/common/hooks';

const { DATA_TYPES } = MojitoServices.SportsContent.Events.descriptors;
const { EVENT_CHUNK } = DATA_TYPES;
const { FlexPane, ImageButton } = MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const IntentActionTrigger = MojitoPresentation.Base.Intent.IntentActionTrigger;
const { EVENT_TYPE } = MojitoServices.SportsContent.Events.types;
const { COUPON_EVENTS, COUPON_MARKETS } = MojitoServices.SportsContent.Events.types.CONTENT_ORIGIN;
const log = MojitoCore.logger.get('EventList');

export default memo(function EventList(props) {
    const {
        mojitoTools: { config, appContext, stringResolver, instanceId },
        eventGroups,
        eventGroupsId,
        marketOptions,
        sportId,
        isInPlay,
        headerText,
        initialExpandedGroupIds,
        onItemClick,
        onDataLoad,
    } = props;
    const { numberOfEvents } = useContext(EventListContext) || {};
    const { routeResolver } = appContext;
    const loadedEventIds = useRef([]);

    const getHrefItem = eventId => {
        return isInPlay
            ? NavigationPathBuilder.inplayEventPath(eventId, routeResolver)
            : NavigationPathBuilder.sportEventPath(sportId, eventId, routeResolver);
    };

    const getHrefAllEvents = () => {
        return isInPlay
            ? NavigationPathBuilder.inplayPath(sportId, routeResolver)
            : NavigationPathBuilder.sportPath(sportId, routeResolver);
    };

    const onEventClick = useCallback(
        eventId => {
            if (isInPlay) {
                IntentActionTrigger.showInPlayEvent(eventId);
            } else {
                IntentActionTrigger.showSportEvent(sportId, eventId);
            }
            onItemClick(eventId);
        },
        [sportId, isInPlay, onItemClick]
    );

    const onShowAllEventsClick = () => {
        if (isInPlay) {
            IntentActionTrigger.showInPlay(sportId);
        } else {
            IntentActionTrigger.showSport(sportId);
        }
        props.onShowAllButtonClick(sportId);
    };

    const isGameLineMode = config.gameLineSports[sportId];
    const { count: marketColumnCount, elementRef } = useMarketColumnCount(
        config.marketsSection,
        isGameLineMode
    );

    const marketSwitcherLabels = useMemo(() => {
        const labels = marketOptions.map(getOptionLabel).filter(Boolean);
        if (labels.length !== marketOptions.length) {
            log.warn(`Labels are missing for some of ${sportId} market options.`);
        }
        return labels;
    }, [marketOptions, sportId]);

    const { onMarketSwitch, selectedSwitchers } = useMarketSwitchers(
        marketSwitcherLabels,
        marketColumnCount
    );

    const selectedMarketOptions = useMemo(
        () => findSelectedMarketOptions(selectedSwitchers, marketOptions),
        [selectedSwitchers, marketOptions]
    );

    const headerTypes = useMemo(() => {
        const optionMapper = isGameLineMode ? getGameLineHeaders : option => option?.headers;
        return selectedMarketOptions.map(optionMapper);
    }, [isGameLineMode, selectedMarketOptions]);

    const sportName = appContext.sportName(sportId);

    // Determining child modules loading state and preload events
    const eventIds = useMemo(() => {
        loadedEventIds.current = [];
        return eventGroups
            .filter(eventGroup => initialExpandedGroupIds.includes(eventGroup.id))
            .flatMap(eventGroup => eventGroup.events.map(event => event.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventGroups, initialExpandedGroupIds, sportId]);

    // Preload events
    const eventContentType = isInPlay ? `${COUPON_EVENTS}Live` : COUPON_EVENTS;
    const marketContentType = isInPlay ? `${COUPON_MARKETS}Live` : COUPON_MARKETS;
    const [eventsPreloadDone] = useContentPreload(
        instanceId,
        eventContentType,
        EVENT_CHUNK,
        eventIds,
        [eventGroupsId]
    );

    // Preload markets
    const marketsPreloadDone = useMarketsPreload(
        instanceId,
        marketContentType,
        eventIds,
        eventGroups,
        selectedMarketOptions,
        isGameLineMode,
        [eventGroupsId]
    );

    const onChildDataLoad = eventId => {
        loadedEventIds.current.push(eventId);
        const dataLoaded = eventIds.every(eventId => loadedEventIds.current.includes(eventId));
        if (dataLoaded && onDataLoad) {
            onDataLoad(eventGroups);
        }
    };

    const titleText = headerText || sportName;
    const { nothingToShow: hideHeader } = EventListUtils.resolveHeaderAppearance(
        config.header,
        titleText,
        marketSwitcherLabels,
        isGameLineMode,
        isInPlay
    );

    return (
        <FlexPane class="ta-EventList" config={config.container}>
            {!hideHeader && (
                <EventListHeader
                    config={config.header}
                    marketSectionRef={elementRef}
                    sportId={sportId}
                    titleText={titleText}
                    isGameLineMode={isGameLineMode}
                    isInPlay={isInPlay}
                    numberOfMarketSwitchers={marketColumnCount}
                    marketOptions={marketSwitcherLabels}
                    selectedMarketOptions={selectedSwitchers}
                    onSelectedMarketOptionChange={onMarketSwitch}
                />
            )}
            <FlexPane class="ta-EventListGroups" config={config.eventGroupsContainer}>
                {eventGroups.map(group => {
                    return (
                        <EventListGroup
                            key={group.id}
                            eventGroup={group}
                            sportId={sportId}
                            config={config.eventListGroup}
                            headerTypes={
                                group.eventGroupType !== EVENT_TYPE.OUTRIGHT
                                    ? headerTypes
                                    : undefined
                            }
                            initiallyExpanded={initialExpandedGroupIds.includes(group.id)}
                        >
                            <FlexPane class="ta-EventListItems" config={config.eventItemsContainer}>
                                {group.events.map(eventInfo => {
                                    return (
                                        <EventListItem
                                            key={eventInfo.id}
                                            eventId={eventInfo.id}
                                            hrefLink={getHrefItem(eventInfo.id)}
                                            config={config.eventItem}
                                            onClick={onEventClick}
                                            isGameLineMode={isGameLineMode}
                                            marketOptions={selectedMarketOptions}
                                            shouldRequestData={
                                                eventsPreloadDone && marketsPreloadDone
                                            }
                                            marketLines={eventInfo.marketLines}
                                            onDataLoad={onChildDataLoad}
                                        />
                                    );
                                })}
                            </FlexPane>
                        </EventListGroup>
                    );
                })}
            </FlexPane>
            {config.showAllEventsButton && (
                <ImageButton
                    onClick={onShowAllEventsClick}
                    hrefLink={getHrefAllEvents()}
                    label={resolveAllEventsLabel(
                        sportName,
                        isInPlay,
                        numberOfEvents || getTotalEventCount(eventGroups),
                        stringResolver
                    )}
                    config={config.allEventsButton}
                />
            )}
        </FlexPane>
    );
});

const getTotalEventCount = groups => sumBy(groups, group => group.events.length);
const getGameLineHeaders = gameLineOption => gameLineOption.options?.map(getOptionLabel);

const findSelectedMarketOptions = (optionLabels, marketOptions) => {
    if (!optionLabels || !marketOptions) {
        return;
    }
    return optionLabels
        .map(label => marketOptions.find(option => getOptionLabel(option) === label))
        .filter(Boolean);
};

const resolveAllEventsLabel = (sportName, isInPlay, eventCount, stringResolver) => {
    const l10nKey = isInPlay
        ? `$EVENT_LIST.ALL_INPLAY_EVENTS_BUTTON`
        : `$EVENT_LIST.ALL_EVENTS_BUTTON`;
    return stringResolver.resolveAndFormatString(l10nKey, sportName, eventCount);
};

const getOptionLabel = option => option.name || option.label;
