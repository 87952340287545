import PopoverImpl from './popover.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

/**
 * Popover component. Displays a clickable button which opens a popup dialog to show content in it.
 *
 * @class Popover
 * @memberof Mojito.Presentation.Components
 */

/**
 * Popover prop types.
 *
 * @property {boolean} [isDisabledButton] - True if signpost button should be disabled.
 * @property {node} [signpostContent] - Content of signpost.
 * @property {boolean} [shouldShowPopup = true] - False if popup shouldn't be shown for some reason.
 * @property {string} [class] - The className to be assigned to the Button element.
 * @property {node} [children] - Content of a popup.
 * @memberof Mojito.Presentation.Components.Popover
 */

const propTypes = {
    isDisabledButton: PropTypes.bool,
    signpostContent: PropTypes.node,
    shouldShowPopup: PropTypes.bool,
    class: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    shouldShowPopup: true,
};

export default MojitoCore.Presentation.UIView('Popover', PopoverImpl, propTypes, defaultProps);
