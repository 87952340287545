import { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import EventList from 'modules/event-list/index.jsx';
import { useEventGroups, useContentPreload } from 'modules/common/hooks';
import EventListSkeleton from 'modules/event-list/skeletons/event-list-skeleton/index.jsx';
import EventListUtils from 'modules/event-list/utils.js';
import { isFunction } from 'mojito/utils';

const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;
const EventGroupsDataDescriptor = MojitoServices.EventGroups.descriptor;
const { EVENT_GROUP_CHUNK } = EventGroupsDataDescriptor.DATA_TYPES;
const { selectEventGroupMarketOptions, selectEventGroupState } =
    MojitoServices.EventGroups.selectors;
const { makeSelectEventsState } = MojitoServices.SportsContent.Events.selectors;
const { CONTENT_STATE } = MojitoServices.Common.types;
const ServicesUtils = MojitoServices.Common.utils;
const { UNAVAILABLE } = CONTENT_STATE;
const { EmptyContent, LoaderSuspense, FlexPane } = MojitoPresentation.Components;
const { useComponentVisibility } = MojitoPresentation.Hooks;

export default function EventsCoupon(props) {
    const {
        eventGroupsId,
        analyticsName,
        showEmptyContent,
        mojitoTools: { config, instanceId },
        onDataLoad,
    } = props;

    const eventGroupIdsList = useMemo(() => [eventGroupsId], [eventGroupsId]);
    const [visible, setVisible] = useState(!config.enableContentLazyLoading);
    const elementRef = useComponentVisibility(() => setVisible(true));
    const [shouldRequestData] = useContentPreload(
        visible ? instanceId : undefined,
        'EventsCoupon',
        EVENT_GROUP_CHUNK,
        eventGroupIdsList
    );
    const eventGroupsSet = useEventGroups([eventGroupsId], shouldRequestData);

    const allEventGroups = eventGroupsSet[eventGroupsId];
    const marketOptions = useSelector(state => selectEventGroupMarketOptions(eventGroupsId, state));

    const { maxGroupsToRender, maxEventsToRender, initialExpandedGroups } = config;

    const eventGroups = useMemo(
        () =>
            EventListUtils.resolveViewableGroups(
                maxGroupsToRender,
                maxEventsToRender,
                allEventGroups
            ),
        [allEventGroups, maxGroupsToRender, maxEventsToRender]
    );

    const analyticsContext = useMemo(
        () => new AnalyticsContextBuilder().withAnalyticsPath(analyticsName).build(),
        [analyticsName]
    );
    const initialExpandedGroupIds = useMemo(
        () => EventListUtils.resolveInitialExpandedGroups(initialExpandedGroups, eventGroups),
        [initialExpandedGroups, eventGroups]
    );
    const { loadDone, groupsLoadingState } = useGroupsLoadingDone(
        eventGroupsId,
        eventGroups,
        initialExpandedGroups
    );

    const contentVersion = useContentVersion(eventGroupsId);

    // Determining children modules loading status
    const setLoadedEventGroups = useDataLoaded(onDataLoad, eventGroupsId, eventGroups);
    const onChildrenLoadDone = useCallback(
        eventGroups => setLoadedEventGroups(eventGroups),
        [setLoadedEventGroups]
    );

    // If no data available or event group is void, render empty content.
    if (groupsLoadingState === UNAVAILABLE || eventGroups?.length === 0) {
        return showEmptyContent ? <EmptyContent config={config.emptyContent} /> : null;
    }

    const skeleton = (
        <EventListSkeleton config={config.eventListSkeleton} sportId={props.sportId} />
    );

    const headerText = isFunction(props.headerText)
        ? props.headerText(eventGroups)
        : props.headerText;

    return (
        <FlexPane config={config.container} elementRef={elementRef}>
            <LoaderSuspense
                config={config.skeletonLoader}
                isContentPending={!loadDone}
                contentHash={contentVersion}
                loader={skeleton}
            >
                {eventGroups && (
                    <AnalyticsContextExtender value={analyticsContext}>
                        <EventList
                            headerText={headerText}
                            config={config.eventList}
                            sportId={props.sportId}
                            marketOptions={marketOptions}
                            eventGroupsId={eventGroupsId}
                            eventGroups={eventGroups}
                            initialExpandedGroupIds={initialExpandedGroupIds}
                            onShowAllButtonClick={props.onShowAllButtonClick}
                            isInPlay={props.isInPlay}
                            onDataLoad={onChildrenLoadDone}
                        />
                    </AnalyticsContextExtender>
                )}
            </LoaderSuspense>
        </FlexPane>
    );
}

const useDataLoaded = (onDataLoaded, eventGroupsId, eventGroups = []) => {
    const [loadedEventGroups, setLoadedEventGroups] = useState([]);
    const loadedGroupIds = useMemo(
        () => loadedEventGroups.map(({ id }) => id),
        [loadedEventGroups]
    );
    const allGroupIds = useMemo(() => eventGroups.map(({ id }) => id), [eventGroups]);
    useEffect(() => {
        const hasIds = !!allGroupIds.length;
        if (
            allGroupIds.length === loadedEventGroups.length &&
            hasIds &&
            allGroupIds.every(id => loadedGroupIds.includes(id))
        ) {
            onDataLoaded(eventGroupsId);
        }
    }, [loadedGroupIds, allGroupIds, onDataLoaded, eventGroupsId, loadedEventGroups.length]);
    return setLoadedEventGroups;
};

const useGroupsLoadingDone = (eventGroupsId, eventGroups, initialExpandedGroups) => {
    const groupsLoadingState = useSelector(state => selectEventGroupState(eventGroupsId, state));
    const eventIds = useMemo(() => {
        const expandedGroupIds = EventListUtils.resolveInitialExpandedGroups(
            initialExpandedGroups,
            eventGroups
        );
        return (eventGroups || [])
            .filter(group => expandedGroupIds.includes(group.id))
            .flatMap(group => group.events)
            .map(event => event.id);
    }, [initialExpandedGroups, eventGroups]);

    const selectEventsState = useMemo(makeSelectEventsState, []);
    const eventsLoadDone = useSelector(state => {
        const loadingValues = Object.values(selectEventsState(eventIds, state));
        const everyEventRequested = loadingValues.length === eventIds.length;
        return everyEventRequested && loadingValues.every(ServicesUtils.isContentLoadDone);
    });
    // Both AVAILABLE or UNAVAILABLE state can appear on group and on events after it has been requested, and we received the response.
    const groupLoadDone = ServicesUtils.isContentLoadDone(groupsLoadingState);
    const loadDone = groupLoadDone && eventsLoadDone;
    return { loadDone, groupsLoadingState };
};

const useContentVersion = eventGroupsId => {
    const groupIdRef = useRef(eventGroupsId);
    const contentVersionRef = useRef(0);
    if (eventGroupsId !== groupIdRef.current) {
        contentVersionRef.current = Math.random();
        groupIdRef.current = eventGroupsId;
    }
    return `${eventGroupsId}-${contentVersionRef.current}`;
};
