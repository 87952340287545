const GridCell = props => {
    const {
        mojitoTools: { config },
        children,
        onClick,
        startColumn,
        endColumn,
        startRow,
        endRow,
    } = props;

    // Copy the style to not modify the original, then add start/end column/rows.
    const style = { ...config.style };
    if (startColumn) {
        style.gridColumnStart = startColumn;
    }
    if (endColumn) {
        style.gridColumnEnd = endColumn + 1;
    }
    if (startRow) {
        style.gridRowStart = startRow;
    }
    if (endRow) {
        style.gridRowEnd = endRow + 1;
    }

    if (config.useAbsolutePosition) {
        style.position = 'absolute';
    }

    return (
        <div style={style} className={props.class} onClick={onClick}>
            {children}
        </div>
    );
};

export default GridCell;
