import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';
import serviceFactory from './service/service-factory';
import { selectEventItem } from 'services/sports-content/events/selectors.js';
import NotificationsTypes from './types';
import EventsUtils from 'services/sports-content/events/utils.js';

const reduxInstance = MojitoCore.Services.redux;
const { SUBSCRIPTION_TYPE } = NotificationsTypes;
const { DateTimeUtils } = MojitoCore.Base;

/**
 * Sports content notifications store selectors.
 *
 * @class SportsContentNotificationsSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */

/**
 * Selects sports content notifications state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.ContentNotifications.SportsContentNotificationsState} The state of the Content notifications store.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Check if notifications service is available.
 * Typically, identifies if service has been configured and browser supports push notifications.
 * This is an unusual selector because it goes beyond store data, so be careful.
 *
 * @function selectIsServiceAvailable
 * @returns {boolean} True if available, false otherwise.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectIsServiceAvailable = () => !!serviceFactory.getService()?.isAvailable();

/**
 * Check if event has active subscription or follow request is pending.
 *
 * @function selectIsEventSubscribed
 * @param {string} eventId  - Event ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if event has active subscription or follow request is pending, otherwise false.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectIsEventSubscribed = (eventId, state) => {
    const { subscriptions, pendingFollowEventIds, pendingUnfollowEventIds } = selectState(state);
    return Boolean(
        !pendingUnfollowEventIds[eventId] &&
            (subscriptions[eventId] || pendingFollowEventIds[eventId])
    );
};

/**
 * Check if event has pending follow or unfollow request.
 *
 * @function selectIsPending
 * @param {string} eventId - Event ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if event is pending, false otherwise.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectIsPending = (eventId, state) => {
    const { pendingFollowEventIds, pendingUnfollowEventIds } = selectState(state);
    return Boolean(pendingFollowEventIds[eventId] || pendingUnfollowEventIds[eventId]);
};

/**
 * Check if event is eligible for notifications subscription.
 *
 * @function selectIsEventEligible
 * @param {string} eventId - Event ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if eligible, false otherwise.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectIsEventEligible = (eventId, state) => {
    const event = selectEventItem(eventId, state);
    return !!event?.notificationsId;
};

/**
 * Check if notification onboarding has been acknowledged.
 *
 * @function selectIsOnboardingAcknowledged
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if acknowledged, false otherwise.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectIsOnboardingAcknowledged = state => selectState(state).onboardingAcknowledged;

/**
 * Function to select event subscription info. The format defines how the subscription is stored, and making changes to it will break backward compatibility.
 *
 * @function selectEventSubscription
 * @param {string} eventId - The unique identifier of the event.
 * @param {object} [state] - The Redux state object. If not provided, the application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used.
 * @returns {Mojito.Services.SportsContent.ContentNotifications.types.EventSubscriptionInfo|undefined} The event subscription information or undefined if not found.
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectEventSubscription = (eventId, state) => {
    const event = selectEventItem(eventId, state);
    if (event) {
        const { startTime, eventType } = event;
        const days = getDaysUntilDeletion(state, EventsUtils.isOutrightEventType(eventType));
        return {
            types: [SUBSCRIPTION_TYPE.EVENT],
            expired: getExpiryTime(new Date(startTime), days),
        };
    }
};

/**
 * Select follow event info as it is passed to follow/unfollow service calls.
 *
 * @function selectFollowEventInfo
 * @param {string} eventId - Event ID.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.ContentNotifications.types.FollowEventInfo|undefined} Follow event info.
 *
 * @memberof Mojito.Services.SportsContent.ContentNotifications.selectors
 */
export const selectFollowEventInfo = (eventId, state) => {
    const event = selectEventItem(eventId, state);
    return event && { eventId: event.id, notificationsId: event.notificationsId };
};

function getDaysUntilDeletion(state, isOutright) {
    const { daysUntilDeletion, daysUntilDeletionForOutrights } = selectState(state).options;
    return isOutright ? daysUntilDeletionForOutrights : daysUntilDeletion;
}

function getExpiryTime(startTime, days) {
    return DateTimeUtils.addDays(startTime, days).getTime();
}
