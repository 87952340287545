import MojitoCore from 'mojito/core';
import { RESTRICTION_ERROR_CODES } from './types.js';

const { selectApplicationMode } = MojitoCore.Services.SystemSettings.selectors;
const { APPLICATION_MODE } = MojitoCore.Services.SystemSettings.types;

const CLIENT_TO_SERVER_APPLICATION_MODE_MAP = Object.freeze({
    [APPLICATION_MODE.DESKTOP]: 'DESKTOP',
    [APPLICATION_MODE.MOBILE]: 'MOBILE',
});

/**
 * Video Streaming utilities.
 *
 * @class StreamingUtils
 * @name utils
 * @memberof Mojito.Services.VideoStreaming
 */
export default class StreamingUtils {
    /**
     * Checks if a stream is available for the specified event.
     *
     * @param {object} event - Event object.
     *
     * @returns {boolean} True if a stream is available.
     * @function Mojito.Services.VideoStreaming.utils.isStreamAvailable
     */
    static isStreamAvailable(event) {
        return !!(
            event &&
            event.streams &&
            event.streams.length &&
            StreamingUtils.getStreamForCurrentContext(event.streams)
        );
    }

    /**
     * Returns first available stream for current platform.
     *
     * @param {Array} [streams = []] - Event Streams array.
     *
     * @returns {object|undefined} Stream spec object, or undefined if not found.
     * @function Mojito.Services.VideoStreaming.utils.getStreamForCurrentContext
     */
    static getStreamForCurrentContext(streams = []) {
        const platform = CLIENT_TO_SERVER_APPLICATION_MODE_MAP[selectApplicationMode()];
        return streams.find(stream => stream.platform === platform);
    }

    /**
     * Get the errors with codes that belong to {@link Mojito.Services.VideoStreaming.types.RESTRICTION_ERROR_CODES|RESTRICTION_ERROR_CODES}.
     *
     * @param {Array<Mojito.Services.VideoStreaming.types.Error>} errors - List of streaming errors.
     *
     * @returns {Array<Mojito.Services.VideoStreaming.types.Error>} Restriction errors list.
     * @function Mojito.Services.VideoStreaming.utils.getRestrictionErrors
     */
    static getRestrictionErrors(errors) {
        return errors.some(({ code }) => RESTRICTION_ERROR_CODES.includes(code));
    }
}
