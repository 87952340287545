import PropTypes from 'prop-types';
import SwiperSlideImpl from './swiper-slide.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * SwiperSlide component. Used as a wrapper around each slide content for {@link Mojito.Presentation.Components.Swiper|Swiper} component.
 *
 * @class SwiperSlide
 * @memberof Mojito.Presentation.Components
 */

/**
 * SwiperSlide prop types.
 *
 * @property {string} id - Slide id.
 * @property {node} [children] - Children react elements. Content of the slide.
 * @memberof Mojito.Presentation.Components.SwiperSlide
 */
const propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
};

export default UIView('SwiperSlide', SwiperSlideImpl, propTypes);
