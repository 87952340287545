import TransactionRequest from './transaction-request.js';
import PostponedRequest from './postponed-request.js';
import TransactionsTypes from './types';

const { GET, PATCH, POST } = TransactionsTypes.REQUEST_METHOD;

/**
 * RequestFactory constructor.
 *
 * @class RequestFactory
 * @param {object} logger - Logger instance.
 * @param {Mojito.Core.Services.Transactions.types.ErrorHandler} errorHandler - Function can be set to introduce global error handling in requests produced by the factory.
 * @classdesc Factory class used for <code>Mojito.Core.Services.Transactions.TransactionRequest</code> instances creation.
 * Operates as a wrapper around fetch API.
 * @memberof Mojito.Core.Services.Transactions
 */
export default class RequestFactory {
    constructor(logger, errorHandler) {
        this.logger = logger;
        this.errorHandler = errorHandler;
    }

    /**
     * Creates get request.
     *
     * @param {string} url - Request URL.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Request instance.
     * @function Mojito.Core.Services.Transactions.RequestFactory#get
     */
    get(url) {
        return new TransactionRequest(url, GET, this.logger).withErrorHandler(this.errorHandler);
    }

    /**
     * Creates patch request.
     *
     * @param {string} url - Request URL.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Request instance.
     * @function Mojito.Core.Services.Transactions.RequestFactory#patch
     */
    patch(url) {
        return new TransactionRequest(url, PATCH, this.logger)
            .withCsrfTokenHeader()
            .withErrorHandler(this.errorHandler);
    }

    /**
     * Creates post request.
     *
     * @param {string} url - Request URL.
     * @returns {Mojito.Core.Services.Transactions.TransactionRequest} Request instance.
     * @function Mojito.Core.Services.Transactions.RequestFactory#post
     */
    post(url) {
        return new TransactionRequest(url, POST, this.logger)
            .withCsrfTokenHeader()
            .withErrorHandler(this.errorHandler);
    }

    toPostponed(request, sendCallback) {
        return new PostponedRequest(request, sendCallback)
            .withCsrfTokenHeader()
            .withErrorHandler(this.errorHandler);
    }
}
