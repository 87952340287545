import MojitoCore from 'mojito/core';
import betslipDataRetriever from './betslip/data-retriever.js';
import { actions as bootstrapActions } from './bootstrap/slice.js';
import betsDataRetriever from './bets/data-retriever.js';
import eventGroupsProvider from './eventgroups/provider';
import sportMetaInformationProvider from './sports-content/content-provider/sport-meta-information-provider';
import eventProvider from './sports-content/content-provider/event-provider';
import itemListProvider from './item-list/provider';
import containerProvider from './sports-content/content-provider/container-provider';
import marketGroupsProvider from './sports-content/content-provider/market-groups-provider';
import marketProvider from './sports-content/content-provider/market-provider';
import menusProvider from './sports-content/content-provider/menus-provider';
import metaInformationProvider from './meta-information/provider';
import { actions as matchAccaActions } from './match-acca/slice.js';
import matchAccaService from './match-acca/service/match-acca-service';
import meetingProvider from './sports-content/content-provider/meeting-provider';
import externalNavigationServiceFactory from './external-navigation/service/service-factory';
import pdfService from './pdf/pdf-service';
import promotionsProvider from './promotions/provider';
import raceRegionProvider from './sports-content/content-provider/race-region-provider';
import userInfoDataRetriever from './user-info/data-retriever';
import videoStreamingServiceFactory from './video-streaming/service/service-factory.js';
import freeBetsDataRetriever from './bonus-free-bets/data-retriever';
import AbstractMessagingService from './messaging/service/abstract-messaging-service';
import AbstractGamificationService from './gamification/service/abstract-gamification-service';
import promotionsDataRetriever from './promotions/dataretriever';
import gamificationServiceFactory from './gamification/service/service-factory';
import { actions as openBetsActions } from './bets/open-bets/open-bets-slice';
import bonusService from './bonus/service/bonus-service';
import bonusServiceFactory from './bonus/service/service-factory';
import { actions as promotionsActions } from './promotions/slice.js';
import { actions as userInfoActions } from './user-info/slice.js';
import { actions as userSettingsActions } from './user-settings/slice.js';
import { actions as contentNotificationsActions } from './sports-content/content-notifications/slice.js';
import { actions as notificationEventActions } from './sports-content/notification-event/slice.js';
import systemInformationProvider from './system-information/provider';
import analyticsreporter from './analytics/analyticsreporter';
import { actions as authenticationActions } from './authentication/slice.js';
import { actions as appBannerActions } from './app-banner/slice.js';
import quickStartGuideActions from './quick-start-guide/actions.js';
import contentChannelFactory from './common/content/content-channel-factory.js';
import { omit } from 'mojito/utils';
import { actions as searchActions } from './search/slice.js';
import { actions as betslipActions } from './betslip/slice.js';
import messagingServiceFactory from './messaging/service/service-factory';
import prefetchListener from './prefetch/prefetch-listener';

const {
    actions: systemSettingsActions,
    selectors: { selectLanguage },
} = MojitoCore.Services.SystemSettings;
const { dispatch } = MojitoCore.Services.redux.store;
const { configRegistry } = MojitoCore.Services.Config;
const log = MojitoCore.logger.get('MojitoServices');

/**
 * This consists of Mojito services associated with business and application data models.
 * The services layer is partitioned into two sub-layers:
 * - The upper public layer includes various stores, implemented using the Alt flux implementation, which provide data to Mojito's higher layers.
 * - The lower internal layer fetches content data from the Mojito core layer's repository.
 *
 * To import the Mojito services module: `import MojitoServices from 'mojito/services';`.
 *
 * @namespace Services
 * @memberof Mojito
 */

/**
 * @typedef Config
 * @type {object}
 * @property {object} configurations - Map of configurations using the configuration names as keys.
 * @property {Mojito.Services.ImplementationConfig} viewImplementations - Map of view implementations using the view names as keys.
 * @property {object} stores - Stores configuration values.
 * @property {Mojito.Services.UserSettings.Config} stores.userSettings - Initial user setting values.
 *
 * @memberof Mojito.Services
 *
 * @example <caption>Services layer config</caption>
 * init({
 *     configurations: {
 *         PageHeader: {
 *             background: '#D3D3D3',
 *             border: '1 solid transparent',
 *             borderRadius: '2px 2px 0 0',
 *             text: {
 *                 color: '#212121'
 *             }
 *         },
 *
 *         TextStyle: {
 *             fontWeight: 300
 *         },
 *
 *         l10n_sv: {
 *             strings: {
 *                 GENERIC: {
 *                     SUSPENDED: 'avbruten'
 *                 }
 *             }
 *         }
 *     },
 *
 *     viewImplementations: {
 *         ApplicationFooter: {
 *             implementation: require('views/applicationfooter/index.jsx').default
 *         },
 *         Button: {
 *              implementation: MyButton,
 *              suspenseLoader: MyButtonLoadingView,
 *        },
 *     },
 *
 *     stores: {
 *         content: {
 *             contentKeepAliveTime: 1000
 *         },
 *         systemSettings: {
 *             applicationMode: 'mobile',
 *             language: 'sv'
 *         },
 *         userSettings: {
 *             oddsFormat: 'decimal',
 *             timeOffset: '1'
 *         }
 *     }
 *  })
 */

/**
 * @typedef ImplementationConfig
 * @type {object}
 * @property {Function} implementation - Reference to custom react component implementation class or function.
 * @property {Function} suspenseLoader - Reference loading view class or function. Will be shown if component was imported using {@link https://reactjs.org/docs/code-splitting.html#reactlazy|React.lazy}.
 *
 * @memberof Mojito.Services
 */

class Module {
    /**
     * Initialises the services layer using the given configuration.
     *
     * @param {Mojito.Services.Config} [config = {}] - Configuration object for the Data module.
     * @function Mojito.Services#init
     */
    // Motivation to ignore sonar: don't want to refactor this function on this stage to reduce cognitive complexity.
    // No prettier - to keep no sonar on the same line
    // prettier-ignore
    init(config = {}) { // NOSONAR

        // Set app configs
        if (config.configurations) {
            configRegistry.addOverrides(config.configurations);
        }

        // Set the viewImplementations
        if (config.viewImplementations) {
            configRegistry.addImplementations(config.viewImplementations);
        }

        // Configure Bets store
        const betsConfig = config.stores && config.stores.bets;
        dispatch(openBetsActions.configure(betsConfig));

        // Configure user info store
        dispatch(userInfoActions.configure(config.stores && config.stores.userInfo));

        // Init search service
        if (config.searchService) {
            dispatch(
                searchActions.init({
                    service: config.searchService,
                })
            );
        }
        // Configure search store
        searchActions.configure(config.stores && config.stores.search);

        const systemSettings = config?.stores?.systemSettings || {};
        dispatch(systemSettingsActions.configure(systemSettings));

        // Init external navigation service
        if (config.externalNavigationService) {
            externalNavigationServiceFactory.init({
                service: config.externalNavigationService.service,
            });
        }

        // Configure analytics reporter
        if (config.analytics) {
            analyticsreporter.config(config.analytics);
        }

        // Init app banner settings from app config
        dispatch(appBannerActions.configure(config.stores?.appBanner || {}));

        // Init user setting from app config
        if (config.stores) {
            dispatch(userSettingsActions.setApplicationDefaults(config.stores?.userSettings || {}));
        }

        // Configure all Content stores
        const contentConfig = config.stores?.content;
        contentChannelFactory.configure(contentConfig);

        const { transactionUrl } = config;
        if (config.bootstrapService) {
            dispatch(
                bootstrapActions.configure({
                    service: config.bootstrapService,
                    serviceUrl: transactionUrl,
                })
            );
        }

        // Start prefetch listener
        prefetchListener.init();

        const { service: gamificationServiceInstance } = config.gamificationService || {};
        if (gamificationServiceInstance) {
            if (gamificationServiceInstance instanceof AbstractGamificationService) {
                gamificationServiceFactory.init(gamificationServiceInstance);
            } else {
                log.warn(
                    `Check 'gamificationService.service' config value. Provided instance should implement Mojito.Services.Gamification.AbstractGamificationService`
                );
            }
        }

        const languageCode = selectLanguage();

        // Init betslip data retriever
        const {betslipService, transactionBetslipUrl} = config;
        if (betslipService) {
            betslipDataRetriever.init({
                service: betslipService.service,
                serviceUrl: transactionBetslipUrl || transactionUrl,
                refreshBonusOffersInterval: betslipService.refreshBonusOffersInterval,
                refreshOveraskStatusInterval: betslipService.refreshOveraskStatusInterval,
                refreshOveraskStatusDelay: betslipService.refreshOveraskStatusDelay,
            });
        }

        dispatch(betslipActions.configure(config.stores?.betslip));

        if (config.bonusService) {
            bonusService.configure({
                serviceUrl: config.transactionBonusUrl || transactionUrl,
            });

            freeBetsDataRetriever.init(config.bonusService);
            bonusServiceFactory.init(config.bonusService.service);
        }

        // Configure Notification service
        const notificationsServiceConfig = config.contentNotificationsService || {};
        const notificationsService = notificationsServiceConfig.service;
        if (notificationsService) {
            const serviceUrl = config.transactionNotificationsUrl || transactionUrl;
            notificationsService.configure({
                serviceUrl,
                ...omit(notificationsServiceConfig, 'service'),
            });
            dispatch(
                contentNotificationsActions.init({
                    service: notificationsService,
                    daysUntilDeletion: notificationsServiceConfig.daysUntilDeletion,
                    daysUntilDeletionForOutrights:
                        notificationsServiceConfig.daysUntilDeletionForOutrights,
                })
            );
        }

        // Configure Bets service and data retriever
        if (config.betsService && config.betsService.service) {
            config.betsService.service.configure({
                serviceUrl: config.transactionBetsUrl || transactionUrl,
                language: languageCode,
                payoutEnabled: config.betsService.payoutEnabled,
            });
        }
        const { service, openBets = {}, cashout = {} } = config.betsService || {};
        const cashoutServiceUrl = config.transactionCashoutUrl || transactionUrl;
        const cashoutConfig = { serviceUrl: cashoutServiceUrl, ...cashout };
        betsDataRetriever.init({
            service: service,
            serviceUrl: config.transactionBetsUrl || transactionUrl,
            openBetsConfig: openBets,
            cashoutConfig,
        });

        // Init match acca data retriever
        if (config.matchAccaService) {
            matchAccaService.configure({
                serviceUrl: config.transactionMatchAccaUrl || transactionUrl,
            });
            dispatch(
                matchAccaActions.init({
                    service: config.matchAccaService.service,
                })
            );
        }

        // Init authentication data retriever
        if (config.authenticationService) {
            dispatch(
                authenticationActions.init({
                    service: config.authenticationService.service,
                    transactionUrl: config.transactionAuthenticationUrl || transactionUrl,
                    languageCode,
                    imsConfig: config.ims,
                    ssoServiceImpl: config.authenticationService.ssoImplementation,
                })
            );
        }

        // Init messaging data retriever
        const messagingServiceInstance = config.messagingService?.service;
        if (messagingServiceInstance) {
            if (messagingServiceInstance instanceof AbstractMessagingService) {
                messagingServiceFactory.init(messagingServiceInstance)
            } else {
                log.warn(
                    `Check 'messagingService.service' config value. Provided instance should implement Mojito.Services.Messaging.AbstractMessagingService`
                );
            }
        }

        // Init user info data retriever
        userInfoDataRetriever.init({
            service: config.userInfoService,
            serviceUrl: config.transactionUserInfoUrl || transactionUrl,
        });

        if (config.quickStartGuideService) {
            dispatch(
                quickStartGuideActions.init({
                    service: config.quickStartGuideService.service,
                })
            );
        }

        if (config.pdfServiceUrl) {
            pdfService.init(config.pdfServiceUrl);
        }

        // Init video streaming data retriever
        const { transactionVideoStreamingUrl } = config;
        videoStreamingServiceFactory.init({
            service: config?.videoStreamingService?.service,
            serviceUrl: transactionVideoStreamingUrl || transactionUrl,
        });
        if (config.promotionsService) {
            promotionsDataRetriever.init(
                config.promotionsService,
                config.transactionPromotionUrl || transactionUrl
            );
        }
        dispatch(promotionsActions.configure(config.stores && config.stores.promotions));

        if (config.notificationEventResolverService) {
            dispatch(
                notificationEventActions.init({
                    service: config.notificationEventResolverService,
                    serviceUrl: config.notificationEventResolverUrl || transactionUrl,
                })
            );
        }

        // Start data providers
        systemInformationProvider.init();
        eventProvider.init(languageCode);
        eventGroupsProvider.init(languageCode);
        marketGroupsProvider.init(languageCode);
        marketProvider.init(languageCode);
        meetingProvider.init(languageCode);
        raceRegionProvider.init();
        containerProvider.init(languageCode);
        itemListProvider.init();
        promotionsProvider.init(languageCode);
        metaInformationProvider.init(languageCode);
        sportMetaInformationProvider.init(languageCode);
        menusProvider.init(languageCode);

        dispatch(bootstrapActions.init());
    }

    /**
     * Dispose services layer. Stops all background processing.
     *
     * @function Mojito.Services#dispose
     */
    dispose() {
        dispatch(bootstrapActions.dispose());
    }
}

export default new Module();
