import { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';

import DeepLinkingRoutingController from './deep-linking-routing-controller.jsx';
import { useDispatch, useSelector } from 'react-redux';

const {
    actions: betslipActions,
    selectors: { selectInitialized },
} = MojitoServices.Betslip;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;

const IS_REDIRECT = true;

const PARAMS = {
    BET: 'bet_ref',
    CLEAR: 'clear_slip',
    STAKE: 'acc_stake',
    NAV: 'no_nav',
};

/**
 * Functional React component responsible for handling deep linking navigation that adds selections to betslip.
 *
 * @class DeepLinkingController
 *
 * @returns {object} React route renderer.
 * @memberof Mojito.Application
 */
export default memo(function DeepLinkingController() {
    const [search, setSearchParams] = useSearchParams();
    const refs = search.getAll(PARAMS.BET);
    const clearSlip = search.get(PARAMS.CLEAR);
    const accStake = search.get(PARAMS.STAKE);
    const noNav = search.get(PARAMS.NAV);
    const dispatch = useDispatch();
    const betslipInitialized = useSelector(selectInitialized);

    useEffect(() => {
        if (!betslipInitialized) {
            return;
        }
        if (Array.isArray(refs) && refs.length) {
            const shouldClearBetslip = clearSlip === 'Y';

            const parsedStake = parseFloat(accStake);
            const stake = isFinite(parsedStake) ? parsedStake : undefined;
            dispatch(
                betslipActions.executeDeepLinkingActions({ refs, stake, clear: shouldClearBetslip })
            );

            if (noNav === 'true') {
                IntentActionTrigger.showHomePage(IS_REDIRECT);
            }
        } else {
            IntentActionTrigger.showHomePage(IS_REDIRECT);
        }
        clearDeepLinkingSearchParams(search, setSearchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betslipInitialized]);

    return <DeepLinkingRoutingController />;
});

function clearDeepLinkingSearchParams(search, setSearchParams) {
    const newSearchParams = {};
    for (const [key, value] of search.entries()) {
        if (!Object.values(PARAMS).includes(key)) {
            newSearchParams[key] = value;
        }
    }
    setSearchParams(newSearchParams);
}
