import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('NotificationEventsServiceFactory');

const voidResponder = () => {
    log.warn('NotificationEvents instance is missing.');
    return Promise.reject();
};

const NULL_SERVICE = {
    getEventId: voidResponder,
};

/**
 * Singleton object that receives notification events config and spawn service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.SportsContent.NotificationEvents
 */
class ServiceFactory {
    constructor() {
        this.service = NULL_SERVICE;
    }

    /**
     * Init factory.
     *
     * @function init
     *
     * @param {object} [config = {}] - Notification event data retriever config.
     *
     * @memberof Mojito.Services.SportsContent.NotificationEvents.serviceFactory
     */
    init(config = {}) {
        this.service = config.service || NULL_SERVICE;
        this.service.configure({ serviceUrl: config.serviceUrl });
    }

    /**
     * Service getter.
     *
     * @function getService
     *
     * @returns {Mojito.Services.SportsContent.NotificationEvents.AbstractNotificationEventResolverService} NotificationEvents service instance.
     *
     * @memberof Mojito.Services.SportsContent.NotificationEvents.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
