import Utils from 'core/utils';

/**
 * Utility function simplifying the creation of string-based class definitions.
 *
 * @param {*} args - Variadic parameters that could be a list of strings, numbers, arrays, or an Object.
 * In the case of an Object, it should have CSS classes as keys and boolean values indicating whether
 * the class should be included.
 *
 * @returns {string} The final class string with all included class names separated by space.
 **/
export const classNames = (...args) => {
    return args
        .reduce((result, arg) => {
            if (arg) {
                const argType = typeof arg;
                if (argType === 'string' || argType === 'number') {
                    result.push(arg);
                } else if (Array.isArray(arg)) {
                    result.push(classNames.apply(null, arg));
                } else if (argType === 'object') {
                    Object.keys(arg).forEach(key => {
                        if (arg.hasOwnProperty(key) && arg[key]) {
                            result.push(key);
                        }
                    });
                }
            }
            return result;
        }, [])
        .join(' ');
};

/**
 * Create unique class id.
 *
 * @param {string} className - Class name.
 *
 * @returns {string} Class id.
 **/
export const createClassUUID = className => `${className}-${Utils.generateUUID()}`;
