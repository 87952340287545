import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import VirtualSportsMenuControllerImpl from './virtual-sports-menu-controller.jsx';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * A module for viewing virtual sports menu.
 *
 * @class VirtualSportsMenu
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.Virtuals
 */

/**
 * VirtualSportsMenu prop types.
 *
 * @property {string} [selectedItemId] - The id of the selected menu item. Typically, sport class id.
 * @property {string} [sportId] - The virtual sport id to display.
 *
 * @memberof Mojito.Modules.Virtuals.VirtualSportsMenu
 */

const propTypes = {
    selectedItemId: PropTypes.string,
    sportId: PropTypes.string,
};

export default UIControllerView(
    'VirtualSportsMenuController',
    VirtualSportsMenuControllerImpl,
    propTypes
);
