import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import channelFactory from 'services/common/content/content-channel-factory.js';
import raceRegionProvider from 'services/sports-content/content-provider/race-region-provider';
import RaceRegionsDescriptor from './descriptors.js';
import { omit } from 'mojito/utils';

const reduxInstance = MojitoCore.Services.redux;
const { RACE_REGION } = RaceRegionsDescriptor.DATA_TYPES;
const { actionsRegistry } = MojitoCore.Services.Content;
export const getRaceRegionsChannel = () =>
    channelFactory.getChannel(raceRegionProvider, RACE_REGION);

/**
 * Defines the state of the race regions store.
 *
 * @typedef RaceRegionsState
 *
 * @property {object} raceRegions - Race regions. Object where keys are race region ids and values are race region data.
 *
 * @memberof Mojito.Services.SportsContent.RaceRegions
 */

/**
 * The name of the race regions state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.RaceRegions
 */
export const STORE_KEY = 'raceRegionsStore';

export const INITIAL_STATE = {
    raceRegions: {},
};

export const { reducer, actions } = createSlice({
    name: 'raceRegions',
    initialState: INITIAL_STATE,
    reducers: {
        updateRaceRegion(state, { payload }) {
            const { raceRegionId, raceRegionData } = payload;
            if (raceRegionId) {
                state.raceRegions[raceRegionId] = raceRegionData;
            }
        },
        removeRaceRegions(state, { payload }) {
            const { raceRegionIds } = payload;

            state.raceRegions = omit(state.raceRegions, raceRegionIds);
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Race regions related actions.
 *
 * @class RaceRegionsActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.RaceRegions
 */

/**
 * Update race region.
 *
 * @function updateRaceRegion
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{raceRegionId: string, raceRegionData: object}} payload - Payload for race region update.
 *
 * @memberof Mojito.Services.SportsContent.RaceRegions.actions
 */

/**
 * Remove race regions.
 *
 * @function removeRaceRegions
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{raceRegionIds: Array<string>}} payload - Payload for race regions removal.
 *
 * @memberof Mojito.Services.SportsContent.RaceRegions.actions
 */

/**
 * Reset race regions state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.RaceRegions.actions
 */

/**
 * Subscribes to race region by list id.
 *
 * @function subscribe
 *
 * @param {{raceRegionId: string, clientId: string}} payload - Subscription payload. Contains the subscribed raceRegionId and clientId of subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to race region thunk.
 * @memberof Mojito.Services.SportsContent.RaceRegions.actions
 */
actions.subscribe =
    ({ raceRegionId, clientId }) =>
    dispatch => {
        const onData = (raceRegionId, raceRegionData) => {
            dispatch(actions.updateRaceRegion({ raceRegionId, raceRegionData }));
        };
        getRaceRegionsChannel().subscribe([raceRegionId], clientId, onData);
    };

/**
 * Unsubscribe from race regions.
 *
 * @function unsubscribe
 *
 * @param {string} clientId - The clientId of subscriber to unsubscribe.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from race regions thunk.
 * @memberof Mojito.Services.SportsContent.RaceRegions.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getRaceRegionsChannel().unsubscribeAll(clientId, raceRegionIds => {
        dispatch(actions.removeRaceRegions({ raceRegionIds }));
    });
};
actionsRegistry.addSubscription(RACE_REGION, actions.subscribe, actions.unsubscribe);

reduxInstance.injectReducer(STORE_KEY, reducer);
