import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * Abstract SSO authentication service.
 *
 * @class AbstractSSOService
 * @abstract
 * @memberof Mojito.Services.Authentication
 */
export default class AbstractSSOService {
    /**
     * Check if logged in through SSO.
     * Typical implementations would check for some sort of cookie to determine if logged in.
     * If a valid credentials are provided the user is considered logged in.
     *
     * @param {Mojito.Services.Authentication.AbstractSSOService#loginCallback} callback - Response callback.
     * @function Mojito.Services.Authentication.AbstractSSOService#checkIfLoggedInBySSO
     */
    // eslint-disable-next-line no-unused-vars
    checkIfLoggedInBySSO(callback) {
        log.error('checkIfLoggedInBySSO must be implemented by concrete subclass!');
    }

    /**
     * Login request using credentials.
     *
     * @param {Mojito.Services.Authentication.types.AuthCredentials} credentials - User authentication credentials.
     * @param {Mojito.Services.Authentication.AbstractSSOService#loginCallback} successCallback - Login success callback.
     * @param {Mojito.Services.Authentication.AbstractSSOService#loginErrorCallback} errorCallback - Login error callback.
     * @function Mojito.Services.Authentication.AbstractSSOService#login
     */
    // eslint-disable-next-line no-unused-vars
    login(credentials, successCallback, errorCallback) {
        log.error('login must be implemented by concrete subclass!');
    }

    /**
     * Logout request.
     *
     * @function Mojito.Services.Authentication.AbstractSSOService#logout
     */
    logout() {
        log.error('logout must be implemented by concrete subclass!');
    }
}

/**
 * Callback function that should be called to perform login to towards betting platform.
 *
 * @function Mojito.Services.Authentication.AbstractSSOService#loginCallback
 * @param {string} userName - User name to perform login with.
 * @param {string} sessionToken - Third party session token to login with.
 * @param {string} sessionCreationTime - Time when session was created.
 */

/**
 * @function Mojito.Services.Authentication.AbstractSSOService#loginErrorCallback
 * @param {Mojito.Core.Services.Transactions.types.Error} errorInfo - Object defines error details.
 */
