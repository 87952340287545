import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import intentActionTrigger from 'presentation/base/intents/actiontrigger.js';
import DocumentHead from 'presentation/components/document-head/index.jsx';
import DefaultHeadMetaFactory from 'presentation/components/document-head/default-head-meta-factory.js';
import { useDispatch } from 'react-redux';

const intentActions = MojitoCore.Intents.actions;

export default function EmptyContent({ mojitoTools }) {
    const dispatch = useDispatch();
    const { stringResolver, config, l10n } = mojitoTools;
    const metaFactory = config.documentHeadMetaFactory || DefaultHeadMetaFactory;
    const documentHeadMetaFactory = new metaFactory(l10n);

    const onClick = () => {
        const intent = config.buttonIntent;
        if (intent && intent.type) {
            dispatch(intentActions.publishIntent(intent.type, intent.data));
        } else {
            intentActionTrigger.showHomePage();
        }
    };

    return (
        <>
            <DocumentHead
                config={config.documentHeadEmptyContent}
                metaFactory={documentHeadMetaFactory}
            />
            <FlexPane config={config.container} class="ta-EmptyContent">
                <FlexPane config={config.textContainer}>
                    <Text config={config.text}>
                        {stringResolver.resolveString('$EMPTY_CONTENT.TEXT')}
                    </Text>
                </FlexPane>
                <Button config={config.button} onClick={onClick}>
                    <Text config={config.buttonLabel}>
                        {stringResolver.resolveString('$EMPTY_CONTENT.BUTTON_LABEL')}
                    </Text>
                </Button>
            </FlexPane>
        </>
    );
}
