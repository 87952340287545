import {PALETTE_IDS} from 'core/application/modules/ids';
import {COLOR, FONT} from '../theme';
export function getBaseComponentsConfig() {
    return {
        palette: {
            [PALETTE_IDS.STREAMING_INDICATOR]: {
                INDICATOR_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.ODDS_BUTTON]: {
                ODDS_DISABLED_BG: COLOR.BRAND.BACKGROUND3,
                ODDS_INFO_TEXT: {color: COLOR.BRAND.FOREGROUND2, fontWeight: FONT.WEIGHT_NORMAL},
                ODDS_SELECTED_INFO_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_NORMAL},
                ODDS_PRICE_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_SEMI_BOLD},
                ODDS_SELECTED_PRICE_TEXT: {color: COLOR.BRAND.BACKGROUND2, fontWeight: FONT.WEIGHT_SEMI_BOLD},
                ODDS_UNAVAILABLE_TEXT: COLOR.BRAND.FOREGROUND2,

                ODDS_PRICE_INDICATOR_DOWN_COLOR: COLOR.SEMANTIC.ERROR1,
                ODDS_PRICE_INDICATOR_UP_COLOR: COLOR.SEMANTIC.SUCCESS1,
            },
            [PALETTE_IDS.POPOVER]: {
                POPOVER_BG: COLOR.BRAND.BACKGROUND3,
                POPOVER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: 300,
                },
                POPOVER_CLOSE_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.DROPDOWN_MENU]: {
                DROPDOWN_MENU_ITEM_TEXT: COLOR.BRAND.FOREGROUND1,
                DROPDOWN_MENU_ITEM_SELECTED_TEXT: COLOR.BRAND.FOREGROUND5,
                DROPDOWN_MENU_ITEM_SELECTED_BG: COLOR.BRAND.ACCENT3,
                DROPDOWN_MENU_ITEM_BG: COLOR.BRAND.BACKGROUND1,
            },
            [PALETTE_IDS.PRIMARY_DROPDOWN]: {
                PRIMARY_DROPDOWN_BG: COLOR.BASE.PAGE,
                PRIMARY_DROPDOWN_DISABLED_TEXT: COLOR.BRAND.BACKGROUND5,
            },
            [PALETTE_IDS.SECONDARY_DROPDOWN]: {
                SECONDARY_DROPDOWN_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
                SECONDARY_DROPDOWN_DISABLED_TEXT: COLOR.BRAND.BACKGROUND5,
                SECONDARY_DROPDOWN_DISABLED_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.TERTIARY_DROPDOWN]: {
                TERTIARY_DROPDOWN_DISABLED_TEXT: COLOR.BRAND.BACKGROUND5,
            },
            [PALETTE_IDS.TOGGLE_BUTTON]: {
                TOGGLE_BUTTON_TEXT: {
                    fontWeight: FONT.WEIGHT_BOLD,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                TOGGLE_BUTTON_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
                TOGGLE_BUTTON_SELECTED_BG: COLOR.BRAND.ACCENT3,
                TOGGLE_BUTTON_DISABLED_TEXT: COLOR.BRAND.BACKGROUND5,
                TOGGLE_BUTTON_DISABLED_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.BET_FUNDS_INFO]: {
                BET_FUNDS_INFO_TEXT: COLOR.BRAND.FOREGROUND2,
                BET_FUNDS_INFO_MEDIUM_TEXT: COLOR.BRAND.FOREGROUND1,
                BET_FUNDS_INFO_EMPHASIZED_TEXT: COLOR.BRAND.FOREGROUND1,
                BET_FUNDS_INFO_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.PRIMARY_BUTTON]: {
                PRIMARY_BUTTON_BG: COLOR.BRAND.CTA1,
                PRIMARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND7,
                PRIMARY_BUTTON_DISABLED_TEXT: COLOR.BRAND.FOREGROUND3,
                PRIMARY_BUTTON_DISABLED_BG: COLOR.BRAND.FOREGROUND4,
            },
            [PALETTE_IDS.SECONDARY_BUTTON]: {
                ON_LIGHT: {
                    SECONDARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND7,
                    SECONDARY_BUTTON_BORDER_COLOR: COLOR.BRAND.FOREGROUND7,
                    SECONDARY_BUTTON_SELECTED_BG: COLOR.BRAND.FOREGROUND7,
                    SECONDARY_BUTTON_SELECTED_TEXT: COLOR.BRAND.BACKGROUND2,
                    SECONDARY_BUTTON_DISABLED_TEXT: COLOR.BRAND.FOREGROUND3,
                },
                ON_DARK: {
                    SECONDARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND4,
                    SECONDARY_BUTTON_SELECTED_BG: COLOR.BRAND.FOREGROUND5,
                    SECONDARY_BUTTON_SELECTED_TEXT: COLOR.BRAND.BACKGROUND3,
                    SECONDARY_BUTTON_DISABLED_TEXT: COLOR.BRAND.BACKGROUND3,
                    SECONDARY_BUTTON_DISABLED_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
                },
            },
            [PALETTE_IDS.TERTIARY_BUTTON]: {
                ON_LIGHT: {
                    TERTIARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND1,
                    TERTIARY_BUTTON_SELECTED_BG: COLOR.BRAND.FOREGROUND1,
                    TERTIARY_BUTTON_SELECTED_TEXT: COLOR.BRAND.FOREGROUND5,
                    TERTIARY_BUTTON_DISABLED_TEXT: COLOR.BRAND.FOREGROUND3,
                },
                ON_DARK: {
                    TERTIARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND4,
                    TERTIARY_BUTTON_SELECTED_BG: COLOR.BRAND.FOREGROUND5,
                    TERTIARY_BUTTON_SELECTED_TEXT: COLOR.BRAND.BACKGROUND3,
                    TERTIARY_BUTTON_DISABLED_TEXT: COLOR.BRAND.BACKGROUND3,
                },
            },
            [PALETTE_IDS.PIVOT]: {
                PIVOT_INDICATOR_BG: COLOR.BRAND.ACCENT1,
                PIVOT_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_NORMAL},
            },
            [PALETTE_IDS.PRIMARY_LINK]: {
                PRIMARY_LINK_TEXT: COLOR.BRAND.FOREGROUND1,
                PRIMARY_LINK_BG: COLOR.BRAND.BACKGROUND4,
                PRIMARY_LINK_BORDER_COLOR: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.SECONDARY_LINK]: {
                SECONDARY_LINK_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
            },
            [PALETTE_IDS.TERTIARY_LINK]: {
                TERTIARY_LINK_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
            },
            [PALETTE_IDS.CLOSE_BUTTON]: {
                CLOSE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                CIRCULAR_CLOSE_BUTTON_BG: COLOR.BRAND.BACKGROUND3,
            },
            [PALETTE_IDS.EVENT_TIMING_STATUS]: {
                EVENT_TIMING_LIVE_TEXT: {color: COLOR.BRAND.ACCENT2, fontWeight: COLOR.BRAND.WEIGHT_NORMAL},
                EVENT_TIMING_UPCOMING_TEXT: {color: COLOR.BRAND.FOREGROUND2, fontWeight: COLOR.BRAND.WEIGHT_NORMAL},
                EVENT_TIMING_SEPARATOR_COLOR: COLOR.BASE.DIVIDER,
            },
            [PALETTE_IDS.MINI_SCOREBOARD]: {
                MINI_SCOREBOARD_SCORES_TEXT: COLOR.BRAND.FOREGROUND1,
                MINI_SCOREBOARD_SCORE_COUNT_TEXT: {
                    fontFamily: FONT.FAMILY,
                    color: COLOR.BRAND.ACCENT2,
                },
                MINI_SCOREBOARD_SEGMENT_INDICATOR_TEXT: COLOR.BRAND.FOREGROUND2,
                MINI_SCOREBOARD_SERVER_INDICATOR: COLOR.BRAND.ACCENT2,
                MINI_SCOREBOARD_GOAL_BG: COLOR.BRAND.ACCENT1,
                MINI_SCOREBOARD_GOAL_TEXT: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.RUNNER_INFO]: {
                RUNNER_CONTAINER_TEXT: COLOR.BRAND.FOREGROUND2,
                RUNNER_DRAW_NUMBER_TEXT: COLOR.BRAND.FOREGROUND2,
                RUNNER_TRAINER_AND_JOCKEY_NAMES_TEXT: COLOR.BRAND.FOREGROUND2,
            },
            [PALETTE_IDS.TABLE]: {
                TABLE_HEADER_BG: COLOR.BRAND.BACKGROUND5,
                TABLE_HEADER_TEXT: COLOR.BRAND.FOREGROUND2,
                TABLE_ITEM_BG: COLOR.BRAND.BACKGROUND1,
                TABLE_ITEM_SELECTED_BG: COLOR.BRAND.ACCENT3,
                TABLE_ITEM_TEXT: `${COLOR.BRAND.FOREGROUND1}|${COLOR.BRAND.BACKGROUND1}`,
            },
            [PALETTE_IDS.PARTICIPANTS]: {
                PARTICIPANTS_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                },
                PITCHER_TEXT: COLOR.BRAND.FOREGROUND2,
                PARTICIPANTS_SEPARATOR_COLOR: COLOR.BRAND.FOREGROUND2,
            },
            [PALETTE_IDS.BET_STATUS_ICON]: {
                OPEN_BET_ICON_COLOR: COLOR.RESERVED.ACCENT,
                VOID_BET_ICON_COLOR: COLOR.RESERVED.BLACK,
            },
            [PALETTE_IDS.BET_STATUS_ICON]: {
                OPEN_BET_ICON_COLOR: COLOR.BRAND.ACCENT1,
                VOID_BET_ICON_COLOR: COLOR.RESERVED.WHITE,
            },
            [PALETTE_IDS.BET_BASE_INFO]: {
                BET_MARKET_NAME_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
                BET_DATE_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                BET_MARKET_CVI: {
                    CVI_PREVIOUS_VALUE_TEXT: COLOR.BRAND.FOREGROUND2,
                    CVI_NEW_VALUE_TEXT: COLOR.BRAND.FOREGROUND2,
                },
                BET_BUILDER_BET_MARKET_NAME_TEXT: COLOR.BRAND.FOREGROUND2,
            },
            [PALETTE_IDS.BET_BUILDER_SELECTION_ITEM]: {
                BB_BET_MARKET_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                BB_BET_ICON_COLOR: COLOR.BRAND.FOREGROUND3,
                BB_BET_PATH_LINE_COLOR: COLOR.BRAND.FOREGROUND3,
                BB_BET_SUSPENDED_NOTIFICATION: {
                    INFO_TEXT: {
                        color: COLOR.RESERVED.BLACK,
                        fontSize: FONT.SIZE_XSMALL,
                    },
                },
            },
            [PALETTE_IDS.PROGRESS_BAR]: {
                PROGRESS_BAR_LINE_COLOR: COLOR.BRAND.BACKGROUND5,
                PROGRESS_BAR_FILL_LINE_COLOR: COLOR.BRAND.ACCENT1,
                PROGRESS_BAR_RANGE_LABEL_TEXT: COLOR.BRAND.FOREGROUND1,
                PROGRESS_BAR_LIMIT_LABEL_TEXT: COLOR.BRAND.FOREGROUND2,
            },
            [PALETTE_IDS.SWITCH]: {
                SWITCH_CONTAINER_BG: COLOR.RESERVED.TRANSPARENT,
                SWITCH_CONTAINER_BORDER_COLOR: COLOR.BRAND.ACCENT3,
                SWITCH_DISABLED_BUTTON_BG: COLOR.BRAND.FOREGROUND2,
                SWITCH_DISABLED_CONTAINER_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
                SWITCH_BUTTON_BG: COLOR.BRAND.ACCENT3,
                SWITCH_ICON_COLOR: COLOR.BRAND.BACKGROUND2,
                SWITCH_LABEL_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                SWITCH_DISABLED_LABEL_TEXT: COLOR.BRAND.FOREGROUND3,
            },
            [PALETTE_IDS.CHECKBOX]: {
                CHECKBOX_BG: COLOR.BRAND.FOREGROUND1,
                CHECKBOX_LABEL: COLOR.BRAND.FOREGROUND1,
                CHECKBOX_ICON_COLOR: COLOR.BRAND.FOREGROUND7,
            },
            [PALETTE_IDS.RANGE_INPUT]: {
                RANGE_THUMB_COLOR: COLOR.BRAND.ACCENT3,
                RANGE_TRACK_COLOR: COLOR.BRAND.FOREGROUND4,
                RANGE_TRACK_DISABLED_COLOR: COLOR.BRAND.FOREGROUND3,
                RANGE_THUMB_DISABLED_COLOR: COLOR.BRAND.FOREGROUND3,
                RANGE_LABEL_TEXT: {color: COLOR.BRAND.FOREGROUND2, fontWeight: FONT.WEIGHT_NORMAL},
            },
            [PALETTE_IDS.INPUT]: {
                INPUT_BG: 'transparent',
                INPUT_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
                INPUT_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_NORMAL},
                INPUT_LABEL_TEXT: COLOR.BRAND.FOREGROUND2,
                INPUT_PLACEHOLDER_TEXT: COLOR.BRAND.FOREGROUND1,
                INPUT_ERROR_TEXT: COLOR.BRAND.ERROR1,
                INPUT_FOCUSED_BORDER_COLOR: COLOR.BRAND.ACCENT1,
            },
            [PALETTE_IDS.STAKE_INPUT]: {
                STAKE_INPUT_BG: COLOR.RESERVED.TRANSPARENT,
                STAKE_INPUT_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_NORMAL},
                STAKE_INPUT_ERROR_TEXT: COLOR.BRAND.ERROR1,
                STAKE_INPUT_FOCUSED_BORDER_COLOR: COLOR.BRAND.ACCENT1,
                STAKE_INPUT_BORDER_COLOR: COLOR.BRAND.FOREGROUND2,
                STAKE_INPUT_DISABLED_BG: COLOR.RESERVED.TRANSPARENT,
            },
            [PALETTE_IDS.DESKTOP_CMS_LINKS]: {
                DESKTOP_CMS_LINKS_BG: COLOR.BRAND.BACKGROUND1,
                DESKTOP_CMS_LINKS_HEADER_BG: COLOR.BRAND.BACKGROUND6,
            },
            [PALETTE_IDS.DESKTOP_INFO_SECTION]: {
                INFO_SECTION_BG: COLOR.BRAND.BACKGROUND1,
            },
            [PALETTE_IDS.ACCEPT_DIALOG]: {
                ACCEPT_DIALOG_BG: COLOR.BRAND.FOREGROUND1,
            },
            [PALETTE_IDS.EARLY_PAYOUT_INDICATOR]: {
                EARLY_PAYOUT_POPOVER_BG: COLOR.BRAND.BACKGROUND5,
                EARLY_PAYOUT_POPOVER_LINK: {
                    TERTIARY_LINK_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                },
            },
            [PALETTE_IDS.PRICE_BOOST_LABEL]: {
                PRICE_BOOST_LABEL_ICON_CONTAINER_BG: COLOR.BRAND.ACCENT1,
            },
        },
    };
}
