import PropTypes from 'prop-types';
import SearchControllerViewImpl from './search-controller-view.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const { UIControllerView } = MojitoCore.Presentation;

/**
 * Module for performing data search input and result presentation.
 *
 * @class SearchControllerView
 * @name Search
 * @memberof Mojito.Application
 */

/**
 * SearchControllerView prop types.
 *
 * @property {boolean} [initiallyActive = false] - Whether the Search View should be initially active.
 * @property {Function} [onSearchClose = ()=>{}] - On search close callback.
 *
 * @memberof Mojito.Application.Search.SearchView
 */
const propTypes = {
    initiallyActive: PropTypes.bool,
    onSearchClose: PropTypes.func,
};

const defaultProps = {
    initiallyActive: false,
    onSearchClose: noop,
};

export default UIControllerView(
    'SearchControllerView',
    SearchControllerViewImpl,
    propTypes,
    defaultProps
);
