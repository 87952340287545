import MojitoCore from 'mojito/core';
const CORRELATION_ID = MojitoCore.Services.Transactions.types.HEADER.CORRELATION_ID;

export const beforeBreadcrumb = (breadcrumb, hint) => {
    if (breadcrumb.category === 'console') {
        // filter out DBX initialization log
        if (hint.level === 'info' && hint.input[0].match(/^DBX/)) {
            return null;
        }
        if (hint.level === 'log' && breadcrumb.message.startsWith('[batch] MIXPANEL REQUEST')) {
            return null;
        }
        return breadcrumb;
    }

    // filter out analytics stuff
    if (breadcrumb.category === 'xhr') {
        const url = breadcrumb.data?.url;
        if (
            url.indexOf('clarity.ms') >= 0 ||
            url.startsWith('https://api-js.mixpanel.com') ||
            url.startsWith('https://www.google-analytics.com') ||
            url.startsWith('https://stats.g.doubleclick.net') ||
            url.startsWith('https://rollout.ada.support') // frontend security 3rd party lib
        )
            return null;

        return breadcrumb;
    }
    // Enrich with correlationId
    if (breadcrumb.category === 'fetch' && breadcrumb.data && hint.input[0]?.headers) {
        breadcrumb.data.correlationId = hint.input[0].headers.get(CORRELATION_ID);
        return breadcrumb;
    }
    return breadcrumb;
};
