/**
 * Events types.
 *
 * @class EventTypes
 * @name types
 * @memberof Mojito.Services.SportsContent.Events
 */
export default class EventTypes {}

/**
 * Race events race status.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.RACE_STATUS = {
    UPCOMING: 'UPCOMING',
    OFF: 'OFF',
    FINISHED: 'FINISHED',
    UNKNOWN: 'UNKNOWN',
};

/**
 * Race events race states. For now, only abandoned has special handling.
 * Other states are not listed.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.RACE_STATE = {
    ABANDONED: 'ABANDONED',
};

/**
 * Event types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.EVENT_TYPE = {
    MATCH: 'MATCH',
    OUTRIGHT: 'OUTRIGHT',
    RACE: 'RACE',
};

/**
 * Market type categories. Each category contains set of types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.MARKET_TYPE_CATEGORY = {
    UNKNOWN: 'UNKNOWN',
    TEAM: 'TEAM',
    TEAM_HDA: 'TEAM_HDA',
    TEAM_HA: 'TEAM_HA',
    PARTICIPANT: 'PARTICIPANT',
    OVER_UNDER: 'OVER_UNDER',
    OVER_EXACTLY_UNDER: 'OVER_EXACTLY_UNDER',
    DOUBLE_CHANCE: 'DOUBLE_CHANCE',
    TOTAL_SET: 'TOTAL_SET',
    HALF_TIME_FULL_TIME: 'HALF_TIME_FULL_TIME',
    YES_NO: 'YES_NO',
    ODD_EVEN: 'ODD_EVEN',
};

const { MARKET_TYPE_CATEGORY } = EventTypes;
/**
 * Defines list of market types categories that are considered as
 * team or participant.
 *
 * @constant
 * @type {Array}
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.TEAM_OR_PARTICIPANT_TYPES = [
    MARKET_TYPE_CATEGORY.TEAM,
    MARKET_TYPE_CATEGORY.TEAM_HDA,
    MARKET_TYPE_CATEGORY.TEAM_HA,
    MARKET_TYPE_CATEGORY.PARTICIPANT,
];

/**
 * In play clock details that can be provided by an event.
 *
 * @typedef IN_PLAY_CLOCK_DETAILS
 * @property {number} time - Current in play time in seconds.
 * @property {boolean} isClockRunning - True if clock is running and false if clock has stopped.
 * @property {Mojito.Services.SportsContent.Events.types.CLOCK_DIRECTION} clockDirection - Clock direction.
 *
 * @memberof Mojito.Services.SportsContent.Events.types
 */

/**
 * Clock direction details that can be provided by an in play event.
 *
 * @typedef CLOCK_DIRECTION
 * @property {string} F - Forward direction. A clock should count up.
 * @property {string} B - Backward direction. A clock should count down.
 *
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.CLOCK_DIRECTION = {
    F: 'F',
    B: 'B',
};

/**
 * Participant position.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.PARTICIPANT_POSITION = {
    HOME: 'HOME',
    AWAY: 'AWAY',
};

/**
 * Events origin, defines from what part of content collection the event is originated.
 * Can be useful to prepare bulk subscription for events chunk for particular origin.
 * For example to load all events for banners and event cards in one chunk.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.CONTENT_ORIGIN = {
    PROMOTION_EVENTS: 'PromotionEvents',
    COUPON_EVENTS: 'CouponEvents',
    QUICK_NAVIGATION_EVENTS: 'QuickNavigationEvents',
    PROMOTION_MARKETS: 'PromotionMarkets',
    COUPON_MARKETS: 'CouponMarkets',
    EVENT_DETAILS_MARKETS: 'EventDetailsMarkets',
};

/**
 * Starting price odds.
 *
 * @readonly
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.STARTING_PRICE_ODDS = '-1.00';

/**
 * Unavailable odds.
 *
 * @readonly
 * @memberof Mojito.Services.SportsContent.Events.types
 */
EventTypes.UNAVAILABLE_ODDS = '-2.00';

/**
 * @typedef AggregatedMarket
 * @type {object}
 * @property {string} key - Unique key value.
 * @property {string} name - Market name.
 * @property {string} mostBalancedLineMarketId - The most balanced line market id. It is applicable only for handicap market types. It determines the default position of the range input in the handicap market.
 * @property {string} type - Market type. Contains unified type to which all child markets belongs to.
 * @property {string} typeCategory - Market type category. Contains unified type category to which all child markets belongs to.
 * @property {number} displayOrder - Market display order. Contains the value from child market that has the lowest display order.
 * @property {boolean} cashoutAvailable - True if all child markets are available for cash out, false otherwise.
 * @property {string} eachWayTerms - Each way terms from first child market.
 * @property {Array<object>} markets - List of child markets that belongs to particular aggregated market.
 * @property {Array<Mojito.Services.SportsContent.MarketGroups.types.LINE_TYPE>} mask - Market lines types mask, reflects the type of market line within marketIds list.
 *
 * @memberof Mojito.Services.SportsContent.Events.types
 */

/**
 * @typedef MarketLine
 * @type {object}
 * @property {string} id - Market id.
 * @property {boolean} fallback - True if market like represents fallback market. The fallback markets are typically presented differently on a coupons.
 *
 * @memberof Mojito.Services.SportsContent.Events.types
 */
