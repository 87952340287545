import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';
import { withRetry } from 'services/utils';

const log = MojitoCore.logger.get('BootstrapController');
const TaskExecutor = MojitoCore.Services.TaskExecutor;

const voidResponder = () => {
    log.warn('bootstrapService instance is missing.');
    return Promise.reject();
};
const NULL_SERVICE = {
    configure: noop,
    initApi: voidResponder,
};

const INIT_API_RETRIES = 2;

/**
 * Bootstrap controller config.
 *
 * @typedef BootstrapControllerConfig
 * @type {object}
 * @property {Mojito.Services.Bootstrap.AbstractBootstrapService} service - Instance of concrete bootstrap service implementation.
 * @property {string} serviceUrl - URL that will be used by <code>service<code/> instance.
 *
 * @memberof Mojito.Services.Bootstrap.controller
 */

/**
 * Bootstrap controller used to delegate calls to bootstrap service abstraction to init Mojito transactional API.
 * Guarantees that API will be initialised before any request added through {@link Mojito.Services.Bootstrap.controller#addWaitingRequest|addWaitingRequest} method is triggered.
 * This is useful if we want to allow backend API to be setup in any specific way before client starts regular communication with it.
 * For example, client might use this controller to send current channel and locale to the backend. Afterwards this information will be taken into consideration by a server on each farther request.
 *
 * @class BootstrapController
 * @name controller
 * @memberof Mojito.Services.Bootstrap
 */
class BootstrapController {
    constructor() {
        this.service = NULL_SERVICE;
        this.waitingRequests = [];
        this.initTask = this.initApi.bind(this);
        this.waitingRequestsTask = this.executeWaitingRequests.bind(this);

        this.taskExecutor = new TaskExecutor();
        this.initApi = this.taskExecutor.withQueue(this.initTask);
        this.executeWaitingRequests = this.taskExecutor.withQueue(this.waitingRequestsTask);
    }

    /**
     * Api initialization is in progress.
     *
     * @returns {boolean} True if API initialization is in progress, false otherwise.
     * @function Mojito.Services.Bootstrap.controller#isInitializing
     */
    isInitializing() {
        return this.taskExecutor.hasTask(this.initTask);
    }

    /**
     * Configures controller with service implementation and API endpoint URL.
     *
     * @param {Mojito.Services.Bootstrap.controller.BootstrapControllerConfig} [config = {}] - Bootstrap data retriever config.
     * @function Mojito.Services.Bootstrap.controller#configure
     */
    configure({ service, serviceUrl } = {}) {
        this.service = service || NULL_SERVICE;
        this.service.configure({ serviceUrl });
        this.waitingRequests = [];
        this.taskExecutor.abortAll();
        this.serviceInitCall = this.service.initApi.bind(this.service);
    }

    /**
     * Initialise mojito transactional API with provided config.
     * Will initiate waiting requests execution that were added through {@link Mojito.Services.Bootstrap.controller#addWaitingRequest|addWaitingRequest} method.
     * This function will be called after mojito initialisation is finalised.
     *
     * @param {Mojito.Services.Common.types.TransactionalAPIConfig} config - API config.
     * @returns {Promise} Init API promise.
     * @function Mojito.Services.Bootstrap.controller#initApi
     */
    initApi(config) {
        // Calling executeWaitingRequests will add it to the task executor queue.
        // Do it only if it is not there yet.
        if (!this.taskExecutor.hasTask(this.waitingRequestsTask)) {
            this.executeWaitingRequests();
        }
        const initTask = withRetry(this.serviceInitCall, INIT_API_RETRIES);
        return initTask(config);
    }

    /**
     * Add request to the waiting list. The request will be postponed until API initialised,
     * see {@link Mojito.Services.Bootstrap.controller#initApi|initApi} method.
     *
     * @param {Mojito.Core.Services.Transactions.PostponedRequest} request - Postponed request. To be executed after <code>initApi</code> done.
     * @function Mojito.Services.Bootstrap.controller#addWaitingRequest
     */
    addWaitingRequest(request) {
        if (!this.waitingRequests.includes(request)) {
            this.waitingRequests.push(request);
        }
    }

    /**
     * Executes all requests added with addWaitingRequest method. These requests are waiting until initApi is done.
     *
     * @private
     * @returns {Promise} Promise that always resolves successfully. Needed for proper queue processing inside TaskExecutor.
     * @function Mojito.Services.Bootstrap.controller#addWaitingRequest
     */
    executeWaitingRequests() {
        this.waitingRequests.forEach(request => request.execute());
        this.waitingRequests = [];
        return Promise.resolve();
    }
}

export default new BootstrapController();
