import translations from './config/translations.l10n.yaml'; // This will be transpiled by webpack.translations-loader
import defaultConfig from './config/default-config.yaml';
import fonts from './config/fonts.yaml';
import {BASIC_PALETTE} from './config/theme';
import {configureSportsbook} from 'core/application-initializer';
import {getTokens} from './config/viewsconfig';

configureSportsbook({
    getTokensFn: getTokens,
    configuration: {
        ...defaultConfig,
        translations,
        palette: {
            BASIC: BASIC_PALETTE,
        },
        fonts,
    },
});
