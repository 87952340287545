import PropTypes from 'prop-types';
import TaxImpl from './tax-info.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Component to display tax of bet funds.
 *
 * @class TaxInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * TaxInfo props.
 *
 * @property {number} value - Tax value.
 * @property {boolean} [isPotential] - Flag to show potential label.
 * @property {boolean} [hasDivider] - Flag to append $BET_FUNDS.DIVIDER to label.
 * @property {string} currencyCode - Currency.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.TaxInfo
 */
const propTypes = {
    value: PropTypes.number.isRequired,
    percent: PropTypes.number,
    isPotential: PropTypes.bool,
    hasDivider: PropTypes.bool,
    currencyCode: PropTypes.string.isRequired,
};

export default UIView('TaxInfo', TaxImpl, propTypes);
