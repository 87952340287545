import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * System information related selectors.
 *
 * @module SystemInformationSelectors
 * @name selectors
 * @memberof Mojito.Services.SystemInformation
 */

/**
 * Selects system information state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SystemInformation.SystemInformationState} The state of the system information store.
 * @memberof Mojito.Services.SystemInformation.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};
