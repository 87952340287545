const DBXCustomStrategies = {
    INTERNAL: 'internal',
    ACCOUNT_POPUP: 'accountPopup',
    FEATURE: 'feature',
    NAMED_INTENT: 'intent',
    NAVIGATE_HOST: 'navigateHost'
};

const OpenUrlStrategies = {
    SAME: 0, // Same window
    FRAME: 1, // iframe / webview
    BROWSER: 2, // Separate browser tab
    NATIVE_CASINO: 3, // Native casino hub
    NATIVE_GAMES: 4, // Native games lobby
    SAME_NO_BACK: 5, // Same window. No back url
    WINDOW: 6, // Separate window above original
    NATIVE_WEBVIEW: 7, // Native webview
};

const DBXCustomStrategiesValues = Object.values(DBXCustomStrategies);

function isValidCustomStrategy(strategy) {
    return DBXCustomStrategiesValues.includes(strategy);
}

function isValidIntentStrategy(strategy) {
    return strategy && /^[0-256][025][0-57]$/.test(strategy);
}

module.exports = {
    DBXCustomStrategies,
    OpenUrlStrategies,
    isValidCustomStrategy,
    isValidIntentStrategy,
};