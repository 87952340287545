import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { PostponedRenderer } from '../../../presentation/components/_namespace';

const { useComponentVisibility } = MojitoPresentation.Hooks;
const ContentPreloader = MojitoCore.Services.Content.ContentPreloader;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const ContentPreloaderContext = MojitoCore.Presentation.ContentPreloaderContext;
const { TopSports, NextRaces, QuickStartGuideBanner } = MojitoModules;
const { BannerPromotions, MenuLinkPromotions, PromotionUtils, PrebuiltPromotionCards } =
    MojitoModules.Promotions;
const { FlexPane, DocumentHead, GlobalLinks, PageSplitLayout, DefaultHeadMetaFactory } =
    MojitoPresentation.Components;
const EventCardsCarousel = MojitoModules.EventCardsCarousel.module;
const PageContextMenu = MojitoModules.PageContextMenu;
const SportsMenu = MojitoModules.SportsMenu.module;
const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const log = MojitoCore.logger.get('HomePage');
const extendAnalyticsContext = (context, value) => ({ ...context, ...value });

export default class HomePage extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.onTopPromotionsLinkClick = this.onTopPromotionsLinkClick.bind(this);

        // Creating the MetaFactory instance here to avoid re-creating it on every render()
        const metaFactory = this.config.documentHeadMetaFactory || DefaultHeadMetaFactory;
        this.documentHeadMetaFactory = new metaFactory(this.l10n);
        this.analyticsContext = {
            marketChangeAnalyticsType: 'homePageEventListMarketSelectorValueChanged',
        };

        this.contentPreloader = new ContentPreloader();
        this.renderBannersPromotion = this.renderBannersPromotion.bind(this);

        const { primary, secondary } = this.config.topSportCoupons;

        if (
            (!primary.hide && !primary.coupon.sportListName) ||
            !primary.coupon.view.eventGroupSuffix
        ) {
            log.error('Configuration for primary top sports missing!');
        }

        if (
            (!secondary.hide && !secondary.coupon.sportListName) ||
            !secondary.coupon.view.eventGroupSuffix
        ) {
            log.error('Configuration for secondary top sports missing!');
        }
    }

    onTopPromotionsLinkClick(menuItem) {
        this.emitAnalytics('sportsCarouselLinkClicked', menuItem.id);
    }

    renderQuickStartGuideBanner() {
        if (this.config.hideQuickStartGuideBanner) {
            return null;
        }

        return <QuickStartGuideBanner />;
    }

    renderTopContextMenu() {
        if (this.config.hideTopContextMenu) {
            return null;
        }

        return <PageContextMenu config={this.config.topContextMenu} applicablePage="homepage" />;
    }

    renderSecondaryPromotions() {
        const { leftPromotions: promotionsConfig } = this.config;
        if (!PromotionUtils.hasLocationId(promotionsConfig)) {
            return;
        }

        return (
            <BannerPromotions
                config={promotionsConfig}
                key={promotionsConfig.locationId}
                applicablePage="homepage"
            />
        );
    }

    renderNextRaces() {
        const nextRaces = this.config.nextRacesSports.map(nextRaceConfig =>
            nextRaceConfig.hide ? null : (
                <NextRaces
                    key={nextRaceConfig.sportId}
                    sportId={nextRaceConfig.sportId}
                    config={this.config.nextRacesController}
                />
            )
        );

        return nextRaces.some(nextRace => !!nextRace) ? <>{nextRaces}</> : null;
    }

    renderDocumentHead() {
        const headData = {
            title: '$HOME_PAGE.PAGE_TITLE',
            keywords: '$HOME_PAGE.PAGE_KEYWORDS',
            description: '$HOME_PAGE.PAGE_DESCRIPTION',
            canonical: '$HOME_PAGE.PAGE_CANONICAL',
        };

        return <DocumentHead data={headData} metaFactory={this.documentHeadMetaFactory} />;
    }

    renderBannersPromotion() {
        const { topBannerPromotions } = this.config;
        if (!PromotionUtils.hasLocationId(topBannerPromotions)) {
            return;
        }
        return <BannerPromotions config={topBannerPromotions} applicablePage="homepage" />;
    }

    renderLinkPromotions() {
        const { topLinkPromotions } = this.config;
        if (!PromotionUtils.hasLocationId(topLinkPromotions)) {
            return;
        }
        return (
            <MenuLinkPromotions
                config={topLinkPromotions}
                applicablePage="homepage"
                onLinkClick={this.onTopPromotionsLinkClick}
            />
        );
    }

    render() {
        return (
            <>
                {this.renderDocumentHead()}
                <PageSplitLayout>
                    {/* Primary content */}
                    <ContentPreloaderContext.Provider value={this.contentPreloader}>
                        <FlexPane config={this.config.primaryContentLayout}>
                            <FlexPane config={this.config.topPromotionsContainer}>
                                {this.renderLinkPromotions()}
                                {this.renderBannersPromotion()}
                            </FlexPane>

                            <EventCardsCarousel
                                config={this.config.eventCardsCarousel}
                                pageId={'homepage'}
                            />

                            <Outlet />

                            {this.renderTopContextMenu()}
                            {this.renderQuickStartGuideBanner()}
                            <PrebuiltPromotionCards
                                applicablePage={'homepage'}
                                config={this.config.prebuiltPromotionCards}
                            />
                            {this.renderNextRaces()}

                            <PostponedRenderer delay={0}>
                                <AnalyticsContextExtender
                                    value={this.analyticsContext}
                                    extendingStrategy={extendAnalyticsContext}
                                >
                                    {/* Primary Top X Sports */}
                                    <TopSportsSection config={this.config}>
                                        {!this.config.topSportCoupons.primary.hide && (
                                            <TopSports
                                                navigationTitle={this.resolveString(
                                                    '$HOME_PAGE.PRIMARY_TOP_SPORTS_TITLE'
                                                )}
                                                config={this.config.topSportCoupons.primary.coupon}
                                            />
                                        )}

                                        {/* Secondary Top X Sports */}
                                        {!this.config.topSportCoupons.secondary.hide && (
                                            <TopSports
                                                navigationTitle={this.resolveString(
                                                    '$HOME_PAGE.SECONDARY_TOP_SPORTS_TITLE'
                                                )}
                                                config={
                                                    this.config.topSportCoupons.secondary.coupon
                                                }
                                            />
                                        )}
                                    </TopSportsSection>
                                </AnalyticsContextExtender>
                            </PostponedRenderer>
                        </FlexPane>
                    </ContentPreloaderContext.Provider>

                    {/* Secondary content */}
                    <FlexPane config={this.config.secondaryContentContainer}>
                        <PageContextMenu
                            config={this.config.leftContextMenu}
                            applicablePage="homepage"
                        />
                        <SportsMenu />
                        <GlobalLinks />
                        {/* Left promotion */}
                        {this.renderSecondaryPromotions()}
                    </FlexPane>
                </PageSplitLayout>
            </>
        );
    }
}

const TopSportsSection = props => {
    const { config, children } = props;
    const [visible, setVisible] = useState(!config.topSportCoupons.optimizeRendering);
    const onShowUp = () => setVisible(true);
    const elementRef = useComponentVisibility(onShowUp);
    return (
        <FlexPane
            elementRef={elementRef}
            class="ta-TopSportsSection"
            config={config.topSportsContainer}
        >
            {visible ? children : null}
        </FlexPane>
    );
};

HomePage.ALL_SPORTS_CODE = 'ALL';

HomePage.getStyle = config => ({
    margin: config.margin,
    display: 'flex',
    flexDirection: 'column',
});
