import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('AbstractTransactionService');

/**
 * Abstract bootstrap API service to be implemented by different integrations.
 * Note: All methods must be implemented in a asynchronous way and return promise.
 *
 * @class AbstractBootstrapService
 * @abstract
 * @memberof Mojito.Services.Bootstrap
 */
export default class AbstractBootstrapService {
    /**
     * Configure service instance.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @function Mojito.Services.Bootstrap.AbstractBootstrapService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Initialise transactional API with provided config.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @returns {Promise} Init API promise.
     * @function Mojito.Services.Bootstrap.AbstractBootstrapService#initApi
     */
    // eslint-disable-next-line no-unused-vars
    initApi(config) {
        log.error('initApi must be implemented by concrete subclass');
        return Promise.reject();
    }
}
