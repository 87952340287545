import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import ServicesTypes from 'services/common/types.js';

const reduxInstance = MojitoCore.Services.redux;
const { CONTENT_STATE } = ServicesTypes;

/**
 * Selects item list state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.ItemList} The state of the item list store.
 * @memberof Mojito.Services.ItemList.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Get item list object.
 *
 * @function selectItemLists
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Map<string, Array<string>>} Item list object. Key is items list id and value is an array of item ids.
 * @memberof Mojito.Services.ItemList.selectors
 */
export const selectItemLists = state => selectState(state).itemLists;

/**
 * Get specified items for an item list.
 *
 * @function selectItems
 *
 * @param {string} listId - List of the item ids.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string[]} Array or item ids.
 * @memberof Mojito.Services.ItemList.selectItems
 */

export const selectItems = (listId, state) => {
    return selectItemLists(state)[listId];
};

/**
 * Select items list loading state.
 *
 * @function selectItemsState
 *
 * @param {string} listId - List id to retrieve information for.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Item list loading state.
 * @memberof Mojito.Services.ItemList.selectors
 */
export const selectItemsState = (listId, state) =>
    selectState(state)?.itemsState[listId] || CONTENT_STATE.UNKNOWN;
