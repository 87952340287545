import MojitoServices from 'mojito/services';
import { upperFirst } from 'mojito/utils';

const BetsTypes = MojitoServices.Bets.types;

const { OPEN, WON, LOST, VOID, CASHED_OUT, PLACED } = BetsTypes.BET_STATUS;

/**
 * A utility class specifically designed for use within the `BetView` component.
 *
 * @class BetViewUtils
 * @name betViewUtils
 * @memberof Mojito.Modules.OpenBets
 */
export default class BetViewUtils {
    /**
     * Returns the corresponding leg/bet status icon config for a leg.
     *
     * @param {string} status - The status of the leg/bet.
     *
     * @returns {string} The config status for the leg/bet icon.
     * @function Mojito.Modules.OpenBets.betViewUtils.getStatusIcon
     */
    static getStatusIcon(status) {
        switch (status) {
            case OPEN:
                return 'open';
            case VOID:
                return 'void';
            case WON:
            case PLACED:
                return 'won';
            case LOST:
                return 'lost';
            case CASHED_OUT:
                return 'cashedOut';
        }
    }

    /**
     * Returns the config key for a bet status text.
     *
     * @param {string} betStatus - The status of the leg/bet.
     *
     * @returns {string} The config key for the leg/bet status text.
     * @function Mojito.Modules.OpenBets.betViewUtils.getStatusTextKey
     */
    static getStatusTextKey(betStatus) {
        return upperFirst(BetViewUtils.getStatusIcon(betStatus));
    }

    /**
     * Check if bet has earlyPayout functionality enabled or not.
     *
     * @param {object} bet - Bet item.
     *
     * @returns {boolean} The earlyPayout status true or false.
     * @function Mojito.Modules.OpenBets.betViewUtils.isEarlyPayout
     */

    static isEarlyPayout(bet) {
        return !!bet?.legs?.[0]?.parts?.find(part => part.earlyPayout);
    }
}
