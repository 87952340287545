const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent || '');

/**
 * A class providing methods to make an educated guess on the type of device being used,
 * based on provided features or user agent characteristics.
 *
 * @class DeviceTypeGuesser
 * @memberof Mojito.Core.Base
 */
class DeviceTypeGuesser {
    constructor() {
        this.deviceChecker = undefined;
    }

    init(config) {
        if (config !== undefined) {
            this.deviceChecker = config.isProbablyHandHeldDevice;
        }
    }

    /**
     * Determines if the application is run on a mobile device, by doing a user agent check. It is possible to replace this method implementation by adding a {@link https://mojito-docs.preproduction02.pbs-master.com/client/device-detection/|custom implementation} to the client core layer configuration.
     *
     * @returns {boolean} By default it will return true if mobile, otherwise false. A method provided in config must also return a boolean depending on preferred behavior.
     * @function Mojito.Core.Base.DeviceTypeGuesser#isProbablyHandHeldDevice
     */
    isProbablyHandHeldDevice() {
        return this.deviceChecker
            ? this.deviceChecker()
            : navigator.userAgent && navigator.userAgent.includes('Mobi');
    }

    /**
     * Determines if the application is run on iOS mobile device.
     *
     * @returns {boolean} True if it is iOS mobile device.
     * @function Mojito.Core.Base.DeviceTypeGuesser#isIos
     */
    isIos() {
        return IS_IOS;
    }
}

export default new DeviceTypeGuesser();
