import ContentChannel from './content-channel.js';

/**
 * Default keep content alive time in milliseconds.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Common.Content
 */
const DEFAULT_KEEP_ALIVE = 500;

/**
 * Config object.
 *
 * @property {string} [contentKeepAliveTime = DEFAULT_KEEP_ALIVE] - Number of milliseconds which will be used to postpone data unsubscribe.
 * Typically, it is useful to not unsubscribe from data immediately on component unmount but rather wait for some time as it is quite possible
 * that user will navigate back to the same data in short. If set to -1 then content will be kept forever.
 *
 * @typedef ContentChannelFactoryConfig
 * @memberof Mojito.Services.Common.Content
 */
/**
 * Factory function to create an instance of {@link Mojito.Services.Common.Content.ContentChannel|ContentChannel} with predefined settings.
 * For instance, it allows setting the `contentKeepAliveTime` for the channel instance.
 *
 * @class ContentChannelFactory
 * @name contentChannelFactory
 * @memberof Mojito.Services.Common.Content
 */
class ContentChannelFactory {
    constructor() {
        this.contentKeepAliveTime = DEFAULT_KEEP_ALIVE;
        this.activeChannels = {};
    }

    /**
     * Configure channel factory. This config will be used to produce channel instances.
     *
     * @param {Mojito.Services.Common.Content.ContentChannelFactoryConfig} config - Factory config.
     * @function Mojito.Services.Common.Content.contentChannelFactory#configure
     */
    configure(config = {}) {
        const { contentKeepAliveTime = DEFAULT_KEEP_ALIVE } = config;
        this.contentKeepAliveTime = contentKeepAliveTime;
    }

    /**
     * Creates new channel instance to be used for content subscription.
     *
     * @param {Mojito.Core.Services.DataProvider} dataProvider - Data provider instance, will be used to subscribe to entity.
     *
     * @returns {Mojito.Services.Common.Content.ContentChannel} The instance of content channel.
     * @function Mojito.Services.Common.Content.contentChannelFactory#newChannel
     */
    newChannel(dataProvider) {
        return new ContentChannel(dataProvider, this.contentKeepAliveTime);
    }

    /**
     * Similar to {@link Mojito.Services.Common.Content.contentChannelFactory#newChannel|newChannel} function will create new channel instance on first call
     * with specific channelId. Next time the function called with the same channelId - channel will be reused.
     *
     * @param {Mojito.Core.Services.DataProvider} dataProvider - Data provider instance, will be used to subscribe to entity.
     * @param {string} channelId - Channel id. Will be used to register newly created channel and reuse it on next request with the same channelId.
     *
     * @returns {Mojito.Services.Common.Content.ContentChannel} The instance of content channel.
     * @function Mojito.Services.Common.Content.contentChannelFactory#getChannel
     */
    getChannel(dataProvider, channelId) {
        if (!this.activeChannels[channelId]) {
            this.activeChannels[channelId] = this.newChannel(dataProvider);
        }
        return this.activeChannels[channelId];
    }

    /**
     * Disposes channel created via {@link Mojito.Services.Common.Content.contentChannelFactory#getChannel|getChannel} call.
     *
     * @param {string} channelId - Channel id to dispose.
     *
     * @function Mojito.Services.Common.Content.contentChannelFactory#disposeChannel
     */
    disposeChannel(channelId) {
        const channel = this.activeChannels[channelId];
        channel?.dispose();
        delete this.activeChannels[channelId];
    }

    /**
     * Dispose all channels created via {@link Mojito.Services.Common.Content.contentChannelFactory#getChannel|getChannel} call.
     *
     * @function Mojito.Services.Common.Content.contentChannelFactory#disposeAllChannels
     */
    disposeAllChannels() {
        Object.keys(this.activeChannels).forEach(channelId => this.disposeChannel(channelId));
    }
}

export default new ContentChannelFactory();
