import { createSlice } from '@reduxjs/toolkit';
import SystemSettingsStorage from './storage/system-settings-storage';
import SystemSettingsTypes from './types.js';
import deviceTypeGuesser from 'core/base/device-type-guesser';
import translationService from 'core/services/localization/translation-service';
import reduxInstance from 'core/services/redux-instance';

const { APPLICATION_MODES_SUPPORTED, APPLICATION_MODE } = SystemSettingsTypes;
const { MOBILE, DESKTOP } = APPLICATION_MODE;

const isValidApplicationMode = mode => APPLICATION_MODES_SUPPORTED.includes(mode);
const bestGuessApplicationMode = () =>
    deviceTypeGuesser.isProbablyHandHeldDevice() ? MOBILE : DESKTOP;

/**
 * Defines the state of the system settings store.
 *
 * @typedef SystemSettingsState
 *
 * @property {string} language - Currently selected language code.
 * @property {Mojito.Core.SystemSettings.SystemSettingsTypes} applicationMode - Currently selected application mode.
 * @property {string} additionalContext - Currently selected additional context.
 *
 *
 * @memberof Mojito.Services.SystemSettings
 */

/**
 * The name of the system settings state property. Will be used to register in global redux store.
 * This state holds information about system settings.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SystemSettings
 */
export const STORE_KEY = 'systemSettingsStore';

/**
 * System settings initial state.
 *
 * @typedef INITIAL_STATE
 * @memberof Mojito.Services.SystemSettings
 */
export const INITIAL_STATE = {
    applicationMode: DESKTOP,
};

export const settingsStorage = new SystemSettingsStorage();

export const { reducer, actions } = createSlice({
    name: 'systemSettings',
    initialState: INITIAL_STATE,
    reducers: {
        updateLanguage(state, { payload }) {
            state.language = payload.language;
        },
        updateAdditionalContext(state, { payload }) {
            state.additionalContext = payload.additionalContext;
        },
        updateState(state, { payload }) {
            state.applicationMode = payload.applicationMode;
            state.language = payload.language;
            state.additionalContext = payload.additionalContext;
            state.contextToChannelMap = payload.contextToChannelMap;
            state.isTAMode = payload.isTAMode;
        },
        reset() {
            return INITIAL_STATE;
        },
    },
});

/**
 * Set the initial values of the application.
 *
 * @function configure
 *
 * @param {object} config - <code>Config</code> object.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Core.Services.SystemSettings.actions
 *
 * @example <caption>Config</caption>
 * configure({
 *   language: 'sv',
 *   applicationMode: SystemSettingsTypes.APPLICATION_MODE.MOBILE
 * })
 */
actions.configure = config => {
    // Initial configuration for the system settings store slice. Both application mode and language are given by strings.
    // Language is only saved to local storage when changed from the action updateLanguage.
    // Language retrieved from url or config will not be saved between sessions.
    return dispatch => {
        const {
            applicationMode: configMode,
            language: configLang,
            additionalContext: configContext,
            contextToChannelMap: configContextToChannelMap,
            allowTAMode,
            enableUrlLanguage,
        } = config;

        settingsStorage.configure({ enableUrlLanguage, allowTAMode });

        // Resolve language
        const storageLang = settingsStorage.getLanguage();
        const isStorageLangAvailable = translationService.isLanguageAvailable(storageLang);
        const language = (isStorageLangAvailable && storageLang) || configLang;

        // Resolve application mode
        const applicationMode =
            (isValidApplicationMode(configMode) && configMode) ||
            bestGuessApplicationMode() ||
            APPLICATION_MODE.DESKTOP;

        // Resolve additional context
        const additionalContext = settingsStorage.getAdditionalContext() || configContext;

        // Resolve context map
        const contextToChannelMap = configContextToChannelMap || { native: 'NATIVE' };

        // Resolve TA mode
        const isTAMode = settingsStorage.isTAMode();

        dispatch(
            actions.updateState({
                language,
                applicationMode,
                additionalContext,
                contextToChannelMap,
                isTAMode,
            })
        );
    };
};

reduxInstance.actionListener.startListening({
    actionCreator: actions.updateLanguage,
    effect: (action, listenerApi) => {
        const language = listenerApi.getState()[STORE_KEY].language;
        settingsStorage.setLanguage(language);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.updateAdditionalContext,
    effect: (action, listenerApi) => {
        const context = listenerApi.getState()[STORE_KEY].additionalContext;
        settingsStorage.setAdditionalContext(context);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.updateState,
    effect: (action, listenerApi) => {
        const state = listenerApi.getState()[STORE_KEY];
        const storageLang = settingsStorage.getLanguage();
        const additionalContext = settingsStorage.getAdditionalContext() || state.additionalContext;
        const isStorageLangAvailable = translationService.isLanguageAvailable(storageLang);

        storageLang && !isStorageLangAvailable && settingsStorage.removeProperty('language');
        settingsStorage.setAdditionalContext(additionalContext);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.reset,
    effect: () => settingsStorage.reset(),
});

/**
 * Update language.
 *
 * @function updateLanguage
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{language: string}} payload - Payload contains <code>language</code>.
 * @memberof Mojito.Core.Services.SystemSettings.actions
 */

/**
 * Update additional context.
 *
 * @function updateAdditionalContext
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{additionalContext: string}} payload - Payload contains <code>additionalContext</code>.
 * @memberof Mojito.Core.Services.SystemSettings.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
