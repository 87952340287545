import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';
import BonusTypes from 'services/bonus/types';

const reduxInstance = MojitoCore.Services.redux;
const { BONUS_FILTER } = BonusTypes;

/**
 * Bonus campaign selectors.
 *
 * @module BonusCampaignSelectors
 * @name selectors
 * @memberof Mojito.Services.BonusCampaign
 */

/**
 * Selects bonus campaigns state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.BonusCampaign} The state of the bonus campaigns store.
 * @memberof Mojito.Modules.BonusCampaign.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selector for checking if there is pending retrieval of bonus campaigns.
 *
 * @function selectIsPending
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if in pending state.
 * @memberof Mojito.Modules.BonusCampaign.selectors
 */
export const selectIsPending = state => selectState(state).isPending;

/**
 * Selects active bonus campaigns state.
 *
 * @function selectActiveBonusCampaigns
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Bonus.type.Campaign>} Active bonus campaigns.
 * @memberof Mojito.Modules.BonusCampaign.selectors
 */
export const selectActiveBonusCampaigns = state =>
    selectState(state).campaignsByFilter[BONUS_FILTER.ACTIVE] || [];

/**
 * Selects in progress bonus campaigns state.
 *
 * @function selectInprogressBonusCampaigns
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Bonus.type.InProgressBonus>} In progress bonus campaigns.
 * @memberof Mojito.Modules.BonusCampaign.selectors
 */
export const selectInprogressBonusCampaigns = state =>
    selectState(state).campaignsByFilter[BONUS_FILTER.IN_PROGRESS] || [];

/**
 * Selects historic bonus campaigns state.
 *
 * @function selectHistoricBonusCampaigns
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<Mojito.Services.Bonus.type.Campaign>} Historic bonus campaigns.
 * @memberof Mojito.Modules.BonusCampaign.selectors
 */
export const selectHistoricBonusCampaigns = state =>
    selectState(state).campaignsByFilter[BONUS_FILTER.HISTORIC] || [];
