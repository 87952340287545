import MojitoCore from 'mojito/core';
import AbstractAnalyticsService, { EVENT_NAMES } from './service/abstractanalyticsservice';
import { partial } from 'mojito/utils';

const log = MojitoCore.logger.get('AnalyticsReporter');
const { selectAdditionalContext } = MojitoCore.Services.SystemSettings.selectors;

/**
 * Analytics report responsible for keeping track of registered
 * analytics services, notifying them about application events.
 *
 * @class AnalyticsReporter
 * @name reporter
 * @memberof Mojito.Services.Analytics
 * @extends Mojito.Services.Analytics.AbstractAnalyticsService
 */
class AnalyticsReporter extends AbstractAnalyticsService {
    constructor() {
        super('reporter');
        this.services = [];
        this.active = false;
    }

    /**
     * Configures the reporter with the concrete implementations of AbstractAnalyticsService to receive notification.
     *
     * @param {object} config - Configuration containing analytics service implementations.
     * @function Mojito.Services.Analytics.reporter#config
     */
    config(config) {
        this.services = config.services[this.getAdditionalContext()] || config.services['default'];

        const serviceNames = this.services.map(service => service.name).join(' ');
        log.info(`Installed analytics services: ${serviceNames}`);
    }

    getAdditionalContext() {
        return selectAdditionalContext();
    }

    setUsername(username) {
        this.username = username;
        this.services.forEach(service => service.setUsername(username));
    }

    setCurrency(currency) {
        this.currency = currency;
        this.services.forEach(service => service.setCurrency(currency));
    }

    activate(url) {
        this.active = true;
        this.services.forEach(service => service.activate(url));
    }
}

function callServices(eventName, ...args) {
    this.active && this.services.forEach(service => service[eventName](...args));
}

for (const eventName of EVENT_NAMES) {
    AnalyticsReporter.prototype[eventName] = partial(callServices, eventName);
}

export default new AnalyticsReporter();
