import PropTypes from 'prop-types';
import SelectableButtonGroupImpl from './selectable-button-group.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * SelectableButtonGroup component. Should be used to group SelectableButton components as a single
 * radio button-like element, where their isSelected properties behave as a mutually exclusive
 * option.
 *
 * @class SelectableButtonGroup
 * @memberof Mojito.Presentation.Components
 */

/**
 * Prop types for the `SelectableButtonGroup` component.
 *
 * @property {string} [selectedKey] - Unique key of the SelectableButton to be displayed as selected.
 * @property {Function} [onSelectionChange = ()=>{}] - Callback for selection changes. Called with the selected SelectableButton key and index.
 * @property {Mojito.Presentation.Components.SelectableButton[]} [children] - Array of SelectableButton components within the SelectableButtonGroup container.
 * @property {string} [class] - Class name assigned to the root element.
 * @property {object | Function} [selectedButtonElementRef] - Ref to the rendered element of the Selected Button.
 *
 * @memberof Mojito.Presentation.Components.SelectableButtonGroup
 */
const propTypes = {
    selectedKey: PropTypes.string,
    onSelectionChange: PropTypes.func,
    children: PropTypes.node,
    class: PropTypes.string,
    selectedButtonElementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const defaultProps = {
    onSelectionChange: noop,
};

export default MojitoCore.Presentation.UIView(
    'SelectableButtonGroup',
    SelectableButtonGroupImpl,
    propTypes,
    defaultProps
);
