import MojitoCore from 'mojito/core';
const ApiProvider = MojitoCore.Services.ApiProvider;

const log = MojitoCore.logger.get();

/**
 * Abstract authentication service to be implemented by different integrations.
 * Note: All methods must be implemented in a asynchronous way, hence
 * the specified callbacks must not be called when inside the called function.
 *
 * @class AbstractAuthenticationService
 * @abstract
 * @memberof Mojito.Services.Authentication
 */
export default class AbstractAuthenticationService extends ApiProvider {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {{}} config - Config object.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Login request using credentials.
     *
     * @param {Mojito.Services.Authentication.types.AuthCredentials} credentials - User authentication credentials.
     * @param {Mojito.Services.Authentication.types.loginSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.loginFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#login
     */
    // eslint-disable-next-line no-unused-vars
    login(credentials, successCallback, errorCallback) {
        log.error('login must be implemented by concrete subclass!');
    }

    /**
     * Logout request.
     *
     * @param {Mojito.Services.Authentication.types.logoutSuccessCallback} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.logoutFailCallback} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#logout
     */
    // eslint-disable-next-line no-unused-vars
    logout(successCallback, errorCallback) {
        log.error('logout must be implemented by concrete subclass!');
    }

    /**
     * Validates existing user session.
     *
     * @param {Mojito.Services.Authentication.types.validateSessionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.validateSessionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#validateSession
     */
    // eslint-disable-next-line no-unused-vars
    validateSession(successCallback, errorCallback) {
        log.error('poll must be implemented by concrete subclass!');
    }

    /**
     * Tries to restore user session.
     * Typically happens on page load when user already has been authenticated by third party SSO service
     * or if session cookie is still valid.
     * Note: To be called once at application startup.
     *
     * @param {Mojito.Services.Authentication.types.restoreSessionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.restoreSessionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#restoreSession
     */
    // eslint-disable-next-line no-unused-vars
    restoreSession(successCallback, errorCallback) {
        log.error('restoreSession must be implemented by concrete subclass!');
    }

    /**
     * Change the user password.
     *
     * @param {Mojito.Services.Authentication.types.PasswordUpdate} passwordUpdate - Object defines password change data.
     * @param {Mojito.Services.Authentication.types.changePasswordSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.changePasswordFail} errorCallback  - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#changePassword
     */
    // eslint-disable-next-line no-unused-vars
    changePassword(passwordUpdate, successCallback, errorCallback) {
        log.error('changePassword must be implemented by concrete subclass!');
    }

    /**
     * Accept terms and conditions.
     *
     * @param {Mojito.Services.Authentication.AcceptTermsAndConditionsInfo} termsAndConditionsInfo - T&C data associated with a TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED "error".
     * @param {Mojito.Services.Authentication.types.acceptTermAndConditionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.acceptTermAndConditionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#acceptTermsAndConditions
     */
    // eslint-disable-next-line no-unused-vars
    acceptTermsAndConditions(termsAndConditionsInfo, successCallback, errorCallback) {
        log.error('acceptTermsAndConditions must be implemented by concrete subclass!');
    }

    /**
     * Decline terms and conditions.
     *
     * @param {Mojito.Services.Authentication.AcceptTermsAndConditionsInfo} termsAndConditionsInfo - T&C data associated with a TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED "error".
     * @param {Mojito.Services.Authentication.types.declineTermAndConditionSuccess} successCallback - Success callback.
     * @param {Mojito.Services.Authentication.types.declineTermAndConditionFail} errorCallback - Error callback.
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#declineTermsAndConditions
     */
    // eslint-disable-next-line no-unused-vars
    declineTermsAndConditions(termsAndConditionsInfo, successCallback, errorCallback) {
        log.error('declineTermsAndConditions must be implemented by concrete subclass!');
    }

    /**
     * Terminates the service.
     *
     * @function Mojito.Services.Authentication.AbstractAuthenticationService#terminate
     */
    terminate() {
        // To be implemented by subclasses
    }
}
