import MojitoCore from 'mojito/core';
import InplayFallbackEventCard from './inplay/index.jsx';
import SportFallbackEventCard from './sport/index.jsx';

export default class FallbackEventCardModule extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);

        this.fallbackByTypeRenderers = {
            inplay: config => <InplayFallbackEventCard config={config} />,
            sport: config => (
                <SportFallbackEventCard config={config} sportId={this.props.sportId} />
            ),
        };
    }

    render() {
        const { type } = this.props;
        const card = this.fallbackByTypeRenderers[type];
        const cardConfig = this.config.cardTypes[type];
        if (!card || !cardConfig) {
            return null;
        }

        return card(cardConfig);
    }
}
