export {Application} from './application';

import './actions-proxy';
import './analytics-proxy';
import './balances';
import './embedded-app';
import './external-navigation';
import './intents';
import './mojito-fixes';
import './multi-jurisdiction';
import './seo';
import './routes';
import './widgets-provider';
import './user-settings';
import './currencies';
import './other-configs';

export const applicationModulesPromise = import(
    /* webpackMode: "lazy" */
    /* webpackChunkName: "sportsbook.dbx" */
    /* webpackPreload: true */
    './modules'
);
