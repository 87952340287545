import {createSlice} from '@reduxjs/toolkit';

import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {balancesAreEqual, calculateBalances} from './utils';
import {selectBalances} from './selectors';

const reduxInstance = MojitoCore.Services.redux;
const {dispatch} = MojitoCore.Services.redux.store;

const {actions: UserInfoActions, selectors: UserInfoSelectors} = MojitoServices.UserInfo;
const {actions: FreeBetsActions} = MojitoServices.BonusFreeBets;
const {selectors: AuthenticationSelectors} = MojitoServices.Authentication;
const {selectors: FreeBetsSelectors} = MojitoServices.BonusFreeBets;

export const STORE_KEY = 'SportsbookAPIBalancesStore';

export const INITIAL_STATE = {
    balances: [],
};

export const {reducer, actions} = createSlice({
    name: 'sportsbook-api-balances',
    initialState: INITIAL_STATE,
    reducers: {
        updateBalance(state, {payload: newBalances = []}) {
            state.balances = newBalances;
        },
    },
});

const notifyIfBalancesHaveChanged = state => {
    const isUserLoggedIn = AuthenticationSelectors.isLoggedIn(state);
    const userInfo = UserInfoSelectors.selectUserInfo(state);
    const activeFreeBets = FreeBetsSelectors.selectActiveFreeBets(state);
    const currentBalances = selectBalances(state);

    const newBalances = calculateBalances({isUserLoggedIn, userInfo, activeFreeBets});
    const changed = !balancesAreEqual(newBalances, currentBalances);

    if (changed) {
        dispatch(actions.updateBalance(newBalances));
    }
};

reduxInstance.actionListener.startListening({
    actionCreator: UserInfoActions.updateBalance,
    effect: (action, listenerApi) => notifyIfBalancesHaveChanged(listenerApi.getState()),
});

reduxInstance.actionListener.startListening({
    actionCreator: FreeBetsActions.fetchActiveFreeBetsSuccess,
    effect: (action, listenerApi) => notifyIfBalancesHaveChanged(listenerApi.getState()),
});

reduxInstance.injectReducer(STORE_KEY, reducer);
