import MojitoNGen from 'mojito/ngen';
import uiStyle from 'core/presentation/ui-style';

const log = MojitoNGen.logger.get('CssRuleManager');

/**
 * Centralized mechanism to add and dispose css rules in document stylesheet.
 *
 * @name cssRuleManager
 * @class CssRuleManager
 * @memberof Mojito.Core.Presentation
 */
class CssRuleManager {
    constructor() {
        this.rules = {};
    }

    /**
     * Build css rules.
     *
     * @example
     * const cssVariables = {'%class%': 'default'};
     * const ruleTemplates = ['input.%class% { }`]
     * // Will return ['input.default { }`]
     *
     * @param {object} cssVariables - Css variables.
     * @param {Array<string>} ruleTemplates - Css rule templates.
     *
     * @returns {Array<string>} Return cssRules.
     */
    buildRules(cssVariables, ruleTemplates) {
        return ruleTemplates.map(ruleTemplate => applyVariables(ruleTemplate, cssVariables));
    }

    /**
     * Add css rules to document stylesheet once for each class name.
     *
     * @param {string} instanceId - Component instance id.
     * @param {string} classId - CSS selector class id. Typically, unique per instance class/type.
     * @param {Array<string>} cssRules - Css rules for component.
     */
    addRules(instanceId, classId, cssRules) {
        if (!this.rules[classId]) {
            this.rules[classId] = [];
        }
        // No registered rules for the class, need to register them.
        if (!this.rules[classId].length) {
            cssRules.forEach(rule => {
                try {
                    uiStyle.insertCssRule(rule);
                } catch (e) {
                    // Mozilla specific pseudo-elements fail on Chrome, and it is not a problem
                    log.debug(`CSS rule: ${rule} creation failed: ${e}`);
                }
            });
        }

        if (!this.rules[classId].includes(instanceId)) {
            this.rules[classId].push(instanceId);
        }
    }

    /**
     * Remove css rules from document stylesheet.
     *
     * @param {string} instanceId - Component instance id.
     * @param {string} classId - CSS selector class id. Typically, unique per instance class/type.
     */
    removeRules(instanceId, classId) {
        if (!this.rules[classId]) {
            return;
        }
        const index = this.rules[classId].indexOf(instanceId);
        if (index > -1) {
            this.rules[classId].splice(index, 1);
        }
        // No instances left which are still interested in rules registered for a class.
        // Dispose class in this case.
        if (!this.rules[classId].length) {
            uiStyle.removeCssRules(classId);
        }
    }
}

function applyVariables(ruleTemplate, cssVariables) {
    return Object.entries(cssVariables).reduce(
        (result, [cssVariable, value]) => result.replace(cssVariable, value),
        ruleTemplate
    );
}

export default new CssRuleManager();
