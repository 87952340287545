import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE, FAKE_EVENT_ID } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * NotificationEvent related selectors.
 *
 * @module NotificationEventSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.NotificationEvent
 */

/**
 * Selects notification event state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {object} NotificationEvent state.
 * @memberof Mojito.Services.SportsContent.NotificationEvent.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects event id state.
 *
 * @function selectEventId
 *
 * @param {string} [notificationsId] - Notifications event id.
 * @param {object} [state] - State object.
 *
 * @returns {string|undefined} Selected eventId or undefined.
 * @memberof Mojito.Services.SportsContent.NotificationEvent.selectors
 */
export const selectEventId = (notificationsId, state) => {
    const eventId = selectState(state).notificationsIdToEventId[notificationsId];
    return eventId && eventId !== FAKE_EVENT_ID ? eventId : undefined;
};

/**
 * Selects loaded state.
 *
 * @function isLoaded
 *
 * @param {string} [notificationsId] - Notifications event id.
 * @param {object} [state] - State object.
 *
 * @returns {boolean} True if notifications event id already loaded.
 * @memberof Mojito.Services.SportsContent.NotificationEvent.selectors
 */
export const isLoaded = (notificationsId, state) =>
    !!selectState(state).notificationsIdToEventId[notificationsId];
