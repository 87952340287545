import PropTypes from 'prop-types';
import DocumentHeadImpl from './document-head.jsx';
import MojitoCore from 'mojito/core';

/**
 * A component based on React Helmet, used to manage HTML document head changes.
 * If one of title, description, keywords or canonical is undefined or resolves to undefind/empty string, they will fall back to document-head l10n strings.
 *
 * **Important!** This component should ideally be rendered from a page component.
 * If it must be rendered from a child component, ensure that a unique {@link Mojito.Presentation.Components.DocumentHead.PageData|data.params.pageId} is passed when necessary.
 * See {@link Mojito.Presentation.Components.DocumentHead.PageDataParams|pageId} for more info.
 *
 * @class DocumentHead
 * @memberof Mojito.Presentation.Components
 */

/**
 * @typedef PageData
 * @type {object}
 * @property {string} [title] - A l10n-key (that will be resolved and formatted) or a string used as HTML document HEAD title. _Note!_ If a normal string is used together with {@link Mojito.Presentation.Components.DocumentHead.DefaultHeadMetaFactory|DefaultHeadMetaFactory} it's recommended to not set l10n in the constructor to avoid warnings in console.
 * @property {string} [description] - A l10n-key (that will be resolved and formatted) or a string used as the HTML document HEAD description meta-tag. _Note!_ If a normal string is used together with {@link Mojito.Presentation.Components.DocumentHead.DefaultHeadMetaFactory|DefaultHeadMetaFactory} it's recommended to not set l10n in the constructor to avoid warnings in console.
 * @property {string} [keywords] - A l10n-key (that will be resolved and formatted) or a string used as the HTML document HEAD keywords meta-tag. _Note!_ If a normal string is used together with {@link Mojito.Presentation.Components.DocumentHead.DefaultHeadMetaFactory|DefaultHeadMetaFactory} it's recommended to not set l10n in the constructor to avoid warnings in console.
 * @property {string} [canonical] - A l10n-key (that will be resolved and formatted) or a string used as the HTML document HEAD canonical meta-tag. _Note!_ If a normal string is used together with {@link Mojito.Presentation.Components.DocumentHead.DefaultHeadMetaFactory|DefaultHeadMetaFactory} it's recommended to not set l10n in the constructor to avoid warnings in console.
 * @property {Mojito.Presentation.Components.DocumentHead.LocalizedPageDataParams} [localizedParams] - Localized business logic parameters. Used to substitute %X in given strings/resolved keys. Can be used to create your own meta-factory with special logic but {@link Mojito.Presentation.Components.DocumentHead.PageDataParams|PageDataParams} is probably easier to use since those params are not localized. _Note! It is important that the params are given in the exact order they are mentioned, hence we require them to be a map and not an object.
 * @property {Mojito.Presentation.Components.DocumentHead.PageDataParams} [params] - Business logic parameters. Can be used to create your own meta-factory with special logic AND send in custom parameters from custom pages.
 *
 * @memberof Mojito.Presentation.Components.DocumentHead
 */

/**
 * @typedef LocalizedPageDataParams
 * @type {Map}
 * @property {string} [sportName] - Localized name of the sport, such as tennis, basketball, darts. Check the sport-collection in MongoDB to see all available sport names.
 * @property {string} [eventName] - Localized event name, usually something like "Malmö FF - Dynamo Kyiv" or "Euroleague 2019/2020 - winner". Future races also use the eventName property for the race event name.
 * @property {string} [eventClass] - Localized class of the event. Usually means a country, "International" or a big tournament like "US Open" in Tennis.
 * @property {string} [eventType] - Localized event type. Usually a league or a tournament, i.e., "Premier League"/"World Cup". If 'eventClass' is a tournament this can be the location (eventClass: "Challenger", eventType: "Hamburg") or a more specified type (eventClass: "French Open", eventType: "Singles - Women").
 * @property {string} [meetingName] - Localized track name. Only used for racing.
 * @property {string} [couponName] - Name of a coupon created from CMS or one of the two predefined coupons (Today or Tomorrow) localized.
 * @property {string} [eventStartTime] - Localized start time of the event.
 *
 * @memberof Mojito.Presentation.Components.DocumentHead
 */

/**
 * @typedef PageDataParams
 * @type {object}
 * @property {string} [sportId] - Sport id, eg: soccer, tennis, darts. One can reference the sport-collection in MongoDB for all available ids.
 * @property {string} [eventId] - Id for a specific event, such as "Malmö FF - Dynamo Kyiv", "Euroleague 2019/2020 - winner" or a specific racing event.
 * @property {string} [eventClassId] - ClassId for a specific event.
 * @property {string} [eventTypeId] - TypeId for a specific event.
 * @property {string} [couponId] - CouponId for a CMS created coupon or a predefined Mojito coupon (Today or Tomorrow).
 * @property {*} [customData] - Can be anything needed by a custom factory/page-implementation. _Not used by Mojito_.
 * @property {Mojito.Presentation.Components.DocumentHeadTypes.PAGE_ID} [pageId] - An id to identify the page. Should be provided when the parent component of a DocumentHead is NOT a page component and is used on more than one page. Implement a custom head meta factory to make use of pageId, allowing you to provide your own translations based on its value.
 *
 * @memberof Mojito.Presentation.Components.DocumentHead
 */

/**
 * `DocumentHead` component prop types.
 *
 * @property {Mojito.Presentation.Components.DocumentHead.AbstractHeadMetaFactory} metaFactory - Factory used for getting information to be displayed in the header. Mojito provide a default implementation that can be used for standard cases, see: {@link Mojito.Presentation.Components.DocumentHead.DefaultHeadMetaFactory|DefaultHeadMetaFactory}.
 * @property {Mojito.Presentation.Components.DocumentHead.PageData} [data] - Information provided from pages to be able to create a well-defined document head, including description, keywords, title and business data.
 *
 * @memberof Mojito.Presentation.Components.DocumentHead
 */
const propTypes = {
    metaFactory: PropTypes.object.isRequired,
    data: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        canonical: PropTypes.string,
        localizedParams: PropTypes.shape({
            sportName: PropTypes.string,
            eventName: PropTypes.string,
            eventClass: PropTypes.string,
            eventType: PropTypes.string,
            eventStartTime: PropTypes.string,
            meetingName: PropTypes.string,
            couponName: PropTypes.string,
        }),
        params: PropTypes.shape({
            sportId: PropTypes.string,
            eventId: PropTypes.string,
            customData: PropTypes.any,
            pageId: PropTypes.string,
        }),
    }),
};

export default MojitoCore.Presentation.UIView('DocumentHead', DocumentHeadImpl, propTypes);
