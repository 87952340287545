import MojitoCore from 'mojito/core';

const DataProvider = MojitoCore.Services.DataProvider;
const COLLECTION_NAME = 'sportMetaInformation';

/**
 *
 * Class offering the possibility to link to specific sport meta information.
 *
 * @class SportMetaInformationProvider
 * @private
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class SportMetaInformationProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(sportMetaInformationId, setter) {
        return super.subscribeToEntity(sportMetaInformationId, setter, COLLECTION_NAME);
    }

    subscribeToEntities(sportMetaInformationIds, onInit, onUpdate) {
        return super.subscribeToEntities(
            sportMetaInformationIds,
            onInit,
            onUpdate,
            COLLECTION_NAME
        );
    }
}

export default new SportMetaInformationProvider();
