import MojitoNGen from 'mojito/ngen';
import { StorageService } from './index.js';
const log = MojitoNGen.logger.get('NamedStorageService');

/**
 * Storage service for storing / restoring data from localStorage by specific name.
 *
 * @param {string} name - LocalStorage key.
 * @param {Storage} [storageApi = window.localStorage] - The Storage interface of the browser Web Storage API. If not provided then window.localStorage will be used.
 *
 * @class NamedStorageService
 * @name NamedStorageService
 * @memberof Mojito.Core.Services.Storage
 */
export default class NamedStorageService {
    constructor(name, storageApi = window.localStorage) {
        if (!name) {
            log.warn('Falsy key specified for named storage service');
        }
        this.name = name;
        this.service = new StorageService(storageApi);
    }

    /**
     * Get a value from the localStorage using a specified name.
     *
     * @see Mojito.Core.Services.Storage.StorageService#getItem
     * @returns {any} The property value, or null if it does not exist.
     *
     * @function Mojito.Core.Services.Storage.NamedStorageService#getItem
     */
    getItem() {
        try {
            return JSON.parse(this.service.getItem(this.name));
        } catch (e) {
            log.warn('Unsuccessful getItem call', e);
            return null;
        }
    }

    /**
     * Store a value in the localStorage.
     *
     * @param {any} value - Value to be stored.
     * @see Mojito.Core.Services.Storage.StorageService#setItem
     * @function Mojito.Core.Services.Storage.NamedStorageService#setItem
     */
    setItem(value) {
        try {
            this.service.setItem(this.name, JSON.stringify(value));
        } catch (e) {
            log.warn('Unsuccessful setItem call', e);
        }
    }

    /**
     * Checks if a value with the specified name exists.
     *
     * @see Mojito.Core.Services.Storage.StorageService#hasItem
     * @returns {boolean} True if a value with the specified name exists.
     *
     * @function Mojito.Core.Services.Storage.NamedStorageService#hasItem
     */
    hasItem() {
        return this.service.hasItem(this.name);
    }

    /**
     * Remove the item having the specified name from localStorage.
     *
     * @see Mojito.Core.Services.Storage.StorageService#removeItem
     * @function Mojito.Core.Services.Storage.NamedStorageService#removeItem
     */
    removeItem() {
        this.service.removeItem(this.name);
    }

    /**
     * Remove a property of the item with the specified name.
     *
     * @param {string} key - The property of the item.
     * @see Mojito.Core.Services.Storage.StorageService#removeItemProperty
     * @function Mojito.Core.Services.Storage.NamedStorageService#removeItem
     */
    removeItemProperty(key) {
        const itemValues = this.getItem();
        delete itemValues[key];
        this.setItem(itemValues);
    }
}
