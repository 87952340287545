import PropTypes from 'prop-types';
import GridSeparatorImpl from './grid-separator.jsx';
import MojitoCore from 'mojito/core';

/**
 * Helper component to create separators in a `GridPane` based on a `GridCell`.
 *
 * @class GridSeparator
 * @memberof Mojito.Presentation.Components.GridLayout
 */

/**
 * `GridSeparator` component prop types.
 *
 * @property {number} [startColumn] - The column index where this cell should start rendering.
 * @property {number} [endColumn] - The column index where this cell should stop rendering.
 * @property {number} [startRow] - The row index where this cell should start rendering.
 * @property {number} [endRow] - The row index where this cell should stop rendering.
 * @property {string} [class] - The classname to be applied to the root element of the component.
 *
 * @memberof Mojito.Presentation.Components.GridLayout.GridSeparator
 */
const propTypes = {
    startColumn: PropTypes.number,
    endColumn: PropTypes.number,
    startRow: PropTypes.number,
    endRow: PropTypes.number,
    class: PropTypes.string,
    children: PropTypes.node,
};

export default MojitoCore.Presentation.UIView('GridSeparator', GridSeparatorImpl, propTypes);
