import MojitoNGen from 'mojito/ngen';
import transporterFactory from 'core/services/transporter/transporter-factory.js';
import performanceTypes from 'core/services/performance/types';
import serviceFactory from 'core/services/performance/service/service-factory';
import { selectIsTAMode } from 'core/services/system-settings/selectors';

const { TIMELINE_RECORD_TYPES } = performanceTypes;
const log = MojitoNGen.logger.get('DataProvider');

/**
 *
 * Abstract class offering generic functions for subscribing to data.
 *
 * @class DataProvider
 * @abstract
 * @memberof Mojito.Core.Services
 */
export default class DataProvider {
    /**
     * Subscribe to the specified entity.
     *
     * @param {string} id - The entity id.
     * @param {Function} onUpdate - Function called when the requested data is updated.
     * @param {string} collectionName - Repository collection name.
     *
     * @returns {object} Subscription that must be disposed when no longer needed.
     *
     * @function Mojito.Core.Services.DataProvider#subscribeToEntity
     */
    subscribeToEntity(id, onUpdate, collectionName) {
        let subscription;
        const requestTimestamp = Date.now();
        const performanceService = serviceFactory.getService();

        this.reportWsSubscriptionIntent(id, collectionName, false, performanceService);

        const promise = new Promise((resolve, reject) => {
            const transporter = transporterFactory.getTransporter();
            subscription = transporter.subscribe(collectionName, id, function (data) {
                onUpdate(data);
            });
            subscription.promise
                .then(data => {
                    this.reportWsMessage(
                        id,
                        data,
                        collectionName,
                        requestTimestamp,
                        performanceService
                    );
                    return data;
                })
                .then(initialData => {
                    onUpdate(initialData);
                    this.reportWsMessageProcessed(id, collectionName, performanceService);
                    resolve(initialData);
                }, reject);
        });

        return {
            promise: promise,
            dispose: function () {
                subscription.dispose();
            },
        };
    }

    /**
     * Subscribe to the multiple entities.
     *
     * @param {Array<string>} ids - The entities id list.
     * @param {Function} onInit - Function called when the requested data was fetched after subscription resolved.
     * @param {Function} onUpdate - Function called when the requested data is updated.
     * @param {string} collectionName - Repository collection name.
     *
     * @returns {object} Composite subscription object that contains nested subscriptions for each item.
     *
     * @function Mojito.Core.Services.DataProvider#subscribeToEntities
     */
    subscribeToEntities(ids, onInit, onUpdate, collectionName) {
        const idsStr = ids.join('-');
        const requestTimestamp = Date.now();
        const performanceService = serviceFactory.getService();

        this.reportWsSubscriptionIntent(idsStr, collectionName, true, performanceService);

        const transporter = transporterFactory.getTransporter();
        const subscription = transporter.subscribeMultiple(collectionName, ids, onUpdate);

        subscription.promise
            .then(data => {
                this.reportWsMessage(
                    idsStr,
                    data,
                    collectionName,
                    requestTimestamp,
                    performanceService
                );
                return data;
            })
            .then(data => {
                onInit(data);
                this.reportWsMessageProcessed(idsStr, collectionName, performanceService);
            })
            .catch(e => {
                log.error(`Error in subscribeMultiple for ${collectionName} collection.\n`, e);
            });
        return subscription;
    }

    /**
     * Retrieves a specific entity from the repository using the repository's get method.
     * This function should only be used when the data is expected to be already present in the repository.
     *
     * @param {string} id - The id of the entity.
     * @param {Function} setter - A function that is called when the requested data is updated.
     * @param {string} collectionName - The name of the repository collection.
     *
     * @returns {void} This function doesn't return a value.
     *
     * @function Mojito.Core.Services.DataProvider#getEntity
     */
    getEntity(id, setter, collectionName) {
        const transporter = transporterFactory.getTransporter();
        transporter
            .get(collectionName, id)
            .then(data => {
                setter(data);
            })
            .catch(() => {
                log.warn('Failed to get entity: ', id, ' from collection: ', collectionName);
            });
    }

    /**
     * Performs reporting of subscription intent.
     *
     * @param {string} id - The identifier of the entity for which the subscription is created.
     * @param {string} collection - The name of the collection associated with the entity.
     * @param {boolean} isMulti - Indicates whether the subscription is for multiple entities.
     * @param {Mojito.Core.Services.Performance.AbstractPerformanceService} performanceService - Instance of performance service.
     *
     * @function Mojito.Core.Services.DataProvider#reportWsSubscriptionIntent
     */
    reportWsSubscriptionIntent(id, collection, isMulti, performanceService) {
        performanceService.report(TIMELINE_RECORD_TYPES.WS_SUBSCRIPTION_INTENT, {
            id,
            isMulti,
            collection,
        });
    }

    /**
     * Performs reporting of received messages.
     *
     * @param {string} id - The identifier of the entity for which the subscription is created.
     * @param {object} data - Backend response.
     * @param {string} collection - The name of the collection associated with the entity.
     * @param {Date} requestTimestamp - Timestamp of request.
     * @param {Mojito.Core.Services.Performance.AbstractPerformanceService} performanceService - Instance of performance service.
     *
     * @function Mojito.Core.Services.DataProvider#reportWsMessage
     */
    reportWsMessage(id, data, collection, requestTimestamp, performanceService) {
        performanceService.report(TIMELINE_RECORD_TYPES.WS_MESSAGE, {
            id,
            data,
            collection,
            wsLatency: Date.now() - requestTimestamp,
        });
    }

    /**
     * Performs reporting after messages processed by store.
     *
     * @param {string} id - The identifier of the entity for which the subscription is created.
     * @param {string} collection - The name of the collection associated with the entity.
     * @param {Mojito.Core.Services.Performance.AbstractPerformanceService} performanceService - Instance of performance service.
     *
     * @function Mojito.Core.Services.DataProvider#reportWsMessageProcessed
     */
    reportWsMessageProcessed(id, collection, performanceService) {
        performanceService.report(TIMELINE_RECORD_TYPES.WS_MESSAGE_PROCESSED, {
            id,
            collection,
        });
    }

    /**
     * Add simple collection to transporter.
     *
     * @param {string} collectionKey - Collection key.
     * @param {string} lang - Language.
     * @function Mojito.Core.Services.DataProvider#addSimpleCollection
     */
    addSimpleCollection(collectionKey, lang) {
        const locale = selectIsTAMode() ? undefined : lang;
        const transporter = transporterFactory.getTransporter();
        transporter.registerCollection(collectionKey, locale);
    }

    /**
     * Add localized collection to transporter.
     *
     * @param {string} collectionKey - Collection key.
     * @param {string} lang - Language.
     * @function Mojito.Core.Services.DataProvider#addLocalizedCollection
     */
    addLocalizedCollection(collectionKey, lang) {
        const locale = selectIsTAMode() ? undefined : lang;
        const transporter = transporterFactory.getTransporter();
        transporter.registerCollection(collectionKey, locale, false);
    }
}
