import MojitoPresentation from 'mojito/presentation';

const { FlexPane, SkeletonPart } = MojitoPresentation.Components;

const QuickStartGuideSkeleton = props => {
    const {
        mojitoTools: { config },
    } = props;
    return (
        <FlexPane config={config.container} class="ta-QuickStartGuideSkeleton">
            <SkeletonPart
                config={config.startGuideSkeletonContent}
                class="ta-QuickStartGuideSkeletonContent"
            />
            <SkeletonPart
                config={config.startGuideSkeletonButton}
                class="ta-QuickStartGuideSkeletonButton"
            />
        </FlexPane>
    );
};
export default QuickStartGuideSkeleton;
