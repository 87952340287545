import { useState } from 'react';
import Button from 'presentation/components/button/index.jsx';
import Popup from 'presentation/components/popup/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';
import ImageButton from 'presentation/components/image-button/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const Popover = props => {
    const { isDisabledButton, signpostContent, shouldShowPopup, children, mojitoTools } = props;
    const { config } = mojitoTools;

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = event => {
        event.stopPropagation();
        setShowPopup(!showPopup);
    };

    return (
        <Button
            onClick={togglePopup}
            config={config.button}
            disabled={isDisabledButton}
            class={`ta-Popover ${props.class || ''}`}
        >
            <FlexPane config={config.textContainer}>{signpostContent}</FlexPane>
            {showPopup && shouldShowPopup && (
                <Popup visible={showPopup} onOutsideClick={togglePopup} config={config.popup}>
                    <FlexPane config={config.container}>
                        {children}
                        <AbsolutePane config={config.closeButtonContainer}>
                            <ImageButton onClick={togglePopup} config={config.closeButton} />
                        </AbsolutePane>
                    </FlexPane>
                </Popup>
            )}
        </Button>
    );
};

export default Popover;
