import { createSlice } from '@reduxjs/toolkit';

import reduxInstance from 'core/services/redux-instance';
import storageService from 'core/services/storage';

const DAYS_UNTIL_COOKIE_EXPIRY = 365;
const COOKIE_NAME = 'cookie-consent-given';

/**
 * Defines the state of the cookie consent.
 *
 * @typedef CookieConsentState
 *
 * @property {boolean} isConsentGiven - Defines whether cookie consent was given. True if yes, false - no.
 *
 * @memberof Mojito.Services.CookieConsent
 */

/**
 * The name of the cookie consent store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.CookieConsent
 */

export const STORE_KEY = 'cookieConsentStore';
export const INITIAL_STATE = {
    isConsentGiven: storageService.getCookie(COOKIE_NAME) === 'true',
};

export const { reducer, actions } = createSlice({
    name: 'cookieConsent',
    initialState: INITIAL_STATE,
    reducers: {
        giveConsent(state) {
            state.isConsentGiven = true;
        },
        reset(state) {
            state.isConsentGiven = false;
        },
    },
});

/**
 * CookieConsent related actions.
 *
 * @class CookieConsentActions
 * @name actions
 * @memberof Mojito.Services.CookieConsent
 */

/**
 * Give consent on using cookies.
 *
 * @function giveConsent
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.CookieConsent.actions
 */

/**
 * Reset cookie consent state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.CookieConsent.actions
 */

reduxInstance.actionListener.startListening({
    actionCreator: actions.giveConsent,
    // This effect will be executed once giveConsent action fired right after reducer handles it.
    effect: () => {
        storageService.setCookie(COOKIE_NAME, 'true', DAYS_UNTIL_COOKIE_EXPIRY);
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
