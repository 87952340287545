import serviceFactory from './service/service-factory.js';
import { actions as quickStartGuideSliceActions } from './slice.js';

/**
 * Quick start guide related actions.
 *
 * @module QuickStartGuideActions
 * @name actions
 * @memberof Mojito.Services.QuickStartGuide
 */

const actions = { ...quickStartGuideSliceActions };

/**
 * Init quick start guide service layer.
 *
 * @function init
 *
 * @param {Mojito.Services.QuickStartGuide.types.QuickStartGuideConfig} config - Quick start guide config.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk.
 * @memberof Mojito.Services.QuickStartGuide.actions
 */
actions.init = config => () => {
    serviceFactory.init(config);
};

/**
 * Action that fetches the guides and menu information.
 *
 * @function fetchQuickStartGuides
 *
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Fetch quickthunk. Dispatches restoreSession action once init is done.
 * @memberof Mojito.Services.QuickStartGuide.actions
 */
actions.fetchQuickStartGuides = () => {
    return dispatch => {
        serviceFactory.getService().fetchQuickStartGuides(
            data => dispatch(actions.fetchQuickStartGuidesSuccess(data)),
            err => dispatch(actions.fetchQuickStartGuidesFailed(err))
        );
    };
};

/**
 * Fetch quick start guides success action.
 *
 * @function fetchQuickStartGuidesSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.QuickStartGuide.types.QuickStartGuideResponse} response - Quick start guides response.
 *
 * @memberof Mojito.Services.QuickStartGuide.actions
 */

/**
 * Fetch quick start guides failed action.
 *
 * @function fetchQuickStartGuidesFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Core.Services.Transactions.types.Error} error - Object defines error details.
 *
 * @memberof Mojito.Services.QuickStartGuide.actions
 */

export default actions;
