import PropTypes from 'prop-types';
import EventListSkeletonImpl from './event-list-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Event list skeleton component used to represent loading event list.
 *
 * @function EventListSkeleton
 * @memberof Mojito.Modules.EventList
 */

/**
 * EventListSkeleton prop types.
 *
 * @param {string} [sportId] - Sport id. Used to determine if US style skeletons will be displayed.
 */
const propTypes = {
    sportId: PropTypes.string,
};

export default UIView('EventListSkeleton', EventListSkeletonImpl, propTypes);
