import {PALETTE_IDS} from 'core/application/modules/ids';
import {COLOR, FONT} from '../theme';

export function getCardsConfig() {
    return {
        custom: {
            // Could be removed once all operators have the right to use team logos
            [PALETTE_IDS.EVENT_CARDS]: {
                MatchEventCard: {
                    gameLineMarketContent: {
                        participants: {
                            showTeamLogo: true,
                            showShortName: true,
                        },
                    },
                    eventItemDetails: {
                        participants: {
                            showTeamLogo: true,
                        },
                    },
                },
            },
        },
        palette: {
            [PALETTE_IDS.EVENT_CARDS]: {
                CARD_FALLBACK_TEXT: COLOR.BRAND.FOREGROUND2,
                CARD_SPORT_ICON_COLOR: COLOR.BRAND.FOREGROUND2,

                CARD_BASE_HEADER_TEXT: COLOR.BRAND.FOREGROUND1,
                CARD_EVENT_NAME_TEXT: COLOR.BRAND.FOREGROUND2,

                CARD_MARKET_NAME_TEXT: {color: COLOR.BRAND.FOREGROUND2, fontWeight: FONT.WEIGHT_NORMAL},
                CARD_WATERMARK_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                CARD_PARTICIPANTS: {
                    PARTICIPANTS_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                },
                CARD_MINI_SCOREBOARD: {
                    MINI_SCOREBOARD_SCORE_COUNT_TEXT: {
                        color: COLOR.BRAND.ACCENT2,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                    MINI_SCOREBOARD_SCORES_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                },
                CARD_WATERMARK_ICON_OPACITY: 0.1,
                CARD_TIMING_STATUS: {
                    EVENT_TIMING_LIVE_TEXT: {fontWeight: FONT.WEIGHT_NORMAL},
                    EVENT_TIMING_UPCOMING_TEXT: {fontWeight: FONT.WEIGHT_NORMAL},
                },
                CARD_MATCH_ACCA_EVENT_NAME_TEXT: {
                    fontWeight: 700,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                CARD_MATCH_ACCA_SELECTION_ITEM: {
                    BB_BET_PATH_LINE_COLOR: COLOR.BRAND.FOREGROUND3,
                },
                CARD_MATCH_ACCA_BB_BET_ICON_COLOR: COLOR.BRAND.ACCENT2,
            },
        },
    };
}
