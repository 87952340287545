import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';

const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { FallbackEventCard } = MojitoPresentation.Components.EventCards;

export default class SportFallbackEventCard extends MojitoCore.Presentation.UIViewImplementation {
    render() {
        const { title, content, onClick } = this.config;
        const { sportId } = this.props;
        const sportName = this.appContext().sportName(sportId);
        return (
            <FallbackEventCard
                config={this.config.view}
                headerTitle={this.resolveAndFormatString(title, sportName)}
                contentText={this.resolveString(content)}
                sportId={sportId}
                onClick={onClick || (() => IntentActionTrigger.showSport(sportId))}
            />
        );
    }
}
