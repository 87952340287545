import PropTypes from 'prop-types';
import EventListItemDetailsImpl from './event-list-item-details.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 *
 * @class EventListItemDetails
 * @memberof Mojito.Modules.EventList.EventListItem
 */

/**
 * EventListItemDetails prop types.
 *
 * @property {object} event - The event data corresponding to this particular list item.
 * @property {string} [hrefLink] - A string to be set in the `hrefLink` prop of the `<Button>` component (for SEO purpose only).
 * @property {Function} [cbClick] - Callback triggered when the item is clicked.
 * @property {boolean} [forceDisplayEventName = false] - Flag that when set to true, forces the display of the event name
 * instead of the participants in the event details section.
 * @property {string} [drawLabelText] - String displayed as the draw label.
 *
 * @memberof Mojito.Modules.EventList.EventListItem.EventListItemDetails
 */

const propTypes = {
    event: PropTypes.object.isRequired,
    hrefLink: PropTypes.string,
    cbClick: PropTypes.func,
    forceDisplayEventName: PropTypes.bool,
    drawLabelText: PropTypes.string,
};

const defaultProps = {
    forceDisplayEventName: false,
};

export default UIView('EventListItemDetails', EventListItemDetailsImpl, propTypes, defaultProps);
