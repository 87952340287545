import { connect } from 'react-redux';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import { isString, pick, isEmpty } from 'mojito/utils';

const { StringUtils } = MojitoCore.Base;
const { Image, DotNotification, Button, AbsolutePane, FlexPane, Text } =
    MojitoPresentation.Components;
const { UIViewImplementation } = MojitoCore.Presentation;
const intentActions = MojitoCore.Intents.actions;

class QuickLinks extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.renderLink = this.renderLink.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    onButtonClick(event, data) {
        const { dispatch } = this.props;
        const { intent, label } = data;
        if (intent.type) {
            this.emitAnalytics('quickLinkClicked', label);

            const parsedIntentData = isString(intent.data) ? JSON.parse(intent.data) : intent.data;
            dispatch(intentActions.publishIntent(intent.type, parsedIntentData));
        }
    }

    hasNotificationToShow(link) {
        const subscribedActions = link.notification && link.notification.onActions;
        // If we find notification that we subscribed on and it's state is true,
        // we will show the notification icon
        return (
            subscribedActions &&
            subscribedActions.some(action => this.props.notifications[action] === true)
        );
    }

    renderLinks() {
        if (isEmpty(this.config.links)) {
            return null;
        }
        return this.config.links.map(this.renderLink);
    }

    renderLink(link) {
        const isDotNotificationVisible =
            this.config.isDotNotificationVisible && this.hasNotificationToShow(link);

        const intent = {
            type: link.intent && link.intent.type,
            data: link.intent && JSON.stringify(link.intent.data),
        };

        const data = { intent, label: this.deriveLabelNamePrefix(link.label) };
        const label = this.resolveString(link.label);
        return (
            <Button
                config={this.config.linkButton}
                onClickData={data}
                onClick={this.onButtonClick}
                hrefLink={link.hrefLink}
                class={StringUtils.makeCssClassName('ta', link.label)}
                key={label}
            >
                {isDotNotificationVisible ? (
                    <DotNotification
                        config={this.config.dotNotification}
                        isVisible={isDotNotificationVisible}
                    >
                        <Image src={link.icon} config={this.config.linkIcon} />
                    </DotNotification>
                ) : (
                    <Image src={link.icon} config={this.config.linkIcon} />
                )}
                <Text config={this.config.linkLabel}>{label}</Text>
            </Button>
        );
    }

    render() {
        // we need to render dummyContainer and include it to normal layout flow to fix scrolling issue
        // that will happen if this component will be placed inside scrollable area.
        // Due to the `position:fixed` the container will be moved out from normal flow layout,
        // that's why there will be no possible to scroll content to the end. Dummy container fixes this
        // preserving space at the bottom of the container.
        return (
            <>
                <div style={this.style.dummyContainer} />
                <AbsolutePane config={this.config.container}>
                    <FlexPane config={this.config.linksContainer}>{this.renderLinks()}</FlexPane>
                </AbsolutePane>
            </>
        );
    }
}

QuickLinks.getStyle = config => {
    const { container } = config;
    return {
        dummyContainer: pick(container.style, 'width', 'height', 'left', 'right', 'top', 'bottom'),
    };
};

export default connect()(QuickLinks);
