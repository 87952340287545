import PropTypes from 'prop-types';
import SearchOverlayImpl from './search-overlay.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * View for search overlay.
 *
 * @class SearchOverlay
 * @memberof Mojito.Application.Search
 */

/**
 * SearchOverlay prop types.
 *
 * @property {boolean} isOpen - Search open state.
 * @property {Function} [onSearchClose = ()=>{}] - Callback for search close event.
 *
 * @memberof Mojito.Application.Search.SearchOverlay
 */
const propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSearchClose: PropTypes.func,
};

const defaultProps = {
    onSearchClose: noop,
};

export default UIView('SearchOverlay', SearchOverlayImpl, propTypes, defaultProps);
