import PropTypes from 'prop-types';
import PayableImpl from './payable-info.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to display payable.
 *
 * @class PayableInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * PayableInfo props.
 *
 * @property {number} [value] - Payable value.
 * @property {number} [previousValue] - Previous payable value.
 * @property {boolean} [isTotal = true] - Flag to indicate if payable is total or not.
 * @property {boolean} [hasDivider] - Flag to append $BET_FUNDS.DIVIDER to label.
 * @property {string} currencyCode - Currency.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.PayableInfo
 */
const propTypes = {
    value: PropTypes.number,
    previousValue: PropTypes.number,
    isTotal: PropTypes.bool,
    hasDivider: PropTypes.bool,
    currencyCode: PropTypes.string.isRequired,
};

const defaultProps = {
    isTotal: true,
};

export default UIView('PayableInfo', PayableImpl, propTypes, defaultProps);
