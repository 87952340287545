/**
 * Abstract gamification service to be implemented by different integrations.
 *
 * @class AbstractGamificationService
 * @abstract
 * @memberof Mojito.Services.Gamification
 */
export default class AbstractGamificationService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @function Mojito.Services.Gamification.AbstractGamificationService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Connect to gamification endpoints.
     *
     * @param {string} userName - User's name.
     * @param {string} userId - User's id.
     * @returns {Promise} Promise will be resolved on gamification service connect.
     * @function Mojito.Services.Gamification.AbstractGamificationService#connect
     */
    // eslint-disable-next-line no-unused-vars
    connect(userName, userId) {
        return Promise.resolve('connect must be implemented by concrete subclass!');
    }

    /**
     * Disconnect from gamification endpoints.
     *
     * @returns {Promise} Promise will be resolved on gamification service disconnect.
     * @function Mojito.Services.Gamification.AbstractGamificationService#disconnect
     */
    disconnect() {
        return Promise.resolve('disconnect must be implemented by concrete subclass!');
    }

    /**
     * Retrieves the initial set of data (user, levels, badges, etc.).
     *
     * @param {string} [staticDataUrl] - An optional URL for loading the initial data as a static resource. This could be an absolute URL path to JSON or XML containing static gamification data.
     *
     * @returns {Promise} Returns a promise that resolves with the initial data set.
     *
     * @function Mojito.Services.Gamification.AbstractGamificationService#getInitialDataSet
     */
    // eslint-disable-next-line no-unused-vars
    getInitialDataSet(staticDataUrl) {
        return Promise.resolve('getInitialDataSet must be implemented by concrete subclass!');
    }

    /**
     * Subscribe on badge updates.
     *
     * @param {Function} callback - Callback called on each update.
     * @function Mojito.Services.Gamification.AbstractGamificationService#onBadgeUpdate
     */
    // eslint-disable-next-line no-unused-vars
    onBadgeUpdate(callback) {}

    /**
     * Subscribe on badges state update.
     *
     * @param {Function} callback - Callback called on each update.
     * @function Mojito.Services.Gamification.AbstractGamificationService#onBadgesState
     */
    // eslint-disable-next-line no-unused-vars
    onBadgesState(callback) {}
}
