/**
 * Abstract task to be implemented for usage in conjunction with TaskRunner.
 *
 * @class AbstractTask
 * @abstract
 * @memberof Mojito.Services.Common
 */
export default class AbstractTask {
    /**
     * Initiates the execution of the task.
     * Can be called in asynchronous way.
     *
     * @param {Function} callback - The function that will be invoked upon the completion of the task execution.
     * @function Mojito.Services.Common.AbstractTask#execute
     */
    // eslint-disable-next-line no-unused-vars
    execute(callback) {
        // To be implemented by subclasses
    }
}
