import MojitoCore from 'mojito/core';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class ApplicationFooter extends UIViewImplementation {
    render() {
        const placeholder = 'PLACEHOLDER';
        return (
            <div style={this.style.container} className="ApplicationFooter">
                {placeholder}
            </div>
        );
    }
}

ApplicationFooter.getStyle = function (/* Config, applicationMode, merge */) {
    const style = {
        container: {
            overflow: 'hidden',
            height: 100,
            background: '#c3c3c3',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: 28,
        },
    };

    return style;
};
