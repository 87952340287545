import {PALETTE_IDS} from 'core/application/modules/ids';
import {COLOR, FONT} from '../theme';

export function getAccountConfigs() {
    return {
        palette: {
            [PALETTE_IDS.ACCOUNT_POPOVER]: {
                ACCOUNT_BALANCE_LABEL_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                    fontSize: FONT.SIZE_XSMALL,
                    textTransform: 'uppercase',
                },
            },
            [PALETTE_IDS.MY_ACCOUNT_DIALOG]: {
                MY_ACCOUNT_DIALOG_TAB_BUTTON_BG: COLOR.BRAND.BACKGROUND6,
                MY_ACCOUNT_DIALOG_TAB_BUTTON_SELECTED_BG: COLOR.BRAND.BACKGROUND1,
                MY_ACCOUNT_DIALOG_TAB_BUTTON_TEXT: `${COLOR.BRAND.FOREGROUND5}|${COLOR.BRAND.FOREGROUND1}`,
            },
        },
    };
}
