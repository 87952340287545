import PropTypes from 'prop-types';
import Hooks from 'core/presentation/hooks';
import UIViewUtils from './utils';
import ComponentRenderer from 'core/presentation/component-renderer';

/**
 * UIView is a High Order Component (HoC) function used to wrap the component implementation
 * with basic Mojito functionality and tooling useful for each view.
 *
 * UIView will look for the component implementation by its name, if nothing is found,
 * the default implementation from function arguments will be used.
 *
 * As a result, it will return a new functional component that renders implementation
 * and passes Mojito helpers and config objects through props.
 *
 * @function UIView
 * @memberof Mojito.Core.Presentation
 *
 * @param {string} name - The name of the view.
 * @param {object} defaultImpl - The default implementation of the class.
 * @param {object} [propTypes] - The prop types of the view.
 * @param {object} [defaultProps = {}] - The default prop values of the view.
 * @param {Function} [loadingView] - Loading view component will be shown for suspense implementations.
 *
 * @returns {Function} MojitoComponent function.
 */
export default function UIView(
    name,
    defaultImpl,
    propTypes,
    defaultProps,
    loadingView = undefined
) {
    const mojitoPropTypes = {
        ...propTypes,
        config: PropTypes.object,
    };

    const MojitoComponent = props => {
        const { implementation: Implementation } = props;
        const componentProps = UIViewUtils.ensureDefaultProps(props, defaultProps);

        const mojitoTools = Hooks.useComponentTools(
            name,
            props.config,
            Implementation,
            Hooks.useAppContext(),
            Hooks.useEmitPerformanceMark()
        );
        Hooks.usePropTypesValidation(componentProps, mojitoPropTypes, name);

        return <Implementation {...componentProps} mojitoTools={mojitoTools} />;
    };

    const ComponentWrapper = props => {
        return (
            <ComponentRenderer name={name} defaultImpl={defaultImpl} loadingView={loadingView}>
                {impl => <MojitoComponent {...props} implementation={impl} />}
            </ComponentRenderer>
        );
    };

    ComponentWrapper.propTypes = mojitoPropTypes;
    ComponentWrapper.displayName = `${name}UIViewWrapper`;

    return ComponentWrapper;
}
