import MojitoPresentation from 'mojito/presentation';
import { resolveMarketLayout } from 'modules/market/helper/market-helper';
import Market from 'modules/market/index.jsx';
import {
    useMarketsSelectionInfos,
    useLimitedMarketSelectionInfos,
} from 'modules/aggregated-market-pane/hooks';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import ShowAllSelectionsButton from 'modules/aggregated-market-pane/show-all-selections-button';
import MarketTypes from 'modules/market/types.js';
import { noop } from 'mojito/utils';
import SelectionHeaders from 'modules/aggregated-market-pane/selection-headers';

const { DIRECTION } = MarketTypes;
const { Text, FlexPane } = MojitoPresentation.Components;
const { MarketHelper } = MojitoPresentation.Utils;

const GenericMarketContent = props => {
    const { aggregatedMarket, eventItem, mojitoTools, showEachWayTerms, showSelectionHeaders } =
        props;
    const { config, style } = mojitoTools;
    const { eachWayTerms, markets, mask } = aggregatedMarket;
    const [minimized, setMinimized] = useState(true);
    const [layout, setLayout] = useState(undefined);
    const marketRef = useRef(null);
    const [showAllButtonVisible, setShowAllButtonVisible] = useState(false);

    const onToggleShowAllButton = () => {
        !minimized && marketRef?.current.scrollIntoView();
        setMinimized(!minimized);
    };

    const onSelectionLinesOverflow = useCallback(exceeded => {
        setShowAllButtonVisible(exceeded);
    }, []);

    const isRowLayout = layout === DIRECTION.ROW;

    return (
        <FlexPane class="ta-GenericMarketContent" config={config.container} elementRef={marketRef}>
            {eachWayTerms && showEachWayTerms && (
                <Text class="ta-EachWayTerms" config={config.eachWayTermsLabel}>
                    {MarketHelper.getEachWayLabel(eachWayTerms, mojitoTools.l10n)}
                </Text>
            )}

            <FlexPane config={config.innerContainer} class={'ta-InnerMarketsContainer'}>
                {showSelectionHeaders && isRowLayout && (
                    <SelectionHeaders
                        eventItem={eventItem}
                        market={markets?.[0]}
                        config={style.selectionHeaders}
                    />
                )}
                <MarketsContent
                    config={config}
                    minimized={minimized}
                    onSelectionLinesOverflow={onSelectionLinesOverflow}
                    markets={markets}
                    eventItem={eventItem}
                    selectionsMask={mask}
                    componentStyle={style}
                    onLayoutChange={setLayout}
                />
            </FlexPane>

            {showAllButtonVisible && (
                <ShowAllSelectionsButton
                    config={config.showAllSelectionsButton}
                    onMinimizeChange={onToggleShowAllButton}
                    minimized={minimized}
                />
            )}
        </FlexPane>
    );
};

GenericMarketContent.getStyle = (config, mode, merge) => {
    return {
        columnMarket: merge(config.market, { direction: DIRECTION.COLUMN }),
        selectionHeaders: merge(config.selectionHeaders, {
            layoutDescriptors: config.layoutDescriptors,
        }),
    };
};

export default GenericMarketContent;

const MarketsContent = ({
    config,
    markets,
    eventItem,
    selectionsMask,
    minimized,
    onSelectionLinesOverflow,
    componentStyle,
    onLayoutChange = noop,
}) => {
    const { rowsThreshold, numberOfExpandedRows } = config;
    const marketsDirectionRef = useRef({});
    const [layout, setLayout] = useState(undefined);
    const marketsSelectionInfos = useMarketsSelectionInfos(
        markets,
        config,
        selectionsMask,
        eventItem.homeAway,
        layout === DIRECTION.COLUMN
    );
    const [limitedSelectionInfos, exceedThreshold] = useLimitedMarketSelectionInfos(
        marketsSelectionInfos,
        minimized,
        rowsThreshold,
        numberOfExpandedRows
    );

    useEffect(
        () => onSelectionLinesOverflow(exceedThreshold),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [exceedThreshold]
    );

    const onMarketDirectionChange = (direction, marketId) => {
        marketsDirectionRef.current[marketId] = marketId ? direction : undefined;
        const marketDirections = Object.values(marketsDirectionRef.current);
        const isColumn = marketDirections.some(direction => direction === DIRECTION.COLUMN);
        const isRow = marketDirections.every(direction => direction === DIRECTION.ROW);
        let marketLayout = undefined;
        if (isColumn) {
            marketLayout = DIRECTION.COLUMN;
        } else if (isRow) {
            marketLayout = DIRECTION.ROW;
        }
        setLayout(marketLayout);
        onLayoutChange(marketLayout);
    };

    const marketConfig = useMemo(
        () => (layout === DIRECTION.COLUMN ? componentStyle.columnMarket : config.market),
        [layout, componentStyle.columnMarket, config.market]
    );

    return (
        <FlexPane class="ta-MarketsContainer" config={config.marketsContainer}>
            {markets.map((market, index) => {
                const marketLayout = resolveMarketLayout(market, config.layoutDescriptors);
                // This is needed to keep selections of specific types in corresponding columns like [1, X, 2], [O, U], [1, 2] etc.
                const selectionTypes =
                    marketLayout.selectionTypes ||
                    selectionsMask ||
                    marketLayout.default.selectionTypes;
                const marketId = market?.id || index;
                const selectionsInfo = limitedSelectionInfos.find(info => info.id === marketId);
                const selectionGroups = selectionsInfo?.groups;
                // we should render <Market /> if there is no market prop to be able to show unavailable market
                const shouldRenderMarket =
                    !market || selectionGroups.some(group => group.length > 0);

                return (
                    shouldRenderMarket && (
                        <Market
                            key={marketId}
                            market={market}
                            event={eventItem}
                            config={marketConfig}
                            selectionGroups={selectionGroups}
                            selectionTypes={selectionTypes}
                            nameDisplay={marketLayout.selectionNameDisplay}
                            onDirectionChange={onMarketDirectionChange}
                        />
                    )
                );
            })}
        </FlexPane>
    );
};
