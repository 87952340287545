import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const { UNKNOWN } = ServicesTypes.CONTENT_STATE;
const reduxInstance = MojitoCore.Services.redux;
const EMPTY_LIST = [];

/**
 * Sport meta information selectors.
 *
 * @class SportMetaInformationSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.SportMetaInformation
 */

/**
 * Selects sport meta information state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.SportMetaInformationState} The state of the sport meta information.
 *
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.selectors
 */

export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select sport meta information.
 *
 * @function selectSportMetaInformation
 *
 * @param {string} type - Id of wanted sport meta information value.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Sport meta information by type.
 *
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.selectors
 */
export const selectSportMetaInformation = (type, state) => {
    return selectState(state).sportMetaInformation[type] || EMPTY_LIST;
};

/**
 * Select sport meta information state.
 *
 * @function selectSportMetaInformationState
 *
 * @param {string} type - Id of wanted sport meta information state.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Sport meta information state. Unknown if no state is found.
 *
 * @memberof Mojito.Services.SportsContent.SportMetaInformation.selectors
 */
export const selectSportMetaInformationState = (type, state) => {
    return selectState(state).sportMetaState[type] || UNKNOWN;
};
