/**
 * @class types
 * @memberof Mojito.Services.MetaInformation
 */

/**
 * Types for meta information values.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.MetaInformation.types
 */
const META_INFORMATION_TYPES = {
    LIVE_MATCH_EVENTS: 'live_match_events',
    LIVE_OUTRIGHT_EVENTS: 'live_outright_events',
};

export default { META_INFORMATION_TYPES };
