import MojitoCore from 'mojito/core';
import Button from 'presentation/components/button/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import { omit } from 'mojito/utils';

const UIViewUtils = MojitoCore.Presentation.UIViewUtils;
const DEFAULT = 'default';
const ACTIVE = 'active';

const ImageButton = props => {
    const {
        label,
        active,
        mojitoTools: { config, style },
    } = props;
    const propsWithoutLabel = omit(props, ['label']);
    const mode = active ? ACTIVE : DEFAULT;
    const elementsConfig = style[mode];
    return (
        <Button {...UIViewUtils.omitComponentOwnProps(propsWithoutLabel)} config={config.button}>
            <FlexPane config={config.innerContainer}>
                <Image class={'ta-imageButtonIcon'} config={elementsConfig.icon} />
                {label && (
                    <Text class={'ta-imageButtonLabel'} config={elementsConfig.label}>
                        {label}
                    </Text>
                )}
            </FlexPane>
        </Button>
    );
};

ImageButton.getStyle = (config, mode, merge) => {
    const { buttonIcon, buttonIconActive, buttonLabel, buttonLabelActive } = config;
    return {
        [DEFAULT]: { icon: buttonIcon, label: buttonLabel },
        [ACTIVE]: {
            icon: merge(buttonIcon, buttonIconActive),
            label: merge(buttonLabel, buttonLabelActive),
        },
    };
};

export default ImageButton;
