import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;
import ServicesTypes from 'services/common/types.js';

/**
 * Container items store selectors.
 *
 * @class ContainerItemsSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.ContainerItems.selectors */

/**
 * Selects container items state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.ContainerItems.ContainerItemsState} The state of the sports content store.
 * @memberof Mojito.Services.SportsContent.ContainerItems.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select items by list id.
 *
 * @function selectItems
 *
 * @param {string} listId - List id to retrieve information for.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array} Items list for particular group.
 * @memberof Mojito.Services.SportsContent.ContainerItems.selectors
 */
export const selectItems = (listId, state) => selectState(state)?.itemLists[listId];

/**
 * Select sport class subscription suffix by id.
 *
 * @function selectSuffixById
 *
 * @param {string} listId - List id to retrieve information for.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Item suffix. Typically, used to subscribe to particular class or type.
 * @memberof Mojito.Services.SportsContent.ContainerItems.selectors
 */
export const selectSuffixById = (listId, state) => selectState(state)?.suffixes[listId];

/**
 * Select container items loading state.
 *
 * @function selectItemsState
 *
 * @param {string} listId - List id to retrieve information for.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Container items loading state.
 * @memberof Mojito.Services.SportsContent.ContainerItems.selectors */
export const selectItemsState = (listId, state) =>
    selectState(state)?.itemsState[listId] || ServicesTypes.CONTENT_STATE.UNKNOWN;
