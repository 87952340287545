import { useEffect, useMemo, useRef, useState } from 'react';
import { useMarketItems } from 'modules/common/hooks/index.js';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import AggregatedMarketTypes from 'modules/aggregated-market-pane/types.js';
import {
    resolveMarketLayout,
    resolveSelectionGroupsByType,
} from 'modules/market/helper/market-helper';
import { sumBy } from 'mojito/utils';

const { useArrayComparator } = MojitoPresentation.Hooks;
const { GOAL_SCORER_MENU_ITEM } = AggregatedMarketTypes;
const { useResizeDetector } = MojitoCore.Base.resizeDetector;
const { utils: EventUtils } = MojitoServices.SportsContent.Events;
/**
 * Contains custom hooks for expandable market info module.
 *
 * @class Hooks
 * @name hooks
 * @memberof Mojito.Modules.AggregatedMarketPane
 */

/**
 * Uses {@link Mojito.Services.SportsContent.Events.store|markets store} to fetch markets from marketInfo.marketIds list.
 * It will subscribe to markets if not available in store and trigger re-render once markets or marketIds received.
 * Returns aggregated market object handy to use for rendering.
 *
 * @function
 * @param {Mojito.Services.SportsContent.MarketGroups.types.AggregatedMarketInfo} marketInfo - Aggregated market descriptor object. Defines market lines that should be aggregated and generic market meta info.
 * @param {string} eventId - The id of the aggregated markets' event.
 * @param {boolean} [shouldRequest = true] - Flag indicating if hook should perform markets request.
 *
 * @returns {Mojito.Services.SportsContent.Events.types.AggregatedMarket} Aggregated market with the list of markets and additional meta info.
 * @memberof Mojito.Modules.AggregatedMarketPane.hooks
 */
export const useAggregatedMarket = (marketInfo, eventId, shouldRequest = true) => {
    const { marketIds } = marketInfo || {};
    const resultCache = useRef();
    const didMarketsChange = useArrayComparator();
    const didMarketIdsChange = useArrayComparator();
    const markets = useMarketItems(eventId, marketIds, shouldRequest);

    if (didMarketsChange(markets) || didMarketIdsChange(marketIds)) {
        resultCache.current = markets
            ? EventUtils.createAggregatedMarket(marketInfo, markets)
            : undefined;
    }
    return resultCache.current;
};

/**
 * Hook provides functionality to split markets in two groups: popular and other based on the total amount of available markets,
 * marketMinWidth and marketsGap. The behaviour is responsive to the size change on the markets container. To support this hook returns holderRef
 * object that can be used as a ref on markets container element.
 * Note: only support 'row' markets layout.
 *
 * @function useMarketGroupsMenu
 *
 * @param {Array} allMarkets - List of market objects.
 * @param {number} [marketMinWidth] - Min width of the market. If not provided then there will be not markets split.
 * @param {number} [marketsGap = 0] - The size spacing between markets in parent container.
 *
 * @returns {{selectedItem: string, setSelectedItem: Function, markets: Array, elementRef: object}} Hook resulting object.
 * @memberof Mojito.Modules.AggregatedMarketPane.hooks
 */
export const useMarketGroupsMenu = (allMarkets, marketMinWidth, marketsGap = 0) => {
    const [selectedItem, setSelectedItem] = useState();
    const [popularMarkets, otherMarkets, elementRef] = useResponsiveMarketGroups(
        allMarkets,
        marketMinWidth,
        marketsGap,
        selectedItem
    );
    useDefaultMenuItem(selectedItem, setSelectedItem, !!otherMarkets.length);
    const marketsProvider = {
        [GOAL_SCORER_MENU_ITEM.POPULAR]: popularMarkets,
        [GOAL_SCORER_MENU_ITEM.OTHER]: otherMarkets,
    };
    const markets = marketsProvider[selectedItem] || allMarkets;
    return { selectedItem, setSelectedItem, markets, elementRef };
};

/**
 * Hook to get grouped selection infos from market.
 *
 * @function useMarketsSelectionInfos
 *
 * @param {Array<object>} markets - Markets list.
 * @param {object} config - Markets config object.
 * @param {Array<Mojito.Services.SportsContent.MarketGroups.types.LINE_TYPE>} selectionsMask - Market lines types mask, reflects the type of market line within marketIds list.
 * @param {boolean} homeAway - True if home team should be first in list.
 * @param {boolean} isColumnLayout - True if the selections are forced to display in one column based on the responsive market selection feature.
 *
 * @returns {Array<{id: string, groups: Array<object> }>} Hook resulting object.
 * @memberof Mojito.Modules.AggregatedMarketPane.hooks
 */
export const useMarketsSelectionInfos = (
    markets,
    config,
    selectionsMask,
    homeAway,
    isColumnLayout
) => {
    return useMemo(() => {
        return markets.map((market, index) => {
            const marketLayout = resolveMarketLayout(market, config.layoutDescriptors);
            const { selectionTypes, fallbackSelectionTypes } = marketLayout;
            const selectionTypesMask = selectionTypes || selectionsMask || fallbackSelectionTypes;
            const { defaultNumberOfSelectionGroups } = config.market;
            const selectionGroups = resolveSelectionGroupsByType(
                market,
                selectionTypesMask,
                !homeAway,
                defaultNumberOfSelectionGroups,
                true
            );
            return {
                id: market?.id || index,
                groups: isColumnLayout ? [selectionGroups.flat()] : selectionGroups,
            };
        });
    }, [markets, config, selectionsMask, homeAway, isColumnLayout]);
};

/**
 * Hook provides functionality to limit amount of market selections rows depending on rowsThreshold and numberOfExpandedRows.
 *
 * @function useLimitedMarketSelectionInfos
 *
 * @param {Array<{id: string, groups: Array<object> }>} marketsSelectionInfos - List of market objects.
 * @param {boolean} minimized - True if selection rows are collapsed.
 * @param {number} rowsThreshold - Maximum number of selection rows showing without collapsing.
 * @param {number} numberOfExpandedRows - Number of selection rows that will be shown if all rows number is more than rowsThreshold.
 *
 * @returns {Array<{selectionInfos: Array, exceedThreshold: boolean}>} Hook resulting object.
 * @memberof Mojito.Modules.AggregatedMarketPane.hooks
 */
export const useLimitedMarketSelectionInfos = (
    marketsSelectionInfos,
    minimized,
    rowsThreshold,
    numberOfExpandedRows
) => {
    const resolveLinesCount = selectionGroups => {
        const selectionCountInGroups = selectionGroups.map(group => group.length);
        return Math.max(...selectionCountInGroups);
    };

    return useMemo(() => {
        const totalSelectionLines = sumBy(marketsSelectionInfos, marketInfo =>
            resolveLinesCount(marketInfo.groups)
        );
        const exceedThreshold = rowsThreshold !== -1 && totalSelectionLines > rowsThreshold;
        const shouldLimit = exceedThreshold && minimized;

        let renderedLinesCount = 0;
        const selectionInfos = shouldLimit
            ? marketsSelectionInfos.map(marketInfo => {
                  const linesCountAllowedToShow = numberOfExpandedRows - renderedLinesCount;
                  const selectionGroups = marketInfo.groups;
                  const limitedSelectionGroups = selectionGroups.map(group =>
                      group.slice(0, linesCountAllowedToShow)
                  );
                  renderedLinesCount += resolveLinesCount(limitedSelectionGroups);
                  return { ...marketInfo, groups: limitedSelectionGroups };
              })
            : marketsSelectionInfos;

        return [selectionInfos, exceedThreshold];
    }, [minimized, marketsSelectionInfos, rowsThreshold, numberOfExpandedRows]);
};

const useResponsiveMarketGroups = (markets, marketMinWidth, marketsGap, selectedGroup) => {
    const [groups, setMarketGroups] = useState({ popular: [], other: [] });

    const { elementRef, width: holderWidth } = useResizeDetector();

    const evaluateMarketGroups = (popular, other) => {
        if (!holderWidth) {
            return;
        }
        const marketsCount = popular.length;
        const gapsCount = marketsCount ? marketsCount - 1 : 0;
        const marketsAccSize = marketsCount * marketMinWidth + gapsCount * marketsGap;
        const marketColumnSize = marketMinWidth + marketsGap;
        if (marketsAccSize > holderWidth && popular.length > 3) {
            const sizeDelta = marketsAccSize - holderWidth;
            const itemsCountToMove = Math.ceil(sizeDelta / marketColumnSize);
            const maxItemsToRemove = popular.length - 3;
            moveMarketBetweenGroups(popular, other, Math.min(itemsCountToMove, maxItemsToRemove));
            setMarketGroups({ popular, other });
        } else if (marketsAccSize < holderWidth && other.length) {
            const sizeDelta = holderWidth - marketsAccSize;
            if (sizeDelta >= marketColumnSize) {
                const itemsCountToMove = Math.floor(sizeDelta / marketColumnSize);
                moveMarketBetweenGroups(other, popular, itemsCountToMove, false);
                setMarketGroups({ popular, other });
            }
        }
    };

    useEffect(() => {
        // Ignore resize when other menu item is selected
        if (selectedGroup === GOAL_SCORER_MENU_ITEM.OTHER || !marketMinWidth) {
            return;
        }
        evaluateMarketGroups(groups.popular, groups.other);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [holderWidth]);

    // Recalculate groups if markets collections has been changed.
    useEffect(() => {
        if (!marketMinWidth) {
            return;
        }
        const currentMarketsCount = groups.popular.length + groups.other.length;
        if (
            selectedGroup === GOAL_SCORER_MENU_ITEM.OTHER &&
            currentMarketsCount === markets.length
        ) {
            // Arrange markets between groups in a same way as it was before.
            // It is safe to do if other menu item is selected and no markets length changed.
            const popular = [...markets];
            const other = [];
            moveMarketBetweenGroups(popular, other, groups.other.length);
            setMarketGroups({ popular, other });
            return;
        }
        const defaultGroups = { popular: markets.slice(), other: [] };
        setMarketGroups(defaultGroups);
        evaluateMarketGroups(defaultGroups.popular, defaultGroups.other);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markets]);

    return [groups.popular, groups.other, elementRef];
};

const useDefaultMenuItem = (menuItem, setMenuItem, hasOtherMarkets) => {
    useEffect(() => {
        if (!hasOtherMarkets) {
            // If no other markets available set to undefined. View will not render menu selector in that case.
            setMenuItem(undefined);
            return;
        }
        if (!menuItem) {
            // Ensure we have POPULAR selected menu item as default
            setMenuItem(GOAL_SCORER_MENU_ITEM.POPULAR);
        }
    }, [menuItem, setMenuItem, hasOtherMarkets]);
};

const moveMarketBetweenGroups = (first, second, count, fromTail = true) => {
    if (fromTail) {
        second.unshift(...first.splice(-count, count));
    } else {
        second.push(...first.splice(0, count));
    }
};
