/**
 * Services that want to notify clients about its outage status can implement this class.
 *
 * @class RestorableService
 * @abstract
 * @memberof Mojito.Services.UserInfo.Balance
 */
export default class RestorableService {
    /**
     * Service status.
     *
     * @returns {boolean} Service status.
     *
     * @function Mojito.Services.UserInfo.Balance.RestorableService#isActive
     */
    isActive() {
        return true;
    }

    /**
     * Stores restore failed listening function.
     *
     * @param {Function} onRestoreFn - Function to be called when service got connected.
     *
     * @function Mojito.Services.UserInfo.Balance.RestorableService#onRestore
     */
    onRestore(onRestoreFn) {
        this.onRestoreFn = onRestoreFn;
    }

    /**
     * Stores fail listening function.
     *
     * @param {Function} onFailFn - Function to be called when service got disconnected.
     *
     * @function Mojito.Services.UserInfo.Balance.RestorableService#onFail
     */
    onFail(onFailFn) {
        this.onFailFn = onFailFn;
    }
}
