import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import MojitoCore from 'mojito/core';
import HomePage from 'application/pages/home-page/index.jsx';
import EventPage from 'application/pages/event-page/index.jsx';
import { useParams, useSearchParams, createSearchParams, Navigate } from 'react-router-dom';

const {
    selectors: { selectEventId, isLoaded },
    actions: notificationEventActions,
} = MojitoServices.SportsContent.NotificationEvent;
const { useAppContext } = MojitoCore.Presentation.Hooks;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;

function usePreviousValue(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

function InplayEventController(props) {
    const { notificationsIdSearchParam = {} } = props.mojitoTools.config;
    const { isInplayRoute: isInplayPage } = props;
    const [search] = useSearchParams();
    const { eventId } = useParams();
    const previousEventId = usePreviousValue(eventId);

    if (search.get(notificationsIdSearchParam.name) === notificationsIdSearchParam.value) {
        return <NotificationEventController />;
    }

    const shouldScrollTop = previousEventId !== eventId;

    return <EventPage autoScrollTop={shouldScrollTop} isInplayPage={isInplayPage} />;
}

function NotificationEventController() {
    const dispatch = useDispatch();
    const { search } = useSearchParams();
    const { eventId: notificationsEventId } = useParams();
    const { routeResolver } = useAppContext();

    useEffect(() => {
        dispatch(notificationEventActions.getEventId(notificationsEventId));
    }, [notificationsEventId, dispatch]);

    const loaded = useSelector(state => isLoaded(notificationsEventId, state));
    const eventId = useSelector(state => selectEventId(notificationsEventId, state));

    if (loaded) {
        const url = eventId
            ? NavigationPathBuilder.inplayEventPath(eventId, routeResolver)
            : NavigationPathBuilder.inplayPath(undefined, routeResolver);

        // Redirect instead of using intentions - in order to change search param along with URL
        return <Navigate to={`${url}?${createSearchParams(search)}`} />;
    }

    return <HomePage />;
}

export default InplayEventController;
