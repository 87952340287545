import PropTypes from 'prop-types';
import FreebetStakeImpl from './freebet-stake-info.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Component to display freebet stake info of bet funds.
 *
 * @class FreebetStakeInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * FreebetStakeInfo props.
 *
 * @property {number} [value] - Stake value.
 * @property {string} currencyCode - Currency.
 * @property {boolean} [showFreeBetIcon = false] - Flag to display an icon if stake contains freebet.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.FreebetStakeInfo
 */
const propTypes = {
    value: PropTypes.number,
    currencyCode: PropTypes.string.isRequired,
    showFreeBetIcon: PropTypes.bool,
};

const defaultProps = {
    showFreeBetIcon: false,
};

export default UIView('FreebetStakeInfo', FreebetStakeImpl, propTypes, defaultProps);
