/* eslint no-unused-vars: "off" */
import {Logger} from 'core/utils/logger';

export class DBXAbstractPerformanceLogger {
    /**
     * Set metadata
     * @param tags {object} Key-value map
     */
    setTags(tags) {
        this.tags = tags;
    }
    /**
     * Called when first WS subscription created
     * @param path {string}
     */
    wsActivityStarted(path) {
        // Do nothing
    }

    /**
     * Called to log collected performance statistics about websocket
     * @param data {object}
     */
    wsActivityData(data) {
        // Do nothing
    }

    /**
     * Called when all subscriptions received at least one response.
     * It also may be called to close corresponding wsActivityStarted, even if no actual websocket activity
     * @param path {string}
     */
    wsActivityMostlyFinished(path) {
        // Do nothing
    }
    /**
     * Report timing metric
     * @param name {string} Name of metric
     * @param timestamp {number} Milliseconds since navigation started
     */
    reportTiming(name, timestamp) {
        // do nothing
    }
    /**
     * Report duration metric
     * @param name {string} Name of metric
     * @param duration {number} Milliseconds
     */
    reportDuration(name, duration) {
        // do nothing
    }

    /**
     * Report metric
     * @param name {string} Name of metric
     * @param value {number} value
     * @param unit {string}
     */
    reportMetric(name, value, unit) {
        // do nothing
    }
}

const COLOR = Logger.STYLE_DEBUG;
export class DBXConsolePerformanceLogger extends DBXAbstractPerformanceLogger {
    constructor() {
        super();
        this.log = Logger('Performance logger');
    }
    setTags(tags) {
        this.log.info(`%cFollowing tags are set:`, COLOR, tags);
    }
    reportTiming(name, timestamp) {
        this.log.info(`%c${name} at ${Math.round(timestamp)}ms`, COLOR);
    }
    reportDuration(name, duration) {
        this.log.info(`%c${name} took ${Math.round(duration)}ms`, COLOR);
    }
    reportMetric(name, value, unit) {
        this.log.info(`%c${name} is ${value} ${unit}`, COLOR);
    }
    wsActivityStarted(path) {
        this.log.info(`%cStarted WS activity on page ${path}`, COLOR);
    }
    wsActivityMostlyFinished(path) {
        this.log.info(`%cMostly finished WS activity for ${path}`, COLOR);
    }
    wsActivityData(data) {
        this.log.info('%cWS activity statistics:', COLOR, data);
    }
}
