import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const { CONTENT_STATE } = ServicesTypes;
const reduxInstance = MojitoCore.Services.redux;
const EMPTY_OBJECT = {};

/**
 * Menus related selectors.
 *
 * @module MenusSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.Menus
 */

/**
 * Selects menus state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsConent.MenusState} The state of the menus store.
 * @memberof Mojito.Services.SportsContent.Menus.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select all sport menus.
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.Menus.types.SportMenusByTimeZone} Object containing all sport menus, empty if no menus available in state.
 * @memberof Mojito.Services.SportsContent.Menus.selectors#selectSportMenus
 */
export const selectSportMenus = state => selectState(state).sportMenusByTimeZone;

/**
 * Select menu content info.
 *
 * @param {string} sportId - Sport id.
 * @param {string} timeZone - Time zone.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.Menus.types.MenuConent} Object containing sport menu content info, empty if no menu content info is found.
 * @memberof Mojito.Services.SportsContent.Menus.selectors#selectMenuContentInfo
 */
export const selectMenuContentInfo = (sportId, timeZone, state) => {
    const timeZoneContent = selectSportMenus(state)[timeZone] || {};
    const sportContent = timeZoneContent[sportId];
    return sportContent || EMPTY_OBJECT;
};

/**
 * Select menu state.
 *
 * @param {string} id - Menu id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Menus state. Unknown if no state is found.
 * @memberof Mojito.Services.SportsContent.Menus.selectors#selectMenuState
 */
export const selectMenuState = (id, state) =>
    selectState(state).sportMenusState?.[id] || CONTENT_STATE.UNKNOWN;
