import SortingHelper from 'presentation/formatters/sorting/index.js';
import MojitoCore from 'mojito/core';
import { range } from 'mojito/utils';

const { selectLanguage } = MojitoCore.Services.SystemSettings.selectors;
const StringUtils = MojitoCore.Base.StringUtils;

/**
 * The MarketHelper offers some utility functions for markets.
 *
 * @class MarketHelper
 * @memberof Mojito.Presentation.Utils
 */
export default class MarketHelper {
    /**
     * Sort the  markets in ascending order based on displayOrder and name.
     *
     * @param {Array} markets - Markets to sort.
     * @param {string} language - Selected language in which to sort the names.
     * @function Mojito.Presentation.Utils.MarketHelper.sortMarkets
     */
    static sortMarkets(markets, language) {
        markets.sort((m1, m2) => SortingHelper.byDisplayOrderAndName(m1, m2, language));
    }

    /* eslint-disable jsdoc/require-description-complete-sentence */
    /**
     * Formats name with handicap according to the given rules:
     * <br>
     * <ol>
     * <li>Remove handicap at the end of the name.</li>
     * <li>Does nothing in case handicap was not provided.</li>
     * </ol>
     *
     * @param {string} name - Name with or without handicap.
     * @param {string} handicap - Handicap value.
     * @returns {{name: string, handicapLabel: number}} Returns an object that contains either a pure name with no handicap or a name with handicap in the middle of the text. If it results in a pure name, the handicapLabel will contain the handicap value that was removed from the name.
     * @function Mojito.Presentation.Utils.MarketHelper.handleHandicap
     */
    /* eslint-enable jsdoc/require-description-complete-sentence */
    static handleHandicap(name, handicap) {
        if (!handicap) {
            return { name, handicapLabel: '' };
        }

        // Sometimes (Handicap +...) can be embedded in the selection name, remove this to apply handicap ourselves.
        const handicapPattern = /\s*\(Handicap.*\)/;
        const _name = name.replace(handicapPattern, '');

        const handicapEscaped = handicap.replace('+', '\\+');
        const pattern = new RegExp(`\\s*${handicapEscaped}\\s*$`);
        const formatted = _name.replace(pattern, '');
        const hasHandicapInName = formatted.includes(handicap);
        return {
            name: formatted,
            handicapLabel: hasHandicapInName ? '' : handicap,
        };
    }

    /**
     * Returns a label with each way terms.
     *
     * @param {object} eachWay - Object containing the each way terms.
     * @param {object} l10n - Object with the translation strings.
     * @returns {string} Label with each way terms.
     * @function Mojito.Presentation.Utils.MarketHelper.getEachWayLabel
     */
    static getEachWayLabel(eachWay, l10n) {
        const { places, numerator, denominator } = eachWay;
        const placesString = range(places)
            .map(i => i + 1)
            .join(',');
        return (
            StringUtils.resolveAndFormatString(
                '$MARKETS.EACH_WAY_TERMS',
                l10n,
                numerator,
                denominator,
                placesString
            ) || ''
        );
    }

    /**
     * Returns price boosted market selections from the markets who's id is included in the array with price boosted
     * markets ids.
     *
     * @param {object} markets - Map of markets. Key is market id and value is market object.
     * @param {Array} boostedMarketIds - List of ids for price boosted markets.
     * @returns {Array} List of price boosted market selections.
     * @function Mojito.Presentation.Utils.MarketHelper.getPriceBoostedMarketsSelections
     */
    static getPriceBoostedMarketsSelections(markets, boostedMarketIds) {
        if (!markets) {
            return [];
        }

        const marketsArray = Object.values(markets);
        const selectedLanguage = selectLanguage();
        MarketHelper.sortMarkets(marketsArray, selectedLanguage);

        return marketsArray.flatMap(market =>
            boostedMarketIds.includes(market.id)
                ? market.selections.filter(selection => selection.basePrice)
                : []
        );
    }
}
