import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import RoutingViewImpl from './routing-view.jsx';

const UIView = MojitoCore.Presentation.UIView;
/**
 * View which handles routing functionality with usage of react router Switch.
 *
 * @class RoutingView
 * @memberof Mojito.Application
 */
/**
 * RoutingView prop types.
 *
 * @property {Array} [customRoutes] - List of objects that defines custom routes, will be added to the routing map.
 *
 * @memberof Mojito.Application.RoutingView
 */
const propTypes = {
    customRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            component: PropTypes.func,
        })
    ),
};

export default UIView('RoutingView', RoutingViewImpl, propTypes);
