import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Sports menu selectors.
 *
 * @class SportsMenuSelectors
 * @name selectors
 * @memberof Mojito.Modules.SportsMenu
 */

/**
 * Selects sports menu state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Modules.SportsMenu.SportsMenuState} The state of the sports menu.
 *
 * @memberof Mojito.Modules.SportsMenu.selectors
 */

export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select show all sports.
 *
 * @function selectShowAllSports
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if all sports should be shown, else false.
 *
 * @memberof Mojito.Modules.SportsMenu.selectors
 */
export const selectShowAllSports = state => {
    return selectState(state).showAllSports;
};

/**
 * Select favorite sports.
 *
 * @function selectFavoriteSports
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string[]} List of favorite sports.
 *
 * @memberof Mojito.Modules.SportsMenu.selectors
 */
export const selectFavoriteSports = state => {
    return selectState(state).favoriteSports;
};
