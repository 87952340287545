import VirtualSportPageView from './virtual-sport-page-view/index.jsx';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MojitoServices from 'mojito/services';
const { selectItems: selectContainerItems } = MojitoServices.SportsContent.ContainerItems.selectors;

export default function VirtualSportPage(props) {
    const { sportId, classId, eventId } = useParams();
    const { config } = props.mojitoTools;
    const { containerId } = config.virtualsEndpoint;
    const sportInfo = useSelector(state => {
        const virtualSports = selectContainerItems(containerId, state);
        return virtualSports?.find(({ id }) => id === sportId);
    });
    return (
        <VirtualSportPageView
            sportId={sportId}
            sportInfo={sportInfo}
            classId={classId}
            selectedEventId={eventId}
        />
    );
}
