import EventFormatter from 'presentation/formatters/event/formatter.js';
import RedCard from 'presentation/components/red-card/index.jsx';
import ScoreCount from 'presentation/components/score-count/index.jsx';
import MojitoCore from 'mojito/core';

const uiStyle = MojitoCore.Presentation.uiStyle;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

const EMPTY_INDICATION_CONFIG = {
    redCard: {},
    score: {},
};

export default class MiniScoreboard extends UIViewImplementation {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        return !isShallowEqual(this.props, nextProps);
    }

    getActionsConfig(sportId) {
        return this.config.gameActionsIndication[sportId] || EMPTY_INDICATION_CONFIG;
    }

    renderScore(score, key, styling, redCards, sportId, eventId) {
        const {
            redCard: { visible: showRedCard, animated: redCardAnimated },
            score: { animated: scoreAnimated },
        } = this.getActionsConfig(sportId);

        const styles = this.config.rowDirection
            ? {
                  ...this.style.score.container,
                  ...this.style.score.rowContainer[key],
              }
            : this.style.score.container;

        return (
            <div style={styles} key={key}>
                {showRedCard && (
                    <RedCard
                        config={this.config.redCard}
                        cardsNumber={redCards}
                        isAnimationEnabled={redCardAnimated}
                        key={eventId}
                    />
                )}
                {scoreAnimated ? (
                    <ScoreCount config={this.config.scoreCount} score={score} />
                ) : (
                    <div className={`ta-score-${key}`} style={this.style.score[styling]}>
                        {score}
                    </div>
                )}
            </div>
        );
    }

    resolveScoreDisplayFormat(event) {
        return this.config.sportsDisplayFormat[event.sportId]
            ? this.config.sportsDisplayFormat[event.sportId].score
            : undefined;
    }

    renderServerColumnCell(isServer, partName, key) {
        const indicatorStyle = isServer ? this.style.serverIndicator : undefined;

        return (
            <div style={this.style.serverIndicatorWrapper} key={`renderServerColumnCell-${key}`}>
                <div style={indicatorStyle} key={`server${partName}`} />
            </div>
        );
    }

    renderServerIndicatorCol(event) {
        let firstRow;
        let secondRow;
        if (event.homeAway) {
            firstRow = this.renderServerColumnCell(event.details.server === 'HOME', 'home', 0);
            secondRow = this.renderServerColumnCell(event.details.server === 'AWAY', 'away', 1);
        } else {
            firstRow = this.renderServerColumnCell(event.details.server === 'AWAY', 'away', 0);
            secondRow = this.renderServerColumnCell(event.details.server === 'HOME', 'home', 1);
        }
        return (
            <div style={this.style.scoresColumn} key="serverIndicatorCol">
                {firstRow}
                {secondRow}
            </div>
        );
    }

    renderScoreCols(event, scores) {
        const cols = [];

        // Assuming the same number of scores for home and away, which seams reasonable
        const colCount = scores.home.length;

        // Get indicators
        const indicators = EventFormatter.getScoreSegmentIndicators(
            this.resolveScoreDisplayFormat(event)
        );

        // Render server indicator if we have a server
        if (event.details.server) {
            cols.push(this.renderServerIndicatorCol(event));
        }

        const redCardsHome = event.details.redCards?.home || 0;
        const redCardsAway = event.details.redCards?.away || 0;

        // Score columns
        for (let idx = 0; idx < colCount; idx++) {
            let segmentIndicator;

            // Segment indicators?
            if (this.config.showSegmentIndicators) {
                segmentIndicator = (
                    <div style={this.style.segmentIndicator}>
                        <span
                            key={`segment-${idx}`}
                            style={this.style.segmentIndicator.text}
                            className="segmentIndicator"
                        >
                            {indicators[idx]}
                        </span>
                    </div>
                );
            }

            const chooseStyling = isMain => (isMain ? 'scoresTextMain' : 'scoresTextSecondary');

            const isMainScore = colCount === 1 || idx === colCount - 1;

            const firstScore = this.renderScore(
                event.homeAway ? scores.home[idx] : scores.away[idx],
                event.homeAway ? 'home' : 'away',
                chooseStyling(isMainScore),
                event.homeAway ? redCardsHome : redCardsAway,
                event.sportId,
                event.id
            );
            const secondScore = this.renderScore(
                event.homeAway ? scores.away[idx] : scores.home[idx],
                event.homeAway ? 'away' : 'home',
                chooseStyling(isMainScore),
                event.homeAway ? redCardsAway : redCardsHome,
                event.sportId,
                event.id
            );

            const showScoreDigits = index => {
                return (
                    <div style={this.style.scoresColumn} key={index}>
                        {segmentIndicator}

                        <div style={this.style.homeAwayScoresContainer} className="ta-scores">
                            {firstScore}
                            {this.config.rowDirection ? '-' : null}
                            {secondScore}
                        </div>
                    </div>
                );
            };

            cols.push(showScoreDigits(idx));
        }

        return cols;
    }

    renderCols(event) {
        const scores = EventFormatter.formatScores(
            event.details,
            this.resolveScoreDisplayFormat(event)
        );

        // Render nothing if we have no scores
        if (scores.home.length === 0 || scores.away.length === 0) {
            return;
        }

        let cols = [];

        // Score cols
        cols = cols.concat(this.renderScoreCols(event, scores));

        return cols;
    }

    render() {
        const { event } = this.props;

        if (!event || !event.details) {
            return <div style={this.style.container} className="MiniScoreboard" />;
        }
        let container = this.style.container;

        if (!event.homeAway && this.config.allowAmericanLayout) {
            container = uiStyle.mergeAndPrefix(container, this.style.americanLayout);
        }

        return (
            <div style={container} className="MiniScoreboard">
                <div style={this.style.columnContainer}>{this.renderCols(event)}</div>
            </div>
        );
    }
}

MiniScoreboard.getStyle = function (config /* , applicationMode, merge */) {
    return {
        container: {
            flex: '0 1 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: config.padding,
        },
        americanLayout: {
            height: '100%',
        },
        columnContainer: {
            display: 'flex',
            alignItems: 'stretch',

            flex: config.stretch ? 1 : 'initial',
        },
        scoresColumn: {
            color: config.scoresColumn.color,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            margin: config.scoreColumn.margin,
            minWidth: config.scoreColumn.minWidth,
        },
        homeAwayScoresContainer: {
            display: config.stretch || config.rowDirection ? 'flex' : 'inline-block',
            flexGrow: config.stretch ? 1 : 'initial',
            flexDirection: config.rowDirection ? 'row' : 'column',
            justifyContent: config.threeWayLayout ? 'normal' : 'space-around',
            alignItems: config.rowDirection ? 'center' : 'flex-end',
            paddingRight: config.rowDirection ? '20px' : '0',
        },
        score: {
            container: {
                textAlign: 'right',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                flexBasis: config.threeWayLayout ? '33%' : 'auto',
            },
            rowContainer: {
                away: {
                    flexDirection: 'row-reverse',
                    position: 'static',
                    ...config.rowContainer.away,
                },
                home: { position: 'static', ...config.rowContainer.home },
            },
            scoresTextMain: config.scoresTextMain,
            scoresTextSecondary: config.scoresTextSecondary,
        },
        serverIndicatorWrapper: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        serverIndicator: {
            backgroundColor: config.serverIndicator.color,
            width: config.serverIndicator.width,
            height: config.serverIndicator.width,
            borderRadius: '100%',
        },
        segmentIndicator: {
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: config.segmentIndicators.top,
            text: config.segmentIndicators.text,
        },
    };
};
