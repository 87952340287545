import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { bindAll, pick, omitBy } from 'mojito/utils';
import BetHistoryDetails from 'modules/bet-history-details/index.jsx';
import BetViewUtils from 'modules/open-bets/bet-view/utils.js';

const { CurrencyHelper, MarketHelper, BettingHelper, OddsUtils } = MojitoPresentation.Utils;
const { UIViewImplementation } = MojitoCore.Presentation;
const {
    betHistorySlice: { actions },
    utils: BetsUtils,
} = MojitoServices.Bets;
const BetsTypes = MojitoServices.Bets.types;
const log = MojitoCore.logger.get('BetHistoryView');
const { dispatch } = MojitoCore.Services.redux.store;

const {
    Text,
    FlexPane,
    Image,
    Dropdown,
    Spinner,
    Button,
    DateTime,
    GridLayout,
    ScrollPane,
    InfiniteList,
    FlexPaneSeparator,
} = MojitoPresentation.Components;

const { GridPane, GridCell, GridSeparator } = GridLayout;

const NO_INDEX_SELECTED = '-1';
const { BET_STATUS, FILTER_CRITERIA_TYPE } = BetsTypes;

export default class BetHistoryView extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.betsRangeFilterItems = this.getBetsRangeFilterItems(this.config.betsRangeFilters);
        this.betStatusFilterItems = this.getBetStatusFilterItems(BET_STATUS);
        this.hasMoreItems = false;
        this.state = {
            isScrolled: false,
            selectedIndex: NO_INDEX_SELECTED,
            showFetchingHistoryMessage: !this.config.delayBeforeShowingLoader,
        };
        bindAll(
            this,
            'getHeaderConfig',
            'handleScroll',
            'onBetsRangeFilterChange',
            'onCloseDetailsClick',
            'onFetchMore',
            'onItemClicked',
            'onStatusFilterChange',
            'onTableItemClick',
            'renderItem'
        );
    }

    componentDidMount() {
        if (this.props.userLoggedIn) {
            this.fetchBets();
        }
    }

    componentDidUpdate(prevProps) {
        // Check if user logged in. This is needed because when loading bet history through widget,
        // the user session will be restored _after_ component is rendered.
        if (!prevProps.userLoggedIn && this.props.userLoggedIn) {
            this.fetchBets();
        }

        const prevFilterValue = prevProps.selectedRangeFilter?.options?.value;
        const currFilterValue = this.props.selectedRangeFilter?.options?.value;
        if (
            prevProps.selectedStatusFilter !== this.props.selectedStatusFilter ||
            prevFilterValue !== currFilterValue
        ) {
            this.updateSelectedIndex(NO_INDEX_SELECTED);
            this.hasMoreItems = false;
        } else if (!this.props.isFetchingBetHistory) {
            this.hasMoreItems = this.props.pagination.hasMoreData;
        }
    }

    componentWillUnmount() {
        // timeoutWhenShowingLoader should only be reset before unmounting a component
        this.timeoutWhenShowingLoader && clearTimeout(this.timeoutWhenShowingLoader);
        this.resetDataLoaderDelay();
    }

    onTableItemClick(index) {
        const { selectedIndex } = this.state;
        const newSelectedIndex = selectedIndex === index ? NO_INDEX_SELECTED : index;
        this.updateSelectedIndex(newSelectedIndex);
        this.emitAnalytics('betHistoryOnDetailsClick', {
            action: `${newSelectedIndex === NO_INDEX_SELECTED ? 'Close' : 'Open'} bet details`,
            betStatus: this.props.betHistory[index].status,
        });
    }

    onCloseDetailsClick() {
        this.updateSelectedIndex(NO_INDEX_SELECTED);
        this.emitAnalytics('betHistoryOnDetailsClick', { action: 'Close bet details' });
    }

    fetchBets(params = {}) {
        const {
            statusFilter = this.getSelectedStatusFilter(),
            rangeFilter = this.getSelectedRangeFilter(),
            page,
        } = params;
        dispatch(
            actions.fetchBets({
                filters: [this.convertToStatusFilterItem(statusFilter), rangeFilter],
                pagination: this.buildPaginationObject({ page, rangeFilter }),
            })
        );
    }

    convertToStatusFilterItem(value) {
        return {
            options: {
                value,
            },
            type: FILTER_CRITERIA_TYPE.STATUS,
        };
    }

    buildPaginationObject({ page, rangeFilter } = {}) {
        if (this.isLastBetsAmountFilter(rangeFilter)) {
            return {};
        }

        return {
            page: page || 1,
            itemsPerPage: this.config.itemsPerPage,
        };
    }

    updateSelectedIndex(selectedIndex) {
        this.setState({ selectedIndex });
    }

    getSelectedBet() {
        const { selectedIndex } = this.state;
        if (selectedIndex !== NO_INDEX_SELECTED) {
            return this.props.betHistory[selectedIndex];
        }
        return null;
    }

    buildSelectionNames(bet) {
        return bet.legs.reduce((selectionNames, leg) => {
            const part = BetsUtils.getFirstLegPart(leg);
            const selectionName = BettingHelper.getSelectionName(leg, this.l10n);
            const modifiers = BettingHelper.getLegModifiers(leg, this.l10n);
            const notAvailableLabel = this.resolveString(
                '$BET_HISTORY.SELECTIONNAME_NOT_AVAILABLE'
            );
            const { name, handicapLabel } = MarketHelper.handleHandicap(selectionName, part.hcap);
            const partName = name ? name.toLowerCase() : notAvailableLabel;
            const separator = selectionNames.length
                ? this.resolveString('$BET_HISTORY.SELECTIONS_SEPARATOR')
                : '';
            const teaserHcapLabel = BettingHelper.formatTeaserHandicapText(
                part.hcapTeaser,
                this.l10n
            );
            return `${selectionNames}${separator}${partName} ${handicapLabel} ${teaserHcapLabel} ${modifiers}`.trim();
        }, '');
    }

    mapBetTableData(bet) {
        return {
            date: this.renderDateAndTime(bet),
            type: this.renderBetTypeName(bet),
            selections: this.renderSelectionNames(bet),
            stake: (
                <FlexPane config={this.config.stakeInfo}>
                    {this.renderFreebetIcon(bet)}
                    {this.renderBonusIcon(bet)}
                    {this.renderStakeText(bet)}
                </FlexPane>
            ),
            odds: this.renderPriceText(bet),
            returns: this.renderReturnsText(bet),
            status: this.renderBetStatus(bet),
        };
    }

    onItemClicked(ev, item) {
        this.emitAnalytics('betHistoryOnDetailsClick', {
            action: 'Open bet details',
            betStatus: item.status,
        });
        this.props.onItemClicked(item);
    }

    renderDateTime(bet) {
        return (
            <Text class="ta-dateTime" config={this.config.dateText}>
                <DateTime
                    config={this.config.betPlacementDateTimeFormat}
                    dateTime={bet.placementTime}
                />
            </Text>
        );
    }

    renderDateAndTime(bet) {
        return (
            <FlexPane class="ta-dateTime" config={this.config.betPlacementDateTime}>
                <Text class="ta-date" config={this.config.dateText}>
                    <DateTime
                        config={this.config.betPlacementDateTimeFormat}
                        dateTime={bet.placementTime}
                    />
                </Text>
                <Text class="ta-time" config={this.config.timeText}>
                    <DateTime
                        config={this.config.betPlacementTimeFormat}
                        dateTime={bet.placementTime}
                    />
                </Text>
            </FlexPane>
        );
    }

    renderSelectionNames(bet) {
        const eventName = bet.legs[0].legInfo.eventName;
        const isMatchAcca = BetsUtils.hasMatchAccaLeg(bet);
        // If it is a match acca we will display event name instead of selections names.
        const label = isMatchAcca ? eventName : this.buildSelectionNames(bet);
        return (
            <FlexPane class="ta-selectionNames" config={this.config.selectionNameContainer}>
                {isMatchAcca && (
                    <Text config={this.config.selectionName}>
                        {this.resolveString('$BETTING_HELPER.MATCH_ACCA')}
                    </Text>
                )}
                <Text config={this.config.selectionName}>{label}</Text>
            </FlexPane>
        );
    }

    renderBetTypeName(bet) {
        const { betType } = bet;
        return (
            <Text config={this.config.typeText} class={`ta-${betType}`}>
                {BettingHelper.getBetTypeAndModifiers(bet, this.l10n)}
            </Text>
        );
    }

    renderBetStatus(bet) {
        const { status } = bet;
        const isEarlyPayout = BetViewUtils.isEarlyPayout(bet);
        const iconStatus = BetViewUtils.getStatusIcon(status);
        const betStatusText = BetViewUtils.getStatusTextKey(status);
        return (
            <FlexPane config={this.config.betStatusContainer}>
                <Image config={this.config.betStatusIcon[iconStatus]} />
                <Text class={`ta-betStatus ta-${betStatusText}`} config={this.config.betStatusText}>
                    {BettingHelper.getBetStatusString(bet, this.l10n, isEarlyPayout)}
                </Text>
            </FlexPane>
        );
    }

    renderPriceText(bet) {
        const priceText = OddsUtils.getDisplayedOdds(bet.odds, bet.priceType, this.l10n);
        return (
            <Text config={this.config.stakeText} class="betItemPriceText">
                {priceText}
            </Text>
        );
    }

    renderStakeText(bet) {
        // Improved the stake tax presentation.
        // If a customer has tax enabled on it's environment, we show the stake value after tax.
        // If not enabled we show the stake value before tax.
        const { currency, stakeAfterTaxes, stake } = bet.funds;
        return (
            <Text config={this.config.stakeValue} class="ta-stakeValue">
                {CurrencyHelper.formatCurrency(stakeAfterTaxes || stake, currency)}
            </Text>
        );
    }

    renderFreebetIcon(bet) {
        return BetsUtils.hasFreebetStake(bet) ? <Image config={this.config.freeBetIcon} /> : null;
    }

    renderBonusIcon(bet) {
        return bet.funds.prewager ? <Image config={this.config.bonusIcon} /> : null;
    }

    renderAccaBoostIcon(bet) {
        return BetsUtils.hasAccaBoost(bet) ? <Image config={this.config.accaBoostIcon} /> : null;
    }

    renderReturnsText(bet) {
        return (
            <>
                {this.renderAccaBoostIcon(bet)}
                <Text config={this.config.returnsText} class="ta-returns">
                    {this.getReturnsValue(bet)}
                </Text>
            </>
        );
    }

    renderReturns(key, item) {
        return (
            <>
                <Text config={this.config.returnsLabel} class="ta-returnsLabel">
                    {this.resolveString(key)}
                </Text>
                <FlexPane
                    config={this.config.returnsValueContainer}
                    class={'ta-returnsValueContainer'}
                >
                    {this.renderAccaBoostIcon(item)}
                    <Text config={this.config.returnsValue} class="ta-returnsValue">
                        {this.getReturnsValue(item)}
                    </Text>
                </FlexPane>
            </>
        );
    }

    getReturnsValue(bet) {
        const { funds, status } = bet;
        const { payoutNet = 0, currency } = funds;

        return status === BET_STATUS.LOST
            ? CurrencyHelper.formatCurrency(payoutNet, currency)
            : BettingHelper.formatPayout(
                  payoutNet,
                  this.resolveString('$BET_HISTORY.VALUE_NOT_AVAILABLE'),
                  currency
              );
    }

    getReturns(bet) {
        return bet.status === BET_STATUS.OPEN
            ? this.renderReturns('$BET_HISTORY.POTENTIAL_RETURN', bet)
            : this.renderReturns('$BET_HISTORY.RETURNS_LABEL', bet);
    }

    renderItem(bet, betIdx) {
        return (
            <div key={bet.id} style={this.config.style.itemContainer}>
                <Button
                    config={this.config.item}
                    class="item"
                    onClick={this.onItemClicked}
                    onClickData={bet}
                >
                    <FlexPane config={this.config.itemHeader} class="ta-row1">
                        <FlexPane
                            config={this.config.typeAndDateContainer}
                            class="ta-typeAndDateContainer"
                        >
                            {this.renderBetTypeName(bet)}
                            {this.renderDateTime(bet)}
                        </FlexPane>
                        {this.renderBetStatus(bet)}
                    </FlexPane>
                    <div style={this.style.itemRow}>
                        {this.renderSelectionNames(bet)}
                        {this.renderPriceText(bet)}
                    </div>
                    <FlexPane config={this.config.priceInfoContainer} class="ta-row2">
                        <FlexPane config={this.config.stakeContainer} class="ta-stakeContainer">
                            <Text config={this.config.stakeLabel} class="ta-stakeLabel">
                                {this.resolveString('$BET_HISTORY.STAKE_LABEL')}
                            </Text>
                            <FlexPane
                                config={this.config.stakeValueContainer}
                                class="ta-stakeValueContainer"
                            >
                                {this.renderFreebetIcon(bet)}
                                {this.renderBonusIcon(bet)}
                                {this.renderStakeText(bet)}
                            </FlexPane>
                        </FlexPane>
                        <FlexPane config={this.config.returnsContainer} class="ta-returnsContainer">
                            {this.getReturns(bet)}
                        </FlexPane>
                    </FlexPane>
                </Button>
                {betIdx !== this.props.betHistory.length - 1 ? (
                    <FlexPaneSeparator direction={'column'} config={this.config.itemSeparator} />
                ) : null}
            </div>
        );
    }

    renderEmptyContent() {
        return (
            <div style={this.config.style.emptyContent}>
                <Text config={this.config.statusLabel} class="ta-noBetsFoundText">
                    {this.resolveString('$BET_HISTORY.NO_BETS_FOUND_LABEL')}
                </Text>
            </div>
        );
    }

    renderDataLoader() {
        return (
            <div style={this.config.style.emptyContent}>
                <Text config={this.config.statusLabel} class="ta-fetchingBetHistoryText">
                    {this.resolveString('$BET_HISTORY.FETCHING_BET_HISTORY')}
                </Text>
                <Spinner config={this.config.spinner} />
            </div>
        );
    }

    setDataLoaderDelay(delayBeforeShowingLoader, delayWhenShowingLoader) {
        this.timeoutBeforeShowingLoader = setTimeout(() => {
            this.setState({ showFetchingHistoryMessage: true });

            this.timeoutWhenShowingLoader = setTimeout(() => {
                this.setState({ showFetchingHistoryMessage: false });
            }, delayWhenShowingLoader);
        }, delayBeforeShowingLoader);
    }

    resetDataLoaderDelay() {
        this.timeoutBeforeShowingLoader && clearTimeout(this.timeoutBeforeShowingLoader);
        this.timeoutBeforeShowingLoader = null;
        this.timeoutWhenShowingLoader = null;
    }

    renderContent() {
        const { isFetchingBetHistory, error, betHistory } = this.props;
        const { showFetchingHistoryMessage } = this.state;

        // Reset delay when the bet history fetching finished
        if (!isFetchingBetHistory) {
            this.resetDataLoaderDelay();
        }

        // Set delay when the bet history fetching started and timeout has not been set
        if (isFetchingBetHistory && !this.timeoutBeforeShowingLoader) {
            this.setDataLoaderDelay(
                this.config.delayBeforeShowingLoader,
                this.config.delayWhenShowingLoader
            );
        }

        // Set a fetching history message flag that depends on delay or 'isFetchingBetHistory' flag if fetching data continue after delay
        const needToShowFetchingMessage =
            this.timeoutWhenShowingLoader && !showFetchingHistoryMessage
                ? isFetchingBetHistory
                : showFetchingHistoryMessage;

        // Don't show loader if there's more items to fetch.
        if (needToShowFetchingMessage && !this.hasMoreItems) {
            return (
                <>
                    {!this.config.showCompactLayout ? this.renderTableView(true) : null}
                    {this.renderDataLoader()}
                </>
            );
        } else if (this.config.showCompactLayout) {
            if (error) {
                return this.renderError();
            }
            return betHistory.length === 0 ? this.renderEmptyContent() : this.renderCompactView();
        }
        return this.renderTableView(false);
    }

    renderCompactView() {
        const scrollTargetId = 'betsListCompactContainer';
        const scrollableTarget = this.config.customScrollableTarget || scrollTargetId;
        const bets = this.props.betHistory.map(this.renderItem);

        return this.config.style.compactTableContainer.wrapInfiniteList ? (
            <div id={scrollTargetId} style={this.config.style.compactTableContainer}>
                <InfiniteList
                    hasMore={this.hasMore()}
                    fetchMore={this.onFetchMore}
                    scrollableTarget={scrollableTarget}
                >
                    {bets}
                </InfiniteList>
            </div>
        ) : (
            <InfiniteList hasMore={this.hasMore()} fetchMore={this.onFetchMore}>
                {bets}
            </InfiniteList>
        );
    }

    getBetStatusFilterItems(obj) {
        const filter = this.config.excludedStatusTypes.length
            ? omitBy(obj, value => this.config.excludedStatusTypes.includes(value))
            : obj;

        return Object.keys(filter).map(key => ({
            value: filter[key],
            label: this.resolveString(`$BET_HISTORY.TYPE_SELECTION.OPTIONS.${key}`),
        }));
    }

    checkBetsRangeFiltersConfigKeys(rangeFilter) {
        const rangeFilterKeys = Object.keys(rangeFilter);
        const expectedRangeFilterKeys = ['options', 'type'];

        const configHasRequiredKeys = expectedRangeFilterKeys.every(key =>
            rangeFilterKeys.includes(key)
        );

        if (!configHasRequiredKeys) {
            log.error(
                `Wrong betsRangeFilters config, saw "${rangeFilterKeys}" and expected "${expectedRangeFilterKeys}"`
            );
        }
    }

    checkBetsRangeFiltersOptionsKeys(options) {
        const optionsKeys = Object.keys(options);
        const expectedOptionsKeys = ['translationKey', 'value'];

        if (options.timeSpan) {
            expectedOptionsKeys.push('timeSpan');
            if (options.timeSpan !== 'today') {
                expectedOptionsKeys.push('duration');
            }
        }
        const configHasAllRequiredOptions = expectedOptionsKeys.every(key =>
            optionsKeys.includes(key)
        );

        if (!configHasAllRequiredOptions) {
            log.error(
                `Wrong betsRangeFilters options, saw "${optionsKeys}" and expected "${expectedOptionsKeys}"`
            );
        }
    }

    getBetsRangeFilterItems(config) {
        if (!config || !config.length) {
            log.error('No betsRangeFilters config detected');
        }

        return config
            .map(rangeFilter => {
                this.checkBetsRangeFiltersConfigKeys(rangeFilter);

                const { options } = rangeFilter;
                this.checkBetsRangeFiltersOptionsKeys(options);

                const label = this.resolveString(options.translationKey);

                return {
                    value: options.value,
                    label: this.isLastBetsAmountFilter(rangeFilter)
                        ? this.formatString(label, options.value)
                        : label,
                };
            })
            .filter(status => status.label && status.value);
    }

    getSelectedStatusFilter() {
        return this.props.selectedStatusFilter || this.config.defaultStatusFilter;
    }

    getSelectedRangeFilter() {
        return (
            this.props.selectedRangeFilter ||
            pick(this.config.defaultRangeFilter, 'options', 'type')
        );
    }

    onBetsRangeFilterChange(value) {
        const rangeFilter = this.config.betsRangeFilters.find(
            ({ options }) => options.value === value
        );
        const analyticsValue =
            rangeFilter.type === FILTER_CRITERIA_TYPE.TIME_FRAME ? value : `last-${value}-bets`;
        this.emitAnalytics('betHistoryRangeFilterChange', analyticsValue.replace(/-/g, ' '));
        this.fetchBets({ rangeFilter });
    }

    onStatusFilterChange(value) {
        this.emitAnalytics('betHistoryStatusChange', value.replace('_', ' '));
        this.fetchBets({ statusFilter: value });
    }

    renderSelectorHeader() {
        return (
            <div style={this.style.header}>
                <Dropdown
                    items={this.betStatusFilterItems}
                    triggerOverlayInteraction={false}
                    selectedItemValue={this.getSelectedStatusFilter()}
                    cbOnChange={this.onStatusFilterChange}
                    config={this.config.dropdown}
                    class="ta-statusFilter"
                />
                <div style={this.config.style.headerDivider} />
                <Dropdown
                    items={this.betsRangeFilterItems}
                    triggerOverlayInteraction={false}
                    selectedItemValue={this.getSelectedRangeFilter().options.value}
                    cbOnChange={this.onBetsRangeFilterChange}
                    config={this.config.dropdown}
                    class="ta-betsRangeFilters"
                />
            </div>
        );
    }

    isLastBetsAmountFilter(rangeFilter) {
        return rangeFilter.type === FILTER_CRITERIA_TYPE.LAST_BETS_AMOUNT;
    }

    hasMore() {
        return (
            this.props.pagination.hasMoreData &&
            !this.isLastBetsAmountFilter(this.getSelectedRangeFilter())
        );
    }

    onFetchMore() {
        const { page } = this.props.pagination;
        if (this.hasMore()) {
            this.fetchBets({ page: page + 1 });
        }
    }

    handleScroll(scrollInfo) {
        const { scrollPosition } = scrollInfo;

        if (scrollPosition > 0 && !this.state.isScrolled) {
            this.setState({ isScrolled: true });
        } else if (scrollPosition <= 0) {
            this.setState({ isScrolled: false });
        }
    }

    getNormalizedColName(colIdx) {
        return this.config.tableColNames[colIdx].toLowerCase();
    }

    getHeaderConfig() {
        if (this.state.isScrolled) {
            return this.style.headerContainerScrolled;
        }
        return this.config.headerItemsContainer;
    }

    getRowItemStyle(index, colIdx) {
        const { selectedIndex } = this.state;
        const colName = this.getNormalizedColName(colIdx);

        if (index === selectedIndex) {
            return this.style.selectedRowItem[colName];
        }
        return this.style.rowItem[colName];
    }

    renderTableView(renderEmptyTable) {
        const { error, betHistory } = this.props;

        if (error) {
            return this.renderError();
        }

        const selectedBet = this.getSelectedBet();
        const headerItems = this.config.tableColNames.map(attr =>
            this.resolveString(`$BET_HISTORY.TABLE_HEADER_ATTRIBUTES.${attr}`)
        );

        const tableContentStyle = selectedBet
            ? this.config.style.tableContentShrinked
            : this.config.style.tableContent;
        return (
            <FlexPane config={this.config.tableContainer}>
                <div style={tableContentStyle} className="ta-tableContainer">
                    <GridPane config={this.getHeaderConfig()}>
                        {headerItems.map((headerItem, colIdx) => (
                            <GridCell
                                key={headerItem}
                                config={this.style.headerItem[this.getNormalizedColName(colIdx)]}
                            >
                                {headerItem}
                            </GridCell>
                        ))}
                    </GridPane>
                    {!betHistory.length && !renderEmptyTable && !error
                        ? this.renderEmptyContent()
                        : this.renderTableContent(renderEmptyTable)}
                </div>
                {selectedBet ? (
                    <BetHistoryDetails
                        config={this.config.betHistoryDetailsController}
                        betId={selectedBet.id}
                        onCloseClick={this.onCloseDetailsClick}
                    />
                ) : null}
            </FlexPane>
        );
    }

    renderTableContent(renderEmptyTable) {
        if (renderEmptyTable) {
            return null;
        }

        const scrollPaneId = 'betsListContainer';
        const scrollableTarget = this.config.customScrollableTarget || scrollPaneId;

        return (
            <ScrollPane
                config={this.config.betsListScrollPane}
                onScroll={this.handleScroll}
                id={scrollPaneId}
            >
                <InfiniteList
                    config={this.config.betsList}
                    pendingStateComponent={
                        <div style={this.config.style.spinnerContainer}>
                            <Spinner config={this.config.spinner} />
                        </div>
                    }
                    hasMore={this.hasMore()}
                    fetchMore={this.onFetchMore}
                    scrollableTarget={scrollableTarget}
                >
                    {this.props.betHistory.map((bet, betIdx) => {
                        const betCells = Object.entries(this.mapBetTableData(bet));
                        return (
                            <GridSeparator
                                key={bet.id}
                                config={this.config.gridSeparator}
                                startColumn={1}
                                endColumn={betCells.length}
                            >
                                {betCells.map((betCell, colIdx) =>
                                    this.renderCell(betCell, betIdx, bet.id, colIdx)
                                )}
                            </GridSeparator>
                        );
                    })}
                </InfiniteList>
            </ScrollPane>
        );
    }

    renderCell(betCell, betIdx, betId, colIdx) {
        const [cellName, cellData] = betCell;

        return (
            <TableCell
                key={`${betId}-${cellName}`}
                cellData={cellData}
                rowItemStyle={this.getRowItemStyle(betIdx, colIdx)}
                onTableItemClick={this.onTableItemClick}
                betIdx={betIdx}
            />
        );
    }

    renderError() {
        return (
            <FlexPane config={this.config.errorContainer}>
                <Text config={this.config.error}>
                    {this.resolveString('$BET_HISTORY.FETCH_BETS_ERROR')}
                </Text>
                {this.renderTryAgainButton()}
            </FlexPane>
        );
    }

    renderTryAgainButton() {
        if (this.config.tryAgainButtonVisibility.hide) {
            return null;
        }

        return (
            <Button config={this.config.tryAgainButton} onClick={this.fetchBets}>
                {this.resolveString('$BET_HISTORY.TRY_AGAIN')}
            </Button>
        );
    }

    render() {
        return (
            <div className="ta-BetHistoryView">
                {this.renderSelectorHeader()}
                {this.renderContent()}
            </div>
        );
    }
}

const TableCell = props => {
    const { cellData, rowItemStyle, onTableItemClick, betIdx } = props;
    return (
        <GridCell config={rowItemStyle} onClick={() => onTableItemClick(betIdx)}>
            {cellData}
        </GridCell>
    );
};

TableCell.displayName = 'TableCell';

BetHistoryView.getStyle = function (config, applicationMode, merge) {
    const mergeItemConfigWithBase = (itemConfig, base) => {
        const mergedItemConfig = {};

        config.tableColNames.forEach(colName => {
            const normalizedColName = colName.toLowerCase();
            mergedItemConfig[normalizedColName] = merge(base, itemConfig[normalizedColName]);
        });

        return mergedItemConfig;
    };

    const headerItem = mergeItemConfigWithBase(config.headerItem, config.headerItem.base);
    const rowItem = mergeItemConfigWithBase(config.rowItem, config.rowItem.base);

    const selectedRowItemBase = merge(config.rowItem.base, config.selectedRowItem.base);
    const selectedRowItem = mergeItemConfigWithBase(config.rowItem, selectedRowItemBase);

    return {
        headerContainerScrolled: merge(config.headerItemsContainer, config.headerContainerScrolled),
        headerItem,
        rowItem,
        selectedRowItem,
        header: merge(config.style.header, {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '100%',
        }),
        itemRow: merge(config.style.itemRow, {
            display: 'flex',
            justifyContent: 'space-between',
        }),
    };
};
