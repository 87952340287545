import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
const reduxInstance = MojitoCore.Services.redux;

const PHONE_TYPE = {
    WINDOWS: 'Windows Phone',
    ANDROID: 'Android',
};

/**
 * App banner store selectors.
 *
 * @class AppBannerSelectors
 * @name selectors
 * @memberof Mojito.Services.AppBanner
 */

/**
 * Selects app banner state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} App banner state.
 * @memberof Mojito.Services.AppBanner.selectors
 */
export const selectState = state => {
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Check if the banner should be hidden. If not Android it should always be hidden.
 *
 * @function shouldHideBanner
 *
 * @param {object} [state] - Application state object.
 * @returns {boolean} True if banner should be hidden.
 * @memberof Mojito.Services.AppBanner.selectors
 */
export const shouldHideBanner = state => {
    const { defaultHideNumber, bannerClosed, bannerClickCount } = selectState(state);
    const closedMaximumNumberOfTimes = bannerClickCount === defaultHideNumber;
    const shouldHide = closedMaximumNumberOfTimes || bannerClosed;

    let device;
    const userAgent = navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        device = PHONE_TYPE.WINDOWS;
    } else if (/android/i.test(userAgent)) {
        device = PHONE_TYPE.ANDROID;
    }

    const isAndroid = device === PHONE_TYPE.ANDROID;
    return isAndroid ? shouldHide : true;
};

/**
 * Select banner clicks count state.
 *
 * @function selectBannerClickCount
 *
 * @param {object} [state] - Application state object.
 * @returns {number} A number of clicks.
 * @memberof Mojito.Services.AppBanner.selectors
 */
export const selectBannerClickCount = state => {
    return selectState(state).bannerClickCount;
};
