import PropTypes from 'prop-types';
import DownloadBetPdfButtonImpl from './download-bet-pdf-button.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * Download bet pdf button component. Displays a configurable button which downloads the bets provided as props as a pdf.
 *
 * @class DownloadBetPdfButton
 * @memberof Mojito.Presentation.Components
 */

/**
 * Download bet pdf button prop types.
 *
 * @property {boolean} isFetching - True if a pdf is being fetched, otherwise false.
 * @property {Array<Mojito.Services.Bets.types.Bet>} bets - Bets which should be printed.
 * @property {Function} [onClick = ()=>{}] -  Additional click handler when download button is clicked, pdf is still printed if no onClick callback is provided.
 *
 * @memberof Mojito.Presentation.Components.DownloadBetPdfButton
 */
const propTypes = {
    isFetching: PropTypes.bool.isRequired,
    bets: PropTypes.array.isRequired,
    onClick: PropTypes.func,
};

const defaultProps = {
    onClick: noop,
};

export default MojitoCore.Presentation.UIView(
    'DownloadBetPdfButton',
    DownloadBetPdfButtonImpl,
    propTypes,
    defaultProps
);
