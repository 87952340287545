/**
 * Singleton object that provide access to sports content notifications service.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */
class ServiceFactory {
    /**
     * Init factory.
     *
     * @function init
     * @param {Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService} service - Service implementation.
     * @memberof Mojito.Services.SportsContent.ContentNotifications.serviceFactory
     */
    init(service) {
        this.service = service;
    }

    /**
     * Service getter.
     *
     * @function getService
     * @returns {Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService} ContentNotifications service instance.
     * @memberof Mojito.Services.SportsContent.ContentNotifications.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
