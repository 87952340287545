import { memo, useState, useEffect, useRef } from 'react';
import { isFunction, isEmpty } from 'mojito/utils';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import { splitIntoGroups } from 'modules/market/helper/market-helper.js';
import SelectionButton from 'modules/selection-button/index.jsx';
import MarketTypes from './types.js';

const { DIRECTION } = MarketTypes;

const { Text, FlexPane } = MojitoPresentation.Components;
const { utils: EventsUtils } = MojitoServices.SportsContent.Events;

const SELECTION_TEXT = {
    FITS: 'fits',
    OVERFLOWS: 'overflows',
    UNKNOWN: 'unknown',
};

const Market = memo(function Market(props) {
    const { market, config, mojitoTools, headerl10nKey, selectionTypes, onDirectionChange } = props;
    const { stringResolver, style } = mojitoTools;
    const header = stringResolver.resolveString(headerl10nKey);
    const [selectionTextState, setSelectionTextState] = useState(SELECTION_TEXT.UNKNOWN);
    const selectionOverflowsRef = useRef({});
    const isAutoLayout = config.direction === DIRECTION.AUTO;

    const { showUnavailablePlaceholder } = config;
    const marketDirection = resolveMarketDirection(selectionTextState, config);
    const showUnavailable = !market && isEmpty(selectionTypes) && showUnavailablePlaceholder;
    const isSuspended = market?.status === EventsUtils.STATUS.SUSPENDED;

    const onOverflowText = (overflows, selectionId) => {
        const overflowsDefined = selectionOverflowsRef.current[selectionId] !== undefined;
        if (isSuspended || overflowsDefined) {
            return;
        }
        selectionOverflowsRef.current[selectionId] = overflows;
        const selectionOverflows = Object.values(selectionOverflowsRef.current).some(Boolean);
        const newState = selectionOverflows ? SELECTION_TEXT.OVERFLOWS : SELECTION_TEXT.FITS;
        setSelectionTextState(newState);
    };

    useEffect(
        () => {
            if (market?.id) {
                onDirectionChange(marketDirection, market?.id);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [marketDirection]
    );

    const containersStyle = marketDirection ? style.directions[marketDirection] : config;
    // For auto layout we will hide market until marketDirection is resolved to avoid jumping effect on market expand.
    // Otherwise, market will render selections in a row for a few milliseconds and then switch to a column once onOverflowText will be triggered by selection component.
    const shouldHideContent = isAutoLayout && !marketDirection && !isSuspended;
    const containerConfig = shouldHideContent ? style.hiddenContainer : containersStyle.container;

    return (
        <FlexPane
            class={`ta-Market ta-MarketType-${market?.type} ta-MarketId-${market?.id}`}
            config={containerConfig}
        >
            {!isEmpty(header) && renderHeader({ ...props, header })}
            {showUnavailable ? (
                <Unavailable config={config} stringResolver={stringResolver} />
            ) : (
                renderSelectionGroups({
                    ...props,
                    market,
                    selectionTypes,
                    containersStyle,
                    onOverflowText,
                })
            )}
        </FlexPane>
    );
});

const renderHeader = props => {
    const { config, header, hideHeader } = props;
    return (
        <FlexPane config={config.headerContainer}>
            <Text class="ta-MarketHeader" config={config.headerLabel}>
                {!hideHeader ? header : ''}
            </Text>
        </FlexPane>
    );
};

const renderSelectionGroups = props => {
    const {
        market,
        event,
        selectionTypes,
        containersStyle,
        selectionGroups: marketSelectionGroups,
        selectionGroupsResolver,
        config,
    } = props;

    const resolveSelectionGroups = () => {
        return isFunction(selectionGroupsResolver)
            ? selectionGroupsResolver(
                  market,
                  selectionTypes,
                  !event.homeAway,
                  config.defaultNumberOfSelectionGroups,
                  true
              )
            : splitIntoGroups(market.selections, 1);
    };

    const selectionGroups = marketSelectionGroups?.length
        ? marketSelectionGroups
        : resolveSelectionGroups();
    const hasComplexGroups = selectionGroups.some(group => group.length > 1);
    return hasComplexGroups
        ? selectionGroups.map(selections => {
              const selectionIds = selections.map(sel => sel.id).join('-');
              return (
                  <FlexPane
                      class="ta-SelectionsGroup"
                      key={selectionIds}
                      config={config.selectionsGroupContainer}
                  >
                      {renderSelections({
                          ...props,
                          selections,
                          containersStyle,
                      })}
                  </FlexPane>
              );
          })
        : renderSelections({
              ...props,
              selections: selectionGroups.flat(),
              containersStyle,
          });
};

const Unavailable = ({ config, stringResolver }) => {
    return (
        <FlexPane class="ta-unavailable" config={config.unavailableMarketContainer}>
            <Text config={config.unavailableMarketText}>
                {stringResolver.resolveString('$GENERIC.UNAVAILABLE')}
            </Text>
        </FlexPane>
    );
};

const renderSelections = ({
    selections,
    market = {},
    event,
    nameDisplay,
    onOverflowText,
    containersStyle,
}) => {
    return selections.map(selection => {
        return (
            <SelectionButton
                key={selection.id}
                selection={selection}
                event={event}
                marketStatus={market.status}
                marketType={market.type}
                marketId={market.id}
                config={containersStyle.selectionButton}
                nameDisplay={nameDisplay}
                onOverflowText={onOverflowText}
            />
        );
    });
};

const resolveMarketDirection = (selectionTextState, config) => {
    if (config.direction !== DIRECTION.AUTO) {
        return config.direction;
    }
    const directionsToState = {
        [SELECTION_TEXT.FITS]: DIRECTION.ROW,
        [SELECTION_TEXT.OVERFLOWS]: DIRECTION.COLUMN,
        [SELECTION_TEXT.UNKNOWN]: undefined,
    };
    return directionsToState[selectionTextState];
};

Market.getStyle = (config, mode, merge) => {
    const { container, selectionButton, selectionLayoutOverrides = {} } = config;
    const applyDirection = flexDirection => {
        const selectionLayoutDirection = selectionLayoutOverrides[flexDirection];
        const selectionButtonExt = selectionLayoutDirection
            ? { view: { layoutDirection: selectionLayoutDirection } }
            : {};

        return {
            container: merge(container, { style: { flexDirection } }),
            selectionButton: merge(selectionButton, selectionButtonExt),
        };
    };
    return {
        directions: {
            [DIRECTION.ROW]: applyDirection('row'),
            [DIRECTION.COLUMN]: applyDirection('column'),
        },
        hiddenContainer: merge(container, { style: { opacity: 0 } }),
    };
};

export default Market;
