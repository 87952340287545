import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import serviceFactory from './service/service-factory';

const reduxInstance = MojitoCore.Services.redux;
export const FAKE_EVENT_ID = -1;

/**
 * Notification event store.
 * Holds mapping notifications ID to event ID.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.NotificationEvent
 */
export const STORE_KEY = 'notificationEventStore';

export const INITIAL_STATE = {
    notificationsIdToEventId: {},
};

export const { reducer, actions } = createSlice({
    name: 'notificationEvent',
    initialState: INITIAL_STATE,
    reducers: {
        getEventIdSuccess(state, { payload }) {
            const { notificationsId, eventId } = payload;
            // Not found also success
            state.notificationsIdToEventId[notificationsId] = eventId || FAKE_EVENT_ID;
        },
        getEventIdFailed(state, { payload }) {
            const { notificationsId } = payload;
            // For simplicity, use fake event ID to let see externally that request is complete.
            state.notificationsIdToEventId[notificationsId] = FAKE_EVENT_ID;
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Notification event related actions.
 *
 * @module NotificationEventActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.NotificationEvent
 */

/**
 * Init notification event service layer.
 *
 * @function init
 *
 * @param {object} config - Notification event config.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Init thunk.
 * @memberof Mojito.Services.SportsContent.NotificationEvent.actions
 */
actions.init = config => () => {
    serviceFactory.init(config);
};

/**
 * Get event ID.
 *
 * @param {string} notificationsId - Notifications ID.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Get event id thunk.
 * @function Mojito.Services.SportsContent.NotificationEvent.actions
 */
actions.getEventId = notificationsId => {
    return dispatch => {
        serviceFactory
            .getService()
            .getEventId(notificationsId)
            .then(data =>
                dispatch(
                    actions.getEventIdSuccess({
                        notificationsId,
                        eventId: data?.eventId,
                    })
                )
            )
            .catch(() => dispatch(actions.getEventIdFailed(notificationsId)));
    };
};

/**
 * Get event id success action.
 *
 * @function getEventIdSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.SportsContent.NotificationEvent.actions.EventIdInfo} eventIdInfo - Event id info object.
 *
 * @memberof Mojito.Services.SportsContent.NotificationEvent.actions
 */

/**
 * Event id info.
 *
 * @typedef EventIdInfo
 * @type {object}
 * @property {string} notificationsId - Notifications id.
 * @property {string} eventId - Event id.
 *
 * @memberof Mojito.Services.SportsContent.NotificationEvent.actions
 */

/**
 * Get event id failed action.
 *
 * @function getEventIdFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} notificationsId - Notifications id.
 *
 * @memberof Mojito.Services.SportsContent.NotificationEvent.actions
 */

/**
 * Reset action returns notifications event state to initial value.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.NotificationEvent.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
