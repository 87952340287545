import MojitoCore from 'mojito/core';
import ServicesTypes from './types.js';

const { APPLICATION_MODE } = MojitoCore.Services.SystemSettings.types;
const { CHANNEL, CONTENT_STATE } = ServicesTypes;

const APP_MODE_TO_CHANNEL = {
    [APPLICATION_MODE.MOBILE]: CHANNEL.MOBILE,
    [APPLICATION_MODE.DESKTOP]: CHANNEL.DESKTOP,
};

/**
 * Utility functions associated with the services layer.
 *
 * @class ServicesUtils
 * @name utils
 * @memberof Mojito.Services.Common
 */
class ServicesUtils {
    /**
     * Resolves communication channel by specific application mode.
     * Typically used within Mojito API calls.
     *
     * @param {Mojito.Core.Services.SystemSettings.type.APPLICATION_MODE} mode - Application mode.
     *
     * @returns {Mojito.Services.Common.types.CHANNEL} Channel.
     * @function Mojito.Services.Common.utils.resolveChannel
     */
    static resolveChannel(mode) {
        return APP_MODE_TO_CHANNEL[mode];
    }

    /**
     * Creates slice pending handler for particular state property.
     *
     * @param {string} stateProp - The name of the state property to assign pending item state.
     *
     * @returns {Function} Slice reducer function accepts state as first arg and instance of {@link Mojito.Core.Services.redux.Action|redux action} as a second arg.
     * @function Mojito.Services.Common.utils.createPendingHandler
     */
    static createPendingHandler(stateProp) {
        return (state, { payload: itemIds }) => {
            itemIds &&
                itemIds.forEach(itemId => (state[stateProp][itemId] = CONTENT_STATE.PENDING));
        };
    }

    /**
     * Checks if content loading has been finalized.
     * It is considered to be finalized if loadingState is either {@link Mojito.Services.Common.types.CONTENT_STATE.AVAILABLE|AVAILABLE} or {@link Mojito.Services.Common.types.CONTENT_STATE.UNAVAILABLE|UNAVAILABLE}.
     *
     * @param {Mojito.Services.Common.types.CONTENT_STATE} loadingState - Loading state of content.
     *
     * @returns {boolean} True if loading is done.
     * @function Mojito.Services.Common.utils.isContentLoadDone
     */
    static isContentLoadDone(loadingState) {
        return (
            loadingState === CONTENT_STATE.AVAILABLE || loadingState === CONTENT_STATE.UNAVAILABLE
        );
    }
}

export default ServicesUtils;
