import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import GameLineMarketContentImpl from './game-line-market-content.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Module that displays market lines content for {@link Mojito.Services.SportsContent.MarketGroups.types.AGGREGATED_MARKET_TYPE.GAME_LINE|GAME_LINE}
 * aggregated market type.
 *
 * @class GameLineMarketContent
 * @memberof Mojito.Modules.AggregatedMarketPane
 */

/**
 * GameLineMarketContent prop types.
 *
 * @property {Mojito.Services.SportsContent.Events.types.AggregatedMarket} [aggregatedMarket] - Aggregated market object.
 * @property {object} eventItem - Event item object.
 * @property {Function} [onEventClick = ()=>{}] - Callback called when we click at the event participants.
 * @property {string} [hrefLink = ''] - A string to be set in the `hrefLink` prop of the `<Button>` component (for SEO purpose only).
 *
 * @memberof Mojito.Modules.AggregatedMarketPane.GameLineMarketContent
 */
const propTypes = {
    aggregatedMarket: PropTypes.object,
    eventItem: PropTypes.object.isRequired,
    onEventClick: PropTypes.func,
    hrefLink: PropTypes.string,
};

export default UIView('GameLineMarketContent', GameLineMarketContentImpl, propTypes);
