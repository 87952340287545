import MojitoNGen from 'mojito/ngen';
import { isPlainObject } from 'mojito/utils';

const log = MojitoNGen.logger.get();
const CLASSNAME_REGEXP = /[^A-Za-z0-9-_]/g;
const REGIONAL_LANGUAGE_DIVIDER = '-';

/**
 * The StringUtils class provides various utility functions for manipulating strings.
 *
 * @class StringUtils
 * @memberof Mojito.Core.Base
 */
export default class StringUtils {
    /**
     * Translates and formats a provided string key using specified variable arguments.
     *
     * @param {string} strKey - The l10n string containing one or more variable parts.
     * @param {object} l10n - Translation map.
     * @param {*} args - Variable number of arguments based on string specification.
     *
     * @returns {string} Translated and formatted string.
     * @function Mojito.Core.Base.StringUtils.resolveAndFormatString
     */
    static resolveAndFormatString(strKey, l10n, ...args) {
        const str = StringUtils.resolveString(strKey, l10n);
        return StringUtils.formatString(str, ...args);
    }

    /**
     * Formats the specified l10n string using the provided variable arguments.
     * <br>
     * The l10n string can contain placeholders identified as %1, %2, etc.
     * <br>
     * Each placeholder will be replaced with the corresponding specified argument.
     * <br>
     * If a placeholder doesn't have a corresponding argument, it will remain unchanged.
     * <br>
     * It's also possible to use a plain object as the second argument for more complex string templates.
     * <br>
     * For example, an l10n string like 'The quick brown %firstAnimal% jumps over the lazy %secondAnimal%.'
     * could use an object like {firstAnimal: 'fox', secondAnimal: 'dog'} for `args`.
     * <br><br>
     * Note: The actual arguments passed depend on the l10n string, and these should be documented with the string specification.
     *
     * @param {string} str - The l10n string containing one or more placeholders.
     * @param {*} args - Arguments for string formatting, matching the placeholders in the string.
     *
     * @returns {string|undefined} FThe l10n string formatted with the provided arguments, or undefined if `str` is falsy.
     * @function Mojito.Core.Base.StringUtils.formatString
     */
    static formatString(str, ...args) {
        // Sanity check
        if (!str) {
            return;
        }

        let res = str;
        if (args.length === 1 && isPlainObject(args[0])) {
            const argument = args[0];
            Object.keys(argument).forEach(key => {
                res = res.replace(`%${key}%`, argument[key] !== undefined ? argument[key] : '');
            });
        } else {
            for (let idx = 0; idx < args.length; idx++) {
                // All replacement args start at 1
                // ie: res.replace('%1', args[0])
                // note: the '' empty string fallback is required to prevent the replacement with 'undefined'.
                res = res.replace(`%${idx + 1}`, args[idx] !== undefined ? args[idx] : '');
            }
        }
        return res;
    }

    /**
     * Identifies if the given string is of the string key format or not.
     * <br>
     * A string key always start with the '$' character.
     *
     * @param {string} str - String key or a "Normal string".
     *
     * @returns {boolean} True if the given string is a string key, otherwise false.
     * @function Mojito.Core.Base.StringUtils.hasStringKeyFormat
     */
    static hasStringKeyFormat(str) {
        if (!str || str.length === 0) {
            return false;
        }

        return str.startsWith('$');
    }

    /**
     * Translates a string key into its corresponding string using the provided localization map (l10n).
     * <br>
     * The key should be a fully-qualified, dot-separated path referring to a property inside the "i18n_strings" object,
     * and should be prefixed with a '$' symbol.
     * <br>
     * Returns an empty string if the key is missing, improperly formatted, or doesn't exist in the l10n object.
     *
     * @param {string} strKey - The string key for translation.
     * @param {object} l10n - Translation map.
     * @param {boolean} suppressWarnings - Set true to prevent logging of warnings for missing or improperly formatted keys.
     *
     * @returns {string} The translated string corresponding to the provided key, or an empty string if translation could not be performed.
     * @function Mojito.Core.Base.StringUtils.resolveString
     */
    static resolveString(strKey, l10n, suppressWarnings = false) {
        // Sanity checks
        if (!strKey || strKey.length === 0 || !l10n) {
            return '';
        }

        if (!StringUtils.hasStringKeyFormat(strKey)) {
            if (!suppressWarnings) {
                log.warn(
                    'Specified string key is not valid: ',
                    strKey,
                    ', as it does not have the prefix "$"'
                );
                log.warn(
                    'StringUtils.resolveString() should only be used to resolve translated strings from string keys!'
                );
            }

            return '';
        }

        // Strip leading $
        const keys = strKey.substring(1).split('.');

        // Navigate into the l10n structure
        const value = keys.reduce((obj, key) => {
            return obj && obj[key];
        }, l10n);

        // Need to check if value is an empty string since placeholder translations can be empty strings
        if (!value && value !== '') {
            suppressWarnings || log.warn(`No translation found for: ${strKey}`);
            return '';
        }

        return value;
    }

    /**
     * Resolve last tuple of label name.
     *
     * @param {*} strKey - Label name.
     *
     * @returns {string} Label name prefix.
     * @function Mojito.Core.Base.StringUtils.deriveLabelNamePrefix
     */
    static deriveLabelNamePrefix(strKey) {
        return strKey.split('.').pop();
    }

    /**
     * Generates a valid CSS class name by concatenating provided arguments with a '-' separator.
     *
     * @param {*} args - Variable number of string arguments to be included in the class name.
     *
     * @returns {string} A valid CSS class name.
     * @function Mojito.Core.Base.StringUtils.makeCssClassName
     */
    static makeCssClassName(...args) {
        return args.map(arg => arg.replace(CLASSNAME_REGEXP, '')).join('-');
    }

    /**
     * Sanitize HTML string.
     *
     * @param {string} dirty - HTML string that should be sanitized.
     *
     * @returns {string|undefined} Sanitized HTML string or undefined if error occurred.
     * @function Mojito.Core.Base.StringUtils.sanitizeHtml
     */
    static async sanitizeHtml(dirty) {
        if (!dirty) {
            return;
        }
        try {
            // Using dynamic import to split the library code out of a main bundle.
            const { default: domPurify } = await import(
                /* webpackChunkName: "dompurify" */ 'dompurify'
            );
            return domPurify.sanitize(dirty, { USE_PROFILES: { html: true } });
        } catch (err) {
            log.error('Failed to sanitize HTML string.', err);
        }
    }

    /**
     * Test if it's a regional language.
     *
     * @param {string} language - Language to test of.
     *
     * @returns {boolean} True if passed language includes country code, otherwise false.
     * @function Mojito.Core.Base.StringUtils.isRegionalLanguage
     */
    static isRegionalLanguage(language) {
        if (!language || language.length === 0) {
            return false;
        }

        return language.includes(REGIONAL_LANGUAGE_DIVIDER);
    }

    /**
     * Extract language code from provided language.
     *
     * @param {string} language - Language.
     *
     * @returns {string} Language code from provided language.
     * @function Mojito.Core.Base.StringUtils.getLanguageCode
     */
    static getLanguageCode(language) {
        return language.split(REGIONAL_LANGUAGE_DIVIDER)[0];
    }
}
