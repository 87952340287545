import { connect } from 'react-redux';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import AccaBarView from './view/index.jsx';
import { actions as accaBarActions } from 'modules/acca-bar/store/slice.js';
import { isAllowedToShow } from 'modules/acca-bar/store/selectors.js';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const {
    utils: BetslipUtils,
    selectors: { selectBetslip },
} = MojitoServices.Betslip;
const { types: BetsTypes } = MojitoServices.Bets;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const intentActions = MojitoCore.Intents.actions;

class AccaBarController extends UIViewImplementation {
    constructor(reactComponent) {
        super(reactComponent);

        this.state = {
            isVisible: this.allowedToShowAcca(),
        };

        this.onBarClick = this.onBarClick.bind(this);
        this.hideAccaBar = this.hideAccaBar.bind(this);
        this.allowedToShowAcca = this.allowedToShowAcca.bind(this);

        this.countDownTime = this.config.displayTime * 1000;
        this.timer = 0;
    }

    componentDidUpdate(prevProps) {
        if (this.getBetId(prevProps.bet) !== this.getBetId(this.props.bet)) {
            const isVisible = this.allowedToShowAcca();
            this.setState({ isVisible }, () => isVisible && this.startTimer());
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    allowedToShowAcca() {
        const { isAllowedToShow, bet } = this.props;
        return isAllowedToShow && !!bet;
    }

    startTimer() {
        this.clearTimer();
        this.timer = setTimeout(() => {
            this.setState({ isVisible: false });
        }, this.countDownTime);
    }

    clearTimer() {
        clearTimeout(this.timer);
    }

    getBetId(bet) {
        return (bet && bet.id) || 0;
    }

    hideAccaBar() {
        const { dispatch } = this.props;
        dispatch(accaBarActions.showAccaBar(false));
    }

    shouldShowAccaBar() {
        return this.allowedToShowAcca() && this.state.isVisible;
    }

    onBarClick() {
        const { dispatch } = this.props;

        dispatch(intentActions.publishIntent(IntentTypes.SHOW_SLIDING_BETSLIP));
    }

    render() {
        if (!this.shouldShowAccaBar()) {
            return null;
        }
        return (
            <AccaBarView
                bet={this.props.bet}
                isVisible={this.shouldShowAccaBar()}
                onBarClick={this.onBarClick}
                hideAccaBar={this.hideAccaBar}
            />
        );
    }
}

const getBetToShow = betslip => {
    return (
        BetslipUtils.getMultiBet(betslip) ||
        BetslipUtils.getSingleBets(
            betslip,
            legSort => legSort === BetsTypes.LEG_SORT.BET_BUILDER
        )[0]
    );
};

const mapStateToProps = state => {
    const betslip = selectBetslip(state);
    return {
        bet: getBetToShow(betslip),
        isAllowedToShow: isAllowedToShow(state),
    };
};

export default connect(mapStateToProps)(AccaBarController);
