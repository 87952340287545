import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';

const {
    actions: betslipActions,
    selectors: { selectSelectionState },
} = MojitoServices.Betslip;
const { dispatch } = MojitoCore.Services.redux.store;

/**
 * Context object defines actions to add and remove selections to the betslip
 * and hook function to get current state of added selection from betslip store.
 *
 * @typedef BetslipSelectionsContext
 * @name betslipSelectionsContext
 * @type {Mojito.Modules.SelectionButton.Context.SelectionsContextDefinition}
 * @memberof Mojito.Modules.Betslip
 */
export default {
    addSelection: payload => dispatch(betslipActions.addSelection(payload)),
    removeSelection: payload => dispatch(betslipActions.removeSelection(payload)),
    selectionStateSelector: (state, selectionId) => selectSelectionState(selectionId, state),
};
