import PropTypes from 'prop-types';
import MatchAccaSelectionItemImpl from './match-acca-selection-item.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Match acca selection info for leg in a single bet.
 *
 * @class MatchAccaSelectionItem
 * @memberof Mojito.Presentation.Modules
 */

/**
 * MatchAccaSelectionItem component prop types.
 *
 * @property {string} selectionLabel - Selection label to be shown.
 * @property {string} marketName - Market name to be shown.
 * @property {boolean} showSeparator - If true a separator will be shown under the icon.
 * @property {string} [iconStatus] - Status for bet, to show correct status icon.
 * @property {boolean} [isSuspended=false] - Will render suspended label if true.
 * @property {boolean} [isEarlyPayout=false] - Will render Early Payout indicator if true.
 *
 * @memberof Mojito.Presentation.Modules.MatchAccaSelectionItem
 */
const propTypes = {
    selectionLabel: PropTypes.string.isRequired,
    marketName: PropTypes.string.isRequired,
    showSeparator: PropTypes.bool.isRequired,
    iconStatus: PropTypes.string,
    isSuspended: PropTypes.bool,
    isEarlyPayout: PropTypes.bool,
};

const defaultProps = {
    isSuspended: false,
    isEarlyPayout: false,
};

export default UIView(
    'MatchAccaSelectionItem',
    MatchAccaSelectionItemImpl,
    propTypes,
    defaultProps
);
