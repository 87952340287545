import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import ServicesTypes from 'services/common/types.js';
import { createSelector } from '@reduxjs/toolkit';
import { pick } from 'mojito/utils';

const reduxInstance = MojitoCore.Services.redux;
const { UNKNOWN } = ServicesTypes.CONTENT_STATE;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

/**
 * Market groups store selectors.
 *
 * @class MarketGroupSelectors
 * @name selectors
 * @memberof Mojito.Services.SportsContent.MarketGroup
 */

/**
 * Selects market groups state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.SportsContent.MarketGroup.MarketGroupsState} The state of the market groups store.
 * @memberof Mojito.Services.SportsContent.MarketGroup.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects market groups.
 *
 * @function MarketGroupsSelector
 *
 * @param {Array<string>} groupIds - The list of market group ids.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Map<string, object>} Market groups map where key is group id and value is group info object.
 * @memberof Mojito.Services.SportsContent.MarketGroup.selectors
 */

/**
 * Selector creator function. Will create memoized selector of type {@link Mojito.Services.SportsContent.MarketGroup.selectors.MarketGroupsSelector|MarketGroupsSelector} to fetch market groups by group ids.
 * The created selector guarantees that the same object instance will be returned on each call if no input params changed.
 * Note: when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance.
 *
 * @function makeSelectMarketGroups
 *
 * @returns {Mojito.Services.SportsContent.MarketGroup.selectors.MarketGroupsSelector} Selector function.
 * @memberof Mojito.Services.SportsContent.MarketGroup.selectors
 */
export const makeSelectMarketGroups = () =>
    createSelector(
        [(groupIds, state) => selectState(state).groups, groupIds => groupIds],
        (groups, groupIds) => pick(groups, groupIds),
        {
            memoizeOptions: { resultEqualityCheck: isShallowEqual },
        }
    );

/**
 * Selects market group.
 *
 * @function selectMarketGroup
 *
 * @param {string} groupId - The id of market group.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Market group info object.
 * @memberof Mojito.Services.SportsContent.MarketGroup.selectors
 */
export const selectMarketGroup = (groupId, state) => selectState(state).groups[groupId];

/**
 * Selects market group state.
 *
 * @function selectMarketGroupState
 *
 * @param {string} groupId - The id of market group.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Lifetime state of specific market group.
 * @memberof Mojito.Services.SportsContent.MarketGroup.selectors
 */
export const selectMarketGroupState = (groupId, state) =>
    selectState(state).groupsState[groupId] || UNKNOWN;
