import { useMemo, useState, useEffect } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import MojitoModules from 'mojito/modules';
import MojitoCore from 'mojito/core';

const ContentPreloader = MojitoCore.Services.Content.ContentPreloader;
const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;
const EventsCoupon = MojitoModules.EventsCoupon;
const ContentPreloaderContext = MojitoCore.Presentation.ContentPreloaderContext;
const analyticsMatchesPath = 'Matches';

export default function MatchesCoupon({ mojitoTools }) {
    const { config, emitPerformanceMark } = mojitoTools;
    const { sportId, filter } = useParams();
    const { eventGroupsId, isInPlayCoupon } = useOutletContext();
    const contentPreloader = useMemo(() => new ContentPreloader(), []);
    const matchesContext = useMemo(
        () =>
            new AnalyticsContextBuilder()
                .withAnalyticsPath(`${analyticsMatchesPath}_${filter}`)
                .build(),
        [filter]
    );

    // Determining child modules loading state
    const [loadedEventGroupId, setLoadedEventGroupId] = useState();

    useEffect(() => {
        const isDataLoad = eventGroupsId && eventGroupsId === loadedEventGroupId;
        emitPerformanceMark('moduleRendered', isDataLoad);
    }, [emitPerformanceMark, loadedEventGroupId, eventGroupsId]);

    const onDataLoad = eventGroupId => {
        setLoadedEventGroupId(eventGroupId);
    };

    return eventGroupsId ? (
        <AnalyticsContextExtender value={matchesContext}>
            <ContentPreloaderContext.Provider value={contentPreloader}>
                <EventsCoupon
                    isInPlay={isInPlayCoupon}
                    sportId={sportId}
                    eventGroupsId={eventGroupsId}
                    config={config.eventsCoupon}
                    onDataLoad={onDataLoad}
                />
            </ContentPreloaderContext.Provider>
        </AnalyticsContextExtender>
    ) : null;
}
