import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('SearchServiceFactory');

const voidResponder = () => {
    log.warn('searchService instance is missing.');
    return Promise.reject();
};

const NULL_SERVICE = {
    search: voidResponder,
};

/**
 * Singleton object that receives search config and spawn service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.Search
 */
class ServiceFactory {
    constructor() {
        this.service = NULL_SERVICE;
    }

    /**
     * Init factory.
     *
     * @function init
     *
     * @param {Mojito.Services.Search.types.SearchConfig} [config = {}] - Search data retriever config.
     *
     * @memberof Mojito.Services.Search.serviceFactory
     */
    init(config = {}) {
        this.service = config.service || NULL_SERVICE;
    }

    /**
     * Service getter.
     *
     * @function getService
     *
     * @returns {Mojito.Services.Search.AbstractSearchService} Search service instance.
     *
     * @memberof Mojito.Services.Search.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
