import {PALETTE_IDS} from '#core/application/modules/ids.js';
import {COLOR, FONT} from '../theme.js';

export function getRacingConfig() {
    return {
        custom: {
            [PALETTE_IDS.FUTURE_RACING]: {
                FutureRaceCard: {
                    eventName: {
                        container: {
                            paddingBottom: '8px',
                        },
                    },
                },
            },
        },
        palette: {
            [PALETTE_IDS.RACE_MEETINGS]: {
                RACE_MEETINGS_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    textTransform: 'uppercase',
                },
                RACE_MEETINGS_RACE_BUTTON: {
                    TOGGLE_BUTTON_TEXT: COLOR.BRAND.CTA1,
                },
                RACE_MEETINGS_LIVE_RACE_ICON_COLOR: COLOR.BRAND.ACCENT1,
                RACE_MEETINGS_STATUS_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                RACE_MEETING_BEST_ODDS_GUARANTEED_INDICATOR: {
                    BEST_ODDS_GUARANTEED_INDICATOR_LABEL_TEXT: {
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                        color: COLOR.BRAND.FOREGROUND1,
                    },
                },
            },
            [PALETTE_IDS.RACE_CARD]: {
                RACE_CARD_INFO_RACE_NUMBER_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND3,
                },
                RACE_CARD_INFO_DISTANCE_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                RACE_CARD_DESCRIPTION_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                RACE_CARD_RUNNERS_TABLE: {
                    TABLE_HEADER_SORT_BUTTON_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.BRAND.CTA1,
                    },
                    TABLE_HEADER_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                },
                RACE_CARD_RUNNERS_TABLE_RUNNER_INFO: {
                    RUNNER_CONTAINER_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        fontSize: FONT.SIZE_SMALL,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                    RUNNER_TRAINER_AND_JOCKEY_NAMES_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        fontSize: FONT.SIZE_XSMALL,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                },
                RACE_CARD_BEST_ODDS_GUARANTEED_INDICATOR: {
                    BEST_ODDS_GUARANTEED_INDICATOR_LABEL_TEXT: {
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                        color: COLOR.BRAND.FOREGROUND1,
                    },
                },
            },
            [PALETTE_IDS.FUTURE_RACING]: {
                FUTURE_RACE_CARD_EVENT_NAME_CONTAINER_BG: COLOR.BRAND.BACKGROUND3,
                FUTURE_RACE_CARD_DATE_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                FUTURE_RACE_CARD_EW_TERMS_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    fontSize: FONT.SIZE_SMALL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
            },
        },
    };
}
