/**
 * Object containing URL related types.
 *
 * @readonly
 * @enum {object}
 *
 * @memberof Mojito.Core.Base
 */
export default {
    PARAMETER: {
        LANG: 'lang',
        ADDITIONAL_CONTEXT: 'additionalContext',
        TA_MODE: 'taMode',
    },
};
