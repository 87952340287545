import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Messaging store selectors.
 *
 * @class MessagingSelectors
 * @name selectors
 * @memberof Mojito.Services.Messaging
 */

/**
 * Selects Messaging state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} The state of the Messaging store.
 * @memberof Mojito.Services.Messaging.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects dialog messages list that are relevant for the user.
 *
 * @function selectDialogMessages
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array} Array of messages of type {@link Mojito.Services.Messaging.types.Message|Message}.
 *
 * @memberof Mojito.Services.Messaging.selectors
 */
export const selectDialogMessages = state => selectState(state).dialogMessages;

/**
 * Selects alert messages list that are relevant for the user.
 *
 * @function selectAlertMessages
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array} Array of messages of type {@link Mojito.Services.Messaging.types.Message|Message}.
 *
 * @memberof Mojito.Services.Messaging.selectors
 */
export const selectAlertMessages = state => selectState(state).alertMessages;

/**
 * Selects toast messages list that are relevant for the user.
 *
 * @function selectToastMessages
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array} Array of messages of type {@link Mojito.Services.Messaging.types.Message|Message}.
 *
 * @memberof Mojito.Services.Messaging.selectors
 */
export const selectToastMessages = state => selectState(state).toastMessages;

/**
 * Selects template urls list.
 *
 * @function selectUrlTemplates
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array} Array of messages of type {@link Mojito.Services.Messaging.types.Message|Message}.
 *
 * @memberof Mojito.Services.Messaging.selectors
 */
export const selectUrlTemplates = state => selectState(state).urlTemplates;

/**
 * Selects the `url` property from a template found by the provided type.
 * If more than one template of the provided type is found, the templates are sorted by priority and the `url` from the highest-priority template is returned.
 *
 * @param {string} type - The type of the URL template.
 * @param {object} [state] - Optional. The Redux state object. If not provided, the application state from  {@link Mojito.Core.Services.redux#getStore | the global store} is used.
 * @returns {string|undefined} Returns the URL template string if found, else `undefined`.
 *
 * @memberof Mojito.Services.Messaging.selectors
 *
 * @function selectUrlByTemplateType
 */
export const selectUrlByTemplateType = (type, state) => {
    const templates = selectState(state).urlTemplates.filter(template => template.urlType === type);
    templates.sort((tempA, tempB) => tempA.priority - tempB.priority);
    return templates[0]?.url;
};
