import MojitoCore from 'mojito/core';
import AbstractTask from 'services/common/task-runner/abstract-task.js';
import { actions } from 'services/bets/open-bets/open-bets-slice.js';
import BetsTypes from 'services/bets/types.js';

const { dispatch } = MojitoCore.Services.redux.store;
/**
 * PollOpenBetCountTask.
 *
 * Retrieves number of open bets and handles result with proper action call.
 *
 * @class PollOpenBetCountTask
 * @memberof Mojito.Services.Bets.Task
 */
export default class PollOpenBetCountTask extends AbstractTask {
    constructor(service, maxBetAge) {
        super();
        this.betsService = service;
        this.maxBetAge = maxBetAge;
    }

    getFilterCriteria() {
        const dateSince = new Date();
        dateSince.setFullYear(dateSince.getFullYear() - this.maxBetAge);
        return {
            filters: [BetsTypes.BET_STATUS.OPEN],
            from: dateSince.toISOString(),
        };
    }

    execute() {
        this.betsService
            .getOpenBetCount({ filterCriteria: this.getFilterCriteria() })
            .then(data => dispatch(actions.fetchOpenBetCountSuccess(data)))
            .catch(error => dispatch(actions.fetchOpenBetCountFailed(error)));
    }
}
