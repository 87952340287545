/**
 * Class defining container item data descriptors to be used in {@link Mojito.Services.SportsContent.ContainerItems.actions|ContainerItemsActions}.
 *
 * @class ContainerItemsDescriptor
 * @name descriptor
 * @memberof Mojito.Services.SportsContent.ContainerItems
 */
export default class ContainerItemsDescriptor {
    /**
     * Factory method for creating container item's list data descriptor.
     *
     * @param {string} listId - The id of the item within container collection.
     *
     * @returns {{dataType: string, listId: string}} Container items data descriptor.
     *
     * @function Mojito.Services.SportsContent.ContainerItems.descriptor#create
     * @static
     */
    static create(listId) {
        return {
            dataType: ContainerItemsDescriptor.DATA_TYPE,
            listId,
        };
    }
}

/**
 * System unique data descriptor type.
 *
 * @typedef DATA_TYPE
 * @property {string} DATA_TYPE - Container items data descriptor type.
 * @memberof Mojito.Services.SportsContent.ContainerItems.descriptor
 */
ContainerItemsDescriptor.DATA_TYPE = 'container-items';
