/**
 * @class MessagingTypes
 * @name types
 * @memberof Mojito.Services.Messaging
 */
export default {
    /**
     * Supported incoming messages types.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Services.Messaging.types
     */
    INCOMING_MESSAGES: {
        /** Message type with simple user interaction flow. Typically just informative message with `OK` button. */
        SHOW_SIMPLE_ALERT: 'show-simple-alert',
        /** Message type informing about session limit expiration. Typically, has options to logout or continue the session. */
        SHOW_SESSION_LIMIT_ALERT: 'show-session-limit-alert',
        /** Message type informing about user last login details. Typically just informative message with `OK` button. */
        SHOW_LAST_LOGIN_ALERT: 'show-last-login-alert',
        /** Message type with simple user interaction flow.
         * Typically presented as toast popup. */
        SHOW_SIMPLE_TOAST: 'show-simple-toast',
        /** Message type with simple user interaction flow.
         * Typically, a reminder for too many selections for the betslip. */
        SHOW_SELECTIONS_LIMIT_TOAST: 'show-selections-limit-toast',
        /** Message type with simple user interaction flow.
         * Toast type used by gamification notifications. */
        SHOW_GAMIFICATION_TOAST: 'show-gamification-toast',
        /** Message type with advanced user interaction flow.
         * Typically, message that promotes user to perform some actions like
         * `Redirect to casino game`, `Accept bonus`, `Start bonus challenge` etc. */
        SHOW_DIALOG: 'show-dialog',
        /** Message type signalizing that message should be hidden.
         * Typically, triggered if user interacted with the same message in other integrated system, e.g., Casino, bingo or any other game. */
        HIDE_MESSAGE: 'hide-message',
        /** Message type signalising that url templates has been received. This templates typically used for external redirects. */
        RECEIVE_URL_TEMPLATES: 'receive-url-templates',
    },

    /**
     * Supported outgoing messages types.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Services.Messaging.types
     */
    OUTGOING_MESSAGES: {
        /** Message type indicating that user did interaction with dialog. */
        SUBMIT_DIALOG_ACTION: 'submit-dialog-action',
        /** Message type indicating that user did interaction with alert. */
        SUBMIT_ALERT_ACTION: 'submit-alert-action',
    },

    /**
     * Supported message button types.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Services.Messaging.types
     */
    BUTTON_TYPES: {
        CLOSE: 'close',
        CANCEL: 'cancel',
        OK: 'ok',
        /** Actionable button used to perform advanced user action. */
        ACTIONABLE: 'actionable',
    },
};

/**
 * The Message object type used for both incoming and outgoing messages.
 *
 * @typedef Message
 * @type {object}
 * @property {string} id - Unique identifier of the message.
 * @property {string} type - Type of the message can be either {@link Mojito.Services.Messaging.types.INCOMING_MESSAGES|INCOMING_MESSAGES} or {@link Mojito.Services.Messaging.types.INCOMING_MESSAGES|OUTGOING_MESSAGES} enum value.
 * @property {Mojito.Services.Messaging.types.DialogMessagePayload|
 * Mojito.Services.Messaging.types.AlertMessagePayload|
 * Mojito.Services.Messaging.types.SimpleToastPayload|
 * Mojito.Services.Messaging.types.GamificationToastPayload} payload - Payload of the message containing useful information for the user.
 * @memberof Mojito.Services.Messaging.types
 */

/**
 * The dialog message payload object that is present in SHOW_DIALOG and SUBMIT_DIALOG_ACTION message types.
 *
 * @typedef DialogMessagePayload
 * @type {object}
 * @property {string} text - Message text.
 * @property {string} bgImageUrl - The URL to the image that will be used as background.
 * @property {Array} buttons - The list of buttons that user can interact with of type Mojito.Services.Messaging.types.MessageButton.
 * @memberof Mojito.Services.Messaging.types
 */

/**
 * The AlertMessagePayload type represents the payload object contained within the SHOW_SIMPLE_ALERT message type.
 *
 * @typedef AlertMessagePayload
 * @type {object}
 * @property {string} text - The text of the message.
 * @property {Array} [buttons] - An optional array of buttons, of type Mojito.Services.Messaging.types.MessageButton, that the user can interact with.
 * @property {object} [additionalInfo] - An optional object containing extra information related to the message, such as specific data to be used when displaying the message.
 * @memberof Mojito.Services.Messaging.types
 */

/**
 * The toast message payload object that is present in SHOW_SIMPLE_TOAST message type.
 *
 * @typedef SimpleToastPayload
 * @type {object}
 * @property {string} title - The title of the toast.
 * @property {string} text - Toast message body.
 * @property {string} icon - Toast icon src.
 * @memberof Mojito.Services.Messaging.types
 */

/**
 * The toast message payload object that is present in SHOW_GAMIFICATION_TOAST message type.
 *
 * @typedef GamificationToastPayload
 * @type {object}
 * @property {string} title - The title of the toast.
 * @property {string} text - Toast message body.
 * @property {string} icon - Toast icon src.
 * @property {Mojito.Services.Gamification.types.BADGE_TYPES} badgeType - The type of the gamification badge.
 * @property {string} badgeId - Action data object contains action meta info.
 * @memberof Mojito.Services.Messaging.types
 */

/**
 * The session limit message payload object that is present in SHOW_SESSION_LIMIT_ALERT message type.
 *
 * @typedef SessionLimitMessagePayload
 * @type {object}
 * @property {string} text - Message text.
 * @property {Array} buttons - The list of buttons that user can interact with of type Mojito.Services.Messaging.types.MessageButton.
 * @memberof Mojito.Services.Messaging.types
 */

/**
 * The submit dialog message payload object that is present in SUBMIT_DIALOG_ACTION message type.
 *
 * @typedef SubmitDialogMessagePayload
 * @type {object}
 * @property {string} actionId - Submitted action id.
 * @memberof Mojito.Services.Messaging.types
 */

/**
 * The MessageButton type represents a user-interactive button.
 *
 * @typedef MessageButton
 * @type {object}
 * @property {string} id - The identifier of the button.
 * @property {Mojito.Services.Messaging.types.BUTTON_TYPES} type - The type of the button.
 * @property {string} label - The text displayed on the button surface.
 * @property {string} action - Defines the application's behavior upon a button click, for example, `switchtogame`, `launchcashier`, `openurls`, etc.
 * @property {*} actionData - An object containing additional metadata for the action.
 * @memberof Mojito.Services.Messaging.types
 */
