import performanceTypes from 'core/services/performance/types.js';
import serviceFactory from 'core/services/performance/service/service-factory';

/**
 * WS listener types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Core.Services.Performance.WsStatusListener.types
 */
const WS_LISTENER_TYPES = {
    READY: 'READY',
    CLOSED: 'CLOSED',
    CONNECTED: 'CONNECTED',
    CONNECTING: 'CONNECTING',
    SUBSCRIBE: 'SUBSCRIBE',
    DISCONNECTED: 'DISCONNECTED',
};

const { WS_STATUS, WS_SUBSCRIPTION } = performanceTypes.TIMELINE_RECORD_TYPES;
const { CONNECTING, CONNECTED, READY, CLOSED, DISCONNECTED } =
    performanceTypes.WEBSOCKET_STATUS_TYPES;

/**
 * Class responsible for reporting websocket status changes to performance service.
 *
 * @class WsStatusListener
 * @memberof Mojito.Core.Services.Performance
 */
class WsStatusListener {
    /**
     * Initializes the data from websocket to start listening.
     *
     * @param {{ socketEnabler: Mojito.NGen.SocketEnablerClient }} config - The configuration object for the websocket data.
     *
     * @function Mojito.Core.Services.Performance.WsStatusListener#init
     */
    init(config) {
        this.socketEnabler = config.socketEnabler;
        this.startListening();
    }

    startListening() {
        this.disposeListener = this.socketEnabler.subscribeToConnectionEvent(data => {
            const service = serviceFactory.getService();
            const { type, payload } = data;

            switch (type) {
                case WS_LISTENER_TYPES.CONNECTING:
                    service.report(WS_STATUS, { status: CONNECTING });
                    break;
                case WS_LISTENER_TYPES.CONNECTED:
                    service.report(WS_STATUS, { status: CONNECTED });
                    break;
                case WS_LISTENER_TYPES.READY:
                    service.report(WS_STATUS, { status: READY });
                    break;
                case WS_LISTENER_TYPES.CLOSED: {
                    const closedPayload = {};
                    if (payload?.event) {
                        closedPayload.code = payload.event.code;
                        closedPayload.wasClean = payload.event.wasClean;
                        closedPayload.reason = payload.event.reason;
                    }
                    service.report(WS_STATUS, { status: CLOSED, payload: closedPayload });
                    break;
                }
                case WS_LISTENER_TYPES.SUBSCRIBE:
                    service.report(WS_STATUS, { type: WS_SUBSCRIPTION, payload });
                    break;
                case WS_LISTENER_TYPES.DISCONNECTED:
                    service.report(WS_STATUS, { type: DISCONNECTED, payload });
                    break;
            }
        });
    }

    /**
     * Disposes websocket listener.
     *
     * @function Mojito.Core.Services.Performance.WsStatusListener#dispose
     */
    dispose() {
        this.disposeListener && this.disposeListener.dispose();
    }
}

export default new WsStatusListener();
