let channel = null;

if (process.env.NODE_ENV === 'development' && window.BroadcastChannel) {
    // Allow broadcast channel only in debug mode - due to security reasons
    channel = new BroadcastChannel('SPORTSBOOK.DO_UPDATE_CONFIG');
    channel.onmessage = event => {
        const {config} = event.data || {};

        storeConfig(config);
        location.reload();
    };
}

export function storeConfig(config) {
    const {sprites, ...appConfig} = config;
    const SESSION_STORAGE_CONFIG_KEY = 'dbx.app-config';
    const SESSION_STORAGE_CUSTOM_ICONS_KEY = 'dbx.app-custom-icons';

    sessionStorage.setItem(SESSION_STORAGE_CONFIG_KEY, JSON.stringify(appConfig, null, 2));
    if (sprites) sessionStorage.setItem(SESSION_STORAGE_CUSTOM_ICONS_KEY, JSON.stringify(sprites, null, 2));
}

export function broadcastConfig(config) {
    channel && channel.postMessage({config});
}
