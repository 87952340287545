import PropTypes from 'prop-types';
import ButtonImpl from './button.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

/**
 * A general-purpose button component. This should be used for any clickable UI element that isn't part of a more specialized component.
 *
 * @class Button
 * @memberof Mojito.Presentation.Components
 */

/**
 * `Button` component prop types.
 *
 * @property {boolean} [disabled = false] - Flag controlling the button's disabled state. Disabled buttons are unclickable and styled differently.
 * @property {boolean} [active] - Flag controlling the button's active state. Active buttons have unique styling. If not set, the active state is internally handled based on user interactions.
 * @property {boolean} [hidden = false] - Flag controlling the button's visibility. A hidden button is not visible, but still rendered in order to not affect the layout.
 * @property {string} [hrefLink] - A string for the `href` attribute of the `<a>` link element. Used for SEO purposes. If not provided or if `config.enableAHref` is unset, a `<div>` element substitutes the `<a>`.
 * @property {Function} [onActiveChange = ()=>{}] - On active state change. The callback will receive true or false based on active state of the button.
 * @property {Function} [onClick = ()=>{}] - Click callback. The callback args are the onClick event and onClickData.
 * @property {*} [onClickData] - Data of unspecified type passed to the onClick callback.
 * @property {object | Function} [buttonElementRef] - Ref to the rendered element.
 * @property {string} [class] - A string of class names attached to the button's root element.
 *
 * @memberof Mojito.Presentation.Components.Button
 */
export const propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    hidden: PropTypes.bool,
    hrefLink: PropTypes.string,
    onActiveChange: PropTypes.func,
    onClick: PropTypes.func,
    onClickData: PropTypes.any,
    buttonElementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    class: PropTypes.string,
};

export const defaultProps = {
    disabled: false,
    hidden: false,
    onActiveChange: noop,
    onClick: noop,
};

export default MojitoCore.Presentation.UIView('Button', ButtonImpl, propTypes, defaultProps);
