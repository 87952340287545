import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import InProgressBonusesImpl from './in-progress-bonuses.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component responsible for presenting the view of active bonuses.
 *
 * @name View
 * @class InProgressBonuses
 * @memberof Mojito.Modules.InProgressBonuses
 */

/**
 * `InProgressBonuses` prop types.
 *
 * @property {Mojito.Services.Bonus.types.InProgressBonus[]} inProgressBonuses - An array of in progress bonuses.
 * @property {string} [currencyCode] - User currency code (e.g., EUR, GBP, SEK, etc).
 *
 * @memberof Mojito.Modules.InProgressBonuses.View
 */
const propTypes = {
    inProgressBonuses: PropTypes.array.isRequired,
    currencyCode: PropTypes.string.isRequired,
};

export default UIView('InProgressBonuses', InProgressBonusesImpl, propTypes);
