import MojitoPresentation from 'mojito/presentation';

const { ImageButton } = MojitoPresentation.Components;

const ShowAllSelectionsButton = ({ mojitoTools, minimized, onMinimizeChange }) => {
    const {
        config: { showAllButton, hideAllButton },
        stringResolver,
    } = mojitoTools;

    return (
        <ImageButton
            config={minimized ? showAllButton : hideAllButton}
            label={stringResolver.resolveString(
                `$SHOW_ALL_SELECTIONS_BUTTON.${minimized ? 'SHOW' : 'HIDE'}`
            )}
            onClick={onMinimizeChange}
        />
    );
};

export default ShowAllSelectionsButton;
