import { useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import RequestDataHelper from 'core/services/request-data-helper';
import AppContext, { NULL_APP_CONTEXT } from 'core/presentation/app-context';
import AnalyticsContext, { NULL_ANALYTICS_CONTEXT } from 'core/presentation/analytics-context';
import IdGenerator from 'core/base/idgenerator';
import UIViewUtils from 'core/presentation/ui-view/utils';
import cssRuleManager from 'core/presentation/css-rule-manager';
import StringResolver from 'core/base/string-utils/string-resolver.js';
import CacheKeyGenerator from 'core/base/cache/cache-key-generator.js';
import { memoize, pick } from 'mojito/utils';

const stylesCacheKeyGenerator = new CacheKeyGenerator();

const getMemoizedStyle = memoize(
    UIViewUtils.getStyle,
    (config, getStyleHook, applicationMode, name) => {
        const cachePath = applicationMode ? `${name}${applicationMode}` : name;
        return stylesCacheKeyGenerator.generate(cachePath, [config]);
    }
);

/**
 * Hook returning the current context value which is of type {@link Mojito.Core.Presentation.AppContext.Context|AppContext}.
 *
 * @function useAppContext
 * @returns {AppContext} The current application context.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useAppContext = () => {
    return useContext(AppContext) || NULL_APP_CONTEXT;
};

/**
 * Hook returning the current context value which is of type {@link Mojito.Core.Presentation.AnalyticsContext.Context|AnalyticsContext}.
 *
 * @function useAnalyticsContext
 * @returns {AnalyticsContext} The current analytics context.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useAnalyticsContext = () => {
    return useContext(AnalyticsContext) || NULL_ANALYTICS_CONTEXT;
};

/**
 * Hook performs component implementation lookup, returns default implementation
 * if overrides were not found in config.
 *
 * @function useImplementation
 * @param {string} name - Name of the look-up component.
 * @param {string} contextPath - Current context used to look for component implementation.
 *
 * @returns {Function} Implementation class or function.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useImplementation = (name, contextPath) => {
    return useMemo(() => UIViewUtils.getImplementation(name, contextPath), [name, contextPath]);
};

/**
 * Hook extending the given context object with the provided value using an extending strategy.
 *
 * @function useContextExtender
 * @param {object} context - The context to be extended.
 * @param {object} value - The value used to extend the context.
 * @param {Function} extendingStrategy - Function that gets called with context and value passed in on render effect.
 *
 * @returns {object} The extended context object.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useContextExtender = (context, value, extendingStrategy) => {
    const [extendedContext, setExtendedContext] = useState(() => extendingStrategy(context, value));

    useEffect(() => {
        setExtendedContext(extendingStrategy(context, value));
    }, [context, value, extendingStrategy]);

    return extendedContext;
};

/**
 * Hook returning component configuration related to its name and contextPath, after merging with the parent config (if provided).
 *
 * @function useConfig
 * @param {string} name - The name of the component.
 * @param {Mojito.Core.Services.Config.ConfigObject} parentConfig - The config passed by the parent through props.
 * @param {string} contextPath - The context to look for the component config.
 *
 * @returns {Mojito.Core.Services.Config.ConfigObject|undefined} The context-sensitive ConfigObject or undefined if no config available.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useConfig = (name, parentConfig, contextPath) => {
    const config = useMemo(
        () => (name ? UIViewUtils.getConfig(name, contextPath, parentConfig) : undefined),
        [name, contextPath, parentConfig]
    );
    return config;
};

/**
 * Hook returning a reference to a function, used inside React components, that passes a performance mark to the performance emitter.
 *
 * @function useEmitPerformanceMark
 * @returns {Function} Function to report performance mark. Accepts 3 params: type, isReady, payload. Mark will be passed to performance emitter once isReady flag become true.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useEmitPerformanceMark = () => {
    const { pathname, performanceEmitter } = useAppContext();
    const prevPathname = useRef(pathname);
    const calledRef = useRef({});

    if (prevPathname.current !== pathname) {
        prevPathname.current = pathname;
        calledRef.current = {};
    }

    return useCallback(
        (type, isReady, payload) => {
            if (!calledRef.current[type] && isReady && performanceEmitter) {
                performanceEmitter.emitPerformanceMetric(type, payload);
                calledRef.current[type] = true;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pathname, performanceEmitter]
    );
};

/**
 * Hook returning an object that contains the specified part (or parts) of the provided config.
 *
 * @function useSubConfig
 * @param {Mojito.Core.Services.Config.ConfigObject} config - The config from which the subconfig needs to be extracted.
 * @param {*} props - Variable number of props to select parts of the config and build a subconfig.
 *
 * @returns {object} Subconfig object containing the defined props from the provided config.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useSubConfig = (config, ...props) => {
    return useMemo(() => pick(config, props), [config, props]);
};

/**
 * Hook using getStyleHook function to return a component style, typically defined as a static
 * getStyle function in the component module. The returned style object will have vendor prefixes.
 * The function uses memoization to prevent redundant style evaluations.
 *
 * @param {Mojito.Core.Services.Config.ConfigObject} config - Config of the component, which will be passed to the getStyleHook function.
 * @param {Function} getStyleHook - Function used to build style object, typically defined in the component module.
 * @param {string} applicationMode - Will be passed to the getStyleHook function.
 * @param {string} name - Component name.
 *
 * @returns {object} Style object ready for use by the component.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useStyle = (config, getStyleHook, applicationMode, name) => {
    return useMemo(
        () =>
            getStyleHook
                ? getMemoizedStyle(config, getStyleHook, applicationMode, name)
                : undefined,
        [config, applicationMode, getStyleHook, name]
    );
};

/**
 * Hook returning localised object properties based on provided language code.
 *
 * @param {string} language - Language code used to find corresponding localisation object.
 *
 * @returns {object} L10n object with localised property values.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useTranslations = language => {
    return useMemo(() => UIViewUtils.getTranslations(language), [language]);
};

/**
 * Hook generating a unique ID for a component instance.
 *
 * @returns {string} Unique ID for the component instance.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useInstanceId = () => {
    const [instanceId] = useState(IdGenerator.generateId());
    return `${instanceId}`;
};

/**
 * Hook for add on mount and dispose on unmount css rules per component by class id.
 *
 * @param {string} classId - CSS selector class id. Typically, unique per component class/type.
 * @param {object} variables - Css variables.
 * @param {Array<string>} ruleTemplates - List of templates.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useCssRule = (classId, variables, ruleTemplates) => {
    const instanceId = useInstanceId();

    useEffect(() => {
        const cssRules = cssRuleManager.buildRules(variables, ruleTemplates);
        cssRuleManager.addRules(instanceId, classId, cssRules);
    }, [instanceId, classId, variables, ruleTemplates]);

    useEffect(() => {
        return () => {
            cssRuleManager.removeRules(instanceId, classId);
        };
    }, [instanceId, classId]);
};

/**
 * Hook creating an instance of StringResolver class using provided `l10n` object.
 *
 * @param {object} l10n - Object containing localised translations.
 *
 * @returns {StringResolver} Instance of `Mojito.Core.Base.StringResolver` class used for resolving strings.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useStringResolver = l10n => {
    const [stringResolver] = useState(() => new StringResolver(l10n));
    return stringResolver;
};

/**
 * Hook creating an instance of `Mojito.Core.Services.RequestDataHelper`.
 * Typically, for usage in controller components for data fetching.
 *
 * @param {string} [instanceId] - The id of the client component.
 *
 * @returns {RequestDataHelper} Instance of `Mojito.Core.Services.RequestDataHelper` class.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useControllerHelper = instanceId => {
    const defaultInstanceId = useInstanceId();
    const [helper] = useState(() => new RequestDataHelper(instanceId || defaultInstanceId));
    useEffect(() => {
        return () => {
            helper.unsubscribeAll();
        };
    }, [helper]);
    return helper;
};

/**
 * Hook performing a validation of props against the provided propTypes, firing warnings
 * if no `propTypes` are provided, or if there are props not defined in `propTypes`.
 * Also warns about any attempt of trying to use 'mojitoTools' prop as this is a reserved property by Mojito UIView HoC.
 * Validation is performed only on first render.
 *
 * @param {object} props - Properties that are to be validated.
 * @param {object} propTypes - Object defining the expected props and their types.
 * @param {string} displayName - The name of the component, used for logging purposes.
 *
 * @memberof Mojito.Core.Presentation.Hooks
 */
const usePropTypesValidation = (props, propTypes, displayName) => {
    // The hook is designed to check props only on initialization (first render) phase,
    // hence no need to pass any `deps` in here
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        UIViewUtils.verifyPropTypes(props, propTypes, displayName);
        UIViewUtils.validateReservedProps(props, displayName, 'mojitoTools');
    }, []);
};

/**
 * Hook returning a variety of useful tools, including application context (`appContext`), component config (`config`),
 * localisation object (`l10n`), component styles (`style`), performance emitter function, and more, which are beneficial
 * for modern React development in Mojito.
 *
 * @param {string} name - The name of the component.
 * @param {Mojito.Core.Services.Config.ConfigObject} parentConfig - Configuration object provided by the parent.
 * @param {Function} implementation - Implementation of the component module reference, used to determine the `getStyle` hook.
 * @param {Mojito.Core.Presentation.AppContext.AppContextDefinition} appContext - The application context in which the component operates.
 * @param {Function} emitPerformanceMark - Function for reporting performance metrics.
 *
 * @returns {object} Object with useful tools: appContext, config, l10n, style, stringResolver.
 * @memberof Mojito.Core.Presentation.Hooks
 */
const useComponentTools = (name, parentConfig, implementation, appContext, emitPerformanceMark) => {
    const systemSettings = appContext.systemSettings();
    const language = systemSettings && systemSettings.language;
    const applicationMode = systemSettings && systemSettings.applicationMode;

    const instanceId = useInstanceId();
    const l10n = useTranslations(language);
    const componentModule = UIViewUtils.isLazy(implementation)
        ? UIViewUtils.resolveModuleFromLazy(implementation, name)
        : implementation;
    // componentModule can be undefined for lazy implementation in case chunk is still pending
    const configName = componentModule ? name : undefined;
    const config = useConfig(configName, parentConfig, appContext.uiContextPath());
    const style = useStyle(config, componentModule?.getStyle, applicationMode, name);
    const stringResolver = useStringResolver(l10n);
    return useMemo(
        () => ({
            appContext,
            config,
            l10n,
            style,
            stringResolver,
            instanceId,
            emitPerformanceMark,
        }),
        [appContext, config, l10n, style, stringResolver, instanceId, emitPerformanceMark]
    );
};

export default {
    useAppContext,
    useAnalyticsContext,
    useContextExtender,
    useStringResolver,
    useConfig,
    useSubConfig,
    useControllerHelper,
    useImplementation,
    useInstanceId,
    useStyle,
    useTranslations,
    usePropTypesValidation,
    useComponentTools,
    useEmitPerformanceMark,
    useCssRule,
};
