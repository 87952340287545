import AbstractHeadMetaFactory from './abstract-head-meta-factory.js';
import MojitoCore from 'mojito/core';

const { StringUtils } = MojitoCore.Base;

/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 * Default implementation of HeadMetaFactory.
 * <br>
 * Ideal for creating generic titles, keywords, and descriptions that can be utilized by `Mojito.Presentation.Components.DocumentHead`.
 * <br>
 * Titles, Keywords and Descriptions can take two forms:
 * <ol>
 * <li>'String Key' like '$INPLAY_PAGE.PAGE_TITLE'. Can be resolved using l10n provided in the constructor.</li>
 * <li>'Plain string', for example, 'My title'.</li>
 * </ol>
 *
 * Note: If using only plain strings, there's no need to provide an l10n object to the constructor.
 * <>br
 * If localized params are given, the string will be formatted.
 * <br>
 * For example, with input string: 'Sport: %1 and eventName: %2', and LocalizedParams: {sportName: 'football', eventName: 'Malmö FF - Dynamo Kyiv'},
 * <br>
 * Output would be: 'Sport: football and eventName: Malmö FF - Dynamo Kyiv'.
 * <br>
 * If more dynamic behavior is desired, you might need to override {@link Mojito.Presentation.Components.AbstractHeadMetaFactory} and connect it to the specific page/view.
 *
 * @class DefaultHeadMetaFactory
 * @extends Mojito.Presentation.Components.AbstractHeadMetaFactory
 * @memberof Mojito.Presentation.Components
 */
/* eslint-enable jsdoc/require-description-complete-sentence */
export default class DefaultHeadMetaFactory extends AbstractHeadMetaFactory {
    constructor(l10n) {
        super();
        this.l10n = l10n;
    }

    getTitle(data) {
        return _stringFormatHelper(data.title, this.l10n, data.localizedParams);
    }

    getKeywords(data) {
        return _stringFormatHelper(data.keywords, this.l10n, data.localizedParams);
    }

    getDescription(data) {
        return _stringFormatHelper(data.description, this.l10n, data.localizedParams);
    }

    getCanonical(data) {
        return _stringFormatHelper(data.canonical, this.l10n, data.localizedParams);
    }
}

function _stringFormatHelper(string, l10n, localizedParams) {
    // If no l10n object exists OR the string is not in a stringKey-format given we assume that this is a "normal" string, no resolving needed.
    const resolvedString =
        l10n && StringUtils.hasStringKeyFormat(string)
            ? StringUtils.resolveString(string, l10n)
            : string;

    // If params exists - replace all %X's with the given params
    return localizedParams
        ? StringUtils.formatString(resolvedString, ...localizedParams.values())
        : resolvedString;
}
