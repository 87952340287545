import PropTypes from 'prop-types';
import EventDisclaimerImpl from './event-disclaimer.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * This component displays event disclaimer content.
 * The content must be overridden by the sportsbook implementation based on the customer's requirements.
 * The displayForSports configuration can be used to filter display based on sportIds and/or in-play state.
 *
 * @class EventDisclaimer
 * @memberof Mojito.Presentation.Components
 */

/**
 * `EventDisclaimer` component prop types.
 *
 * @property {string} [sportId] - The sport id associated with the event.
 * @property {boolean} [isInPlay] - The in-play state of the event.
 * @property {boolean} [isVirtual] - A property to distinguish virtual page disclaimers from others.
 *
 * @memberof Mojito.Presentation.Components.EventDisclaimer
 */
const propTypes = {
    sportId: PropTypes.string,
    isInPlay: PropTypes.bool,
    isVirtual: PropTypes.bool,
};

export default UIView('EventDisclaimer', EventDisclaimerImpl, propTypes);
