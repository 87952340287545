import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import FreeBetCodeImpl from './free-bet-code.jsx';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component that contains an input field and a button used for adding free bet codes.
 *
 * @class FreeBetCode
 * @memberof Mojito.Modules
 */

/**
 * PromotionCode prop types.
 *
 * @property {Function} [onSubmitFreeBetCode = () => {}] - Callback function for providing the free bet code string.
 *
 * @memberof Mojito.Modules.FreeBetCode
 */
const propTypes = {
    onSubmitFreeBetCode: PropTypes.func,
};

const defaultProps = {
    onSubmitFreeBetCode: noop,
};

export default UIView('FreeBetCode', FreeBetCodeImpl, propTypes, defaultProps);
