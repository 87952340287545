import StringUtils from 'core/base/string-utils/index.js';

/**
 * StringResolver constructor.
 *
 * @class StringResolver
 * @param {object} l10n - Translations object.
 * @classdesc StringResolver is delegate that wraps `Mojito.Core.Base.StringUtils` functionality using l10n object provided in constructor.
 * @memberof Mojito.Core.Base
 */
export default class StringResolver {
    constructor(l10n) {
        this.l10n = l10n;
    }

    /**
     * Resolves and formats specified string key into translated string.
     *
     * @param {string} str - The l10n string containing one or more variable parts.
     * @param  {*} args - Variable number of arguments based on string specification.
     *
     * @returns {string} Translated string.
     * @function Mojito.Core.Base.StringResolver#resolveAndFormatString
     */
    resolveAndFormatString(str, ...args) {
        return StringUtils.resolveAndFormatString(str, this.l10n, ...args);
    }

    /**
     * Replaces placeholder tokens (e.g., %1, %2) in a localization string with provided arguments.
     * The types of `args` required correspond to the localization string and should be documented with it.
     * The unprovided placeholders in the l10n string, if any, will remain the same.
     *
     * @example // returns "Hello, John"
     * Mojito.Core.Base.StringResolver.formatString('Hello, %1', 'John');
     *
     *
     * @param {string} str - The l10n string with one or more placeholder tokens.
     * @param  {*} args - The arguments to replace the placeholder tokens.
     *
     * @returns {string} The formatted l10n string.
     *
     * @function Mojito.Core.Base.StringResolver#formatString
     */
    formatString(str, ...args) {
        return StringUtils.formatString(str, ...args);
    }

    /**
     * Resolves and returns the translated string based on the specified string key.
     * The key must be a fully qualified, dot-separated, path referring to a property
     * that is part of the "i18n_strings" object.
     * <br>
     * Note that the key must be prefixed with a '$' character.
     * <br>
     * If the specified key is not found, an empty string is returned, else the resolved
     * translated string is returned.
     *
     * @param {string} strKey - String key.
     * @param {boolean} [suppressWarnings] - True if no warnings should be shown in console for missing translation or incorrectly formatted strKeys.
     *
     * @returns {string} Translated string.
     *
     * @function Mojito.Core.Base.StringResolver#resolveString
     */
    resolveString(strKey, suppressWarnings) {
        return StringUtils.resolveString(strKey, this.l10n, suppressWarnings);
    }

    /**
     * Parse label name and return last tuple.
     *
     * @param {string} strKey - String key.
     *
     * @returns {string} Label name prefix.
     *
     * @function Mojito.Core.Base.StringResolver#deriveLabelNamePrefix
     */
    deriveLabelNamePrefix(strKey) {
        return StringUtils.deriveLabelNamePrefix(strKey);
    }
}
