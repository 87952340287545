export default class PdfUtils {
    static writePdfToFile(blob, name) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], { type: 'application/pdf' });

        // The browser is not allowed to download on IOS, which requires special care for chrome on IOS.
        // Safari on IOS handles it good already.
        if (PdfUtils.requireIosChromeWorkaround()) {
            const reader = new FileReader();
            reader.onload = () => {
                window.location.href = reader.result;
            };
            reader.readAsDataURL(newBlob);
        } else {
            const filename = `${name}.pdf`;

            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');

            // For Firefox the link should be in DOM
            document.body.appendChild(link);
            link.href = data;
            link.download = filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL
            setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
            }, 100);
        }
    }

    static requireIosChromeWorkaround() {
        // Version 71 of Chrome on IOS is probably the last version that needs the extra handling for downloading PDF files
        const LAST_CHROME_VERSION_FOR_WORKAROUND = 71;
        const parts = navigator.userAgent.match(/CriOS\/(\d+)\./);
        return !!(parts && parseInt(parts[1], 10) <= LAST_CHROME_VERSION_FOR_WORKAROUND);
    }

    static buildPdfRequest(bets, labels) {
        return {
            bets,
            date: bets ? bets[0].betPlacementDateTime : undefined,
            ...labels,
        };
    }
}
