import MojitoNGen from 'mojito/ngen';
import actionsRegistry from 'core/services/content/actions-registry.js';
import reduxInstance from 'core/services/redux-instance';

const log = MojitoNGen.logger.get();

/**
 *
 * Helper class contains functionality to request the specified data to be populated into the relevant store.
 * Typically used in controller views.
 *
 * @class RequestDataHelper
 * @memberof Mojito.Core.Services
 */
export default class RequestDataHelper {
    constructor(instanceId) {
        this.instanceId = instanceId;
    }

    /**
     * Request the specified data to be populated into the relevant store.
     * Creates subscription on data changes and binds it to specified instanceId.
     *
     * @param {object} dataDescriptor - Data to populate the store with.
     * @function Mojito.Core.Services.RequestDataHelper#requestData
     */
    requestData(dataDescriptor) {
        // Sanity
        if (!dataDescriptor.dataType) {
            log.warn('No data type specified!');
            return;
        }

        // Redux specifics
        const subscribeAction = actionsRegistry.getSubscribe(dataDescriptor.dataType);
        const payload = this.instanceId
            ? { ...dataDescriptor, clientId: this.instanceId }
            : dataDescriptor;
        const { dispatch } = reduxInstance.store;
        subscribeAction && dispatch(subscribeAction(payload));
    }

    /**
     * Unsubscribes from all data, that was previously requested to specified instanceId.
     *
     * @param {string} [instanceId] - Id of subscriber instance. If not provided then instanceId which was set in constructor will be used.
     *
     * @function Mojito.Core.Services.RequestDataHelper#unsubscribeAll
     */
    unsubscribeAll(instanceId = this.instanceId) {
        // Redux specifics
        const actions = actionsRegistry.getAllUnsubscribes();
        const { dispatch } = reduxInstance.store;
        actions.filter(Boolean).forEach(action => dispatch(action(instanceId)));
    }
}
