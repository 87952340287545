/**
 *  This module is used to patch Mojito core services for performance reporting.
 **/

import MojitoCore from 'mojito/core';
import {collectTime} from './utils.js';

const UIViewUtils = MojitoCore.Presentation.UIViewUtils;

const oldGetConfig = UIViewUtils.getConfig.bind(UIViewUtils);
UIViewUtils.getConfig = function (...args) {
    const start = performance.now();
    const config = oldGetConfig(...args);
    const end = performance.now();
    collectTime('getConfig', end - start);

    return config;
};
