import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * @class AbstractNotificationsService
 * @abstract
 * @memberof Mojito.Services.SportsContent.ContentNotifications
 */
export default class AbstractNotificationsService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @returns {Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService} Instance of the service.
     * @function Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        return this;
    }

    /**
     * Activates service API.
     *
     * @returns {Promise} Promise object.
     * @function Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService#activate
     */
    activate() {
        log.error('activate must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Follow event.
     *
     * @param {Mojito.Services.SportsContent.ContentNotifications.types.FollowEventInfo} payload - Identifier of event that user wants to subscribe.
     * @returns {Promise} Event promise.
     * @function Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService#followEvent
     */
    // eslint-disable-next-line no-unused-vars
    followEvent(payload) {
        log.error('followEvent must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Unfollow event.
     *
     * @param {Mojito.Services.SportsContent.ContentNotifications.types.FollowEventInfo} payload - Unfollow event payload.
     * @returns {Promise} Event promise.
     * @function Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService#unfollowEvent
     */
    // eslint-disable-next-line no-unused-vars
    unfollowEvent(payload) {
        log.error('unfollowEvent must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Indicates if notifications service is available in user's browser.
     *
     * @returns {boolean} True if available, false otherwise.
     * @function Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService#isAvailable
     */
    isAvailable() {
        log.error('isAvailable must be implemented by concrete subclass');
        return false;
    }

    /**
     * Indicates if notifications service is activated by user.
     *
     * @returns {boolean} True if activated, false otherwise.
     * @function Mojito.Services.SportsContent.ContentNotifications.AbstractNotificationsService#isActivated
     */
    isActivated() {
        log.error('isActivated must be implemented by concrete subclass');
        return false;
    }
}
