import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import MojitoCore, { bootstrap as coreBootstrap } from 'mojito/core';
import { bootstrap as servicesBootstrap } from 'mojito/services';
import { bootstrap as modulesBootstrap } from 'mojito/modules';
import MojitoPresentation, { bootstrap as presentationBootstrap } from 'mojito/presentation';

import MainControllerView from './main/index.jsx';
import ResourceHandler from './resourcehandler/index.jsx';
import { actions as applicationActions } from './stores/application/slice.js';
import routeResolverFactory from './stores/router/route-resolver-factory.js';

import { actions as routerActions } from './stores/router/slice.js';
const { dispatch } = MojitoCore.Services.redux.store;
const intentActions = MojitoCore.Intents.actions;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const reduxInstance = MojitoCore.Services.redux;

/**
 * @typedef AppConfig
 * @type {object}
 * @property {*} core - The configuration for the core layer of the application.
 * @property {Mojito.Services.Config} data - The configuration for the services layer of the application.
 * @property {*} resources - The configuration for the resources layer of the application.
 *
 * @memberof Mojito.Application
 */

class Main {
    constructor() {
        this.initialized = false;
        this.rootElementId = null;
        this.rootElement = null;
        this.appConfig = {};
    }

    /**
     * Initialize the application layer and subsequent layers given the configuration.
     * Typically used in conjunction with {@link Mojito.Application#render|render} and must be called before it.
     *
     * @param {Mojito.Application.AppConfig} appConfig - The Application configuration.
     * @function Mojito.Application#init
     */
    init(appConfig) {
        this.appConfig = this.ensureDestinationPrefix(appConfig);
        this.initLayers(this.appConfig);
        routeResolverFactory.setRouteResolver(
            this.appConfig.application?.stores?.router?.routeResolver
        );
        dispatch(applicationActions.configure(this.appConfig));
        this.initialized = true;
    }

    /**
     * Renders Mojito application on root element.
     * _NOTE:_ Must be called after {@link Mojito.Application#init|init} resolved, else an error is thrown.
     *
     * @param {string} rootElementId - Id of the Dom element to use as the root for the Mojito Application.
     * @function Mojito.Application#render
     */
    render(rootElementId) {
        if (!this.initialized) {
            throw new Error(
                'Application is not initialized. Make sure Mojito.Application.init is done before calling render.'
            );
        }
        this.rootElementId = rootElementId;
        const mainView = (
            <Provider store={reduxInstance.store}>
                <MainControllerView customRoutes={this.appConfig.customRoutes} />
            </Provider>
        );

        this.rootElement = createRoot(document.getElementById(this.rootElementId));
        this.rootElement.render(mainView);
    }

    /**
     * Terminates Mojito application. Used to stop background processing.
     * Typically used in conjunction with {@link Mojito.Application#unmount|unmount}.
     *
     * @function Mojito.Application#terminate
     */
    terminate() {
        dispatch(routerActions.reset());
        coreBootstrap.dispose();
        servicesBootstrap.dispose();
        presentationBootstrap.dispose();
    }

    /**
     * Unmounts Mojito application. Used to properly remove mojito from DOM.
     * Typically used in conjunction with {@link Mojito.Application#terminate|terminate}.
     *
     * @function Mojito.Application#unmount
     */
    unmount() {
        if (!this.rootElement) {
            return;
        }
        this.rootElement.unmount();
    }

    initLayers(config) {
        coreBootstrap.init(config.core);
        servicesBootstrap.init(config.services);
        modulesBootstrap.init(config.modules);
        ResourceHandler.init(config.resources);
        presentationBootstrap.init();
        dispatch(intentActions.publishIntent(IntentTypes.MOJITO_INITIALIZED));
    }

    ensureDestinationPrefix(config) {
        const { destinationPrefix: endpointPrefix } = config.core || {};
        const destinationPrefix = endpointPrefix || '/api';
        return { ...config, core: { ...(config.core || {}), destinationPrefix } };
    }
}

export default new Main();
