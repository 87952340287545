const sum = new Map();
export function collectTime(id, value) {
    let entry = sum.get(id);
    if (!entry) {
        entry = 0;
    }
    entry += value;
    sum.set(id, entry);
}

export function getCollectedTime(id) {
    return sum.get(id);
}
