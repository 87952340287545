import LinkMenu from 'presentation/components/link-menu/index.jsx';
import MojitoCore from 'mojito/core';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;

export default class GlobalLinks extends UIViewImplementation {
    getItems() {
        if (!Array.isArray(this.config.links)) {
            return [];
        }

        return this.config.links.map(link => ({
            key: link.label,
            label: this.resolveString(link.label),
            intent: link.intent,
            hrefLink: link.hrefLink,
        }));
    }

    getHeader() {
        return { label: this.resolveString('$GLOBAL_LINKS.HEADER') };
    }

    render() {
        if (this.config.hide) {
            return null;
        }
        return (
            <LinkMenu
                config={this.config.linkMenu}
                class="ta-InformationMenu"
                header={this.getHeader()}
                items={this.getItems()}
            />
        );
    }
}
