import MojitoNGen from 'mojito/ngen';
const log = MojitoNGen.logger.get('MathUtils');
/**
 *
 * In this revision, a brief description about the potential functionality of MathUtils has been added. This can enhance the comprehensibility of the class for other developers.
 *
 * @class MathUtils
 * @memberof Mojito.Core.Base
 */

export default class MathUtils {
    /**
     * In the updated version, it's made explicit that n should be a non-negative integer.
     *
     * @param {number} n - The non-negative integer to compute the factorial for.
     *
     * @returns {number} The factorial of n.
     * @function Mojito.Core.Base.MathUtils#factorial
     */
    static factorial(n) {
        let val = 1;
        for (let i = 2; i <= n; i++) {
            val *= i;
        }
        return val;
    }

    /**
     * Calculates the number of ways of obtaining an ordered subset of r elements from a set of n elements,
     * also known as permutations.
     *
     * @param {number} n - The total number of elements in the set.
     * @param {number} r - The number of elements in the desired subset.
     *
     * @returns {number} The total number of permutations.
     * @function Mojito.Core.Base.MathUtils#numPermutations
     */
    static numPermutations(n, r) {
        if (!n || !r || n < r) {
            return 0;
        }
        return MathUtils.factorial(n) / MathUtils.factorial(n - r);
    }

    /**
     * Converts fractional number to decimal presentation with provided fraction digits.
     * Fractional number is considered to be a string with numerator and denominator divided by '/'.
     * Will return undefined if fraction is provided in wrong format or empty.
     *
     * @param {string} fraction - The fractional value, e.g 1/2, 5/7, 8/25 etc.
     * @param {number} [fractionDigits = 2] - Number of digits after the decimal point for returned result. Must be in the range 0 - 20, inclusive.
     *
     * @returns {number|undefined} Decimal number.
     * @function Mojito.Core.Base.MathUtils#toDecimal
     */
    static toDecimal(fraction, fractionDigits = 2) {
        if (!fraction || !fraction.includes('/')) {
            log.error('toDecimal called with incorrect fraction format.');
            return;
        }
        const split = fraction.split('/');
        const numerator = parseInt(split[0], 10);
        const denominator = parseInt(split[1], 10);
        return Number((numerator / denominator).toFixed(fractionDigits));
    }
}
