import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import QuickStartGuideDialogImpl from './quick-start-guide-dialog.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A quick start guide dialog.
 *
 * @class QuickStartDialog
 * @memberof Mojito.Presentation.Components
 */

/**
 * QuickStartGuideDialog prop types.
 *
 * @property {Mojito.Services.QuickStartGuide.types.Menu} [menu] - Menu object.
 * @property {Array<Mojito.Services.QuickStartGuide.types.Guide>} guides - Array of guides.
 * @memberof Mojito.Presentation.Components.QuickStartGuideDialog
 */
const propTypes = {
    menu: PropTypes.object,
    guides: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
            content: PropTypes.string,
        })
    ).isRequired,
};

export default UIView('QuickStartGuideDialog', QuickStartGuideDialogImpl, propTypes);
