import ShowAllSelectionsButtonImpl from './show-all-selections-button.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to display show all selections button.
 *
 * @class ShowAllSelectionsButton
 * @memberof Mojito.Modules.AggregatedMarketPane
 */

/**
 * ShowAllSelectionsButton prop types.
 *
 * @property {boolean} minimized - True if container is minimized otherwise false.
 * @property {Function} onMinimizeChange - Callback to toggle minimized value.
 *
 * @memberof Mojito.Modules.AggregatedMarketPane.ShowAllSelectionsButton
 */
const propTypes = {
    minimized: PropTypes.bool.isRequired,
    onMinimizeChange: PropTypes.func.isRequired,
};

const defaultProps = {};

export default UIView(
    'ShowAllSelectionsButton',
    ShowAllSelectionsButtonImpl,
    propTypes,
    defaultProps
);
