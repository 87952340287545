import PropTypes from 'prop-types';
import TotalPayoutInfoImpl from './total-payout-info.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to display payout funds of bet.
 *
 * @class TotalPayoutInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * TotalPayoutInfo props.
 *
 * @property {Mojito.Services.Bets.types.Funds} funds - Bet funds.
 * @property {number} [previousPayout] - Previous payout value.
 * @property {boolean} [isPotential = true] - Flag to show potential label.
 * @property {boolean} [isBoosted] - Flag to show label with boost.
 * @property {boolean} [isSuspended] - Flag indicating if there is any suspended selection.
 * @property {boolean} [showPayoutIcon] - Flag to show icon next to payout. If payout tax is present icon will be shown on payout after taxes, otherwise it will be shown on payout.
 * @property {string} [class] - ClassName for a wrapper.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.TotalPayoutInfo
 */
const propTypes = {
    funds: PropTypes.object.isRequired,
    previousPayout: PropTypes.number,
    isPotential: PropTypes.bool,
    isBoosted: PropTypes.bool,
    isSuspended: PropTypes.bool,
    showPayoutIcon: PropTypes.bool,
    class: PropTypes.string,
};

const defaultProps = {
    isPotential: true,
};

export default UIView('TotalPayoutInfo', TotalPayoutInfoImpl, propTypes, defaultProps);
