/* eslint-disable no-unused-vars */
import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get('AbstractContentTransporter');

/**
 *
 * Abstract class offering content transport.
 *
 * @class AbstractContentTransporter
 * @abstract
 * @memberof Mojito.Core.Services
 */
export default class AbstractContentTransporter {
    /**
     * Initialize transporter.
     *
     * This method is called by the frameworks life-cycle manager.
     * Normally, you should not have to call this method yourself.
     *
     * @param {object} [config] - Configuration properties.
     *
     * @function Mojito.Core.Services.AbstractContentTransporter#init
     */
    init(config) {
        log.error('The method `init` must be implemented by a concrete subclass.');
    }

    /**
     * Registers and configures a collection.
     *
     * @param {string} collection - Collection name.
     * @param {string} locale - Locale.
     *
     * @function Mojito.Core.Services.AbstractContentTransporter#registerCollection
     */
    registerCollection(collection, locale) {
        log.error('The method `registerCollection` must be implemented by a concrete subclass.');
    }

    /**
     * Subscribe to updates on a data entity.
     *
     * @param {string} collection - Collection that entity belongs to.
     * @param {string} id - Identifier of entity.
     * @param {Function} callback - Callback handler for updates ``function (data) {}``. An update where data is null indicates that the entity has been removed from the repository.
     *
     * @returns {object} Subscription that must be disposed when no longer needed.
     *
     * @function Mojito.Core.Services.AbstractContentTransporter#subscribe
     */
    subscribe(collection, id, callback) {
        log.error('The method `subscribe` must be implemented by a concrete subclass.');
        return {};
    }

    /**
     * Subscribe to updates on a list of data entities.
     *
     * @param {string} collection - Collection that entities belong to.
     * @param {Array<string>} ids - Identifiers of entities to subscribe.
     * @param {Function} callback - Callback handler for updates ``function (data) {}``. An update where data is null indicates that the entity has been removed from the repository.
     *
     * @returns {object} Subscription that must be disposed when no longer needed.
     *
     * @function Mojito.Core.Services.AbstractContentTransporter#subscribeMultiple
     */
    subscribeMultiple(collection, ids, callback) {
        log.error('The method `subscribeMultiple` must be implemented by a concrete subclass.');
        return {};
    }

    /**
     * Execute a search query.
     *
     * @param {string} collections - The collection name, for example 'event'.
     * @param {object} options - Map containing options. Valid options are 'locale', 'limit' and 'filter'.
     * @param {string} query - Search query.
     * @param {Function} callback - Callback handler for the results function (error, result) {}, where result is an array of search results.
     *
     * @function Mojito.Core.Services.AbstractContentTransporter#search
     */
    search(collections, options, query, callback) {
        log.error('The method `search` must be implemented by a concrete subclass.');
    }

    /**
     * Dispose a transporter. Stops all background processing, clear configuration, etc.
     *
     * @function Mojito.Core.Services.AbstractContentTransporter#dispose
     */
    dispose() {
        log.error('The method `dispose` must be implemented by a concrete subclass.');
    }
}
