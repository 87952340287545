/* eslint-disable jsdoc/no-undefined-types */
import PropTypes from 'prop-types';

import MojitoCore from 'mojito/core';

import bonusHistoryControllerConfig from 'mojito/core/generated/configs/_bonus-history-controller.js';

import BonusHistoryControllerImpl from './bonus-history-controller.jsx';

MojitoCore.Services.Config.configRegistry.add(bonusHistoryControllerConfig);

/**
 * Component responsible for bonus history data presentation.
 *
 * @name Controller
 * @class BonusHistoryController
 * @extends Mojito.Core.Presentation.UIViewImplementation
 * @memberof Mojito.Modules.BonusHistory
 */

/**
 * BonusHistoryController prop types.
 *
 * @property {boolean} [showPrewager = true] Indicate that prewager should be displayed.
 *
 * @memberof Mojito.Modules.BonusHistory.Controller
 */
const propTypes = {
    showPrewager: PropTypes.bool,
};

const defaultProps = {
    showPrewager: true,
};

export default MojitoCore.Presentation.UIControllerView(
    'BonusHistoryController',
    BonusHistoryControllerImpl,
    propTypes,
    defaultProps
);
