import createPrefixer from 'inline-style-prefixer/dynamic/createPrefixer';
import prefixMap from '../../../generated/prefix-map';
import prefixPlugins from 'inline-style-prefixer/dynamic/plugins';

const backgroundClipPlugin = function (property, value, style) {
    if (property === 'backgroundClip' && value === 'text') {
        style.WebkitBackgroundClip = 'text';
        return 'text';
    }
};

export default createPrefixer({
    plugins: [...prefixPlugins, backgroundClipPlugin],
    prefixMap: prefixMap,
});
