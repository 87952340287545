import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get();

/**
 *
 * Helper class contains functionality typically used in react components to apply set state with slightly delay.
 *
 * @class DeferredStateHelper
 * @memberof Mojito.Core.Base
 */
export default class DeferredStateHelper {
    constructor() {
        this.onStateChangeTimeout = this.onStateChangeTimeout.bind(this);
        this.deferredStates = {};
        this.deferredStatesAllowed = true;
    }

    /**
     * Registers a callback for a state change that will be called after a slight delay. The key
     * uniquely identifies the calling context. If additional calls are made with the same key
     * before the callback is fired, these calls will make the previously registered callbacks obsolete.
     *
     * @function Mojito.Core.Base.DeferredStateHelper#deferStateChange
     * @param {string} key - Key uniquely identifying the calling context.
     * @param {Function} cbFunc - Callback function invoked when a state update is necessary.
     */
    deferStateChange(key, cbFunc) {
        if (!this.deferredStatesAllowed) {
            log.warn(
                'Requesting a deferred state change when not allowed, since the component is, ' +
                    'or about to be, unmounted. key: ',
                key
            );
            return;
        }

        this.deferredStates[key] = cbFunc;

        // Schedule a timer if none is running
        if (!this.deferredStateTimeoutId) {
            this.deferredStateTimeoutId = setTimeout(this.onStateChangeTimeout, 100);
        }
    }

    /**
     * Dispose all pending deferred state changes. Typically called on component unmount.
     *
     * @function Mojito.Core.Base.DeferredStateHelper#dispose
     */
    dispose() {
        this.deferredStatesAllowed = false;

        // Clear everything related to state changes
        if (this.deferredStateTimeoutId) {
            clearTimeout(this.deferredStateTimeoutId);
            this.deferredStateTimeoutId = null;
        }

        this.deferredStates = {};
    }

    onStateChangeTimeout() {
        const prevDeferredStates = this.deferredStates;

        // Clear things before doing callbacks
        this.deferredStates = {};
        this.deferredStateTimeoutId = 0;

        Object.keys(prevDeferredStates).forEach(key => {
            prevDeferredStates[key](key);
        });
    }
}
