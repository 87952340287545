import MojitoCore from 'mojito/core';

const { lacksValues } = MojitoCore.Base.objUtils;

export default class SelectedIndicator extends MojitoCore.Presentation.UIViewImplementation {
    render() {
        const style = this.props.selected ? this.style.base : this.style.unselected;
        return style ? <div style={style}>{this.props.children}</div> : null;
    }
}

SelectedIndicator.getStyle = ({ style }, mode, merge) => {
    const baseStyle = merge(style.base, { position: 'absolute' });
    return {
        base: baseStyle,
        unselected: lacksValues(style.unselected) ? undefined : merge(baseStyle, style.unselected),
    };
};
