import Notification from 'presentation/components/notification/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const CHUNK_LOAD_ERROR = 'ChunkLoadError';

export default function ComponentErrorView(props) {
    const {
        mojitoTools: { config, stringResolver },
        error,
        showErrorDetails,
    } = props;

    if (error.name !== CHUNK_LOAD_ERROR) {
        return null;
    }

    const l10nTitle = window.navigator.onLine
        ? '$COMPONENT_ERROR_VIEW.GENERIC_TITLE'
        : '$COMPONENT_ERROR_VIEW.LOST_CONNECTION_TITLE';

    return (
        <FlexPane config={config.container}>
            <Notification
                config={config.notification}
                class={'ta-ComponentErrorView'}
                text={stringResolver.resolveString(l10nTitle)}
            />
            {showErrorDetails && <Text config={config.errorDetailsMessage}>{error.message}</Text>}
        </FlexPane>
    );
}
