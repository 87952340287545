import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import DataDescriptor from './descriptor.js';
import provider from './provider/index.js';
import channelFactory from 'services/common/content/content-channel-factory.js';

const reduxInstance = MojitoCore.Services.redux;
const { actionsRegistry } = MojitoCore.Services.Content;
const { INITIALISATION } = DataDescriptor.DATA_TYPES;

/**
 * Defines the state of the system information store.
 *
 * @typedef SystemInformationState
 *
 * @property {boolean} complete - Defines if loading is done.
 * @property {number} secondsRemaining - Estimation how much seconds remaining until fully loaded.
 *
 * @memberof Mojito.Services.SystemInformation
 */

/**
 * The name of the system information store. It is used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SystemInformation
 */
export const STORE_KEY = 'systemInformationStore';

export const initialState = {};

export const { reducer, actions } = createSlice({
    name: STORE_KEY,
    initialState,

    reducers: {
        reset() {
            return { ...initialState };
        },

        update(state, { payload = {} }) {
            state.complete = payload.complete;
            state.secondsRemaining = payload.secondsRemaining;
        },
    },
});

export const getChannel = () => channelFactory.getChannel(provider, INITIALISATION);

actions.subscribe = payload => dispatch => {
    const { clientId, dataType } = payload;
    const onData = (_, data) => dispatch(actions.update(data));
    getChannel().subscribe([dataType], clientId, onData);
};

actions.unsubscribe = clientId => () => {
    getChannel().unsubscribeAll(clientId);
};

actionsRegistry.addSubscription(INITIALISATION, actions.subscribe, actions.unsubscribe);

reduxInstance.injectReducer(STORE_KEY, reducer);
