import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';
import EventCardSkeleton from './card/index.jsx';
import { memo } from 'react';

const { FlexPane } = MojitoPresentation.Components;

// eslint-disable-next-line react/display-name
const EventCardsSkeleton = memo(props => {
    const {
        mojitoTools: { config },
    } = props;

    return (
        <FlexPane config={config.container}>
            {range(config.numberOfCards).map(i => (
                <EventCardSkeleton key={i} config={config.eventCardSkeleton} />
            ))}
        </FlexPane>
    );
});

export default EventCardsSkeleton;
