import CookieConsentImpl from './cookieconsent.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A simple view that display the possibility for the user to accept the application cookie police.
 * The view consist of three separate parts, all individually stylable,
 * the description text, the policy link, and a button to accept the policy.
 *
 * @class CookieConsent
 * @memberof Mojito.Presentation.Components
 */

export default UIView('CookieConsent', CookieConsentImpl);
