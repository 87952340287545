import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import AppViewImpl from './view.jsx';
import { SLIDING_VIEW_TYPES } from './types';

const UIView = MojitoCore.Presentation.UIView;

/**
 * @class AppView
 * @memberof Mojito.Application
 */

/**
 * AppView types.
 *
 * @property {node} [children] - The content to be displayed.
 * @property {Mojito.Services.Authentication.types.UserInfo} [userInfo] -  User information data.
 * @property {Mojito.Services.Bonus.types.FreeBet[]} [freeBets] - An array of FreeBet objects representing user's available free bets.
 * @property {object} [sideMenuState] - An object representing the state of the side menu, specifically which menus are currently open or closed.
 * @property {string} [selectedSportId] - The id of the selected sport.
 * @property {Mojito.Application.AppViewTypes.SLIDING_VIEW_TYPES} [activeSlidingViewType] - The type of the sliding view currently displayed. Refer to {@link Mojito.Application.AppViewTypes.SLIDING_VIEW_TYPES|SLIDING_VIEW_TYPES} for possible values.
 * @property {object} [activeSlidingViewData] - Optional: Additional data associated with the active sliding view.
 *
 * @memberof Mojito.Application.AppView
 */
const propTypes = {
    children: PropTypes.node,
    userInfo: PropTypes.object,
    freeBets: PropTypes.array,
    sideMenuState: PropTypes.object,
    selectedSportId: PropTypes.string,
    activeSlidingViewType: PropTypes.oneOf(Object.values(SLIDING_VIEW_TYPES)),
    activeSlidingViewData: PropTypes.object,
};

export default UIView('AppView', AppViewImpl, propTypes);
