import MojitoCore from 'mojito/core';

const DataProvider = MojitoCore.Services.DataProvider;
const COLLECTION_NAME = 'metaInformation';

/**
 *
 * Class offering the possibility to link to specific Meta information.
 *
 * @class MetaInformationProvider
 * @private
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.MetaInformation
 */
class MetaInformationProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(metaInformationId, setter) {
        return super.subscribeToEntity(metaInformationId, setter, COLLECTION_NAME);
    }
}

export default new MetaInformationProvider();
