import MojitoCore from 'mojito/core';
import { STORE_KEY, initialState } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * User info store selectors.
 *
 * @module UserInfoSelectors
 * @name selectors
 * @memberof Mojito.Services.UserInfo
 */

/**
 * Selects user info state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.UserInfo.UserInfoState} UserInfo state.
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || initialState;
};

/**
 * Selects the UserInfo object.
 *
 * @function selectUserInfo
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used, if not provided.
 * @returns {object} The userInfo object.
 *
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectUserInfo = state => selectState(state);

/**
 * Selects the username.
 *
 * @function selectUserName
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string|undefined} User name if exists, else undefined.
 *
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectUserName = state => selectState(state).userName;

/**
 * Selects the currency code.
 *
 * @function selectCurrency
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string|undefined} Currency code if exists, else undefined.
 *
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectCurrency = state => selectState(state).currency;

/**
 * Selects promotions groups that the user belongs to.
 *
 * @function selectPromotionsGroups
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<string>} User promotions groups.
 *
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectPromotionsGroups = state => selectState(state).groups || [];

/**
 * Selects allowEarlyPayout boolean.
 *
 * @function selectAllowEarlyPayout
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} The allowEarlyPayout flag.
 *
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectAllowEarlyPayout = state => selectState(state).allowEarlyPayout;

/**
 * Selects allowBestOddsGuaranteed value.
 *
 * @function selectAllowBestOddsGuaranteed
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} The allowBestOddsGuaranteed flag.
 *
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectAllowBestOddsGuaranteed = state => selectState(state).allowBestOddsGuaranteed;

/**
 * Selects the freebet balance.
 *
 * @function selectFreeBetBalance
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string|undefined} Freebet balance if exists, else undefined.
 *
 * @memberof Mojito.Services.UserInfo.selectors
 */
export const selectFreeBetBalance = state => selectState(state).balances?.freebet;
