import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import SearchView from 'application/components/search/search-view/index.jsx';
import { connect } from 'react-redux';
import { selectRoute } from 'application/stores/router/selectors.js';

const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const { selectRecentSearches, selectResult } = MojitoServices.Search.selectors;
const searchActions = MojitoServices.Search.actions;
class SearchControllerView extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.state = {
            searchInputResetState: '',
        };
        this.onRemoveRecentSearch = this.onRemoveRecentSearch.bind(this);
    }

    onRemoveRecentSearch(query) {
        this.emitAnalytics('searchHistoryItemRemoved', query);
        this.props.dispatch(searchActions.removeRecentSearch({ query }));
    }

    render() {
        return (
            <SearchView
                config={this.config.searchView}
                searchResult={this.props.searchResult}
                recentItems={this.props.recentItems}
                onRemoveRecentSearch={this.onRemoveRecentSearch}
                initiallyActive={this.props.initiallyActive}
                onSearchClose={this.props.onSearchClose}
                searchInputResetState={String(this.props.route)}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        searchResult: selectResult(state),
        recentItems: selectRecentSearches(state),
        route: selectRoute(state),
    };
};

export default connect(mapStateToProps)(SearchControllerView);
