import PropTypes from 'prop-types';
import RotatableImageImpl from './rotatableImage.jsx';
import MojitoCore from 'mojito/core';

/**
 * A RotatableImage component that provides image rotation functionality.
 *
 * @class RotatableImage
 * @memberof Mojito.Presentation.Components
 */

/**
 * RotatableImage prop types.
 *
 * @property {string} angle - The CSS angle string used for image rotation (https://developer.mozilla.org/en-US/docs/Web/CSS/angle).
 * @property {string} [class] - An optional class name applied to the root element.
 *
 * @memberof Mojito.Presentation.Components.RotatableImage
 */
const propTypes = {
    angle: PropTypes.string.isRequired,
    class: PropTypes.string,
};

export default MojitoCore.Presentation.UIView('RotatableImage', RotatableImageImpl, propTypes);
