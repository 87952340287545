import PropTypes from 'prop-types';
import StakeTaxImpl from './stake-tax-info.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Component to display stake tax info of bet funds.
 *
 * @class StakeTaxInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * StakeTaxInfo props.
 *
 * @property {number} value - Stake tax value.
 * @property {boolean} [hasDivider] - Flag to append $BET_FUNDS.DIVIDER to label.
 * @property {string} currencyCode - Currency.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.StakeTaxInfo
 */
const propTypes = {
    value: PropTypes.number.isRequired,
    hasDivider: PropTypes.bool,
    currencyCode: PropTypes.string.isRequired,
};

export default UIView('StakeTaxInfo', StakeTaxImpl, propTypes);
