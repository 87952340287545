import PropTypes from 'prop-types';
import NotificationImpl from './notification.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View for Notification component.
 *
 * @class Notification
 * @memberof Mojito.Presentation.Components
 */

/**
 * Notification component prop types.
 *
 * @property {string} [text=''] - Text to be shown in the notification.
 * @property {node} [children] - Child components to render inside notification.
 * @property {string} [class] - Class name applied to the root element.
 *
 * @memberof Mojito.Presentation.Components.Notification
 */
const propTypes = {
    text: PropTypes.string,
    children: PropTypes.node,
    class: PropTypes.string,
};

const defaultProps = {
    text: '',
};

export default UIView('Notification', NotificationImpl, propTypes, defaultProps);
