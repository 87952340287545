import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';
import { memo } from 'react';

const { FlexPane, SkeletonPart } = MojitoPresentation.Components;

// eslint-disable-next-line react/display-name
const BannerSkeletons = memo(props => {
    const {
        mojitoTools: { config, style },
    } = props;
    const { numberOfBanners, scrollIntoCenter } = config;
    const centralBannerIndex = Math.round(numberOfBanners / 2) - 1;
    return (
        <FlexPane class="ta-BannerSkeletons" config={config.container}>
            {range(config.numberOfBanners).map(i => {
                const container =
                    scrollIntoCenter && centralBannerIndex === i
                        ? style.bannerCentered
                        : config.bannerSkeletonContainer;
                return (
                    <FlexPane key={i} class="ta-BannerSkeleton" config={container}>
                        <SkeletonPart config={config.bannerSkeleton} />
                    </FlexPane>
                );
            })}
        </FlexPane>
    );
});

BannerSkeletons.getStyle = (config, mode, merge) => {
    return {
        bannerCentered: merge(config.bannerSkeletonContainer, {
            style: { scrollSnapAlign: 'center' },
        }),
    };
};

export default BannerSkeletons;
