import AbstractMessagingService from './abstract-messaging-service.js';

/**
 * Singleton object that receives messaging service config and spawn service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.Messaging
 */
class ServiceFactory {
    constructor() {
        this.service = new AbstractMessagingService();
    }
    /**
     * Configure service instance.
     *
     * @param {Mojito.Services.Messaging.AbstractMessagingService} service - The instance to be configured.
     *
     * @function Mojito.Services.Messaging.serviceFactory#init
     */
    init(service) {
        this.service = service;
    }

    /**
     * Service getter.
     *
     * @function getService
     * @returns {Mojito.Services.Messaging.AbstractMessagingService} Messaging service instance.
     * @memberof Mojito.Services.Messaging.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
