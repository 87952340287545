import AbstractBetslipService from './abstract-betslip-service.js';
import MojitoCore from 'mojito/core';
import { omit } from 'mojito/utils';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const BASE_URL = '/betslip';
const logger = MojitoCore.logger.get('BetslipService');
const NamedStorageService = MojitoCore.Services.Storage.NamedService;

const ADD_PART_URL = 'part/add';
const UPDATE_PART_URL = 'part/update';
const REMOVE_PART_URL = 'part/remove';
const SWITCH_PART_SELECTION_URL = 'part/switch-selection';

const ADD_COMPOSITE_URL = 'composite/add';
const UPDATE_COMPOSITE_URL = 'composite/update';
const REMOVE_COMPOSITE_URL = 'composite/remove';

const betslipStorage = new NamedStorageService('betslipData');

/**
 * Betslip service config.
 *
 * @typedef BetslipServiceConfig
 * @type {object}
 * @property {string} serviceUrl - Betslip API endpoint URL.
 *
 * @memberof Mojito.Services.Betslip
 */

/**
 * Class offering the possibility to interact with the mojito betslip API.
 *
 * @class BetslipService
 * @name service
 * @extends Mojito.Services.Betslip.AbstractBetslipService
 * @memberof Mojito.Services.Betslip
 */
export class BetslipService extends AbstractBetslipService {
    constructor() {
        super();
        this.addFreeBet = this.freeBetCallFactory('add-free-bet', 'add free bet');
        this.removeFreeBet = this.freeBetCallFactory('remove-free-bet', 'remove free bet');
    }
    /**
     * Configure service.
     *
     * @param {Mojito.Services.Betslip.BetslipServiceConfig} config - Service configuration object.
     *
     * @function Mojito.Services.Betslip.service#configure
     */
    configure(config) {
        this.serviceUrl = config.serviceUrl;
        this.requestFactory = new ApiRequestProvider(logger);
        this.apiUrl = `${this.serviceUrl + BASE_URL}/`;
    }

    /**
     * Performs init betslip call with provided betslip state.
     *
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
     * @param {Mojito.Modules.Betslip.types.BETSLIP_MODE} betslipMode - Betslip mode.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on init call response.
     * @function Mojito.Services.Betslip.service#initBetslip
     */
    initBetslip(oddsFormat, betslipMode, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}init`)
            .withDescription('init betslip')
            .withCredentials()
            .send({ oddsFormat, betslipMode, state });
    }

    /**
     * Generate api endpoint url for specified api call.
     *
     * @param {string} method - Api call (method).
     *
     * @returns {string} API call url.
     * @function Mojito.Services.Betslip.service#getEndpointUrlFor
     */
    getEndpointUrlFor(method) {
        return `${this.apiUrl}${method}`;
    }

    /**
     * Add part to the betslip.
     *
     * @param {Mojito.Services.Betslip.types.AddSelectionPartPayload} payload - Add part payload data.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on add part call response.
     * @function Mojito.Services.Betslip.service#addPart
     */
    addPart(payload, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        const data = {
            ...payload,
            state,
        };

        if (payload.parent) {
            data.parent = omit(payload.parent, 'marketId');
        }

        return this.requestFactory
            .post(this.getEndpointUrlFor(ADD_PART_URL))
            .withDescription('add part')
            .withCredentials()
            .send(data);
    }

    /**
     * Remove betslip part.
     *
     * @param {string} selectionId - Selection id.
     * @param {string} betId - Bet id.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on remove part call response.
     * @function Mojito.Services.Betslip.service#removePart
     */
    removePart(selectionId, betId, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(this.getEndpointUrlFor(REMOVE_PART_URL))
            .withDescription('remove part')
            .withCredentials()
            .send({ selectionId, betId, state });
    }

    /**
     * Update selections within betslip part.
     *
     * @param {Array<Mojito.Services.Betslip.types.Selection>} selections - Selections to update in betslip.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on update part call response.
     * @function Mojito.Services.Betslip.service#updatePart
     */
    updatePart(selections, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(this.getEndpointUrlFor(UPDATE_PART_URL))
            .withDescription('update part')
            .withCredentials()
            .send({ selections, state });
    }

    switchPartSelection(selectionId, betRef, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(this.getEndpointUrlFor(SWITCH_PART_SELECTION_URL))
            .withDescription('switch part selection')
            .withCredentials()
            .send({ selectionId, betRef, state });
    }

    /**
     * Add composite to the betslip.
     *
     * @param {Mojito.Services.Betslip.types.AddCompositePayload} payload - Add betslip composite payload.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on add composite call response.
     *
     * @function Mojito.Services.Betslip.service#addComposite
     */
    addComposite(payload, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(this.getEndpointUrlFor(ADD_COMPOSITE_URL))
            .withDescription('add composite')
            .withCredentials()
            .send({ ...payload, state });
    }

    /**
     * Update composite in the betslip.
     *
     * @param {Mojito.Services.Betslip.types.UpdateCompositePayload} payload - Update betslip composite payload.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on update composite call response.
     *
     * @function Mojito.Services.Betslip.service#updateComposite
     */
    updateComposite(payload, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(this.getEndpointUrlFor(UPDATE_COMPOSITE_URL))
            .withDescription('update composite')
            .withCredentials()
            .send({ ...payload, state });
    }

    /**
     * Remove composite from the betslip.
     *
     * @param {Array} selectionIds - List of selection ids within composite.
     * @param {string} betId - Bet id.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on remove composite call response.
     *
     * @function Mojito.Services.Betslip.service#removeComposite
     */
    removeComposite(selectionIds, betId, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(this.getEndpointUrlFor(REMOVE_COMPOSITE_URL))
            .withDescription('remove composite')
            .withCredentials()
            .send({ selectionIds, betId, state });
    }

    /**
     * Set the stake for a specific bet in a betslip.
     *
     * @param {Mojito.Services.Betslip.types.SetStakeRequest} payload - Set stake payload data.
     * @param {*} state - Betslip state.
     * @param {AbortSignal} [abortSignal] - Abort signal instance to allow pending request cancellation.
     *
     * @returns {Promise} Promise resolves on set stake call response.
     * @function Mojito.Services.Betslip.service#setStake
     */
    setStake(payload, state, abortSignal) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        const request = this.requestFactory
            .post(`${this.apiUrl}stake`)
            .withDescription('set stake')
            .withCredentials();

        abortSignal?.addEventListener('abort', () => request.abort());

        return request.send({ ...payload, state });
    }

    /**
     * Activate a stake group.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on activate a stake group call response.
     * @function Mojito.Services.Betslip.service#activateStakeGroup
     */
    activateStakeGroup(stakeGroupName, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        return this.requestFactory
            .post(`${this.apiUrl}stake/activate-group`)
            .withDescription('activate stake group')
            .withCredentials()
            .send({
                stakeGroupName,
                state,
            });
    }

    activateTeaserType(type, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        return this.requestFactory
            .post(`${this.apiUrl}teaser/activate-type`)
            .withDescription('activate teaser type')
            .withCredentials()
            .send({ type, state });
    }

    /**
     * Remove a stake group.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on remove a stake group call response.
     * @function Mojito.Services.Betslip.service#removeStakeGroup
     */
    removeStakeGroup(stakeGroupName, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }

        return this.requestFactory
            .post(`${this.apiUrl}stake/clear-group`)
            .withDescription('remove stake group')
            .withCredentials()
            .send({
                stakeGroupName,
                state,
            });
    }

    /**
     * Execute actions.
     *
     * @param {Array<Mojito.Services.Betslip.types.Action>} actions - List of actions.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on execute actions call response.
     * @function Mojito.Services.Betslip.service#executeActions
     */
    executeActions(actions, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}execute-actions`)
            .withDescription('execute actions')
            .withCredentials()
            .send({
                actions,
                state,
            });
    }

    /**
     * Place a betslip.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     * @param {Array<Mojito.Services.Betslip.types.PRICE_CHANGE_POLICY>} priceChangePolicies - Price change policies list.
     * @param {string} currency - Currency.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on place betslip call response.
     * @function Mojito.Services.Betslip.service#placeBetslip
     */
    placeBetslip(stakeGroupName, priceChangePolicies, currency, state) {
        return this.requestFactory
            .post(`${this.apiUrl}place`)
            .withDescription('place betslip')
            .withCredentials()
            .send({
                stakeGroupName,
                currency,
                priceChangePolicies,
                state,
            });
    }

    /**
     * Clear betslip.
     *
     * @param {Array<Mojito.Services.Betslip.types.BETSLIP_PART>} retainParts - Retain parts.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on clear betslip call response.
     * @function Mojito.Services.Betslip.service#clearBetslip
     */
    clearBetslip(retainParts, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}clear`)
            .withDescription('clear betslip')
            .withCredentials()
            .send({
                retainParts,
                state,
            });
    }

    /**
     * Validate betslip bonuses.
     * Typically used to validate acc bonuses on current betslip state.
     * Note: this call is only valuable with valid user session.
     *
     * @param {*} state - Betslip state.
     * @param {AbortSignal} [abortSignal] - Abort signal instance to allow pending request cancellation.
     *
     * @returns {Promise} Promise resolves on validate betslip bonuses call response.
     * @function Mojito.Services.Betslip.service#validateBonuses
     */
    validateBonuses(state, abortSignal) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        const request = this.requestFactory
            .post(`${this.apiUrl}bonus/validate`)
            .withDescription('validate bonuses')
            .withCredentials();

        abortSignal?.addEventListener('abort', () => request.abort());

        return request.send({ state });
    }

    /**
     * Refresh betslip bonuses.
     * Typically, forces backend to reset user bonus offers on betslip state.
     * Note: this call is only valuable with valid user session.
     *
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Promise resolves on validate betslip bonuses call response.
     * @function Mojito.Services.Betslip.service#refreshBonusOffers
     */
    refreshBonusOffers(state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}bonus/refresh-offers`)
            .withDescription('refresh bonus offers')
            .withCredentials()
            .send({ state });
    }

    /**
     * Factory function used to build concrete free bet request method.
     *
     * @private
     *
     * @param {string} call - Name of the free bet endpoint.
     * @param {string} description - Call description.
     *
     * @returns {Function} Function that will perform request to provided free bet endpoint.
     * @function Mojito.Services.Betslip.service#freeBetCallFactory
     */
    freeBetCallFactory(call, description) {
        return (token, state) => {
            if (!this.validateServiceUrl()) {
                return Promise.reject();
            }
            return this.requestFactory
                .post(`${this.apiUrl}bonus/${call}`)
                .withDescription(description)
                .withCredentials()
                .send({ token, state });
        };
    }

    /**
     * Get overask status.
     *
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Overask status promise.
     * @function Mojito.Services.Betslip.service#getOveraskStatus
     */
    getOveraskStatus(state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}overask/status`)
            .withDescription('get overask status')
            .withCredentials()
            .send({ state });
    }

    /**
     * Update overask.
     *
     * @param {Mojito.Services.Betslip.types.OveraskUpdatePayload} payload - Payload.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Overask update promise.
     * @function Mojito.Services.Betslip.service#updateOverask
     */
    updateOverask(payload, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}overask/update`)
            .withDescription('overask update')
            .withCredentials()
            .send({ ...payload, state });
    }

    /**
     * Toggle banker for bet.
     *
     * @param {string} betId - Bet ID.
     * @param {*} state - Betslip state.
     *
     * @returns {Promise} Toggle banker promise.
     * @function Mojito.Services.Betslip.service#toggleBanker
     */
    toggleBanker(betId, state) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        return this.requestFactory
            .post(`${this.apiUrl}bankers/toggle`)
            .withDescription('toggle banker for bet')
            .withCredentials()
            .send({ betId, state });
    }

    /**
     * Stores betslip data in a local storage in 'betslipData' property under dataType key.
     *
     * @param {object} data - Betslip data.
     * @param {Mojito.Services.Betslip.types.BETSLIP_STORAGE_TYPE} dataType - The type of the data, will be used as a key.
     *
     * @function Mojito.Services.Betslip.service#storeBetslipData
     */
    storeBetslipData(data, dataType) {
        const currentItem = betslipStorage.getItem() || {};
        const currentData = currentItem[dataType] || {};
        betslipStorage.setItem({ ...currentItem, [dataType]: { ...currentData, ...data } });
    }

    /**
     * Removes betslip data from local storage.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STORAGE_TYPE} dataType - The type of the data to be removed.
     *
     * @function Mojito.Services.Betslip.service#cleanBetslipData
     */
    cleanBetslipData(dataType) {
        const currentItem = betslipStorage.getItem();
        if (currentItem) {
            delete currentItem[dataType];
            betslipStorage.setItem({ ...currentItem });
        }
    }

    /**
     * Fetch betslip data from a local storage.
     *
     * @param {Mojito.Services.Betslip.types.BETSLIP_STORAGE_TYPE} [dataType] - The type of the data to be fetched.
     *
     * @returns {object} Betslip data from local storage.
     * @function Mojito.Services.Betslip.service#fetchBetslipData
     */
    fetchBetslipData(dataType) {
        const item = betslipStorage.getItem() || {};
        return dataType ? item[dataType] : item;
    }

    validateServiceUrl() {
        if (!this.serviceUrl) {
            logger.warn('serviceUrl is missing.');
            return false;
        }
        return true;
    }
}

export default new BetslipService();
