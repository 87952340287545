import MojitoCore from 'mojito/core';
import { isEmpty } from 'mojito/utils';
import { prefetchDataSuccess } from './actions.js';
import EventUtils from 'services/sports-content/events/utils.js';
import {
    STORE_KEY as PROMOTIONS_STORE_KEY,
    actions as promotionsActions,
} from 'services/promotions/slice.js';
import {
    STORE_KEY as SPORTS_STORE_NAME,
    actions as sportsActions,
} from 'services/sports-content/sports/slice.js';
import {
    STORE_KEY as EVENTS_STORE_NAME,
    actions as eventsActions,
} from 'services/sports-content/events/slice.js';
import {
    STORE_KEY as EVENT_GROUPS_STORE_NAME,
    actions as eventGroupsActions,
} from 'services/eventgroups/slice.js';
import {
    actions as sportMetaInfoActions,
    STORE_KEY as SPORT_META_INFORMATION_STORE_NAME,
} from 'services/sports-content/sport-meta-information/slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * A class for listening to prefetched data. Fills corresponding state slices depending on the data type.
 *
 * @class PrefetchListener
 * @name prefetchListener
 * @memberof Mojito.Services.Prefetch
 */
class PrefetchListener {
    /**
     * Initialize prefetch listener and start listening.
     *
     * @function Mojito.Services.Prefetch.prefetchListener#init
     */
    init() {
        this.startListening();
    }

    /**
     * Attaches listener and start listening for prefetchDataSuccess action. Action payload contains prefetched data.
     *
     * @function Mojito.Services.Prefetch.prefetchListener#startListening
     */
    startListening() {
        reduxInstance.actionListener.startListening({
            actionCreator: prefetchDataSuccess,
            effect: ({ payload }, { getState, dispatch }) => {
                const state = getState();
                const eventsState = state[EVENTS_STORE_NAME];
                const sportsState = state[SPORTS_STORE_NAME];
                const promotionsState = state[PROMOTIONS_STORE_KEY];
                const eventGroupsState = state[EVENT_GROUPS_STORE_NAME];
                const sportMetaState = state[SPORT_META_INFORMATION_STORE_NAME];
                const {
                    container,
                    eventGroups,
                    markets,
                    events,
                    promotions,
                    sportsMetaInformation,
                } = payload;

                const shouldSeedContent = (storeContent, prefetchContent) => {
                    return isEmpty(storeContent) && !isEmpty(prefetchContent);
                };

                if (shouldSeedContent(sportMetaState.sportMetaInformation, sportsMetaInformation)) {
                    const sportMetaPayload = sportsMetaInformation.map(data => ({
                        data,
                        id: data.id,
                    }));
                    dispatch(sportMetaInfoActions.updateSportMetaInformationList(sportMetaPayload));
                }

                if (shouldSeedContent(sportsState.sports, container?.items)) {
                    dispatch(sportsActions.updateSports({ sports: container.items }));
                }

                if (shouldSeedContent(eventsState.events, events)) {
                    const eventsPayload = events.map(data => ({ data, id: data.id }));
                    dispatch(eventsActions.updateEvents(eventsPayload));
                }

                if (shouldSeedContent(eventsState.markets, markets)) {
                    const marketsInMarketLines = EventUtils.getMarketsDataByMarketLine(
                        markets,
                        events
                    );
                    const marketsInPromotions = EventUtils.getMarketsDataByPromotions(
                        markets,
                        promotions
                    );
                    dispatch(
                        eventsActions.updateMarkets([
                            ...marketsInMarketLines,
                            ...marketsInPromotions,
                        ])
                    );
                }

                if (shouldSeedContent(promotionsState.promotionsByLocations, promotions)) {
                    dispatch(promotionsActions.updateChannelPromotions(promotions));
                }

                if (shouldSeedContent(eventGroupsState.eventGroups, eventGroups)) {
                    const eventGroupsPayload = eventGroups.map(data => ({ data, id: data.id }));
                    dispatch(eventGroupsActions.updateEventGroups(eventGroupsPayload));
                }
            },
        });
    }
}

export default new PrefetchListener();
