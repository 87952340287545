import AbstractVideoStreamingService from 'services/video-streaming/service/abstract-video-streaming-service';
import MojitoCore from 'mojito/core';
import { STREAM_PROVIDER, ERROR_CODE } from 'services/video-streaming/types';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const TransactionsTypes = MojitoCore.Services.Transactions.types;
const { GET } = TransactionsTypes.REQUEST_METHOD;
const logger = MojitoCore.logger.get('VideoStreamingService');

const VALIDATION_STATUS = {
    OK: 'OK',
    FAILED: 'FAILED',
};
const generateError = reason => ({
    code: ERROR_CODE.GENERIC_ERROR,
    message: `Direct provider request failed: ${reason}`,
});

/**
 * Video streaming service config.
 *
 * @typedef VideoStreamingServiceConfig
 * @type {object}
 * @property {string} serviceUrl - Streaming API endpoint URL.
 *
 * @memberof Mojito.Services.VideoStreaming
 */

/**
 * Class offering the possibility to interact with the mojito video streaming API.
 *
 * @class VideoStreamingService
 * @name service
 * @extends Mojito.Services.VideoStreaming.AbstractBetslipService
 * @memberof Mojito.Services.VideoStreaming
 */
class VideoStreamingService extends AbstractVideoStreamingService {
    constructor() {
        super();
        this.streamingUrlResolvers = {
            [STREAM_PROVIDER.IMG]: data => data?.hlsUrl,
            [STREAM_PROVIDER.PERFORM_MCC]: data =>
                data?.launchInfo?.streamLauncher?.[0].launcherURL,
        };
    }

    /**
     * Configure service.
     *
     * @param {Mojito.Services.VideoStreaming.VideoStreamingServiceConfig} config - Service configuration object.
     *
     * @function Mojito.Services.VideoStreaming.service#configure
     */
    configure(config) {
        this.serviceUrl = config.serviceUrl;
        this.apiRequestFactory = new ApiRequestProvider(logger);
    }

    getStreaming(payload) {
        if (!this.validateServiceUrl()) {
            return Promise.reject();
        }
        const { provider } = payload;
        return this.requestFromAPI(payload)
            .then(data =>
                data.streamInfo.requestUrl ? this.requestFromProvider(provider, data) : data
            )
            .catch(err => {
                const { message } = err || {};
                message &&
                    logger.error(`Can not load stream data for ${provider} provider. ${message}`);
                throw err;
            });
    }

    requestFromAPI(request) {
        return this.apiRequestFactory
            .post(`${this.serviceUrl}/video-streaming`)
            .withDescription('get video streaming')
            .withCredentials()
            .send(request);
    }

    requestFromProvider(provider, request) {
        return new Promise((resolve, reject) => {
            const validation = this.validateProvider(provider, request);
            if (validation.status !== VALIDATION_STATUS.OK) {
                return reject(generateError(validation.reason));
            }

            fetch(new Request(request.streamInfo.requestUrl, { method: GET }), {
                headers: request.headers,
            })
                .then(response => response.json())
                .then(body => {
                    const streamUrl = this.streamingUrlResolvers[provider](body);
                    if (streamUrl) {
                        resolve({ ...request, streamInfo: { ...request.streamInfo, streamUrl } });
                    } else {
                        reject(generateError('streamUrl is missing in provider response.'));
                    }
                })
                .catch(() => reject(generateError('Internal provider error.')));
        });
    }

    validateProvider(provider) {
        if (!this.streamingUrlResolvers[provider]) {
            return { status: VALIDATION_STATUS.FAILED, reason: `Provider is not supported.` };
        }
        return { status: VALIDATION_STATUS.OK };
    }

    validateServiceUrl() {
        if (!this.serviceUrl) {
            logger.warn('serviceUrl is missing.');
            return false;
        }
        return true;
    }
}

export default new VideoStreamingService();
