/**
 * Class defining system information data descriptors.
 *
 * @class SystemInformationDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.SystemInformation
 */
export default class SystemInformationDataDescriptor {
    /**
     * Factory method for creating a system information data descriptor.
     *
     * @returns {{dataType: string}} System information data descriptor.
     *
     * @function Mojito.Services.SystemInformation.descriptor#createInitialisationDescriptor
     * @static
     */
    static createInitialisationDescriptor() {
        return {
            dataType: SystemInformationDataDescriptor.DATA_TYPES.INITIALISATION,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} INITIALISATION - System initialisation descriptor type.
 * @memberof Mojito.Services.SystemInformation.descriptor
 */
SystemInformationDataDescriptor.DATA_TYPES = {
    INITIALISATION: 'initialisation',
};
