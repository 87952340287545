import { useCallback, useContext } from 'react';
import SelectionButtonView from './view/index.jsx';
import SelectionsContext from 'modules/selection-button/context/selections-context.js';
import SelectionButtonTypes from 'modules/selection-button/types/index.js';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';
import MojitoServices from 'mojito/services';
import { useSelector } from 'react-redux';

const { useAnalyticsContext } = MojitoCore.Presentation.Hooks;
const UIViewUtils = MojitoCore.Presentation.UIViewUtils;

const betslipModelFactory = MojitoServices.Betslip.modelFactory;
const NULL_CONTEXT = { addSelection: noop, removeSelection: noop, selectionStateSelector: noop };

const SelectionButton = props => {
    const { selection, event, mojitoTools, marketId, bettingContext } = props;
    const { config } = mojitoTools;
    const selectionsContext = useContext(SelectionsContext) || NULL_CONTEXT;
    const { analyticsPath } = useAnalyticsContext();
    const selectionState = useSelector(state =>
        selectionsContext.selectionStateSelector(state, selection.id)
    );

    const selectionButtonState = evaluateButtonState(selectionState);

    const onClick = useCallback(() => {
        const selectionData = { ...selection, marketId };
        if (isInactive(selectionButtonState)) {
            selectionsContext.addSelection({
                selection: betslipModelFactory.getSelection(selectionData),
                parent: betslipModelFactory.getParent(event, selectionData),
                source: analyticsPath,
                bettingContext,
            });
        } else if (isActive(selectionButtonState)) {
            selectionsContext.removeSelection({
                selectionId: selection.id,
                parent: betslipModelFactory.getParent(event, selectionData),
                source: analyticsPath,
                bettingContext,
            });
        }
    }, [
        selectionButtonState,
        event,
        selection,
        analyticsPath,
        selectionsContext,
        marketId,
        bettingContext,
    ]);

    return (
        <SelectionButtonView
            onClick={onClick}
            state={selectionButtonState}
            config={config.view}
            {...UIViewUtils.omitComponentOwnProps(props, 'marketId', 'bettingContext')}
        />
    );
};

const isActive = state => state === SelectionButtonTypes.STATES.ACTIVE;
const isInactive = state => state === SelectionButtonTypes.STATES.INACTIVE;

const evaluateButtonState = selectionState => {
    const { INACTIVE, PENDING, ACTIVE } = SelectionButtonTypes.STATES;
    if (!selectionState) {
        return INACTIVE;
    }
    return selectionState.isPending ? PENDING : ACTIVE;
};

export default SelectionButton;
