import { createAction } from '@reduxjs/toolkit';

/**
 * Action creator for prefetching data.
 * This action can be dispatched to indicate that the prefetched data is available.
 * The actual handling of this action, such as updating the state or triggering side effects,
 * depends on the reducers and middleware listening for this action.
 *
 * @function prefetchDataSuccess
 * @type {Mojito.Services.redux.ActionCreator}
 *
 * @param {object} payload - Prefetch data.
 * @memberof Mojito.Services.Prefetch.actions
 */
export const prefetchDataSuccess = createAction('prefetch/prefetchDataSuccess');
