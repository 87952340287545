import MojitoCore from 'mojito/core';

const DataProvider = MojitoCore.Services.DataProvider;
const COLLECTION_NAME = 'markets';

/**
 * Class offering the possibility to link to event markets.
 *
 * @class MarketProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class MarketProvider extends DataProvider {
    init(languageCode) {
        super.addSimpleCollection(COLLECTION_NAME, languageCode);
    }

    subscribeToEntity(marketId, onUpdate) {
        return super.subscribeToEntity(marketId, onUpdate, COLLECTION_NAME);
    }

    subscribeToEntities(marketIds, onInit, onUpdate) {
        return super.subscribeToEntities(marketIds, onInit, onUpdate, COLLECTION_NAME);
    }
}

export default new MarketProvider();
