import PropTypes from 'prop-types';
import QuickLinksImpl from './quick-links.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view designed to display "Quick Links," typically found at the bottom of the application.
 *
 * @class QuickLinksView
 * @name view
 * @memberof Mojito.Modules.QuickLinks
 */

/**
 * QuickLinks prop types.
 *
 * @property {object} [notifications = {}] - An object using Mojito.Modules.QuickLinks.types.NOTIFICATIONS as keys.
 * The keys are mapped to boolean values, and these define the show/hide behavior of notifications associated with each quick link.
 *
 * @memberof Mojito.Modules.QuickLinks.module
 */
const propTypes = {
    notifications: PropTypes.object,
};

const defaultProps = {
    notifications: {},
};

export default UIView('QuickLinks', QuickLinksImpl, propTypes, defaultProps);
