import { createContext } from 'react';

/**
 * Defines the actions of selection buttons and contains callback functions that are typically used when the selection button is clicked.
 *
 * @typedef SelectionsContextDefinition
 *
 * @property {Mojito.Modules.SelectionButton.Context.selectionStateSelector} selectionStateSelector - Selector to retrieve selection state.
 * Typically used by {@link Mojito.Modules.SelectionButton.module|SelectionButton} to ascertain its current state, like selected, unselected, pending, etc.
 * @property {Function} addSelection - An action handler for adding a selection.
 * @property {Function} removeSelection -An action handler for removing a selection.
 *
 * @memberof Mojito.Modules.SelectionButton.Context
 */

/**
 * Selector to retrieve selection state.
 * Typically used to highlight selections that are being added into betslip or match acca.
 *
 * @function selectionStateSelector
 *
 * @property {object} [state] - Application state object.
 * @property {string} selectionId - Selection id.
 * Can be used to retrieve selection state in the process of building a per-event bet, such as match acca betting.
 * @property {Mojito.Modules.SelectionButton.Context.SelectionsContextDefinition} [context] - The reference to executing context object.
 *
 * @returns {Mojito.Modules.SelectionButton.Context.SelectionState} The state of the selection.
 *
 * @memberof Mojito.Modules.SelectionButton.Context
 */

/**
 * Represents the state of a selection.
 *
 * @typedef SelectionState
 *
 * @property {boolean} isPending - Indicates whether the selection is in a pending state.
 * @memberof Mojito.Modules.SelectionButton.Context
 */

/**
 * Creates a context object for selections using `React.createContext()`.
 * This object propagates the {@link Mojito.Modules.SelectionButton.Context.SelectionsContextDefinition|SelectionsContextDefinition} instance as a value.
 *
 * @typedef SelectionsContext
 * @memberof Mojito.Modules.SelectionButton.Context
 */

export default createContext();
