import { useLoaderVisibility } from './hooks';
import { useEffect } from 'react';

export default function LoaderSuspense(props) {
    const {
        children,
        isContentPending,
        contentHash,
        loader,
        onShowContent,
        mojitoTools: { config, style },
    } = props;

    const isLoadingStage = useLoaderVisibility(
        config.showLoaderDelay,
        isContentPending,
        contentHash
    );

    useEffect(() => {
        if (!isLoadingStage) {
            onShowContent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingStage]);

    if (config.disableLoader) {
        return children || null;
    }

    const containerStyle = isLoadingStage ? style.hiddenContainer : config.style.container;
    const shouldShowLoaderContent = children || isLoadingStage;

    return (
        <>
            {shouldShowLoaderContent && (
                <div className={'ta-LoaderSuspenseContent'} style={containerStyle}>
                    {children}
                </div>
            )}
            {isLoadingStage && loader}
        </>
    );
}

LoaderSuspense.getStyle = (config, mode, merge) => ({
    hiddenContainer: merge(config.style.container, config.style.hidden),
});
