import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import BreadcrumbsImpl from './breadcrumbs-controller.jsx';
import BreadcrumbsTypes from './types.js';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * A module for displaying breadcrumbs.
 *
 * @class BreadcrumbsController
 * @name Breadcrumbs
 * @memberof Mojito.Modules
 */

/**
 * `Breadcrumbs` prop types.
 *
 * @property {Array} breadcrumbs -An array of objects representing breadcrumbs to display. Each object should have the following properties: 'type', 'data', and 'onClick'.
 *
 * @memberof Mojito.Modules.Breadcrumbs
 */
const propTypes = {
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(Object.values(BreadcrumbsTypes)).isRequired,
            data: PropTypes.any.isRequired,
            onClick: PropTypes.func,
            hrefLink: PropTypes.string,
        })
    ).isRequired,
};

export default UIControllerView('BreadcrumbsController', BreadcrumbsImpl, propTypes);
