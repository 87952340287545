import MojitoNGen from 'mojito/ngen';

/**
 * The Mojito core layer offers both low level functionality related to the business data retrieval
 * and basic utility functionality.
 *
 * @namespace Core
 * @memberof Mojito
 */

export * as Base from './base/_namespace.js';
export * as Constants from './constants/_namespace.js';
export * as Intents from './intents/_namespace.js';
export * as Presentation from './presentation/_namespace.js';
export * as Services from './services/_namespace.js';

/**
 * Core-level utility functions.
 *
 * @see {@link Mojito.Core.CoreUtils}
 * @memberof Mojito.Core
 */
export { default as Utils } from './utils/index.js';

/**
 * Supported application execution modes configured by application in runtime.
 *
 * @property {string} DEBUG - Debug execution mode. Debug code that may influence the performance will be active.
 * @property {string} RELEASE - Release execution mode (default). All debug code is turned off.
 *
 * @memberof Mojito.Core
 */
export { EXECUTION_MODES } from './base/execution-mode';

/**
 * Logger to be used for system-wide logging.
 *
 * @see {@link module:modules/logger|Logger}
 * @memberof Mojito.Core
 */
export const { logger } = MojitoNGen;
