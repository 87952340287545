import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';
import freeBetsRetriever from './data-retriever';
import BonusFreeBetsTypes from './types';
import BetslipTypes from 'services/betslip/types.js';
import { selectFreeBetBalance, selectCurrency } from 'services/user-info/selectors.js';
import { actions as userInfoActions } from 'services/user-info/slice.js';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import { actions as betslipActions } from 'services/betslip/slice.js';
import { isLoggedIn } from 'services/authentication/selectors.js';
import { selectFreeBetCode } from 'services/bonus-free-bets/selectors.js';

const reduxInstance = MojitoCore.Services.redux;
const { FREE_BETS_CODE_STATE, FREE_BETS_ERROR_CODE } = BonusFreeBetsTypes;
const { PLACEMENT_STATUS } = BetslipTypes;
const log = MojitoCore.logger.get('BonusFreeBetsStore');

/**
 * The name of the bonus free bets store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.BonusFreeBets
 */
export const STORE_KEY = 'bonusFreeBetsStore';

/**
 * Defines the state of the bonus free bets store.
 *
 * @typedef BonusFreeBetsState
 *
 * @property {Mojito.Services.BonusFreeBets.types.FreeBet[]} activeFreeBets - Stored active free bets.
 * @property {Mojito.Services.BonusFreeBets.types.FreeBet[]} historicFreeBets - Stored historic free bets bonus information.
 * @property {Mojito.Services.BonusFreeBets.types.FREE_BETS_CODE_STATE} freeBetCodeState - Free bets code state.
 * @property {Mojito.Services.Bonus.types.FreeBetError} freeBetCodeError - Free bet code error.
 * @property {boolean} isFetchingHistoricFreeBets - True if fetching is ongoing, else false.
 * @property {object} freeBetsBalance - Freebets balance.
 * @property {string} freeBetCode - Free bet code id.
 *
 * @memberof Mojito.Services.BonusFreeBets
 */

export const initialState = {
    activeFreeBets: [],
    historicFreeBets: [],
    freeBetCodeState: FREE_BETS_CODE_STATE.READY,
    freeBetCodeError: undefined,
    isFetchingHistoricFreeBets: false,
    freeBetsBalance: undefined,
    freeBetCode: '',
};

export const { reducer, actions } = createSlice({
    name: 'bonusFreeBets',
    initialState: initialState,
    reducers: {
        fetchActiveFreeBets: noop,
        fetchActiveFreeBetsSuccess(state, { payload: { freebets } }) {
            state.activeFreeBets = freebets;
        },
        fetchHistoricFreeBets: noop,
        fetchHistoricFreeBetsSuccess(state, { payload: { freebets } }) {
            state.isFetchingHistoricFreeBets = false;
            state.historicFreeBets = freebets;
        },
        fetchHistoricFreeBetsPending(state) {
            state.isFetchingHistoricFreeBets = true;
        },
        fetchFreeBetsFailure(state, { payload }) {
            const { message, type } = payload;
            const errorMessage = message ? message : '';
            state.isFetchingHistoricFreeBets = false;
            log.warn(`Failed to fetch free bets. Error: ${type} ${errorMessage}`);
        },
        inputFreeBet(state, { payload: freeBetCode }) {
            state.freeBetCode = freeBetCode;
            state.freeBetCodeState = FREE_BETS_CODE_STATE.READY;
            state.freeBetCodeError = undefined;
        },
        addFreeBet: noop,
        addFreeBetSuccess: noop,
        freeBetAdded(state) {
            state.freeBetCodeState = FREE_BETS_CODE_STATE.ACCEPTED;
            state.freeBetCode = '';
        },
        addFreeBetPending(state) {
            state.freeBetCodeState = FREE_BETS_CODE_STATE.PENDING;
        },
        addFreeBetEmpty(state) {
            state.freeBetCodeState = FREE_BETS_CODE_STATE.ERROR;
            state.freeBetCodeError = { type: FREE_BETS_ERROR_CODE.EMPTY_INPUT };
        },
        addFreeBetFailure(state, { payload }) {
            const { messages, type } = payload;
            state.freeBetCodeState = FREE_BETS_CODE_STATE.ERROR;
            state.freeBetCodeError = { type, message: messages ? messages[0] : undefined };
        },
        updateFreeBetsBalance(state, { payload: freeBetBalance }) {
            state.freeBetsBalance = freeBetBalance;
        },
        dispose(state) {
            state.activeFreeBets = [];
            state.historicFreeBets = [];
            state.freeBetCodeState = FREE_BETS_CODE_STATE.READY;
            state.freeBetCodeError = undefined;
            state.freeBetsBalance = undefined;
        },
    },
});

actions.fetchHistoricFreeBets = () => (dispatch, getState) => {
    if (isLoggedIn(getState())) {
        dispatch(actions.fetchHistoricFreeBetsPending());
        freeBetsRetriever.getHistoricFreeBets(selectCurrency(getState()));
    }
};

actions.addFreeBet = () => (dispatch, getState) => {
    const { freeBetCode } = getState()[STORE_KEY];
    if (!freeBetCode) {
        dispatch(actions.addFreeBetEmpty());
    } else {
        dispatch(actions.addFreeBetPending());
        freeBetsRetriever.addFreeBet(selectFreeBetCode(getState()));
    }
};

actions.addFreeBetSuccess = () => dispatch => {
    dispatch(actions.freeBetAdded());
    dispatch(actions.fetchActiveFreeBets());
};

actions.fetchActiveFreeBets = () => (dispatch, getState) => {
    if (isLoggedIn(getState())) {
        if (freeBetsRetriever.hasTaskRunner()) {
            freeBetsRetriever.fetchFreeBets();
        } else {
            freeBetsRetriever.getActiveFreeBets(selectCurrency(getState()));
        }
    }
};

reduxInstance.actionListener.startListening({
    matcher: isAnyOf(bootstrapActions.dispose, authenticationActions.disposeSession),
    effect: (action, listenerApi) => {
        listenerApi.dispatch(actions.dispose());
        freeBetsRetriever.stopPollingFreeBets();
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: (_, listenerApi) => {
        const state = listenerApi.getState();
        freeBetsRetriever.pollFreeBets(selectCurrency(state));
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: betslipActions.placeBetslipSuccess,
    effect: ({ payload = {} }, listenerApi) => {
        const { status } = payload;
        // Check for status accepted because place betslip success is triggered
        // when placing bet independent of bet going to overask or not.
        if (status === PLACEMENT_STATUS.ACCEPTED) {
            listenerApi.dispatch(actions.fetchActiveFreeBets());
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: userInfoActions.updateBalance,
    effect: (_, listenerApi) => {
        const state = listenerApi.getState();
        const userFreeBetBalance = selectFreeBetBalance(state);
        if (state[STORE_KEY].freeBetsBalance !== userFreeBetBalance) {
            listenerApi.dispatch(actions.updateFreeBetsBalance(userFreeBetBalance));
            listenerApi.dispatch(actions.fetchActiveFreeBets());
        }
    },
});

/**
 * Bonus free bets related actions.
 *
 * @module BonusFreeBetsActions
 * @name actions
 * @memberof Mojito.Services.BonusFreeBets
 */

/**
 * Retrieve active free bets bonus information.
 *
 * @function fetchActiveFreeBets
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Retrieve historic free bets bonus information.
 *
 * @function fetchHistoricFreeBets
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Bonus historic free bets retrieved successfully.
 *
 * @function fetchHistoricFreeBetsSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Bonus.types.FreeBet[]} freeBets - List of free bets.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Bonus active free bets retrieved successfully.
 *
 * @function fetchActiveFreeBetsSuccess
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Services.Bonus.types.FreeBet[]} freeBets - List of free bets.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Bonus free bets retrieval failed.
 *
 * @function fetchFreeBetsFailure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Core.Services.Transactions.types.Error} error - Error.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Action to add a free bet voucher to the user.
 *
 * @function addFreeBet
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Free bet successfully added to user.
 *
 * @function addFreeBetSuccess
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Update successful free bet added state.
 *
 * @function freeBetAdded
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Failed to add free bet code.
 *
 * @function addFreeBetFailure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {Mojito.Core.Services.Transactions.types.Error} error - Error.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Free bet code input action. Typically, arrives from user input interaction.
 *
 * @function inputFreeBet
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} code - Code.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

/**
 * Update free bets balance.
 *
 * @function updateFreeBetsBalance
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string|undefined} freeBetBalance - FreeBet balance.
 *
 * @memberof Mojito.Services.BonusFreeBets.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
