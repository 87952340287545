/* eslint-disable */
import React from 'react';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';

const FlexPane = MojitoPresentation.Components.FlexPane;
const Timer = MojitoPresentation.Components.Timer;
const TimerTypes = MojitoPresentation.Components.TimerTypes;
const DateTimeUtils = MojitoCore.Base.DateTimeUtils;
const LAYOUT_DIRECTION = Object.freeze({
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
});

const SessionTime = props => {
    const {loginTime, mojitoTools} = props;
    const {config, stringResolver} = mojitoTools;
    const {layoutDirection} = config;

    /*
    The {{time}} constant is set to a fake value below.
    This is done to guarantee scamper screenshots sustainability.
    */
    // const time = DateTimeUtils.diffInSeconds(new Date(), new Date(loginTime));
    const time = 1000;
    const className = `ta-SessionTime ta-${layoutDirection}-layout`;
    const isHorizontal = layoutDirection === LAYOUT_DIRECTION.HORIZONTAL;
    const timerConfig = isHorizontal ? config.timerHorizontal : config.timerVertical;
    const labelText = isHorizontal
        ? stringResolver.resolveString('$SESSION_TIME.HORIZONTAL_LABEL')
        : stringResolver.resolveString('$SESSION_TIME.VERTICAL_LABEL');
    const timerLabel = config.showLabel ? labelText : '';

    return (
        <FlexPane config={config.container} class={className}>
            <Timer
                time={time}
                startTimeLabel={timerLabel}
                config={timerConfig}
                direction={TimerTypes.TIMER_DIRECTION.FORWARD}
                format={config.timeFormat}
            />
        </FlexPane>
    );
};

export default SessionTime;
