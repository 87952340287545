import MojitoServices from 'mojito/services';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EventCardsCarouselUtils from 'modules/event-cards-carousel/utils.js';
import { without, pickBy } from 'mojito/utils';

const { convertToCard } = EventCardsCarouselUtils;
const { makeSelectEventsState, makeSelectMarketsState } =
    MojitoServices.SportsContent.Events.selectors;
const { types: ServicesTypes } = MojitoServices.Common;
const { UNAVAILABLE } = ServicesTypes.CONTENT_STATE;
const byDisplayOrder = (c1, c2) => c1.displayOrder - c2.displayOrder;

/**
 * Contains custom hooks for event card carousel functionality.
 *
 * @class EventCardsCarouselHooks
 * @name hooks
 * @memberof Mojito.Modules.EventCardsCarousel
 */

/**
 * Converts promotions to cards, sort by display order and filter out cards which have events with an UNAVAILABLE state.
 * NOTE: Not to be used to convert promotions to pre built bet cards, instead use
 * {@link Mojito.Modules.EventCardsCarousel.hooks.usePreBuiltBetCardsPromotions|usePreBuiltBetCardsPromotions}.
 *
 * @function useCardsPromotions
 *
 * @param {Array<Mojito.Services.Promotions.types.Promotion>} promotions - Promotions list.
 *
 * @returns {Array<object>} List of cards.
 * @memberof Mojito.Modules.EventCardsCarousel.hooks
 */
export const useCardsPromotions = promotions => {
    const cards = useMemo(
        () => (promotions ? promotions.map(convertToCard).sort(byDisplayOrder) : []),
        [promotions]
    );
    const eventIds = useMemo(
        () => cards.map(card => card.content.eventId).filter(Boolean),
        [cards]
    );

    const selectEventsState = useMemo(makeSelectEventsState, []);
    const eventsState = useSelector(state => selectEventsState(eventIds, state));

    // Sometimes promotions can still have event card for outdated event. It will happen if event is over but CmsJob hasn't rerun yet.
    // Here we will detect such event cards to ignore them while rendering.
    return useMemo(() => {
        const unavailableEventIds = Object.keys(
            pickBy(eventsState, eventState => eventState === UNAVAILABLE)
        );
        const unavailableCards = cards.filter(card =>
            unavailableEventIds.includes(card.content.eventId)
        );
        return without(cards, ...unavailableCards);
    }, [eventsState, cards]);
};

/**
 * Converts promotions to pre built bet cards, sort by display order and filter
 * out cards which have event or market with an UNAVAILABLE state.
 * This is needed instead of {@link Mojito.Modules.EventCardsCarousel.hooks.useCardsPromotions|useCardsPromotions}
 * because pre built bet cards don't support being shown in suspended state.
 *
 * @function usePreBuiltBetCardsPromotions
 *
 * @param {Array<Mojito.Services.Promotions.types.Promotion>} [promotions = []] - Promotions list.
 *
 * @returns {Array<object>} List of cards.
 * @memberof Mojito.Modules.EventCardsCarousel.hooks
 */
export const usePreBuiltBetCardsPromotions = (promotions = []) => {
    const cards = useMemo(() => promotions.map(convertToCard).sort(byDisplayOrder), [promotions]);
    const ids = useMemo(
        () =>
            cards.reduce(
                (acc, cur) => {
                    acc.eventIds.push(cur.content.eventId);
                    acc.marketIds.push(cur.content.marketId);
                    return acc;
                },
                { eventIds: [], marketIds: [] }
            ),
        [cards]
    );

    const { eventIds, marketIds } = ids;

    const selectEventsState = useMemo(makeSelectEventsState, []);
    const eventsState = useSelector(state => selectEventsState(eventIds, state));

    const selectMarketsState = useMemo(makeSelectMarketsState, []);
    const marketsState = useSelector(state => selectMarketsState(marketIds, state));

    // PreBuiltBetBuilderPromotions can have outdated event or market.
    // It will happen if event or market is over but CmsJob hasn't rerun yet.
    // Here we will detect such promotions to ignore them while rendering.
    return useMemo(() => {
        const unavailableEventIds = Object.keys(
            pickBy(eventsState, eventState => eventState === UNAVAILABLE)
        );
        const unavailableMarketIds = Object.keys(
            pickBy(marketsState, marketState => marketState === UNAVAILABLE)
        );

        const unavailableCards = cards.filter(
            card =>
                unavailableEventIds.includes(card.content.eventId) ||
                unavailableMarketIds.includes(card.content.marketId)
        );
        return without(cards, ...unavailableCards);
    }, [cards, eventsState, marketsState]);
};
