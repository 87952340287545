import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('AbstractBetsService');
/**
 * Abstract bets service to be implemented by different integrations.
 * Note: All methods must be implemented in an asynchronous way and return promise.
 *
 * @abstract
 * @memberof Mojito.Services.Bets
 */
export default class AbstractBetsService {
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Get bets.
     *
     * @param {Mojito.Services.Bets.types.GetBetsPayload} payload - Get bets payload.
     *
     * @returns {Promise} Get bets promise.
     * @function Mojito.Services.Bets.AbstractBetsService#getBets
     */
    // eslint-disable-next-line no-unused-vars
    getBets(payload) {
        log.error('getBets must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Get bet.
     *
     * @param {string} betId - Bet id.
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
     *
     * @returns {Promise} Get bet promise.
     * @function Mojito.Services.Bets.AbstractBetsService#getBet
     */
    // eslint-disable-next-line no-unused-vars
    getBet(betId, oddsFormat) {
        log.error('getBet must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Get open bet count.
     *
     * @param {Mojito.Services.Bets.types.GetBetsPayload} payload - Get bets payload.
     *
     * @returns {Promise} Get open bet count promise.
     * @function Mojito.Services.Bets.AbstractBetsService#getOpenBetCount
     */
    // eslint-disable-next-line no-unused-vars
    getOpenBetCount(payload) {
        log.error('getOpenBetCount must be implemented by concrete subclass');
        return Promise.reject();
    }

    /**
     * Refresh bets.
     *
     * @param {Mojito.Services.Bets.types.RefreshBetsPayload} payload - Refresh open bets payload.
     *
     * @returns {Promise} Refresh open bets promise.
     * @function Mojito.Services.Bets.AbstractBetsService#refreshBets
     */
    // eslint-disable-next-line no-unused-vars
    refreshBets(payload) {
        log.error('refreshOpenBets must be implemented by concrete subclass');
        return Promise.reject();
    }
}
