import MojitoCore from 'mojito/core';
import { Repository, SocketEnablerClient, Search, ExecutionEngine } from '@pbs/ngen-assets';

const { wsStatusListener } = MojitoCore.Services.Performance;
const AbstractContentTransporter = MojitoCore.Services.AbstractContentTransporter;

export default class NgenContentTransporter extends AbstractContentTransporter {
    constructor(config = {}) {
        super();
        this.config = config;
        this.repository = new Repository();
        this.socketEnablerClient = new SocketEnablerClient();
        this._search = new Search();
        this.executionEngine = new ExecutionEngine();
    }

    init() {
        this.executionEngine.init({
            maxConcurrentRequests: this.config.maxConcurrentRequests,
        });

        this.socketEnablerClient.init({
            ...this.config,
            executionEngine: this.executionEngine,
        });

        wsStatusListener.init({
            socketEnabler: this.socketEnablerClient,
        });

        this.repository.init({ executionEngine: this.executionEngine });

        // No need to start the socket enabler client if no base url
        if (this.config.baseUrl) {
            this.socketEnablerClient.start();
        }

        this._search.init({ socketEnablerClient: this.socketEnablerClient });
    }

    registerCollection(collection, locale, isLocaleInHeader = true) {
        const localeProp = isLocaleInHeader ? 'headerLocale' : 'locale';
        this.repository.addCollection(collection, {
            networkProvider: this.socketEnablerClient.createNetworkProvider(collection, {
                [localeProp]: locale,
            }),
            locale,
        });
    }

    subscribe(collection, id, callback) {
        return this.repository.subscribe(collection, id, callback);
    }

    subscribeMultiple(collection, ids, callback) {
        return this.repository.subscribeMultiple(collection, ids, callback);
    }

    search(collections, options, query, callback) {
        this._search.search(collections, options, query, callback);
    }

    dispose() {
        this.socketEnablerClient.terminate();
        wsStatusListener.dispose();
    }
}
