import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get('TransporterFactory');

const voidResponder = () => log.warn('Transporter instance is not set');
const NULL_TRANSPORTER = {
    init: voidResponder,
    registerCollection: voidResponder,
    subscribe: () => {
        voidResponder();
        return {
            dispose: () => {},
        };
    },
    subscribeMultiple: () => {
        voidResponder();
        return {
            dispose: () => {},
        };
    },
    search: voidResponder,
    dispose: voidResponder,
};

/**
 *
 * Class holds transporter instance.
 *
 * @name transporterFactory
 * @class TransporterFactory
 * @memberof Mojito.Core.Services
 */
class TransporterFactory {
    constructor() {
        this.transporter = NULL_TRANSPORTER;
    }

    /**
     * Set content transporter.
     *
     * @param {Mojito.Core.Services.AbstractContentTransporter} transporter - Content transporter.
     * @function Mojito.Core.Services.TransporterFactory#setTransporter
     */
    setTransporter(transporter) {
        this.transporter = transporter || NULL_TRANSPORTER;
    }

    /**
     * Get content transporter.
     *
     * @returns {Mojito.Core.Services.AbstractContentTransporter} Content transporter.
     * @function Mojito.Core.Services.TransporterFactory#getTransporter
     */
    getTransporter() {
        return this.transporter;
    }
}

export default new TransporterFactory();
