import PropTypes from 'prop-types';
import PrebuiltBets from './prebuilt-bets.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * Module for prebuilt bets, used by PrebuiltPromotionCards and PrebuiltMatchAccaMarkets to display PrebuiltBets as carousel or wrapping row layout.
 *
 * @function PrebuiltBets
 * @memberof Mojito.Modules
 */

/**
 * @typedef BetItem
 * @type {object}
 * @property {string} eventId - Id of event.
 * @property {string} marketId - Id of market.
 *
 * @memberof Mojito.Modules.PrebuiltBets
 */

/**
 * PrebuiltBets prop types.
 *
 * @property {Mojito.Modules.PrebuiltBets.BetItem[]} betItems - Array of BetItem to display. The array takes objects with the properties: eventId and marketId.
 * @property {Function} [onPrepareCardsToRender] - Callback triggered once prebuilt cards are prepared to be rendered. Accepts list of prebuilt card indexes.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from content. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 *
 * @memberof Mojito.Modules.PreBuiltBets
 */

const propTypes = {
    betItems: PropTypes.arrayOf(
        PropTypes.shape({
            eventId: PropTypes.string,
            marketId: PropTypes.string,
        })
    ).isRequired,
    onPrepareCardsToRender: PropTypes.func,
    shouldRequestData: PropTypes.bool,
};

const defaultProps = {
    shouldRequestData: true,
    onPrepareCardsToRender: noop,
};

export default UIControllerView('PrebuiltBets', PrebuiltBets, propTypes, defaultProps);
