import { Route, Routes, useParams, useLocation, matchPath } from 'react-router-dom';
import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';
import HomePage from 'application/pages/home-page/index.jsx';
import InPlayPage from 'application/pages/inplay-page/index.jsx';
import RaceMeetingsPage from 'application/pages/race-meetings-page/index.jsx';
import MatchSportPage from 'application/pages/match-sport-page/index.jsx';
import CompetitionsView from 'application/pages/match-sport-page/views/competitions-view/index.jsx';
import MatchesView from 'application/pages/match-sport-page/views/matches-view/index.jsx';
import MatchesCoupon from 'application/pages/match-sport-page/views/matches-view/matches-coupon/index.jsx';
import PriceBoostsView from 'application/pages/match-sport-page/views/price-boosts-view/index.jsx';
import OutrightsView from 'application/pages/match-sport-page/views/outrights-view/index.jsx';
import SingleLeaguePage from 'application/pages/single-league-page/index.jsx';
import SportDefaultPage from 'application/pages/sport-default-page/index.jsx';
import SportCouponPage from 'application/pages/sport-coupon-page/index.jsx';
import RaceSportPage from 'application/pages/race-sport-page/index.jsx';
import RaceMeetingPage from 'application/pages/race-meeting-page/index.jsx';
import FutureRacePage from 'application/pages/future-race-page/index.jsx';
import FutureRacesPage from 'application/pages/future-races-page/index.jsx';
import MyAccountPage from 'application/pages/my-account-page/index.jsx';
import BadRoutePage from 'application/pages/bad-route-page/index.jsx';
import VirtualSportPage from 'application/pages/virtual-sport-page/index.jsx';
import PriceBoostPage from 'application/pages/price-boost-page/index.jsx';
import GamificationPage from 'application/pages/gamification/index.jsx';
import DeepLinkingController from 'application/app/routing/deep-linking/deep-linking-controller.jsx';
import InplayEventController from 'application/app/routing/inplay-event/index.jsx';
import Protected from 'application/app/routing/protected/index.jsx';

const {
    types: { NAVIGATION },
    NavigationPathBuilder,
} = MojitoPresentation.Base.Navigation;
const { CustomRedirect } = MojitoPresentation.Components;
const QuickStartGuide = MojitoModules.QuickStartGuide;
const { COUPONS } = MojitoModules.SingleLeagueCoupon.SingleLeagueCouponTypes;

export default function RoutingView({ mojitoTools, customRoutes }) {
    const { config, appContext } = mojitoTools;
    const { routeResolver } = appContext;
    const sportNavigation = routeResolver.getPattern(NAVIGATION.SPORT_PAGE);
    const homeNavigation = routeResolver.getPattern(NAVIGATION.HOME);
    const matchesNavigation = sportNavigation.nested[NAVIGATION.MATCHES];
    const quickStartGuidePath = homeNavigation.nested[NAVIGATION.QUICK_START_GUIDE];
    const { pathname } = useLocation();
    const isQuickStartPath = !!matchPath(
        { path: routeResolver.enrichWithRoot(quickStartGuidePath) },
        pathname
    );

    return (
        <Routes>
            {renderCustomRoutes(customRoutes)}
            <Route
                path={routeResolver.getPattern(NAVIGATION.REDIRECT_HOME_1)}
                element={
                    <CustomRedirect to={routeResolver.getRoute(NavigationPathBuilder.toHome())} />
                }
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.REDIRECT_HOME_2)}
                element={
                    <CustomRedirect to={routeResolver.getRoute(NavigationPathBuilder.toHome())} />
                }
            />
            <Route
                path={homeNavigation.path}
                element={<HomePage autoScrollTop={!isQuickStartPath} />}
            >
                <Route path={quickStartGuidePath} element={<QuickStartGuide />} replace />
            </Route>
            <Route
                path={routeResolver.getPattern(NAVIGATION.INPLAY_PAGE)}
                element={<InPlayPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.INPLAY_SPORT_PAGE)}
                element={<InPlayPage />}
            />
            <Route path={sportNavigation.path} element={<SportPage config={config} />}>
                <Route index element={<SportDefaultPage />} replace />
                <Route
                    path={sportNavigation.nested[NAVIGATION.COMPETITIONS]}
                    element={<CompetitionsView />}
                />
                <Route path={matchesNavigation.path} element={<MatchesView />}>
                    <Route
                        path={matchesNavigation.nested[NAVIGATION.EVENTS_COUPON]}
                        element={<MatchesCoupon />}
                    />
                </Route>
                <Route
                    path={sportNavigation.nested[NAVIGATION.PRICE_BOOSTS]}
                    element={<PriceBoostsView />}
                />
                <Route
                    path={sportNavigation.nested[NAVIGATION.OUTRIGHTS]}
                    element={<OutrightsView />}
                />
            </Route>
            <Route
                path={routeResolver.getPattern(NAVIGATION.OUTRIGHTS_LEAGUE)}
                element={<SingleLeaguePage pageType={COUPONS.OUTRIGHTS} />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.COMPETITIONS_LEAGUE)}
                element={<SingleLeaguePage pageType={COUPONS.COMPETITIONS} />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.SPORT_HIGHLIGHTS_PAGE)}
                element={<RaceSportPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.INPLAY_EVENT_PAGE)}
                element={<InplayEventController isInplayRoute={true} />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.SPORTS_COUPON_PAGE)}
                element={<SportCouponPage />}
            />
            <Route
                path={`${routeResolver.getPattern(NAVIGATION.GAMIFICATION_ROOT)}/*`}
                element={<GamificationPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.SPORT_EVENT_PAGE)}
                element={<InplayEventController />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.MEETINGS_PAGE)}
                element={<RaceMeetingsPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.MEETINGS_TODAY_PAGE)}
                element={<RaceMeetingsPage filter={'today'} />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.MEETINGS_TOMORROW_PAGE)}
                element={<RaceMeetingsPage filter={'tomorrow'} />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.MEETING_PAGE)}
                element={<RaceMeetingPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.RACE_PAGE)}
                element={<RaceMeetingPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.FUTURE_RACE_PAGE)}
                element={<FutureRacePage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.FUTURE_RACES_PAGE)}
                element={<FutureRacesPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.VIRTUAL_SPORT_EVENT_PAGE)}
                element={<VirtualSportPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.VIRTUAL_SPORT_PAGE)}
                element={<VirtualSportPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.VIRTUAL_SPORT_CLASS_PAGE)}
                element={<VirtualSportPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.VIRTUAL_SPORTS_PAGE)}
                element={<VirtualSportPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.PRICE_BOOST_PAGE)}
                element={<PriceBoostPage />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.DEEP_LINKS_ADD_SELECTIONS)}
                element={<DeepLinkingController />}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.ACCOUNT_MAIN_PAGE)}
                element={myAccountPageElement()}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.ACCOUNT_ITEM_PAGE)}
                element={myAccountPageElement()}
            />
            <Route
                path={routeResolver.getPattern(NAVIGATION.ACCOUNT_PAGE)}
                element={myAccountPageElement()}
            />
            <Route path="*" element={<BadRoutePage />} />
        </Routes>
    );
}

function renderCustomRoutes(customRoutes) {
    return customRoutes.map(({ path, element }) => {
        return <Route path={path} element={element} key={path} />;
    });
}

function SportPage({ config }) {
    const { sportId } = useParams();
    const { hash } = useLocation();
    const isRacing = config.sportCategories.racing[sportId];

    if (isRacing) {
        return <SportDefaultPage />;
    }
    return <MatchSportPage autoScrollTop={!hash} />;
}

function myAccountPageElement() {
    return (
        <Protected>
            <MyAccountPage />
        </Protected>
    );
}
