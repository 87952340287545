import { useLayoutEffect, useMemo } from 'react';
import { useParams, useOutletContext, Outlet } from 'react-router-dom';
import MojitoPresentation from 'mojito/presentation';
import { isEmpty } from 'mojito/utils';

const NavigationPathBuilder = MojitoPresentation.Base.Navigation.NavigationPathBuilder;
const IntentActionTrigger = MojitoPresentation.Base.Intent.IntentActionTrigger;
const { ButtonBar, ButtonBarSkeleton, FlexPane, ScrollPane, LoaderSuspense } =
    MojitoPresentation.Components;
const INPLAY = 'inplay';

const getKey = (filter = {}) => filter.span || filter.type;

const resolveFirstFilterId = matches => getKey(matches?.filters[0]);

const isValidFilterId = (filterId, matches) =>
    matches?.filters.some(filter => getKey(filter) === filterId);

const convertToButtonItems = (matches, stringResolver) => {
    const resolveTranslation = key => {
        const customType = key.split(/(\d+)/); // Expecting custom filter type, as example: 2h, 48h, 7d.
        const numberFromKey = customType[1];
        const valueFromKey = customType[2];
        return (
            stringResolver.resolveString(`$MATCHES_VIEW.FILTERS.${key.toUpperCase()}`, true) ||
            stringResolver.resolveAndFormatString(
                `$MATCHES_VIEW.FILTERS.${valueFromKey.toUpperCase()}`,
                numberFromKey
            )
        );
    };

    return matches.filters.map(filter => ({
        key: getKey(filter),
        label: resolveTranslation(getKey(filter)),
        group: filter.group,
    }));
};

const getSelectedFilter = matches => matches?.filters.find(item => item.selected);

const MatchesView = props => {
    const {
        mojitoTools: { config, appContext, stringResolver },
    } = props;
    const { routeResolver, analyticsEmitter } = appContext;
    const { sportId, filter } = useParams();
    const { matches, menuItemsLoading } = useOutletContext();
    const filterAutoSelect = !isValidFilterId(filter, matches);
    let selectedFilterKey = filter || getKey(getSelectedFilter(matches));
    if (!isValidFilterId(selectedFilterKey, matches)) {
        selectedFilterKey = resolveFirstFilterId(matches);
    }

    useLayoutEffect(() => {
        if (filterAutoSelect && selectedFilterKey) {
            IntentActionTrigger.showSportsMatches(sportId, selectedFilterKey, true);
        }
    }, [sportId, selectedFilterKey, filterAutoSelect]);

    const buttonItems = useMemo(
        () => (matches ? convertToButtonItems(matches, stringResolver) : []),
        [matches, stringResolver]
    );

    if (isEmpty(matches) && config.skeletonLoader.disableLoader) {
        return null;
    }

    const selectedItem = buttonItems.find(item => item.key === selectedFilterKey) || {};
    const isInPlayCoupon = selectedFilterKey === INPLAY;
    const onEventItemClicked = eventId => IntentActionTrigger.showSportEvent(sportId, eventId);
    const getHrefEventItem = eventId =>
        routeResolver.getRoute(NavigationPathBuilder.toSportEvent(sportId, eventId));
    const getHrefMatchesFilterItem = filterId =>
        routeResolver.getRoute(NavigationPathBuilder.toSportMatches(sportId, filterId));
    const onFilterClick = key => {
        IntentActionTrigger.showSportsMatches(sportId, key);
        analyticsEmitter.emitAnalytics('matchesFilterClicked', {
            selectedFilterKey: key,
            sport: sportId,
        });
    };

    const buttonBarSkeleton = <ButtonBarSkeleton config={config.topMenuSkeleton} />;
    return (
        <FlexPane config={config.container} class="ta-MatchesView">
            <FlexPane config={config.contentContainer}>
                <FlexPane config={config.filterContainer}>
                    <ScrollPane config={config.scrollPane}>
                        <LoaderSuspense
                            config={config.skeletonLoader}
                            loader={buttonBarSkeleton}
                            isContentPending={menuItemsLoading}
                        >
                            <ButtonBar
                                items={buttonItems}
                                selectedKey={selectedFilterKey}
                                onSelectionChanged={onFilterClick}
                                config={config.filter}
                                getItemHrefLink={getHrefMatchesFilterItem}
                            />
                        </LoaderSuspense>
                    </ScrollPane>
                </FlexPane>
                <Outlet
                    context={{
                        isInPlayCoupon,
                        eventGroupsId: selectedItem.group,
                        onEventItemClicked,
                        getHrefItem: getHrefEventItem,
                    }}
                />
            </FlexPane>
        </FlexPane>
    );
};

export default MatchesView;
