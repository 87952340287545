import MojitoServices from 'mojito/services';
import LoginTypes from 'modules/login/types.js';

const AuthenticationTypes = MojitoServices.Authentication.types;
const { selectLoginState } = MojitoServices.Authentication.selectors;

/**
 * Login module helper class.
 *
 * @class LoginHelper
 * @name helper
 * @memberof Mojito.Modules.Login
 */
class LoginHelper {
    /**
     * Checks if a provided intent type requires user's authentication.
     *
     * @param {string} intentType - The intent type to examine.
     *
     * @returns {boolean} Returns 'true' if authentication is needed for intent resolution.
     * @function Mojito.Modules.Login#isLoginRequired
     */
    isLoginRequired(intentType) {
        return (
            Object.keys(LoginTypes.ENFORCE_LOGIN_INTENTS).includes(intentType) &&
            selectLoginState() !== AuthenticationTypes.STATES.LOGGED_IN
        );
    }
}

export default new LoginHelper();
