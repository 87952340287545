export default function SwiperSlide(props) {
    const {
        id,
        children,
        mojitoTools: { config },
    } = props;
    return (
        <div className={'ta-SwiperSlide'} id={id} style={config.style}>
            {children}
        </div>
    );
}
