import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';

const intentActions = MojitoCore.Intents.actions;
const reduxInstance = MojitoCore.Services.redux;
const { BACK_NAVIGATION } = MojitoPresentation.Base.Intent.Types;

/**
 * The name of the keypad store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Modules.Keypad
 */
export const STORE_KEY = 'modules/keypad';

/**
 * Defines the state of the keypad module store.
 *
 * @typedef KeypadModuleState
 *
 * @property {string} activeInput - The active input id.
 *
 * @memberof Mojito.Modules.Keypad
 */
export const initialState = {
    activeInput: undefined,
    isHidden: true,
};

export const { reducer, actions } = createSlice({
    name: 'keypad',
    initialState,
    reducers: {
        inputBlur(state, { payload: inputId }) {
            if (inputId === state.activeInput) {
                state.activeInput = undefined;
            }
        },
        setInputId(state, { payload: inputId }) {
            state.activeInput = inputId;
        },
        setIsHidden(state, { payload: isHidden }) {
            state.isHidden = isHidden;
        },
    },
    extraReducers: builder => {
        builder.addCase(intentActions.publishIntent, (state, { payload: intent }) => {
            const { type } = intent;
            // We need to hide keypad on back navigation, this could be handy behaviour for native for example.
            if (type === BACK_NAVIGATION) {
                state.activeInput = undefined;
            }
        });
    },
    reset() {
        return { ...initialState };
    },
});

/**
 * Keypad module related actions.
 *
 * @module Keypad
 * @name actions
 * @memberof Mojito.Modules.Keypad
 */

/**
 * Set active input id.
 *
 * @function setInputId
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} inputId - Unique input id.
 *
 * @memberof Mojito.Modules.Keypad.actions
 */

/**
 * Controls if keyboard should be hidden.
 *
 * @function setIsHidden
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {boolean} isHidden - Boolean isHidden flag.
 *
 * @memberof Mojito.Modules.Keypad.actions
 */

/**
 * Executes once input is getting blurred.
 *
 * @function inputBlur
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {string} inputId - Unique input id.
 *
 * @memberof Mojito.Modules.Keypad.actions
 */

/**
 * Reset keypad module state to initial value.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Modules.Keypad.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
