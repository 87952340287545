import MojitoNGen from 'mojito/ngen';
import ExecutionMode from './base/execution-mode';
import deviceTypeGuesser from './base/device-type-guesser';
import translationService from './services/localization/translation-service';
import { actions as cookieConsentActions } from './services/cookie-consent/slice.js';
import transporterFactory from './services/transporter/transporter-factory.js';
import reduxInstance from './services/redux-instance';
import Build from './generated/build.js';
import channelFactory from 'services/common/content/content-channel-factory';
import performanceServiceFactory from './services/performance/service/service-factory';

const { logger } = MojitoNGen;
const { dispatch } = reduxInstance.store;

/**
 * The Mojito core layer offers both low level functionality related to the business data retrieval
 * and basic utility functionality.
 *
 * @namespace Core
 * @memberof Mojito
 */
class Core {
    /**
     * Initializes the core layer with the given configuration.
     *
     * @param {{}} coreConfig - The configuration object for the core layer.
     *
     * @function Mojito.Core#init
     */
    init(coreConfig) {
        coreConfig = coreConfig || {};

        deviceTypeGuesser.init(coreConfig.deviceGuesser);

        const log = logger.get('Mojito', { level: logger.INFO });

        // Set global level based on config
        logger.setGlobalLogLevel(coreConfig.logLevel || logger.ERROR);

        // Hold on to execution mode
        ExecutionMode.setExecutionMode(coreConfig.executionMode);

        // Log build config
        log.info(
            `ver: ${Build.appVersion}` +
                `, sha: ${Build.gitVersion}` +
                `, execution mode: ${ExecutionMode.getExecutionMode().toLowerCase()}`
        );

        const { services } = coreConfig;
        if (services?.performanceService) {
            performanceServiceFactory.init({
                service: services?.performanceService.service,
                serviceConfig: services?.performanceService.serviceConfig,
            });
        }

        const contentTransporter = coreConfig.contentTransporter;
        contentTransporter?.init();
        transporterFactory.setTransporter(contentTransporter);

        // Start core data providers

        // Configure translations
        translationService.configure(coreConfig.translations);

        if (coreConfig.defaultCookieConsentGiven) {
            dispatch(cookieConsentActions.giveConsent());
        }
    }

    /**
     * Dispose core layer. Stops all background processing, disconnects from data feed, clear configuration.
     *
     * @function Mojito.Core#dispose
     */
    dispose() {
        channelFactory.disposeAllChannels();
        transporterFactory.getTransporter().dispose();
    }
}

export default new Core();
