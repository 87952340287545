import MojitoCore from 'mojito/core';
import Text from 'presentation/components/text/index.jsx';

export default class DotNotification extends MojitoCore.Presentation.UIViewImplementation {
    renderDot() {
        if (!this.props.isVisible) {
            return null;
        }
        const configStyle = this.config.style;
        return (
            <div style={configStyle.container} className="dot-notification-container">
                <div style={this.props.text ? configStyle.dotWithText : configStyle.dot}>
                    {this.props.text && (
                        <Text class="dot-notification-text" config={this.config.text}>
                            {this.props.text}
                        </Text>
                    )}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="DotNotification" style={this.config.style.rootContainer}>
                {this.props.children}
                {this.renderDot()}
            </div>
        );
    }
}
