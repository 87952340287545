import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import FutureRacesPageImpl from './future-races-page.jsx';

/**
 * @namespace FutureRacesPage
 * @memberof Mojito.Application.Pages
 */

/**
 * A page for displaying a list of future races for a particular racing sport.
 *
 * @class FutureRacesPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Application.Pages.FutureRacesPage
 */

export default UIPage('FutureRacesPage', FutureRacesPageImpl);
