import {createSlice} from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';

const reduxInstance = MojitoCore.Services.redux;

export const STORE_KEY = 'MetaDescriptionStore';

export const INITIAL_STATE = {
    meta: null,
};

export const {reducer, actions} = createSlice({
    name: 'meta-description',
    initialState: INITIAL_STATE,
    reducers: {
        setMetaDescription(state, {payload: metaData}) {
            state.meta = metaData;
        },
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
