import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Login module store selectors.
 *
 * @module Login
 * @name selectors
 * @memberof Mojito.Modules.Login
 */

/**
 * Selects user settings state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Module.Login.LoginModuleState} Login module state.
 * @memberof Mojito.Modules.Login.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects the state of login view visibility.
 *
 * @function selectLoginViewVisibility
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} Whether or not login view visible.
 * @memberof Mojito.Modules.Login.selectors
 */
export const selectLoginViewVisibility = state => {
    return selectState(state).isVisible;
};

/**
 * Selects the login reason.
 *
 * @function selectLoginReason
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Modules.Login.types.LOGIN_REASON} Login reason.
 * @memberof Mojito.Modules.Login.selectors
 */
export const selectLoginReason = state => {
    return selectState(state).loginReason;
};

/**
 * Selects the reason of why the login view show up.
 *
 * @function selectLoginViewReason
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} The reason of why the login view has been shown.
 * @memberof Mojito.Modules.Login.selectors
 */
export const selectLoginViewReason = state => {
    return selectState(state).showLoginViewReason;
};

/**
 * Subscribes to the changes of particular login state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Modules.Login.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
