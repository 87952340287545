import MojitoModules from 'mojito/modules';
import { SLIDING_VIEW_TYPES } from 'application/app/view/types.js';

const { BETSLIP_TYPE } = MojitoModules.SlidingBetslip.types;

/**
 * Application store utils.
 *
 * @class ApplicationStoreUtils
 * @private
 */

/**
 * Check if quick betslip is active using application store data.
 *
 * @param {string} type - Sliding view type.
 * @param {object} [data = {}] - Sliding view data.
 * @returns {boolean} True if quick betslip is active (shown in application), else false.
 * @private
 */
export const isQuickBetslipActive = (type, data = {}) => {
    const { betslipType } = data;
    return type === SLIDING_VIEW_TYPES.BETSLIP && betslipType === BETSLIP_TYPE.QUICK_BETSLIP;
};
