import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import NextRacesControllerImpl from './next-races-controller.jsx';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * A module responsible for displaying the next races.
 *
 * @class NextRacesController
 * @name NextRaces
 * @memberof Mojito.Modules
 */

/**
 * NextRaces prop types.
 *
 * @property {string} sportId - The id of the sport of interest.
 *
 * @memberof Mojito.Modules.NextRaces
 */
const propTypes = {
    sportId: PropTypes.string.isRequired,
};

export default UIControllerView('NextRacesController', NextRacesControllerImpl, propTypes);
