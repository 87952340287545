import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { useSelector } from 'react-redux';

const { CustomRedirect } = MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;

const { LOGGED_IN, LOGGED_OUT } = MojitoServices.Authentication.types.STATES;
const { selectLoginState } = MojitoServices.Authentication.selectors;

export default function Protected({ mojitoTools, children }) {
    const { appContext } = mojitoTools;
    const { routeResolver } = appContext;

    const loginState = useSelector(state => selectLoginState(state));

    if (loginState === LOGGED_IN) {
        return children;
    }

    if (loginState === LOGGED_OUT) {
        return <CustomRedirect to={routeResolver.getRoute(NavigationPathBuilder.toHome())} />;
    }

    return null;
}
