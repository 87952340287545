import ButtonBarSkeletonImpl from './button-bar-skeleton.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Skeleton component to represent a loading button bar.
 *
 * @function ButtonBarSkeleton
 * @memberof Mojito.Presentation
 */

export default UIView('ButtonBarSkeleton', ButtonBarSkeletonImpl);
