import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { noop, uniqBy, isEmpty } from 'mojito/utils';

const { MarketHelper, BettingHelper } = MojitoPresentation.Utils;
const BetslipUtils = MojitoServices.Betslip.utils;
const BetslipTypes = MojitoServices.Betslip.types;
const { PARTICIPANT_POSITION } = MojitoServices.SportsContent.Events.types;
const { SUSPENDED, ODDS_CHANGE, HANDICAP_CHANGE, MULTIPLIER_BREACH } = BetslipTypes.ERROR_CODE;

const byMarketTypeComparator = marketNamesMap => (item1, item2) => {
    const marketTypes = Object.keys(marketNamesMap);
    return marketTypes.indexOf(item1.type) - marketTypes.indexOf(item2.type);
};

const compareByName = (item1, item2) => item1.name.localeCompare(item2.name);

/**
 * Utility functions associated with the betslip module.
 *
 * @class BetslipModuleUtils
 * @name utils
 * @memberof Mojito.Modules.Betslip
 */
class BetslipModuleUtils {
    /**
     * Check if betslip has content.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @returns {boolean} True if content should exist, else false.
     * @function Mojito.Modules.Betslip.utils.hasContent
     */
    static hasContent(betslip) {
        return BetslipUtils.getSingleBetsCount(betslip) > 0;
    }

    /**
     * Get accumulative betslip errors list. Combines errors from betslip with client validation errors.
     * Attaches additional error types like {@link Mojito.Services.Betslip.types.ERROR_CODE|HANDICAP_CHANGE},
     * {@link Mojito.Services.Betslip.types.ERROR_CODE|SUSPENDED} and {@link Mojito.Services.Betslip.types.ERROR_CODE|ODDS_CHANGE}
     * based on respective flags in arguments. Filter out duplicated errors based on criteria of {@link Mojito.Modules.Betslip.utils.unifyErrors|unifyErrors} function.
     *
     * @param {Mojito.Services.Betslip.types.Betslip} betslip - Betslip.
     * @param {boolean} hasSuspendedBet - Flag indicates if betslip contains at least one suspended bet.
     * @param {boolean} hasOddsChangeBet - Flag indicates if betslip contains at least one bet with odds change.
     * @param {boolean} hasHcapChangeBet - Flag indicates if betslip contains at least one bet with handicap change.
     * @param {Array<Mojito.Services.Betslip.types.Error>} [validationErrors = []] - Validation errors.
     * Typically represents errors that were generated due to client validation process.
     * @returns {Array<Mojito.Services.Betslip.types.Error>} Accumulative list of errors. Usually can be used by betslip components for rendering.
     * @function Mojito.Modules.Betslip.utils.getErrors
     */
    static getErrors(
        betslip,
        hasSuspendedBet,
        hasOddsChangeBet,
        hasHcapChangeBet,
        validationErrors = []
    ) {
        const { errors: betslipErrors = [] } = betslip;
        // Filter our errors with duplicated message and code pair.
        const errors = BetslipModuleUtils.unifyErrors([...betslipErrors, ...validationErrors]);
        // Here we will ensure that errors contains SUSPENDED, ODDS_CHANGE or HANDICAP_CHANGE code depending on the corresponding flag.
        const enrichErrors = errorCodeToAdd => {
            const hasError = errors.some(error => error.code === errorCodeToAdd);
            !hasError && errors.push({ code: errorCodeToAdd });
        };
        // If corresponding flag is true but no error code exists - we will add our own.
        if (hasSuspendedBet) {
            enrichErrors(SUSPENDED);
        }
        if (hasOddsChangeBet) {
            enrichErrors(ODDS_CHANGE);
        }
        if (hasHcapChangeBet) {
            enrichErrors(HANDICAP_CHANGE);
        }

        return errors;
    }

    /**
     * Get accumulative bet errors list. Combines errors from bet stake with
     * additional {@link Mojito.Services.Betslip.types.ERROR_CODE|ODDS_CHANGE} error if <code>hasHcapChange</code> flag is true.
     *
     * @param {Mojito.Services.Betslip.types.BetStake} betStake - Bet stake object.
     * @param {boolean} hasHcapChange - Flag indicates if bet has handicap change.
     * @returns {Array<Mojito.Services.Betslip.types.Error>} Accumulative list of bet errors. Usually can be used to render next to particular bet on the betslip.
     * @function Mojito.Modules.Betslip.utils.getBetErrors
     */
    static getBetErrors(betStake, hasHcapChange) {
        const errors = [...(betStake.errors || [])];
        if (hasHcapChange) {
            errors.push({ code: HANDICAP_CHANGE });
        }
        return errors;
    }

    /**
     * Picks errors that considered to be betslip placement related
     * and usually should be shown inside place bet content component.
     *
     * @param {Array<Mojito.Services.Betslip.types.Error>} errors - List of errors.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Error>} Placement related errors list. Returns empty array if <code>errors</code> is undefined.
     * @function Mojito.Modules.Betslip.utils.pickPlacementErrors
     */
    static pickPlacementErrors(errors = []) {
        // Due to unknown UX reasons this particular error should be shown inside place bet content component
        // and not inside betslip itself as we do for all other error types.
        return errors.filter(error => error.code === MULTIPLIER_BREACH);
    }

    /**
     * Unify errors by message and code. The resulting list will contain errors with unique code-messages pair.
     *
     * @param {Array<Mojito.Services.Betslip.types.Error>} errors - List of errors.
     *
     * @returns {Array<Mojito.Services.Betslip.types.Error>} List of errors with unique code-messages pair.
     * @function Mojito.Modules.Betslip.utils.unifyErrors
     */
    static unifyErrors(errors) {
        return uniqBy(errors, error => `${error.code}-${error.message}`);
    }

    /**
     * Check if systems tab should be activated based on stake group name.
     *
     * @param {Mojito.Services.Betslip.types.STAKE_GROUP_NAME} stakeGroupName - Stake group name.
     *
     * @returns {boolean} True if stake group name is {@link Mojito.Services.Betslip.types.STAKE_GROUP_NAME.BANKERS|BANKERS} or {@link Mojito.Services.Betslip.types.STAKE_GROUP_NAME.SYSTEMS|SYSTEMS}, else false.
     * @function Mojito.Modules.Betslip.utils.isSystemsTab
     */
    static isSystemsTab(stakeGroupName) {
        const { BANKERS, SYSTEMS } = BetslipTypes.STAKE_GROUP_NAME;
        return stakeGroupName === BANKERS || stakeGroupName === SYSTEMS;
    }

    /**
     * Resolve market descriptors from {@link Mojito.Services.Betslip.types.Part|Part} alternative selections.
     * Includes market info from provided part into the list. If marketNamesMap is provided then it will be used to resolve market name,
     * otherwise marketName property from {@link Mojito.Services.Betslip.types.PartInfo|PartInfo} will be used.
     *
     * @param {Mojito.Services.Betslip.types.Part} part - Betslip bet part.
     * @param {Array<object>} participants - Event participants.
     * @param {Function} [marketNamesMap = {}] - Market names map. Key is market type and value is l10n key which will be used to resolve market name.
     * Values from this map will be used to override content driven market names from {@link Mojito.Services.Betslip.types.PartInfo|PartInfo}.
     * @param {Function} [resolveAndFormatString = ()=>{}] - Function that will be used to resolve market name. Used if marketNamesMap is provided.
     *
     * @returns {{name: string, type: string}|undefined} Markets map. Key is market type and value is market name.
     * If no alternativeSelections available then undefined will be returned.
     * @function Mojito.Modules.Betslip.utils.resolveAlternativeMarkets
     */
    static resolveAlternativeMarkets(
        part,
        participants = [],
        marketNamesMap = {},
        resolveAndFormatString = noop
    ) {
        const { alternativeSelections, partInfo } = part;
        if (isEmpty(alternativeSelections)) {
            return;
        }

        const { AWAY, HOME } = PARTICIPANT_POSITION;
        const homeParticipant = participants.find(participant => participant.position === HOME);
        const awayParticipant = participants.find(participant => participant.position === AWAY);
        const PITCHER_START_LABELS = {
            '$BETSLIP.PITCHER_MARKETS.HOME_PITCHER_START': homeParticipant?.startingPitcherName,
            '$BETSLIP.PITCHER_MARKETS.AWAY_PITCHER_START': awayParticipant?.startingPitcherName,
        };

        const resolveMarketName = info => {
            const l10nKey = marketNamesMap[info.marketType];
            const startingPitcher = PITCHER_START_LABELS[l10nKey];
            return l10nKey ? resolveAndFormatString(l10nKey, startingPitcher) : info.marketName;
        };
        const resolveMarketItem = partSelection => ({
            name: resolveMarketName(partSelection),
            type: partSelection.marketType,
        });
        const result = [
            { type: partInfo.marketType, name: resolveMarketName(partInfo) },
            ...alternativeSelections.map(resolveMarketItem),
        ];
        const compareByType = byMarketTypeComparator(marketNamesMap);
        return result.sort(isEmpty(marketNamesMap) ? compareByName : compareByType);
    }

    /**
     * Resolve market and handicap label as a string.
     *
     * @param {Mojito.Services.Betslip.types.Part} betPart - Betslip bet part.
     * @param {object} l10n - Translation map.
     *
     * @returns {string} Trimmed name and handicap string.
     * @function Mojito.Modules.Betslip.utils.resolveNameAndHandicapLabel
     */
    static resolveNameAndHandicapLabel(betPart, l10n) {
        const { name, handicapLabel } = MarketHelper.handleHandicap(
            betPart.selectionName,
            betPart.hcap
        );
        const handicapTeaserLabel = BettingHelper.formatTeaserHandicapText(
            betPart.hcapTeaser,
            l10n
        );
        const handicap = `${handicapLabel} ${handicapTeaserLabel}`.trim();
        if (!name) {
            return handicap;
        }

        return `${name} ${handicap}`.trim();
    }
}

export default BetslipModuleUtils;
