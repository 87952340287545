import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'presentation/components/spinner/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

export default function InfiniteList({
    fetchMore,
    hasMore,
    children,
    scrollableTarget,
    pendingStateComponent,
    mojitoTools: { config },
}) {
    const pendingState =
        pendingStateComponent === undefined ? genericPendingState(config) : pendingStateComponent;
    /* eslint-disable react/forbid-component-props */
    return (
        <InfiniteScroll
            dataLength={children.length}
            next={fetchMore}
            hasMore={hasMore}
            loader={pendingState}
            height={config.height}
            style={config.style}
            scrollThreshold={config.scrollThreshold}
            scrollableTarget={scrollableTarget}
        >
            {children}
        </InfiniteScroll>
    );
}

function genericPendingState(config) {
    return (
        <FlexPane class="ta-spinner-container" config={config.spinnerContainer}>
            <Spinner config={config.spinner} />
        </FlexPane>
    );
}
