import MojitoCore from 'mojito/core';
import ComponentErrorViewImpl from './component-error-view.jsx';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * Presents error of component rendering. So far only handles ChunkLoadError for components
 * imported using {@link https://reactjs.org/docs/code-splitting.html#reactlazy|React.lazy} function.
 *
 * @class ComponentErrorView
 * @memberof Mojito.Presentation.Components
 */

/**
 * ComponentErrorView prop types.
 *
 * @property {object} error - Error object.
 * @property {Function} resetErrorBoundary - Callback to reset the error boundary and retry the render.
 *
 * @memberof Mojito.Presentation.Components.ComponentErrorView
 */
const propTypes = {
    error: PropTypes.object.isRequired,
    resetErrorBoundary: PropTypes.func,
};

export default UIView('ComponentErrorView', ComponentErrorViewImpl, propTypes);
