import MojitoCore from 'mojito/core';
import QuickStartGuideBannerImpl from './quick-start-guide-banner.jsx';

const { UIControllerView } = MojitoCore.Presentation;
/**
 * Quick Start Guide banner is a component that can be enabled to display some text and button that will take new users to a dialog with
 * sportsbook guides.
 *
 * @class QuickStartGuideBanner
 * @name module
 * @memberof Mojito.Modules
 */

export default UIControllerView('QuickStartGuideBanner', QuickStartGuideBannerImpl);
