import ImageButtonImpl from './image-button.jsx';
import MojitoCore from 'mojito/core';
import { omit } from 'mojito/utils';
import { propTypes as buttonPropTypes } from 'presentation/components/button/index.jsx';
import PropTypes from 'prop-types';

const updatedButtonPropTypes = omit(buttonPropTypes, ['children']);
const UIView = MojitoCore.Presentation.UIView;

/**
 * A component that displays an image {@link Mojito.Presentation.Components.Image} within a button {@link Mojito.Presentation.Components.Button.component}. This component does not accept children.
 *
 * @class ImageButton
 * @memberof Mojito.Presentation.Components
 */

/**
 * `ImageButton` component prop types.
 *
 * {@link Mojito.Presentation.Components.Button.component.propTypes} Inherits the same propTypes as the Button component, excluding 'children'.
 *
 * @property {string} [label] - The label to be displayed for the ImageButton.
 *
 * @memberof Mojito.Presentation.Components.ImageButton
 */
const propTypes = {
    ...updatedButtonPropTypes,
    label: PropTypes.string,
};

export default UIView('ImageButton', ImageButtonImpl, propTypes);
