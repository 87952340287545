/**
 * Promotions types.
 *
 * @namespace types
 * @memberof Mojito.Services.Promotions
 */
/**
 * Promotion locations.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Promotions.types
 */
export const LOCATION = {
    /** Promotions located on top of the page. Typically presented as banners. */
    TOP: 'top',
    /** Promotions located in the right page area. Typically presented as banners. */
    RIGHT: 'right',
    /** Promotions located in the left page area. Typically presented as banners. */
    LEFT: 'left',
    /** Promotions located in the middle of the page or on side menu. Typically presented as text links. */
    QUICK_LINKS: 'quicklinks',
    /** Promotions located on top of the page. Typically presented as text links. Gives best user experience in mobile mode. */
    TOP_QUICK_LINKS: 'top_quicklinks',
    /** Promotions located on top of the page. Typically presented as {@link Mojito.Modules.EventCardsCarousel.module|EventCardsCarousel}. */
    EVENT_CARDS: 'event_cards',
    /** Prebuilt cards located on homepage and competitions pages. Typically presented as {@link Mojito.Modules.Promotions.PrebuiltPromotionCards}. */
    PREBUILT_CARDS: 'prebuilt_cards',
};

/**
 * Promotion channels.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Promotions.types
 */
export const CHANNELS = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    DESKTOP_AUTH: 'desktop_auth',
    MOBILE_AUTH: 'mobile_auth',
};

/**
 * Promotion target types.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Promotions.types
 */
export const TARGET_TYPE = {
    SPORT: 'SPORT',
    CLASS: 'CLASS',
    TYPE: 'TYPE',
    EVENT: 'EVENT',
    EXTERNAL_URL: 'EXTERNAL_URL',
};

/**
 * Promotion event status.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Promotions.types
 */
export const STATUS = {
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED',
    NOT_SHOWN: 'NOT_SHOWN',
    UNKNOWN: 'UNKNOWN',
};

/**
 * Participants position.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Promotions.types
 */
export const POSITION = {
    HOME: 'HOME',
    AWAY: 'AWAY',
};

/**
 * Promotion.
 *
 * @typedef Promotion
 * @type {object}
 *
 * @property {string} id - Promotion Id.
 * @property {string} imgUrl - Promotion image source url.
 * @property {Mojito.Services.Promotions.types.Target} target - Promotion navigation target.
 * @property {string} linkText - Link text.
 * @property {string[]} applicablePages - List of pages where promotion can be shown.
 * @property {number} displayOrder - Order of promotion to be shown.
 * @property {Mojito.Services.Promotions.types.PromotionEvent} event - Promotion event.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion event.
 *
 * @typedef PromotionEvent
 * @type {object}
 *
 * @property {string} id - Promotion event Id.
 * @property {string} eventType - Promotion event type.
 * @property {Mojito.Services.Promotions.types.PromotionMarket} market - Promotion event market.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion participant.
 *
 * @typedef Participant
 * @type {object}
 *
 * @property {string} id - Participant Id.
 * @property {string} name - Participant name.
 * @property {string} shortName - Participant short name.
 * @property {Mojito.Services.Promotions.types.POSITION} position - Participant position.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion stream details.
 *
 * @typedef PromotionStreamDetails
 * @type {object}
 *
 * @property {string} platform - Stream platform.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion market.
 *
 * @typedef PromotionMarket
 * @type {object}
 *
 * @property {string} marketId - Promotion market Id.
 * @property {string} marketName - Promotion market name.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion target.
 *
 * @typedef Target
 * @type {object}
 *
 * @property {Mojito.Services.Promotions.types.TARGET_TYPE} type - Promotion target type.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion external url target.
 *
 * @typedef {Mojito.Services.Promotions.types.Target} ExternalUrlTarget
 * @type {object}
 *
 * @property {string} url - Promotion external url source.
 * @property {boolean} openInNewTab - True if url should be opened in new tab.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion sport target.
 *
 * @typedef {Mojito.Services.Promotions.types.Target} SportTarget
 * @type {object}
 *
 * @property {string} sportId - Sport id.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion class target.
 *
 * @typedef {Mojito.Services.Promotions.types.SportTarget} ClassTarget
 * @type {object}
 *
 * @property {string} classId - Class id.
 * @property {boolean} isVirtual - Flag indicates if class contains virtual events.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion type target.
 *
 * @typedef {Mojito.Services.Promotions.types.ClassTarget} TypeTarget
 * @type {object}
 *
 * @property {string} typeId - Type id.
 * @property {string} meetingId - Meeting id.
 * @property {boolean} isVirtual - Flag indicates if type contains virtual events.
 *
 * @memberof Mojito.Services.Promotions.types
 */

/**
 * Promotion event target.
 *
 * @typedef {Mojito.Services.Promotions.types.TypeTarget} EventTarget
 * @type {object}
 *
 * @property {string} eventId - Event id.
 *
 * @memberof Mojito.Services.Promotions.types
 */
