import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import RaceMeetingPageImpl from './race-meeting-page.jsx';

/**
 * @namespace RaceMeetingPage
 * @memberof Mojito.Application.Pages
 */

/**
 * Page displaying a specific racing sport's race meeting.
 *
 * @class RaceMeetingPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Application.Pages.RaceMeetingPage
 */

export default UIPage('RaceMeetingPage', RaceMeetingPageImpl);
