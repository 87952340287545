import { useEffect, useMemo, useCallback, useRef, memo, useState } from 'react';
import MojitoCore from 'mojito/core';
import {
    useAutoPlay,
    usePointerHover,
    useLoopConfig,
    useLoopStart,
    useScrollSnapIgnore,
    useScroll,
    useVisibleSlides,
    useScrollInProgress,
    useTouchMove,
} from './hooks';
import SwiperUtils from './utils.js';
import ImageButton from 'presentation/components/image-button/index.jsx';
import AbsolutePane from 'presentation/components/absolute-pane/index.jsx';
import FlexPane from 'presentation/components/flex-pane';
import SwiperSlide from './slide/index.jsx';
import { isFunction } from 'mojito/utils';
import { ruleTemplates } from './swiper-style';

const { useCssRule } = MojitoCore.Presentation.Hooks;
const { useResizeDetector } = MojitoCore.Base.resizeDetector;
const classUtils = MojitoCore.Base.classUtils;

export default function Swiper(props) {
    const {
        children,
        hideNavigationArrows,
        onSlideChange,
        onPrepareSlidesToRender,
        mojitoTools: { config, style },
    } = props;

    const classUUID = useMemo(() => classUtils.createClassUUID(config.class), [config.class]);
    const cssVars = useMemo(() => getCssVariables(classUUID), [classUUID]);
    useCssRule(classUUID, cssVars, ruleTemplates);

    const containerRef = useRef();
    const { elementRef, element: containerElement, width: containerWidth } = useResizeDetector();

    const loopConfig = useLoopConfig(config, containerElement, children);
    const cursorHover = usePointerHover(containerRef.current);
    const [scrollingInProgress, setScrollingInProgress] = useScrollInProgress(containerElement);
    const [manuallyInteracted, setManuallyInteracted] = useState(false);

    const { prevSlideIndex, nextSlideIndex } = SwiperUtils.getSlideIndices(containerElement);
    const forwardClickAvailable = nextSlideIndex !== -1;
    const backClickAvailable = prevSlideIndex !== -1;

    const onTouch = useCallback(() => setManuallyInteracted(true), []);
    useTouchMove(containerElement, onTouch);

    const onBackClick = () => {
        const { prevSlideIndex } = SwiperUtils.getSlideIndices(containerElement);

        if (scrollingInProgress || prevSlideIndex === -1) {
            return;
        }
        setManuallyInteracted(true);
        setScrollingInProgress(true);
        const toBound = SwiperUtils.isMultiSlideView(containerElement) ? 'center' : 'left';
        SwiperUtils.scrollToChild(
            containerElement,
            prevSlideIndex,
            loopConfig,
            config.scrollBehavior,
            toBound
        );
    };

    const onForwardClick = () => {
        const { nextSlideIndex } = SwiperUtils.getSlideIndices(containerElement);

        if (scrollingInProgress || nextSlideIndex === -1) {
            return;
        }
        setManuallyInteracted(true);
        setScrollingInProgress(true);
        const toBound = SwiperUtils.isMultiSlideView(containerElement) ? 'center' : 'left';
        SwiperUtils.scrollToChild(
            containerElement,
            nextSlideIndex,
            loopConfig,
            config.scrollBehavior,
            toBound
        );
    };

    const showNavigation = cursorHover && config.enableNavigation && !hideNavigationArrows;

    return (
        <FlexPane class={'ta-Swiper-Container'} elementRef={containerRef} config={config.container}>
            <div
                ref={elementRef}
                className={classUtils.classNames('ta-Swiper', classUUID)}
                style={config.style}
            >
                {children?.length && (
                    <Slider
                        config={config}
                        container={containerElement}
                        containerWidth={containerWidth}
                        sliderStyle={style}
                        cursorHover={cursorHover}
                        manuallyInteracted={manuallyInteracted}
                        loopConfig={loopConfig}
                        onSlideChange={onSlideChange}
                        onPrepareSlidesToRender={onPrepareSlidesToRender}
                    >
                        {children}
                    </Slider>
                )}
            </div>
            {showNavigation && backClickAvailable && (
                <NavigationButton
                    onClick={onBackClick}
                    config={config.backwardNavigation}
                    class={'ta-Button-Backward'}
                />
            )}
            {showNavigation && forwardClickAvailable && (
                <NavigationButton
                    onClick={onForwardClick}
                    config={config.forwardNavigation}
                    class={'ta-Button-Forward'}
                />
            )}
        </FlexPane>
    );
}

// eslint-disable-next-line react/display-name
const Slider = memo(props => {
    const {
        children,
        sliderStyle,
        config,
        container,
        loopConfig,
        containerWidth,
        cursorHover,
        manuallyInteracted,
        onSlideChange,
        onPrepareSlidesToRender,
    } = props;
    const { lazyLoadBufferSize = -1 } = config;

    const currentSlide = SwiperUtils.getFirstVisibleSlide(container, loopConfig);
    const currentSlideId = SwiperUtils.parseSlideId(currentSlide?.id);
    useEffect(() => {
        !isNaN(currentSlideId) && onSlideChange(currentSlideId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSlideId]);

    const viewableSlideIds = useVisibleSlides(container);
    const prepareToShowUpSlideIds = useMemo(
        () =>
            SwiperUtils.enrichWithReadyToShowSlideIndexes(
                lazyLoadBufferSize,
                children.length,
                viewableSlideIds,
                !!loopConfig
            ),
        [lazyLoadBufferSize, children.length, viewableSlideIds, loopConfig]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onPrepareSlidesToRender(prepareToShowUpSlideIds), [prepareToShowUpSlideIds]);

    const firstSlideIndex = 0;
    const lastSlideIndex = children.length - 1;
    const centerSideSlides = !!loopConfig;
    const slideConfigMap = {
        [firstSlideIndex]: centerSideSlides ? sliderStyle.slide : sliderStyle.firstSlide,
        [lastSlideIndex]: centerSideSlides ? sliderStyle.slide : sliderStyle.lastSlide,
    };

    const slides = children.map((child, index) => {
        const slideConfig = slideConfigMap[index] || sliderStyle.slide;
        const slideId = SwiperUtils.createSlideId(index);
        const shouldPrepareToShowUp =
            prepareToShowUpSlideIds?.includes(index) || lazyLoadBufferSize === -1;
        return (
            <SwiperSlide id={slideId} config={slideConfig} key={slideId}>
                {isFunction(child) ? child(shouldPrepareToShowUp) : child}
            </SwiperSlide>
        );
    });

    return loopConfig ? (
        <LoopSlider
            config={config}
            loopConfig={loopConfig}
            container={container}
            containerWidth={containerWidth}
            cursorHover={cursorHover}
            manuallyInteracted={manuallyInteracted}
        >
            {slides}
        </LoopSlider>
    ) : (
        slides
    );
});

const LoopSlider = props => {
    const {
        config,
        cursorHover,
        manuallyInteracted,
        loopConfig,
        container,
        containerWidth,
        children,
    } = props;

    // To support loop we will clone few slides from head and from tail and append them from both sides, we call it loop buffer.
    // This gives user an illusion that he is scrolling a carousel.
    const loopedSlides = useMemo(
        () => SwiperUtils.ensureLoopBuffer(children, loopConfig),
        [children, loopConfig]
    );

    // On loop init we will scroll to the first origin slide, this is needed because we have loop buffer from left and right of our slides.
    useLoopStart(container, loopConfig);
    // Allow to set container scrollSnapType to none and then restore it to back to config.style.scrollSnapType.
    // This is needed to avoid scrolling glitch when we are doing it programmatically with scrollToSlide function once loop should be restored.
    const ignoreScrollSnap = useScrollSnapIgnore(container, config);

    // This will start autoplay for slides switching if config.autoPlay is true.
    const [autoPlayStart, autoPlayStop] = useAutoPlay(config, container, loopConfig);

    useEffect(() => {
        const shouldBlockOnInteraction = config.disableAutoPlayOnInteraction && manuallyInteracted;
        const shouldStart = !cursorHover && !shouldBlockOnInteraction;
        const shouldStop = shouldBlockOnInteraction || cursorHover;
        if (shouldStart) {
            autoPlayStart();
        } else if (shouldStop) {
            autoPlayStop();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manuallyInteracted, config.disableAutoPlayOnInteraction, cursorHover]);

    // This is used to emulate loop for swiper with only one slide fully visible in a view e.g. promotional banners' swiper.
    const onSingleSlideScroll = useCallback(() => {
        const preLastChildOffsetCenter = SwiperUtils.getChildOffsetCenter(
            container,
            loopedSlides.length - 2
        );
        const secondChildOffsetCenter = SwiperUtils.getChildOffsetCenter(container, 1);
        const containerOffsetCenter = Math.round(container.scrollLeft + containerWidth / 2);
        if (preLastChildOffsetCenter <= containerOffsetCenter) {
            ignoreScrollSnap(() => SwiperUtils.scrollToSlide(container, 0, loopConfig));
        } else if (secondChildOffsetCenter >= containerOffsetCenter) {
            ignoreScrollSnap(() =>
                SwiperUtils.scrollToSlide(container, children.length - 1, loopConfig)
            );
        }
    }, [container, loopedSlides, loopConfig, children.length, containerWidth, ignoreScrollSnap]);

    // This is used to emulate loop for swiper with multiple slides fully visible in a view in the same time. Will be use case for event cards carousel if we turn on loop for it.
    const onMultiSlideScroll = useCallback(() => {
        const firstSlideOffsetLeft = SwiperUtils.getSlideOffsetLeft(container, 0, loopConfig);
        const lastChildIndex = children.length - 1;
        const lastSlideOffsetRight = SwiperUtils.getSlideOffsetRight(
            container,
            lastChildIndex,
            loopConfig
        );
        const containerOffsetLeft = container.scrollLeft;
        if (lastSlideOffsetRight < containerOffsetLeft) {
            SwiperUtils.scrollToSlide(container, 0, loopConfig);
        } else if (firstSlideOffsetLeft - containerOffsetLeft >= containerWidth) {
            const slideIndex = children.length - loopConfig.bufferLength;
            SwiperUtils.scrollToSlide(container, slideIndex, loopConfig);
        }
    }, [container, children.length, loopConfig, containerWidth]);

    const onScroll = loopConfig.isMultiSlideView ? onMultiSlideScroll : onSingleSlideScroll;

    // Re-evaluate scroll loop on children length change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onScroll(), [children.length]);

    // We will attach onScroll listener to catch the moment when user reached the end or the beginning of the items list,
    // and we need to silently change scroll position, so it look like user is on the same element but the whole list is upfront.
    // This is the main visual tick that gives user illusion that there is a loop.
    useScroll(container, onScroll);

    return loopedSlides;
};

const NavigationButton = ({ config, class: className, onClick }) => {
    return (
        <AbsolutePane config={config.container}>
            <ImageButton onClick={onClick} config={config.button} class={className} />
        </AbsolutePane>
    );
};

Swiper.getStyle = (config, applicationMode, merge) => {
    const applySnapAlign = align => merge(config.slide, { style: { scrollSnapAlign: align } });
    return {
        slide: config.slide,
        firstSlide: applySnapAlign('start'),
        lastSlide: applySnapAlign('end'),
    };
};

function getCssVariables(classUUID) {
    return {
        '%class%': classUUID,
    };
}
