import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { connect } from 'react-redux';

const metaInformationDataDescriptor = MojitoServices.MetaInformation.descriptor;
const { META_INFORMATION_TYPES } = MojitoServices.MetaInformation.types;
const { LIVE_MATCH_EVENTS, LIVE_OUTRIGHT_EVENTS } = META_INFORMATION_TYPES;

const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { FallbackEventCard } = MojitoPresentation.Components.EventCards;

const { selectors: metaInformationSelectors } = MojitoServices.MetaInformation;

const { selectMetaInformation } = metaInformationSelectors;

class InplayFallbackEventCard extends MojitoCore.Presentation.UIViewImplementation {
    constructor(...args) {
        super(...args);
    }

    componentDidMount() {
        this.controllerHelper().requestData(
            metaInformationDataDescriptor.createMetaInformationDescriptor(LIVE_MATCH_EVENTS)
        );

        this.controllerHelper().requestData(
            metaInformationDataDescriptor.createMetaInformationDescriptor(LIVE_OUTRIGHT_EVENTS)
        );
    }

    render() {
        return (
            <FallbackEventCard
                config={this.config.view}
                headerTitle={this.resolveString(this.config.title)}
                contentText={this.resolveAndFormatString(
                    this.config.content,
                    this.props.totalInplayEvents
                )}
                onClick={this.config.onClick || (() => IntentActionTrigger.showInPlay())}
            />
        );
    }
}

const mapStateToProps = state => {
    const liveMatches = selectMetaInformation(LIVE_MATCH_EVENTS, state);
    const liveOutright = selectMetaInformation(LIVE_OUTRIGHT_EVENTS, state);
    return { totalInplayEvents: parseInt(liveMatches) + parseInt(liveOutright) };
};
export default connect(mapStateToProps)(InplayFallbackEventCard);
