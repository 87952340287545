import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import { isLoggedIn } from 'services/authentication/selectors.js';
import { selectChannel as selectApplicationChannel } from 'services/bootstrap/selectors.js';
import { createSelector } from '@reduxjs/toolkit';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;
const arraysAreEqual = MojitoCore.Base.objUtils.arraysAreEqual;
const { UNKNOWN } = ServicesTypes.CONTENT_STATE;

/**
 * Promotions related selectors.
 *
 * @module PromotionsSelectors
 * @name selectors
 * @memberof Mojito.Services.Promotions
 */

/**
 * Selects promotion state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.Promotions.PromotionsState} Promotions state.
 * @memberof Mojito.Services.Promotions.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Select channel (desktop|mobile|desktop_auth|mobile_auth) value for polling and websocket connection to define a collection of promotions to fetch from.
 *
 * @function selectChannel
 *
 * @param {object} [state] - Application state object.
 * @returns {string}  Promotion channel name.
 * @memberof Mojito.Services.Promotions.selectors
 */
export const selectChannel = state => {
    const appMode = selectApplicationChannel(state).toLowerCase();
    return isLoggedIn(state) ? `${appMode}_auth` : appMode;
};

/**
 * Selects promotions loading state.
 *
 * @function selectPromotionState
 *
 * @param {string} locationId - Location id.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.Common.types.CONTENT_STATE} Promotions loading state.
 * @memberof Mojito.Services.Promotions.selectors
 */
export const selectPromotionState = (locationId, state) => {
    return selectState(state)?.promotionsLoadingState[locationId] || UNKNOWN;
};

/**
 * Checks if the provided channel is in the state. Hence, considered to be the active one.
 *
 * @function isActiveChannel
 *
 * @param {Mojito.Services.Promotions.types.CHANNELS} channel - Channel name.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} Returns `true` if the channel is available in the store, `false` otherwise.
 * @memberof Mojito.Services.Promotions.selectors
 */
export const isActiveChannel = (channel, state) => {
    return selectState(state)?.promotionsIdToChannelMap.hasOwnProperty(channel);
};

/**
 * Selects promotions.
 *
 * @callback PromotionsSelector
 *
 * @param {string} locationId - Location id.
 * @param {string} page - Page name.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Array<object>} Promotions list.
 * @memberof Mojito.Services.Promotions.selectors
 */

const selectPromotionsByLocation = (locationId, state) => {
    return selectState(state)?.promotionsByLocations[locationId];
};

/**
 * Selector creator function. Will create memoized selector based on {@link Mojito.Services.Promotions.selectors.PromotionsSelector|PromotionsSelector} to fetch promotions location ids.
 * The created selector guarantees that the same object instance will be returned on each call if no input params changed.
 * Note: when the selector is used in multiple component instances and depends on the component's props, you need to ensure that each component instance gets its own selector instance.
 *
 * @function makeSelectPromotions
 *
 * @returns {Function} Selector function.
 * @memberof Mojito.Services.Promotions.selectors
 */
export const makeSelectPromotions = () =>
    createSelector(
        [
            (locationId, page, state) => selectPromotionsByLocation(locationId, state),
            (locationId, page) => page,
        ],
        (promotions, page) => {
            return (promotions || [])
                .filter(promotion => !page || promotion.applicablePages?.includes(page))
                .sort((a, b) => {
                    const nameA = a.name || '';
                    const nameB = b.name || '';
                    return nameA.localeCompare(nameB);
                })
                .sort((a, b) => a.displayOrder - b.displayOrder);
        },
        {
            memoizeOptions: { resultEqualityCheck: arraysAreEqual },
        }
    );
