import PropTypes from 'prop-types';
import SessionTimeImpl from './sessiontime.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View to display how long a user has been logged in for current session.
 *
 * @class SessionTime
 * @memberof Mojito.Presentation.Components
 */

/**
 * Prop types for the SessionTime component.
 *
 * @property {string} loginTime - The time when the user logged in.
 *
 * @memberof Mojito.Presentation.Components.SessionTime
 */
const propTypes = {
    loginTime: PropTypes.string.isRequired,
};

export default UIView('SessionTime', SessionTimeImpl, propTypes);
