import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';

const reduxInstance = MojitoCore.Services.redux;

/**
 * Defines the state of the PDF store.
 *
 * @typedef PDFState
 *
 * @property {boolean} isFetching - Status for if a pdf is being fetched or not.
 * @property {boolean} error - Status for if an error has occurred when a trying to fetch a pdf.
 *
 *
 * @memberof Mojito.Services.PDF
 */

/**
 * The name of the PDF state property. Will be used to register in global redux store.
 * This state holds information about PDF details.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.PDF
 */
export const STORE_KEY = 'PDFStore';

/**
 * PDF initial state.
 *
 * @typedef INITIAL_STATE
 * @memberof Mojito.Services.PDF
 */
export const INITIAL_STATE = {
    isFetchingPdf: false,
    error: false,
};

export const { reducer, actions } = createSlice({
    name: 'PDF',
    initialState: INITIAL_STATE,
    reducers: {
        updateIsFetching(state, { payload: isFetchingPdf }) {
            state.isFetchingPdf = isFetchingPdf;
        },
        updateError(state, { payload: hasError }) {
            state.error = hasError;
        },
        reset() {
            return INITIAL_STATE;
        },
    },
});

/**
 * Set the status for if a pdf is being fetched or not.
 *
 * @function updateIsFetching
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {boolean} isFetchingPDF - Indicates pdf fetching status.
 *
 * @memberof Mojito.Services.PDF.actions
 */

/**
 * Set the status for if an error has occured when a trying to fetch a pdf.
 *
 * @function updateError
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {boolean} hasError - Indicates whether an error occurred during fetch.
 *
 * @memberof Mojito.Services.PDF.actions
 */

/**
 * Reset PDF state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.PDF.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);
