import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';
import UserAuthenticationInfo from 'application/components/applicationheader/user-authentication-info/index.jsx';
import SystemInitialisation from 'application/components/applicationheader/system-initialisation/index.jsx';
import SearchOverlay from 'application/components/search/search-overlay/index.jsx';

const { CrossSellMenu } = MojitoModules;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { Button, FlexPane, Image } = MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { UIViewImplementation } = MojitoCore.Presentation;

const LAYOUT = Object.freeze({
    SINGLE_ROW: 'single-row',
    MULTI_ROW: 'multi-row',
});

export default class ApplicationMenu extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.onLogoClick = this.onLogoClick.bind(this);
        this.onShowSearchClick = this.onShowSearchClick.bind(this);
        this.onSearchCloseClick = this.onSearchCloseClick.bind(this);

        this.state = {
            isSearchOpen: false,
        };
    }

    onLogoClick() {
        this.emitAnalytics('logoClicked');
        IntentActionTrigger.showHomePage();
    }

    isUsingLayout(layout) {
        return layout === this.config.layout;
    }

    renderSystemInitialisation(layout) {
        if (!this.isUsingLayout(layout) || !this.config.showSystemInitialisation) {
            return null;
        }

        return <SystemInitialisation config={this.config.systemInitialisation} />;
    }

    renderCrossSellMenu(layout) {
        if (!this.isUsingLayout(layout) || !this.config.showCrossSellMenu) {
            return null;
        }

        const config =
            layout === LAYOUT.SINGLE_ROW ? this.config.singleRowLayout : this.config.multiRowLayout;

        return <CrossSellMenu config={config.crossSellMenu} />;
    }

    renderRightColumn() {
        const authInfoElement = (
            <>
                <UserAuthenticationInfo
                    userInfo={this.props.userInfo}
                    freeBets={this.props.freeBets}
                />
                {this.config.showSearch && (
                    <Button
                        config={this.config.searchButton}
                        onClick={this.onShowSearchClick}
                        class="ta-SearchButton"
                    >
                        <Image config={this.config.searchIcon} />
                    </Button>
                )}
            </>
        );
        const columnConfig = this.config.showCompactLayout ? 'rightColumnCompact' : 'rightColumn';
        return <FlexPane config={this.config[columnConfig]}>{authInfoElement}</FlexPane>;
    }

    getHomePagePath() {
        const routeResolver = this.appContext().routeResolver;
        return routeResolver.getRoute(NavigationPathBuilder.toHome());
    }

    renderLogoButton() {
        return (
            <Button
                onClick={this.onLogoClick}
                hrefLink={this.getHomePagePath()}
                class="ta-logo"
                config={this.config.logoButton}
            >
                <Image config={this.config.logo} />
            </Button>
        );
    }

    renderLogo() {
        if (this.config.showCompactLayout) {
            return this.renderLogoButton();
        }

        return <FlexPane config={this.style.leftColumn}>{this.renderLogoButton()}</FlexPane>;
    }

    onShowSearchClick() {
        this.setState({ isSearchOpen: true });
    }

    onSearchCloseClick() {
        this.setState({ isSearchOpen: false });
    }

    renderSearchOverlay() {
        return this.state.isSearchOpen ? (
            <SearchOverlay
                isOpen={this.state.isSearchOpen}
                onSearchClose={this.onSearchCloseClick}
            />
        ) : null;
    }

    render() {
        return (
            <div style={this.style.container} className="ApplicationMenu">
                <div style={this.style.innerContainer}>
                    {this.renderLogo()}
                    {this.renderCrossSellMenu(LAYOUT.SINGLE_ROW)}
                    {this.renderSystemInitialisation(LAYOUT.MULTI_ROW)}
                    {this.renderSearchOverlay()}
                    {this.renderRightColumn()}
                </div>
                {this.renderCrossSellMenu(LAYOUT.MULTI_ROW)}
            </div>
        );
    }
}

ApplicationMenu.getStyle = function (config) {
    const { container, innerContainer, sideColumns } = config.style;

    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
            flexDirection: 'column',
            ...container,
        },
        innerContainer: {
            flexShrink: 0,
            flexGrow: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ...innerContainer,
        },
        leftColumn: {
            style: {
                flexShrink: 1,
                flexGrow: 0,
                flexBasis: sideColumns.flexBasis,
            },
        },
        rightColumn: {
            style: {
                flexShrink: 1,
                flexGrow: 0,
                flexBasis: sideColumns.flexBasis,
                justifyContent: 'flex-end',
            },
        },
    };
};
