import PropTypes from 'prop-types';
import PrebuiltPromotionCards from './prebuilt-promotion-cards.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Module that displays promotions as prebuilt promotion cards.
 * Promotions are retrieved from promotions store using locationId config property and applicablePage component prop.
 * Promotion cards will be displayed in carousel.
 *
 * @class PrebuiltPromotionCards
 * @memberof Mojito.Modules.Promotions
 */

/**
 * PrebuiltPromotionCards prop types.
 *
 * @property {string} applicablePage - The id of the applicable page where event cards will be shown. Example: 'homepage', 'tennis', 'basketball'.
 * @memberof Mojito.Modules.Promotions.PrebuiltPromotionCards
 */

const propTypes = {
    applicablePage: PropTypes.string.isRequired,
};

export default UIView('PrebuiltPromotionCards', PrebuiltPromotionCards, propTypes);
