import MojitoCore from 'mojito/core';
import { createSlice } from '@reduxjs/toolkit';
import containerProvider from 'services/sports-content/content-provider/container-provider';
import SportsDataDescriptor from './descriptor.js';
import channelFactory from 'services/common/content/content-channel-factory.js';

const reduxInstance = MojitoCore.Services.redux;

const { SPORTS } = SportsDataDescriptor.DATA_TYPES;
const { actionsRegistry } = MojitoCore.Services.Content;

export const getSportListChannel = () => channelFactory.getChannel(containerProvider, SPORTS);

/**
 * Defines the state of the sports store.
 *
 * @typedef SportsState
 *
 * @property {Array<object>} sports - List of sports.
 *
 * @memberof Mojito.Services.SportsContent.Sports
 */

/**
 * The name of the sports store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.SportsContent.Sports
 */
export const STORE_KEY = 'sportsStore';

export const INITIAL_STATE = { sports: [] };

export const { reducer, actions } = createSlice({
    name: 'sports',
    initialState: INITIAL_STATE,
    reducers: {
        updateSports(state, { payload }) {
            state.sports = payload.sports;
        },
        removeSports(state) {
            state.sports = [];
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Sports store actions.
 *
 * @class SportActions
 * @name actions
 * @memberof Mojito.Services.SportsContent.Sports
 */

/**
 * Sports list update.
 *
 * @function updateSports
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{sports: Array<object>}} payload - Payload contains <code>sports</code> list.
 * @memberof Mojito.Services.SportsContent.Sports.actions
 */

/**
 * Sports list remove.
 *
 * @function removeSports
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.Sports.actions
 */

/**
 * Reset sports state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.SportsContent.Sports.actions
 */

/**
 * Subscribes to sports list.
 *
 * @function subscribe
 *
 * @param {{listId: string, clientId: number}} payload - Subscription payload. Contains listId of sports list to subscribe to and the id of subscriber.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to sports thunk.
 * @memberof Mojito.Services.SportsContent.Sports.actions
 */
actions.subscribe = payload => dispatch => {
    const { listId, clientId } = payload;
    getSportListChannel().subscribe([listId], clientId, (id, data = {}) => {
        dispatch(actions.updateSports({ sports: data.items }));
    });
};

/**
 * Unsubscribe from sports list.
 *
 * @function unsubscribe
 *
 * @param {number} clientId - Id of subscriber which is aiming to unsubscribe.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from sports thunk.
 * @memberof Mojito.Services.SportsContent.Sports.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getSportListChannel().unsubscribeAll(clientId, () => {
        dispatch(actions.removeSports());
    });
};

// Register thunks in common registry to use them by request data helper.
actionsRegistry.addSubscription(SPORTS, actions.subscribe, actions.unsubscribe);
reduxInstance.injectReducer(STORE_KEY, reducer);
