/**
 * Services layer common types.
 *
 * @class ServicesTypes
 * @name types
 * @memberof Mojito.Services.Common
 */
export default class ServicesTypes {}

/**
 * Enum for the communication channels from which a Mojito API request can originate.
 * For instance, a request might come from either a mobile or desktop device.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Common.types
 */
ServicesTypes.CHANNEL = {
    /** Mobile channel. Application is operating in mobile mode. */
    MOBILE: 'MOBILE',
    /** Desktop channel.  Application is operating in desktop mode. */
    DESKTOP: 'DESKTOP',
    /** Native channel.  Application is operating in native mode. */
    NATIVE: 'NATIVE',
    /** Retail channel. Application is operating as retail terminal. */
    RETAIL: 'RETAIL',
};

/**
 * Mojito services initialisation status.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Common.types
 */
ServicesTypes.INIT_STATUS = {
    NOT_INITIALISED: 'NOT_INITIALISED',
    INITIALISED: 'INITIALISED',
    FAILED: 'FAILED',
};

/**
 * Defines loading state and existence of a content in a store.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.Common.types
 */
ServicesTypes.CONTENT_STATE = {
    /** Content was never requested, the availability is unknown. Or it was requested but then unsubscribed, hence current availability is unknown. */
    UNKNOWN: 'UNKNOWN',
    /** Content was requested and is waiting for response. */
    PENDING: 'PENDING',
    /** Content was requested and valid object arrived from backend. */
    AVAILABLE: 'AVAILABLE',
    /** Content was requested but response from backend doesn't contain any valid object. */
    UNAVAILABLE: 'UNAVAILABLE',
};

/**
 * Mojito transactional API config object.
 *
 * @typedef TransactionalAPIConfig
 * @type {object}
 * @property {string} locale - Two letters ISO 639-1 local code.
 * @property {Mojito.Services.Common.types.CHANNEL} channel - Communication channel.
 *
 * @memberof Mojito.Services.Common.types
 */
