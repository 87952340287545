/**
 * Video streaming types.
 *
 * @class VideoStreamingTypes
 * @name types
 * @memberof Mojito.Services.VideoStreaming
 */

/**
 * List of providers.
 *
 * @typedef STREAM_PROVIDER
 * @property {string} IMG - IMG streaming provider.
 * @property {string} BETER - BETER streaming provider.
 * @property {string} PERFORM_MCC - PERFORM MCC streaming provider.
 * @memberof Mojito.Services.VideoStreaming.types
 */
export const STREAM_PROVIDER = {
    IMG: 'IMG',
    BETER: 'BETER',
    PERFORM_MCC: 'PERFORMMCC_S',
};

/**
 * Error codes.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.VideoStreaming.types
 */
export const ERROR_CODE = {
    USER_NOT_LOGGED_IN: 'USER_NOT_LOGGED_IN',
    USER_NOT_PLACED_BET: 'USER_NOT_PLACED_BET',
    USER_NOT_PLACED_BET_WITHIN_INTERVAL: 'USER_NOT_PLACED_BET_WITHIN_INTERVAL',
    USER_NOT_PLACE_BET_WITH_ENOUGH_STAKE: 'USER_NOT_PLACE_BET_WITH_ENOUGH_STAKE',
    UNSPECIFIED_UNMET_RESTRICTION: 'UNSPECIFIED_UNMET_RESTRICTION',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    INVALID_CONFIGURATION: 'INVALID_CONFIGURATION',
    UNAUTHORIZED: 'UNAUTHORIZED',
    EVENT_NOT_STARTED: 'EVENT_NOT_STARTED',
    EVENT_OVER: 'EVENT_OVER',
    EVENT_DOESNT_EXIST: 'EVENT_DOESNT_EXIST',
    MEETING_NOT_FOUND: 'MEETING_NOT_FOUND',
    GEO_BLOCKED: 'GEO_BLOCKED',
    FAIR_USE_BREACH: 'FAIR_USE_BREACH',
    STREAM_NOT_FOUND: 'STREAM_NOT_FOUND',
    NO_STREAM: 'NO_STREAM',
    OUTSIDE_SHOP: 'OUTSIDE_SHOP',
    GENERIC_ERROR: 'GENERIC_ERROR',
    UNKNOWN: 'UNKNOWN',
};

/**
 * Video stream restriction error codes.
 *
 * @typedef RESTRICTION_ERROR_CODES
 * @memberof Mojito.Services.VideoStreaming.types
 */
export const RESTRICTION_ERROR_CODES = [
    ERROR_CODE.USER_NOT_PLACED_BET,
    ERROR_CODE.USER_NOT_PLACED_BET_WITHIN_INTERVAL,
    ERROR_CODE.USER_NOT_PLACE_BET_WITH_ENOUGH_STAKE,
    ERROR_CODE.INSUFFICIENT_FUNDS,
    ERROR_CODE.UNSPECIFIED_UNMET_RESTRICTION,
];

/**
 * Video stream states.
 *
 * @typedef STREAM_STATE
 * @property {string} UNAVAILABLE - Stream is unavailable.
 * @property {string} LOADING - Stream is loading.
 * @property {string} READY - Stream is loaded and ready to by played.
 * @property {string} PLAYING - Stream is playing.
 * @property {string} STOPPED - Stream is stopped.
 * @property {string} ENDED - Stream is ended.
 * @memberof Mojito.Services.VideoStreaming.types
 */
export const STREAM_STATE = {
    UNAVAILABLE: 'UNAVAILABLE',
    LOADING: 'LOADING',
    READY: 'READY',
    PLAYING: 'PLAYING',
    STOPPED: 'STOPPED',
    ENDED: 'ENDED',
};

/**
 * Get video streaming request payload.
 *
 * @typedef VideoStreamingRequest
 * @type {object}
 * @property {string} eventId - Event id.
 * @property {string} streamId - Stream id.
 * @property {string} provider - Video streaming provider name.
 * @property {string} userId - Identifier of the user. Typically required by streaming provider API.
 *
 * @memberof Mojito.Services.VideoStreaming.types
 */

/**
 * Video streaming data. Contains information needed to start video streaming playback.
 *
 * @typedef VideoStreamingData
 * @type {object}
 * @property {Mojito.Services.VideoStreaming.types.StreamInfo} streamInfo - Information needed to start video streaming playback.
 * @property {Mojito.Services.VideoStreaming.types.VideoRestrictions} videoRestrictions - Video playback restrictions.
 * @property {Array<Mojito.Services.VideoStreaming.types.Error>} errors - Errors that can happen during streaming request.
 * @property {string} userId - Identifier of the user. Typically required by streaming provider API.
 * @property {string} eventId - Event id that stream belongs to.
 * @property {string} provider - Video streaming provider name.
 *
 * @memberof Mojito.Services.VideoStreaming.types
 */

/**
 * Stream information.
 *
 * @typedef StreamInfo
 * @type {object}
 * @property {string} streamUrl - Stream URL ready to be used in video player. Note: Will be <code>undefined</code> if requestUrl is present.
 * @property {string} requestUrl - Request stream URL. Can be supplied by some providers that require direct client to provider negotiation.
 * Typically used to request streamUrl directly from client code. Applicable for providers like IMG, PerformMCC, UNAS etc.
 * Note: Will be <code>undefined</code> if streamUrl is present.
 * @property {string} streamId - The livestream asset ID. Required to be set as <code>data-streamuuid</code> attribute on the script tag for the RMG integration.
 * @property {string} fixtureId - The ID for the chosen fixture. Required to be set as <code>data-uuid</code> attribute on the script tag for the RMG integration.
 * @property {string} outletKey - The outlet authentication key. Required to be set as <code>data-outletkey</code> attribute on the script tag for the RMG integration.
 * @property {string} accessToken - The OAuth access token. Required to be set as <code>data-oauthtoken</code> attribute on the script tag for the RMG integration.
 * @property {string} userId - The ID of the user. Required to be set as <code>data-streamuser</code> attribute on the script tag for the RMG integration.
 *
 * @memberof Mojito.Services.VideoStreaming.types
 */

/**
 * Video streaming error.
 *
 * @typedef Error
 * @type {object}
 * @property {string} code - Error code.
 * @property {*} factor - Additional information. If this is relevant for the corresponding error code,
 * it can be a value that was breached by streaming restriction and lead to an error.
 * For example, this could be the minimal stake to be placed, the number of hours passed from the last bet placement, etc.
 *
 * @memberof Mojito.Services.VideoStreaming.types
 */
