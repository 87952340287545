import MojitoNGen from 'mojito/ngen';

const logger = MojitoNGen.logger;
const log = logger.get('ExecutionMode');

/**
 * Supported application execution modes configured by application in runtime.
 *
 * @property {string} DEBUG - Debug execution mode. Debug code that may influence the performance will be active.
 * @property {string} RELEASE - Release execution mode (default). All debug code is turned off.
 */
export const EXECUTION_MODES = {
    DEBUG: 'debug',
    RELEASE: 'release',
};

/**
 * @class ExecutionMode
 * @private
 * @memberof Mojito.Core.Base
 */
class ExecutionMode {
    constructor() {
        this._executionMode = EXECUTION_MODES.RELEASE;
    }

    /**
     * Sets the global application's execution mode.
     *
     * @param {string} executionMode - The desired operation mode for the application.
     * @function Mojito.Core.Base.ExecutionMode#setExecutionMode
     */
    setExecutionMode(executionMode) {
        if (!Object.values(EXECUTION_MODES).includes(executionMode)) {
            log.warn('Unknown execution mode: ', executionMode);
            return;
        }

        this._executionMode = executionMode;
    }

    /**
     * Returns the global application execution mode.
     *
     * @returns {string} Global application execution mode.
     * @function Mojito.Core.Base.ExecutionMode#getExecutionMode
     */
    getExecutionMode() {
        return this._executionMode;
    }

    /**
     * Returns true if currently executing in debug mode.
     *
     * @returns {boolean} True if debug mode, otherwise false.
     * @function Mojito.Core.Base.ExecutionMode#isDebugMode
     */
    isDebugMode() {
        return this._executionMode === EXECUTION_MODES.DEBUG;
    }
}

export default new ExecutionMode();
