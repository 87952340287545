/**
 * @class LeaguesTypes
 * @memberof Mojito.Modules
 */
export default class LeaguesTypes {}

/**
 * Types of leagues.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.LeaguesTypes
 */
LeaguesTypes.TYPE = {
    ALL: 'all',
    TOP: 'top',
};

LeaguesTypes.GROUP_ID = {
    TOP_LEAGUES: '-top-leagues',
    TOP_OUTRIGHTS: '-top-outrights',
};
