import PropTypes from 'prop-types';
import { noop } from 'mojito/utils';
import SearchInputImpl from './search-input.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Search input view for inputting and triggering data searches.
 *
 * @class SearchInput
 * @memberof Mojito.Presentation.Components
 */

/**
 * SearchInput prop types.
 *
 * @property {string} [placeholderValue] - Placeholder text shown when input value is empty.
 * @property {string} [default = ''] - Default initial text.
 * @property {boolean} [showSpinner = false] - Show the spinner instead of the search icon.
 * @property {boolean} [initiallyActive = false] - Whether the component should be initially active.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onFocus = noop] - Callback when the component gets focus.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onChange = noop] - Callback when input changes.
 * @property {Mojito.Presentation.Components.TextInput.changeCallback} [onKeyEnter = noop] - Callback for the onKeyEnter event.
 * @memberof Mojito.Presentation.Components.SearchInput
 */
const propTypes = {
    placeholderValue: PropTypes.string,
    default: PropTypes.string,
    showSpinner: PropTypes.bool,
    initiallyActive: PropTypes.bool,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onKeyEnter: PropTypes.func,
};

const defaultProps = {
    default: '',
    showSpinner: false,
    initiallyActive: false,
    onFocus: noop,
    onChange: noop,
    onKeyEnter: noop,
};

export default UIView('SearchInput', SearchInputImpl, propTypes, defaultProps);
