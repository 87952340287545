import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import QuickStartGuideSkeleton from './skeleton/index.jsx';
import MojitoServices from 'mojito/services';

const intentActions = MojitoCore.Intents.actions;
const { FlexPane, ImageButton, Text, Button, LoaderSuspense } = MojitoPresentation.Components;
const {
    types: UserSettingsTypes,
    selectors: userSettingsSelectors,
    actions: userSettingsActions,
} = MojitoServices.UserSettings;
const { selectOnboarding } = userSettingsSelectors;
const { QUICK_START_GUIDE_BANNER } = UserSettingsTypes.ONBOARDING_TYPES;

export default function QuickStartGuideBanner({
    mojitoTools: { appContext, config, stringResolver },
}) {
    const dispatch = useDispatch();
    const [isSkeletonHidden, setSkeletonHidden] = useState(false);
    const { analyticsEmitter } = appContext;
    const showQuickStartGuideBannerOnboarding = useSelector(
        state => !selectOnboarding(state)[QUICK_START_GUIDE_BANNER]?.passed
    );
    const updateOnBoardingProgress = useCallback(() => {
        dispatch(
            userSettingsActions.updateOnboardingProgress({
                [QUICK_START_GUIDE_BANNER]: { passed: true },
            })
        );
    }, [dispatch]);

    const onOpenGuideClicked = useCallback(() => {
        updateOnBoardingProgress();
        dispatch(
            intentActions.publishIntent(config.openGuideIntent.type, config.openGuideIntent.data)
        );
        analyticsEmitter.emitAnalytics('quickStartGuideOpenedFromBanner');
    }, [
        config.openGuideIntent.data,
        config.openGuideIntent.type,
        analyticsEmitter,
        dispatch,
        updateOnBoardingProgress,
    ]);

    const hideBanner = () => {
        updateOnBoardingProgress();
        analyticsEmitter.emitAnalytics('quickStartGuideBannerClosed');
    };

    useEffect(() => setSkeletonHidden(true), []);

    if (!showQuickStartGuideBannerOnboarding) {
        return null;
    }

    return (
        <LoaderSuspense
            isContentPending={!isSkeletonHidden}
            config={config.skeletonLoader}
            loader={<QuickStartGuideSkeleton />}
        >
            <FlexPane class="ta-quickStartGuideBanner" config={config.container}>
                <ImageButton
                    class="ta-closeBanner"
                    config={config.closeButton}
                    onClick={hideBanner}
                />
                <Text class="ta-textContent" config={config.text}>
                    {stringResolver.resolveString('$QUICK_START_GUIDE_BANNER.TEXT')}
                </Text>
                <Button
                    class="ta-openGuide"
                    config={config.openGuideButton}
                    onClick={onOpenGuideClicked}
                >
                    {stringResolver.resolveString('$QUICK_START_GUIDE_BANNER.BUTTON_TEXT')}
                </Button>
            </FlexPane>
        </LoaderSuspense>
    );
}
