import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MojitoCore from 'mojito/core';

const StringUtils = MojitoCore.Base.StringUtils;
const { arraysAreEqual } = MojitoCore.Base.objUtils;

/**
 * Presentation related hooks.
 *
 * @name Hooks
 * @memberof Mojito.Presentation
 */

/**
 * Hook utilises setTimeout usage.
 *
 * @function useTimeout
 *
 * @param {number} delay - Number of milliseconds to delay. If set to -1 the timeout will be resolved immediately.
 * @param {Function} done - Done callback triggered once setTimeout is over. This function will be cached with useCallback, use deps parameter to invalidate the cache.
 * @param {Array} [deps = []] - List of done callback dependencies.
 *
 * @returns {{startTimer: Function, stopTimer: Function, isRunning: boolean}} Object that contains functions to start
 * and stop the timer and to check if timer is currently running.
 * @memberof Mojito.Presentation.Hooks
 */
export const useTimeout = (delay, done, deps = []) => {
    const timeoutRef = useRef();
    const isRunning = useRef(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const doneCallback = useCallback(() => done(), deps);

    const stopTimer = useCallback(() => {
        clearTimeout(timeoutRef.current);
        isRunning.current = false;
    }, []);

    const startTimer = useCallback(() => {
        stopTimer();
        if (delay > -1) {
            timeoutRef.current = setTimeout(() => {
                isRunning.current = false;
                doneCallback();
            }, delay);
            isRunning.current = true;
        } else {
            isRunning.current = false;
            doneCallback();
        }
    }, [delay, stopTimer, doneCallback]);

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return { startTimer, stopTimer, isRunning: isRunning.current };
};

/**
 * Hook sanitize html string for possible XSS vulnerabilities.
 *
 * @function useSanitizeHtml
 *
 * @param {string} html - HTML string to be sanitized.
 *
 * @returns {string} Sanitized string.
 * @memberof Mojito.Presentation.Hooks
 */
export const useSanitizeHtml = html => {
    const [sanitized, setSanitized] = useState();
    useEffect(() => {
        StringUtils.sanitizeHtml(html)
            .then(setSanitized)
            .catch(() => setSanitized(undefined));
    }, [html]);
    return sanitized;
};

/**
 * Hook produces comparator function helpful to compare array instance between render cycles.
 * Compare function accepts array and checks it towards array that was provided during previous render. Returns boolean.
 * If true then array differs from the one that was used previously, false otherwise.
 *
 * @function useArrayComparator
 *
 * @param {Array} initialArray - Initial array instance.
 *
 * @returns {Function} Compare function that accepts instance of array and returns boolean.
 * If true then array differs from the one that was used previously, false otherwise.
 * @memberof Mojito.Presentation.Hooks
 */
export const useArrayComparator = initialArray => {
    const cache = useRef(initialArray);
    return useCallback((arr = []) => {
        const prevArray = cache.current;
        cache.current = arr;
        return !arraysAreEqual(prevArray, arr);
    }, []);
};

/**
 * Hook accepts boolean flag and resolves to false if flag is false.
 * Once flag is true hook will catch this state and will be always resolved to true
 * on each forthcoming render.
 *
 * @function useIrreversibleTrue
 *
 * @param {boolean} flag - Boolean flag.
 *
 * @returns {boolean} Boolean result.
 * @memberof Mojito.Presentation.Hooks
 */
export const useIrreversibleTrue = flag => {
    const truthfulRef = useRef(false);
    return useMemo(() => {
        if (flag || truthfulRef.current) {
            truthfulRef.current = true;
            return true;
        }
        return false;
    }, [flag]);
};

/**
 * Hook that behaves like useEffect, but is dormant until component has been mounted.
 *
 * @function useEffectOnUpdate
 *
 * @param {Function} callback - Callback that only is invoked on dependency change after the component mounted.
 * @param {Array} dependencies - List of dependencies that will trigger callback on change.
 * @memberof Mojito.Presentation.Hooks
 */
export const useEffectOnUpdate = (callback, dependencies) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) {
            return callback();
        }
        mounted.current = true;
    }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * Hook that detects when component appears in a view port.
 *
 * @function useComponentVisibility
 *
 * @param {Function} callback - Callback that is invoked once component appears in a view port.
 * @param {number} [threshold = 0] - The threshold at which the callback will be invoked. 0 - when it shows up partly, 1 - when it is 100% in view.
 *
 * @returns {Function} Ref function to be passed as a component ref.
 * @memberof Mojito.Presentation.Hooks
 */
export const useComponentVisibility = (callback, threshold = 0) => {
    const observerRef = useRef();
    const componentRef = useRef();
    const resetObserver = useCallback(() => {
        observerRef.current?.disconnect();
        observerRef.current = undefined;
    }, []);
    useEffect(() => {
        return () => {
            observerRef.current?.disconnect();
        };
    }, []);
    return useCallback(
        node => {
            if (componentRef.current === node) {
                return;
            }
            resetObserver();
            componentRef.current = node;
            if (node) {
                observerRef.current = new IntersectionObserver(
                    ([entry]) => {
                        if (entry.isIntersecting) {
                            callback();
                            resetObserver();
                        }
                    },
                    { threshold }
                );
                observerRef.current.observe(node);
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [resetObserver, threshold]
    );
};
