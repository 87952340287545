/**
 * @class AggregatedMarketTypes
 * @name types
 * @memberof Mojito.Modules.AggregatedMarketPane
 */
export default class AggregatedMarketTypes {}

/**
 * Goal scorer menu item that can be show inside aggregated market content.
 * Mainly applicable for {@link Mojito.Modules.AggregatedMarketPane.GoalScorerMarketContent|goal scorer market}.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.AggregatedMarketPane.types
 */
AggregatedMarketTypes.GOAL_SCORER_MENU_ITEM = {
    POPULAR: 'popular',
    OTHER: 'other',
};

/**
 * Correct score menu item that can be show inside aggregated market content.
 * Mainly applicable for {@link Mojito.Modules.AggregatedMarketPane.CorrectScoreMarketContent|correct score market}.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.AggregatedMarketPane.types
 */
AggregatedMarketTypes.CORRECT_SCORE_MENU_ITEM = {
    MAIN: 'main',
    ALL: 'all',
};

/**
 * Handicap menu item that can be shown inside aggregated market content.
 * Mainly applicable for {@link Mojito.Modules.AggregatedMarketPane.HandicapMarketContent|handicap market}.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.AggregatedMarketPane.types
 */
AggregatedMarketTypes.HANDICAP_MENU_ITEM = {
    MAIN: 'main',
    ALL: 'all',
};
