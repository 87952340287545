import MojitoCore from 'mojito/core';
import AccaBarImpl from './acca-bar-controller.jsx';

const { UIControllerView } = MojitoCore.Presentation;

/**
 * Acca bar is a component which animates up from the bottom of the device, if the user has an accumulator
 * with 1 number of lines in the betslip.
 *
 * @class AccaBarController
 * @memberof Mojito.Modules
 */

export default UIControllerView('AccaBarController', AccaBarImpl);
