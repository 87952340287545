import MojitoCore from 'mojito/core';
import SuspendedSelectionLabelImpl from './suspended-selection-label.jsx';

const { UIView } = MojitoCore.Presentation;

/**
 * Component to display the suspended label of the bet.
 *
 * @class SuspendedSelectionLabel
 * @memberof Mojito.Modules.BetslipView.BetComponents
 */

export default UIView('SuspendedSelectionLabel', SuspendedSelectionLabelImpl);
