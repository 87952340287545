import PropTypes from 'prop-types';
import SelectedIndicatorImpl from './selected-indicator.jsx';
import MojitoCore from 'mojito/core';

/**
 * `SelectedIndicator` component. Used to indicate if an item is selected or not.
 *
 * The component by default is rendered only when it is selected (i.e., when
 * the "selected" prop is set to true). However, if the "unselected" config
 * values are non-empty, it will be rendered even if the "selected" prop is false.
 * This is particularly useful when a visual transition between selected and
 * unselected states is desired.
 *
 * @class SelectedIndicator
 * @memberof Mojito.Presentation.Components
 */

/**
 * Prop types for the `SelectedIndicator` component.
 *
 * @property {node|Array<node>} [children] - Content to be displayed in the SelectedIndicator.
 * @property {boolean} [selected = true] - Controls the selected state of the indicator.
 *
 * @memberof Mojito.Presentation.Components.SelectedIndicator
 */
const propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    selected: PropTypes.bool,
};

const defaultProps = {
    selected: true,
};

export default MojitoCore.Presentation.UIView(
    'SelectedIndicator',
    SelectedIndicatorImpl,
    propTypes,
    defaultProps
);
