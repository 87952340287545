import MojitoCore from 'mojito/core';
const UIPage = MojitoCore.Presentation.UIPage;
import RaceSportPageImpl from './race-sport-page.jsx';

/**
 * @namespace RaceSportPage
 * @memberof Mojito.Application.Pages
 */

/**
 * Page displaying a specific racing sport's race.
 *
 * @class RaceSportPage
 * @extends Mojito.Core.Presentation.UIPage
 * @memberof Mojito.Application.Pages.RaceSportPage
 */

export default UIPage('RaceSportPage', RaceSportPageImpl);
