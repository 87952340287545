import MojitoCore from 'mojito/core';

const { classUtils } = MojitoCore.Base;

const GridPane = props => {
    const {
        mojitoTools: {
            config: { style, defaultColumnSize },
        },
        children,
        gridTemplateColumns,
        repeatedColumnsNum,
    } = props;

    const configureGridTemplateColumns = () => {
        if (repeatedColumnsNum) {
            return `repeat(${repeatedColumnsNum}, ${defaultColumnSize})`;
        }
        return gridTemplateColumns || style.gridTemplateColumns;
    };

    return (
        <div
            style={{
                ...style,
                position: 'relative', // Required in order to support absolute positioning over grid cells like in the race card
                gridTemplateColumns: configureGridTemplateColumns(),
            }}
            className={classUtils.classNames('ta-GridPane', props.class)}
        >
            {children}
        </div>
    );
};

export default GridPane;
