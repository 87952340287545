import { isEmpty, noop, pick, pullAll, uniq, xor } from 'mojito/utils';

/**
 * Callback function triggered once data update arrives.
 *
 * @callback DataUpdate
 * @property {object} data - Content data.
 * @memberof Mojito.Services.Common.Content.ContentChannel
 */

/**
 * Callback function triggered once unsubscribe is finalised.
 *
 * @callback Unsubscribe
 * @property {Array<string>} itemIds - The list of item ids that have been unsubscribed.
 * @memberof Mojito.Services.Common.Content.ContentChannel
 */

/**
 * ContentChannel constructor.
 *
 * @class ContentChannel
 * @param {Mojito.Core.Services.DataProvider} dataProvider - Data provider instance.
 * @param {number} [keepAliveTime = 0] - Keep content alive timeout. If set to -1 then content will be kept alive forever.
 *
 * @classdesc Class that allows to subscribe to data content channel and receive real live content updates.
 * Keeps subscription-to-client integrity which allows to avoid duplication of subscriptions.
 * Ensures that subscription will be kept open until at least one client is still interested in it.
 *
 * @memberof Mojito.Services.Common.Content
 */
export default class ContentChannel {
    constructor(dataProvider, keepAliveTime = 0) {
        this.dataProvider = dataProvider;
        this.keepAliveTime = keepAliveTime;
        this.subscriptionMetas = {};
        this.pendingDisposalItems = {};
    }

    /**
     * Subscribes client to provided content items.
     * Proxy call to {@link Mojito.Core.Services.DataProvider#subscribeToEntity|subscribeToEntity} method of provided retriever instance.
     *
     * @param {Array<string>} itemIds - The list of item ids to subscribe.
     * @param {string} clientId - The id of subscriber.
     * @param {Mojito.Services.Common.Content.ContentChannel.DataUpdate} [onData] - Callback triggered once data arrives and on each update.
     *
     * @returns {Array<string>} The list of entities that are pending subscription.
     * @function Mojito.Services.Common.Content.ContentChannel#subscribe
     */
    subscribe(itemIds, clientId, onData = noop) {
        itemIds = itemIds && itemIds.filter(Boolean);

        if (isEmpty(itemIds)) {
            return [];
        }
        pullAll(this.pendingDisposalItems[clientId], itemIds);
        const idsToSubscribe = itemIds.filter(id => !this.subscriptionMetas[id]);
        const idsAlreadySubscribed = xor(itemIds, idsToSubscribe);

        idsAlreadySubscribed.forEach(itemId => {
            const subscriptionMeta = this.subscriptionMetas[itemId];
            subscriptionMeta.clients.add(clientId);
        });

        idsToSubscribe.forEach(itemId => {
            const subscription = this.dataProvider.subscribeToEntity(itemId, data => {
                // This might not exist if we unsubscribe even before first data arrives.
                if (this.subscriptionMetas[itemId]) {
                    onData(itemId, data);
                }
            });
            this.subscriptionMetas[itemId] = this.createMeta(subscription, clientId);
        });

        return idsToSubscribe;
    }

    /**
     * Subscribes to content items for multiple clients in a single subscription chunk.
     * Proxy call to {@link Mojito.Core.Services.DataProvider#subscribeToEntities|subscribeToEntities} method of provided retriever instance.
     *
     * @param {Array<object>} requests - The list of data requests. One request per each client.
     * @param {Mojito.Services.Common.Content.ContentChannel.DataUpdate} [onInit] - Callback triggered once init data load.
     * @param {Mojito.Services.Common.Content.ContentChannel.DataUpdate} [onData] - Callback triggered once data arrives and on each update.
     *
     * @returns {Array<string>} The list of entity ids that are pending subscription.
     * @function Mojito.Services.Common.Content.ContentChannel#subscribeMultiple
     */
    subscribeMultiple(requests, onInit = noop, onData = noop) {
        requests =
            requests &&
            requests
                .map(request => {
                    const itemIds = request?.itemIds.filter(Boolean);
                    return itemIds.length > 0 ? { ...request, itemIds } : undefined;
                })
                .filter(Boolean);

        if (isEmpty(requests)) {
            return [];
        }
        requests.forEach(request => {
            const { clientId, itemIds } = request;
            pullAll(this.pendingDisposalItems[clientId], itemIds);
        });

        const itemIds = requests.flatMap(request => request.itemIds);
        const idsToSubscribe = itemIds.filter(id => !this.subscriptionMetas[id]);
        const idsAlreadySubscribed = xor(itemIds, idsToSubscribe);

        idsAlreadySubscribed.forEach(itemId => {
            const subscriptionMeta = this.subscriptionMetas[itemId];
            const request = requests.find(request => request.itemIds.includes(itemId));
            request && subscriptionMeta.clients.add(request.clientId);
        });

        requests = requests
            .map(request => {
                return {
                    ...request,
                    itemIds: request.itemIds.filter(itemId => idsToSubscribe.includes(itemId)),
                };
            })
            .filter(request => request.itemIds.length);

        if (requests.length) {
            this.doMultipleEntrySubscription(requests, onInit, onData);
        }
        return idsToSubscribe;
    }

    /**
     * Unsubscribes client from provided content items.
     *
     * @param {Array<string>} itemIds - The list of item ids to unsubscribe.
     * @param {string} clientId - The id of client that is aiming to unsubscribe.
     *
     * @returns {Array<string>|undefined} The list of entities that are being unsubscribed.
     * @function Mojito.Services.Common.Content.ContentChannel#unsubscribe
     */
    unsubscribe(itemIds, clientId) {
        const subscriptionMetas = pick(this.subscriptionMetas, itemIds);
        const metasList = Object.values(subscriptionMetas);
        if (!metasList.length || !this.hasClient(metasList, clientId)) {
            return;
        }

        metasList.forEach(meta => meta.clients.delete(clientId));
        const itemsIdsToDispose = Object.keys(subscriptionMetas).filter(
            itemId => !subscriptionMetas[itemId].clients.size
        );

        itemsIdsToDispose.forEach(itemId => this.disposeItemSubscription(itemId));
        return itemsIdsToDispose;
    }

    /**
     * Unsubscribes client from all data.
     * The actual subscriptions disposal will happen only if there are no more clients interested particular items.
     * Note: the actual unsubscribe will be delayed according to keepAliveTime setting.
     *
     * @param {string} clientId - The id of client that is aiming to unsubscribe.
     * @param {Mojito.Services.Common.Content.ContentChannel.Unsubscribe} [callback] - Callback on unsubscription finalise. Callback will not be triggered if no subscription were disposed.
     *
     * @function Mojito.Services.Common.Content.ContentChannel#unsubscribeAll
     */
    unsubscribeAll(clientId, callback = noop) {
        if (this.keepAliveTime < 0) {
            return;
        }

        this.pendingDisposalItems[clientId] = this.getItemsByClientId(clientId);
        if (this.keepAliveTime > 0) {
            setTimeout(() => {
                const disposedItemIds = this.unsubscribe(
                    this.pendingDisposalItems[clientId],
                    clientId
                );
                !isEmpty(disposedItemIds) && callback(disposedItemIds);
            }, this.keepAliveTime);
        } else {
            const disposedItemIds = this.unsubscribe(this.pendingDisposalItems[clientId], clientId);
            !isEmpty(disposedItemIds) && callback(disposedItemIds);
        }
    }

    /**
     * Dispose the channel.
     *
     * @function Mojito.Services.Common.Content.ContentChannel#dispose
     */
    dispose() {
        const itemIdsToDispose = Object.keys(this.subscriptionMetas);
        itemIdsToDispose.forEach(itemId => this.disposeItemSubscription(itemId));
    }

    doMultipleEntrySubscription(requests, onInit, onData) {
        // Composite subscription object is returned
        const itemIds = uniq(requests.flatMap(request => request.itemIds));
        const compositeSubscription = this.dataProvider.subscribeToEntities(
            itemIds,
            onInit,
            (itemId, data) => {
                // This will not exist if we unsubscribe even before first data arrives.
                if (this.subscriptionMetas[itemId]) {
                    onData(itemId, data);
                }
            }
        );
        itemIds.forEach(itemId => {
            const subscription = compositeSubscription.subscriptions.find(sub => sub.id === itemId);
            const request = requests.find(request => request.itemIds.includes(itemId));
            this.subscriptionMetas[itemId] = this.createMeta(subscription, request.clientId);
        });
    }

    disposeItemSubscription(itemId) {
        const { subscription } = this.subscriptionMetas[itemId] || {};
        subscription && subscription.dispose();
        delete this.subscriptionMetas[itemId];
    }

    hasClient(metas, clientId) {
        return metas.some(meta => meta.clients.has(clientId));
    }

    createMeta(subscription, clientId) {
        return {
            subscription,
            clients: new Set([clientId]),
        };
    }

    getItemsByClientId(clientId) {
        return Object.keys(this.subscriptionMetas).filter(itemId =>
            this.subscriptionMetas[itemId].clients.has(clientId)
        );
    }
}
