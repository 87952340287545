import SelectionCardImpl from './selection-card.jsx';
import MojitoCore from 'mojito/core';
import PropTypes from 'prop-types';

const { UIView } = MojitoCore.Presentation;

/**
 * Selection card component.
 *
 * @function SelectionCard
 * @name view
 *
 * @memberof Mojito.Modules.EventCards.SelectionCard
 */

/**
 * Selection event card prop types.
 *
 * @property {string} eventId - Event id.
 * @property {string} marketId - Market id is used to get a market info.
 * @property {string} marketName - Market name.
 * @property {string} selectionId - Selection id is used to get a selection.
 * @property {string} selectionName - Selection name.
 * @property {boolean} [shouldRequestData = true] - Flag indicating whether component should request event and market data from content. If false, then neither event nor markets will be requested, hence in order to present info corresponding data should be already available in a store.
 * @property {boolean} [showSkeleton = true] - True if data loading skeleton should be visible. It can be useful to hide skeleton if parent component already covers component with its of top level skeleton.
 *
 * @memberof Mojito.Modules.EventCards.SelectionCard.view
 */
const propTypes = {
    eventId: PropTypes.string.isRequired,
    marketId: PropTypes.string.isRequired,
    marketName: PropTypes.string.isRequired,
    selectionId: PropTypes.string.isRequired,
    selectionName: PropTypes.string.isRequired,
    shouldRequestData: PropTypes.bool,
    showSkeleton: PropTypes.bool,
};

const defaultProps = {
    shouldRequestData: true,
    showSkeleton: true,
};

export default UIView('SelectionCard', SelectionCardImpl, propTypes, defaultProps);
