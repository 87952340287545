import PropTypes from 'prop-types';
import PageHeaderImpl from './pageheader.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * A view that displays a simple page header.
 *
 * @class PageHeader
 * @memberof Mojito.Presentation.Components
 */

/**
 * `PageHeader` prop types.
 *
 * @property {string} [label = ''] - Text to be displayed as the header label.
 * @property {boolean} [showBackButton = false] - Indicates whether a 'back' button should be included in the page header.
 * @property {string} [iconSrc = ''] - Source URL for the header icon. Takes precedence over the `PageHeader.icon.src` config value.
 * @property {Function} [onBackClicked = ()=>{}] - Function to be executed when the 'back' button is clicked.
 * @property {string} [tooltipText] - Text to be displayed in a {@link Mojito.Presentation.Components.Tooltip|tooltipText} component.
 * @property {Function} [onTooltipClick = ()=>{}] - Function to be executed when the tooltip is clicked.
 * @memberof Mojito.Presentation.Components.PageHeader
 */

const propTypes = {
    label: PropTypes.string,
    showBackButton: PropTypes.bool,
    iconSrc: PropTypes.string,
    onBackClicked: PropTypes.func,
    tooltipText: PropTypes.string,
    onTooltipClick: PropTypes.func,
    children: PropTypes.node,
};

const defaultProps = {
    label: '',
    showBackButton: false,
    iconSrc: '',
    onBackClicked: noop,
    onTooltipClick: noop,
};

export default UIView('PageHeader', PageHeaderImpl, propTypes, defaultProps);
