/**
 * Class defining sport list data descriptors.
 *
 * @class SportsDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.SportsContent.Sports
 */
export default class SportsDataDescriptor {
    /**
     * Factory method for creating a sports data descriptor.
     *
     * @param {string} listId - Id of list.
     * @returns {{dataType: string}} Generic descriptor.
     * @function Mojito.Services.SportsContent.Sports.descriptor#create
     * @static
     */
    static create(listId) {
        return {
            dataType: SportsDataDescriptor.DATA_TYPES.SPORTS,
            listId,
        };
    }
}

/**
 * Unique system data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} SPORTS - Stands for the sports data descriptor type.
 * @memberof Mojito.Services.SportsContent.Sports.descriptor
 */
SportsDataDescriptor.DATA_TYPES = {
    SPORTS: 'sports',
};
