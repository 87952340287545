import PropTypes from 'prop-types';
import PrewagerPayoutInfoImpl from './prewager-payout-info.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to display payout info of bet funds prewager.
 *
 * @class PrewagerPayoutInfo
 * @memberof Mojito.Presentation.Components.BetFunds
 */

/**
 * PrewagerPayoutInfo props.
 *
 * @property {Mojito.Services.Bets.types.Prewager} [prewager] - Payout value.
 * @property {boolean} [isPotential] - Flag to display potential label.
 * @property {string} [currencyCode] - Currency.
 *
 * @memberof Mojito.Presentation.Components.BetFunds.PrewagerPayoutInfo
 */
const propTypes = {
    prewager: PropTypes.object,
    isPotential: PropTypes.bool,
    currencyCode: PropTypes.string,
};

export default UIView('PrewagerPayoutInfo', PrewagerPayoutInfoImpl, propTypes);
