import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {PaletteRetriever} from 'core/application/modules/palette-retriever';
import {registerPaletteSchema} from '../schema-registry';
import {mergeToHierarchy} from 'core/utils/config-utils';
import {ApplicationConfig} from 'core/config/application-config';
import {BasicPalette} from './basic-palette';
import {DebugFlags} from '../debug';
import {countAndIncreaseAutomatedPropertiesCount} from 'core/utils/statistics';
import {DBXPerformanceService} from 'core/services/performance-reporting-service';

export class AbstractVisualFeature extends AbstractFeature {
    constructor(parentFeature) {
        super(parentFeature);

        VisualFeatures.addFeatureToMapping(this);

        if (parentFeature) {
            if (this.paletteSchema && parentFeature.paletteSchema) {
                this.paletteSchema.parent = parentFeature.paletteSchema.key;
            }
        }

        if (this.paletteSchema) {
            registerPaletteSchema(this.paletteSchema);
        }
    }

    get paletteSchema() {
        return null;
    }

    buildVisualConfig(mojitoConfig, parent) {
        let palette = null;

        // temp solution
        const {DEFAULT_PAGE_BG} = BasicPalette.palette;

        let paletteRetriever;
        if (this.paletteSchema) {
            // construct ready-to-use palette from global config
            paletteRetriever = new PaletteRetriever(this.logger, {
                defaults: {...BasicPalette.palette, PARENT: parent?.intermediateCache},
                defaultsResolved: {...BasicPalette.palette, PARENT: parent?.resolvedValuesCache},
                basedOn: DEFAULT_PAGE_BG,
            });

            // Unfortunately need to access global ApplicationConfig because updateTokens modify global, but not local copies inside features
            palette = paletteRetriever.constructPaletteBySchema(ApplicationConfig.config || {}, this.paletteSchema); // not used yet
        }

        const configs = this.getVisualConfig(palette);
        mergeToHierarchy(mojitoConfig, 'services.configurations', configs);

        this.paletteSchema && countAndIncreaseAutomatedPropertiesCount(this.paletteSchema.key, configs);

        this.dependants.forEach(
            feature => feature.buildVisualConfig && feature.buildVisualConfig(mojitoConfig, paletteRetriever)
        );
    }

    // eslint-disable-next-line no-unused-vars
    getVisualConfig(palette) {
        return {};
    }
}

const _featuresMap = [];

class VisualFeatures extends AbstractVisualFeature {
    // eslint-disable-next-line no-unused-vars
    setupConfigs(globalConfig, options, filterFn) {
        BasicPalette.load(globalConfig);
        return super.setupConfigs(...arguments);
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const start = performance.now();
        super.beforeMojitoConfigBuild(mojitoConfig);

        const startVisual = performance.now();
        if (!DebugFlags.measurePerformance) {
            this.buildVisualConfig(mojitoConfig);
        } else {
            for (let i = 1; i <= 100; i++) {
                this.buildVisualConfig(mojitoConfig);
            }
        }
        performance.measure('dbx:visual-features:visual', {start: startVisual});
        performance.measure('dbx:visual-features', {start});
        DBXPerformanceService.reportSystemMeasurementDuration(
            'dbx:visual-features',
            'dbx.init.visual-features-duration'
        );
    }

    static addFeatureToMapping(feature) {
        if (_featuresMap[feature.name]) {
            this.logger.warn('You are trying to override existing feature in mapping. Feature name: ', feature.name);
        }

        _featuresMap[feature.name] = feature;
    }

    getFeatureByName(name) {
        return _featuresMap[name];
    }
}

export const allVisuals = new VisualFeatures(allFeatures);
