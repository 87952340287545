import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';

const Breadcrumbs = MojitoModules.Breadcrumbs.module;
const BreadcrumbsTypes = MojitoModules.Breadcrumbs.types;
const PriceBoostCoupon = MojitoModules.PriceBoostCoupon;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const PageContextMenu = MojitoModules.PageContextMenu;
const { FlexPane, DocumentHead, GlobalLinks, PageHeader, PageSplitLayout, DefaultHeadMetaFactory } =
    MojitoPresentation.Components;
const SportsMenu = MojitoModules.SportsMenu.module;
const { PRICE_BOOST_EVENT_GROUP_NAME } = MojitoServices.EventGroups.types;

export default class PriceBoostPageView extends UIViewImplementation {
    constructor(props) {
        super(props);

        // Creating the MetaFactory instance here to avoid re-creating it on every render()
        const metaFactory = this.config.documentHeadMetaFactory || DefaultHeadMetaFactory;
        this.documentHeadMetaFactory = new metaFactory(this.l10n);
    }

    onPageHeaderBackClicked() {
        window.history.back();
    }

    renderDocumentHead() {
        const headData = {
            title: '$PRICE_BOOST_PAGE.PAGE_TITLE',
            keywords: '$PRICE_BOOST_PAGE.PAGE_KEYWORDS',
            description: '$PRICE_BOOST_PAGE.PAGE_DESCRIPTION',
            canonical: '$PRICE_BOOST_PAGE.PAGE_CANONICAL',
        };

        return <DocumentHead data={headData} metaFactory={this.documentHeadMetaFactory} />;
    }

    renderBreadcrumbs() {
        if (this.config.hideBreadcrumbs) {
            return null;
        }

        const breadcrumbs = [
            {
                type: BreadcrumbsTypes.TEXT_LABEL,
                data: this.resolveString('$PRICE_BOOST_PAGE.HEADER'),
                analyticsData: 'Big Price Boost Coupon',
            },
        ];

        return <Breadcrumbs breadcrumbs={breadcrumbs} />;
    }

    renderPageHeader() {
        if (!this.config.showPageHeader) {
            return null;
        }

        return (
            <PageHeader
                label={this.resolveString('$PRICE_BOOST_PAGE.HEADER')}
                config={this.config.pageHeader}
                showBackButton={true}
                onBackClicked={this.onPageHeaderBackClicked}
            />
        );
    }

    render() {
        return (
            <>
                {this.renderDocumentHead()}
                <PageSplitLayout>
                    <FlexPane config={this.config.primaryContent}>
                        {this.renderBreadcrumbs()}
                        {this.renderPageHeader()}
                        <PriceBoostCoupon
                            boostedGroupId={PRICE_BOOST_EVENT_GROUP_NAME.ALL_SPORTS}
                            showSportTitles={true}
                        />
                    </FlexPane>
                    <FlexPane config={this.config.secondaryContentContainer}>
                        <PageContextMenu
                            config={this.config.pageContextMenu}
                            applicablePage="homepage"
                        />
                        <SportsMenu />
                        <GlobalLinks />
                    </FlexPane>
                </PageSplitLayout>
            </>
        );
    }
}
