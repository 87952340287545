import {PALETTE_IDS} from 'core/application/modules/ids';
import {COLOR, FONT} from '../theme';

export function getDialogConfig() {
    return {
        custom: {
            [PALETTE_IDS.DIALOGS]: {
                LoginDialog: {
                    usernamePasswordLogin: {
                        register: {
                            hide: true,
                        },
                    },
                },
            },
        },
        palette: {
            [PALETTE_IDS.COMBINATION_ODDS_POPUP]: {
                COMBINATION_ODDS_CLOSE_BUTTON: {
                    CLOSE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                },
                COMBINATION_ODDS_HEADER_SHOW_SEPARATOR: false,
                COMBINATION_ODDS_TABLE: {
                    TABLE_HEADER_TEXT: COLOR.BRAND.FOREGROUND2,
                    TABLE_ITEM_TEXT: COLOR.BRAND.FOREGROUND1,
                },
            },
            [PALETTE_IDS.LOGIN_DIALOG]: {
                LOGIN_DIALOG_FORGOT_DETAILS_TEXT: {
                    color: COLOR.BRAND.ACCENT2,
                    fontSize: FONT.SIZE_MEDIUM,
                },
                CHANGE_PASSWORD_CONTAINER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontSize: FONT.SIZE_XSMALL,
                },
                CHANGE_PASSWORD_POLICIES_TEXT: COLOR.BRAND.ACCENT3,
            },
            [PALETTE_IDS.TOAST]: {
                TOAST_BG: COLOR.BASE.PAGE,
                TOAST_BORDER_COLOR: COLOR.BRAND.ACCENT1,
                TOAST_MESSAGE_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
            },
            [PALETTE_IDS.QUICK_START_GUIDE]: {
                QSG_DIALOG_CONTAINER_BG: COLOR.BASE.BACKGROUND4,
                QSG_DIALOG_TITLE_TEXT: {fontWeight: FONT.WEIGHT_SEMI_BOLD},
                QSG_DIALOG_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                },

                QSG_DIALOG_GUIDE_BUTTON: {
                    TOGGLE_BUTTON_TEXT: {
                        textTransform: 'capitalize',
                    },
                },
                QSG_DIALOG_NEXT_BUTTON: {
                    PRIMARY_BUTTON_TEXT: {
                        color: COLOR.BRAND.FOREGROUND7,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                },
                QSG_DIALOG_BACK_TO_MENU_BUTTON: {
                    TERTIARY_BUTTON_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                },
            },
        },
    };
}
