import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const reduxInstance = MojitoCore.Services.redux;

/**
 * The name of the quick start guide store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.QuickStartGuide
 */
export const STORE_KEY = 'quickStartGuideStore';

export const INITIAL_STATE = {
    guides: [],
    menu: {},
    error: undefined,
};

const resetState = state => Object.assign(state, INITIAL_STATE);

export const { reducer, actions } = createSlice({
    name: 'quickStartGuide',
    initialState: INITIAL_STATE,
    reducers: {
        fetchQuickStartGuides: noop,
        fetchQuickStartGuidesSuccess(state, { payload }) {
            state.error = undefined;
            state.guides = payload.guides;
            state.menu = payload.menu;
        },
        fetchQuickStartGuidesFailed(state, { payload: error }) {
            resetState(state);
            state.error = error;
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
