import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import CurrencyHelper from 'presentation/formatters/currency/index.js';
import OddsUtils from 'presentation/formatters/odds/index.js';
import MarketHelper from 'presentation/formatters/markets/market-helper.js';
import { isEmpty } from 'mojito/utils';

const StringUtils = MojitoCore.Base.StringUtils;
const BetsTypes = MojitoServices.Bets.types;
const BetsUtils = MojitoServices.Bets.utils;
const { EACH_WAY } = MojitoServices.Bets.types.BET_WAY;
const { COMBINATION } = MojitoServices.Bets.types.MULTICAST_TYPE;

/**
 * BettingHelper offers some helpers for betting components.
 *
 * @class BettingHelper
 * @memberof Mojito.Presentation.Utils
 */
export default class BettingHelper {
    /**
     * Returns the marketName for a bet leg.
     *
     * @param {Mojito.Services.Bets.types.Leg} leg - The bet leg to get market name for.
     * @param {object} l10n - Object with the translation strings.
     * @param {number} numberOfLines - Number of lines in bet related to leg. Only used when bet is multicast combination bet.
     *
     * @returns {string|undefined} Market name to use for the leg.
     * @function Mojito.Presentation.Utils.BettingHelper.getMarketName
     */
    static getMarketName(leg, l10n, numberOfLines) {
        const isMulticast = BetsUtils.isMulticast(leg);

        if (!isMulticast) {
            return BetsUtils.getFirstLegPart(leg).marketName;
        }

        const castMarketName = StringUtils.resolveString(
            `$BETSLIP.MARKET_TYPES.${leg.legSort}`,
            l10n
        );

        if (leg.legSort.includes(COMBINATION)) {
            const translationKey =
                numberOfLines === 1
                    ? '$BETSLIP.COMBINATION_CAST_WITH_ONE_LINE'
                    : '$BETSLIP.COMBINATION_CAST_WITH_LINES';

            return StringUtils.resolveAndFormatString(
                translationKey,
                l10n,
                castMarketName,
                numberOfLines
            );
        }

        return castMarketName;
    }

    /**
     * Get bet status string using provided l10n object.
     * Return bet status if string is not available.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - Bet to get status string from.
     * @param {object} l10n - Object with strings.
     * @param {boolean} [isEarlyPayout] - If true then bet has EarlyPayout.
     *
     * @returns {string} The l10n bet status string.
     * @function Mojito.Presentation.Utils.BettingHelper.getBetStatusString
     */
    static getBetStatusString(bet, l10n, isEarlyPayout = false) {
        const betStatusString = bet.status;

        if (isEarlyPayout && bet.legs.length === 1 && betStatusString === 'WON') {
            return StringUtils.resolveString(`$BETTING_HELPER.BET_STATUS.EARLY_PAYOUT`, l10n);
        }

        const resolvedStatusString = StringUtils.resolveString(
            `$BETTING_HELPER.BET_STATUS.${betStatusString}`,
            l10n
        );

        return resolvedStatusString ? resolvedStatusString : betStatusString;
    }

    /**
     * Get localized type name for provided bet using l10n object followed by the modifiers included in the legs in the bet.
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - The bet.
     * @param {object} l10n - Object with strings.
     *
     * @returns {string} The type name followed by the modifiers.
     * @function Mojito.Presentation.Utils.BettingHelper.getBetTypeAndModifiers
     */
    static getBetTypeAndModifiers(bet, l10n) {
        const { betType, legs, numberOfLines, isTeaser } = bet;
        const isPlural = legs.length > 1 && numberOfLines > 1;
        const typeName = BettingHelper.getTypeName(betType, l10n, isTeaser, isPlural);
        const modifiers = BettingHelper.getBetModifiers(bet, l10n);
        return `${typeName} ${modifiers}`;
    }

    /**
     * Get localized name for provided betType using l10n object.
     * Returns betType if name is not available.
     *
     * @param {Mojito.Services.Bets.types.BET_TYPE} betType - Bet type to get name.
     * @param {object} l10n - Object with strings.
     * @param {boolean} [isTeaser] - True if bet is teaser, false otherwise.
     * @param {boolean} [isPlural] - If true, return the name of this bet type in plural. This property has no effect for teaser bet.
     *
     * @returns {string} The type name.
     * @function Mojito.Presentation.Utils.BettingHelper.getTypeName
     */
    static getTypeName(betType, l10n, isTeaser, isPlural) {
        if (!l10n || !betType) {
            return betType;
        }
        const { resolveString, resolveAndFormatString } = StringUtils;

        if (isTeaser) {
            const foldNumber = BetsTypes.BET_TYPE_TO_INDEX[betType];
            return (
                resolveString(`$BETTING_HELPER.TEASER_BET_TYPES.${betType}`, l10n, true) ||
                resolveAndFormatString('$BETTING_HELPER.TEASER', l10n, foldNumber)
            );
        }
        const prefix = isPlural ? 'BET_TYPES_PLURAL' : 'BET_TYPES';
        const resolvedBetTypeName = resolveString(`$BETTING_HELPER.${prefix}.${betType}`, l10n);
        return resolvedBetTypeName ? resolvedBetTypeName : betType;
    }

    /**
     * Get banker formula (e.g. "2B + 3/5") for accumulator.
     * If bet type not found, fallbacks to {@link Mojito.Presentation.Utils.BettingHelper.getTypeName}.
     *
     * @param {Mojito.Services.Bets.types.BET_TYPE} betType - Bet type.
     * @param {object} l10n - Object with strings.
     * @param {number} singleBetCount - Number of single bets in the betslip.
     * @param {number} bankerBetCount - Number of of banker bets in the betslip.
     * @param {boolean} [isPlural] - Used for getting fallback.
     *
     * @returns {string} Banker formula.
     * @function Mojito.Presentation.Utils.BettingHelper.getBankerFormula
     */
    static getBankerFormula(betType, l10n, singleBetCount, bankerBetCount, isPlural) {
        const banker = StringUtils.resolveString('$BETTING_HELPER.BANKER_IN_FORMULA', l10n);
        const foldNumber = BetsTypes.BET_TYPE_TO_INDEX[betType];
        const nonBankerCount = singleBetCount - bankerBetCount;
        return foldNumber === undefined
            ? BettingHelper.getTypeName(betType, l10n, false, isPlural)
            : `${bankerBetCount}${banker} + ${foldNumber - bankerBetCount}/${nonBankerCount}`;
    }

    /**
     * Formats payout into a payout as a currency string.
     * The default value is used if the payout is zero.
     *
     * @param {string} payout - The payout.
     * @param {string} defaultValue - The default value.
     * @param {string} currencyCode - The currency code.
     *
     * @returns {string} Formatted payout or the default value if payout was zero.
     * @function Mojito.Presentation.Utils.BettingHelper.formatPayout
     */
    static formatPayout(payout, defaultValue, currencyCode) {
        return payout > 0 ? CurrencyHelper.formatCurrency(payout, currencyCode) : defaultValue;
    }

    /**
     * Returns the marketName for a bet leg or label with each way terms.
     *
     * @param {Mojito.Services.Bets.types.Leg} leg - The bet leg to get market name or each way terms for.
     * @param {object} l10n - Object with the translation strings.
     * @param {number} numberOfLines - Number of lines in bet related to leg. Only used when bet is multicast combination bet.
     *
     * @returns {string} Market name as market name or each way terms.
     * @function Mojito.Presentation.Utils.BettingHelper.getMarketNameOrEachWayLabel
     */
    static getMarketNameOrEachWayLabel(leg, l10n, numberOfLines) {
        return leg.betWay === EACH_WAY && leg.eachWayTerms
            ? MarketHelper.getEachWayLabel(leg.eachWayTerms, l10n)
            : BettingHelper.getMarketName(leg, l10n, numberOfLines);
    }

    /**
     * Returns the price text based on the user selected odds format.
     *
     * @param {Mojito.Presentation.Utils.BettingHelperTypes.ODDS} priceTexts - The price texts.
     * @param {object} l10n - Object with the translation strings.
     *
     * @returns {string} Formatted odds string.
     * @function Mojito.Presentation.Utils.BettingHelper.getPriceText
     */
    static getPriceText(priceTexts, l10n) {
        if (!priceTexts) {
            return '';
        }
        const { fractional, decimal, american } = priceTexts;
        return OddsUtils.getOdds(fractional, decimal, american, l10n);
    }

    /**
     * Returns a label with leg modifiers.
     *
     * For example, two leg modifiers would return the following string: "(<modifier_1>, <modifier_2>)".
     *
     * @param {Mojito.Services.Bets.types.Leg} leg - The bet leg to get modifiers for.
     * @param {object} l10n - Object with the translation strings.
     * @returns {string|undefined} Label with leg modifiers, or an empty string if there are no modifiers.
     * @function Mojito.Presentation.Utils.BettingHelper.getLegModifiers
     */
    static getLegModifiers(leg, l10n) {
        const modifiers = [];
        if (leg.isBanker) {
            modifiers.push(BettingHelper.getBankerModifier(l10n));
        }

        if (leg.betWay === EACH_WAY) {
            modifiers.push(BettingHelper.getEachWayModifier(l10n));
        }

        return modifiers.length > 0 ? `(${modifiers.filter(Boolean).join(', ')})` : '';
    }

    /**
     * Get each way modifier.
     * Primarily used with selection name to indicate that it is an each way selection.
     *
     * @param {object} l10n - Object with the translation strings.
     * @returns {string} Each way modifier. Standard is `EW`.
     *
     * @function Mojito.Presentation.Utils.BettingHelper.getEachWayModifier
     */
    static getEachWayModifier(l10n) {
        return StringUtils.resolveString('$BETTING_HELPER.LEG_MODIFIERS.EACH_WAY', l10n);
    }

    /**
     * Get banker modifier.
     * Primarily used with selection name to indicate that it is a banker selection.
     *
     * @param {object} l10n - Object with the translation strings.
     * @returns {string} Banker modifier. Standard is `B`.
     *
     * @function Mojito.Presentation.Utils.BettingHelper.getBankerModifier
     */
    static getBankerModifier(l10n) {
        return StringUtils.resolveString('$BETTING_HELPER.LEG_MODIFIERS.BANKER', l10n);
    }

    /**
     * Returns the selection name(s) of the leg.
     *
     * @param {Mojito.Services.Bets.types.Leg} leg - The bet leg to get selection name for.
     * @param {object} l10n - Object with the translation strings.
     *
     * @returns {string} Selection name for the leg.
     * @function Mojito.Presentation.Utils.BettingHelper.getSelectionName
     */
    static getSelectionName(leg, l10n) {
        const firstLegPart = BetsUtils.getFirstLegPart(leg) || {};
        const isMulticast = BetsUtils.isMulticast(leg);
        if (!isMulticast) {
            return firstLegPart.selectionName;
        }

        return leg.parts
            .map(part =>
                StringUtils.resolveAndFormatString(
                    '$BETTING_HELPER.CAST_MARKET_RUNNER',
                    l10n,
                    part.selectionName,
                    part.number
                )
            )
            .join(StringUtils.resolveString('$BETTING_HELPER.CAST_MARKET_RUNNERS_SEPARATOR', l10n));
    }

    /**
     * Get selection text used for displaying selection info for bet leg.
     *
     * The difference between this function and get selection name is that
     * it includes leg modifiers and handicap if they exist.
     *
     * @param {Mojito.Services.Bets.types.Leg} leg - Leg.
     * @param {object} l10n - Object with the translation strings.
     * @returns {string} A string containing selection name, handicap and leg modifiers (e.g. EW, Banker).
     * @function Mojito.Presentation.Utils.BettingHelper.getSelectionText
     */
    static getSelectionText(leg, l10n) {
        const selectionName = BettingHelper.getSelectionName(leg, l10n);
        const legModifiers = BettingHelper.getLegModifiers(leg, l10n);
        const part = BetsUtils.getFirstLegPart(leg);
        const { hcap, hcapTeaser } = part;
        const { name, handicapLabel } = MarketHelper.handleHandicap(selectionName, hcap);
        const handicapTeaserLabel = BettingHelper.formatTeaserHandicapText(hcapTeaser, l10n);
        return [name, handicapLabel, handicapTeaserLabel, legModifiers].filter(Boolean).join(' ');
    }

    /**
     * Returns a label with bet modifiers included in the legs of the bet.
     *
     * For example, two bet modifiers will return the following string: "(<modifier_1>, <modifier_2>)".
     *
     * @param {Mojito.Services.Bets.types.Bet} bet - The bet to get modifiers for.
     * @param {object} l10n - Object with the translation strings.
     * @returns {string} Label with bet modifiers, or an empty string if no modifiers.
     * @function Mojito.Presentation.Utils.BettingHelper.getBetModifiers
     */
    static getBetModifiers(bet, l10n) {
        const modifiers = [];

        if (BetsUtils.isBanker(bet)) {
            const numberOfBankers = BetsUtils.getNumberOfBankers(bet);
            const key =
                numberOfBankers > 1
                    ? '$BETTING_HELPER.BET_MODIFIERS.BANKER_PLURAL'
                    : '$BETTING_HELPER.BET_MODIFIERS.BANKER_SINGLE';
            const bankerModifier = StringUtils.resolveAndFormatString(key, l10n, numberOfBankers);
            modifiers.push(bankerModifier);
        }

        if (BetsUtils.isEachWay(bet)) {
            const eachWayModifier = StringUtils.resolveString(
                '$BETTING_HELPER.BET_MODIFIERS.EACH_WAY',
                l10n
            );
            modifiers.push(eachWayModifier);
        }

        return modifiers.length > 0 ? `(${modifiers.filter(Boolean).join(', ')})` : '';
    }

    /**
     * Returns a label with formatted teaser handicap modifier.
     * Uses `BETTING_HELPER.TEASER_HANDICAP` key to search for localized value in l10n object.
     *
     * @param {string} hcap - Teaser handicap value. Sometimes referred as handicap modifier.
     * @param {object} l10n - Object with the translation strings.
     * @returns {string} Localized string.
     * @function Mojito.Presentation.Utils.BettingHelper.formatTeaserHandicapText
     */
    static formatTeaserHandicapText(hcap, l10n) {
        if (isEmpty(hcap)) {
            return '';
        }
        return StringUtils.resolveAndFormatString('$BETTING_HELPER.TEASER_HANDICAP', l10n, hcap);
    }
}
