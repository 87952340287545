/**
 * System Settings types.
 *
 * @class SystemSettingsTypes
 * @name types
 * @memberof Mojito.Core.Services.SystemSettings
 */
export default class SystemSettingsTypes {}

/**
 * Available application modes.
 *
 * @typedef APPLICATION_MODE
 * @property {string} MOBILE - Mobile application mode.
 * @property {string} DESKTOP - Desktop application mode.
 * @memberof Mojito.Core.Services.SystemSettings.types
 */
SystemSettingsTypes.APPLICATION_MODE = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
};

/**
 * Supported application modes.
 *
 * @typedef APPLICATION_MODES_SUPPORTED
 * @memberof Mojito.Core.Services.SystemSettings.types
 */
SystemSettingsTypes.APPLICATION_MODES_SUPPORTED = Object.values(
    SystemSettingsTypes.APPLICATION_MODE
);
