import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import channelFactory from 'services/common/content/content-channel-factory';
import metaInformationProvider from './provider';
import metaInformationDescriptor from './descriptor.js';

const reduxInstance = MojitoCore.Services.redux;
const { actionsRegistry } = MojitoCore.Services.Content;
const { META_INFORMATION } = metaInformationDescriptor.DATA_TYPES;

export const getMetaInformationChannel = () =>
    channelFactory.getChannel(metaInformationProvider, META_INFORMATION);

/**
 * Defines the state of the meta information store.
 *
 * @typedef MetaInformationState
 *
 * @property {string} liveMatchEvents - Number of live match events.
 * @property {string} liveOutrightEvents - Number of live outright events.
 *
 * @memberof Mojito.Services.MetaInformation
 */

/**
 * The name of the meta information state property. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.MetaInformation
 */
export const STORE_KEY = 'metaInformationStore';

export const INITIAL_STATE = {
    liveMatchEvents: undefined,
    liveOutrightEvents: undefined,
};

export const { reducer, actions } = createSlice({
    name: 'metaInformation',
    initialState: INITIAL_STATE,
    reducers: {
        updateMetaInformation(state, { payload }) {
            const { metaInformation } = payload;

            if (metaInformation) {
                state[metaInformation.id] = metaInformation.info;
            }
        },
        removeMetaInformation(state, { payload }) {
            const { metaInformationId } = payload;

            if (metaInformationId) {
                state[metaInformationId] = undefined;
            }
        },
        reset() {
            return { ...INITIAL_STATE };
        },
    },
});

/**
 * Meta information actions.
 *
 * @class MetaInformationActions
 * @name actions
 * @memberof Mojito.Services.MetaInformation
 */

/**
 * Update meta information.
 *
 * @function updateMetaInformation
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{ metaInformation: object }} payload - Payload for update meta information.
 * @memberof Mojito.Services.MetaInformation.actions
 */

/**
 * Remove meta information.
 *
 * @function removeMetaInformation
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @param {{ metaInformationId: string }} payload - Payload for remove meta information.
 * @memberof Mojito.Services.MetaInformation.actions
 */

/**
 * Reset meta information state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.MetaInformation.actions
 */

/**
 * Subscribe to meta information.
 *
 * @function subscribe
 *
 * @param {{clientId: string, metaInformationId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to sport meta information thunk.
 * @memberof Mojito.Services.MetaInformation.actions
 */
actions.subscribe =
    ({ clientId, metaInformationId }) =>
    dispatch => {
        const onData = (metaInformationId, metaInformation) => {
            dispatch(actions.updateMetaInformation({ metaInformationId, metaInformation }));
        };
        getMetaInformationChannel().subscribe([metaInformationId], clientId, onData);
    };

/**
 * Unsubscribe from meta information.
 *
 * @function unsubscribe
 *
 * @param {string} clientId - The clientId of subscriber to unsubscribe.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from sport meta information thunk.
 * @memberof Mojito.Services.MetaInformation.actions
 */
actions.unsubscribe = clientId => dispatch => {
    getMetaInformationChannel().unsubscribeAll(clientId, metaInformationId => {
        dispatch(actions.removeMetaInformation({ metaInformationId }));
    });
};

actionsRegistry.addSubscription(META_INFORMATION, actions.subscribe, actions.unsubscribe);

reduxInstance.injectReducer(STORE_KEY, reducer);
