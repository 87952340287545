import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { createSlice } from '@reduxjs/toolkit';

const ACCA_BAR_STORAGE_KEY = 'acca-bar-hide';

const NamedStorageService = MojitoCore.Services.Storage.NamedService;
const accaBarStorage = new NamedStorageService(ACCA_BAR_STORAGE_KEY);
const reduxInstance = MojitoCore.Services.redux;

const {
    utils: BetslipUtils,
    selectors: { selectBetslip },
    actions: betslipActions,
} = MojitoServices.Betslip;

/**
 * Defines the state of the acca bar.
 *
 * @typedef AccaBarState
 *
 * @property {boolean} allowedToShow - Flag showing if acca bar should be displayed.
 * @memberof Mojito.Modules.AccaBar
 */

/**
 * The name of the acca bar store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Modules.AccaBar
 */
export const STORE_KEY = 'modules/accaBar';

export const initialState = {
    allowedToShow: true,
};

export const { reducer, actions } = createSlice({
    name: 'accaBar',
    initialState,
    reducers: {
        showAccaBar(state, { payload: value }) {
            state.allowedToShow = value;
        },
        reset() {
            return { ...initialState };
        },
    },
});

/**
 * AccaBar module related actions.
 *
 * @module AccaBar
 * @name actions
 * @memberof Mojito.Modules.AccaBar
 */

/**
 * Toggles flag to allow AccaBar be hidden or displayed.
 *
 * @function showAccaBar
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @param {boolean} value - True if we want accaBar to show.
 *
 * @memberof Mojito.Modules.AccaBar.actions
 */

/**
 * Reset acca bar module state to initial value.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Modules.AccaBar.actions
 */

reduxInstance.actionListener.startListening({
    actionCreator: actions.showAccaBar,
    effect: action => {
        if (!action.payload) {
            accaBarStorage.setItem(true);
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: betslipActions.removePartSuccess,
    effect: (action, listenerApi) => {
        if (BetslipUtils.isBetslipEmpty(selectBetslip())) {
            accaBarStorage.setItem(false);
            listenerApi.dispatch(actions.showAccaBar(true));
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: betslipActions.clearBetslipSuccess,
    effect: (action, listenerApi) => {
        accaBarStorage.setItem(false);
        listenerApi.dispatch(actions.showAccaBar(true));
    },
});

reduxInstance.injectReducer(STORE_KEY, reducer);
