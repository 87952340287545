/**
 * Generates a system unique numerical id.
 *
 * @class IdGenerator
 * @memberof Mojito.Core.Base
 */
export default class IdGenerator {
    static generateId() {
        return IdGenerator.id++;
    }
}

IdGenerator.id = 1;
