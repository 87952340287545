import SystemInitialisationImpl from './system-initialisation.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Component for displaying system initialisation message.
 *
 * @class SystemInitialisation
 *
 * @memberof Mojito.Application
 */

export default UIView('SystemInitialisation', SystemInitialisationImpl);
