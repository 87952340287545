import AbstractTask from 'services/common/task-runner/abstract-task';
import MojitoCore from 'mojito/core';
const log = MojitoCore.logger.get('RefreshBonusOffersTask');

/**
 * Execute refresh bonus offers call on retriever instance.
 *
 * @class RefreshBonusOffersTask
 * @param {Mojito.Services.Betslip.dataRetriever} dataRetriever - Data retriever instance.
 *
 * @classdesc Execute refresh bonus offers call on retriever instance.
 * @memberof Mojito.Services.Betslip
 */
export default class RefreshBonusOffersTask extends AbstractTask {
    constructor(dataRetriever) {
        super();
        this.dataRetriever = dataRetriever;
    }

    execute(callback) {
        if (!this.dataRetriever) {
            log.warn('dataRetriever is missing.');
        }
        this.dataRetriever.refreshBonusOffers().finally(callback);
    }
}
