/**
 * Common filters.
 *
 * @class Filters
 * @memberof Mojito.Presentation.Utils
 */
export default class Filters {}

/**
 * Represents filter options for days. Used for filtering events that occur 'Today' or 'Tomorrow'.
 *
 * @typedef DAY
 * @property {string} TODAY - Represents the filter option for events happening today.
 * @property {string} TOMORROW - Represents the filter option for events happening tomorrow.
 *
 * @memberof Mojito.Presentation.Utils.Filters
 */
Filters.DAY = {
    TODAY: 'today',
    TOMORROW: 'tomorrow',
};
