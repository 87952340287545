import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';
import { resolveTimeZoneSuffix } from 'services/utils';
import { pickBy } from 'mojito/utils';
import UserSettingsTypes from './types.js';

const { ODDS_FORMAT } = UserSettingsTypes;

const reduxInstance = MojitoCore.Services.redux;

/**
 * User settings store selectors.
 *
 * @module UserSettingsSelectors
 * @name selectors
 * @memberof Mojito.Services.UserSettings
 */

/**
 * Selects user settings state.
 *
 * @function selectState
 *
 * @param {object} [state] - Application state object. If not provided then state from global {@link Mojito.Core.Services.redux|redux store} will be returned.
 * @returns {Mojito.Services.UserSettings.UserSettingsState} UserSettings state.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects available odds formats.
 *
 * @function selectAvailableOddsFormats
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Available odds formats.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectAvailableOddsFormats = state => {
    const enabled = selectState(state).enabledOddsFormats;
    const isEnabled = oddsFormat => enabled.includes(oddsFormat);
    return pickBy(ODDS_FORMAT, isEnabled);
};

/**
 * Selects the configured locales.
 *
 * @function selectLocales
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string|string[]} A string with a BCP 47 language tag, or an array of such strings. If no locales are configured, the array will be empty.
 * This is typically interpreted as a prioritized request from the application.
 *
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectLocales = state => selectState(state).locales;

/**
 * Selects time offset as a number.
 *
 * @function selectTimeOffset
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {number|Mojito.Services.UserSettings.types.LOCAL_TIME} Time offset value.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectTimeOffset = state => selectState(state).timeOffset;

/**
 * Selects time zone name in IANA format, e.g. `Europe/Kiev`.
 *
 * @function selectTimeZone
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string|undefined}  Time zone name.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectTimeZone = state => selectState(state).timeZone;

/**
 * Selects time zone name in GMT format, e.g. `gmt+1`.
 *
 * @function selectTimeZoneGmt
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} Time zone in GMT format.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectTimeZoneGmt = state => {
    return resolveTimeZoneSuffix({
        timeZone: selectTimeZone(state),
        timeOffset: selectTimeOffset(state),
    });
};

/**
 * Checks if user's system time is used.
 *
 * @function selectUseLocalTime
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if user's system time is used, false otherwise.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectUseLocalTime = state => selectState(state).useLocalTime;

/**
 * Selects current anonymous user value.
 *
 * @function selectIsAnonymousUser
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} Is anonymous user check.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectIsAnonymousUser = state => selectState(state).isAnonymousUser;

/**
 * Selects current onbording object.
 *
 * @function selectOnboarding
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} Current onboarding object.
 * This object will contain the information
 * if onboardings for navigation and auto cashout
 * have been passed by the user.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectOnboarding = state => selectState(state).onboarding || {};

/**
 * Check if onboarding type enabled.
 *
 * @function selectIsOnboardingEnabled
 *
 * @param {Mojito.Services.UserSettings.types.ONBOARDING_TYPES} [onboardingType] - Onboarding type.
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if onboarding type allowed at the moment.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectIsOnboardingEnabled = (onboardingType, state) =>
    selectState(state).onboardingEnabledMap[onboardingType];

/**
 * Selects user's country code.
 *
 * @function selectCountryCode
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {string} User's country code.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectCountryCode = state => selectState(state).countryCode;

/**
 * Selects currently selected odds format.
 *
 * @function selectOddsFormat
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {Mojito.Services.UserSettings.types.ODDS_FORMAT} The selected odds format as defined in User settings.
 *
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectOddsFormat = state => selectState(state).oddsFormat;

/**
 * Selects the complete user settings object.
 *
 * @function selectUserSettings
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} User settings.
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const selectUserSettings = state => {
    return {
        oddsFormat: selectOddsFormat(state),
        timeOffset: selectTimeOffset(state),
        timeZone: selectTimeZone(state),
        locales: selectLocales(state),
        useLocalTime: selectUseLocalTime(state),
        onboarding: selectOnboarding(state),
        countryCode: selectCountryCode(state),
    };
};

/**
 * Subscribes to the changes of particular user settings state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Services.UserSettings.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
