import MojitoPresentation from 'mojito/presentation';
import SuspendedSelectionLabel from 'modules/betslip/betslip-view/bet-components/suspended-selection-label/index.jsx';

const { FlexPane, Image, Text, EarlyPayoutIndicator } = MojitoPresentation.Components;

const MatchAccaSelectionItem = props => {
    const {
        showSeparator,
        selectionLabel,
        marketName,
        iconStatus,
        isSuspended,
        isEarlyPayout,
        mojitoTools: { config },
    } = props;

    const icon = iconStatus ? config.legStatusIcon[iconStatus] : config.selectionIcon;

    return (
        <FlexPane config={config.selectionContainer}>
            <FlexPane config={config.selectionIconContainer}>
                <Image config={icon} class="ta-SingleBet-selectionIcon" />
                {showSeparator && (
                    <FlexPane class="ta-separator-line" config={config.separatorLine} />
                )}
            </FlexPane>
            <FlexPane config={config.marketInfoContainer}>
                <Text config={config.selectionLabel}>{selectionLabel}</Text>
                <FlexPane config={config.marketNameContainer}>
                    <FlexPane config={config.marketNameInnerContainer}>
                        <Text config={config.marketName}>{marketName}</Text>
                        {isEarlyPayout && (
                            <EarlyPayoutIndicator config={config.earlyPayoutIndicator} />
                        )}
                    </FlexPane>
                    {isSuspended && (
                        <SuspendedSelectionLabel config={config.suspendedSelectionLabel} />
                    )}
                </FlexPane>
            </FlexPane>
        </FlexPane>
    );
};

export default MatchAccaSelectionItem;
