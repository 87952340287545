import MojitoPresentation from 'mojito/presentation';

const IntentTypes = MojitoPresentation.Base.Intent.Types;
const OPEN_BETS = 'OPEN_BETS';

export default {
    /**
     * Defines reasons for logout.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Modules.Login.types
     */
    REASON_TO_SHOW_LOGIN: {
        /** Auto logout message due to user's inactivity. */
        AUTO_LOGOUT_BY_INACTIVITY: 'AUTO_LOGOUT_BY_INACTIVITY',
    },

    /**
     * Defines reasons for login.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Modules.Login.types
     */
    LOGIN_REASON: {
        DEFAULT: 'DEFAULT',
        PLACE_BET: 'PLACE_BET',
        LIVE_STREAM: 'LIVE_STREAM',
        GAMIFICATION_INVITATION: 'GAMIFICATION_INVITATION',
    },

    /**
     * Map defines relation between intent type that enforce user to perform authentication
     * and login reason typically used to show proper login dialog.
     *
     * @typedef ENFORCE_LOGIN_INTENTS
     * @memberof Mojito.Modules.Login.types
     */
    ENFORCE_LOGIN_INTENTS: {
        [IntentTypes.SHOW_SLIDING_OPEN_BETS]: OPEN_BETS,
    },
};
