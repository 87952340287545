/**
 * Abstract messaging service to be implemented by different integrations.
 *
 * @class AbstractMessagingService
 * @abstract
 * @memberof Mojito.Services.Messaging
 */
export default class AbstractMessagingService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - The configuration object.
     *
     * @function MMojito.Services.Messaging.AbstractMessagingService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Connects to messaging endpoints.
     *
     * @param {object} credentials - The object containing the authentication data required for the connection.
     * @param {object} options - The object containing additional information required for the connection to messaging endpoints.
     * @param {Mojito.Services.Messaging.AbstractMessagingService.messageCallback} messageCallback - The function to be executed once a message is received.
     *
     * @function Mojito.Services.Messaging.AbstractMessagingService#connect
     */
    // eslint-disable-next-line no-unused-vars
    connect(credentials, options, messageCallback) {
        // To be implemented by subclasses
    }

    /**
     * Disconnect from messaging endpoints.
     *
     * @function Mojito.Services.Messaging.AbstractMessagingService#disconnect
     */
    disconnect() {
        // To be implemented by subclasses
    }

    /**
     * Sends a message.
     *
     * @param {Mojito.Services.Messaging.types.Message} message - The message object to be sent to an external messaging endpoint.
     *
     * @function Mojito.Services.Messaging.AbstractMessagingService#sendMessage
     */
    // eslint-disable-next-line no-unused-vars
    sendMessage(message) {
        // To be implemented by subclasses
    }
}

/**
 * This callback is invoked when the concrete messaging service implementation receives a message.
 *
 * @callback messageCallback
 * @param {Mojito.Services.Messaging.types.Message} message - The received message object.
 * @memberof Mojito.Services.Messaging.AbstractMessagingService
 */
