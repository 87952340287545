import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {ensureHierarchy} from 'core/utils/config-utils';
import {DBXGoogleAnalytics4Service} from './service';

import SCHEMA from './google-analytics-4.schema.yaml';

class GoogleAnalytics4Feature extends AbstractFeature {
    get name() {
        return 'GoogleAnalytics4';
    }

    get configSchema() {
        return SCHEMA;
    }

    get isThirdParty() {
        return true;
    }

    get isTracker() {
        return true;
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const {measurementId} = this.config;
        const services = ensureHierarchy(mojitoConfig, 'services.analytics.services');

        services.default = services.default || [];
        services.default.push(new DBXGoogleAnalytics4Service(measurementId));

        return super.afterMojitoConfigBuild(mojitoConfig);
    }
}

export const GoogleAnalytics4 = new GoogleAnalytics4Feature(allFeatures);
