import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import SportsMenuImpl from './sports-menu-controller.jsx';
import SportsMenuViewTypes from './types.js';
import { noop } from 'mojito/utils';

const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * A module for viewing sports menu.
 *
 * @class SportsMenu
 * @name module
 * @extends Mojito.Core.Presentation.UIControllerView
 * @memberof Mojito.Modules.SportsMenu
 */

/**
 * `SportsMenu` prop types.
 *
 * @property {Function} [cbSportClicked = ()=>{}] - Callback function triggered when a sport is selected.
 * @property {string} [selectedSportId] - The ID of the currently selected sport.
 * @property {boolean} [showHighlightedSports = true] - Determines whether highlighted sports should be displayed if configured.
 * @property {Mojito.Modules.SportsMenu.types.VIEW_TYPES} [viewType = DEFAULT] - Determines the visual representation of the component.
 * Can be used to display different modes of the component. Possible types are derived from {@link Mojito.Modules.SportsMenu.types.VIEW_TYPES|VIEW_TYPES}.
 *
 * @memberof Mojito.Modules.SportsMenu.module
 */
const propTypes = {
    cbSportClicked: PropTypes.func,
    selectedSportId: PropTypes.string,
    showHighlightedSports: PropTypes.bool,
    viewType: PropTypes.oneOf(Object.values(SportsMenuViewTypes.VIEW_TYPES)),
};

const defaultProps = {
    cbSportClicked: noop,
    showHighlightedSports: true,
    viewType: SportsMenuViewTypes.VIEW_TYPES.DEFAULT,
};

export default UIControllerView('SportsMenu', SportsMenuImpl, propTypes, defaultProps);
