import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';
import EventListItemSkeleton from 'modules/event-list/skeletons/event-list-item-skeleton/index.jsx';
import { memo } from 'react';

const { SkeletonPart, FlexPane } = MojitoPresentation.Components;

const isOdd = value => value % 2 !== 0;

// eslint-disable-next-line react/display-name
const EventListSkeleton = memo(props => {
    const {
        mojitoTools: { config },
    } = props;

    const isAmericanSport = config.americanSports[props.sportId];
    const eventConfig = isAmericanSport ? config.americanEvent : config.event;

    return (
        <FlexPane class="ta-EventListSkeleton" config={config.container}>
            <EventListHeader config={config} isAmericanSport={isAmericanSport} />
            <FlexPane config={config.groupContainer}>
                <GroupHeader config={config} isAmericanSport={isAmericanSport} />
                <FlexPane config={config.eventsContainer}>
                    {range(config.numberOfEvents).map(i => (
                        <EventListItemSkeleton
                            key={i}
                            config={eventConfig}
                            numberOfMarkets={config.numberOfMarkets}
                        />
                    ))}
                </FlexPane>
            </FlexPane>
        </FlexPane>
    );
});

export default EventListSkeleton;

const EventListHeader = ({ config, isAmericanSport }) => {
    if (!config.showEventListTitle && (isAmericanSport || !config.showMarketSwitcher)) {
        return null;
    }

    return (
        <FlexPane config={config.eventListHeaderContainer}>
            {config.showEventListTitle && <SkeletonPart config={config.eventListTitle} />}
            {!isAmericanSport && config.showMarketSwitcher && (
                <FlexPane config={config.marketSwitcherContainer}>
                    {range(config.numberOfMarkets).map(key => (
                        <SkeletonPart key={key} config={config.marketSwitcher} />
                    ))}
                </FlexPane>
            )}
        </FlexPane>
    );
};

const GroupHeader = ({ config, isAmericanSport }) => {
    return (
        <FlexPane config={config.groupHeaderContainer}>
            <FlexPane config={config.typeAndClassContainer}>
                <SkeletonPart config={config.type} />
                <SkeletonPart config={config.class} />
            </FlexPane>
            <FlexPane config={config.groupHeaderMarketsContainer}>
                {range(config.numberOfMarkets).map(key => (
                    <GroupHeaderMarket
                        key={key}
                        config={config}
                        numberOfSelections={isOdd(key + 1) ? 3 : 2}
                        isAmericanSport={isAmericanSport}
                    />
                ))}
            </FlexPane>
        </FlexPane>
    );
};

const GroupHeaderMarket = ({ config, numberOfSelections, isAmericanSport }) => {
    const numberOfSelectionHeaders =
        isAmericanSport && config.numberOfMarkets > 1 ? 1 : numberOfSelections;
    return (
        <FlexPane config={config.groupHeaderMarketContainer}>
            {range(numberOfSelectionHeaders).map(key => (
                <SkeletonPart key={key} config={config.selectionHeader} />
            ))}
        </FlexPane>
    );
};
