import MojitoCore from 'mojito/core';

export default class FlexPaneSeparator extends MojitoCore.Presentation.UIViewImplementation {
    render() {
        return (
            <div
                className={`ta-FlexPaneSeparator ${this.props.class || ''}`}
                style={this.style[this.props.direction]}
            />
        );
    }
}

FlexPaneSeparator.getStyle = config => {
    const {
        size,
        color,
        margin,
        flexGrow = '0',
        flexShrink = '0',
        flexBasis = 'auto',
    } = config.style;
    const baseStyle = {
        backgroundColor: color,
        alignSelf: 'stretch',
        margin,
        flexGrow,
        flexShrink,
        flexBasis,
    };

    // Because of browser bugs (*), it does not work to just set flex: `0 0 ${size}`.
    // Doing so causes a FlexPane container with e.g. flex: '0 0 auto' to not expand to contain
    // its contents. Instead, we explicitly have to set either "width" or "height".
    return {
        row: {
            ...baseStyle,
            width: size,
        },
        'row-reverse': {
            ...baseStyle,
            width: size,
        },
        column: {
            ...baseStyle,
            height: size,
        },
        'column-reverse': {
            ...baseStyle,
            height: size,
        },
    };
};

// (*)
// https://stackoverflow.com/questions/34352140/what-are-the-differences-between-flex-basis-and-width
// https://bugs.chromium.org/p/chromium/issues/detail?id=464210
