import MojitoCore from 'mojito/core';
const ApiProvider = MojitoCore.Services.ApiProvider;

const log = MojitoCore.logger.get();

/**
 * Abstract quick start guide service to be implemented by different integrations.
 *
 * @class AbstractQuickStartGuideService
 * @abstract
 * @memberof Mojito.Services.QuickStartGuide
 */
export default class AbstractQuickStartGuideService extends ApiProvider {
    /**
     * Fetch quick start guides.
     *
     * @param {Function} successCallback - Success callback.
     * @param {Function} errorCallback - Error callback.
     * @function Mojito.Services.QuickStartGuide.AbstractQuickStartGuideService#fetchQuickStartGuides
     */
    // eslint-disable-next-line no-unused-vars
    fetchQuickStartGuides(successCallback, errorCallback) {
        log.error('fetchQuickStartGuides must be implemented by concrete subclass!');
    }
}
