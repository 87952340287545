import { Component } from 'react';
import MojitoNGen from 'mojito/ngen';
import PropTypes from 'prop-types';
import StringResolver from 'core/base/string-utils/string-resolver';

const log = MojitoNGen.logger.get();

/**
 * A base class for view implementations.
 * Bequeaths basic Mojito functionality and offers boilerplate for common tasks.
 * Consider extending this class to gain access to specific Mojito functions.
 * This class acts as a `props` object wrapper and delegates function calls to specific configuration and helper instances inside `props`.
 *
 * @example The <code>resolveString()</code> method delegates to <code>props.stringResolver.resolveString()</code>
 *
 * @class UIViewImplementation
 * @abstract
 * @memberof Mojito.Core.Presentation
 */
export default class UIViewImplementation extends Component {
    /**
     * Get tools prepared for the component by Mojito HoCs.
     *
     * @type {Mojito.Core.Services.Config.ConfigObject}
     */
    get mojitoTools() {
        return this.props.mojitoTools;
    }

    /**
     * Unique component instance id.
     *
     * @type {string}
     */
    get instanceId() {
        return this.mojitoTools.instanceId;
    }

    /**
     * Get component configuration object.
     *
     * @type {Mojito.Core.Services.Config.ConfigObject}
     */
    get config() {
        return this.mojitoTools.config;
    }

    /**
     * Get component's style object.
     *
     * @type {object}
     */
    get style() {
        return this.mojitoTools.style;
    }

    /**
     * Get component's l10n object.
     *
     * @type {object}
     */
    get l10n() {
        return this.mojitoTools.l10n;
    }

    /**
     * Get application context instance.
     *
     * @returns {Mojito.Core.Presentation.AppContext.ContextDefinition} The application context.
     *
     * @function Mojito.Core.Presentation.UIViewImplementation#appContext
     */
    appContext() {
        return this.mojitoTools.appContext;
    }

    /**
     * Get controller view helper. Available only for controller view instances created by UIControllerView HoC.
     * Typically used to retrieve data.
     *
     * @returns {UIViewImplementation.controllerHelper|*} Controller view helper.
     *
     * @function Mojito.Core.Presentation.UIViewImplementation#controllerHelper
     */
    controllerHelper() {
        return this.mojitoTools.controllerHelper;
    }

    /**
     * Delegates call to StringResolver {@link Mojito.Core.Base.StringResolver#resolveAndFormatString|resolveAndFormatString}.
     *
     * @param {string} str - The l10n string containing one or more variable parts.
     * @param  {*} args - Variable number of arguments based on string specification.
     *
     * @returns {string} Translated string.
     * @function Mojito.Core.Presentation.UIViewImplementation#resolveAndFormatString
     */
    resolveAndFormatString(str, ...args) {
        return this.mojitoTools.stringResolver.resolveAndFormatString(str, ...args);
    }

    /**
     * Delegates call to StringResolver {@link Mojito.Core.Base.StringResolver#formatString|formatString}.
     *
     * @param {string} str - The l10n string containing one or more variable parts.
     * @param  {*} args - Variable number of arguments based on string specification.
     *
     * @returns {string} Formatted l10n string.
     *
     * @function Mojito.Core.Presentation.UIViewImplementation#formatString
     */
    formatString(str, ...args) {
        return this.mojitoTools.stringResolver.formatString(str, ...args);
    }

    /**
     * Delegates call to StringResolver {@link Mojito.Core.Base.StringResolver#resolveString|resolveString}.
     *
     * @param {string} strKey - String key.
     *
     * @returns {string} Translated string.
     *
     * @function Mojito.Core.Presentation.UIViewImplementation#resolveString
     */
    resolveString(strKey) {
        return this.mojitoTools.stringResolver.resolveString(strKey);
    }

    /**
     * Delegates call to StringResolver {@link Mojito.Core.Base.StringResolver#resolveString|deriveLabelNamePrefix}.
     *
     * @param {string} strKey - String key.
     *
     * @returns {string} Label name prefix.
     *
     * @function Mojito.Core.Presentation.UIViewImplementation#deriveLabelNamePrefix
     */
    deriveLabelNamePrefix(strKey) {
        return this.mojitoTools.stringResolver.deriveLabelNamePrefix(strKey);
    }

    /**
     * Emit analytics.
     * Retrieves the concrete implementation instance
     * of {@link Mojito.Core.Presentation.AbstractAnalyticsEmitter|AbstractAnalyticsEmitter}
     * and delegates call to {@link Mojito.Core.Presentation.AbstractAnalyticsEmitter#emitAnalytics|emitAnalytics}.
     *
     * @param {string} type - Type of analytics event.
     * @param {*} data - Any type of data that will be used in the analytics report.
     * @function Mojito.Core.Presentation.UIViewImplementation#emitAnalytics
     */
    emitAnalytics(type, data) {
        const context = this.appContext();
        const analyticsEmitter = context && context.analyticsEmitter;
        analyticsEmitter && analyticsEmitter.emitAnalytics(type, data);
    }

    componentDidCatch(error, info) {
        log.error('Unhandled error in UIViewImplementation', error, info);
    }
}

UIViewImplementation.propTypes = {
    mojitoTools: PropTypes.shape({
        appContext: PropTypes.object,
        l10n: PropTypes.object,
        style: PropTypes.object,
        config: PropTypes.object,
        stringResolver: PropTypes.instanceOf(StringResolver),
    }),
};
