import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import ApplicationMenu from 'application/components/applicationheader/application-menu/index.jsx';
import MainNavigationBar from 'application/components/applicationheader/main-navigation-bar/index.jsx';
import SystemInitialisation from 'application/components/applicationheader/system-initialisation/index.jsx';
import { connect } from 'react-redux';
import MojitoServices from 'mojito/services';

const { HideablePane, CookieConsent, AppBanner } = MojitoPresentation.Components;
const { UIViewImplementation } = MojitoCore.Presentation;

const LAYOUT = Object.freeze({
    NORMAL: 'normal',
    COMPACT: 'compact',
});
const { shouldHideBanner } = MojitoServices.AppBanner.selectors;
const { selectCookieConsent } = MojitoCore.Services.CookieConsent.selectors;

class ApplicationHeader extends UIViewImplementation {
    isUsingLayout(layout) {
        return layout === this.config.layout;
    }

    renderSystemInitialisation() {
        if (this.isUsingLayout(LAYOUT.NORMAL) || !this.config.showSystemInitialisation) {
            return null;
        }
        return <SystemInitialisation config={this.config.systemInitialisation} />;
    }

    renderMainNavigationBar(config) {
        if (this.config.mainNavigationBar.hide) {
            return null;
        }

        return <MainNavigationBar config={config} />;
    }

    renderCookieConsent() {
        return (
            <div style={this.config.cookieConsentWrapper}>
                <HideablePane
                    config={this.config.cookieConsentContainer}
                    hidden={this.config.cookieConsentView.hide || this.props.isCookieConsentGiven}
                >
                    <CookieConsent />
                </HideablePane>
            </div>
        );
    }

    renderAppBanner() {
        return (
            <HideablePane
                config={this.config.appBannerContainer}
                hidden={!this.shouldShowAppBanner()}
            >
                <AppBanner />
            </HideablePane>
        );
    }

    shouldShowAppBanner() {
        const { appBannerView, cookieConsentView } = this.config;
        const { shouldHideBanner, isCookieConsentGiven } = this.props;

        const shouldShowCookieConsent = !cookieConsentView.hide && !isCookieConsentGiven;
        const shouldShowAppBanner = !appBannerView.hide && !shouldHideBanner;

        return !shouldShowCookieConsent && shouldShowAppBanner;
    }

    renderHeaderContent() {
        const configLayout = `${this.config.layout}Layout`;
        const componentsConfig = this.config[configLayout];

        return (
            <div style={this.config.headerContentContainer}>
                <ApplicationMenu
                    config={componentsConfig.applicationMenu}
                    userInfo={this.props.userInfo}
                    freeBets={this.props.freeBets}
                />
                {this.renderMainNavigationBar(componentsConfig.mainNavigationBar)}
            </div>
        );
    }

    renderApplicationHeader() {
        return (
            <>
                {this.renderCookieConsent()}
                {this.renderAppBanner()}
                {this.renderHeaderContent()}
                {this.renderSystemInitialisation()}
            </>
        );
    }

    onTouchMove(event) {
        // When header is sticky, disable touch scrolling content underneath
        event.preventDefault();
    }

    render() {
        return (
            <header
                className="ta-ApplicationHeader"
                style={this.config.stickyHeader ? this.config.headerContainer : null}
                onTouchMove={this.config.stickyHeader ? this.onTouchMove : undefined}
            >
                {this.renderApplicationHeader()}
            </header>
        );
    }
}

const mapStateToProps = state => ({
    shouldHideBanner: shouldHideBanner(state),
    isCookieConsentGiven: selectCookieConsent(state),
});
export default connect(mapStateToProps)(ApplicationHeader);
