// PUT and PATCH methods we can add on demand
class ApiHandler {
    async request({url, method, payload}) {
        const requestOptions = {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (payload) {
            requestOptions.body = JSON.stringify(payload);
        }

        const response = await fetch(url, requestOptions);

        if (response.status !== 200) throw Error(`Server responded with error status ${response.status}. Url: ${url}`);

        if (method === 'GET') {
            try {
                return await response.json();
            } catch (err) {
                // leave it empty
            }
        }

        return null;
    }

    async get(url) {
        return this.request({url, method: 'GET'});
    }

    async post(url, payload) {
        return this.request({url, payload, method: 'POST'});
    }
}

class PortalApiHandler {
    constructor() {
        this.api = new ApiHandler();
    }

    async getCustomConfig() {
        return this.api.get('/sportsbookCustomization');
    }

    async saveCustomConfig(newConfig) {
        return this.api.post('/cp/sportsbookCustomization', newConfig);
    }

    async getPostInitConfig(configPromise) {
        if (configPromise === undefined || configPromise === null) {
            return this.api.get('/sportsbookPostInitConf');
        }

        if (typeof configPromise === 'object') {
            return Promise.resolve(configPromise);
        }

        return configPromise;
    }

    async savePostInitConfig(newConfig) {
        return this.api.post('/cp/sportsbookPostInitConf', newConfig);
    }
}

export const PortalApi = new PortalApiHandler();
