import PropTypes from 'prop-types';
import SwiperImpl from './swiper.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * Swiper component.
 *
 * @class Swiper
 * @memberof Mojito.Presentation.Components
 */

/**
 * Swiper prop types.
 *
 * @property {node} [children] - Children react elements. Content to slide.
 * @property {boolean} [hideNavigationArrows] - A flag indicating whether carousel navigation arrows should be displayed or not.
 * @property {Function} [onSlideChange = () => {}] - Callback triggered when swiper slide changed.
 * @property {Function} [onPrepareSlidesToRender = () => {}] - Callback triggered when swiper slides are prepared to render.
 * As a parameter in accepts an array with a list of slide indexes that are visible or going to be visible soon after swipe left ot right.
 * @memberof Mojito.Presentation.Components.Swiper
 */
const propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.arrayOf(PropTypes.func),
        PropTypes.node,
    ]),
    hideNavigationArrows: PropTypes.bool,
    onSlideChange: PropTypes.func,
    onPrepareSlidesToRender: PropTypes.func,
};

const defaultProps = {
    hideNavigationArrows: false,
    onSlideChange: noop,
    onPrepareSlidesToRender: noop,
};

export default UIView('Swiper', SwiperImpl, propTypes, defaultProps);
