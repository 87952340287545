import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

const {
    actions: quickStartGuideActions,
    selectors: { selectGuides, selectMenu },
} = MojitoServices.QuickStartGuide;

const { QuickStartGuideDialog } = MojitoPresentation.Components;

export default function QuickStartGuide() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(quickStartGuideActions.fetchQuickStartGuides());
    }, [dispatch]);

    const guides = useSelector(selectGuides);
    const menu = useSelector(selectMenu);

    return <QuickStartGuideDialog menu={menu} guides={guides} />;
}
