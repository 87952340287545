import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';

const Breadcrumbs = MojitoModules.Breadcrumbs.module;
const BreadcrumbsTypes = MojitoModules.Breadcrumbs.types;
const VirtualSportsMenu = MojitoModules.Virtuals.VirtualSportsMenu;
const VirtualEventsCoupon = MojitoModules.Virtuals.VirtualEventsCoupon;
const SportsMenu = MojitoModules.SportsMenu.module;
const { BannerPromotions, PromotionUtils } = MojitoModules.Promotions;
const {
    PageHeader,
    PageSplitLayout,
    GlobalLinks,
    FlexPane,
    DocumentHead,
    DefaultHeadMetaFactory,
    EventDisclaimer,
} = MojitoPresentation.Components;
const { UIViewImplementation } = MojitoCore.Presentation;

export default class VirtualSportPageView extends UIViewImplementation {
    constructor(props) {
        super(props);

        // Creating the MetaFactory instance here to avoid re-creating it on every render()
        const metaFactory = this.config.documentHeadMetaFactory || DefaultHeadMetaFactory;
        this.documentHeadMetaFactory = new metaFactory(this.l10n);

        this.onPageHeaderBackClicked = this.onPageHeaderBackClicked.bind(this);
    }

    onPageHeaderBackClicked() {
        window.history.back();
    }

    renderBreadcrumbs() {
        if (this.config.hideBreadcrumbs) {
            return null;
        }

        const breadcrumbs = [
            {
                type: BreadcrumbsTypes.TEXT_LABEL,
                data: this.resolveString('$VIRTUAL_SPORT_PAGE.TITLE'),
                analyticsData: 'Virtuals',
            },
        ];

        return <Breadcrumbs breadcrumbs={breadcrumbs} />;
    }

    renderPageHeader() {
        if (this.config.hideHeader) {
            return null;
        }

        return (
            <PageHeader
                label={this.resolveString('$VIRTUAL_SPORT_PAGE.TITLE')}
                config={this.config.pageHeader}
                onBackClicked={this.onPageHeaderBackClicked}
            />
        );
    }

    renderContent() {
        const { sportId, classId } = this.props;

        if (!sportId || !classId) {
            return null;
        }

        return (
            <VirtualEventsCoupon
                selectedEventId={this.props.selectedEventId}
                sportId={sportId}
                classId={classId}
            />
        );
    }

    renderVirtualSportsMenu() {
        return (
            <VirtualSportsMenu selectedItemId={this.props.classId} sportId={this.props.sportId} />
        );
    }

    renderAllSportsMenu() {
        if (this.config.hideSportsMenu) {
            return null;
        }
        return <SportsMenu />;
    }

    renderSecondaryPromotions() {
        const { leftPromotions: promotionsConfig } = this.config;
        if (!PromotionUtils.hasLocationId(promotionsConfig)) {
            return;
        }

        return (
            <BannerPromotions
                config={promotionsConfig}
                key={promotionsConfig.locationId}
                applicablePage="virtuals"
            />
        );
    }

    renderSecondaryContent() {
        return (
            <FlexPane config={this.config.menuContainer}>
                {this.renderVirtualSportsMenu()}
                {this.renderAllSportsMenu()}
                <GlobalLinks />
                {/* Left promotion */}
                {this.renderSecondaryPromotions()}
            </FlexPane>
        );
    }

    renderDocumentHead(sportId, classId) {
        const { sportInfo: sport } = this.props;
        const sportName = sport?.canonicalName || '-';
        const sportClass = sport?.items?.find(sportClass => sportClass.id === classId) || {};
        const className = sportClass?.canonicalName || '-';

        const headData = {
            title: '$VIRTUAL_SPORT_PAGE.PAGE_TITLE',
            keywords: '$VIRTUAL_SPORT_PAGE.PAGE_KEYWORDS',
            description: '$VIRTUAL_SPORT_PAGE.PAGE_DESCRIPTION',
            canonical: '$VIRTUAL_SPORT_PAGE.PAGE_CANONICAL',
            localizedParams: new Map([
                ['sportName', sportName],
                ['className', className],
            ]),
            params: { sportId, classId },
        };

        return <DocumentHead data={headData} metaFactory={this.documentHeadMetaFactory} />;
    }

    render() {
        const { sportId, classId } = this.props;

        return (
            <>
                {this.renderDocumentHead(sportId, classId)}
                <PageSplitLayout>
                    {/* Primary content */}
                    <FlexPane config={this.config.mainContainer}>
                        {this.renderBreadcrumbs()}
                        {this.renderPageHeader()}
                        {this.config.showCompactLayout ? this.renderVirtualSportsMenu() : null}
                        {this.renderContent()}
                        <EventDisclaimer sportId={sportId} isVirtual={true} />
                    </FlexPane>

                    {/* Secondary content */}
                    {!this.config.showCompactLayout && this.renderSecondaryContent()}
                </PageSplitLayout>
            </>
        );
    }
}
