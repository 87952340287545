import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';
import AccountMenu from 'application/components/applicationheader/account-menu/index.jsx';

const { Button, FlexPane } = MojitoPresentation.Components;
const { dispatch } = MojitoCore.Services.redux.store;
const intentActions = MojitoCore.Intents.actions;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const loginActions = MojitoModules.Login.actions;
const { LOGGED_IN, LOGGED_OUT } = MojitoServices.Authentication.types.STATES;

const log = MojitoCore.logger.get('UserAuthenticationInfo');

// Map used to determine what render function to run based on configs in YAML
const ITEM_RENDER_FUNCTION_MAP = {
    SIGNUP_BTN: 'renderSignupButton',
    LOGIN_BTN: 'renderLoginButton',
    ACCOUNT_MENU: 'renderAccountMenu',
};

export default class UserAuthenticationInfo extends UIViewImplementation {
    constructor(props) {
        super(props);

        const loginAnalyticsData = { type: 'openLogin', data: 'Header' };
        this.onLoginClick = this.onLoginButtonClick(
            this.config.loginButton,
            loginAnalyticsData,
            () => dispatch(loginActions.showLoginView())
        );

        this.onSignupClick = this.onSignupButtonClick(this.config.signupButton);
    }

    onSignupButtonClick(config) {
        return () => {
            const { intent } = config;

            if (intent && intent.type) {
                dispatch(intentActions.publishIntent(intent.type, intent.data));
            }
        };
    }

    onLoginButtonClick(config, analyticsData, defaultAction) {
        return () => {
            const { intent } = config;

            this.emitAnalytics(analyticsData.type, analyticsData.data);

            if (intent && intent.type) {
                dispatch(intentActions.publishIntent(intent.type, intent.data));
            } else {
                defaultAction();
            }
        };
    }

    _isLoggedIn() {
        return this.appContext().getLoginState() === LOGGED_IN;
    }

    // Rendering
    renderLoginButton() {
        if (this._isLoggedIn()) {
            return null;
        }

        // The button should only be enabled if we are in logged out state. The other states (e.g. UNKNOWN, LOGGING_IN)
        // imply that we are already doing some user authentication related action.
        const isReadyToLogIn = this.appContext().getLoginState() === LOGGED_OUT;

        return (
            <Button
                config={this.config.loginButton.button}
                onClick={this.onLoginClick}
                disabled={!isReadyToLogIn}
                class="ta-loginButton"
                key="login-info"
            >
                {this.resolveString(this.config.loginButton.labelKey)}
            </Button>
        );
    }

    renderAccountMenu() {
        if (!this._isLoggedIn()) {
            return null;
        }

        return (
            <AccountMenu
                key="account-menu"
                userInfo={this.props.userInfo}
                freeBets={this.props.freeBets}
            />
        );
    }

    renderSignupButton() {
        if (this._isLoggedIn() || !this.config.signupButton.intent) {
            return null;
        }

        return (
            <Button
                onClick={this.onSignupClick}
                config={this.config.signupButton.button}
                class="ta-signupButton"
                key="signup-button"
            >
                {this.resolveString(this.config.signupButton.labelKey)}
            </Button>
        );
    }

    renderItems() {
        const items = [];

        // Based on whatever's in item config we will render signup, login, etc
        this.config.items.forEach(itemType => {
            const itemRenderFunctionName = ITEM_RENDER_FUNCTION_MAP[itemType];

            if (itemRenderFunctionName) {
                items.push(this[itemRenderFunctionName]());
            } else {
                log.error('Unsupported item type', itemType);
            }
        });

        return items;
    }

    render() {
        return (
            <FlexPane config={this.config.container} class="ta-UserAuthenticationInfo">
                {this.renderItems()}
            </FlexPane>
        );
    }
}
