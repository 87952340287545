import PropTypes from 'prop-types';
import InPlayMenu from './inplay-menu.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Module that displays InPlay sports and allows the user to switch between the sports.
 *
 * @class InPlayMenu
 * @name InPlayMenu
 * @memberof Mojito.Modules.InPlay
 */

/**
 * `InPlayMenu` prop types.
 *
 * @property {string} [selectedSport] - The sport code of the currently selected sport.
 * @property {Function} [cbSportClicked = ()=>{}] - Callback function triggered when a sport is clicked.
 * @property {boolean} [hasPendingGroups] - Indicates if there's ongoing data loading.
 * @property {Array<string>} [sportIds = []] - Ids of the InPlay sports.
 * @property {object} [eventGroups = {}] - Event groups of the InPlay sports.
 *
 * @memberof Mojito.Modules.InPlay.InPlayMenu
 */
const propTypes = {
    eventGroups: PropTypes.object,
    cbSportClicked: PropTypes.func,
    selectedSport: PropTypes.string,
    hasPendingGroups: PropTypes.bool,
    sportIds: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    sportIds: [],
    eventGroups: {},
    cbSportClicked: noop,
};

export default UIView('InPlayMenu', InPlayMenu, propTypes, defaultProps);
