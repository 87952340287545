import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';
import MojitoCore from 'mojito/core';
import { isEmpty } from 'mojito/utils';

const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const SportContextMenuItems = MojitoPresentation.Components.SportContextMenuList.types.ITEMS;

const { selectTimeZoneGmt } = MojitoServices.UserSettings.selectors;

const { useMenuContent } = MojitoModules.Common.Hooks;
const { CustomRedirect } = MojitoPresentation.Components;

const log = MojitoCore.logger.get('SportDefaultPage');

const { RacingHelper } = MojitoPresentation.Utils;

export default function SportDefaultPage({ mojitoTools }) {
    const { config, appContext } = mojitoTools;
    const { sportId } = useParams();

    const menusContentInfo = useMenusContentInfo(sportId);

    if (!menusContentInfo || isEmpty(menusContentInfo)) {
        return null;
    }

    const defaultPages = getDefaultPages(config, sportId);
    const defaultPage = getDefaultPageWithContent(defaultPages, menusContentInfo);
    const routeResolver = appContext.routeResolver;
    let routingData = RacingHelper.isRacingSport(sportId)
        ? NavigationPathBuilder.toSportHighlights(sportId)
        : NavigationPathBuilder.toSportCompetitions(sportId);
    let sportPageRoute = routeResolver.getRoute(routingData);

    if (defaultPage) {
        if (
            defaultPage === SportContextMenuItems.HIGHLIGHTS ||
            defaultPage === SportContextMenuItems.COMPETITIONS ||
            defaultPage === SportContextMenuItems.OUTRIGHTS ||
            defaultPage === SportContextMenuItems.MEETINGS ||
            defaultPage === SportContextMenuItems.PRICE_BOOSTS ||
            defaultPage === SportContextMenuItems.MATCHES ||
            defaultPage === SportContextMenuItems.FUTURE
        ) {
            routingData = NavigationPathBuilder.toSportCategory(sportId, defaultPage);
            sportPageRoute = routeResolver.getRoute(routingData);
            return <CustomRedirect to={sportPageRoute} isReplace={true} />;
        }
        log.warn(`Sport page not supported: ${defaultPage}`);
    }

    return <CustomRedirect to={sportPageRoute} isReplace={true} />;
}

function useMenusContentInfo(sportId) {
    const timeZone = useSelector(state => selectTimeZoneGmt(state));

    return useMenuContent(sportId, timeZone);
}

function getDefaultPageWithContent(defaultPages, content) {
    const contentKeys = Object.keys(content);
    const pageTitleWithContent = defaultPages
        .map(page => page.toLowerCase())
        .find(page => !!contentKeys.find(key => key.includes(page) && !!content[key]));

    return pageTitleWithContent?.toLowerCase();
}

function getDefaultPages(pages, sportId) {
    const sportType = RacingHelper.isRacingSport(sportId) ? 'racing' : 'match';
    const pagesPerSport = pages[sportType] || {};

    return pagesPerSport[sportId] || pagesPerSport.default;
}
