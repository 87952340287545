import MojitoCore from 'mojito/core';

const DataProvider = MojitoCore.Services.DataProvider;
const COLLECTION_NAME = 'raceRegions';

/**
 *
 * Class offering the possibility to link to specific race region lists.
 *
 * @class RaceRegionProvider
 * @extends Mojito.Core.Services.DataProvider
 * @memberof Mojito.Services.SportsContent.DataProviders
 */
class RaceRegionProvider extends DataProvider {
    /**
     * Initialize the race region provider.
     *
     * @function Mojito.Services.SportsContent.DataProviders.RaceRegionProvider#init
     */
    init() {
        super.addSimpleCollection(COLLECTION_NAME);
    }

    subscribeToEntity(raceRegionId, setter) {
        return super.subscribeToEntity(raceRegionId, setter, COLLECTION_NAME);
    }
}

export default new RaceRegionProvider();
