import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoModules from 'mojito/modules';
import MojitoApplication from 'mojito/application';
import Build from '../core/generated/build.js';
import { isEmpty } from 'mojito/utils';

const reduxInstance = MojitoCore.Services.redux;
const { dispatch } = reduxInstance.store;
const AuthenticationTypes = MojitoServices.Authentication.types;
const { selectLoginState, subscribe: subscribeAuthentication } =
    MojitoServices.Authentication.selectors;
const { selectLoginViewVisibility, subscribe: subscribeLoginModuleState } =
    MojitoModules.Login.selectors;
const authenticationActions = MojitoServices.Authentication.actions;
const { subscribe: subscribeEvents } = MojitoServices.SportsContent.Events.selectors;
const SSOAuthenticationService = MojitoServices.Authentication.SSOAuthenticationService;
const { selectUserInfo } = MojitoServices.UserInfo.selectors;
const loginActions = MojitoModules.Login.actions;

const appSettingsActions = MojitoModules.AppSettings.actions;
const { selectAppSettingsViewVisibility, subscribe: subscribeAppSettingsModuleState } =
    MojitoModules.AppSettings.selectors;

const { selectLanguage } = MojitoCore.Services.SystemSettings.selectors;
const SystemSettingsActions = MojitoCore.Services.SystemSettings.actions;
const TranslationService = MojitoCore.Services.TranslationService;
const { selectEvents } = MojitoServices.SportsContent.Events.selectors;
const { selectIsInitialized, subscribe: subscribeApplicationStoreState } =
    MojitoApplication.selectors;

const betslipActions = MojitoServices.Betslip.actions;
const { actions: openBetsActions } = MojitoServices.Bets.openBetsSlice;
const cookieConsentActions = MojitoCore.Services.CookieConsent.actions;

const log = MojitoCore.logger.get('VersionedAPI');
const { LOGIN_ERRORS, STATES } = MojitoServices.Authentication.types;

// These are actually not errors, but the beginning of other workflows initiated after a successful login, to e.g. change password or accept T&C.
const LOGIN_ERRORS_WITH_CALL_TO_ACTION = new Set([
    LOGIN_ERRORS.TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED,
    LOGIN_ERRORS.CHANGE_PASSWORD_IS_REQUIRED,
    LOGIN_ERRORS.CHANGE_PIN_IS_REQUIRED,
]);

/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 * <p>The Mojito Versioned API offers a simple API that allows outside interaction with Mojito in a controlled way.
 * It was created to allow the native iOS app interact with Mojito and control a few minor things.</p>
 *
 * <p>The API consists of a few messages than can be posted to a message handler, and also a few functions that return directly.
 * The messages act as callback information to actions that happen in the Mojito framework.
 * They are delivered as stringified JSON objects.</p>
 *
 * <h4>Messages</h4>
 * <ul>
 *
 * <li>
 * <p>{ type: 'initialization', data: { status: 'ok', version: STRING, mojitoVersion: STRING, hasReceivedEvents: BOOLEAN, loginState: STRING } }</p>
 * <p>Description: Sent when initialization is successfully completed. If 'hasReceivedEvents' is false, an 'events-received' message should come shortly if all is well.</p>
 * </li>
 *
 * <li>
 * <p>{ type: 'events-received' }</p>
 * <p>Description: Gives a crude indication when there are some events available. Can be used to determine if the landing page is ready to be shown.</p>
 * </li>
 *
 * <li>
 * <p>{ type: 'login-view', data: { isOpen: BOOLEAN } }</p>
 * <p>Description: Indicates if the Login view was requested to be shown or hidden.</p>
 * </li>
 *
 * <li>
 * <p>{ type: 'login', data: { loginState: STRING, logoutReason: {@link Mojito.Services.Authentication.types.LOGOUT_REASON|LOGOUT_REASON}, errorCode: STRING, errorMessage: STRING, sessionToken: STRING, userId: STRING } }</p>
 *
 * <p>Description: Message will only be fired when <code>loginState</code> changes to either LOGGED_IN or LOGGED_OUT.
 * <code>errorCode</code> will either be null or contain an error code as a fixed string.
 * <code>errorMessage</code> is a localized string that is provided when an error has occurred.</p>
 *
 * <p>Error codes:
 * 'wrong-credentials', 'account-is-locked', 'account-temporarily-locked',
 * 'unknown', 'terms-and-conditions-acceptance-is-required',
 * 'change-password-is-required', 'change-pin-is-required' and 'ims-error'.</p>
 *
 * <p>If <code>errorCode</code> is set to 'ims-error', <code>errorMessage</code> can potentially consist
 * of several error messages. The messages will be joined together into one message.</p>
 * </li>
 *
 * <li>
 * <p>{ type: 'app-settings-view', data: { isOpen: BOOLEAN } }</p>
 * <p>Description: Indicates if the app settings view was requested to be shown or hidden.</p>
 * </li>
 *
 * </ul>
 *
 * <h4>Testing API from Chrome</h4>
 * <ol>
 *
 * <li>
 * <p>Initialize the API to post all messages to the console. Type the following in the Chrome console:</p>
 *
 * <pre>mojito_versioned_api.default.init(console.log)</pre>
 * </li>
 *
 * <li>
 * <p>Assume the native app uses a native login dialog that fires a login using this API:</p>
 *
 * <pre>mojito_versioned_api.default.login("PhonyUser", "pa$$w0rd")</pre>
 * </li>
 * </ol>
 *
 * <h4>Usage from iOS native app</h4>
 * <ol>
 *
 * <li>
 * <p>Before the API should used, the client should check that the API has the correct version
 * to ensure that the behaviour and functionality is the expected.</p>
 * </li>
 *
 * <li>
 * <p>The second step is to initialize the API and set up a message handler that consumes the
 * events.</p>
 *
 * <p>The iOS web view has a handler at "window.webkit.messageHandlers.updateApplicationState.postMessage",
 * so to set it up from Swift something like this can be done:</p>
 *
 * <pre>webView.evaluateJavaScript("mojito_versioned_api.default.init(function(msg) { webkit.messageHandlers.updateApplicationState.postMessage(JSON.stringify(msg)); })"</pre>
 *
 * <p>During development it could be valuable to combine the webkit message handler with a regular console log:</p>
 *
 * <pre>webView.evaluateJavaScript("mojito_versioned_api.default.init(function(msg) { webkit.messageHandlers.updateApplicationState.postMessage(JSON.stringify(msg)); console.log(msg); })"</pre>
 * </li>
 * </ol>
 *
 * <h4>Configuration of the sportsbook</h4>
 *
 * <p>The sportsbook can be provided an additional context which will be considered in all yaml
 * configurations.</p>
 *
 * <p>Example, add configuration:</p>
 *
 * @example Login: {
 *   loginView: {
 *     _native_hide: true,
 *   },
 * }
 *
 *
 * <p>Provide additionalContext 'native' in the URL as in the examples below:</p>
 *
 * <ul>
 * <li><pre>http://example.com/?additionalContext=native</pre></li>
 * <li><pre>http://example.com/?otherParam=test&additionalContext=native</pre></li>
 * </ul>
 *
 * <b>The additionalContext will be saved to Local Storage when initializing. Reloading the page will set context, navigating within the page will not.</b>
 *
 * <p>If additionalContext need to be removed it can be done by passing an empty additionalContext, like so:</p>
 *
 * <pre>http://example.com/?additionalContext=</pre>
 *
 * @namespace VersionedAPI
 * @memberof Mojito
 */
/* eslint-enable jsdoc/require-description-complete-sentence */
class VersionedAPI {
    constructor() {
        this._isInitialized = false;
        this._postMessageFunc = undefined;
        this._translations = undefined;
        this._hasSomeEvents = false;
        this._isLoginViewVisible = selectLoginViewVisibility();

        this.onLoginStateChange = this.onLoginStateChange.bind(this);
        this.onLoginViewVisibilityChange = this.onLoginViewVisibilityChange.bind(this);
        this.onAppSettingsChanged = this.onAppSettingsChanged.bind(this);
        this.onApplicationInitChange = this.onApplicationInitChange.bind(this);
    }

    /**
     * Returns the version number of the API.
     *
     * @returns {string} The version formatted as a string.
     * @function Mojito.VersionedAPI#version
     */
    version() {
        return '2.0.0';
    }

    /**
     * Initializes the API and starts monitoring stores. No messages will be sent and the API may
     * not be usable until the 'initialization' message has been received with 'status': 'ok'.
     *
     * @param {Function} postMessageFunc - The function that should handle the post messages. The function should accept a string as parameter.
     * @function Mojito.VersionedAPI#init
     */
    init(postMessageFunc) {
        this._postMessageFunc = postMessageFunc;

        if (selectIsInitialized()) {
            this._finalizeInit();
        } else {
            this.unsubscribeApplicationStoreState = subscribeApplicationStoreState(
                'isInitialized',
                this.onApplicationInitChange
            );
        }
    }

    /**
     * Informs client that initialization has completed.
     *
     * @function Mojito.VersionedAPI#_finalizeInit
     * @private
     */
    _finalizeInit() {
        this._translations = TranslationService.getTranslations(this.getLanguage());

        cookieConsentActions.giveConsent();

        this.unsubscribeLoginModuleState = subscribeLoginModuleState(
            'isVisible',
            this.onLoginViewVisibilityChange
        );
        this.unsubscribeAppSettingsModuleState = subscribeAppSettingsModuleState(
            'isViewVisible',
            this.onAppSettingsChanged
        );

        this._hasSomeEvents = !isEmpty(selectEvents());

        if (!this._hasSomeEvents) {
            this.unsubscribeEvents = subscribeEvents('events', events => {
                if (!isEmpty(events)) {
                    this._hasSomeEvents = true;
                    this.postMessage('events-received');
                    this.unsubscribeEvents();
                }
            });
        }

        this.unsubscribeLoginState = subscribeAuthentication('loginState', this.onLoginStateChange);

        this._isInitialized = true;
        this.postMessage('initialization', {
            status: 'ok',
            version: this.version(),
            mojitoVersion: Build.appVersion,
            hasReceivedEvents: this._hasSomeEvents,
            loginState: this.getLoginState(),
        });

        // No sonar comment was added due to wrong complain about cross-origin communication
        // No prettier - to keep no sonar on the same line
        // prettier-ignore
        window.addEventListener('beforeunload', event => { // NOSONAR
            this.unsubscribeLoginModuleState();
            this.unsubscribeAppSettingsModuleState();
            this.unsubscribeLoginState();
            this.unsubscribeEvents();
            this.unsubscribeApplicationStoreState();
            event.preventDefault();
            this.postMessage('terminate');
        });
    }

    /**
     * Checks if API is initialized.
     *
     * @returns {boolean} True if API have been initialized.
     * @function Mojito.VersionedAPI#isInitialized
     */
    isInitialized() {
        return this._isInitialized;
    }

    /**
     * Posts a message to a function provided during initialization.
     *
     * @param {string} type - The type name for the message.
     * @param {object} data - Optional data object that will be passed along with the message.
     * @function Mojito.VersionedAPI#postMessage
     * @private
     */
    postMessage(type, data) {
        if (this._postMessageFunc) {
            // sometimes it happens that an error occur on android which breaks the whole functionality -> MOJ-8381
            try {
                this._postMessageFunc({ type, data });
            } catch (e) {
                log.warn('postMessage failed! ERROR: ', e);
            }
        }
    }

    /**
     * Get the translated error message based on an error code.
     *
     * @param {Mojito.Core.Services.Transactions.types.Error} errorInfo - Error info object.
     * @returns {string} String intended for end-user provided in selected language.
     *
     * @function Mojito.VersionedAPI#getLoginErrorMessage
     * @private
     */
    getLoginErrorMessage(errorInfo) {
        const { type, messages } = errorInfo;
        const errorCodes = AuthenticationTypes.LOGIN_ERRORS;
        const errors = this._translations.LOGIN_DIALOG.ERRORS;

        let errorMsg;

        switch (type) {
            case errorCodes.WRONG_CREDENTIALS:
                errorMsg = errors.WRONG_CREDENTIALS;
                break;
            case errorCodes.ACCOUNT_IS_LOCKED:
                errorMsg = errors.ACCOUNT_LOCKED;
                break;
            case errorCodes.ACCOUNT_TEMPORARILY_LOCKED:
                errorMsg = errors.ACCOUNT_TEMPORARILY_LOCKED;
                break;
            case errorCodes.UNKNOWN:
                errorMsg = errors.UNKNOWN;
                break;
            case errorCodes.TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED:
                errorMsg = errors.TERMS_AND_CONDITIONS_ACCEPTANCE_IS_REQUIRED;
                break;
            case errorCodes.CHANGE_PASSWORD_IS_REQUIRED:
                errorMsg = errors.CHANGE_PASSWORD_IS_REQUIRED;
                break;
            case errorCodes.CHANGE_PIN_IS_REQUIRED:
                errorMsg = errors.CHANGE_PIN_IS_REQUIRED;
                break;
            case errorCodes.IMS_ERROR:
                // The approach here of joining multiple error messages into one message was requested by Native.
                errorMsg = messages.join(' ');
                break;
            default:
                errorMsg = '';
        }

        return errorMsg;
    }

    /**
     * Handler for application init status change.
     *
     * @param {boolean} isInitialized - Initializing state. True if initialized.
     * @function Mojito.VersionedAPI#onApplicationInitChange
     * @private
     */
    onApplicationInitChange(isInitialized) {
        if (isInitialized) {
            this.unsubscribeApplicationStoreState && this.unsubscribeApplicationStoreState();
            this._finalizeInit();
        }
    }

    /**
     * Monitors Authentication Store login state and posts changes.
     *
     * @param {Mojito.Services.Authentication.types.STATES} loginState - Login state.
     * @param {object} authenticationState - State of authentication store.
     * @function Mojito.VersionedAPI#onLoginStateChange
     * @private
     */
    onLoginStateChange(loginState, authenticationState) {
        // Only fire a notification if we're in a proper state.
        if (loginState === STATES.LOGGED_IN || loginState === STATES.LOGGED_OUT) {
            const sessionInfo = authenticationState.sessionInfo;
            const errorInfo = authenticationState.errorInfo;
            const errorType = errorInfo && errorInfo.type;

            if (LOGIN_ERRORS_WITH_CALL_TO_ACTION.has(errorType)) {
                // When any of the special events errors occur, a modal is shown which the user can act on. The native app needs to close the native login view in order to show this view.
                // Therefore, we need to ask the native app to close the login view instead of displaying an error. Hence, we post the 'login-view' type and ignore posting the error.
                // TODO: Ideally, these special cases should be handled on the native side. Will be addressed in DNA-1318 and DNA-1319.
                this._isLoginViewVisible = false;
                this.postMessage('login-view', {
                    isOpen: this._isLoginViewVisible,
                });
            } else {
                this.postMessage('login', {
                    loginState,
                    logoutReason: authenticationState.logoutReason,
                    errorCode: errorType,
                    errorMessage: errorInfo && this.getLoginErrorMessage(errorInfo),
                    sessionToken: sessionInfo.sessionId,
                    userId: selectUserInfo().userId,
                });
            }
        }
    }

    /**
     * Monitors login module state and posts changes.
     *
     * @param {boolean} isVidible - Whether the login view visible or not .
     * @function Mojito.VersionedAPI#onLoginViewVisibilityChange
     * @private
     */
    onLoginViewVisibilityChange(isVidible) {
        if (this._isLoginViewVisible !== isVidible) {
            this.postMessage('login-view', {
                isOpen: isVidible,
            });
            this._isLoginViewVisible = isVidible;
        }
    }

    /**
     * Monitors AppSettings Store and posts changes.
     *
     * @function Mojito.VersionedAPI#onAppSettingsChanged
     * @private
     */
    onAppSettingsChanged() {
        this.postMessage('app-settings-view', {
            isOpen: selectAppSettingsViewVisibility(),
        });
    }

    /**
     * Checks if any events have been received. Can be used to verify the state of some pages.
     *
     * @returns {boolean} True if events have been received.
     * @function Mojito.VersionedAPI#hasReceivedEvents
     */
    hasReceivedEvents() {
        return this._hasSomeEvents;
    }

    /**
     * Get available languages.
     *
     * @returns {Array} Array of objects containing both code and name for the available languages.
     * @function Mojito.VersionedAPI#getAvailableLanguages
     */
    getAvailableLanguages() {
        return TranslationService.getAvailableLanguages();
    }

    /**
     * Get default language.
     *
     * @returns {string} The language code for the default language for the site.
     * @function Mojito.VersionedAPI#getLanguage
     */
    getLanguage() {
        return selectLanguage();
    }

    /**
     * Checks if a provided language code is supported.
     *
     * @param {string} languageCode - Language code for the language to test.
     * @returns {boolean} True if the language is supported.
     * @function Mojito.VersionedAPI#isLanguageAvailable
     */
    isLanguageAvailable(languageCode) {
        return TranslationService.isLanguageAvailable(languageCode);
    }

    /**
     * Sets the selected language. This will reload the page from scratch and requires to initialize the API again.
     *
     * @param {string} languageCode - Language code for the language to set.
     * @function Mojito.VersionedAPI#setLanguage
     */
    setLanguage(languageCode) {
        dispatch(SystemSettingsActions.updateLanguage({ language: languageCode }));
        this._translations = TranslationService.getTranslations(languageCode);
    }

    /**
     * Login a user. Response will be posted back through native channel.
     *
     * @param {string} username - Username.
     * @param {string} password - Password.
     * @param {Mojito.Services.Authentication.types.CREDENTIALS_PUBLIC_TYPE} [loginMethod = Mojito.Services.Authentication.types.CREDENTIALS_PUBLIC_TYPE.USERNAME] - Login method. Only applicable if automatic login method detection has not been set up.
     * @function Mojito.VersionedAPI#login
     */
    login(username, password, loginMethod = AuthenticationTypes.CREDENTIALS_PUBLIC_TYPE.USERNAME) {
        dispatch(authenticationActions.login({ userName: username, password, loginMethod }));
    }

    /**
     * Logout the current user. Response will be posted back through native channel.
     *
     * @function Mojito.VersionedAPI#logout
     */
    logout() {
        dispatch(authenticationActions.logout());
    }

    /**
     * Exposes the current login state.
     *
     * @returns {Mojito.Services.Authentication.types.STATES} The current user login state.
     * @function Mojito.VersionedAPI#getLoginState
     */
    getLoginState() {
        return selectLoginState();
    }

    /**
     * Shows the app settings view.
     *
     * @function Mojito.VersionedAPI#showAppSettings
     */
    showAppSettings() {
        dispatch(appSettingsActions.showAppSettingsView());
    }

    /**
     * Hides the app settings view.
     *
     * @function Mojito.VersionedAPI#hideAppSettings
     */
    hideAppSettings() {
        dispatch(appSettingsActions.hideAppSettingsView());
    }

    /**
     * Returns true if the app settings view is visible.
     *
     * @function Mojito.VersionedAPI#isAppSettingsVisible
     * @returns {boolean} True if view is visible.
     */
    isAppSettingsVisible() {
        return selectAppSettingsViewVisibility();
    }

    /**
     * Post login state from SSO.
     *
     * @function Mojito.VersionedAPI#restoreSession
     */
    restoreSession() {
        if (SSOAuthenticationService && SSOAuthenticationService.ssoServiceImpl) {
            SSOAuthenticationService.restoreSession(
                () => this.postMessage('sso-state', { state: STATES.LOGGED_IN }),
                () => this.postMessage('sso-state', { state: STATES.LOGGED_OUT })
            );
        }
    }

    enableCashoutButton() {
        dispatch(openBetsActions.enableCashout());
    }

    disableCashoutButton() {
        dispatch(openBetsActions.disableCashout());
    }

    enableBetPlacementButton() {
        dispatch(betslipActions.betPlacementAvailable(true));
    }

    disableBetPlacementButton() {
        dispatch(betslipActions.betPlacementAvailable(false));
    }

    /**
     * Notify Mojito about login view closing.
     *
     * @function Mojito.VersionedAPI#hideLoginView
     */
    hideLoginView() {
        // Avoid triggering message to native
        this._isLoginViewVisible = false;

        dispatch(loginActions.hideLoginView());
    }

    /**
     * Provide native wrapper version information to Mojito.
     * Native app should invoke this and the method on initialization.
     *
     * @example setWrapperVersion({name: 'native', version: '1.3.6'})
     *
     * @param {object} config - Description for wrapper version. Recommended properties are name and version.
     * @function Mojito.VersionedAPI#setWrapperVersion
     */
    setWrapperVersion(config) {
        this._wrapperConfig = config;
    }

    /**
     * Get native wrapper version information.
     *
     * @function Mojito.VersionedAPI#getWrapperVersion
     * @returns {object} Wrapper version information.
     */
    getWrapperVersion() {
        return this._wrapperConfig;
    }

    /**
     * This function trigger the loginSuccess action.
     *
     * @param {string} data - Http response from Authentication.login action.
     * @function loginSuccess
     */
    loginSuccess(data) {
        const loginData = JSON.parse(data).data;
        const { userId, userName } = loginData.userInfo;
        if (userId && !userName) {
            loginData.userInfo.userName = userId;
        }
        dispatch(authenticationActions.loginSuccess(loginData));
    }
}

// Export the singleton interface
export default new VersionedAPI();
