/**
 * Sliding betslip types.
 *
 * @class SlidingBetslipTypes
 * @name types
 * @memberof Mojito.Modules.SlidingBetslip
 */
export default class SlidingBetslipTypes {}

/**
 * Types of betslip presented within sliding view.
 *
 * @typedef BETSLIP_TYPE
 * @property {string} STANDARD_BETSLIP - Standard full size betslip.
 * @property {string} QUICK_BETSLIP - Quick betslip, typically gives user an opportunity to do quick single bet placement.
 * @memberof Mojito.Modules.SlidingBetslip.types
 */
SlidingBetslipTypes.BETSLIP_TYPE = {
    STANDARD_BETSLIP: 'standardBetslip',
    QUICK_BETSLIP: 'quickBetslip',
};
