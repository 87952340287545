import MojitoNGen from 'mojito/ngen';
const log = MojitoNGen.logger.get('StorageService');

/**
 * Storage service for storing / restoring data from provided storage API and/or cookies.
 *
 * Nothing we save at the moment (applicationMode, language, oddsFormat, etc)
 * require consent according to http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm,
 * so this service currently impose no requirement on user consent - everything is
 * unconditionally stored.
 *
 * @param {Storage} storageApi - The Storage interface of the browser Web Storage API.
 *
 * @class StorageService
 * @memberof Mojito.Core.Services.Storage
 */
export class StorageService {
    constructor(storageApi) {
        if (!storageApi) {
            log.error(
                'Storage API instance is not provided. ' +
                    'Should be either window.localStorage or window.sessionStorage'
            );
        }
        this.storageApi = storageApi;
    }

    /**
     * Get the given key's value or returns null if the key is absent.
     *
     * @param {string} key - The property key.
     * @returns {string} The property value or null if the key is absent.
     *
     * @function Mojito.Core.Services.Storage.StorageService#getItem
     */
    getItem(key) {
        if (!key) {
            log.warn('Falsy key specified in getItem');
        }
        return this.storageApi.getItem(key);
    }

    /**
     * Assigns the provided value to the specified key.
     *
     * @param {string} key - The property key.
     * @param {string} value - The value to be stored.
     *
     * @function Mojito.Core.Services.Storage.StorageService#setItem
     */
    setItem(key, value) {
        if (!key) {
            log.warn('Falsy key specified in setItem');
        }
        try {
            this.storageApi.setItem(key, value);
        } catch (error) {
            log.warn(`Failed to set item ${key}: ${error}`);
        }
    }

    /**
     * Checks if a value with the specified key exists.
     *
     * @param {string} key - The property key.
     * @returns {boolean} True if a value is associated with the key, false otherwise.
     *
     * @function Mojito.Core.Services.Storage.StorageService#hasItem
     */
    hasItem(key) {
        return localStorage.getItem(key) !== null;
    }

    /**
     * Removes the value associated with the specified key.
     *
     * @param {string} key - The key of the item to remove.
     *
     * @function Mojito.Core.Services.Storage.StorageService#removeItem
     */
    removeItem(key) {
        if (!key) {
            log.warn('Falsy key specified in removeItem');
        }
        this.storageApi.removeItem(key);
    }

    /**
     * Retrieves the cookie value associated with the specified name.
     *
     * @param {string} name - The cookie name.
     * @returns {string} The cookie value.
     *
     * @function Mojito.Core.Services.Storage.StorageService#getCookie
     */
    getCookie(name) {
        if (!name) {
            return null;
        }

        return (
            decodeURIComponent(
                document.cookie.replace(
                    new RegExp(
                        `(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(
                            /[-.+*]/g,
                            '\\$&'
                        )}\\s*\\=\\s*([^;]*).*$)|^.*$`
                    ),
                    '$1'
                )
            ) || null
        );
    }

    /**
     * Sets a cookie with the specified properties.
     *
     * @param {string} name - The name of the cookie.
     * @param {string} value - The value of the cookie.
     * @param {number} days - The number of days until the cookie expires.
     * @param {string} path - The path associated with the cookie (default is '/').
     *
     * @function Mojito.Core.Services.Storage.StorageService#setCookie
     */
    setCookie(name, value, days, path = '/') {
        doSetCookie(name, value, days, path);
    }

    /**
     * Removes the cookie with the specified name.
     *
     * @param {string} name - The name of the cookie to delete.
     *
     * @function Mojito.Core.Services.Storage.StorageService#removeCookie
     */
    removeCookie(name) {
        doSetCookie(name, '', -1);
    }

    /**
     * Checks whether a cookie with the specified name exists.
     *
     * @param {string} name - The name of the cookie.
     * @returns {boolean} True if the cookie exists, false otherwise.
     *
     * @function Mojito.Core.Services.Storage.StorageService#cookieExists
     */
    cookieExists(name) {
        return document.cookie.includes(`${name}=`);
    }
}

// Private helpers

function doSetCookie(name, value, days, path = '/') {
    const expires = new Date(Date.now() + days * 1000 * 60 * 60 * 24).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`;
}

/**
 * Represents an instance of the StorageService class, which utilizes the localStorage API.
 *
 * @class StorageService
 * @name service
 * @memberof Mojito.Core.Services.Storage
 */
export default new StorageService(window.localStorage);
