import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { isEmpty } from 'mojito/utils';
import { usePromotions } from 'modules/promotions/hooks';
import CmsLinksUtils from 'modules/sport-context-menu/utils/cms-links-utils';
import MenuLinkPromotions from 'modules/promotions/menu-links/index.jsx';

const { CMS_COUPON } = MojitoServices.Analytics.types.LINK_CATEGORIES;
const { LinkMenu, FlexPane, Image } = MojitoPresentation.Components;
const { Types } = MojitoPresentation.Base.Intent;
const { PRICE_BOOST_EVENT_GROUP_NAME } = MojitoServices.EventGroups.types;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { LOCATION: PROMOTION_LOCATION } = MojitoServices.Promotions.types;
const ALL_SPORTS_PRICE_BOOST = PRICE_BOOST_EVENT_GROUP_NAME.ALL_SPORTS;
const PRICE_BOOST_LINK = 'priceBoostLink';

const priceBoostPath = NavigationPathBuilder.toPriceBoost();
const toPriceBoostIntent = { type: Types.VIEW, data: priceBoostPath };
const getPriceBoostHref = routeResolver => routeResolver.getRoute(priceBoostPath);
const getPriceBoostLabel = stringResolver =>
    stringResolver.resolveString('$PAGE_CONTEXT_MENU.ALL_SPORTS_PRICE_BOOSTS');

const PageContextMenu = props => {
    const { config } = props.mojitoTools;
    return config.useRowLayout ? <RowLinkMenu {...props} /> : <TileLinkMenu {...props} />;
};

const TileLinkMenu = props => {
    const { applicablePage } = props;
    const { config, stringResolver, appContext } = props.mojitoTools;
    const promotions = usePromotions(PROMOTION_LOCATION.QUICK_LINKS, applicablePage) || [];
    const showPriceBoostQuickLink = promotions.some(
        promotion => promotion['id'] === ALL_SPORTS_PRICE_BOOST
    );

    const filteredPromotions = promotions.filter(id => id.id !== ALL_SPORTS_PRICE_BOOST);
    const { routeResolver } = appContext;
    const links = CmsLinksUtils.getCmsLinks(filteredPromotions, routeResolver);
    showPriceBoostQuickLink &&
        links.push(createPriceBoostLink(stringResolver, routeResolver, config));

    if (isEmpty(links)) {
        return null;
    }
    const headerLabel = stringResolver.resolveString('$PAGE_CONTEXT_MENU.CMS_LINKS_SECTION_HEADER');
    const header = { label: headerLabel };
    return (
        <LinkMenu
            config={config.tileLinkMenu}
            class="ta-QuickLinks"
            items={links}
            header={header}
        />
    );
};

const RowLinkMenu = props => {
    const { applicablePage } = props;
    const { config, stringResolver, appContext } = props.mojitoTools;
    const { routeResolver, analyticsEmitter } = appContext;
    const promotions = usePromotions(PROMOTION_LOCATION.QUICK_LINKS, applicablePage) || [];
    const showPriceBoostQuickLink = promotions.some(
        promotion => promotion['id'] === ALL_SPORTS_PRICE_BOOST
    );

    const priceBoostLink = showPriceBoostQuickLink && {
        id: PRICE_BOOST_LINK,
        data: getPriceBoostLabel(stringResolver),
        onClickIntent: toPriceBoostIntent,
        hrefLink: getPriceBoostHref(routeResolver),
        className: 'ta-priceBoostLink',
        iconRenderer: () => iconRenderer(config),
    };
    const extraLinks = priceBoostLink ? [priceBoostLink] : [];
    const onLinkClick = item => analyticsEmitter.emitAnalytics('cmsQuickLinksClicked', item.data);
    return (
        <MenuLinkPromotions
            config={config.rowLinkMenu}
            extraLinks={extraLinks}
            locationId={PROMOTION_LOCATION.QUICK_LINKS}
            applicablePage={applicablePage}
            onLinkClick={onLinkClick}
        />
    );
};

const createPriceBoostLink = (stringResolver, routeResolver, config) => {
    const label = getPriceBoostLabel(stringResolver);
    return {
        id: PRICE_BOOST_LINK,
        label,
        intent: toPriceBoostIntent,
        analyticsData: {
            type: CMS_COUPON,
            linkName: label,
        },
        hrefLink: getPriceBoostHref(routeResolver),
        className: `ta-priceBoostLink`,
        iconRenderer: () => iconRenderer(config),
    };
};

const iconRenderer = config => {
    const { priceBoostLink } = config;
    return (
        <FlexPane config={priceBoostLink.iconContainer}>
            <FlexPane config={priceBoostLink.iconBackground}>
                <Image config={priceBoostLink.icon} />
            </FlexPane>
        </FlexPane>
    );
};
export default PageContextMenu;
