import MojitoCore from 'mojito/core';
import AbstractUserInfoService from './abstract-user-info-service.js';
import ApiRequestProvider from 'services/common/api-request/request-provider.js';

const USER_BASE_URL = '/user';

const logger = MojitoCore.logger.get('UserInfoService');

/**
 * User info service config.
 *
 * @typedef UserInfoServiceConfig
 * @type {object}
 * @property {string} serviceUrl - URL that will be used to perform API calls.
 *
 * @memberof Mojito.Services.UserInfo.service
 */

/**
 *
 * Class offering the possibility to interact with the user API.
 *
 * @class UserInfoService
 * @name service
 * @memberof Mojito.Services.UserInfo
 */
class UserInfoService extends AbstractUserInfoService {
    /**
     * Configure service.
     *
     * @param {Mojito.Services.UserInfo.service.UserInfoServiceConfig} config - Service configuration object.
     *
     * @function Mojito.Services.UserInfo.service#configure
     */
    configure(config) {
        this.serviceUrl = config.serviceUrl;
        this.requestFactory = new ApiRequestProvider(logger);
        this.apiUrl = `${this.serviceUrl + USER_BASE_URL}/`;
    }

    /**
     * Get balance.
     *
     * @param {string} currency - User currency code.
     *
     * @returns {Promise} Get balance promise.
     * @function Mojito.Services.UserInfo.service#configure
     */
    getBalance(currency) {
        return this.requestFactory
            .get(`${this.apiUrl}get-balance?currency=${currency}`)
            .withDescription('get balance')
            .withCredentials()
            .send();
    }
}

export default new UserInfoService();
