/**
 * @class MarketTypes
 * @memberof Mojito.Modules
 */
export default class MarketTypes {}

/**
 * Types of selections headers.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.MarketTypes
 */
MarketTypes.HEADER_CONTENT = {
    /** Show participant name over selections column/row that belongs to it. */
    PARTICIPANT: 'participant',
    /** Show headers based on the type of the selections in column/row, e.g. 'Over', 'Under'. */
    TYPE: 'type',
};

/**
 * Markets layout types.
 *
 * @readonly
 * @enum {number}
 * @memberof Mojito.Modules.MarketTypes
 */
MarketTypes.LAYOUT_TYPE = {
    /** One way market type. Can be used to display selections in one columns. */
    ONE_WAY: 1,
    /** Two way market type, e.g., Over/Under. Selections typically displayed in two columns. */
    TWO_WAY: 2,
    /** Three way market type, e.g., Match result - Home/Draw/Away. Selections typically displayed in three columns. */
    THREE_WAY: 3,
    /** Free form type. This type of markets doesn't have any strict requirements for selections layout.
     * E.g., Team A goals number - 0/1/2/3/3+ etc. Selections typically displayed in two columns. */
    FREE_FORM: 0,
};

/**
 * Market direction. Defines selections flow within market.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.MarketTypes
 */
MarketTypes.DIRECTION = {
    /** Child markets will be rendered in a column. */
    COLUMN: 'column',
    /** Child markets will be rendered in a row. */
    ROW: 'row',
    /** Child markets will be rendered responsively based on the content.  */
    AUTO: 'auto',
};

/**
 * Resolves selection groups for a specific market.
 * Useful for column-based selection presentation according to a defined criterion. The criterion implementation is function-specific.
 * E.g., If criterion is <code>selection.type</code> and market contains Over 1.5, Under 1.5, Over 2.5, Under 2.5, selections divide into two groups - Over and Under.
 *
 * @callback selectionsGroupResolver
 *
 * @param {object} market - Market data object.
 * @param {Array<string>} groupingMask - List of groups identifiers. Will be used as criteria to generate selection group per identifier.
 * Selections will be organized between groups according to this mask. The groups order will reflect the order of corresponding identifiers withing mask list.
 * @param {boolean} switchHomeAway - True if home and away groups are suppose to switch places.
 * Mainly used in american sports where home/away selections have reverse order within market line.
 * @returns {Array<Array<object>>} List of selection groups. Each selections group is a list of selections.
 *
 * @memberof Mojito.Modules.MarketTypes
 */
