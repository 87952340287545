import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {ensureHierarchy} from 'core/utils/config-utils';
import {ExternalNavigationService} from './external-navigation-service';

class ExternalNavigationFeature extends AbstractFeature {
    get name() {
        return 'External Navigation';
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const externalNavigationService = ensureHierarchy(mojitoConfig, 'services.externalNavigationService');
        externalNavigationService.service = new ExternalNavigationService();

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }
}

new ExternalNavigationFeature(allFeatures);
