/**
 * Class defining system information data descriptors.
 *
 * @class PromotionsDataDescriptors
 * @name descriptor
 * @memberof Mojito.Services.Promotions
 */
export default class PromotionsDataDescriptors {
    /**
     * Factory method for creating a system information data descriptor.
     *
     * @param {string} locationId - The location id.
     *
     * @returns {{dataType: string}} System information data descriptor.
     *
     * @function Mojito.Services.Promotions.descriptor#createPromotionLocationDescriptor
     * @static
     */
    static createPromotionLocationDescriptor(locationId) {
        return {
            dataType: PromotionsDataDescriptors.DATA_TYPES.PROMOTION_LOCATION,
            locationId: locationId,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} PROMOTION_LOCATION - Descriptor type for the promotions location.
 *
 * @memberof Mojito.Services.Promotions.descriptor
 */
PromotionsDataDescriptors.DATA_TYPES = {
    PROMOTION_LOCATION: 'promotion-location',
};
