import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';

import SCHEMA from './other-configs.schema.yaml';

// This feature supposed to contain all configs which couldn't be included into other features
class OtherConfigsFeature extends AbstractFeature {
    get name() {
        return 'Other Configurations';
    }

    get configSchema() {
        return SCHEMA;
    }
}

new OtherConfigsFeature(allFeatures);
