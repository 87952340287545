import DateTimeTypes from './types.js';
import MojitoNGen from 'mojito/ngen';

const { OFFSET_FORMAT } = DateTimeTypes;
const log = MojitoNGen.logger.get('DateTimeUtils');

const formatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hourCycle: 'h23',
};

const MILLISECONDS_IN_MINUTE = 60000;
const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;

const timezoneFormatter = timeZone => new Intl.DateTimeFormat('en', { ...formatOptions, timeZone });
const utcFormatter = timezoneFormatter('UTC');

/**
 * Method returns the difference, between a date as evaluated in the UTC time zone,
 * and the same date as evaluated in the time zone provided in <code>timeZone</code> parameter.
 * The results are presented in units defined by <code>offsetFormat</code> parameter.
 *
 * @param {string} timeZone - IANA time zone name, e.g. `America/New_York`.
 * @param {Mojito.Core.Base.DateTimeTypes.OFFSET_FORMAT} [offsetFormat = HOURS] - Desired offset format.
 *
 * @returns {number|undefined} Number that represents time zone offset or undefined.
 * @function Mojito.Core.Base.DateTimeUtils.getTimezoneOffset
 */
const getTimezoneOffset = (timeZone, offsetFormat = OFFSET_FORMAT.HOURS) => {
    if (!timeZone) {
        return;
    }
    if (!OFFSET_FORMAT.hasOwnProperty(offsetFormat)) {
        log.error('Unknown offset format ', offsetFormat);
        return;
    }
    // Pure JS doesn't have a simple way to evaluate UTC offset between two time zones so far.
    // That is why we create two dates object from time zone formatted date strings, then
    // we evaluate time difference and return it in desired format.
    const now = new Date();
    const utcDate = new Date(utcFormatter.format(now));
    const tzDate = new Date(timezoneFormatter(timeZone).format(now));
    const diff = utcDate.getTime() - tzDate.getTime();

    const toMinutes = offset => offset / MILLISECONDS_IN_MINUTE;
    const toHours = offset => toMinutes(offset) / 60;
    const formatters = {
        [OFFSET_FORMAT.MINUTES]: toMinutes,
        [OFFSET_FORMAT.HOURS]: toHours,
    };
    const offsetFormatter = formatters[offsetFormat];
    return Math.floor(offsetFormatter(diff));
};

/**
 * Method returns the difference, between a date as evaluated in the UTC time zone,
 * and the same date as evaluated in local system time zone.
 * The results is presented in units defined by <code>offsetFormat</code> parameter.
 *
 * @param {Mojito.Core.Base.DateTimeTypes.OFFSET_FORMAT} [offsetFormat = HOURS] - Desired offset format.
 *
 * @returns {number|undefined} Current system UTC offset or undefined if incorrect <code>offsetFormat</code> has been provided.
 * @function Mojito.Core.Base.DateTimeUtils.getLocalTimezoneOffset
 */
const getLocalTimezoneOffset = (offsetFormat = OFFSET_FORMAT.HOURS) => {
    if (!OFFSET_FORMAT.hasOwnProperty(offsetFormat)) {
        log.error('Unknown offset format ', offsetFormat);
        return;
    }
    const localOffset = new Date().getTimezoneOffset();
    const toHours = offset => offset / 60;
    const formatters = {
        [OFFSET_FORMAT.MINUTES]: offset => offset,
        [OFFSET_FORMAT.HOURS]: toHours,
    };
    const formatter = formatters[offsetFormat];
    return formatter(localOffset);
};

/**
 * Adds a specified number of years to a given date.
 *
 * @example DateTimeUtils.addYears(new Date(2015, 11, 2), 6) => Thu Dec 02 2021.
 *
 * // Note: This takes into account leap years. For example, adding six years to 'Feb 29 2016' results in 'Feb 28 2022', since there's no 'Feb 29' in 2022.
 *
 * @param {Date} date - The date to be modified.
 * @param {number} amount - The number of years to be added.
 *
 * @returns {Date} A new date with the added years.
 * @function Mojito.Core.Base.DateTimeUtils.addYears
 */
const addYears = (date, amount) => {
    return addMonths(date, amount * 12);
};

/**
 * Adds a specified number of months to a given date, respecting monthly boundaries.
 * <br>
 * For instance, DateTimeUtils.addMonths(new Date(2015, 11, 2), 6) => Thu Jun 02 2016.
 * <br><br>
 * Note: Using #addMonths shifts month and also adjusts year if necessary.
 * An example is adding one month to 'Dec 31 2016', it returns 'Feb 28 2017', preserving end of month boundaries.
 *
 * @param {Date} date - The date to be modified.
 * @param {number} amount - The number of months to be added.
 *
 * @returns {Date} A new date with the added months.
 * @function Mojito.Core.Base.DateTimeUtils.addMonths
 */
const addMonths = (date, amount) => {
    const dateTime = new Date(date);
    const dayOfMonth = dateTime.getDate();

    const endOfDesiredMonth = new Date(dateTime.getTime());
    endOfDesiredMonth.setMonth(dateTime.getMonth() + amount + 1, 0);

    const daysInMonth = endOfDesiredMonth.getDate();
    if (dayOfMonth >= daysInMonth) {
        return endOfDesiredMonth;
    }

    dateTime.setFullYear(endOfDesiredMonth.getFullYear(), endOfDesiredMonth.getMonth(), dayOfMonth);
    return dateTime;
};

/**
 * Add the specified number of days to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of days to be added.
 *
 * @returns {Date} The new date with the days added.
 * @function Mojito.Core.Base.DateTimeUtils.addDays
 */
const addDays = (date, amount) => {
    return new Date(date.getTime() + amount * MILLISECONDS_IN_DAY);
};

/**
 * Add the specified number of hours to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of hours to be added.
 *
 * @returns {Date} The new date with the hours added.
 * @function Mojito.Core.Base.DateTimeUtils.addHours
 */
const addHours = (date, amount) => {
    return new Date(date.getTime() + amount * MILLISECONDS_IN_HOUR);
};

/**
 * Add the specified number of minutes to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of minutes to be added.
 *
 * @returns {Date} The new date with the minutes added.
 * @function Mojito.Core.Base.DateTimeUtils.addMinutes
 */
const addMinutes = (date, amount) => {
    return new Date(date.getTime() + amount * MILLISECONDS_IN_MINUTE);
};

/**
 * Add the specified number of milliseconds to the given date.
 *
 * @param {Date} date - The date to be changed.
 * @param {number} amount - The amount of milliseconds to be added.
 *
 * @returns {Date} The new date with the milliseconds added.
 * @function Mojito.Core.Base.DateTimeUtils.addMilliseconds
 */
const addMilliseconds = (date, amount) => {
    return new Date(date.getTime() + amount);
};

/**
 * Get the number of hours between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in hours. Can be a negative value if secondDate occurs after firstDate.
 * @function Mojito.Core.Base.DateTimeUtils.diffInHours
 */
const diffInHours = (firstDate, secondDate) => {
    return (firstDate - secondDate) / MILLISECONDS_IN_HOUR;
};

/**
 * Get the number of minutes between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in minutes. Can be a negative value if secondDate occurs after firstDate.
 * @function Mojito.Core.Base.DateTimeUtils.diffInMinutes
 */
const diffInMinutes = (firstDate, secondDate) => {
    return (firstDate - secondDate) / MILLISECONDS_IN_MINUTE;
};

/**
 * Get the number of seconds between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in seconds. Can be a negative value if secondDate occurs after firstDate.
 * @function Mojito.Core.Base.DateTimeUtils.diffInSeconds
 */
const diffInSeconds = (firstDate, secondDate) => {
    return (firstDate - secondDate) / 1000;
};
/**
 * Get the number of milliseconds between the given dates.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {number} The difference in milliseconds. Can be a negative value if secondDate occurs after firstDate.
 * Can be also number with fractions.
 * @function Mojito.Core.Base.DateTimeUtils.diffInMilliseconds
 */
const diffInMilliseconds = (firstDate, secondDate) => {
    return firstDate - secondDate;
};

/**
 * Check whether the given dates are in the same day.
 *
 * @param {Date} firstDate - The first date.
 * @param {Date} secondDate - The second date.
 *
 * @returns {boolean} The dates are in the same day.
 * @function Mojito.Core.Base.DateTimeUtils.isSameDay
 */
const isSameDay = (firstDate, secondDate) => {
    return (
        firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
    );
};

/**
 * Checks if a date corresponds to the specified number of days forward in time.
 *
 * @param {Date} currentDate - A date and time string in ISO 8601 format.
 * @param {number} amountDays - Number of days forward to compare with.
 * @param {string|number} timeOffset - UTC time offset in hours.
 *
 * @returns {boolean} True if date corresponds to the specified number of days forward in time. False otherwise.
 * @function Mojito.Core.Base.DateTimeUtils.isSameDateWithOffset
 */
const isSameDateWithOffset = (currentDate, amountDays, timeOffset) => {
    const localTimezoneOffset = getLocalTimezoneOffset();

    const timeZoneWithOffset = timeOffset + localTimezoneOffset;
    const dateToCheck = addHours(currentDate, timeZoneWithOffset);
    const dateNow = addHours(new Date(), timeZoneWithOffset);
    const dateForward = addDays(dateNow, amountDays);

    return isSameDay(dateToCheck, dateForward);
};

/**
 * Date time utility functions. The given functions do not mutate passed parameters, particularly dates.
 *
 * @class DateTimeUtils
 * @memberof Mojito.Core.Base
 */
export default {
    getTimezoneOffset,
    getLocalTimezoneOffset,
    addYears,
    addMonths,
    addDays,
    addHours,
    addMinutes,
    addMilliseconds,
    diffInHours,
    diffInMinutes,
    diffInSeconds,
    diffInMilliseconds,
    isSameDay,
    isSameDateWithOffset,
};
