import PropTypes from 'prop-types';
import SearchViewImpl from './search-view.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View for allowing data search input and result presentation.
 *
 * @class SearchView
 * @memberof Mojito.Application.Search
 */

/**
 * SearchView prop types.
 *
 * @property {object} searchResult - Search result (error or found objects).
 * @property {Array<string>} recentItems - Recent items.
 * @property {string} [searchInputResetState = ''] - Random string, every time it changes, the search input will be reset..
 * @property {Mojito.Application.Search.RecentSearch.itemCallback} [onRemoveRecentSearch = ()=>{}] - Remove recent search clicked callback.
 * @property {Function} [onSearchClose = ()=>{}] - On search close callback.
 * @property {boolean} [initiallyActive = false] - Whether the component should be initially active.
 *
 * @memberof Mojito.Application.Search.SearchView
 */
const propTypes = {
    searchResult: PropTypes.object.isRequired,
    recentItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    searchInputResetState: PropTypes.string,
    onRemoveRecentSearch: PropTypes.func,
    onSearchClose: PropTypes.func,
    initiallyActive: PropTypes.bool,
};

const defaultProps = {
    onRemoveRecentSearch: noop,
    onSearchClose: noop,
    initiallyActive: false,
};

export default UIView('SearchView', SearchViewImpl, propTypes, defaultProps);
