import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get();

/**
 * Abstract match acca service to be implemented by different integrations.
 * Note: All methods must be implemented in an asynchronous way and return promise.
 *
 * @class AbstractMatchAccaService
 * @abstract
 * @memberof Mojito.Services.MatchAcca
 */
export default class AbstractMatchAccaService {
    /**
     * Configure the service.
     * The configuration properties are specific to the concrete implementations.
     *
     * @param {object} config - Config object.
     * @function Mojito.Services.MatchAcca.AbstractMatchAccaService#configure
     */
    // eslint-disable-next-line no-unused-vars
    configure(config) {
        // To be implemented by subclasses
    }

    /**
     * Calculate odds.
     *
     * @param {Array<Mojito.Services.Betslip.types.Selection>} selectionsLine - List of selections.
     * @param {Mojito.Services.Betslip.types.LEG_SORT} legSort - Leg sort.
     * @param {Mojito.Services.UserSettings.types.ODDS_FORMAT} oddsFormat - Odds format.
     *
     * @returns {Promise} Calculate odds promise.
     * @function Mojito.Services.MatchAcca.AbstractMatchAccaService#calculateOdds
     */
    // eslint-disable-next-line no-unused-vars
    calculateOdds(selectionsLine, legSort, oddsFormat) {
        log.error('calculateOdds must be implemented by concrete subclass');
        return Promise.reject();
    }
}
