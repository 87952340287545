import ObjUtils from 'core/base/objutils';
import { omit } from 'mojito/utils';
import IdGenerator from 'core/base/idgenerator';

import { META } from 'core/config/config-factory';
import CustomPrefixer from './custom-prefixer/custom-prefixer';
import MojitoNGen from 'mojito/ngen';

const log = MojitoNGen.logger.get('UIStyle');

/**
 * Singleton for supporting vendor prefixes on inline style objects.
 *
 * @class UIStyle
 * @memberof Mojito.Core.Presentation
 */

class UIStyle {
    /**
     * Initialize the UIStyle singleton.
     *
     * @param {string} userAgent - The userAgent to use for prefixing. If not provided, the current userAgent will be used.
     *
     * @function Mojito.Core.Presentation.UIStyle#init
     */
    init(userAgent) {
        const prefixerParams = {
            keepUnprefixed: false,
            userAgent: userAgent,
        };
        this.customPrefixer = new CustomPrefixer(prefixerParams);

        this.createStyleSheet();
    }

    /**
     * Creates empty stylesheet in DOM which will keep Mojito CSS rules.
     *
     * @private
     * @function Mojito.Core.Presentation.UIStyle#createStyleSheet
     */
    createStyleSheet() {
        this.styleId = `mojito-style-${IdGenerator.generateId()}`;

        const styleElement = document.createElement('style');
        styleElement.setAttribute('id', this.styleId);

        document.head.appendChild(styleElement);
        this.customStyleSheet = styleElement.sheet;
        this.insertRule = this.customStyleSheet.insertRule.bind(this.customStyleSheet);
    }

    /**
     * Clear UIStyle singleton.
     *
     * @function Mojito.Core.Presentation.UIStyle#dispose
     */
    dispose() {
        const styleElement = document.getElementById(this.styleId);
        if (styleElement) {
            document.head.removeChild(styleElement);
        } else {
            log.warn(`Can't remove stylesheet with id ${this.styleId}`);
        }
    }

    /**
     * Remove cssRules by className.
     *
     * @param {string} className - Class name which was used in css rules.
     */
    removeCssRules(className) {
        const sheet = this.customStyleSheet;

        // We iterate array from the end because we modify it.
        for (let i = sheet.cssRules.length - 1; i >= 0; i--) {
            if ((sheet.cssRules[i].selectorText || '').includes(`.${className}`)) {
                sheet.deleteRule(i);
            }
        }
    }

    /**
     * Add a named animation keyframes definition to the global style scope.
     *
     * @param {string} animationName - Unique name for the animation.
     * @param {object} keyframes - Key-value map where key are keyframe names and values are objects representing CSS styles.
     *
     * @private
     * @function Mojito.Core.Presentation.UIStyle#setAnimationDefinition
     */
    setAnimationDefinition(animationName, keyframes) {
        const sheet = this.customStyleSheet;

        for (let i = 0; i < sheet.cssRules.length; i++) {
            if (sheet.cssRules[i].name === animationName) {
                sheet.deleteRule(i);
            }
        }

        const keyframesStr = Object.keys(keyframes)
            .map(keyframeName => {
                const keyframe = keyframes[keyframeName];
                const props = Object.keys(keyframe)
                    .filter(propName => keyframe[propName] !== undefined)
                    .map(propName => `${propName}: ${keyframe[propName]};`)
                    .join(' ');
                return `${keyframeName} { ${props} }`;
            })
            .join(' ');
        const animationStr = `@keyframes ${animationName} { ${keyframesStr} }`;

        this.insertRule(animationStr, sheet.cssRules.length);
    }

    /**
     * Creates a named animation from a component's config and an animation definition.
     * Also adds the animation's keyframes to a global CSS animation registry.
     *
     * @param {object} componentConfig - The configuration for the component to which this animation is tied.
     * @param {string} animationName - The name of the animation, should be unique within the component.
     * @param {object} animationDefinition - Definition of the CSS animation.
     *
     * @returns {object} The CSS animation properties, ready to be used as an inline style.
     * @function Mojito.Core.Presentation.UIStyle#createCssAnimation
     */
    createCssAnimation(componentConfig, animationName, animationDefinition) {
        const configSpecificAnimationName = `${animationName}_${componentConfig[META]?.id}`;

        this.setAnimationDefinition(configSpecificAnimationName, animationDefinition.keyframes);

        const animation = omit(animationDefinition, 'keyframes');
        animation.animationName = configSpecificAnimationName;

        return animation;
    }

    /**
     * Add css rule to document.head.style.
     *
     * @param {string} rule - Css rule.
     * @function Mojito.Core.Presentation.UIStyle#insertCssRule
     */
    insertCssRule(rule) {
        this.insertRule(rule, this.customStyleSheet.cssRules.length);
    }

    /**
     * Adds required vendor prefixes to an object with style values.
     * <br><br>
     * Note: This method will add the vendor prefix values to the object that is provided.
     *
     * @param {object} values - An object with valid style declarations to which vendor prefixes will be added.
     *
     * @returns {object} Returns the object with added prefixed style declarations for convenience.
     *
     * @function Mojito.Core.Presentation.UIStyle#prefix
     */
    prefix(values) {
        return this.customPrefixer.prefix(values);
    }

    /**
     * Deep merges style values.
     * <br>
     * This function does not merge the content of arrays.
     * <br>
     * Argument n will be merged into argument n-1, meaning that the last argument has the highest priority.
     * <br><br>
     * Important: This method takes care not to mutate the input styles in any way.
     * However, whenever possible, the merge operation will still copy objects by reference,
     * which means that the merged result may contain references to data that shouldn't be mutated.
     *
     * @example const mergedStyle = uiStyle.merge(style1, style2, style3);
     *
     * @function Mojito.Core.Presentation.UIStyle#merge
     *
     * @param {...object} args - The style objects to be merged.
     *
     * @returns {object} A new object with the merged values.
     */
    merge(...args) {
        return ObjUtils.merge(...args);
    }

    /**
     * Merges multiple style objects deeply and applies vendor prefixes except to the first object.
     * <br><br>
     * Note: The first object argument is not prefixed.
     *
     * @example const mergedAndPrefixedStyle = uiStyle.mergeAndPrefix(style1, style2, style3);
     *
     * @param {Array} args - The style objects to be merged.
     *
     * @returns {object} A new object with the merged and prefixed styles.
     *
     * @function Mojito.Core.Presentation.UIStyle#mergeAndPrefix
     */
    mergeAndPrefix(...args) {
        let result = {};

        for (let i = 0; i < args.length; i++) {
            let valuesToMerge = args[i];
            if (i > 0) {
                valuesToMerge = this.prefix(valuesToMerge);
            }
            result = ObjUtils.merge(result, valuesToMerge);
        }

        return result;
    }
}

export default new UIStyle();
