import MojitoCore from 'mojito/core';

const log = MojitoCore.logger.get('ExternalNavigationServiceFactory');

const voidResponder = () => {
    log.warn('externalNavigationService instance is missing.');
    return Promise.reject();
};

const NULL_SERVICE = {
    navigate: voidResponder,
};

/**
 * Singleton object that spawns service instance.
 *
 * @class ServiceFactory
 * @name serviceFactory
 * @memberof Mojito.Services.ExternalNavigation
 */
class ServiceFactory {
    constructor() {
        this.service = NULL_SERVICE;
    }

    /**
     * Init factory.
     *
     * @function init
     * @param {object} config - External navigation service config.
     * @param {Mojito.Services.ExternalNavigation.AbstractExternalNavigationService} config.service - External navigation service instance.
     *
     * @memberof Mojito.Services.ExternalNavigation.serviceFactory
     */
    init({ service }) {
        this.service = service;
    }

    /**
     * Service getter.
     *
     * @function getService
     *
     * @returns {Mojito.Services.ExternalNavigation.AbstractExternalNavigationService} External navigation service instance.
     *
     * @memberof Mojito.Services.ExternalNavigation.serviceFactory
     */
    getService() {
        return this.service;
    }
}

export default new ServiceFactory();
