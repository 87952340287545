/**
 * Intended to work with implementation of AbstractTask
 * that can perform asynchronous actions during execution.
 * Continuously executes task with defined interval.
 * Supports single task instance processing.
 * Solves problem of slow task execution.
 *
 * @class TaskRunner
 * @memberof Mojito.Services.Common
 */
export default class TaskRunner {
    /**
     * Constructor for the TaskRunner.
     *
     * @param {number} interval - The execution interval for the task (in milliseconds).
     * @function Mojito.Services.Common.TaskRunner#constructor
     */
    constructor(interval) {
        if (isNaN(interval)) {
            throw new Error('interval is not a number');
        }
        this.interval = interval;
        this.activeTask = undefined;
        this.pendingTask = undefined;
        this._isExecuting = false;
        this._lastExecutedTime = 0;
        this._isDelayed = false;

        this._onFinish = this._onFinish.bind(this);
        this._start = this._start.bind(this);
        this._clearTimer = this._clearTimer.bind(this);
    }

    /**
     * Determines whether the active task is currently executing or pending.
     *
     * @returns {boolean} IsExecuting - Returns true if the active task is in the process of execution (i.e., the execute function was called but has not yet returned a result).
     * @function Mojito.Services.Common.TaskRunner#isExecuting
     */
    get isExecuting() {
        return this._isExecuting;
    }

    /**
     * Gets the timestamp from the most recent execution of the active task.
     *
     * @returns {number} LastExecutedTime - Represents the timestamp indicating the completion of the most recent execution of the task.
     * @function Mojito.Services.Common.TaskRunner#lastExecutedTime
     */
    get lastExecutedTime() {
        return this._lastExecutedTime;
    }

    /**
     * Starts the TaskRunner with the provided task.
     * This method can be invoked several times with distinct task instances.
     * When a new task is provided, the currently processing task is discarded,
     * and the TaskRunner state is reset to begin processing the newly provided task.
     * If the active task is in the middle of execution, the TaskRunner will wait until it finishes,
     * and then it will start processing the newly provided task.
     *
     * @param {Mojito.Services.Common.AbstractTask} task - The task that will be executed at the specified interval.
     * @function Mojito.Services.Common.TaskRunner#run
     */
    run(task) {
        // If activeTask is currently executing or if we have a delayed task
        // we need to store newly provided task as pendingTask and process it _onFinish
        if (this.isExecuting || this._isDelayed) {
            this.pendingTask = task;
            return;
        }

        this.reset();
        this._start(task);
    }

    /**
     * Resets the TaskRunner.
     * This will terminate any task that is currently being processed and clear the timer.
     *
     * @function Mojito.Services.Common.TaskRunner#reset
     */
    reset() {
        this._clearTimer();
        this._lastExecutedTime = 0;
        this.pendingTask = undefined;
        this.activeTask = undefined;
        this._isExecuting = false;
        this._isDelayed = false;
    }

    /**
     * Stops scheduled task and then executes new task after a delay.
     * The task will then continue as usual.
     *
     * @param {Mojito.Services.Common.AbstractTask} task - Task that will be executed after the delay.
     * @param {number} ms - Milliseconds until execution.
     *
     * @function Mojito.Services.Common.TaskRunner#executeWithDelay
     */
    executeWithDelay(task, ms) {
        this._clearTimer();
        this._isDelayed = true; // Prevent new execution during delay
        const startTaskAndResetDelayed = () => {
            this._isDelayed = false;
            this._start(task);
        };
        this.timeoutId = setTimeout(startTaskAndResetDelayed, ms);
    }

    _clearTimer() {
        clearTimeout(this.timeoutId);
    }

    _start(task) {
        if (this._isDelayed) {
            return;
        }

        this._isExecuting = true;
        this.activeTask = task;
        this.activeTask.execute(this._onFinish);
    }

    _onFinish() {
        if (this._isDelayed) {
            return;
        }

        this._isExecuting = false;
        this._lastExecutedTime = new Date().getTime();
        // If we have pendingTask we run it immediately
        // and it becomes new activeTask
        if (this.pendingTask) {
            this._start(this.pendingTask);
            this.pendingTask = undefined;
            return;
        }

        if (this.activeTask) {
            this.timeoutId = setTimeout(() => this._start(this.activeTask), this.interval);
        }
    }
}
