/**
 * Blocks the default behavior that the event would have triggered,
 * and prevents the event from bubbling up the DOM.
 *
 * @param {Event} ev - A DOM event.
 *
 * @returns {undefined}
 **/
export function blockEvent(ev) {
    ev.preventDefault();
    ev.stopPropagation();
}
