/**
 * Class defining sport generic menu descriptors.
 *
 * @class MenusDataDescriptor
 * @name descriptor
 * @memberof Mojito.Services.SportsContent.Menus
 */
export default class MenusDataDescriptor {
    /**
     * Factory method for creating a generic sport menus descriptor.
     *
     * @param {string} listId - Id of list.
     *
     * @returns {{dataType: string, listId: string}} Generic sport menu descriptor.
     *
     * @function Mojito.Services.SportsContent.Menus.descriptor#create
     * @static
     */
    static create(listId) {
        return {
            dataType: MenusDataDescriptor.DATA_TYPES.SPORT_MENU,
            listId,
        };
    }
}

/**
 * System unique data descriptor types.
 *
 * @typedef DATA_TYPES
 * @property {string} SPORT_MENU - Sport menu data descriptor type.
 * @memberof Mojito.Services.SportsContent.Menus.descriptor
 */
MenusDataDescriptor.DATA_TYPES = {
    SPORT_MENU: 'sport-menu',
};
