import EventCardSkeletonImpl from './event-card-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Event card skeleton component used to represent loading event card.
 *
 * @function EventCardSkeleton
 * @memberof Mojito.Modules.EventCards
 */

export default UIView('EventCardSkeleton', EventCardSkeletonImpl);
