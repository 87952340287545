/**
 * Bonus free bets types.
 *
 * @class BonusFreeBetsTypes
 * @name types
 * @memberof Mojito.Services.BonusFreeBets
 */
export default class BonusFreeBetsTypes {}

/**
 * Bonus free bets code state.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.BonusFreeBets.types
 */
BonusFreeBetsTypes.FREE_BETS_CODE_STATE = {
    ACCEPTED: 'ACCEPTED',
    ERROR: 'ERROR',
    PENDING: 'PENDING',
    READY: 'READY',
};

/**
 * Error codes that can arrive during add free bet process.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Services.BonusFreeBets.types
 */
BonusFreeBetsTypes.FREE_BETS_ERROR_CODE = {
    /* This code appears if there is an attempt to add free bet using empty input string. */
    EMPTY_INPUT: 'EMPTY_INPUT',
};
