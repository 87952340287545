import MojitoPresentation from 'mojito/presentation';

const { Text } = MojitoPresentation.Components;

const SuspendedSelectionLabel = ({ mojitoTools: { stringResolver, config } }) => {
    return (
        <Text class="ta-SuspendedSelectionLabel" config={config.suspendedText}>
            {stringResolver.resolveString('$SUSPENDED_SELECTION_LABEL.TEXT')}
        </Text>
    );
};

export default SuspendedSelectionLabel;
