/**
 * A list of timer types. Could be used for timers general settings.
 *
 * @class TimerTypes
 * @memberof Mojito.Presentation.Components
 */
export default class TimerTypes {}

/**
 * Timer direction types. Could be used for setting timer direction.
 *
 * @typedef TIMER_DIRECTION
 * @property {string} BACKWARD - Timer direction counting backwards.
 * @property {string} FORWARD - Timer direction counting forwards.
 *
 * @memberof Mojito.Presentation.Components.TimerTypes
 */
TimerTypes.TIMER_DIRECTION = {
    BACKWARD: 'B',
    FORWARD: 'F',
};
