import { createSlice } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import ServicesTypes from 'services/common/types.js';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import { selectChannel, selectState, isActiveChannel } from './selectors';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import PromotionsDataDescriptor from './descriptor.js';
import promotionsDataRetriever from './dataretriever.js';
import promotionsProvider from './provider';
import channelFactory from 'services/common/content/content-channel-factory.js';
import { isLoggedIn } from 'services/authentication/selectors.js';
import { selectPromotionsGroups } from 'services/user-info/selectors.js';
import { uniq } from 'mojito/utils';
import { LOCATION, CHANNELS } from './types';
import PromotionsUtils from './utils.js';

const { actionsRegistry } = MojitoCore.Services.Content;
const log = MojitoCore.logger.get('PromotionsStore');
const { UNKNOWN, AVAILABLE, UNAVAILABLE } = ServicesTypes.CONTENT_STATE;
const reduxInstance = MojitoCore.Services.redux;
const IdGenerator = MojitoCore.Base.IdGenerator;

export const getPromotionsChannel = () =>
    channelFactory.getChannel(
        promotionsProvider,
        PromotionsDataDescriptor.DATA_TYPES.PROMOTION_LOCATION
    );

/**
 * Defines the structure of the promotions' state object.
 *
 * @typedef PromotionsState
 *
 * @property {boolean} pollOnLoggedIn - Indicates whether polling should be done when user is logged in.
 * @property {object} potentialSubscribers - Contains information related to potential subscribers.
 * @property {object} promotionsByLocations - Contains promotions information organized by locations (e.g., top, left).
 * @property {object} userSpecificPromotionSubscription - Contains subscription information specific to a user.
 * @property {object} defaultLocations - Contains information about default locations.
 * @property {object} userSpecificLocations - Contains information about locations specific to a user.
 * @property {object} promotionsIdToChannelMap - Maps promotion ids to their corresponding channels (e.g., desktop, mobile).
 *
 * @memberof Mojito.Services.Promotions
 */

/**
 * The name of the promotions store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Promotions
 */
export const STORE_KEY = 'promotionsStore';

export const INITIAL_STATE = {
    promotionsLoadingState: {},
    pollOnLoggedIn: false,
    potentialSubscribers: {},
    promotionsByLocations: {},
    userSpecificPromotionSubscription: {},
    defaultLocations: {},
    userSpecificLocations: {},
    promotionsIdToChannelMap: {},
};

const reducers = {
    resetPromotionsByLocations: state => {
        state.promotionsByLocations = {};
        state.defaultLocations = {};
        state.userSpecificLocations = {};
        state.promotionsIdToChannelMap = {};
        Object.values(LOCATION).forEach(locationId => {
            state.promotionsLoadingState[locationId] = UNKNOWN;
        });
    },
    configure: (state, { payload: config = {} }) => {
        state.pollOnLoggedIn =
            config.pollOnLoggedIn !== undefined
                ? config.pollOnLoggedIn
                : INITIAL_STATE.pollOnLoggedIn;
    },
    updateChannelPromotions: (state, { payload }) => {
        if (!payload) {
            reducers.resetPromotionsByLocations(state);
        } else if (payload.channelToRemove) {
            state.promotionsByLocations = PromotionsUtils.combinePromotionsWithoutChannel(
                state,
                payload.channelToRemove
            );
        } else {
            const { locations, id: channel } = payload;
            state.promotionsIdToChannelMap = {
                ...state.promotionsIdToChannelMap,
                ...PromotionsUtils.getPromotionsIdsToChannelMap(channel, locations),
            };
            state.promotionsByLocations = PromotionsUtils.combinePromotions(
                channel,
                state,
                locations
            );
        }
        Object.values(LOCATION).forEach(locationId => {
            state.promotionsLoadingState[locationId] = Array.isArray(
                state.promotionsByLocations[locationId]
            )
                ? AVAILABLE
                : UNAVAILABLE;
        });
    },
    getPromotionsFailed: state => {
        Object.values(LOCATION).forEach(locationId => {
            state.promotionsLoadingState[locationId] = UNAVAILABLE;
        });
    },
    updateUserSpecificPromotionSubscription: (state, { payload }) => {
        const { clientId, channel } = payload;
        state.userSpecificPromotionSubscription[clientId] = channel;
    },
    updatePotentialSubscribers: (state, { payload }) => {
        const { clientId, channel } = payload;
        if (channel) {
            state.potentialSubscribers[clientId] = channel;
        } else {
            delete state.potentialSubscribers[clientId];
        }
    },
    reset: () => {
        return { ...INITIAL_STATE };
    },
};

export const { reducer, actions } = createSlice({
    name: 'promotions',
    initialState: INITIAL_STATE,
    reducers,
});

const handleDispose = (state, listenerApi) => {
    const allSubscriptionChannels = uniq([
        ...Object.values(state.potentialSubscribers),
        ...Object.values(state.userSpecificPromotionSubscription),
    ]);
    allSubscriptionChannels.map(channel => getPromotionsChannel().disposeItemSubscription(channel));
    listenerApi.dispatch(actions.resetPromotionsByLocations());
    if (state.pollOnLoggedIn) {
        promotionsDataRetriever.stopPollingUserPromotions();
    }
};

const subscribeAllPotentialSubscribers = (clientId, channel, listenerApi) => {
    const onData = (clientId, channel) => {
        const promotions = channel ? channel : { channelToRemove: clientId };
        listenerApi.dispatch(actions.updateChannelPromotions(promotions));
    };
    getPromotionsChannel().subscribe([channel], clientId, onData);
};

/**
 * Services layer promotions actions.
 *
 * @module PromotionsActions
 * @name actions
 * @memberof Mojito.Services.Promotions
 */

/**
 * Init configure action.
 *
 * @function configure
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Promotions.actions
 */

/**
 * Update promotions success action.
 *
 * @function updateChannelPromotions
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Promotions.actions
 */

/**
 * Get promotions failed action.
 *
 * @function getPromotionsFailed
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Promotions.actions
 */

/**
 * Reset promotions by locations.
 *
 * @function resetPromotionsByLocations
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Promotions.actions
 */

/**
 * Update user specific promotion subscription.
 *
 * @function updateUserSpecificPromotionSubscription
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Promotions.actions
 */

/**
 * Update potential subscribers.
 *
 * @function updatePotentialSubscribers
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.Promotions.actions
 */

/**
 * Reset promotion state.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 *
 * @memberof Mojito.Services.MetaInformation.actions
 */

reduxInstance.actionListener.startListening({
    actionCreator: bootstrapActions.dispose,
    effect: (action, listenerApi) => {
        const state = listenerApi.getState();
        handleDispose(selectState(state), listenerApi);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: actions.getPromotionsFailed,
    effect: action => {
        const error = action.payload;
        log.error(`getUserPromotions failed (${error})`);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.logoutSuccess,
    effect: (action, listenerApi) => {
        const state = listenerApi.getState();
        const channel = selectChannel(state);
        if (
            isActiveChannel(CHANNELS.DESKTOP_AUTH, state) ||
            isActiveChannel(CHANNELS.MOBILE_AUTH, state)
        ) {
            handleDispose(selectState(state), listenerApi);
            Object.keys(selectState(state).potentialSubscribers).forEach(clientId => {
                subscribeAllPotentialSubscribers(clientId, channel, listenerApi);
            });
        }
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: bootstrapActions.initPending,
    effect: (action, listenerApi) => {
        handleLoginSuccess(listenerApi);
    },
});

reduxInstance.actionListener.startListening({
    actionCreator: authenticationActions.loginSuccess,
    effect: (action, listenerApi) => {
        handleLoginSuccess(listenerApi);
    },
});

const handleLoginSuccess = listenerApi => {
    if (!isLoggedIn()) {
        return;
    }
    const state = listenerApi.getState();
    const channel = selectChannel(state);
    handleDispose(selectState(state), listenerApi);
    if (selectState(state).pollOnLoggedIn) {
        promotionsDataRetriever.pollUserPromotions();
    } else {
        // subscribe to promotions items for logged users
        Object.keys(selectState(state).potentialSubscribers).forEach(clientId => {
            listenerApi.dispatch(
                actions.updateUserSpecificPromotionSubscription({ clientId, channel })
            );
            subscribeAllPotentialSubscribers(clientId, channel, listenerApi);
        });
        // subscribe to user specific promotions
        const promotionsGroups = selectPromotionsGroups(state);
        if (!promotionsGroups || !promotionsGroups.length) {
            return;
        }
        promotionsGroups.forEach(group => {
            const groupChannel = `${channel}_${group}`;
            const clientId = IdGenerator.generateId();

            listenerApi.dispatch(
                actions.updateUserSpecificPromotionSubscription({ clientId, channel: groupChannel })
            );
            subscribeAllPotentialSubscribers(group, groupChannel, listenerApi);
        });
    }
};

/**
 * Subscribe to promotions.
 *
 * @function subscribePromotions
 *
 * @param {{clientId: string}} payload - Subscription payload.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Subscribe to item list thunk.
 * @memberof Mojito.Services.Promotions.actions
 */
actions.subscribePromotions = payload => {
    return (dispatch, getState) => {
        const state = getState();
        const { clientId } = payload;
        const channel = selectChannel(state);
        dispatch(actions.updatePotentialSubscribers({ clientId, channel }));

        if (!isLoggedIn(state) || !selectState(state).pollOnLoggedIn) {
            // if user logged-in and user promotions' polling is enabled,
            // all possible promotions will be pulled from [GET /promotions] api call
            // otherwise, subscribe on promotions update through websocket by channel name
            // Channel name has suffix 'auth' for logged-in users
            const onData = (clientId, channel) => {
                const promotions = channel ? channel : { channelToRemove: channel };
                dispatch(actions.updateChannelPromotions(promotions));
            };
            getPromotionsChannel().subscribe([channel], clientId, onData);
        }
    };
};
/**
 * Unsubscribe client from promotions.
 *
 * @function unsubscribePromotions
 *
 * @param {string} clientId - The id of subscriber which aims to be unsubscribed.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Unsubscribe from item list thunk.
 * @memberof Mojito.Services.Promotions.actions
 */
actions.unsubscribePromotions = clientId => {
    return dispatch => {
        dispatch(actions.updatePotentialSubscribers({ clientId }));
        getPromotionsChannel().unsubscribeAll(clientId);
    };
};

const { subscribePromotions, unsubscribePromotions } = actions;
actionsRegistry.addSubscription(
    PromotionsDataDescriptor.DATA_TYPES.PROMOTION_LOCATION,
    subscribePromotions,
    unsubscribePromotions
);

reduxInstance.injectReducer(STORE_KEY, reducer);
