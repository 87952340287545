import MojitoPresentation from 'mojito/presentation';
import EventCardSkeleton from './card/index.jsx';
import { useCallback, useState } from 'react';
import { noop } from 'mojito/utils';

const { LoaderSuspense } = MojitoPresentation.Components;

export default function withEventCardSkeleton(
    EventCard,
    useLoadDone = () => false,
    loaderConfigResolver = noop
) {
    const EventCardWrapper = props => {
        const {
            mojitoTools: { config },
            showSkeleton,
        } = props;

        const cardContentLoaded = useLoadDone(props);
        const [cardLoaded = cardContentLoaded, setCardLoaded] = useState(undefined);
        const onLoadDone = useCallback(() => setCardLoaded(true), []);
        const loaderConfig = loaderConfigResolver(config) || config.eventCard.skeletonLoader;

        return (
            <LoaderSuspense
                config={loaderConfig}
                isContentPending={!cardLoaded && showSkeleton}
                loader={<EventCardSkeleton />}
            >
                <EventCard {...props} onLoadDone={onLoadDone} />
            </LoaderSuspense>
        );
    };

    return EventCardWrapper;
}
