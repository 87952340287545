import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

const StringUtils = MojitoCore.Base.StringUtils;
const { ODDS_FORMAT } = MojitoServices.UserSettings.types;
const { PRICE_TYPE } = MojitoServices.Bets.types;
const { selectOddsFormat } = MojitoServices.UserSettings.selectors;
const { STARTING_PRICE_ODDS } = MojitoServices.SportsContent.Events.types;

/**
 * Utility class for odds.
 * <br><br>
 * It is used by other utility classes and is not exposed in the api.
 *
 * @class OddsUtils
 * @private
 * @memberof Mojito.Presentation.Utils
 */
export default class OddsUtils {
    /**
     * Decides which of the odds should be used based on the user selected odds format.
     *
     * If the decimal odds is -1 then 'ODDS_UTILS.STARTING_PRICE' translation will be returned instead.
     *
     * @param {string} frac - Fractional odds format.
     * @param {string} dec - Decimal odds format.
     * @param {string} us - US Sports odds format.
     * @param {object} l10n - Object with strings.
     * @returns {string} Returns the odds string according to odds format.
     * @function Mojito.Presentation.Utils.OddsUtils.getOdds
     */
    static getOdds(frac, dec, us, l10n) {
        const typesMap = {
            [ODDS_FORMAT.FRACTIONAL]: frac,
            [ODDS_FORMAT.DECIMAL]: dec,
            [ODDS_FORMAT.AMERICAN]: us,
        };
        const priceType =
            parseFloat(dec) === parseFloat(STARTING_PRICE_ODDS) ? PRICE_TYPE.SP : PRICE_TYPE.LP;
        // Ideally we should never use data from store in utils. Store tend to change,
        // but in case of odds format it kind of works fine so far.
        return OddsUtils.getStartingPrice(priceType, l10n) || typesMap[selectOddsFormat()];
    }

    /**
     * Get odds to display. Odds with {@link Mojito.Services.Bets.types.PRICE_TYPE.LP|LP} price type
     * will be displayed normally, while {@link Mojito.Services.Bets.types.PRICE_TYPE.SP|SP} price type
     * will be replaced with ODDS_UTILS.STARTING_PRICE string from l10n object.
     *
     * @param {string} oddsString - Odds.
     * @param {Mojito.Services.Bets.types.PRICE_TYPE} priceType - Price type.
     * @param {object} l10n - Object with strings.
     *
     * @returns {string} Odds or starting price translated string.
     *
     * @function Mojito.Presentation.Utils.OddsUtils.getDisplayedOdds
     */
    static getDisplayedOdds(oddsString, priceType, l10n) {
        return OddsUtils.getStartingPrice(priceType, l10n) || oddsString || '';
    }

    /**
     * Get starting price string. In case <code>priceType</code> parameter equals to {@link Mojito.Services.Bets.types.PRICE_TYPE.SP|SP}
     * the output will be resulted to localized string from <code>l10n</code> object using `ODDS_UTILS.STARTING_PRICE` key to be resolved.
     *
     * @param {Mojito.Services.Bets.types.PRICE_TYPE} priceType - Price type.
     * @param {object} l10n - Object with strings.
     *
     * @returns {string|undefined} Translated string if priceType is SP otherwise undefined.
     *
     * @function Mojito.Presentation.Utils.OddsUtils.getStartingPrice
     */
    static getStartingPrice(priceType, l10n) {
        if (priceType === PRICE_TYPE.SP) {
            return StringUtils.resolveString('$ODDS_UTILS.STARTING_PRICE', l10n);
        }
    }
}
