import MojitoCore from 'mojito/core';
import AbstractTask from 'services/common/task-runner/abstract-task.js';
import { actions as openBetsActions } from 'services/bets/open-bets/open-bets-slice.js';
import BetsTypes from 'services/bets/types.js';
import { selectOddsFormat } from 'services/user-settings/selectors.js';
import { noop, isEmpty } from 'mojito/utils';

const { dispatch } = MojitoCore.Services.redux.store;

/**
 * Refresh open bets task.
 *
 * Retrieves open bets and handles result with proper action call.
 *
 * @class RefreshOpenBetsTask
 * @memberof Mojito.Services.Bets.Task
 */
export default class RefreshOpenBetsTask extends AbstractTask {
    constructor(service, maxBetAge) {
        super();
        this.betsService = service;
        this.maxBetAge = maxBetAge;
        this.dataHashRetriever = noop;
    }

    getFilterCriteria() {
        const dateSince = new Date();
        dateSince.setFullYear(dateSince.getFullYear() - this.maxBetAge);
        return {
            filters: [BetsTypes.BET_STATUS.OPEN],
            from: dateSince.toISOString(),
        };
    }

    setDataHashRetriever(hashRetriever = noop) {
        this.dataHashRetriever = hashRetriever;
    }

    execute(callback = noop) {
        const filterCriteria = this.getFilterCriteria();
        const oddsFormat = selectOddsFormat();
        const dataHash = this.dataHashRetriever();
        const hashPart = isEmpty(dataHash) ? {} : { dataHash };

        this.betsService
            .refreshBets({ filterCriteria, oddsFormat, ...hashPart })
            .then(data => dispatch(openBetsActions.fetchOpenBetsSuccess(data)))
            .catch(() => dispatch(openBetsActions.fetchOpenBetsFailed()));

        callback();
    }
}
