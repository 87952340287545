import PropTypes from 'prop-types';
import PageSplitLayoutImpl from './pagesplitlayout.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Layout view designed for rendering page content split into two areas: primary and secondary.
 * The secondary content is typically rendered in a container on the left, while the primary content is displayed in the central page area.
 *
 * @class PageSplitLayout
 * @memberof Mojito.Presentation.Components
 */

/**
 * PageSplitLayout prop types.
 * The content of the two sections are passed as child nodes, with the first node being the primary content, and the second the secondary content.
 *
 * @property {node[]} children - Represents the primary and secondary page content nodes.
 *
 * @memberof Mojito.Presentation.Components.PageSplitLayout
 */
const propTypes = {
    children: PropTypes.node,
};

export default UIView('PageSplitLayout', PageSplitLayoutImpl, propTypes);
