/**
 * Singleton manager that controls document body scrolling.
 * Typically used to enable/disable scrolling.
 *
 * @class BodyScrollManager
 * @name bodyScrollManager
 * @memberof Mojito.Core.Presentation
 */
class BodyScrollManager {
    constructor() {
        this._callers = new Set();
        this._lastScrollPosition = { top: 0, left: 0 };
    }

    /**
     * Disables body scrolling. Unique reference to the caller instance
     * should be provided to effectively handle scrolling release.
     *
     * @param {*} caller - Unique reference to the caller instance.
     *
     * @function Mojito.Core.Presentation.bodyScrollManager#disableBodyScrolling
     */
    disableBodyScrolling(caller) {
        if (!this._validateCaller(caller)) {
            return;
        }
        // If _callers is empty then no one requested to block the scroll yet - do it now.
        if (this._callers.size === 0) {
            // setting position to `fixed` resets the scrolling offsets, to avoid this we need to store
            // scroll offset before and then restore it in `enableBodyScrolling`
            const top = window.scrollY;
            const left = window.scrollX;
            this._lastScrollPosition = { top, left };
            document.body.style.overflow = 'hidden';
            document.body.style.width = '100%';
            document.body.style.height = '100%';
            document.body.style.position = 'fixed';
        }
        // We record every caller who wants to block the scroll, will use this set to unblock it later.
        this._callers.add(caller);
    }

    /**
     * Enables body scrolling. The instance of the caller parameter
     * should be the same that was used to disable scroll.
     *
     * @param {*} caller - Unique reference to the caller instance.
     *
     * @function Mojito.Core.Presentation.bodyScrollManager#enableBodyScrolling
     */
    enableBodyScrolling(caller) {
        if (!this._validateCaller(caller) || !this._callers.has(caller)) {
            return;
        }
        this._callers.delete(caller);
        // If no one left who still wants to block the body scrolling - safely enable it back.
        if (this._callers.size === 0) {
            this._releaseScroll();
        }
    }

    /**
     * Enables body scrolling and resets manager's state.
     *
     * @function Mojito.Core.Presentation.bodyScrollManager#reset
     */
    reset() {
        this._callers = new Set();
        this._lastScrollPosition = { top: 0, left: 0 };
        this._releaseScroll();
    }

    /**
     * Saves the last scroll position which will be automatically navigated to when body scrolling is re-enabled.
     *
     * @param {number} top - The vertical position (Y-coordinate) to save.
     * @param {number} left - The horizontal position (X-coordinate) to save.
     *
     * @function Mojito.Core.Presentation.bodyScrollManager#setLastScrollPosition
     */
    setLastScrollPosition(top, left) {
        this._lastScrollPosition = { top, left };
    }

    _releaseScroll() {
        document.body.style.overflow = 'visible';
        document.body.style.width = 'auto';
        document.body.style.height = 'auto';
        document.body.style.position = 'static';
        window.scrollTo(this._lastScrollPosition.left, this._lastScrollPosition.top);
    }

    _validateCaller(caller) {
        if (!caller) {
            throw Error('caller parameter is required');
        }
        return true;
    }
}

export default new BodyScrollManager();
