import { createRef, useCallback } from 'react';
import MojitoCore from 'mojito/core';
import ButtonScrollPane from 'presentation/components/button-scroll-pane/index.jsx';
import SelectableButton from 'presentation/components/selectable-button/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import { isEmpty } from 'mojito/utils';

const classUtils = MojitoCore.Base.classUtils;

const MenuRow = ({ mojitoTools, menuItems, itemRenderer, cbItemClicked, selectedItemId }) => {
    const { appContext, config, style } = mojitoTools;

    const itemRefs = menuItems.reduce((refs, item) => {
        refs[item.id] = createRef();
        return refs;
    }, {});

    const onItemClicked = useCallback(
        (event, item) => {
            if (item.id !== selectedItemId) {
                cbItemClicked(item.id, item);
                appContext.overlayInteraction();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cbItemClicked, selectedItemId]
    );

    function renderItemContent({ data, icon, iconRenderer }, isSelectedItem) {
        if (itemRenderer) {
            return itemRenderer(data);
        }
        const { chevronIcon = {} } = config;
        return (
            <FlexPane config={config.itemInnerContainer}>
                {iconRenderer && iconRenderer()}
                {icon && config.showItemIcon && (
                    <Image
                        src={icon}
                        class="ta-ItemIcon"
                        config={isSelectedItem ? config.selectedItemIcon : config.itemIcon}
                    />
                )}
                <Text
                    class="ta-ItemText"
                    config={isSelectedItem ? config.selectedItemLabel : config.itemLabel}
                >
                    {data}
                </Text>
                {!isEmpty(chevronIcon.src) && <Image class="ta-ItemChevron" config={chevronIcon} />}
            </FlexPane>
        );
    }

    function renderItem(item) {
        const isSelectedItem = item.id === selectedItemId;
        const className = classUtils.classNames(
            'ta-MenuRowItem',
            item.className || `ta-${item.id.toLowerCase()}`,
            isSelectedItem && 'ta-selected'
        );
        return (
            <SelectableButton
                key={item.id}
                config={config.item}
                onClick={onItemClicked}
                onClickData={item}
                hrefLink={item.hrefLink}
                selected={isSelectedItem}
                class={className}
                buttonElementRef={itemRefs[item.id]}
            >
                {renderItemContent(item, isSelectedItem)}
            </SelectableButton>
        );
    }

    return (
        <div className="ta-MenuRow" style={style.container}>
            <ButtonScrollPane
                config={config.buttonScrollPane}
                refs={itemRefs}
                selectedItemKey={selectedItemId}
            >
                {menuItems.map(renderItem)}
            </ButtonScrollPane>
        </div>
    );
};

MenuRow.getStyle = (config, applicationMode, merge) => {
    return {
        container: merge(
            {
                position: 'relative',
                flexGrow: '0',
                flexShrink: '1',
                minWidth: '0px',
            },
            config.style.container,
            config.style.container.borderStyle
        ),
    };
};

export default MenuRow;
