import {COLOR_CHOOSE_STRATEGY, DATA_TYPE, FALLBACK_FN} from '../../utils/data-validation.cjs';
import {
    autoLightenDarkenColor,
    chooseFirstWithContrast,
    chooseMaxContrast,
    chooseMinContrast,
    desaturate,
    isLightColor,
    setAlpha,
} from '../../utils/color-utils';

/**
 * Choose color if more than one variant are specified
 * @param colorVariantsStr Color variants are specified using pipe '|'. Or just one color
 * @param basedOnValue Basement color value to consider while choosing variant
 * @param strategy Strategy of color choose
 * @param defaultStrategy Global strategy
 * @returns {*|string}
 */
export function chooseColorFromVariants(colorVariantsStr, basedOnValue, strategy, defaultStrategy) {
    if (!colorVariantsStr) return colorVariantsStr;
    const colors = colorVariantsStr.split('|');
    if (colors.length === 1) {
        return colorVariantsStr;
    }

    if (!strategy) {
        strategy = defaultStrategy || [COLOR_CHOOSE_STRATEGY.ONLIGHT_ONDARK];
    }

    switch (strategy[0]) {
        case COLOR_CHOOSE_STRATEGY.EXACT: {
            // if (IS_LOCAL) {
            //     throw new Error(
            //         'Assertion: should not fall here. If it happens it means that resolved value have color tuple that should not be'
            //     );
            // }
            return colors[0];
        }
        case COLOR_CHOOSE_STRATEGY.ONLIGHT_ONDARK: {
            const color = isLightColor(basedOnValue, true) ? colors[0] : colors[1];
            return color;
        }
        case COLOR_CHOOSE_STRATEGY.MIN_CONTRAST: {
            const minRatio = parseFloat(strategy[1]);
            const color = chooseMinContrast(colorVariantsStr, basedOnValue, minRatio);
            return color;
        }
        case COLOR_CHOOSE_STRATEGY.MAX_CONTRAST: {
            const color = chooseMaxContrast(colorVariantsStr, basedOnValue);
            return color;
        }
        case COLOR_CHOOSE_STRATEGY.FIRST_WITH_CONTRAST: {
            const minRatio = parseFloat(strategy[1]);
            const color = chooseFirstWithContrast(colorVariantsStr, basedOnValue, minRatio);
            return color;
        }
    }
    return colors[0];
}

/**
 * Check if additional function (FN) should be applied to color. Resolves color if needed, or return raw color
 * @param schema Schema of item
 * @param value Gotten value need to resolve/apply calculation
 * @param defaultGlobalStrategy Global color choose strategy
 * @param basementColor Basement color value
 * @param errors Array of error messages. Will be added here if any
 * @returns {*} Resolved or raw color value
 */
export function applyCalc(schema, value, defaultGlobalStrategy, basementColor, errors) {
    if (!schema.fn) {
        return value;
    }

    value = chooseColorFromVariants(value, basementColor, schema.strategy, defaultGlobalStrategy);

    // Apply function to fallback result
    switch (schema.fn) {
        case FALLBACK_FN.AUTO_LIGHTEN_DARKEN:
            value = autoLightenDarkenColor(value, parseInt(schema.fnArg));
            break;
        case FALLBACK_FN.OPACITY:
            value = setAlpha(value, parseFloat(schema.fnArg));
            break;
        case FALLBACK_FN.DESATURATE:
            value = desaturate(value, parseFloat(schema.fnArg));
            break;
        default:
            errors.push(`Unknown function in calc: ${schema.fn}`);
    }
    return value;
}

/**
 * Do calculations or type resolving on fallback value
 * @param schema Schema of item
 * @param value Fallback value to resolve type and apply calculation
 * @param defaultGlobalStrategy Global color choose strategy
 * @param basementColor Basement color value
 * @param errors Array of error messages. Will be added here if any
 * @returns {*} Resolved or raw color value
 */
export function postProcessFallbackValue(schema, value, defaultGlobalStrategy, basementColor, errors) {
    // This method mostly converts types, and apply FN to resulting color
    switch (schema.type) {
        case DATA_TYPE.COLOR:
        case DATA_TYPE.BACKGROUND:
            // 'text-style' to 'color' converting
            if (value !== null && typeof value === 'object') {
                value = applyCalc(schema, value.color, defaultGlobalStrategy, basementColor, errors);
            } else {
                value = applyCalc(schema, value, defaultGlobalStrategy, basementColor, errors);
            }
            break;
        case DATA_TYPE.TEXT_STYLE:
            if (value !== null && typeof value === 'object') {
                // 'text-style' to 'color' converting
                value = {
                    ...value,
                    color: applyCalc(schema, value.color, defaultGlobalStrategy, basementColor, errors),
                };
            } else {
                value = {
                    color: applyCalc(schema, value, defaultGlobalStrategy, basementColor, errors),
                };
            }
            break;
    }

    return value;
}

export function convertTypes(value, schema, basementColor, defaultStrategy) {
    switch (schema.type) {
        case DATA_TYPE.TEXT_STYLE:
            if (typeof value === 'string') {
                value = {color: value};
            }

            // Text-style may not have color, its normal. But we should not create new property 'color' with 'undefined' value
            if (value.color) {
                // Clones object (do not modify src value because it could be reused by different basedOn)
                value = {
                    ...value,
                    color: chooseColorFromVariants(value.color, basementColor, schema.strategy, defaultStrategy),
                };
            }
            break;
        case DATA_TYPE.COLOR:
            value = chooseColorFromVariants(value, basementColor, schema.strategy, defaultStrategy);
            break;
        case DATA_TYPE.BACKGROUND:
            if (value && value.indexOf('|') > 0) {
                // if it is a color tuple
                value = chooseColorFromVariants(value, basementColor, schema.strategy, defaultStrategy);
            }
            break;
    }
    return value;
}
