import MojitoCore from 'mojito/core';
import { STORE_KEY, INITIAL_STATE } from './slice.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * PDF store selectors.
 *
 * @class PDFSelectors
 * @name selectors
 * @memberof Mojito.Services.PDF
 */

/**
 * Selects PDF state.
 *
 * @function selectState
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {object} The state of the PDF store.
 * @memberof Mojito.Services.PDF.selectors
 */
export const selectState = state => {
    // Should be no need to fallback to global state once fully migrated to Redux.
    const appState = state || reduxInstance.store?.getState();
    return appState?.[STORE_KEY] || INITIAL_STATE;
};

/**
 * Selects fetching status of PDF.
 *
 * @function selectIsFetching
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if a pdf is being fetched, otherwise false.
 *
 * @memberof Mojito.Services.PDF.selectors
 */
export const selectIsFetching = state => selectState(state).isFetchingPdf;

/**
 * Selects error status of PDF.
 *
 * @function selectError
 *
 * @param {object} [state] - Redux state object. The application state from {@link Mojito.Core.Services.redux#getStore|global store} will be used if not provided.
 * @returns {boolean} True if an error has occurred when trying to fetch a pdf.
 *
 * @memberof Mojito.Services.PDF.selectors
 */
export const selectError = state => selectState(state).error;

/**
 * Subscribes to the changes of particular PDF state property.
 *
 * @function subscribe
 *
 * @type {Mojito.Core.Services.redux.SubscriberFunction}
 * @memberof Mojito.Services.PDF.selectors
 */
export const subscribe = reduxInstance.getSubscriber(STORE_KEY);
