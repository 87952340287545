/**
 * Breadcrumb types indicating whether the breadcrumb is provided by a text label to display or how the displayed label should be resolved.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Modules.Breadcrumbs
 */
export default {
    TEXT_LABEL: 'TEXT_LABEL',
    SPORT_NAME: 'SPORT_NAME',
    COUPON_NAME: 'COUPON_NAME',
};
