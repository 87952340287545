import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import { connect } from 'react-redux';

const IntentTypes = MojitoPresentation.Base.Intent.Types;
const { AbsolutePane, Image, SelectableButtonGroup, SelectableButton, Text, FlexPane } =
    MojitoPresentation.Components;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const intentActions = MojitoCore.Intents.actions;

class CrossSellMenu extends UIViewImplementation {
    constructor(component) {
        super(component);

        this.state = {
            selectedLink: this.getInitialLink(),
        };

        this.onLinkClicked = this.onLinkClicked.bind(this);
    }

    getInitialLink() {
        const { links = [] } = this.config;
        const selectedItem = links.find(
            ({ label, intent = {} }) =>
                intent.type === IntentTypes.VIEW &&
                this.appContext().isContentShown(intent.data) &&
                label
        );

        // If no menu link found, default to first.
        // This can happen if navigating to /inplay directly.
        return selectedItem || links[0] || {};
    }

    onLinkClicked(key) {
        const linkItem = this.config.links.find(link => link.label === key);

        if (linkItem && linkItem.intent) {
            const { onClick, dispatch } = this.props;
            dispatch(intentActions.publishIntent(linkItem.intent.type, linkItem.intent.data));
            onClick(linkItem);
            this.setState({ selectedLink: linkItem });
        }
    }

    renderLinkItems() {
        return this.config.links.map(linkItem => {
            const label = this.resolveString(linkItem.label);
            return (
                <SelectableButton
                    class={`ta-cross-sell-link ta-cross-sell-link-${label}`}
                    key={linkItem.label}
                    selectableKey={linkItem.label}
                >
                    {linkItem.image && (
                        <FlexPane config={this.config.linkImageContainer}>
                            <Image src={linkItem.image} config={this.config.linkImage} />
                        </FlexPane>
                    )}
                    <Text config={this.config.linkLabel}>{label}</Text>
                    {linkItem.highlighted && (
                        <AbsolutePane config={this.config.linkHighlightIconContainer}>
                            <Image config={this.config.linkHighlightIcon} class="HighlightIcon" />
                        </AbsolutePane>
                    )}
                </SelectableButton>
            );
        });
    }

    render() {
        // Sanity check
        if (!Array.isArray(this.config.links) || !this.config.links.length) {
            return null;
        }

        return (
            <FlexPane config={this.config.container} class="CrossSellMenu">
                <SelectableButtonGroup
                    selectedKey={this.state.selectedLink && this.state.selectedLink.label}
                    onSelectionChange={this.onLinkClicked}
                    config={this.config.linkButtons}
                >
                    {this.renderLinkItems()}
                </SelectableButtonGroup>
            </FlexPane>
        );
    }
}

export default connect()(CrossSellMenu);
