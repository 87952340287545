import { useRef } from 'react';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import { isEmpty } from 'mojito/utils';
import { useDispatch } from 'react-redux';

const { Notification, FlexPane, Text, Button } = MojitoPresentation.Components;
const BetsTypes = MojitoServices.Bets.types;
const { ACCA_BOOST, ACCA_INSURANCE, EVENT_TRIGGERED } = BetsTypes.BONUS_TYPE;
const NumberUtils = MojitoCore.Base.NumberUtils;
const CurrencyHelper = MojitoPresentation.Utils.CurrencyHelper;
const log = MojitoCore.logger.get('BetBonusPromotions');
const intentActions = MojitoCore.Intents.actions;
const { useSubConfig } = MojitoCore.Presentation.Hooks;

const BetBonusPromotions = props => {
    const { bonuses, isActive, mojitoTools, onReadMoreClick, currencyCode } = props;
    const { config, stringResolver } = mojitoTools;
    const bonusesCache = useRef();
    if (isActive) {
        // We will store bonuses in ref to avoid blinking during bonus validations on server.
        bonusesCache.current = bonuses;
    }

    const containerConfig = isActive ? config.container : config.disabledContainer;
    const readMoreConfig = useSubConfig(
        config,
        'etbReadMoreButtonLabel',
        'etbReadMoreButton',
        'etbReadMoreIntent'
    );
    const notificationConfigsMap = {
        [ACCA_BOOST]: config.accaBoostNotification,
        [ACCA_INSURANCE]: config.accaInsuranceNotification,
        [EVENT_TRIGGERED]: config.etbNotification,
    };

    const betBonuses = bonusesCache.current || bonuses;
    if (isEmpty(betBonuses)) {
        return null;
    }
    return (
        <FlexPane config={containerConfig} class="ta-BetBonusPromotions">
            {betBonuses.map(bonus => {
                const message = getBonusMessage(bonus, stringResolver, currencyCode);
                return (
                    message && (
                        <Notification
                            key={bonus.type}
                            config={notificationConfigsMap[bonus.type]}
                            class={`ta-${bonus.type}`}
                            text={message}
                        >
                            {isETBAndFreebetGenerated(bonus) && (
                                <ReadMoreButton
                                    stringResolver={stringResolver}
                                    config={readMoreConfig}
                                    onClick={onReadMoreClick}
                                />
                            )}
                        </Notification>
                    )
                );
            })}
        </FlexPane>
    );
};

const isETBAndFreebetGenerated = bonus =>
    bonus.type === EVENT_TRIGGERED && bonus.isFreebetGenerated;

const ReadMoreButton = props => {
    const dispatch = useDispatch();
    const { config, stringResolver, onClick } = props;
    return (
        <Button
            config={config.readMoreButton}
            onClick={() => onEtbReadMoreClick(config, onClick, dispatch)}
        >
            <Text config={config.readMoreButtonLabel} class={'ta-etb-read-more'}>
                {stringResolver.resolveString('$BET_BONUS_PROMOTIONS.EVENT_TRIGGERED_READ_MORE')}
            </Text>
        </Button>
    );
};

const getEtbMessage = (bonus, stringResolver) => {
    const { name, description } = bonus;
    return stringResolver.resolveAndFormatString(
        '$BET_BONUS_PROMOTIONS.EVENT_TRIGGERED_TEXT',
        name,
        description
    );
};

const getBonusMessage = (bonus, stringResolver, currencyCode) => {
    const messageResolver = {
        [ACCA_BOOST]: getAccaBoostMessage,
        [ACCA_INSURANCE]: getAccaInsuranceMessage,
        [EVENT_TRIGGERED]: getEtbMessage,
    };
    const getMessage = messageResolver[bonus.type] || logUnknownBonus;
    return getMessage(bonus, stringResolver, currencyCode);
};

const getAccaBoostMessage = (bonus, stringResolver, currencyCode) => {
    const { rate, maxCap, value } = bonus;
    const bonusRate = Number(rate);
    const bonusMaxCap = Number(maxCap) || 0;
    const bonusValue = Number(value) || 0;
    if (isNaN(bonusRate) || bonusRate === 1) {
        return stringResolver.resolveString('$BET_BONUS_PROMOTIONS.ACCA_BOOST_TEXT');
    }
    const percent = NumberUtils.percentageMultiplierToPercentageValue(bonusRate);

    if (bonusValue) {
        return stringResolver.resolveAndFormatString(
            '$BET_BONUS_PROMOTIONS.ACCA_BOOST_TEXT_WITH_VALUE',
            percent,
            CurrencyHelper.formatCurrency(bonusValue, currencyCode)
        );
    }

    return bonusMaxCap
        ? stringResolver.resolveAndFormatString(
              '$BET_BONUS_PROMOTIONS.ACCA_BOOST_TEXT_WITH_CAP',
              percent,
              CurrencyHelper.formatCurrency(bonusMaxCap, currencyCode)
          )
        : stringResolver.resolveAndFormatString(
              '$BET_BONUS_PROMOTIONS.ACCA_BOOST_TEXT_WITH_NO_CAP',
              percent
          );
};

const getAccaInsuranceMessage = (bonus, stringResolver) =>
    stringResolver.resolveString('$BET_BONUS_PROMOTIONS.ACCA_INSURANCE_TEXT');

const onEtbReadMoreClick = (config, onClick, dispatch) => {
    const { etbReadMoreIntent } = config;
    if (etbReadMoreIntent && etbReadMoreIntent.type) {
        dispatch(intentActions.publishIntent(etbReadMoreIntent.type, etbReadMoreIntent.data));
    } else {
        log.warn('readMoreIntent is not configured');
    }
    onClick();
};

const logUnknownBonus = bonus => log.warn(`Unknown bonus type ${bonus.type}.`);

export default BetBonusPromotions;
