import MojitoCore from 'mojito/core';
import Timer from 'presentation/components/timer/index.jsx';
import TimerTypes from 'presentation/components/timer/types';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const { DateTimeUtils } = MojitoCore.Base;
const LAYOUT_DIRECTION = Object.freeze({
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
});

const SessionTime = props => {
    const { loginTime, mojitoTools } = props;
    const { config, stringResolver } = mojitoTools;
    const { layoutDirection } = config;

    const time = DateTimeUtils.diffInSeconds(new Date(), new Date(loginTime));
    const className = `ta-SessionTime ta-${layoutDirection}-layout`;
    const isHorizontal = layoutDirection === LAYOUT_DIRECTION.HORIZONTAL;
    const timerConfig = isHorizontal ? config.timerHorizontal : config.timerVertical;
    const labelText = isHorizontal
        ? stringResolver.resolveString('$SESSION_TIME.HORIZONTAL_LABEL')
        : stringResolver.resolveString('$SESSION_TIME.VERTICAL_LABEL');
    const timerLabel = config.showLabel ? labelText : '';

    return (
        <FlexPane config={config.container} class={className}>
            <Timer
                time={time}
                startTimeLabel={timerLabel}
                config={timerConfig}
                direction={TimerTypes.TIMER_DIRECTION.FORWARD}
                format={config.timeFormat}
            />
        </FlexPane>
    );
};

export default SessionTime;
