import MojitoPresentation from 'mojito/presentation';
import MojitoCore from 'mojito/core';
import { isEmpty } from 'mojito/utils';

const AbstractRouteResolver = MojitoCore.Base.AbstractRouteResolver;
const { NAVIGATION, PARAMETER } = MojitoPresentation.Base.Navigation.types;
import { generatePath } from 'react-router-dom';

const { BAD_REQUEST_PAGE } = NAVIGATION;
const log = MojitoCore.logger.get('RouteResolver');

/**
 *
 * RouteResolver constructor.
 *
 * @classdesc Default route resolver for resolving logical content uri's to concrete routes.
 *
 * @class RouteResolver
 * @param {object} paths - Paths map object. Typically key in this map is one of the {@link Mojito.Presentation.Base.Navigation.types|navigation type}
 * and value is path patter, e.g., '/inplay/events/:eventId'.
 * @param {string} [root = '/'] - The root of all route paths. For example, if root: '/sportsbook' is specified then
 * all navigation will be performed under '/sportsbook' root prefix, e.g., '/sportsbook/inplay/events/:eventId'.
 *
 * @extends Mojito.Core.Base.AbstractRouteResolver
 * @memberof Mojito.Application
 */
export default class RouteResolver extends AbstractRouteResolver {
    constructor(paths, root = '/') {
        super();
        this.paths = paths;
        this.root = root;
    }

    setRoot(value) {
        this.root = value;
    }

    getRoot() {
        return this.root;
    }

    getPattern(type) {
        if (!type) {
            log.warn(`Type pattern is not provided`);
        }
        const pattern = this.paths[type];
        if (typeof pattern !== 'string' && typeof pattern !== 'object') {
            log.error(
                `Navigation type ${type} has unsupported type. Should be either string or plain object.`
            );
            return;
        }
        return pattern;
    }

    /**
     * Resolves navigation route to be used in URL.
     * If NavigationPayload type is not provided or not found in paths, then {@link Mojito.Presentation.Base.Navigation.types|BAD_REQUEST_PAGE}
     * route will be generated.
     *
     * @param {Mojito.Presentation.Base.Navigation.types.NavigationPayload} navigationPayload - Navigation payload object.
     * Note: if navigation type {@link Mojito.Presentation.Base.Navigation.types|CUSTOM_NAVIGATION} is used then the route from 'url' property will be used to generate function result.
     *
     * @returns {string} Resolved navigation route.
     * @function Mojito.Application.RouteResolver#getRoute
     */
    getRoute(navigationPayload) {
        const { type, params } = navigationPayload;
        if (type === NAVIGATION.CUSTOM_NAVIGATION) {
            return this.enrichWithRoot(params[PARAMETER.URL]);
        }
        let pattern = this.getPattern(type);
        if (pattern === undefined) {
            return this.enrichWithRoot(this.getPattern(BAD_REQUEST_PAGE));
        }
        pattern = this.resolveNestedRoute(navigationPayload, this.paths);
        return this.enrichWithRoot(pattern);
    }

    /**
     * This method recursively traverses through all the nested navigation types and resolves them into a single absolute route.
     *
     * @param {Mojito.Presentation.Base.Navigation.types.NavigationPayload} navigationPayload - Navigation payload object.
     * @param {object} paths - An object mapping paths, where each key typically corresponds to a {@link Mojito.Presentation.Base.Navigation.types|navigation type}.
     * @param {boolean} [shouldGeneratePath = true] - A flag indicating whether a path should be generated or not.
     * @returns {string|undefined} If successful, the method returns a string representing the fully resolved navigation route in the format 'path/params/nestedRoute', otherwise, it returns 'undefined' if the route cannot be resolved.
     * @private
     */
    resolveNestedRoute(navigationPayload, paths, shouldGeneratePath = true) {
        if (isEmpty(paths) || isEmpty(navigationPayload)) {
            return;
        }
        const { type, params, anchor, nested } = navigationPayload;
        let nestedRoute;
        const pattern = paths[type];
        let path = pattern;
        // path will be always undefined because custom navigation doesn't exist in paths
        if (type === NAVIGATION.CUSTOM_NAVIGATION) {
            path = params[PARAMETER.URL];
        }
        if (path.nested) {
            nestedRoute = this.resolveNestedRoute(nested, pattern.nested);
            path = pattern.path;
        }
        const isNested = !!nestedRoute;
        nestedRoute = isNested ? `/${nestedRoute}` : '';
        const anchorLink = anchor && !isNested ? `#${anchor}` : '';

        return shouldGeneratePath
            ? `${generatePath(path, params)}${nestedRoute}${anchorLink}`
            : `${path}${nestedRoute}`;
    }

    /**
     * Enrich navigation path with root prefix.
     *
     * @param {string} path - Navigation path.
     *
     * @returns {string} Navigation path prefixed with root.
     * @function Mojito.Application.RouteResolver#enrichWithRoot
     */
    enrichWithRoot(path) {
        let root = this.getRoot() || '/';
        if (root !== '/') {
            root = `${root}/`;
        }
        // Prevent slashes duplication
        return `${root}${path}`.replace(/\/+/g, '/');
    }
}
