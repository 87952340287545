import {AbstractFeature, allFeatures} from 'core/application/abstract-feature';
import {loadScript} from 'core/utils/utils';

import SCHEMA from './gtag.schema.yaml';

class GtagFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);

        this.initialized = false;
    }

    get name() {
        return 'Gtag';
    }

    get ready() {
        return this.initialized && this.config.enabled;
    }

    get configSchema() {
        return SCHEMA;
    }

    get isThirdParty() {
        return true;
    }

    get isTracker() {
        return true;
    }

    cookieConsentGiven() {
        if (this.scriptLoaded) {
            return true;
        }

        const {id} = this.config;
        const url = `https://www.googletagmanager.com/gtag/js?id=${id}`;

        loadScript(url).then(() => {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', id);
            this.scriptLoaded = true;
        });
    }
}

export const Gtag = new GtagFeature(allFeatures);
