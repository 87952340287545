export default {
    /**
     * Available header columns.
     *
     * @readonly
     * @enum {string}
     * @memberof Mojito.Modules.Bonuses.BonusHistory.types
     */
    HEADER_COLUMNS: {
        NAME: 'name',
        DESCRIPTION: 'description',
        EXPIRY: 'expiry',
        TOTAL: 'total',
        REMAINING: 'remaining',
        STATUS: 'status',
    },
};
