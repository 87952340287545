import MojitoCore from 'mojito/core';
import AbstractBootstrapService from './abstract-bootstrap-service.js';

const RequestFactory = MojitoCore.Services.Transactions.RequestFactory;
const BASE_URL = '/';
const logger = MojitoCore.logger.get('BootstrapService');

/**
 * Bootstrap service config.
 *
 * @typedef BootstrapServiceConfig
 * @type {object}
 * @property {string} serviceUrl - URL that will be used to perform API calls.
 *
 * @memberof Mojito.Services.Bootstrap.service
 */

/**
 * Represents a class that allows interaction with the Mojito transactional API.
 * This class is designed to perform top-level bootstrap requests not tied to any specific endpoints.
 * For example, it can be used to initialize a generic API configuration by calling the `initApi` method.
 *
 * @class BootstrapService
 * @name service
 * @extends Mojito.Services.Bootstrap.AbstractBootstrapService
 * @memberof Mojito.Services.Bootstrap
 */
class BootstrapService extends AbstractBootstrapService {
    /**
     * Configure service instance.
     *
     * @param {Mojito.Services.Bootstrap.service.BootstrapServiceConfig} config - Service configuration object.
     *
     * @function Mojito.Services.Bootstrap.service#configure
     */
    configure(config) {
        this.serviceUrl = config.serviceUrl;
        this.requestFactory = new RequestFactory(logger);
        this.apiUrl = `${this.serviceUrl + BASE_URL}`;
    }

    /**
     * Initialise mojito transactional API with provided config.
     * This method should be called before any other request to transactional API endpoints.
     *
     * @param {Mojito.Services.Common.types.TransactionalAPIConfig} config - API config.
     * @returns {Promise} Init API promise.
     * @function Mojito.Services.Bootstrap.service#initApi
     */
    initApi(config) {
        return this.requestFactory
            .post(`${this.apiUrl}configuration/init`)
            .withDescription('initialise transactional API')
            .withCredentials()
            .send(config);
    }
}

export default new BootstrapService();
