import PropTypes from 'prop-types';
import MiniScoreboardImpl from './mini-scoreboard.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * View to a display a small scoreboard intended to be re-used in other components.
 * The scoreboard includes event time segments, scores, and server indication when applicable.
 *
 * @class MiniScoreboard
 * @memberof Mojito.Presentation.Components
 */

/**
 * MiniScoreboard prop types.
 *
 * @property {object} [event] - The event item.
 *
 * @memberof Mojito.Presentation.Components.MiniScoreboard
 */
const propTypes = {
    event: PropTypes.object,
};

export default UIView('MiniScoreboard', MiniScoreboardImpl, propTypes);
