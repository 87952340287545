import PropTypes from 'prop-types';
import BetHistoryDetailsImpl from './bet-history-details.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';
const UIView = MojitoCore.Presentation.UIView;

/**
 * View displaying the details of the bet. Leg(s) info, tax info, bonuses etc.
 *
 * @class BetHistoryDetails
 * @public
 * @memberof Mojito.Modules.BetHistoryDetails
 */

/**
 * BetHistoryDetails prop types.
 *
 * @property {Mojito.Services.Bets.types.Bet} bet - The bet.
 * @property {object} [pdfData = {isFetching: false, hasError: false}] - Information about fetching of pdf.
 * @property {Function} [onCloseClick = () => {}] - Callback for closing on the close icon.
 * @memberof Mojito.Modules.BetHistoryDetails
 */
const propTypes = {
    bet: PropTypes.object.isRequired,
    onCloseClick: PropTypes.func,
    pdfData: PropTypes.shape({
        isFetching: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
    }),
};

const defaultProps = {
    pdfData: {
        isFetching: false,
        hasError: false,
    },
    onCloseClick: noop,
};

export default UIView('BetHistoryDetails', BetHistoryDetailsImpl, propTypes, defaultProps);
