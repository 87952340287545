import { createRef } from 'react';
import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import TextInput from 'presentation/components/text-input/index.jsx';
import Button from 'presentation/components/button/index.jsx';
import Spinner from 'presentation/components/spinner/index.jsx';

const { UIViewImplementation } = MojitoCore.Presentation;

export default class SearchInput extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.state = {
            hasText: this.props.default.length > 0,
            searchString: this.props.default,
        };
        this.textInput = createRef();

        this.setFocus = this.setFocus.bind(this);
        this.onInputChanged = this.onInputChanged.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentDidMount() {
        if (this.props.initiallyActive) {
            // Because of different ways on to handle the autoFocus in Safari and Chrome (on device), this structure is needed.
            const element = this.textInput;
            // Set autoFocus attribute on the input field to get the keyboard in Safari.
            element.current && element.current.setAttribute('autoFocus', '');
            // Trigger focus() to get the keyboard in Chrome.
            setTimeout(() => this.setFocus());
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.default !== this.props.default) {
            this.setState({
                hasText: this.props.default.length > 0,
                searchString: this.props.default,
            });
        }
    }

    onInputChanged(value) {
        this.setState({
            searchString: value,
            hasText: value && value.length > 0,
        });
        this.props.onChange(value.trim());
    }

    setFocus() {
        const element = this.textInput;
        element.current && element.current.focus();
    }

    hasFocus() {
        const element = this.textInput;
        return element.current === document.activeElement;
    }

    onClear() {
        this.setState({
            searchString: '',
            hasText: false,
        });
        this.setFocus();
        this.props.onChange('');
        this.emitAnalytics('searchInputCleared', this.state.searchString);
    }

    renderSearchIconOrSpinner() {
        if (this.props.showSpinner) {
            return (
                <FlexPane config={this.config.spinnerContainer}>
                    <Spinner config={this.config.spinner} />
                </FlexPane>
            );
        }
        const searchIconConfig = this.hasFocus()
            ? this.config.activeSearchIcon
            : this.config.inactiveSearchIcon;
        return (
            <FlexPane class="ta-searchIcon" config={this.config.searchIconContainer}>
                <Image config={searchIconConfig} />
            </FlexPane>
        );
    }

    render() {
        const containerConfig =
            this.state.hasText || this.hasFocus() ? this.style.active : this.style.inactive;

        return (
            <div style={this.style.wrapperContainer}>
                <FlexPane config={containerConfig} class="ta-SearchInput">
                    {this.renderSearchIconOrSpinner()}
                    <FlexPane config={this.config.inputContainer}>
                        <TextInput
                            inputParams={this.config.inputParams}
                            config={this.config.textInput}
                            value={this.state.searchString}
                            placeholderValue={this.props.placeholderValue}
                            onFocus={this.props.onFocus}
                            onChange={this.onInputChanged}
                            onKeyEnter={this.props.onKeyEnter}
                            inputElementRef={this.textInput}
                        />
                        {this.state.hasText && (
                            <Button
                                class="ta-clear-button"
                                config={this.config.clearButton}
                                onClick={this.onClear}
                            >
                                <Image config={this.config.clearIcon} />
                            </Button>
                        )}
                    </FlexPane>
                </FlexPane>
            </div>
        );
    }
}

SearchInput.getStyle = function (config, applicationMode, merge) {
    return {
        active: merge(config.container.base, config.container.active),
        inactive: merge(config.container.base, config.container.inactive),
        wrapperContainer: config.style.wrapperContainer,
    };
};
