import { range } from 'mojito/utils';
import SkeletonPart from 'presentation/components/skeleton-part/index.jsx';
import FlexPane from 'presentation/components/flex-pane/index.jsx';

const ButtonBarSkeleton = props => {
    const {
        mojitoTools: { config },
    } = props;

    return (
        <FlexPane config={config.container} class="ta-ButtonBarSkeleton">
            {range(config.numberOfButtons).map(i => {
                return <SkeletonPart key={i} config={config.buttonSkeleton} />;
            })}
        </FlexPane>
    );
};

export default ButtonBarSkeleton;
