import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';

const {
    actions: betslipActions,
    selectors: { selectSelectionState },
} = MojitoServices.Betslip;
const { dispatch } = MojitoCore.Services.redux.store;

const ACTIVE_STATE = { isPending: false };

/**
 * Context object defines actions to add and remove prebuilt bet to the betslip
 * and selector function to get current state of added selections from the betslip store.
 *
 * @class PrebuiltBetSelectionsContext
 * @type {Mojito.Modules.SelectionButton.Context.SelectionsContextDefinition}
 * @memberof Mojito.Modules
 */
export default class PrebuiltBetSelectionsContext {
    constructor(matchAccaParts) {
        this.matchAccaParts = matchAccaParts;
    }

    addSelection(payload) {
        dispatch(betslipActions.addSelection(payload));
    }

    removeSelection(payload) {
        const partIds = this.matchAccaParts.map(part => part.id);
        dispatch(betslipActions.removeSelectionsByParts({ partIds, source: payload.source }));
    }

    selectionStateSelector(state, selectionId) {
        const mathAccaSelectionState = selectSelectionState(selectionId, state);
        if (mathAccaSelectionState?.isPending) {
            return mathAccaSelectionState;
        }
        const everyPartInSlip = this.matchAccaParts.every(
            part => selectSelectionState(part.id, state)?.isPending === false
        );
        if (everyPartInSlip) {
            return ACTIVE_STATE;
        }
    }
}
