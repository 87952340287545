import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import FreeBetItemsImpl from './free-bet-items.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * View that renders free bet item.
 *
 * @class FreeBetItems
 * @memberof Mojito.Modules.Bonuses
 */

/**
 * FreeBetItems prop types.
 *
 * @property {Mojito.Services.Bonus.types.FreeBet[]} freeBets - List of free bets.
 * @property {string} [currencyCode] - User currency code.
 *
 * @memberof Mojito.Modules.FreeBetItems
 */
const propTypes = {
    freeBets: PropTypes.array.isRequired,
    currencyCode: PropTypes.string,
};

export default UIView('FreeBetItems', FreeBetItemsImpl, propTypes);
