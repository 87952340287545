import PropTypes from 'prop-types';
import ApplicationMenuImpl from './application-menu.jsx';
import MojitoCore from 'mojito/core';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Component to show the top part of the header. It renders a logo and the CrossSellMenu, SystemInitialisation and
 * UserAuthenticationInfo components.
 * <br><br>
 * If its 'layout' config is set to 'single-row', all its content will be
 * included in a single row container. Since there will be no room for the SystemInitialisation component, CrossSellMenu
 * taking its position, the component will not render it. If the 'layout' is set to 'multi-row', the
 * CrossSellMenu will be rendered on a row below the other content. SystemInitialisation will then be included in the
 * top row.
 *
 * @class ApplicationMenu
 * @memberof Mojito.Application
 */

/**
 * ApplicationMenu prop types.
 *
 * @property {Mojito.Services.Authentication.types.UserInfo} [userInfo] - User info data.
 * @property {Mojito.Services.Bonus.types.FreeBet[]} [freeBets] - List of free bets items.
 *
 * @memberof Mojito.Application.ApplicationMenu
 */
const propTypes = {
    userInfo: PropTypes.object,
    freeBets: PropTypes.array,
};

export default UIView('ApplicationMenu', ApplicationMenuImpl, propTypes);
