/* eslint no-unused-vars: "off" */

import MojitoCore from 'mojito/core';

import {Logger} from 'core/utils/logger';
import {SchemaValidator} from 'core/utils/validation/schema-validator';
import {isAppTrackingConsentAccepted} from 'core/utils/utils';
import {isNative} from 'core/utils/context-utils';
import {registerSchema} from './schema-registry';

const reduxInstance = MojitoCore.Services.redux;
const {dispatch} = MojitoCore.Services.redux.store;
const {actions: cookieConsentActions, selectors: cookieConsentSelectors} = MojitoCore.Services.CookieConsent;

function enabledOnly(feature) {
    return feature.enabled;
}

//Constants for prioritizing feature initialization
export const PRIORITY = {
    FIRST: 0,
    CORE: 100,
    CONFIGS: 200,
    ANALYTICS: 300,
    DEFAULT: 400,
};

export class AbstractFeature {
    constructor(parent = null) {
        this.logger = Logger(this.name);
        this.config = {};
        this.registeredDependants = []; // list of dependants
        this.dependants = []; // same list but filtered out disabled ones

        _featuresMap[this.name] = this;

        if (!parent) {
            throw new Error(`Feature ${this.name} should have parent`);
        }
        parent.registeredDependants.push(this);
        this.configSchema && registerSchema(this.configSchema);
    }

    get name() {
        return this.constructor.name;
    }

    get priority() {
        return PRIORITY.DEFAULT;
    }

    /**
     * Indicates that feature is allowed by config
     * @return {boolean}
     */
    get enabled() {
        if (this.config.enabled !== undefined) {
            return this.config.enabled;
        }
        return true;
    }

    /**
     * Indicates that feature is successfully initialized and ready to go
     * @return {boolean}
     */
    get ready() {
        return this.enabled;
    }

    /**
     * Indicates feature is 3rd party.
     * All 3rd party featurees are disabled for widgets.
     * May be also temporary disabled by developers for testing purposes.
     * @return {boolean}
     */
    get isThirdParty() {
        return false;
    }

    /**
     * Indicates feature is 3rd-party tracker. User may not allow trackers so feature may be automatically disabled
     * @return {boolean}
     */
    get isTracker() {
        return false;
    }

    get configSchema() {
        return null;
    }

    get intent() {
        return null;
    }

    setupConfigs(globalConfig, options, filterFn = enabledOnly) {
        this.config = this.getConfiguration(globalConfig);
        this.registeredDependants.forEach(feature => {
            feature.setupConfigs(globalConfig, options);
        });
        this.dependants = this.registeredDependants.filter(filterFn).filter(enabledOnly);
        this.dependants.sort((firstItem, secondItem) => {
            return firstItem.priority - secondItem.priority;
        });
    }

    /**
     * Called in order to load features which sets cookies.
     */
    cookieConsentGiven() {
        this.dependants.forEach(feature => {
            feature.cookieConsentGiven();
        });
    }

    /**
     * Called before any mojito related preparation activity is started.
     * @return {Promise}
     */
    beforeMojito() {
        return Promise.all(this.dependants.map(feature => feature.beforeMojito()));
    }

    /**
     * Called after mojito is started but before first render occurs
     * @return {Promise}
     */
    afterMojito() {
        return Promise.all(this.dependants.map(feature => feature.afterMojito()));
    }

    /**
     * Parse and validate configuration
     * @param globalConfig {Object} Global configuration
     * @return {Object} valid configuration object
     */
    getConfiguration(globalConfig) {
        const schema = this.configSchema;
        if (schema) {
            const validator = new SchemaValidator(this.logger, typeTransformHandlers);
            const config = validator.buildConfigFromSchema(globalConfig, schema);

            return config || {enabled: false};
        }
        return globalConfig;
    }

    /**
     * This method is called before default config is merged with viewsconfig
     * @param mojitoConfig {Object} - Mojito configuration object
     */
    beforeMojitoConfigBuild(mojitoConfig) {
        // do nothing
        this.dependants.forEach(feature => feature.beforeMojitoConfigBuild(mojitoConfig));
    }

    /**
     * Called after mojito config is built
     * @param mojitoConfig {Object} - Mojito configuration object
     */
    afterMojitoConfigBuild(mojitoConfig) {
        this.dependants.forEach(feature => feature.afterMojitoConfigBuild(mojitoConfig));
    }

    /**
     * Called before tokens preparation is started.
     */
    beforeTokensBuild() {
        this.dependants.map(feature => feature.beforeTokensBuild());
    }

    /**
     * Called after tokens preparation finished
     */
    afterTokensBuild() {
        this.dependants.map(feature => feature.afterTokensBuild());
    }

    /**
     * Called just before sportsbook is going to mount.
     * @param containerDOMNode {DOMNode} Container node where sportsbook is going to mount. Its topmost level container
     */
    beforeMount(containerDOMNode) {
        this.dependants.map(feature => feature.beforeMount(containerDOMNode));
    }

    /**
     * Called just after sportsbook is mounted
     * @param DOMNode {DOMNode} Node where sportsbook is mounted. Its topmost level container
     */
    afterMount(DOMNode) {
        this.dependants.map(feature => feature.afterMount(DOMNode));
    }

    beforeUnmount() {
        this.dependants.map(feature => feature.beforeUnmount());
    }

    /**
     * Called when sportsbook going to be shut down
     */
    dispose() {
        this.dependants.forEach(feature => feature.dispose());
    }
}

const _featuresMap = [];

class AllFeatures extends AbstractFeature {
    constructor() {
        super({registeredDependants: []});

        this.cookieConsentGiven = this.cookieConsentGiven.bind(this);
    }

    getFeatureByName(name) {
        return this.dependants.find(feature => feature.name === name);
    }

    cookieConsentRestrictionsInit() {
        let oneTrustFeature = this.getFeatureByName('OneTrust');

        if (isNative() && isAppTrackingConsentAccepted()) {
            dispatch(cookieConsentActions.giveConsent());
        }

        if (!oneTrustFeature || !oneTrustFeature.enabled) {
            if (cookieConsentSelectors.selectCookieConsent()) {
                this.cookieConsentGiven();
            } else {
                reduxInstance.actionListener.startListening({
                    actionCreator: cookieConsentActions.giveConsent,
                    effect: this.cookieConsentGiven.bind(this),
                });
            }
        }
    }

    get featuresMap() {
        return _featuresMap;
    }

    get name() {
        return 'AllFeatures';
    }

    setupConfigs(globalConfig, options, filterFn) {
        const start = performance.now();
        super.setupConfigs(...arguments);
        performance.measure('dbx:all-features:setupConfigs', {start});
    }

    beforeMojito() {
        const start = performance.now();
        return super.beforeMojito().then(() => {
            performance.measure('dbx:all-features:beforeMojito', {start});
        });
    }

    afterMojito() {
        const start = performance.now();
        return super.afterMojito().then(() => {
            performance.measure('dbx:all-features:afterMojito', {start});
        });
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const start = performance.now();
        super.beforeMojitoConfigBuild(...arguments);
        performance.measure('dbx:all-features:beforeMojitoConfigBuild', {start});
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const start = performance.now();
        super.afterMojitoConfigBuild(...arguments);
        performance.measure('dbx:all-features:afterMojitoConfigBuild', {start});
    }

    beforeTokensBuild() {
        const start = performance.now();
        super.beforeTokensBuild(...arguments);
        performance.measure('dbx:all-features:beforeTokensBuild', {start});
    }

    afterTokensBuild() {
        const start = performance.now();
        super.afterTokensBuild(...arguments);
        performance.measure('dbx:all-features:afterTokensBuild', {start});
    }

    beforeMount(containerDOMNode) {
        const start = performance.now();
        super.beforeMount(containerDOMNode);
        performance.measure('dbx:all-features:beforeMount', {start});
    }

    afterMount(DOMNode) {
        const start = performance.now();
        super.afterMount(DOMNode);
        performance.measure('dbx:all-features:afterMount', {start});
    }
    beforeUnmount() {
        const start = performance.now();
        super.beforeUnmount();
        performance.measure('dbx:all-features:beforeUnmount', {start});
    }
}

export const allFeatures = new AllFeatures();
const typeTransformHandlers = {};

/**
 * Registers type value handler
 *
 * @param type {string} - type to handle
 * @param handler {func} - handler to register
 */
export function registerTypeTransformHandler(type, handler) {
    typeTransformHandlers[type] = handler;
}
