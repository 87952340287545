import PrebuiltBetsSkeletonImpl from './prebuilt-bets-skeleton.jsx';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Prebuilt bet skeleton component used to represent loading prebuilt bets.
 *
 * @function PrebuiltBetsSkeleton
 * @memberof Mojito.Modules
 */

export default UIView('PrebuiltBetsSkeleton', PrebuiltBetsSkeletonImpl);
