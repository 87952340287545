import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

const {
    actions: bonusFreeBetsActions,
    selectors: bonusFreeBetsSelectors,
    types: BonusFreeBetTypes,
} = MojitoServices.BonusFreeBets;
const { selectFreeBetCode, selectFreeBetCodeState, selectFreeBetCodeError } =
    bonusFreeBetsSelectors;
const { TextInput, Text, FlexPane, Button, Image, Spinner } = MojitoPresentation.Components;
const { ACCEPTED, PENDING, ERROR } = BonusFreeBetTypes.FREE_BETS_CODE_STATE;

const FreeBetCode = props => {
    const { mojitoTools } = props;
    const { config, style } = mojitoTools;
    const dispatch = useDispatch();

    const { freeBetCode, freeBetCodeState, freeBetCodeError } = useSelector(
        state => ({
            freeBetCode: selectFreeBetCode(state),
            freeBetCodeState: selectFreeBetCodeState(state),
            freeBetCodeError: selectFreeBetCodeError(state),
        }),
        shallowEqual
    );

    const onInputChange = value => dispatch(bonusFreeBetsActions.inputFreeBet(value));
    const onAddFreeBetClick = () => dispatch(bonusFreeBetsActions.addFreeBet());

    useEffect(() => {
        return () => {
            dispatch(bonusFreeBetsActions.inputFreeBet(''));
        };
    }, [dispatch]);

    const hasError = freeBetCodeState === ERROR;
    const isAccepted = freeBetCodeState === ACCEPTED;

    return (
        <FlexPane config={config.container}>
            {config.showCompactLayout && <Label {...props} />}
            <FlexPane config={style.contentContainer}>
                {!config.showCompactLayout && <Label {...props} />}
                <TextInput
                    config={config.input}
                    value={freeBetCode}
                    onChange={onInputChange}
                    hasError={hasError}
                    class="ta-promotioncodeTextInput"
                    onKeyEnter={onAddFreeBetClick}
                />
                <Button
                    config={config.button}
                    onClick={onAddFreeBetClick}
                    key={freeBetCodeState}
                    class="ta-addVoucherCode"
                >
                    <ButtonContent {...props} />
                </Button>
            </FlexPane>
            {hasError && <ErrorMessage {...props} error={freeBetCodeError} />}
            {isAccepted && <SuccessMessage {...props} />}
        </FlexPane>
    );
};

const ButtonContent = props => {
    const { freeBetCodeState, mojitoTools } = props;
    const { config } = mojitoTools;

    if (freeBetCodeState === PENDING) {
        return <Spinner config={config.spinner} />;
    }

    return <Image config={config.submitIcon} />;
};

const Label = props => {
    const { mojitoTools } = props;
    const { stringResolver, config } = mojitoTools;

    return (
        <FlexPane config={config.labelContainer}>
            <Text config={config.label} class="ta-promotionCodeLabel">
                {stringResolver.resolveString('$FREE_BET_CODE.LABEL')}
            </Text>
        </FlexPane>
    );
};

const ErrorMessage = props => {
    const { mojitoTools, error = {} } = props;
    const { stringResolver, config } = mojitoTools;
    const errorMessage =
        error.message || stringResolver.resolveString(`$FREE_BET_CODE.ERROR.${error.type}`);
    const errorMessageComponent = <Text config={config.error}>{errorMessage}</Text>;
    if (!config.showCompactLayout) {
        return <FlexPane config={config.messageContainer}>{errorMessageComponent}</FlexPane>;
    }

    return errorMessageComponent;
};

const SuccessMessage = props => {
    const { mojitoTools } = props;
    const { stringResolver, config } = mojitoTools;

    return (
        <MessageContainer {...props}>
            <FlexPane config={config.successContainer}>
                {config.successMessageIcon.src && <Image config={config.successMessageIcon} />}
                <Text config={config.success}>
                    {stringResolver.resolveString(`$FREE_BET_CODE.SUCCESS`)}
                </Text>
            </FlexPane>
        </MessageContainer>
    );
};

const MessageContainer = props => {
    const { mojitoTools, children } = props;
    const { config } = mojitoTools;
    return config.showCompactLayout ? (
        children
    ) : (
        <FlexPane config={config.messageContainer}>{children}</FlexPane>
    );
};

FreeBetCode.getStyle = function (config, applicationMode, merge) {
    return {
        contentContainer: merge(config.contentContainer, {
            nthSeparator:
                applicationMode === 'mobile'
                    ? {
                          0: config.inputAndButtonSeparator,
                      }
                    : {
                          0: config.labelAndInputSeparator,
                          1: config.inputAndButtonSeparator,
                      },
        }),
    };
};

export default FreeBetCode;
