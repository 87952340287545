import MojitoServices from 'mojito/services';

const AbstractNotificationsService = MojitoServices.SportsContent.ContentNotifications.AbstractNotificationsService;

export class FakeContentNotificationsService extends AbstractNotificationsService {
    followEvent() {
        return Promise.resolve();
    }

    unfollowEvent() {
        return Promise.resolve();
    }

    login() {}

    logout() {}

    isAvailable() {
        return true;
    }

    isActivated() {
        return true;
    }
}
