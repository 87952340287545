import MojitoCore from 'mojito/core';
import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import DateTimeFormatter from 'presentation/formatters/date-time/index.js';
import FixedWidthTime from 'presentation/components/fixed-width-time/index.jsx';

const { UIViewImplementation } = MojitoCore.Presentation;
const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;

export default class Clock extends UIViewImplementation {
    constructor(props) {
        super(props);
        this.state = {
            currentUTC: new Date(),
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isShallowEqual(this.props, nextProps) || !isShallowEqual(this.state, nextState);
    }

    componentDidMount() {
        const updateCurrentTime = () => {
            const currentUTC = new Date();
            if (this.state.currentUTC.getSeconds() !== currentUTC.getSeconds()) {
                this.setState({ currentUTC });
            }
            this.rAf = requestAnimationFrame(updateCurrentTime);
        };
        this.rAf = requestAnimationFrame(updateCurrentTime);
    }

    componentWillUnmount() {
        this.rAf && cancelAnimationFrame(this.rAf);
    }

    renderOffset() {
        if (this.config.isOffsetHidden) {
            return null;
        }
        const offsetValueFormat =
            this.props.timeOffset >= 0 ? `+${this.props.timeOffset}` : this.props.timeOffset;
        return (
            <Text config={this.config.offset} class="offset">
                {this.resolveAndFormatString('$CLOCK.OFFSET_FORMAT', offsetValueFormat)}
            </Text>
        );
    }

    render() {
        const language = this.appContext().systemSettings().language;
        const userSettings = this.appContext().userSettings();
        const { currentUTC } = this.state;
        const { timeFormat, useHour12 } = this.config;
        const timeStr = DateTimeFormatter.formatDateTime(
            currentUTC,
            timeFormat,
            language,
            userSettings,
            useHour12
        );
        return (
            <FlexPane config={this.config.container} class={`ta-Clock ${this.props.class || ''}`}>
                <Text config={this.config.time} class="time">
                    <FixedWidthTime time={timeStr} />
                </Text>
                {this.renderOffset()}
            </FlexPane>
        );
    }
}
