import GenericBetFundsInfo from 'presentation/components/bet-funds/generic-info/index.jsx';
import CurrencyHelper from 'presentation/formatters/currency/index.js';

const CashStakeInfo = ({ value, currencyCode, mojitoTools }) => {
    const { stringResolver, config } = mojitoTools;
    return (
        <GenericBetFundsInfo
            class="ta-cashStake"
            config={config.betInfo}
            label={stringResolver.resolveString('$BET_FUNDS.CASH_STAKE')}
            valueClass="ta-CashStakeValue"
            value={CurrencyHelper.formatCurrency(value, currencyCode)}
        />
    );
};

export default CashStakeInfo;
