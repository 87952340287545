import MojitoCore from 'mojito/core';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import dataRetriever from 'services/bets/data-retriever.js';
import { selectIsFetching, selectBets } from './bet-history-selectors.js';
import { isLoggedIn } from 'services/authentication/selectors.js';
import { selectOddsFormat } from 'services/user-settings/selectors.js';
import { actions as authenticationActions } from 'services/authentication/slice.js';
import { actions as bootstrapActions } from 'services/bootstrap/slice.js';
import Utils from 'services/bets/utils.js';

const reduxInstance = MojitoCore.Services.redux;

/**
 * The name of the bet history store. Will be used to register in global redux store.
 *
 * @constant
 * @type {string}
 * @memberof Mojito.Services.Bets
 */
const STORE_KEY = 'betHistoryStore';

/**
 * Defines the state of the bet history store.
 *
 * @typedef BetHistoryState
 * @type {object}
 * @property {Array<Mojito.Services.Bets.types.Bet>} bets - Bets.
 * @property {Mojito.Services.Bets.types.Pagination} pagination - Pagination info.
 * @property {boolean} isFetching - True if open bets fetching is in progress.
 * @property {string} rangeFilter - Range filter. Typically last N bets or time span.
 * @property {string} statusFilter - Bet status filter.
 * @property {Mojito.Core.Services.Transactions.types.Error} error - Error from response if any.
 *
 * @memberof Mojito.Services.Bets
 */
const initialState = {
    bets: [],
    pagination: {},
    isFetching: false,
    rangeFilter: undefined,
    statusFilter: undefined,
    error: undefined,
};

const { reducer, actions } = createSlice({
    name: STORE_KEY,
    initialState,
    reducers: {
        reset() {
            return { ...initialState };
        },
        startFetching(state) {
            state.isFetching = true;
            state.error = undefined;
        },
        updateFilters(state, { payload }) {
            state.rangeFilter = payload.rangeFilter;
            state.statusFilter = payload.statusFilter;
        },
        fetchBetsFinish(state, { payload }) {
            state.isFetching = false;
            if (payload) {
                state.bets = payload.bets;
                state.pagination = payload.pagination;
            }
        },
        fetchBetsFailed(state, { payload: error }) {
            return { ...initialState, error };
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            isAnyOf(authenticationActions.disposeSession, bootstrapActions.dispose),
            () => initialState
        );
    },
});

actions.fetchBets =
    ({ filters, pagination }) =>
    (dispatch, getState) => {
        const filterObj = Utils.getFilterObj(filters);
        const payload = Utils.getPayloadFromFilters(filterObj.rangeFilter, filterObj.statusFilter);
        const state = getState();
        dispatch(actions.updateFilters(filterObj));
        if (!selectIsFetching(state) && isLoggedIn(state)) {
            dispatch(actions.startFetching());
            dataRetriever.getBets({
                ...payload,
                oddsFormat: selectOddsFormat(state),
                pagination,
            });
        }
    };

actions.fetchBetsSuccess = payload => (dispatch, getState) => {
    const state = getState();
    if (isLoggedIn(state)) {
        const bets =
            payload.pagination?.page > 1 ? selectBets(state).concat(payload.bets) : payload.bets;
        dispatch(actions.fetchBetsFinish({ bets, pagination: payload.pagination }));
    } else {
        dispatch(actions.fetchBetsFinish());
    }
};

/**
 * Bet history actions.
 *
 * @class BetHistoryActions
 * @name actions
 * @memberof Mojito.Services.Bets.betHistorySlice
 */

/**
 * Reset store.
 *
 * @function reset
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.betHistorySlice.actions
 */

/**
 * Start fetching bet history.
 *
 * @function startFetching
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.betHistorySlice.actions
 */

/**
 * Keep current filter values.
 *
 * @function updateFilters
 * @param {{rangeFilter: string, statusFilter: string}} payload - Range and status filters.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.betHistorySlice.actions
 */

/**
 * Fetch bets is finished.
 * This action is typically dispatched after {@link Mojito.Services.Bets.betHistorySlice.actions.fetchBetsSuccess|fetchBetsSuccess}.
 *
 * @function fetchBetsFinish
 * @param {{bets: Array<Mojito.Services.Bets.types.Bet>, pagination: Mojito.Services.Bets.types.Pagination}} payload - Bets and pagination info.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.betHistorySlice.actions
 */

/**
 * Bets fetching has failed.
 *
 * @function fetchBetsFailed
 * @param {Mojito.Core.Services.Transactions.types.Error} payload - Error.
 * @type {Mojito.Core.Services.redux.ActionCreator}
 * @memberof Mojito.Services.Bets.betHistorySlice.actions
 */

/**
 * Fetch bets.
 *
 * @function fetchBets
 * @param {{filters: Array<Mojito.Services.Bets.types.BetHistoryFilter>, pagination: Mojito.Services.Bets.types.Pagination}} payload - Filters and pagination info.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.betHistorySlice.actions
 */

/**
 * Bets have been fetched successfully.
 *
 * @function fetchBetsSuccess
 * @param {{bets: Array<Mojito.Services.Bets.types.Bet>, pagination: Mojito.Services.Bets.types.Pagination}} payload - Bets and pagination info.
 * @returns {Mojito.Core.Services.redux.ThunkFunction} Thunk.
 * @memberof Mojito.Services.Bets.betHistorySlice.actions
 */

reduxInstance.injectReducer(STORE_KEY, reducer);

export { reducer, actions, STORE_KEY, initialState };
