import { useState, useEffect, useCallback } from 'react';
import { debounce, noop } from 'mojito/utils';

/**
 * ElementResizeDetector constructor.
 *
 * @class ElementResizeDetector
 * @name resizeDetector
 * @classdesc Class provides functionality to track DOM element resize.
 *
 * @memberof Mojito.Core.Base
 */
class ElementResizeDetector {
    constructor() {
        this.elements = new WeakMap();
        // debounce resize callback to avoid constant triggering on crazy resize interactions.
        const onResize = debounce(this.handleResize.bind(this), 100);
        this.resizeObserver = new ResizeObserver(onResize);
    }

    handleResize(entries) {
        entries.forEach(entry => {
            if (this.elements.has(entry.target)) {
                const element = this.elements.get(entry.target);
                const shouldSkipResize =
                    !element.initialResizeTriggered && element.skipInitialResize;
                element.initialResizeTriggered = true;
                if (shouldSkipResize) {
                    return;
                }
                element.onResize(entry.target, entry.contentRect);
            }
        });
    }

    /**
     * Add an observer to the ResizeObserver.
     *
     * @param {HTMLElement} element - DOM node that was resized.
     * @param {Function} resizeCallback - Callback function.
     * @param {boolean} [skipInitialResize = true] - If set to false then resizeCallback will be triggered immediately after this method called.
     * Note: initial resize on observable element is triggered by ResizeObserver. See {@link https://drafts.csswg.org/resize-observer/#ref-for-element%E2%91%A3|this} for more details.
     * This flag was intended to prevent this behaviour. If set to true then resizeCallback will be triggered only once real element resize happens.
     *
     * @function Mojito.Core.Base.resizeDetector#listen
     */
    listen(element, resizeCallback, skipInitialResize = true) {
        if (element && resizeCallback) {
            this.elements.set(element, {
                onResize: resizeCallback,
                skipInitialResize,
                initialResizeTriggered: false,
            });
            this.resizeObserver.observe(element);
        }
    }

    /**
     * Removes an observer from the ResizeObserver.
     *
     * @param {HTMLElement} element - The DOM node to stop observing for resizes.
     *
     * @function Mojito.Core.Base.resizeDetector#unlisten
     */
    unlisten(element) {
        if (element) {
            this.resizeObserver.unobserve(element);
            this.elements.delete(element);
        }
    }

    /**
     * Remove all observers from the ResizeObserver.
     *
     * @function Mojito.Core.Base.resizeDetector#disconnect
     */
    disconnect() {
        this.resizeObserver.disconnect();
        this.elements = new WeakMap();
    }
}

const resizeDetector = new ElementResizeDetector();

/**
 * Hook that adds a resize listener to an HTMLElement to monitor size changes.
 *
 * @function useResizeDetector
 *
 * @param {Function} [onResizeHandler = noop] - Function that is executed when a resize occurs.
 * @param {Array} [deps = []] - Dependencies that are evaluated on each render to recreate the handler function.
 * @param {boolean} [ignoreResize = false] - Allows to ignore element resize. Can be useful for conditional flows.
 *
 * @returns {{elementRef: Function, element: HTMLElement, width: number, height: number}}
 * An object containing the height, width, and reference to the observed HTMLElement,
 * as well as an `elementRef` function to be used as a ref callback on the desired React component.
 * @memberof Mojito.Core.Base.resizeDetector
 */
const useResizeDetector = (onResizeHandler = noop, deps = [], ignoreResize = false) => {
    const [element, setElement] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();

    // Will be executed every time resize happens on element.
    const resizeCallback = useCallback(
        (element, contentRect) => {
            onResizeHandler && onResizeHandler(element, contentRect);
            const { width, height } = contentRect || {};
            setHeight(height);
            setWidth(width);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    );

    // Element ref function. To be used by a client component to pass to a ref prop of React element.
    const elementRef = useCallback(node => !ignoreResize && setElement(node), [ignoreResize]);

    // Add/remove resize listener to newly set DOM element.
    useEffect(() => {
        const { width, height } = element ? element.getBoundingClientRect() : {};
        if (ignoreResize) {
            return;
        }
        setHeight(height);
        setWidth(width);
        resizeDetector.listen(element, resizeCallback, false);
        return () => resizeDetector.unlisten(element);
    }, [element, resizeCallback, ignoreResize]);

    return { elementRef, element, width, height };
};

export { useResizeDetector, resizeDetector, ElementResizeDetector };
