import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import EventsCouponImpl from './events-coupon.jsx';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Module that displays an events coupon.
 *
 * @class EventsCoupon
 * @memberof Mojito.Modules
 */

/**
 * `EventsCoupon` prop types.
 *
 * @property {string} sportId - Sport id.
 * @property {string} [eventGroupsId] - Event groups id.
 * @property {string|Function} [headerText] - Text used as header. If function is provided then it will be called to resolve header text with the list of presented event groups.
 * @property {boolean} [isInPlay = false] - If true, the coupon is related to in-play events. Defaults to false.
 * @property {string} [analyticsName] - Identification name for analytics.
 * @property {boolean} [showEmptyContent = false] - True if it should show empty content.
 * @property {Function} [onShowAllButtonClick = ()=>{}] - Callback executed when the 'Show All' button is clicked.
 * @property {Function} [onDataLoad = () => {}] - Callback executed when all the component's data and its child components' data have loaded.
 *
 * @memberof Mojito.Modules.EventsCoupon
 */
const propTypes = {
    sportId: PropTypes.string,
    eventGroupsId: PropTypes.string,
    headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    isInPlay: PropTypes.bool,
    analyticsName: PropTypes.string,
    showEmptyContent: PropTypes.bool,
    onDataLoad: PropTypes.func,
    onShowAllButtonClick: PropTypes.func,
};

const defaultProps = {
    isInPlay: false,
    showEmptyContent: false,
    onShowAllButtonClick: noop,
    onDataLoad: noop,
};

export default UIView('EventsCoupon', EventsCouponImpl, propTypes, defaultProps);
