import MojitoCore from 'mojito/core';
import EmptyContentImpl from './empty-content.jsx';

/**
 * Component to represent empty content with informative text and a button to navigate on click.
 *
 * @class EmptyContent
 * @memberof Mojito.Presentation.Components
 */

export default MojitoCore.Presentation.UIView('EmptyContent', EmptyContentImpl);
