import MojitoPresentation from 'mojito/presentation';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
const { Image, Text, FlexPane, ProgressBar } = MojitoPresentation.Components;
const CurrencyHelper = MojitoPresentation.Utils.CurrencyHelper;
const NumberUtils = MojitoCore.Base.NumberUtils;
const BonusType = MojitoServices.Bonus.types.BONUS_TYPE;

const bonusToIconName = {
    [BonusType.FREEBET]: 'freeBetIcon',
    [BonusType.TOKEN]: 'freeBetIcon',
    [BonusType.PREWAGER]: 'prewagerIcon',
    [BonusType.CASH]: 'cashIcon',
};

export default function InProgressBonuses(props) {
    const {
        mojitoTools: { config, stringResolver },
        inProgressBonuses,
        currencyCode,
    } = props;

    const renderBonuses = () =>
        inProgressBonuses.map(bonus => (
            <InProgressBonus
                key={bonus.id}
                config={config}
                bonus={bonus}
                currencyCode={currencyCode}
                mojitoTools={props.mojitoTools}
            />
        ));

    const renderNoBonusesMessage = () => (
        <Text config={config.noBonusesLabel}>
            {stringResolver.resolveString(`$IN_PROGRESS_BONUSES.NO_BONUS_IN_PROGRESS`)}
        </Text>
    );

    return (
        <FlexPane config={config.bonusList} class="ta-InProgressBonuses">
            {inProgressBonuses?.length ? renderBonuses() : renderNoBonusesMessage()}
        </FlexPane>
    );
}

function InProgressBonus({ config, mojitoTools, bonus, currencyCode }) {
    const bonusIcon = bonusToIconName[bonus.bonusType];

    return (
        <FlexPane config={config.bonusContainer} class="ta-InProgressBonus">
            <FlexPane config={config.bonusHeader}>
                <FlexPane config={config.bonusNameContainer}>
                    <Image config={config[bonusIcon]} />
                    <Text config={config.bonusNameLabel}>{bonus.name}</Text>
                    <Text config={config.bonusPriceLabel}>
                        {CurrencyHelper.formatCurrency(bonus.amount, currencyCode)}
                    </Text>
                </FlexPane>
                <Text config={config.bonusDescription}>{bonus.description}</Text>
            </FlexPane>

            <FlexPane config={config.stepsList}>
                {bonus.steps.map((step, index) => (
                    <BonusStep
                        mojitoTools={mojitoTools}
                        key={step.name}
                        config={config}
                        step={step}
                        currencyCode={currencyCode}
                        disableBottomBorder={bonus.steps.length - 1 === index}
                    />
                ))}
            </FlexPane>
        </FlexPane>
    );
}

function BonusStep({ config, step, currencyCode, disableBottomBorder, mojitoTools }) {
    const { numBets, minBets, amount = 0, requiredWager, status, type } = step;
    const { style, stringResolver } = mojitoTools;

    const stepSubContainer = disableBottomBorder ? config.stepSubContainer : style.stepSubContainer;

    return (
        <FlexPane config={config.stepContainer} class="ta-BonusStep">
            <FlexPane config={config.stepNameContainer}>
                <FlexPane config={config.stepNumber}>
                    <Text config={config.stepNumberLabel}>{step.order}</Text>
                </FlexPane>
                <Text config={config.stepNameLabel}>{step.name}</Text>
                <Text config={config.stepStatus}>
                    {stringResolver.resolveString(`$IN_PROGRESS_BONUSES.STATUSES`)[status]}
                </Text>
            </FlexPane>
            <FlexPane config={stepSubContainer}>
                <Text config={config.stepDescription}>{step.description}</Text>
                <FlexPane config={config.progressContainer} class="ta-BonusStep">
                    <BonusProgressBar
                        config={config}
                        current={numBets}
                        max={minBets}
                        label={
                            stringResolver.resolveString(`$IN_PROGRESS_BONUSES.STEP_PROGRESS_TYPE`)[
                                type
                            ].FIRST
                        }
                        bottomLabelFrom={numBets}
                        bottomLabelTo={minBets}
                    />
                    <BonusProgressBar
                        config={config}
                        current={amount}
                        max={requiredWager}
                        label={
                            stringResolver.resolveString(`$IN_PROGRESS_BONUSES.STEP_PROGRESS_TYPE`)[
                                type
                            ].SECOND
                        }
                        bottomLabelFrom={CurrencyHelper.formatCurrency(amount, currencyCode)}
                        bottomLabelTo={CurrencyHelper.formatCurrency(requiredWager, currencyCode)}
                    />
                </FlexPane>
            </FlexPane>
        </FlexPane>
    );
}

function BonusProgressBar({ config, current, max, label, bottomLabelFrom, bottomLabelTo }) {
    if (current !== undefined && max !== undefined) {
        const progressRatio = Math.min(NumberUtils.numberToPrecision(current / max, 2), 1);

        return (
            <div>
                <ProgressBar
                    config={config.progressBar}
                    progress={progressRatio}
                    progressLabel={label}
                />
                <Text config={config.stepInfo}>
                    {bottomLabelFrom}
                    {'/'}
                    {bottomLabelTo}
                </Text>
            </div>
        );
    }
}

InProgressBonuses.getStyle = (config, mode, merge) => {
    const border = {
        style: config.stepSubContainerBorder,
    };

    return {
        stepSubContainer: merge(config.stepSubContainer, border),
    };
};
