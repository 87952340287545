/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 * The registry is responsible for tracking:
 * <ul>
 * <li>- Configurations registered through `add` method.</li>
 * <li>- Configuration overrides registered by using `addOverrides`.</li>
 * <li>- Configurations registered via `addImplementations`.</li>
 * </ul>
 *
 * @class ConfigRegistry
 * @memberof Mojito.Core.Services.Config
 */
export default class ConfigRegistry {
    constructor() {
        this.reset();
    }

    /**
     * Returns default config.
     *
     * @param {string} id - Component id to get config for.
     *
     * @returns {object|undefined} Config object or undefined if no config available.
     * @function Mojito.Core.Services.Config.configRegistry#getConfigDefaults
     */
    getConfigDefaults(id) {
        return this.configs[id]?.defaultValues;
    }

    /**
     * Returns overridden config. Typically, set by sports book implementation to apply styling and branding.
     *
     * @param {string} id - Component id to get config for.
     *
     * @returns {object|undefined} Config object or undefined if no config available.
     * @function Mojito.Core.Services.Config.configRegistry#getConfigOverrides
     */
    getConfigOverrides(id) {
        return this.configs[id]?.overrideValues;
    }

    /**
     * Returns config meta information.
     *
     * @param {string} id - Component id to get meta for.
     *
     * @returns {object|undefined} Meta info object.
     * @function Mojito.Core.Services.Config.configRegistry#getConfigMeta
     */
    getConfigMeta(id) {
        return this.configs[id]?.meta;
    }

    /**
     * Fetches config from cache.
     *
     * @param {string} id - Component id.
     * @param {string} key - Catch key.
     *
     * @returns {object|undefined} Config object.
     * @function Mojito.Core.Services.Config.configRegistry#fromCache
     */
    fromCache(id, key) {
        return this.cache[id]?.[key];
    }

    /**
     * Returns the component implementation config by ID.
     *
     * @param {string} id - Component id to get the implementation config for.
     *
     * @returns {Mojito.Core.Services.Config.ConfigObject|undefined} Contextualized ConfigObject or undefined if no config available.
     * @function Mojito.Core.Services.Config.configRegistry#getImplementationConfig
     */
    getImplementationConfig(id) {
        return this.implementationConfigs[id];
    }

    /**
     * Get raw config data. This getter returns object set by {@link Mojito.Core.Services.Config.configRegistry#add|add} method.
     *
     * @param {string} id - Component id.
     *
     * @returns {object|undefined} Config object.
     * @function Mojito.Core.Services.Config.configRegistry#getRawConfig
     */
    getRawConfig(id) {
        return this.configs[id]?.rawConfig;
    }

    /**
     * Check if config has default values available.
     *
     * @param {string} id - Component id.
     *
     * @returns {boolean} Config object.
     * @function Mojito.Core.Services.Config.configRegistry#hasConfigDefaults
     */
    hasConfigDefaults(id) {
        return !!this.getConfigDefaults(id);
    }

    /**
     * Add config object to a registry.
     *
     * @param {object} config - Config object.
     *
     * @function Mojito.Core.Services.Config.configRegistry#add
     */
    add(config) {
        if (!config) {
            return;
        }
        const id = config.name;
        this.ensureConfig(id);
        this.configs[id].defaultValues = config.values || {};
        this.configs[id].meta = config.meta;
        this.configs[id].rawConfig = config;
    }

    /**
     * Set additional (on the top of default) configuration for all components.
     *
     * @param {{}} configs - Object mapping component id to application configuration of component.
     *
     * @function Mojito.Core.Services.Config.configRegistry#addOverrides
     */
    addOverrides(configs) {
        Object.entries(configs).forEach(([id, config]) => this.addOverride(id, config));
    }

    /**
     * Set additional (on the top of default) configuration for component.
     *
     * @param {string} id - Component id.
     * @param {object} config - Component config, typically, set by sport book application to apply styling and branding.
     *
     * @function Mojito.Core.Services.Config.configRegistry#addOverride
     */
    addOverride(id, config) {
        if (!config) {
            return;
        }
        this.invalidateCache(id);
        this.ensureConfig(id);
        this.configs[id].overrideValues = config;
    }

    /**
     * Add implementation configuration for components.
     *
     * @param {object} implementations - Object mapping component id to implementation configuration of component.
     *
     * @function Mojito.Core.Services.Config.configRegistry#addImplementations
     */
    addImplementations(implementations) {
        this.implementationConfigs = implementations;
    }

    /**
     * Appends config to the cache.
     *
     * @param {string} id - Component id.
     * @param {string} key - Catch key.
     * @param {object} config - Config object.
     *
     * @function Mojito.Core.Services.Config.configRegistry#toCache
     */
    toCache(id, key, config) {
        if (!this.cache[id]) {
            this.cache[id] = {};
        }
        this.cache[id][key] = config;
    }

    /**
     * Removes config object from a registry.
     *
     * @param {string} id - Config id.
     *
     * @function Mojito.Core.Services.Config.configRegistry#remove
     */
    remove(id) {
        delete this.configs[id];
    }

    /**
     * Reset configuration for all components.
     *
     * @function Mojito.Core.Services.Config.configRegistry#reset
     */
    reset() {
        this.configs = {};
        this.implementationConfigs = {};
        this.resetCache();
    }

    /**
     * Reset configuration cache for all components.
     *
     * @function Mojito.Core.Services.Config.configRegistry#resetCache
     */
    resetCache() {
        this.cache = {};
    }

    /**
     * Invalidate configuration cache for a specific component.
     *
     * @param {string} id - Component id.
     *
     * @function Mojito.Core.Services.Config.configRegistry#invalidateCache
     */
    invalidateCache(id) {
        delete this.cache[id];
    }

    ensureConfig(id) {
        if (!this.configs[id]) {
            this.configs[id] = {};
        }
    }
}

export const configRegistry = new ConfigRegistry();
