import PropTypes from 'prop-types';
import ArrowheadImpl from './arrowhead.jsx';
import MojitoCore from 'mojito/core';

/**
 * A pointy component, used for pointing at things.
 *
 * For a use case example, see.
 *
 * @see Mojito.Presentation.Components.Popup
 *
 * @class Arrowhead
 * @memberof Mojito.Presentation.Components
 */

/**
 * `Arrowhead` component props.
 *
 * @memberof Mojito.Presentation.Components.Arrowhead
 */
const propTypes = {
    children: PropTypes.instanceOf(null),
};

export default MojitoCore.Presentation.UIView('Arrowhead', ArrowheadImpl, propTypes);
