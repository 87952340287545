import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import BetHistoryDetailsImpl from './bet-history-details-controller.jsx';
import { noop } from 'mojito/utils';
const UIControllerView = MojitoCore.Presentation.UIControllerView;

/**
 * A module for viewing bet history details.
 *
 * @class BetHistoryDetailsController
 * @memberof Mojito.Modules
 */

/**
 * BetHistoryDetailsController prop types.
 *
 * @property {string} betId - Id of the bet.
 * @property {Function} [onCloseClick = () => {}] - Callback for closing on the close icon.
 * @memberof Mojito.Modules.BetHistoryDetailsController
 */
const propTypes = {
    betId: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func,
};

const defaultProps = {
    onCloseClick: noop,
};

export default UIControllerView(
    'BetHistoryDetailsController',
    BetHistoryDetailsImpl,
    propTypes,
    defaultProps
);
